import { Typography, Button, Card, Col, DatePicker, Form, Input, InputNumber, Layout, PageHeader, Row, Select, Skeleton, TimePicker, Space, Tabs, Divider, Checkbox } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from "moment";
import React, { useState } from "react";
import { Link, Redirect, RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import { Services } from "./Services";
import { TeachersHandler } from "./TeachersHandler";
import { CheckpointsForm } from "./CheckpointsForm";

export type NewGroupPageProps = RouteComponentProps & {
};

export type NewGroupPageState = {
    submitting: boolean,
    classifiers: any,
    loaded: boolean,
    teachers: [],
    freeTeachers: [],
    services: [],
    checkpoints: [],
    selected_course_type: [{ full_time: boolean }],
    saveTrigger: number,
    servicesSet: boolean,
    checkpointsSet: boolean,
    data: {},
    selectedCities: [],
    fakeCsdd: string
};

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

export class NewGroupPage extends React.Component<NewGroupPageProps, NewGroupPageState>
{
    constructor(props: NewGroupPageProps) {
        super(props);
        this.state = {
            classifiers: {
                languages: [],
                cities: [],
                categories: [],
                status: [],
                course_type: [],
            },
            loaded: false,
            submitting: false,
            teachers: [],
            freeTeachers: [],
            services: [],
            checkpoints: [],
            selected_course_type: [{ full_time: false }],
            saveTrigger: 0,
            servicesSet: false,
            checkpointsSet: false,
            data: {},
            selectedCities: [],
            fakeCsdd: undefined as any
        }
    }



    async loadClassifiers() {
        const languages = await http.get("/rest/languages");
        const cities = await http.get("/rest/cities");
        const categories = await http.get("/rest/categories");
        const course_type = await http.get("/rest/study-groups-types");
        const status = await http.get("/rest/study-groups-status");
        //const teachers = [];//get data which is from this group await http.get("/rest/lecturers?no_paginate");
        const freeTeachers = await http.get("/rest/lecturers?no_paginate");
        this.setState({
            classifiers: {
                languages: languages.data.data,
                cities: cities.data.data,
                categories: categories.data.data,
                status: status.data.data,
                course_type: course_type.data.data,
            },
            loaded: true,
            submitting: false,
            teachers: [],//teachers.data.data,
            freeTeachers: freeTeachers.data.data,
        });
    }

    getFakeCsddID = async () => {
        await http.get(`/rest/get-default-study-group-csdd`).then((response: any) => {
            console.log(response)
            this.setState({ fakeCsdd: response.data })
        })
    }

    componentDidMount() {
        this.loadClassifiers();
        this.getFakeCsddID();
    }

    onFinish = (values: any) => {
        console.log(values)
        if (values.csdd_nr === undefined) values.csdd_nr = this.state.fakeCsdd
        this.setState({ saveTrigger: this.state.saveTrigger + 1, data: values })
    };

    processData = (values: any) => {
        let newCities = [] as any;
        console.log(values)
        if (this.state.selected_course_type) {
            newCities = this.state.selectedCities.map((city: any) => {
                return {
                    [city]: {
                        registration_fee: values[`registration_fee-${city}`],
                        registration_discount: values[`registration_discount-${city}`],
                    }
                }
            })
        }
        values.cities = newCities;
        console.log(values)

        //teacher relations
        let teacherIds = Array();
        this.state.teachers.map((teacher: any) => {
            return teacherIds.push(teacher.id);
        })
        values.teachers = teacherIds;
        //checkpoint relations
        let checkpointData = Array();
        this.state.checkpoints.map((checkpoint: any) => {
            return checkpointData.push(checkpoint);
        });
        values.checkpoints = checkpointData;
        //checkpoint relations
        let serviceData = Array();
        this.state.services.map((service: any) => {
            return serviceData.push(service);
        });
        values.services = serviceData;
        values.group_for_statistic_survey = values.group_for_statistic_survey ? 1 : 0;
        values.real_elearning = values.real_elearning ? 1 : 0;
        values.fake_elearning = values.fake_elearning ? 1 : 0;

        http.post("/rest/study-groups", values).then((response) => {
            this.props.history.replace(`/groups/edit/${response.data.data.id}`);
            console.log("OK")
        });

    }

    componentDidUpdate = (prevProps: any, prevState: any) => {
        if (this.state.servicesSet && this.state.checkpointsSet) {
            this.setState({ servicesSet: false, checkpointsSet: false })
            this.processData(this.state.data)
        }
    }

    onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    handleCourseChange = (event: any) => {
        var ftime = false;
        this.state.classifiers.course_type.map((type: any) => {
            if (type.id == event) {
                ftime = Boolean(type.full_time) || type.value === "Online apmācība";
            }
        });
        this.setState({
            selected_course_type: [{ full_time: Boolean(ftime) }]
        });
    }

    updateTeachers = (newList: []) => {
        this.setState({ teachers: newList })
    }

    saveServices = (services: []) => {
        this.setState({ services: services, servicesSet: true })
    }

    saveCheckpoints = (cp: []) => {
        this.setState({ checkpoints: cp, checkpointsSet: true })
    }

    render() {
        return (
            <>
                {!this.state.loaded ?
                    <Card style={{ width: "100%" }}>
                        <Skeleton />
                    </Card>
                    :
                    <Layout>
                        <Row>
                            {/* <Button onClick={() => console.log(this.state.from_tpl)}>Console.Log</Button> */}
                            <PageHeader
                                onBack={this.props.history.goBack}
                                title={
                                    <Title level={4} style={{ marginLeft: 40, marginBottom: 0 }}>Jauna grupa</Title>
                                }
                            />
                        </Row>
                        <Row>
                            <Card style={{ width: "100%", margin: 15 }}>
                                <Form
                                    layout="vertical"
                                    onFinish={this.onFinish}
                                >
                                    <Row gutter={[2, 24]}>
                                        <Col span={24} >
                                            <Form.Item>
                                                <Button type="primary" /*loading={this.state.submitting}*/ htmlType="submit">
                                                    Saglabāt
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="category_id"
                                                label="Kategorija"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <Select style={{ width: 200 }}>
                                                    {this.state.classifiers.categories.map((category: any) => {
                                                        return <Option key={category.id} value={category.id}>{category.value}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="name"
                                                label="Nosaukums"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <Input style={{ width: 200 }} />
                                                {/* <Select style={{ width: 200 }}>
                            {services.map((service: any) => {
                              return <Option key={service.id} value={service.name}>{service.name}</Option>
                            })}
                          </Select> */}
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="study_type_id"
                                                label="Apmācību veids"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <Select style={{ width: 200 }} onChange={this.handleCourseChange}>
                                                    {this.state.classifiers.course_type.map((type: any) => {
                                                        return <Option key={type.id} value={type.id}>{type.value}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="languages"
                                                label="Valoda"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <Select style={{ width: 200 }} mode="multiple">
                                                    {this.state.classifiers.languages.map((language: any) => {
                                                        return <Option key={language.id} value={language.id}>{language.value}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {this.state.selected_course_type[0].full_time === false ? <></> :
                                            <Col md={12} lg={8}>
                                                <Form.Item
                                                    name="cities"
                                                    label="Pilsēta"
                                                    // rules={[
                                                    //     {
                                                    //         required: Boolean(this.state.selected_course_type[0].full_time),
                                                    //         message: 'Šis lauks ir obligāts',
                                                    //     },
                                                    // ]}
                                                >
                                                    <Select style={{ width: 200 }} mode="multiple" onChange={(e: any) => this.setState({ selectedCities: e })}>
                                                        {this.state.classifiers.cities.map((city: any) => {
                                                            return <Option key={city.id} value={city.id}>{city.value}</Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        }
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="start_date"
                                                label="Uzsākšanas datums"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <DatePicker style={{ width: 200 }} format="DD.MM.YYYY" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="start_end_time"
                                                label="Nodarbības sākuma un beigu laiks"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <TimePicker.RangePicker
                                                    format="HH:mm"
                                                    style={{ width: 200 }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="planned_slots"
                                                label="Maksimālais studentu skaits"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <InputNumber style={{ width: 200 }} min={1} />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="extra_slots"
                                                label="Papildus studentu skaits"
                                            >
                                                <InputNumber style={{ width: 200 }} min={0} />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="extra_slots_price"
                                                label="Papildus studenta lekcijas cena"
                                            >
                                                <InputNumber style={{ width: 200 }} min={0} />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="show_web"
                                                label="Publicēt WEB"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <Select style={{ width: 200 }}>
                                                    <Option value="1">Jā</Option>
                                                    <Option value="0">Nē</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8} >
                                            <Form.Item
                                                name="csdd_nr"
                                                label="CSDD numurs"
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         message: 'Šis lauks ir obligāts',
                                            //     },
                                            // ]}
                                            >
                                                <Input style={{ width: 200 }} />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="status_id"
                                                label="Statuss"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <Select style={{ width: 200 }}>
                                                    {this.state.classifiers.status.map((service: any) => {
                                                        return <Option key={service.id} value={service.id}>{service.value}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Item valuePropName="checked" name="group_for_statistic_survey" label="Grupa statistikai">
                                                <Checkbox />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Item valuePropName="checked" name="real_elearning" label="Einšteins online apmācība">
                                                <Checkbox />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Item valuePropName="checked" name="fake_elearning" label="Grupa klātienes nodarbībām (individuāla grupa)">
                                                <Checkbox />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Card title="Reģistrācija">
                                                <Row gutter={24}>
                                                    <Col span={8}>
                                                        <Row gutter={16}>
                                                            <Col span={8}>
                                                                <Form.Item name="registration_fee" label="Cena">
                                                                    <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={8}>
                                                                <Form.Item name="registration_discount" label="Atlaides cena">
                                                                    <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={8}>
                                                                <Form.Item name="registration_vat" label="PVN %">
                                                                    <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                                </Form.Item>
                                                            </Col>
                                                            {
                                                                this.state.selectedCities.map((cityId: any) => {
                                                                    return (
                                                                        <Row key={cityId} gutter={16}>
                                                                            <h3 style={{ width: "100%", borderBottom: "1px solid #f0f0f0" }}>
                                                                                {this.state.classifiers.cities.filter((city: any) => {
                                                                                    return city.id === cityId;
                                                                                })[0].value}
                                                                            </h3>
                                                                            <Col span={12}>
                                                                                <Form.Item name={`registration_fee-${cityId}`} label="Cena">
                                                                                    <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col span={12}>
                                                                                <Form.Item name={`registration_discount-${cityId}`} label="Atlaides cena">
                                                                                    <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Tabs style={{ marginLeft: 30 }} defaultActiveKey="1">
                                                            <TabPane tab="LV" key="1">
                                                                <Form.Item name="comment_lv" label="Komentārs latviešu valodā">
                                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                                </Form.Item>
                                                            </TabPane>
                                                            <TabPane tab="EN" key="2">
                                                                <Form.Item name="comment_en" label="Komentārs angļu valodā">
                                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                                </Form.Item>
                                                            </TabPane>
                                                            <TabPane tab="RU" key="3">
                                                                <Form.Item name="comment_ru" label="Komentārs krievu valodā">
                                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                                </Form.Item>
                                                            </TabPane>
                                                        </Tabs>
                                                        <Tabs style={{ marginLeft: 30 }} defaultActiveKey="1">
                                                            <TabPane tab="LV" key="1">
                                                                <Form.Item name="description_lv" label="Apraksts latviešu valodā">
                                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                                </Form.Item>
                                                            </TabPane>
                                                            <TabPane tab="EN" key="2">
                                                                <Form.Item name="description_en" label="Apraksts angļu valodā">
                                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                                </Form.Item>
                                                            </TabPane>
                                                            <TabPane tab="RU" key="3">
                                                                <Form.Item name="description_ru" label="Apraksts krievu valodā">
                                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                                </Form.Item>
                                                            </TabPane>
                                                        </Tabs>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                        <TeachersHandler teachers={this.state.teachers} teachersList={this.state.freeTeachers} returnUpdated={(newList: any) => this.updateTeachers(newList)} />
                                    </Row>
                                </Form>
                            </Card>
                            <CheckpointsForm
                                getCheckpoints={this.saveCheckpoints}
                                save={this.state.saveTrigger}
                            />
                            <Services
                                cities={this.state.classifiers.cities}
                                getServices={this.saveServices}
                                save={this.state.saveTrigger}
                            />
                        </Row>
                    </Layout>}
            </>
        )
    }
}

export const NewGroup = withRouter(NewGroupPage)
