import { Layout, PageHeader, Row, Col, Card, Form, Button, Input, Skeleton, Space, Select, Radio, message, InputNumber } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import { RouteComponentProps, withRouter, Switch, Route } from 'react-router-dom';
import { http } from '../../helpers/http';
import { FormInstance } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import Avatar from '../ImgUploader';

const Content = Layout.Content;

export type EditLecturersOutlinePageProps = RouteComponentProps<{
    id: string
}> & {
};
export type EditLecturersOutlinePageState = {
    submitting: boolean,
    loading: boolean,
    changing_password: boolean,
    initialData: any,
    classifiers: any,
    fileData: any,
    img: any,
    changeImg: boolean
};

export class BaseEditLecturersOutlinePage extends React.Component<EditLecturersOutlinePageProps, EditLecturersOutlinePageState> {

    constructor(props: EditLecturersOutlinePageProps) {
        super(props);

        this.state = {
            submitting: false,
            loading: true,
            changing_password: false,
            initialData: {},
            classifiers: {},
            fileData: [],
            img: {},
            changeImg: false
        };
    }

    passwordFormRef = React.createRef<FormInstance>();

    componentDidMount() {
        (window["component" as any] as any) = this;
        this.loadClassifiers().then(() => {
            http.get(`/rest/lecturers/${this.props.match.params.id}`).then(response => {
                const data = response.data.data;
                this.setState({
                    initialData: this.processData(data),
                    loading: false
                });
            });
        });
    }

    async loadClassifiers() {
        const languages = await http.get("/rest/languages");
        const cities = await http.get("/rest/cities");
        const categories = await http.get("/rest/categories");

        this.setState({
            classifiers: {
                languages: languages.data.data,
                cities: cities.data.data,
                categories: categories.data.data
            }
        });
    }

    processData = (data: any) => {
        return {
            ...data,
            cities: data.cities.map((c: any) => String(c.id)),
            language_ids: data.languages.map((l: any) => String(l.id)),
            category_ids: data.categories.map((l: any) => String(l.id))
        }
    }


    uploadImage = (value: any) => {
        console.log(value)
        this.setState({ img: value });
    }

    getFile = (file: any) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            this.setState({ fileData: reader.result });
        }
        // console.log(file)
        if (file && file?.length > 0) {
            reader.readAsDataURL(file[0].originFileObj);
        }
    }

    onFinish = (values: any) => {
        console.log('Success:', values);
        console.log(this.state.fileData);
        let valuesToSubmit
        if (this.state.fileData) {
            if (this.state.fileData.length > 0) {
                valuesToSubmit = {
                    ...values,
                    img: this.state.fileData
                };
            } else {
                valuesToSubmit = {
                    ...values,
                };
            }
        } else {
            valuesToSubmit = {
                ...values,
            };
        }

        this.setState({ submitting: true });

        http.patch(`/rest/lecturers/${this.state.initialData.id}`, valuesToSubmit).then((response: any) => {
            this.setState({ submitting: false, initialData: this.processData(response.data.data) });
            message.success("Dati ir saglabāti");
        }).catch(() => {
            message.error("Kļūda!");
        });
    };

    onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    onChangePassword = (values: any) => {
        this.setState({ changing_password: true });
        http.patch(`/rest/lecturers/${this.state.initialData.id}`, { password: values.new_password }).then(() => {
            this.passwordFormRef.current?.resetFields();
            this.setState({ changing_password: false });
            message.success("Parole veiksmīgi nomainīta");
        }).catch(() => {
            message.error("Kļūda!");
        });
    }

    onChangePasswordFailed = (errorInfo: any) => {
        console.log("Password change failed:", errorInfo);
    }

    deleteService = (serviceId: number) => {
        http.delete(`/rest/services/${serviceId}`).then(() => {
            this.setState({
                initialData: {
                    ...this.state.initialData,
                    services: this.state.initialData.services.filter((service: any) => service.id !== serviceId)
                }
            });
        });
    }

    deleteImg = () => {
        http.delete(`/rest/lecturer-img/${this.state.initialData.id}`).then((response: any) => {
            console.log(response)
            this.setState({ changeImg: false, initialData: this.processData(response.data.data) })
            message.success("Attēls ir dzēsts")
        }).catch(() => {
            message.error("Kļūda")
        })
    }

    render() {

        const content = this.state.loading ? (
            <Card>
                <Skeleton />
            </Card>
        ) : (
            <>
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    initialValues={this.state.initialData}
                >
                    <Card style={{ marginBottom: 5 }} title={
                        <Space>
                            <strong>Pasniedzēja informācija</strong>
                            <h3 style={{ margin: "0px 0px 0px 30px" }}>CSDD statistika:</h3>
                            <Form.Item style={{ margin: 0 }} name="rating">
                                <InputNumber min={0} step={1} max={100} />
                            </Form.Item>
                        </Space>
                    }>
                        <Row gutter={20}>
                            <Col flex={1}>
                                <Form.Item name="first_name" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Vārds">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col flex={1}>
                                <Form.Item name="last_name" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Uzvārds">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col flex={1}>
                                <Form.Item name="phone_number" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Tālrunis">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col flex={1}>
                                <Form.Item name="email" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }, { type: "email", message: "Nav derīga e-pasta adrese" }]} label="E-pasta adrese">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col flex={1}>
                                <Form.Item name="username" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Lietotāja vārds (login)">
                                    <Input disabled style={{ fontWeight: "bold", color: "#020202" }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={20}>
                            <Col>
                                <Form.Item name="language_ids" required rules={[{ required: true, message: "Obligāts lauks", type: "array" }]} label="Valodas">
                                    <Select style={{ width: 200 }} mode="multiple">
                                        {this.state.classifiers.languages
                                            ? this.state.classifiers.languages.map((language: any) => {
                                                return <Select.Option key={language.id} value={String(language.id)}>{language.value}</Select.Option>
                                            }) : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="cities" required rules={[{ required: true, message: "Obligāts lauks", type: "array" }]} label="Pilsētas">
                                    <Select style={{ width: 200 }} mode="multiple">
                                        {this.state.classifiers.cities
                                            ? this.state.classifiers.cities.map((city: any) => {
                                                return <Select.Option key={city.id} value={String(city.id)}>{city.value}</Select.Option>
                                            }) : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="category_ids" required rules={[{ required: true, message: "Obligāts lauks", type: "array" }]} label="Kategorijas">
                                    <Select style={{ width: 200 }} mode="multiple">
                                        {this.state.classifiers.categories
                                            ? this.state.classifiers.categories.map((category: any) => {
                                                return <Select.Option key={category.id} value={String(category.id)}>{category.value}</Select.Option>
                                            }) : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col flex={1}>
                                <Form.Item name="web" required rules={[{ required: true, message: "Obligāts lauks" }]} label="WEB pieejamība">
                                    <Radio.Group>
                                        <Radio.Button value={0}>Nav pieejams</Radio.Button>
                                        <Radio.Button value={1}>Ir pieejams</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={20}>
                            <Col>
                                <Form.Item name="description" label="Papildus informācija">
                                    <TextArea style={{ width: 300 }}></TextArea>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    this.state.initialData.img.includes('noimage.svg') || this.state.initialData.img === 'storage/instructors/' || this.state.changeImg
                                        ? <>
                                            <Form.Item name="image" label="Pievienot attēlu">
                                                <Avatar getImg={this.uploadImage} returnFile={this.getFile} />
                                            </Form.Item>
                                            {
                                                this.state.changeImg
                                                    ? <Button style={{ margin: "10px auto" }} onClick={() => this.setState({ changeImg: false })}>Atcelt attēla maiņu</Button>
                                                    : <></>
                                            }
                                        </>
                                        : <>
                                            <img width="200px" height="200px" src={process.env.REACT_APP_API_URL + this.state.initialData.img} alt="" /><br />
                                            <Space>
                                                <Button style={{ margin: "10px auto" }} onClick={() => this.setState({ changeImg: true })}>Mainīt attēlu</Button>
                                                <Button danger onClick={this.deleteImg} icon={<DeleteOutlined />}></Button>
                                            </Space>
                                        </>
                                }
                            </Col>
                        </Row>
                    </Card>

                    <Card style={{ marginBottom: 20 }}>
                        <Row>
                            <Col>
                                <Button type="primary" loading={this.state.submitting} htmlType="submit">Saglabāt</Button>
                            </Col>
                        </Row>
                    </Card>
                </Form>

                <Card style={{ marginBottom: 20 }} title={<strong>Mainīt paroli</strong>}>
                    <Form
                        name="change-password"
                        layout="vertical"
                        onFinish={this.onChangePassword}
                        onFinishFailed={this.onChangePasswordFailed}
                        ref={this.passwordFormRef}
                    >
                        <Row gutter={20}>
                            <Col>
                                <Form.Item name="new_password" required rules={[{ required: true, whitespace: true, message: "Ievadiet jauno paroli" }]}>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col>
                                <Form.Item>
                                    <Button type="primary" loading={this.state.changing_password} htmlType="submit">Mainīt</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </>
        );

        return <Layout>
            <PageHeader
                style={{ padding: "0 16px", background: "rgb(255, 255, 255)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}
                onBack={() => { this.props.history.goBack(); }}
                title={
                    <>
                        <span style={{ fontSize: "0.7em", fontWeight: "normal" }}>Doties atpakaļ</span>
                        <span style={{ marginLeft: 40 }}>{this.state.loading ? `` : `${this.state.initialData.first_name} ${this.state.initialData.last_name}`}</span>
                    </>
                }
            />
            <Content style={{ padding: 20 }}>
                {content}
            </Content>
        </Layout>;
    }

}


export const EditLecturersOutlinePage = withRouter(BaseEditLecturersOutlinePage);

export const EditLecturerPage = withRouter((props: RouteComponentProps) => {
    return (
        <Switch>
            <Route exact path={props.match.path}>
                <EditLecturersOutlinePage />
            </Route>
        </Switch>
    );
});