import { Button, Col, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { http } from '../../../helpers/http'

type QuestionFilterProps = {
    onFilterChange: (filter: any) => void;
    filter: any;
    search: () => void;
    cancel: () => void;
    themes: any;
}

function QuestionsFilter(props: QuestionFilterProps) {
    const { themes } = props;
    const [languages, setLanguages] = useState([]);
    const [categories, setCategories] = useState([]);

    const getLanguages = () => {
        http.get(`rest/languages`).then((response) => {
            setLanguages(response.data.data)
            console.log(response)
        }).catch((error) => {
            console.log(error)
        });
    }

    const getCategories = () => {
        http.get(`rest/categories`).then((response) => {
            setCategories(response.data.data)
            console.log(response)
        }).catch((error) => {
            console.log(error)
        });
    }

    useEffect(() => {
        getCategories();
    }, []);

    return <Row gutter={[10, 10]} align='bottom'>
        <Col>
            <p><b>Kategorijas:</b></p>
            <Select
                style={{ width: 100 }}
                onChange={(value) => props.onFilterChange({ category_id: [value] })}
                value={props.filter.category_id}
            >
                {categories.map((category: any) => <Select.Option key={category.id} value={category.id}>{category.value}</Select.Option>)}
            </Select>
        </Col>
        {/* <Col>
            <p><b>Valoda:</b></p>
            <Select
                style={{ width: 100 }}
                onChange={(value) => props.onFilterChange(value)}
                value={props.filter.language}
            >
                {languages.map((language: any) => <Select.Option key={language.id} value={language.id}>{language.iso?.toUpperCase()}</Select.Option>)}
            </Select>
        </Col> */}
        <Col>
            <p><b>Jautājums:</b></p>
            <Input
                style={{ width: 300 }}
                onChange={(e) => props.onFilterChange({ question: [e.target.value] })}
                value={props.filter.question}
            />
        </Col>
        <Col>
            <p><b>Tēma:</b></p>
            <Select
                style={{ width: 300 }}
                onChange={(value) => props.onFilterChange({ csdd_question_category_id: [value] })}
                value={props.filter.csdd_question_category_id}
            >
                {themes.map((theme: any) => <Select.Option key={theme.id} value={theme.id}>{theme.name_lv}</Select.Option>)}
            </Select>
        </Col>
        <Col>
            <Button type="primary" onClick={props.search}>Atlasīt</Button>
        </Col>
        <Col>
            <Button type="primary" ghost onClick={props.cancel}>Atcelt</Button>
        </Col>
    </Row>
}

export default QuestionsFilter