import { Layout, Space, Button, Table, Tag, Tooltip, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { identity } from "lodash";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { http } from "../../helpers/http";
import moment from "moment";
import { MigrateServices } from "./MigrateServices";

const { Header, Content } = Layout;

function Activity(props: { status: any, allStatus: any }) {
    // const isActive = props.status === "planned";
    let currentStatus = { text: 'undefined', icon_color: '000000' };
    props.allStatus.map((type: any) => {
        if (type.value == props.status.id) {
            currentStatus = type;
        }
    });
    const text = currentStatus.text;
    //const text = isActive ? "Plānota" : "Cits";
    const icon = <span style={{
        display: "inline-block",
        width: 6,
        height: 6,
        borderRadius: "50%",
        backgroundColor: "#" + currentStatus.icon_color,
        marginBottom: 2
    }}></span>;
    return <span style={{ fontWeight: 600 }}>{icon} {text}</span>
}

export type GroupsPageProps = RouteComponentProps & {
    windowHeight: number,
    rType: string,
    match:
    {
        params: {
            id: any,
            study_group_id: any
        }
    },
    prevLocation: any,
    mutateHistory: any
}

export type GroupsPageState = {
    data: any,
    pagination: any,
    loading: any,
    classifiers: any,
    rType: string,
    tableState: any,
    changeGroup: {
        visible: boolean,
        groupID: number
    },
    schools: 1 | 0;
}

export class BaseGroupsPage extends React.Component<GroupsPageProps, GroupsPageState> {

    preparedData: any;
    allStatus: any;

    constructor(props: GroupsPageProps) {
        super(props);
        this.state = {
            data: [],
            pagination: {
                current: 1,
                pageSize: 15,
            },
            loading: true,
            classifiers: {},
            rType: props.rType,
            tableState: {} as any,
            changeGroup: {
                visible: false,
                groupID: 0
            },
            schools: 0
        };
    }

    componentDidMount() {
        const { prevLocation } = this.props;
        const { state } = this.props.location as any;
        const group = state !== undefined && state?.hasOwnProperty('group') ? state.group : undefined;
        const resetFilter = prevLocation !== undefined && !prevLocation.includes("group");
        // console.log("reset:", resetFilter, prevLocation)
        if (resetFilter) {
            sessionStorage.removeItem("groupsTableState");
        }
        // console.log(this.props.prevLocation)
        this.loadClassifiers().then(() => {
            if (group !== undefined) {
                this.handleTableChange({ current: 1 }, { category: [`${group?.category?.id}`] }, {})
                return;
            }
            if (sessionStorage.getItem("groupsTableState")) {
                const groupsTableState = JSON.parse(sessionStorage.groupsTableState)
                this.setState({ tableState: groupsTableState })
                const { pagination, filters, sorter } = groupsTableState;
                this.handleTableChange(pagination, filters, sorter)
            } else {
                this.fetch();
            }
        })
    }

    componentDidUpdate(prevProps: GroupsPageProps, prevState: GroupsPageState) {
        if (prevState.schools !== this.state.schools) {
            this.fetch({ pagination: { current: 1 }, schools: this.state.schools })
        }
    }

    componentWillUnmount() {
        this.props.mutateHistory(window.location.href)
    }

    async loadClassifiers() {
        const languages = await http.get("/rest/languages");
        const cities = await http.get("/rest/cities");
        const categories = await http.get("/rest/categories");
        const status = await http.get("/rest/study-groups-status");
        const course_type = await http.get("/rest/study-groups-types");

        this.setState({
            classifiers: {
                languages: languages.data.data,
                cities: cities.data.data,
                categories: categories.data.data,
                status: status.data.data,
                course_type: course_type.data.data,
            }
        });
    };

    handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const tableState = {
            pagination,
            filters,
            sorter: sorter.hasOwnProperty("field")
                ? {
                    columnKey: sorter.columnKey,
                    field: sorter.field,
                    order: sorter.order
                }
                : {}
        }
        this.setState({ tableState: tableState })
        sessionStorage.setItem("groupsTableState", JSON.stringify(tableState));
        this.fetch({
            sortField: sorter.order ? sorter.field : null,
            sortOrder: sorter.order || null,
            pagination,
            filters,
        });
    };

    removeFilters = () => {
        sessionStorage.removeItem("groupsTableState");
        this.setState({ tableState: {} });
        this.fetch();
    }

    fetch(params: any = { pagination: { current: 1 } }) {
        // const timeout = setTimeout(() => { this.setState({ loading: true }); }, 150);
        this.setState({ loading: true })
        http.get("/rest/study-groups", { params: this.getParams(params) }).then(result => {
            const { data, meta } = result.data;
            // clearTimeout(timeout);
            this.setState({
                data,
                pagination: {
                    current: meta.current_page,
                    pageSize: meta.per_page,
                    total: meta.total
                },
                loading: false
            });
        }).then(() => {
            this.processData();
            console.log(this.preparedData);
            this.setState({
                loading: false
            })
        }
        );
    }

    processData() {
        this.preparedData = this.state.data.map((dataItem: any) => {
            return {
                ...dataItem,
                category: { id: dataItem.category.id, value: dataItem.category.value },
            }
        })
        console.log(this.preparedData);
        this.allStatus = this.state.classifiers.status != null ? this.state.classifiers.status.map((status: any) => ({
            text: status.value, value: status.id, icon_color: status.icon_color
        })) : []
    }

    getParams(params: any) {
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters,
            schools: this.state.schools
        }
    }

    render() {
        const { tableState } = this.state;

        const columns = [
            // {
            //     title: 'Statuss',
            //     width: 10,
            //     dataIndex: 'group_status',
            //     key: 'group_status',
            //     sorter: true,
            //     fixed: 'left',
            //     filters: this.state.classifiers.status != null ? this.state.classifiers.status.map((statusData: any) => ({
            //         text: statusData.value, value: statusData.id
            //     })) : [],
            //     render: (status: any, all: any) => {
            //         let comment;
            //         if (all.comment?.length > 0) {
            //             comment =  <Tooltip placement="top" title={all.comment}>
            //                 <InfoCircleOutlined />
            //             </Tooltip>
            //         }
            //         return status !== null
            //         ? <Space><Activity status={status} allStatus={this.allStatus} />{comment}</Space>
            //         : <>
            //             {comment}
            //         </>
            //     }
            // },
            {
                title: 'Publicēta web',
                dataIndex: 'show_online',
                key: 'show_online',
                width: 8,
                align: 'center',
                render: (show_online: any) => {
                    return show_online === 1 ? "Jā" : "Nē";
                }
            },
            {
                title: <div title="Kategorija">Kat.</div>,
                dataIndex: 'category',
                key: 'category',
                width: 7,
                align: 'center',
                sorter: true,
                filters: this.state.classifiers.categories != null ? this.state.classifiers.categories.map((cat: any) => ({
                    text: cat.value, value: cat.id
                })) : [],
                filteredValue: tableState?.filters?.category || null,
                sortOrder: tableState?.sorter?.columnKey === 'category' && tableState?.sorter?.order,
                render: (category: any) => category.value
            },
            {
                title: 'Uzsākšanas datums',
                width: 12,
                dataIndex: 'start_date',
                key: 'start_date',
                // sorter: true,
                align: 'center',
                render: (date: any) => {
                    return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
                },
                filteredValue: tableState?.filters?.start_date || null,
                sortOrder: tableState?.sorter?.columnKey === 'start_date' && tableState?.sorter?.order,
                ...getColumnSearchProps('calendar')
            },
            {
                title: 'Nosaukums',
                dataIndex: 'name',
                key: 'name',
                width: 10,
                align: 'center',
                render: (name: any, all: any) => {
                    if (all.start_time) {
                        return <>
                            <p style={{ fontSize: 12 }}>
                                {name}
                            </p>
                            <p style={{ fontSize: 12 }}>
                                {moment(all?.start_time, "HH:mm:ss").format("HH:mm") +
                                    " - " + moment(all?.end_time, "HH:mm:ss").format("HH:mm")}
                            </p>
                        </>
                    }
                }
            },
            {
                title: 'Pilsēta',
                dataIndex: 'cities',
                key: 'cities',
                width: 8,
                filters: this.state.classifiers.cities != null ? this.state.classifiers.cities.map((c: any) => ({
                    text: c.value, value: c.id
                })) : [],
                filteredValue: tableState?.filters?.cities || null,
                render: (cities: any) => {
                    return cities.map((city: any) => {
                        return <Tag key={city.id}>{city.value + " "}</Tag>;
                    })
                }
            },
            {
                title: 'Valoda',
                dataIndex: 'languages',
                key: 'languages',
                width: 7,
                filters: this.state.classifiers.languages != null ? this.state.classifiers.languages.map((c: any) => ({
                    text: c.value, value: c.id
                })) : [],
                filteredValue: tableState?.filters?.languages || null,
                render: (languages: any) => {
                    return languages.map((language: any) => {
                        return <Tag key={language.id}>{language.value}</Tag>;
                    })
                }
            },
            {
                title: 'CSDD Nr',
                dataIndex: 'csdd_nr',
                key: 'csdd_nr',
                width: 10,
                filteredValue: tableState?.filters?.csdd_nr || null,
                ...getColumnSearchProps(),
                render: (csdd: string) => {
                    return <span style={{ color: csdd?.includes("undefined") ? "red" : "#000" }}>{csdd}</span>
                }

            },
            {
                title: 'Veids',
                dataIndex: 'course_type',
                key: 'course_type',
                width: 7,
                filters: this.state.classifiers.course_type != null ? this.state.classifiers.course_type.map((c: any) => ({
                    text: c.value, value: c.id
                })) : [],
                filteredValue: tableState?.filters?.course_type || null,
                render: (course: any) => {
                    return course.value;
                }
            },
            {
                title: <div title={`(No citām grupām) | Hard | Soft | Interested\n\tRezervācija`}>(C) | H | S | I </div>,
                dataIndex: '',
                key: 'reservation_type',
                width: 8,
                align: "center",
                render: (none: any, all: any) => {
                    return <div title="(No citām grupām) | Hard | Soft | Interested">
                        {`(${all.foreign_reservation})` +
                            " | " + (all.hard_reservation || 0) +
                            " | " + (all.soft_reservation || 0) +
                            " | " + (all.interested || 0)}
                    </div>
                }
            },
            {
                title: 'Pasniedzējs',
                dataIndex: 'lecturers',
                key: 'lecturers',
                width: 10,
                render: (lecturers: any) => {
                    return lecturers.map((lecturer: any) => {
                        return <Tag key={lecturer.id}>{lecturer.first_name} {lecturer.last_name}</Tag>;
                    })
                },
                filteredValue: tableState?.filters?.lecturers || null,
                ...getColumnSearchProps()
            },
        ];

        const { data, pagination, loading } = this.state;
        return (
            <Layout>
                <Header
                    style={{
                        padding: "0 16px",
                        background: "rgb(240, 242, 245)",
                        borderBottom: "2px solid #e7e7e7",
                        flexWrap: 'nowrap',
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}
                >
                    {
                        this.state.rType === "change"
                            ? <Button onClick={this.props.history.goBack}>Atpakaļ</Button>
                            : <Space>
                                <Link to='/groups/new'><Button type="primary">Pievienot grupu</Button></Link>
                                <Link to="/groups/templates"><Button type="primary" ghost>Grupu sagataves</Button></Link>
                                {/* <Button
                                danger
                                onClick={this.migrateInterested}
                                >
                                    Migrēt interesentus (TEST)
                                </Button> */}

                            </Space>
                    }
                    <Space>
                        <Link to='/groups/statistics'><Button>Apmeklējuma statistika</Button></Link>
                        <Button
                            onClick={() => this.setState({ schools: !!this.state.schools ? 0 : 1 })}
                        >
                            {
                                !!this.state.schools
                                    ? "Skolas "
                                    : "Einšteins "
                            }
                            grupas
                        </Button>
                        {
                            this.state.rType === "change"
                                ? <></>
                                : <Button onClick={() => this.props.history.push('/groups/edit-comments')}>Masveida grupu rediģēšana</Button>
                        }
                        <Button onClick={this.removeFilters} type="primary">Dzēst filtrus</Button>
                    </Space>
                </Header>
                <Content>
                    <Table
                        rowKey={record => record.id}
                        columns={columns as any}
                        rowClassName="clickable-row"
                        pagination={{
                            showSizeChanger: false,
                            size: 'small',
                            showTotal: (total) => <div>Kopā {total} Grupas</div>,
                            position: ['bottomCenter'],
                            pageSizeOptions: ['15'],
                            defaultPageSize: 15,
                            ...pagination
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    this.state.rType === "list"
                                        ? this.props.history.push(`/groups/edit/${record.id}`)
                                        : this.setState({
                                            changeGroup: {
                                                visible: Number(record.id) !== Number(this.props.match.params.study_group_id),
                                                groupID: record.id
                                            }
                                        })
                                }
                            };
                        }}
                        dataSource={this.preparedData}
                        loading={loading}
                        scroll={{ x: 1050, y: this.props.windowHeight - 185 }}
                        onChange={this.handleTableChange}
                    />
                    {
                        this.state.rType === "list"
                            ? <></>
                            : <MigrateServices
                                studentID={this.props.match.params.id}
                                groupsID={
                                    {
                                        old: this.props.match.params.study_group_id,
                                        new: this.state.changeGroup.groupID
                                    }
                                }
                                visible={this.state.changeGroup.visible}
                                setVisible={(state: boolean) => this.setState({
                                    changeGroup: {
                                        visible: state,
                                        groupID: this.state.changeGroup.groupID
                                    }
                                })}
                            />
                    }
                </Content>
            </Layout>
        );
    }
}

export const GroupsPage = withRouter(BaseGroupsPage);
