import { Button, Calendar, Card, Col, DatePicker, Input, Layout, PageHeader, Row, Select, Table } from "antd"
import { AxiosResponse } from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { http } from "../../helpers/http";

const DateSlot = (props: any) => {
    const { slot } = props;
    return <div
        style={{
            padding: 4,
            border: "1px solid #F0F0F0",
            borderRadius: 3,
            marginBottom: 3,
            backgroundColor: "#bdf2ff"
        }}
    >
        <p style={{ margin: 0, fontSize: 10 }}><b>{slot.lecturer} {slot.csdd_nr} {slot.city}</b></p>
        <p style={{ margin: 0, fontSize: 10 }}><b>{slot.module} {moment(slot.time_from, "HH:mm:ss").format("HH:mm")} - {moment(slot.time_to, "HH:mm:ss").format("HH:mm")}</b></p>
    </div>
}

export const LecturersStats = () => {
    const history = useHistory();
    const [filters, setFilters] = useState({
        city_id: undefined as undefined | number,
        category: undefined as undefined | number,
        name: undefined as undefined | string,
        lecturer: undefined as undefined | string
    });
    const [currentDate, setCurrentDate] = useState(moment())
    const [categories, setCategories] = useState([] as any);
    const [cities, setCities] = useState([] as any);
    const [data, setData] = useState([] as any);
    const [loading, setLoading] = useState(false);

    const getCategories = () => {
        http.get(`/rest/categories`).then((res: AxiosResponse) => {
            setCategories(res.data.data)
        })
    };

    const getCities = () => {
        http.get(`/rest/cities`).then((res: AxiosResponse) => {
            setCities(res.data.data)
        })
    };

    const fetchData = (date: any = currentDate) => {
        const date_from = moment(date).startOf("month").subtract(10, "days").format("YYYY-MM-DD")
        const date_to = moment(date).endOf("month").add(10, "days").format("YYYY-MM-DD")
        setLoading(true);
        http.get(`/rest/lecturer-load-stats`, {
            params: {
                filters: {
                    ...filters,
                    date_from,
                    date_to
                }
            }
        }).then((res: AxiosResponse) => {
            setLoading(false);
            setData(res.data?.map((data: any) => {
                return {
                    lecturer: data.lecturer,
                    module: data.module,
                    lesson_date: data.lesson_date,
                    study_type: data.study_type,
                    csdd_nr: data.csdd_nr,
                    students: data.student_counter,
                    time_from: data.time_from,
                    time_to: data.time_to,
                    city: data.city
                }
            }))
        }).catch(() => {
            setLoading(false)
        })
    }

    const selectDateHandler = (date: moment.Moment) => {
        setCurrentDate(date)
        if (!date.isSame(currentDate, "month")) {
            fetchData(date)
        }

    }

    const dateCellRender = (value: any) => {
        if (data == undefined) return
        const slots = data?.filter((slot: any) => {
            return moment(slot.lesson_date, "YYYY-MM-DD").isSame(value, "day");
        });
        if (slots.length === 0) {
            return;
        }

        return slots.map((item: any, index: any) => {
            return <DateSlot key={index} slot={item} />
        })
    }

    useEffect(() => {
        getCategories();
        getCities();
        fetchData();
    }, []);

    const columns = [
        {
            title: "Pasniedzējs",
            dataIndex: "lecturer",
        },
        {
            title: "Modulis",
            dataIndex: "module",
        },
        {
            title: "Studenti",
            dataIndex: "students",
        },
        {
            title: "Datums",
            dataIndex: "lesson_date",
        },
        {
            title: "Veids",
            dataIndex: "study_type",
        },
        {
            title: "CSDD numurs",
            dataIndex: "csdd_nr",
        },

    ]

    useEffect(() => {
        console.log(data)
    }, [data])

    return <Layout>
        <PageHeader
            title="Pasniedzēju statistika"
            style={{ backgroundColor: "#FFFFFF" }}
            onBack={history.goBack}
        />
        <Card>
            <Row gutter={[20, 20]}>
                {/* <Col>
                    <p>Atlases periods:</p>
                    <DatePicker.RangePicker
                        value={
                            filters.date_from !== undefined
                                ? [moment(filters.date_from, "YYYY-MM-DD"), moment(filters.date_to, "YYYY-MM-DD")]
                                : undefined
                        }
                        onChange={e => {
                            setFilters((prev: any) => {
                                return {
                                    ...prev,
                                    date_from: e ? e[0]?.format("YYYY-MM-DD") : undefined,
                                    date_to: e ? e[1]?.format("YYYY-MM-DD") : undefined,
                                }
                            })
                        }}
                    />
                </Col> */}
                <Col>
                    <p>Pilsēta:</p>
                    <Select
                        style={{ width: 120 }}
                        value={filters.city_id}
                        onChange={e => {
                            setFilters((prev: any) => {
                                return {
                                    ...prev,
                                    city_id: e
                                }
                            })
                        }}
                    >
                        {
                            cities.map((city: any) => {
                                return <Select.Option key={city.id} value={city.id}>
                                    {city.value}
                                </Select.Option>
                            })
                        }
                    </Select>
                </Col>
                <Col>
                    <p>Kategorija:</p>
                    <Select
                        style={{ width: 120 }}
                        value={filters.category}
                        onChange={e => {
                            setFilters((prev: any) => {
                                return {
                                    ...prev,
                                    category: e
                                }
                            })
                        }}
                    >
                        {
                            categories.map((category: any) => {
                                return <Select.Option key={category.id} value={category.id}>
                                    {category.value}
                                </Select.Option>
                            })
                        }
                    </Select>
                </Col>
                <Col>
                    <p>Modulis:</p>
                    <Input
                        value={filters.name}
                        onChange={e => {
                            e.persist();
                            setFilters((prev: any) => {
                                return {
                                    ...prev,
                                    name: e.target.value.length > 0 ? e.target.value : undefined
                                }
                            })
                        }}
                    />
                </Col>
                <Col>
                    <p>Pasniedzejs:</p>
                    <Input
                        value={filters.lecturer}
                        onChange={e => {
                            e.persist();
                            setFilters((prev: any) => {
                                return {
                                    ...prev,
                                    lecturer: e.target.value.length > 0 ? e.target.value : undefined
                                }
                            })
                        }}
                    />
                </Col>
                <Col>
                    <Button
                        style={{ marginTop: 36 }}
                        type="primary"
                        onClick={() => fetchData()}
                        loading={loading}
                    >
                        Atlasīt
                    </Button>
                </Col>
            </Row>
        </Card>
        <Card bodyStyle={{ overflow: "scroll", maxHeight: "100vh" }}>
            {/* <Table
                rowKey={record => record.lecturer + record.csdd_nr + record.module}
                dataSource={data}
                columns={columns}
                pagination={
                    {
                        ...pagination,
                        pageSize: 15,
                        showSizeChanger: false,
                        position: ['bottomCenter'],
                        showTotal: total => `Kopā ${total} ieraksti`
                    }
                }
                onChange={handleTableChange}
            /> */}
            <Calendar
                onSelect={selectDateHandler}
                dateCellRender={dateCellRender}
            />
        </Card>
    </Layout>
}