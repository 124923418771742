import { Layout, PageHeader, Card, Row, Checkbox, message, Col, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { http } from "../../helpers/http";

export const RemindersAccess = (props: any) => {
    const history = useHistory();
    const [users, setUsers] = useState([] as any);
    const [loading, setLoading] = useState(false);

    const fetchUsers = async () => {
        setLoading(true);
        await http.get(`/rest/admins`).then((response: any) => {
            setUsers(response.data.data.filter((user: any) => user.active === 1))
            setLoading(false);
        }).catch(() => {
            message.error("Neizdevās ielādēt lietotājus");
            setLoading(false);
        })
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return <Layout>
        <PageHeader
            style={{ backgroundColor: "#FFFFFF" }}
            title={"Pieteikumu piekļuves"}
            onBack={history.goBack}
        />
        <Card style={{ width: "100%" }} className="crm-card">
            {
                loading
                && <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(200, 200, 200, .3)", zIndex: 100 }} >
                    <Spin spinning={loading} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                </div>
            }
            <b>*Atzīmētiem lietotājiem ir piekļuve pieteikumiem</b>
            <Row>
                {
                    users.filter((user: any) => {
                        return !!!user.ruler_of_universe || user.id !== props.admin.id
                    }).map((user: any) => {
                        const hasCrmAccess = user.permission?.includes("crm_reminder_application");
                        return <Col span={6} key={user.id} style={{ padding: 20 }}>
                            <Checkbox
                                checked={hasCrmAccess}
                                onChange={(e) => {
                                    setLoading(true);
                                    http.post(`/rest/set-crm-manager/${user.id}`, { set: e.target.checked ? 1 : 0 }).then(() => {
                                        fetchUsers();
                                    }).catch(() => {
                                        setLoading(false);
                                        message.error("Neizdevās atjaunot atļaujas")
                                    })
                                }}
                                style={{ marginRight: 10 }}
                            />
                            {user.first_name} {user.last_name}
                        </Col>
                    })
                }
            </Row>
        </Card>
    </Layout>
}