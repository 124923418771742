import { Button, Col, DatePicker, message, Row, Select, Space, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { http } from "../../../helpers/http";
import lodash from "lodash";
import moment from "moment";

export const InstructorTab = withRouter((props: any) => {

  const [data, setData] = useState([] as Array<any>);
  const [loading, setLoading] = useState(true);
  const [instructors, setInstructors] = useState([] as any);
  const [selectedInstructor, setSelectedInstructor] = useState(undefined as any);
  const [filterDateFrom, setFilterDateFrom] = useState(undefined as any);
  const [filterDateTo, setFilterDateTo] = useState(undefined as any);
  const history = useHistory();

  function changeFilterDateFrom(date: any) {
    setFilterDateFrom(date ? date.startOf("day") : undefined);
  }
  function changeFilterDateTo(date: any) {
    setFilterDateTo(date ? date.endOf("day") : undefined);
  }

  const columns = [
    {
      title: 'Izrakstīšanas datums',
      dataIndex: 'date',
      width: 247,
      render: (date: any) => moment(date).format("DD.MM.YYYY")
    },
    {
      title: 'Instruktors',
      dataIndex: 'instructor',
      render: (instructor: any) => instructor.first_name + " " + instructor.last_name,
    },
    {
      title: 'Rēķina numurs',
      dataIndex: 'invoice_number',
      key: 'invoice_nr',
    },
    // {
    //   title: '',
    //   key: 'download' + 'id',
    //   render: (item: any) => <Button>Lejupielādēt rēķinu</Button>
    // }
  ]

  React.useEffect(() => {
    http.get(`/invoices/list-invoices`)
      .then(result => {
        setData(result.data.data);
        setInstructors(
          lodash.uniqBy(result.data.data.map((invoice: any) => invoice.instructor), "id")
        );
        setLoading(false);
      });
  }, []);

  async function deletePayment(id: any) {
    return http.post(`/invoices/delete-instructor-invoice/${id}`);
  }

  const allColumns = columns.concat([
    {
      title: "",
      width: 5,
      render: (row: any) => {
        return <Button
          onClick={() => {
            history.push(`/invoices/invoice-details/${row.id}`);
          }}
        >Atvērt rēķinu</Button>
      }
    },
    {
      title: "",
      width: 5,
      render: (row: any) => {
        return <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => {
            setLoading(true);
            deletePayment(row.id).then(() => {
              setData(data.filter((r: any) => r.id !== row.id));
              setLoading(false);
            }).catch(() => {
              setLoading(false);
              message.error("Neizdevās dzēst rēķinu");
            });
          }}
        ></Button>
      }
    } as any
  ]);

  return (
    <div>
      <Row gutter={[12, 12]} align="middle" style={{ margin: "10px 0" }}>
        <Col>
          <Space>
            <Select
              loading={instructors.length < 1}
              value={selectedInstructor}
              onChange={(value: any) => {
                setSelectedInstructor(value);
              }}
              placeholder="Izvēlieties instruktoru..."
              showSearch
              allowClear
              style={{ width: 200 }}
              filterOption={((input: any, option: any) => {
                return option && option.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }) as any}
            >
              {instructors.map((instructor: any) =>
                <Select.Option
                  key={`instructor-${instructor.id}`}
                  value={instructor.id}
                >
                  {instructor.first_name} {instructor.last_name}
                </Select.Option>
              )}
            </Select>
            <DatePicker value={filterDateFrom} onChange={changeFilterDateFrom} placeholder="Datums no..." format="DD.MM.YYYY" />
            <DatePicker value={filterDateTo} onChange={changeFilterDateTo} placeholder="Datums līdz..." format="DD.MM.YYYY" />
          </Space>
        </Col>
        {/* <Col>
            <Button>Pievienot rēķinu</Button>
        </Col> */}
        {/* <Button onClick={() => console.log(props.match)}>Console.log</Button> */}
      </Row>
      <Table
        rowKey={record => record.id}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              // props.history.push(`/invoices/instructor/${record.id}`);
            }, // click row
            onDoubleClick: event => { }, // double click row
            onContextMenu: event => { }, // right button click row
            onMouseEnter: event => { }, // mouse enter row
            onMouseLeave: event => { }, // mouse leave row
          };
        }}
        loading={loading}
        dataSource={
          data
            .filter(
              (invoice: any) => selectedInstructor ? invoice.instructor.id === selectedInstructor : true
            )
            .filter((invoice: any) => {
              const date = moment(invoice.date);
            //   console.log("date", filterDateFrom);
              const isSameOrAfter = !filterDateFrom ? true : (date >= filterDateFrom);
              const isSameOrBefore = !filterDateTo ? true : (date <= filterDateTo);
              return isSameOrAfter && isSameOrBefore;
            })
        }
        columns={allColumns}
      // pagination={{
      //   showSizeChanger: false,
      //   size: 'small',
      //   showTotal: (total) => <div>Kopā {total} rēķini</div>,
      //   position: ['bottomCenter'],
      //   pageSizeOptions: ['10', '15'],
      //   defaultPageSize: 10,
      // }}
      />
    </div>
  )
})