import { Button, Col, DatePicker, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'

type DrivenStatsFilterProps = {
    onFilterChange: (filter: any) => void;
    filter: any;
    search: () => void;
    cancel: () => void;
}

function DrivenStatsFilter(props: DrivenStatsFilterProps) {

    return <Row gutter={[10, 10]} align='bottom'>
        <Col>
            <p><b>Periods:</b></p>
            <DatePicker.RangePicker
                value={props.filter.period}
                onChange={(value) => {
                    props.onFilterChange({ period: value })
                }}
            />
        </Col>
        {/* <Col>
            <p><b>Instruktors:</b></p>
            <Input
                style={{ width: 300 }}
                onChange={(e) => props.onFilterChange({ instructor: [e.target.value] })}
                value={props.filter.instructor}
            />
        </Col> */}
        <Col>
            <Button type="primary" onClick={props.search}>Atlasīt</Button>
        </Col>
        <Col>
            <Button type="primary" ghost onClick={props.cancel}>Atcelt</Button>
        </Col>
    </Row>
}

export default DrivenStatsFilter