import { Button, Card, Checkbox, Col, Form, Input, message, Row, Select, Space, Tag } from "antd"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { http } from "../../helpers/http";
import { useForm } from 'antd/lib/form/Form';

type IndividualDrivingSettingsProps = {
    studentCategory: any;
}

export const IndividualDrivingSettings = ({ studentCategory }: IndividualDrivingSettingsProps) => {
    const { id }: { id: string } = useParams();
    const [form] = useForm()
    const [onlyDriving, setOnlyDriving] = useState(0 as 0 | 1);
    const [individualDrivingPayment, setIndividualDrivingPayment] = useState(0);
    const [paidDrivingRegistration, setPaidDrivingRegistration] = useState([] as any);

    const registrationStatusValues = {
        need_driving: "Iesācējs",
        refresh_driving: "Iemaņu atsvaidzināšana"
    } as any;

    const fetchIndividualDrivingRegistration = () => {
        http.post(`rest/check-individual-driving-registration/${id}`, { category: studentCategory.category_id }).then((response: any) => {
            const { data } = response;
            if (data.data[0] === null) {
                data.data = []
            }
            setPaidDrivingRegistration(data);
            // this.setState({ paidDrivingRegistration: data })
        });
    };

    const fetchIndividualDrivingPrice = () => {
        http.get(`/rest/get-individual-driving-price/${id}`, { params: { category: studentCategory.category_id } }).then((response: any) => {
            setIndividualDrivingPayment(response.data.data.individual_price);
            // this.mainFormRef.current?.setFieldsValue({ driving_registration_payment_amount: response.data.data.individual_price })
        });
    }

    const onFinish = (values: any) => {
        const toSend = {
            cancel_driving: values.need_driving_registration_payment ? 0 : 1,
            registration_status: values.registration_status,
            driving_registration_payment_amount: values.driving_registration_payment_amount,
            category: studentCategory.category_id
        }
        http.post(`rest/create-individual-driving-registration/${id}`, { ...toSend }).then((response: any) => {
            fetchIndividualDrivingRegistration();
            message.success("Students ir pieteikts individuālai braukšanai")
        }).catch(() => {
            message.error("Kļūda!")
        })

    }

    useEffect(() => {
        if (Object.keys(studentCategory).length > 0) {
            setOnlyDriving(studentCategory.only_driving);
            fetchIndividualDrivingPrice();
            fetchIndividualDrivingRegistration();
        }
    }, [studentCategory])

    useEffect(() => {

    }, [onlyDriving])

    return <Card
        style={{ marginBottom: 20 }}
        loading={paidDrivingRegistration.length === 0}
    >
        <Form
            form={form}
            layout="inline"
            initialValues={{
                need_driving_registration_payment: !!studentCategory.only_driving,
                registration_status: Object.keys(registrationStatusValues)
                    .some((el: any) => el === studentCategory.registration_status)
                    ? studentCategory.registration_status
                    : undefined,
                driving_registration_payment_amount: individualDrivingPayment
            }}
            onFinish={onFinish}
        >
            <Form.Item
                style={{ marginRight: 30 }}
                valuePropName="checked"
                name="need_driving_registration_payment"
                label={<b>Tikai braukšana</b>}
            >
                <Checkbox
                    onChange={(e: any) => setOnlyDriving(e.target.checked ? 1 : 0)}
                    disabled={!!studentCategory.only_driving}
                />
            </Form.Item>
            {
                onlyDriving
                    ? <>
                        <Form.Item
                            style={{ width: 120, marginRight: 30 }}
                            name="driving_registration_payment_amount"
                            label={<b>Cena</b>}
                            required
                            rules={[{ required: true, message: "Obligāts lauks" }]}
                        >
                            <Input
                                // onChange={(e: any) => {
                                //     e.persist();
                                //     // setIndividualDrivingPayment(e.target.value)
                                //     // this.setState({ individualDrivingPayment: e.target.value })
                                // }}
                                type="number"
                                // disabled={paidDrivingRegistration?.data?.length > 0 && !!!paidDrivingRegistration.cancelled}
                            />
                        </Form.Item>
                        <Form.Item
                            name="registration_status"
                            label={<b>Status</b>}
                            required
                            rules={[{ required: true, message: "Obligāts lauks" }]}
                        >
                            <Select
                                style={{ width: 180 }}
                            >
                                {
                                    Object.keys(registrationStatusValues).map((key: any) => {
                                        return <Select.Option key={key} value={key}>{registrationStatusValues[key]}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Col span={24} style={{ marginTop: 10 }}>
                            {
                                !!paidDrivingRegistration.paid
                                    ? <Space>
                                        <Tag color="green">Apmaksāts</Tag>
                                        <Button
                                            type="primary"
                                            // onClick={sendDrivingRegistrationRequest}
                                            htmlType="submit"
                                        >
                                            {
                                                !!paidDrivingRegistration.cancelled
                                                    ? "Nosūtīt atkārtotu pieprasījumu"
                                                    : "Nosūtīt pieprasījumu"
                                            }
                                        </Button>
                                    </Space>
                                    : <Space>
                                        <Tag color="red">Nav apmaksāts</Tag>
                                        <Button
                                            type="primary"
                                            // onClick={sendDrivingRegistrationRequest}
                                            htmlType="submit"
                                        >
                                            {
                                                !!paidDrivingRegistration.cancelled
                                                    ? "Nosūtīt atkārtotu pieprasījumu"
                                                    : "Nosūtīt pieprasījumu"
                                            }
                                        </Button>
                                    </Space>
                            }
                        </Col>
                    </>
                    : <></>
            }
        </Form>
    </Card>
}