import React, { useEffect, useState } from "react";
import { Button, Layout, message, PageHeader, Table, Tag } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import { http } from "../../helpers/http";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { useHistory } from "react-router-dom";

export const StudentsOnHold = (props: any) => {

    const history = useHistory();

    const [classifiers, setClassifiers] = useState({} as any);
    const [students, setStudents] = useState([] as any);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15
    });

    const fetchClassifiers = async () => {
        const cities = (await http.get(`/rest/cities`)).data.data;
        const categories = (await http.get(`/rest/categories`)).data.data;
        setClassifiers({
            cities,
            categories
        })
    }

    const fetchStudents = async (params: any = { pagination: { current: 1 } }) => {
        await http.get("/rest/students-bad-driving-attendance", { params: getParams(params) }).then((response: any) => {
            const students = response.data.data;
            setStudents(students);
            setPagination(() => {
                return {
                    current: response.data.current_page,
                    pageSize: response.data.per_page,
                    total: response.data.total,
                }
            })
        });
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        fetchStudents({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters,
        });
    };

    const getParams = (params: any) => {
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters
        }
    }

    useEffect(() => {
        fetchStudents();
        fetchClassifiers();
    }, [])

    const columns = [
        {
            title: "Students",
            dataIndex: "",
            key: "student",
            // sorter: true,
            render: (param: any, record: any) => {
                return record.first_name + " " + record.last_name;
            },
            // ...getColumnSearchProps()
        },
        {
            title: "Kategorija",
            dataIndex: "category",
            key: "category_id",
            align: "center",
            filters: classifiers?.categories?.map((category: any) => ({
                text: category.value, value: category.id
            }))
        },
        {
            title: "Pilsēta",
            dataIndex: "city",
            key: "city_id",
            filters: classifiers?.cities?.map((city: any) => ({
                text: city.value, value: city.id
            }))
        },
        {
            title: "E-pasts",
            dataIndex: "email",
            key: "email",
            // sorter: true,
            // ...getColumnSearchProps()
        },
        {
            title: "Tālr.Nr.",
            dataIndex: "phone_number",
            key: "phone_number",
            // sorter: true,
            // ...getColumnSearchProps()
        },
        {
            title: "Nobrauktas stundas",
            dataIndex: "driven_lessons",
            key: "driven_lessons",
            align: "center"
            // sorter: true,
            // ...getColumnSearchProps()
        },
        {
            title: "Nākamā nodarbība",
            dataIndex: "next_lesson",
            key: "next_lesson",
            align: "center",
            render: (record: any) => {
                if (record === null) return "Nav pieteikta"
                return record;
            }
        }
    ] as any;

    return (
        <Layout>
            <PageHeader
                title={"Studenti, kuri kavē braukšanas nodarbības"}
                onBack={history.goBack}
                style={{ backgroundColor: "#FFFFFF", marginBottom: 15 }}
            />
            <Table
                rowKey={record => record.id}
                dataSource={students}
                columns={columns}
                pagination={{
                    showSizeChanger: false,
                    size: 'small',
                    showTotal: (total) => <div>Kopā {total} pieteikumi</div>,
                    position: ['bottomCenter'],
                    pageSizeOptions: ['15'],
                    defaultPageSize: 15,
                    ...pagination
                }}
                onChange={handleTableChange}
                expandable={{
                    expandedRowRender: (record: any) => {
                        return (
                            <>
                                <p>{record.status}</p>
                            </>
                        )
                    }
                }}
            />
        </Layout>
    )
}