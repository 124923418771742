import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Row, Typography } from "antd";
import moment from "moment";

const { Title, Text } = Typography;

export const CSDDForm = (props: any) => {
    const { csdd, edit } = props;

    const [formValues, setFormValues] = useState({} as any)

    const [form] = Form.useForm();

    useEffect(() => {
        setFormValues(
            csdd ? {
                has_declared_address: csdd.has_declared_address,
                has_medical_check: csdd.has_medical_check,
                medical_check_date: csdd.medical_check_date === null || csdd.medical_check_date === undefined ? undefined : moment(csdd.medical_check_date, "YYYY-MM-DD"),
                has_first_aid: csdd.has_first_aid,
                first_aid_date: csdd.first_aid_date === null || csdd.first_aid_date === undefined ? undefined : moment(csdd.first_aid_date, "YYYY-MM-DD"),
                first_aid_nr: csdd.first_aid_nr
            } : {}
        )
    }, [csdd])

    useEffect(() => {
        form.setFieldsValue(formValues);
    }, [formValues])

    const formChangeHandler = (event: any) => {
        saveForm();
    }

    const saveForm = () => {
        form.validateFields()
            .then((values: any) => {
                edit(values);
            })
            .catch((errorInfo) => { console.log(errorInfo) });
    }

    return (
        <Card className="CSDD-card">
            <Title level={5}>CSDD check:</Title>
            <Row>
                <Col span={24}>
                    <Form
                        form={form}
                        labelCol={{
                            span: 21,
                        }}
                        wrapperCol={{
                            span: 3,
                        }}
                        onChange={formChangeHandler}
                        initialValues={formValues}
                    >
                        <Row>
                            <Col span={8}>
                                <Form.Item valuePropName="checked" name="has_declared_address" label="Deklarētā adrese ir " >
                                    <Checkbox />
                                </Form.Item>
                                <Form.Item valuePropName="checked" name="has_medical_check" label="Medicīniskā izziņa: ">
                                    <Checkbox />
                                </Form.Item>
                                <Form.Item valuePropName="checked" name="has_first_aid" label="Pirmās palīdzības kursi: ">
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Row align="bottom">
                                    <Form.Item
                                        name="medical_check_date"
                                        style={{ marginTop: 37 }}
                                        labelCol={{
                                            span: 0,
                                        }}
                                        wrapperCol={{
                                            span: 21,
                                            offset: 2,
                                        }}
                                    >
                                        <DatePicker onChange={formChangeHandler} placeholder="Derīguma termiņš" />
                                    </Form.Item>
                                    <b style={{ marginBottom: 10 }}>
                                        {
                                            csdd.csdd_medical_categories
                                            && csdd.csdd_medical_categories.join(", ")
                                        }
                                    </b>
                                </Row>
                                <Row>
                                    <Form.Item
                                        name="first_aid_date"
                                        labelCol={{
                                            span: 0,
                                        }}
                                        wrapperCol={{
                                            span: 24,
                                            offset: 2,
                                        }}
                                        style={{ marginLeft: 20 }}
                                    >
                                        <DatePicker onChange={formChangeHandler} placeholder="Derīguma termiņš" />
                                    </Form.Item>
                                    <Form.Item
                                        name="first_aid_nr"
                                        labelCol={{
                                            span: 0,
                                        }}
                                        wrapperCol={{
                                            span: 21,
                                            offset: 4,
                                        }}
                                    >
                                        <Input placeholder="Numurs" />
                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row>
                {
                    csdd.admin
                    && <Title style={{ marginTop: 20 }} level={5}>
                        Pēdējās izmaiņas: {csdd?.admin?.first_name} {csdd?.admin?.last_name} {csdd?.admin?.first_name} {moment(csdd?.updated_at).format("DD.MM.YYYY HH:mm")}
                    </Title>
                }
            </Row>
        </Card>
    )
}