import { Button, Card, DatePicker, Modal, Row, Select, Space } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../../helpers/http";
import { StudentApplySlot } from "./StudentApplySlot";
import { AddSlotModal } from "./AddSlotModal/AddSlotModal";

// interface Slot {
//     student: String,
//     status: String,
//     category: String,
//     tries: number,
//     zoom_id: number,
//     zoom_link: String
// }

export const DayOverview = (props: any) => {
    const { examSlots, selectedDay, updateSlots, languages, categories, loading } = props;
    const [slots, setSlots] = useState([]);
    const [timeInterval, setTimeInterval] = useState("all");
    const [availablePeriods, setAvailablePeriods] = useState([] as any);
    const [zoomAccounts, setZoomAccounts] = useState([] as any);
    const [newSlotModalVisible, setNewSlotModalVisible] = useState(false);
    const [slotInfo, setSlotInfo] = useState({});
    const examIsOld = moment(selectedDay).isBefore(moment())


    const selectDaySlots = (time: String = "all") => {
        if (time === "all") {
            setSlots(() => {
                return examSlots.filter((slot: any) => {
                    return slot.exam_date === moment(selectedDay).format("YYYY-MM-DD");
                })
            })
        } else {
            const timeArr = time.split("-");
            setSlots(() => {
                return examSlots.filter((slot: any) => {
                    return slot.exam_date === moment(selectedDay).format("YYYY-MM-DD")
                        && slot.time_from === moment(timeArr[0], "HH:mm").format("HH:mm:ss")
                        && slot.time_to === moment(timeArr[1], "HH:mm").format("HH:mm:ss");
                })
            })
        }
    };



    const fetchZoomAccounts = () => {
        http.get(`rest/zoom-accounts`).then((response: any) => {
            setZoomAccounts(response.data.data)
        })
    };

    const deleteSlot = () => {
        Modal.confirm({
            title: "Dzēst visus teorijas eksāmena slotus šajā dienā",
            content: `Vai tiešām dzēst teorijas eksamena slotus - ${moment(selectedDay).format("YYYY-MM-DD")}?`,
            onOk() {
                http.post(`rest/cancel-slots`, {
                    cancel_date: moment(selectedDay).format("YYYY-MM-DD"),
                    skip_bought: 0,
                    skip_active: 0
                }).then((response: any) => {
                    updateSlots()
                })
            },
        })
    };

    useEffect(() => {
        const initSlots = () => {
            setSlots(() => {
                const filteredSlots = examSlots.filter((slot: any) => {
                    return slot.exam_date === moment(selectedDay).format("YYYY-MM-DD");
                });
                setAvailablePeriods(
                    filteredSlots.map((slot: any) => {
                        return {
                            value: slot.time_from + "-" + slot.time_to,
                            text: `${moment(slot.time_from, "HH:mm:ss").format("HH:mm")} - ${moment(slot.time_to, "HH:mm:ss").format("HH:mm")}`
                        }
                    })
                );
                setTimeInterval("all");
                return filteredSlots
            })
        };
        initSlots();
    }, [selectedDay, examSlots]);

    useEffect(() => {
        fetchZoomAccounts();
    }, [slots]);


    const timeAndDateFilter = <Space>
        <DatePicker
            value={selectedDay}
            onChange={(e) => {
                if (e === null) return;
                props.setSelectedDay(e);

            }}
        />
        <Select
            style={{ width: 160 }}
            onChange={(e) => {
                if (e === null) return;
                selectDaySlots(e);
                setTimeInterval(e);
            }}
            value={timeInterval}
        >
            <Select.Option value={"all"}>Visi laiki</Select.Option>
            {
                availablePeriods?.map((period: any, index: any) => {
                    return <Select.Option key={index} value={period.value}>{period.text}</Select.Option>
                })
            }
        </Select>
        <Button
            type="text"
            onClick={() => {
                setNewSlotModalVisible(true);
            }}
        >
            <PlusCircleOutlined />
            Pievienot papildus slotu
        </Button>
    </Space>

    return <Card
        title={timeAndDateFilter}
    >
        {
            slots?.map((slot: any, index: any) => {
                return <Card
                    style={{ marginBottom: 20 }}
                    title={<b style={{ fontSize: 14 }}>
                        {moment(slot.exam_date).format("DD.MM.YYYY")}&nbsp;
                        {moment(slot.time_from, "HH:mm:ss").format("HH:mm")}&nbsp;-&nbsp;
                        {moment(slot.time_to, "HH:mm:ss").format("HH:mm")}
                    </b>}
                    key={index}
                >
                    {
                        slot.student_slots.map((studentSlot: any, index: any) => {
                            return <StudentApplySlot
                                zoomAccount={zoomAccounts} //te pēc tam samainīs
                                key={index}
                                slot={studentSlot}
                                updateSlots={() => updateSlots()}
                                languages={languages}
                                categories={categories}
                                loading={loading}
                            />
                        })
                    }
                    <Button
                        type="text"
                        onClick={() => {
                            setNewSlotModalVisible(true);
                            setSlotInfo(slot.student_slots[0])
                        }}
                    >
                        <PlusCircleOutlined />
                        Pievienot papildus slotu
                    </Button>
                    {/* <AddSlotModal
                        day={selectedDay}
                        slot={slotInfo}
                        visible={newSlotModalVisible}
                        setVisible={(visible: any) => setNewSlotModalVisible(visible)}
                        languages={languages}
                        updateSlots={() => updateSlots()}
                    /> */}
                </Card>
            })
        }
        {
            examIsOld
                ? <></>
                : <Row justify="end">
                    <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={deleteSlot}
                    />
                </Row>
        }
        <AddSlotModal
            slot={slotInfo}
            day={selectedDay}
            visible={newSlotModalVisible}
            setVisible={(visible: any) => setNewSlotModalVisible(visible)}
            languages={languages}
            updateSlots={() => updateSlots()}
        />
    </Card>
}