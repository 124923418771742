import { Button, Card, Checkbox, Col, Modal, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { ActionsRow } from "./ActionsRow";
import { ApproveStudentRow } from "./ApproveStudentRow";
import { AttainedExamsRow } from "./AttainedExamsRow";
import { ExamResultForm } from "./ExamResultForm";

interface CompProps {
    modalVisible: boolean,
    setModalVisible: Function,
    state: "settings" | "results",
    student: any,
    slot: any,
    updateSlots: any
}

export const StudentSettingsModal = (props: CompProps) => {
    const { modalVisible, setModalVisible, state, student, slot, updateSlots } = props;
    const [allowApprove, setAllowApprove] = useState(false);
    const examTry = slot?.student?.exams?.findIndex((el: any) => {
        return el.id === slot.id
    });
    return <Modal
        visible={modalVisible}
        onOk={() => setModalVisible(!modalVisible)}
        onCancel={() => setModalVisible(!modalVisible)}
        width={700}
        footer={null}
        bodyStyle={{ padding: "40px 50px 50px 50px" }}
    >
        <Row style={styles.row}>
            <Col span={8} style={styles.col}>
                <p style={styles.paragraph}>Students:</p>
                <b>{student?.full_name}</b>
            </Col>
            <Col span={8} style={styles.col}>
                <p style={styles.paragraph}>Personas kods:</p>
                <b>{student?.personal_identity_number}</b>
            </Col>
            <Col span={8} style={styles.col}>
                <p style={styles.paragraph}>Tālrunis:</p>
                <b>{student?.phone_number}</b>
            </Col>
            <Col span={8} style={styles.col}>
                <p style={styles.paragraph}>E-pasta adrese:</p>
                <b>{student?.email}</b>
            </Col>
            <Col span={4} style={styles.col}>
                <p style={styles.paragraph}>Kategorija:</p>
                <b>{student?.category}</b>
            </Col>
            <Col span={4} style={styles.col}>
                <p style={styles.paragraph}>Mēģinājums:</p>
                <b>{examTry + 1}</b>
            </Col>
            {
                !!student?.study_group
                    ? <Col span={8} style={styles.col}>
                        <p style={styles.paragraph}>Grupa:</p>
                        <b>{student?.study_group}</b>
                    </Col>
                    : <></>
            }
        </Row>
        {
            state === "settings"
                ? <>
                    {
                        slot.status !== "student_added"
                            ? <></>
                            : <ApproveStudentRow setApprove={(allow: boolean) => setAllowApprove(allow)} />
                    }
                    <AttainedExamsRow
                        slot={slot}
                    />
                    {
                        slot.status !== "student_added"
                            ? <ActionsRow
                                allowApprove={allowApprove}
                                slotID={slot.id}
                                closeModal={() => setModalVisible(false)}
                                updateSlots={() => updateSlots()}
                                onlyCancel
                            />
                            : <ActionsRow
                                allowApprove={allowApprove}
                                slotID={slot.id}
                                closeModal={() => setModalVisible(false)}
                                updateSlots={() => updateSlots()}
                            />
                    }
                </>
                : <ExamResultForm
                    slot_id={slot.id}
                    closeModal={() => setModalVisible(false)}
                    updateSlots={() => updateSlots()}
                />
        }

    </Modal>
}

const styles = {
    paragraph: {
        marginBottom: 6
    },
    col: {
        paddingBottom: 24
    },
    row: {
        borderBottom: "1px solid #D8D8D8"
    },
}