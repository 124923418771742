import { Typography, Button, Card, Col, DatePicker, Form, Input, InputNumber, Layout, PageHeader, Row, Select, Skeleton, TimePicker, Space, message, Tabs, Checkbox } from "antd";
import React, { useState } from "react";
import { Link, Redirect, RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import { Services } from "./Services";
import { TeachersHandler } from "./TeachersHandler";
import { CheckpointsForm } from "./CheckpointsForm";
import moment from "moment";
import { FormInstance } from "antd/lib/form";
import { EditLessonsSchedule } from "./EditLessonsSchedule";
import { v4 as uuidv4 } from "uuid"

export type GroupTemplateProps = RouteComponentProps & {
};

export type GroupTemplateState = {
    submitting: boolean,
    classifiers: any,
    loaded: boolean,
    services: any,
    checkpoints: any,
    course_type: "Klātienes" | "Tiešsaistes" | "Online apmācība" | "E-apmācība" | null,
    form_data: {},
    lessons_visible: boolean,
    lessons: any,
    saveTrigger: number,
    servicesSet: boolean,
    checkpointsSet: boolean,
    data: any,
    isNew: boolean,
    groupID: any,
    selectedCities: []
};

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

export class GroupTemplatePage extends React.Component<GroupTemplateProps, GroupTemplateState>
{
    constructor(props: GroupTemplateProps) {
        super(props);
        const template: any = this.props.match.params;
        this.state = {
            classifiers: {
                languages: [],
                cities: [],
                categories: [],
                course_type: [],
            },
            loaded: false,
            submitting: false,
            services: [],
            checkpoints: [],
            course_type: null,
            form_data: {},
            lessons_visible: false,
            lessons: [],
            saveTrigger: 0,
            servicesSet: false,
            checkpointsSet: false,
            data: {},
            isNew: template.id ? false : true,
            groupID: undefined,
            selectedCities: []
        }
    }

    form = React.createRef<FormInstance>();

    async loadClassifiers() {
        const languages = await http.get("/rest/languages");
        const cities = await http.get("/rest/cities");
        const categories = await http.get("/rest/categories");
        const course_type = await http.get("/rest/study-groups-types");
        this.setState({
            classifiers: {
                languages: languages.data.data,
                cities: cities.data.data,
                categories: categories.data.data,
                course_type: course_type.data.data,
            },
            submitting: false,
        });
    }

    fetchData = async () => {
        const template: any = this.props.match.params;
        if (template.id !== "new") {
            await http.get(`/rest/study-groups-templates/${template.id}`).then(response => {
                console.log(response.data)
                const data = response.data.data;
                const cities = data.group.cities.map((city: any) => {
                    return city.id;
                });
                this.setState(
                    {
                        selectedCities: cities,
                        data: data.group,
                        checkpoints: data.group.checkpoints,
                        services: data.group.services,
                        groupID: data.study_group_id,
                        course_type: this.state.classifiers.course_type.filter((type: any) => {
                            return type.id === data.group.study_type_id;
                        })[0].value,
                    });
            });
        }
    }

    componentDidMount() {
        console.log(this.props.match)
        this.loadClassifiers().then(() => {
            !this.state.isNew ? this.fetchData() : this.setState({ loaded: true });
        });
    };

    componentDidUpdate = (prevProps: any, prevState: any) => {
        console.log(this.state)
        if (prevState.data !== this.state.data) {
            this.setState({ loaded: true });
        }
        if (this.state.servicesSet && this.state.checkpointsSet) {
            this.setState({ servicesSet: false, checkpointsSet: false })
            this.processData(this.state.data)
        }
    }

    onFinish = (values: any) => {
        console.log(values)
        this.setState({
            saveTrigger: this.state.saveTrigger + 1,
            data:
            {
                ...values,
            }
        })
    };

    processData = (values: any, save: boolean = true) => {
        let newCities = [];
        if (this.state.course_type === "Klātienes" || this.state.course_type === "Online apmācība") {
            newCities = values?.cities?.map((city: any) => {
                return {
                    [city]: {
                        registration_fee: values[`registration_fee-${city}`],
                        registration_discount: values[`registration_discount-${city}`],
                    }
                }
            })

        }

        values.cities = newCities;
        console.log(values)
        //checkpoint relations
        let checkpointData = Array();
        this.state.checkpoints.map((checkpoint: any) => {
            checkpointData.push(checkpoint);
        });
        values.checkpoints = checkpointData;
        //checkpoint relations
        let serviceData = Array();
        this.state.services.map((service: any) => {
            serviceData.push(service);
        });
        values.services = serviceData;
        values.template = "yes";
        values.start_time = moment(values.start_end_time[0]).format("HH:mm");
        values.end_time = moment(values.start_end_time[1]).format("HH:mm");

        values.group_for_statistic_survey = values.group_for_statistic_survey ? 1 : 0;
        values.real_elearning = values.real_elearning ? 1 : 0;
        values.fake_elearning = values.fake_elearning ? 1 : 0;
        values.foreign_school_template = values.foreign_school_template ? 1 : 0
        console.log(values)
        if (save) {
            this.saveGruop(values)
            return null;
        } else {
            // values.show_web = this.state.data.show_online
            return values;
        }
    }

    saveGruop = (values: any) => {
        if (this.state.isNew) {
            http.post(`/rest/study-groups-templates`, values).then((response) => {
                this.props.history.replace(`/groups/templates/${response.data.data.id}`);
                window.location.reload();
            });
        } else {
            const template: any = this.props.match.params;
            values.sg_template_id = template.id;
            console.log(values)
            http.patch(`/rest/study-groups-templates/${this.state.data.sg_template_id}`, values).then((response) => {
                this.fetchData();
                message.success("Izmaiņas saglabātas");
            }).catch(() => {
                message.error("Kļūda");
            });
        }
    }

    handleFormData = (changedVal: any, values: any) => {
        this.setState({ form_data: values })
    }

    onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    handleCourseChange = (event: any) => {
        console.log(event)
        this.setState({
            course_type: this.state.classifiers.course_type.filter((type: any) => {
                return type.id === event;
            })[0].value
        });
    }

    saveServices = (services: []) => {
        this.setState({ services: services, servicesSet: true })
    }

    saveCheckpoints = (cp: []) => {
        this.setState({ checkpoints: cp, checkpointsSet: true })
    }

    makeGroup = () => {
        console.log(this.form.current?.getFieldsValue());
        const template: any = this.props.match.params;
        const values = this.processData(this.form.current?.getFieldsValue(), false);
        if (!values) return;
        values.sg_template_id = template.id
        console.log(values)
        http.post(`/rest/study-groups-templates/create-from-template/${template.id}`, values).then((response) => {
            //no response iegūs jauno grupu un tad var veikt redirektu uz mācību grupas edit
            this.props.history.replace(`/groups/edit/${response.data.data.id}`);
            console.log(response)
        });
    }

    toggleLessonsForm = () => {
        this.state.lessons_visible
            ? this.setState({ lessons_visible: false })
            : this.setState({ lessons_visible: true });
    }

    saveLessons = (lessons: any) => {
        this.setState({ data: { ...this.state.data, lessons: lessons } });
    }

    render() {
        return (
            <>
                {!this.state.loaded ?
                    <Card style={{ width: "100%" }}>
                        <Skeleton />
                    </Card>
                    :
                    <Layout>
                        <Row>
                            {/* <Button onClick={() => console.log(this.props)}>Console.Log</Button> */}
                            <PageHeader
                                style={{ padding: "15px 0 0 15px" }}
                                onBack={this.props.history.goBack}
                                title={
                                    <Title level={4} style={{ marginBottom: 0 }}>
                                        {!this.state.isNew
                                            ? <>
                                                {`Sagatave: ${this.state.data.name}`}
                                                <Button
                                                    onClick={this.makeGroup}
                                                    type="primary"
                                                    style={{
                                                        backgroundColor: this.state.data.foreign_school_template ? "#dbdbdb" : "#3ac700",
                                                        borderColor: this.state.data.foreign_school_template ? "#dbdbdb" : "#3ac700",
                                                        marginLeft: 30
                                                    }}
                                                    disabled={this.state.data.foreign_school_template}
                                                >
                                                    Izveidot grupu
                                                </Button>
                                            </>
                                            : `Izveidot jaunu grupas sagatavi`}
                                    </Title>
                                }
                            />
                        </Row>
                        <Row>
                            <Card style={{ width: "100%", margin: 15 }}>
                                <Form
                                    ref={this.form}
                                    layout="vertical"
                                    onFinish={this.onFinish}
                                    onValuesChange={this.handleFormData}
                                    initialValues={
                                        this.state.data.hasOwnProperty('id') ? {
                                            category_id: this.state.data.category.id,
                                            name: this.state.data.name,
                                            study_type_id: this.state.data.study_type_id,
                                            languages: this.state.data.languages.map((lang: any) => lang.id),
                                            cities: this.state.data.cities.map((city: any) => city.id),
                                            start_end_time:
                                                this.state.data.start_time !== null ?
                                                    [moment(this.state.data.start_time, "HH:mm"), moment(this.state.data.end_time, "HH:mm")]
                                                    : [null, null],
                                            planned_slots: this.state.data.planned_slots,
                                            planned_lessons: this.state.data.planned_lessons,
                                            extra_slots: this.state.data.extra_slots,
                                            extra_slots_price: this.state.data.extra_slots_price,
                                            show_web: this.state.data.show_online ? 1 : 0,
                                            csdd_nr: "yes",
                                            status_id: "yes",
                                            registration_fee: this.state.data.registration_fee,
                                            registration_discount: this.state.data.registration_discount,
                                            registration_vat: this.state.data.registration_vat,
                                            comment_lv: this.state.data.comment_lv,
                                            comment_en: this.state.data.comment_en,
                                            comment_ru: this.state.data.comment_ru,
                                            description_lv: this.state.data.description_lv,
                                            description_en: this.state.data.description_en,
                                            description_ru: this.state.data.description_ru,
                                            group_for_statistic_survey: this.state.data.group_for_statistic_survey,
                                            real_elearning: this.state.data.real_elearning,
                                            fake_elearning: this.state.data.fake_elearning,
                                            foreign_school_template: this.state.data.foreign_school_template
                                        }
                                            : {}
                                    }
                                >
                                    <Row gutter={[2, 24]}>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="category_id"
                                                label="Kategorija"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <Select style={{ width: 200 }}>
                                                    {this.state.classifiers.categories.map((category: any) => {
                                                        return <Option key={category.id} value={category.id}>{category.value}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="name"
                                                label="Nosaukums"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <Input style={{ width: 200 }} />
                                                {/* <Select style={{ width: 200 }}>
                            {services.map((service: any) => {
                              return <Option key={service.id} value={service.name}>{service.name}</Option>
                            })}
                          </Select> */}
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="study_type_id"
                                                label="Apmācību veids"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <Select style={{ width: 200 }} onChange={this.handleCourseChange}>
                                                    {this.state.classifiers.course_type.map((type: any) => {
                                                        return <Option key={type.id} value={type.id}>{type.value}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="languages"
                                                label="Valoda"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <Select style={{ width: 200 }} mode="multiple">
                                                    {this.state.classifiers.languages.map((language: any) => {
                                                        return <Option key={language.id} value={language.id}>{language.value}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {this.state.course_type === "Tiešsaistes" ? <></> : this.state.course_type === "E-apmācība" ? <></> :
                                            <Col md={12} lg={8}>
                                                <Form.Item
                                                    name="cities"
                                                    label="Pilsēta"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Šis lauks ir obligāts',
                                                //     },
                                                // ]}
                                                >
                                                    <Select style={{ width: 200 }} mode="multiple" onChange={(e: any) => this.setState({ selectedCities: e })}>
                                                        {this.state.classifiers.cities.map((city: any) => {
                                                            return <Option key={city.id} value={city.id}>{city.value}</Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        }
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="start_end_time"
                                                label="Nodarbības sākuma un beigu laiks"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <TimePicker.RangePicker
                                                    format="HH:mm"
                                                    style={{ width: 200 }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="planned_slots"
                                                label="Maksimālais studentu skaits"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <InputNumber style={{ width: 200 }} min={1} />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="extra_slots"
                                                label="Papildus studentu skaits"
                                            >
                                                <InputNumber style={{ width: 200 }} min={0} />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="extra_slots_price"
                                                label="Papildus studenta lekcijas cena"
                                            >
                                                <InputNumber style={{ width: 200 }} min={0} />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="planned_lessons"
                                                label="Nodarbību skaits"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <InputNumber style={{ width: 200 }} min={1} />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <Form.Item
                                                name="show_web"
                                                label="Publicēt WEB"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šis lauks ir obligāts',
                                                    },
                                                ]}
                                            >
                                                <Select style={{ width: 200 }}>
                                                    <Option value={1}>Jā</Option>
                                                    <Option value={0}>Nē</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Item valuePropName="checked" name="group_for_statistic_survey" label="Grupa statistikai">
                                                <Checkbox />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Item valuePropName="checked" name="real_elearning" label="Einšteins online apmācība">
                                                <Checkbox />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Item valuePropName="checked" name="fake_elearning" label="Grupa klātienes nodarbībām (individuāla grupa)">
                                                <Checkbox />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Item valuePropName="checked" name="foreign_school_template" label="Grupas sagatave skolām">
                                                <Checkbox />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Card title="Reģistrācija">
                                                <Row gutter={24}>
                                                    <Col span={8}>
                                                        <Row gutter={16}>
                                                            <Col span={8}>
                                                                <Form.Item name="registration_fee" label="Cena (ar PVN)">
                                                                    <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={8}>
                                                                <Form.Item name="registration_discount" label="Atlaides cena">
                                                                    <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={8}>
                                                                <Form.Item name="registration_vat" label="PVN %">
                                                                    <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                                </Form.Item>
                                                            </Col>
                                                            {
                                                                this.state.selectedCities.map((cityId: any) => {
                                                                    const data = this.state.data.cities?.filter((city: any) => {
                                                                        // console.log(data)
                                                                        return city.id === cityId;
                                                                    })[0];
                                                                    return (
                                                                        <Row key={cityId} gutter={16}>
                                                                            <h3 style={{ width: "100%", borderBottom: "1px solid #f0f0f0" }}>
                                                                                {this.state.classifiers.cities.filter((city: any) => {
                                                                                    return city.id === cityId;
                                                                                })[0].value}
                                                                            </h3>
                                                                            <Col span={12}>
                                                                                <Form.Item initialValue={data?.pivot.registration_fee} name={`registration_fee-${cityId}`} label="Cena">
                                                                                    <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col span={12}>
                                                                                <Form.Item initialValue={data?.pivot.registration_discount} name={`registration_discount-${cityId}`} label="Atlaides cena">
                                                                                    <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Tabs style={{ marginLeft: 30 }} defaultActiveKey="1">
                                                            <TabPane tab="LV" key="1" forceRender>
                                                                <Form.Item name="comment_lv" label="Komentārs latviešu valodā">
                                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                                </Form.Item>
                                                            </TabPane>
                                                            <TabPane tab="EN" key="2" forceRender>
                                                                <Form.Item name="comment_en" label="Komentārs angļu valodā">
                                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                                </Form.Item>
                                                            </TabPane>
                                                            <TabPane tab="RU" key="3" forceRender>
                                                                <Form.Item name="comment_ru" label="Komentārs krievu valodā">
                                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                                </Form.Item>
                                                            </TabPane>
                                                        </Tabs>
                                                        <Tabs style={{ marginLeft: 30 }} defaultActiveKey="1">
                                                            <TabPane tab="LV" key="1" forceRender>
                                                                <Form.Item name="description_lv" label="Apraksts latviešu valodā">
                                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                                </Form.Item>
                                                            </TabPane>
                                                            <TabPane tab="EN" key="2" forceRender>
                                                                <Form.Item name="description_en" label="Apraksts angļu valodā">
                                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                                </Form.Item>
                                                            </TabPane>
                                                            <TabPane tab="RU" key="3" forceRender>
                                                                <Form.Item name="description_ru" label="Apraksts krievu valodā">
                                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                                </Form.Item>
                                                            </TabPane>
                                                        </Tabs>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                        <Col span={24} >
                                            <Form.Item>
                                                <Button htmlType="submit" type="primary">
                                                    Saglabāt
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                            {this.state.isNew ? <></> : <Card style={{ width: "100%", margin: 15 }}>
                                <Button onClick={this.toggleLessonsForm} style={{ display: "block", width: "100%" }}>Nodarbību grafiks</Button>
                                {this.state.lessons_visible
                                    ? <EditLessonsSchedule
                                        groupID={this.state.groupID}
                                        lessons={this.state.isNew ? [] : this.state.data.lessons}
                                        saveLessons={this.saveLessons}
                                        updateLessons={this.saveLessons}
                                        groupData={this.state.data}
                                    />
                                    : <></>}
                            </Card>}
                            <CheckpointsForm
                                getCheckpoints={this.saveCheckpoints}
                                save={this.state.saveTrigger}
                                cp={this.state.data.checkpoints}
                            />
                            <Services
                                cities={this.state.classifiers.cities}
                                getServices={this.saveServices}
                                save={this.state.saveTrigger}
                                services={this.state.data.services}
                            />
                        </Row>
                    </Layout>}
            </>
        )
    }
}

export const GroupTemplate = withRouter(GroupTemplatePage)