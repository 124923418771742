import { Row, Form, Input, DatePicker, TimePicker, Col, Card, Button, message } from "antd"
import React, { useEffect, useState } from "react"
import { http } from "../../helpers/http";
import moment from "moment";

type StudentCreditProps = {
    student: any;
}

export const StudentCredit = ({ student }: StudentCreditProps) => {
    const [creditError, setCreditError] = useState(null as string | null);
    const [creditValues, setCreditValues] = useState({
        creditTillDate: null as null | moment.Moment,
        creditTillTime: null as null | moment.Moment,
        credit: undefined as undefined | string
    });
    const [loading, setLoading] = useState(false);

    const giveCredit = () => {
        if (!creditValues.creditTillDate || !creditValues.creditTillTime) return;
        setLoading(true);
        const creditTill = creditValues.creditTillDate !== undefined
            ? moment(creditValues.creditTillDate).format('YYYY-MM-DD')
            + " "
            + moment(creditValues.creditTillTime, "HH:mm:ss").format("HH:mm:ss")
            : undefined
        http.post(`/rest/student-give-credit/${student.id}`, {
            credit: creditValues.credit,
            credit_till: creditTill
        }).then(() => {
            http.patch(`/rest/students/${student.id}`, { credit: creditValues.credit, credit_till: creditTill }).then(() => {
                message.success("Kredīts ir piešķirts!");
                setCreditError(null);
                setLoading(false);
            }).catch((err: any) => {
                message.error("Neizdevās saglabāt");
                setCreditError(err.response.data.message)
                setLoading(false);
            })
        }).catch((err: any) => {
            message.error("Neizdevās saglabāt");
            setCreditError(err.response.data.message)
            setLoading(false);
        });
    }

    useEffect(() => {
        if (Object.keys(student).length > 0) {
            setCreditValues({
                creditTillDate: student.credit_till ? moment(student.credit_till, "YYYY-MM-DD HH:mm:ss") : null,
                creditTillTime: student.credit_till ? moment(student.credit_till, "YYYY-MM-DD HH:mm:ss") : null,
                credit: student.credit
            })
        }
    }, [student])

    return <Card
        style={{ marginBottom: 20 }}
        title={<strong>Kredītlīnija</strong>}
        loading={Object.keys(student).length === 0}
    >
        <Row gutter={20}>
            <Col>
                <label>Kredītlīnija</label>
                <Input
                    value={creditValues.credit}
                    style={{ width: 120, marginLeft: 8 }}
                    onChange={(e) => {
                        e.persist();
                        setCreditValues((prev: any) => {
                            return {
                                ...prev,
                                credit: e.target.value
                            }
                        })
                    }}
                />
            </Col>
            <Col>
                <label>Līdz:</label>
                <DatePicker
                    value={creditValues.creditTillDate}
                    style={{ marginLeft: 8 }}
                    onChange={(e) => {
                        console.log(e)
                        setCreditValues((prev: any) => {
                            return {
                                ...prev,
                                creditTillDate: e
                            }
                        })
                    }}
                />
            </Col>
            <Col>
                <label></label>
                <TimePicker
                    value={creditValues.creditTillTime}
                    onChange={(e) => {
                        console.log(e)
                        setCreditValues((prev: any) => {
                            return {
                                ...prev,
                                creditTillTime: e
                            }
                        })
                    }}
                />
            </Col>
            <Col>
                <Button
                    type="primary"
                    onClick={giveCredit}
                    loading={loading}
                >
                    Piemērot
                </Button>
            </Col>
        </Row>
        {
            creditError !== null
                ? <b style={{ color: "red" }}>{creditError}</b>
                : <></>
        }
    </Card>
}