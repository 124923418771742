import { Card, Layout, PageHeader, Spin } from "antd";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../helpers/http";
import CategoryCityTermSearchExceptionForm from "./CategoryCityTermSearchExceptionForm";

export const CityInstructorApperanceRestrictions = () => {
    const history = useHistory();
    const [exceptions, setExceptions] = useState([] as any);
    const [cities, setCities] = useState([] as any);
    const [categories, setCategories] = useState([] as any);
    const [loading, setLoading] = useState(true);

    const getExceptions = () => {
        setExceptions([]);
        http.get(`rest/busy-instructor-category-city-terms`).then((res: AxiosResponse) => {
            setExceptions(res.data.data)
            setCities(res.data.cities)
            setCategories(res.data.categories)
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        getExceptions();
    }, [])

    return <>
        {
            loading
            && <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(200, 200, 200, .3)", zIndex: 100 }} >
                <Spin spinning={loading} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            </div>
        }
        <Card style={{ margin: 20 }}>
            <p><b>* Jauniem studentiem netiek attēloti instruktori, kuriem tuvāko 3 nedēļu laikā nav pieejamas nodarbības. Šajā sadaļā ir iespējams individuāli pilsētai un kategorijai uzstādīt to cik ilgi (dienās) aizņemta instruktora nodarbības netiks attēlotas jauniem studentiem.</b></p>
            <CategoryCityTermSearchExceptionForm
                categories={categories}
                cities={cities}
                exception={undefined}
                refresh={getExceptions}
                setLoading={setLoading}
            />
            {
                exceptions.map((exception: any) => <CategoryCityTermSearchExceptionForm
                    key={exception.id}
                    categories={categories}
                    cities={cities}
                    exception={exception}
                    refresh={getExceptions}
                    setLoading={setLoading}
                />)
            }
        </Card>
    </>
}