import { Button, Card, Select } from "antd"
import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { http } from "../../helpers/http"
import { GroupsLoaderSelect } from "./GroupsLoaderSelect"

type GroupSettingsProps = {
    student: any,
    refreshStudent: () => void;
}

export const GroupSettings = ({ student, refreshStudent }: GroupSettingsProps) => {
    const { id }: { id: string } = useParams();
    const [signedToGroups, setSignedToGroups] = useState([] as any);
    const [loading, setLoading] = useState(false);

    const getGroups = () => {
        setLoading(true);
        http.get(`/rest/student-study-group/${id}`).then((response: any) => {
            if (response.data.length > 0) {
                setSignedToGroups(response.data)
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        getGroups();
    }, [])

    return <div>
        <Card
            style={{ marginBottom: 20 }}
            title={<strong>Grupas</strong>}
            loading={loading}
        >
            {
                student.categories.map((category: any) => {
                    const group = signedToGroups?.find((group: any) => group.id === category.study_group_id)

                    return <div style={{ marginBottom: 20 }} key={category.id}>
                        <h3><b>{category.category} kategorija</b></h3>
                        {
                            group === undefined || Number(process.env.REACT_APP_NO_GROUP_ID) === category.study_group_id
                                ? <div>
                                    <GroupsLoaderSelect
                                        student={student}
                                        category={category}
                                        refresh={async () => { await refreshStudent(); getGroups() }}
                                    />
                                </div>
                                : <p><b>{group.name} {group.start_date}</b></p>

                        }
                    </div>
                })
            }
            <Link
                to={{
                    pathname: `/groups/student-opening/${id}`,
                    state: {
                        groups: signedToGroups
                    }
                }}
            >
                <Button
                    type="primary"
                >
                    {signedToGroups?.some((el: any) => el.id !== Number(process.env.REACT_APP_NO_GROUP_ID)) ? "Atvērt" : "CRM"}
                </Button>
            </Link>
        </Card>
    </div>
}