import { Button, Card, Checkbox, Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";

export const NotificationSettings = (props: any) => {
    const { student } = props;
    const [loading, setLoading] = useState(true);
    const [channels, setChannels] = useState({
        ...student.notification_channels
    });

    const channelsTrans = {
        db: "Portāls",
        email: "E-pasts",
        sms: "SMS",
        whatsapp: "Whatsapp"
    } as any;

    const saveChannels = async () => {
        await http.post(`rest/set-student-notification-channels/${student.id}`, channels).then((response: any) => {
            message.success("Paziņojuma kanāli ir saglabāti")
        }).catch((err: any) => {
            message.error("Kļūda!");
        })
    };

    useEffect(() => {
        if (Object.keys(student).length > 0) {
            setChannels(student.notification_channels)
            setLoading(false)
        }
    }, [student]);

    return <Card
        title={<strong>Studenta paziņojumu uzstādījumi</strong>}
        style={{ marginBottom: 20 }}
        loading={loading}
    >
        <Row>
            <Col span={24} style={{ paddingBottom: 10 }}>
                <b>Paziņojumu kanāli</b>
            </Col>
            {
                channels
                    ? Object.keys(channels)?.map((key: any) => {
                        return <div key={key} style={{ marginRight: 15 }}>
                            <span>{channelsTrans[key]}:</span>
                            <Checkbox
                                checked={channels[key]}
                                style={{ marginLeft: 5 }}
                                onChange={(e: any) => {
                                    setChannels((prev: any) => {
                                        return {
                                            ...prev,
                                            [key]: e.target.checked ? 1 : 0
                                        }
                                    })
                                }}
                            />
                        </div>
                    })
                    : <></>
            }

        </Row>
        <Row justify="end">
            <Button type="primary" onClick={saveChannels}>
                Saglabāt
            </Button>
        </Row>
    </Card>
}