import { Card, Row, Col, Button, Input, Radio, Tabs, message } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../../../helpers/http";

export const ImgUploader = (props: any) => {
    const { uniqueId, ind } = props;

    const [image, setImage] = useState(props.data.img);
    const [qImageURL, setQImageURL] = useState("" as string);

    const triggerupload = (link: string) => {
        document.getElementById(link)?.click();
    }

    const getFile = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImage(reader.result)
            props.returnImage(reader.result, ind)
            console.log("ONLOAD")
        };
        reader.onerror = (error) => {
            console.log("ERROR ", error)
        }
    }

    useEffect(() => {
        setQImageURL(() => {
            if (props.data.img !== null) {
                return process.env.REACT_APP_API_URL + props.data.public_image
            }
        })
    }, []);

    useEffect(() => {
        if (image === null) {
            setQImageURL("");
            return;
        }
        if (image?.includes("base64")) {
            setQImageURL(image)
        }
    }, [image]);

    return <>
        <Button
            style={{ marginBottom: 10 }}
            icon={<UploadOutlined />}
            onClick={() => triggerupload(`upload-question-img-${uniqueId}`)}
        >
            {
                qImageURL.length > 0
                    ? "Mainīt attēlu"
                    : "Pievienot attēlu"
            }
        </Button>
        {
            qImageURL.length > 0
                ? <Button
                    danger
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                        setImage(null);
                        props.returnImage(null, ind)
                    }}
                >
                    <DeleteOutlined />
                </Button>
                : <></>
        }

        <input
            type="file"
            id={`upload-question-img-${uniqueId}`}
            style={{ display: "none" }}
            accept="image/*"
            onChange={(e: any) => {
                e.persist();
                getFile(e);
                e.target.value = "";
            }}
        />
        {
            qImageURL.length === 0
                ? <></>
                : <div className="question-img-wrap">
                    <img src={qImageURL} />
                </div>

        }
    </>
}

export const QuestionCard = (props: any) => {
    const { qind, question, languages, apiResource, videoQuestion, refresh } = props;

    const [data, setData] = useState({ ...question } as any);
    const [correctAnswer, setCorrectAnswer] = useState(0);

    const addAnswer = (ind: any) => {
        setData((prev: any) => {
            return {
                ...prev,
                answers: [
                    ...prev.answers,
                    {
                        answer_lv: null,
                        answer_en: null,
                        answer_ru: null,
                        description_lv: null,
                        description_en: null,
                        description_ru: null,
                        correct: undefined,
                        img: null
                    }
                ]
            }
        })
    }

    const removeAnswer = (ind: any) => {
        setData((prev: any) => {
            return {
                ...prev,
                answers: prev.answers.filter((answer: any, index: any) => {
                    return index !== ind;
                })
            }
        })
    }

    const saveQuestion = () => {
        const dataCopy = { ...data };
        if (dataCopy.img !== null && !dataCopy.img.includes("base64")) {
            delete dataCopy.img;
        };
        const answers = dataCopy.answers.map((answer: any, index: any) => {
            if (index === correctAnswer) answer.correct = 1;
            else answer.correct = 0;
            if (answer.img !== null && !answer.img?.includes("base64")) {
                delete answer.img
            }
            return answer;
        });
        dataCopy.answers = answers;
        console.log(dataCopy)
        if (question.id === null) {
            http.post(apiResource, dataCopy).then((response: any) => {
                message.success("Jautājums ir saglabāts");
                refresh();
            }).catch(() => message.error("Kļūda!"));
        } else {
            http.patch(apiResource + "/" + question.id, dataCopy).then((response: any) => {
                message.success("Jautājums ir saglabāts (patch)")
            }).catch(() => message.error("Kļūda!"));
        }
    };

    const setImage = (image: any, index: any = undefined) => {
        if (index === undefined) {
            setData((prev: any) => {
                return {
                    ...prev,
                    img: image
                }
            })
        } else {
            setData((prev: any) => {
                const newAnswers = prev.answers.map((answer: any, ind: any) => {
                    if (ind === index) {
                        answer.img = image;
                    }
                    return answer;
                })
                return {
                    ...prev,
                    answers: newAnswers
                }
            })
        }

    }

    useEffect(() => {
        // setData(question)
        const correctAnswerInd = question.answers?.map((answer: any, index: any) => {
            if (answer.correct) return index;
            else return undefined;
        }).find((el: any) => el !== undefined)
        setCorrectAnswer(correctAnswerInd || 0)
    }, []);

    useEffect(() => {
        console.log(question)
    }, [question]);

    useEffect(() => {
        console.log(data)
    }, [data])

    useEffect(() => {
        setData((prev: any) => {
            const prevCopy = [...prev?.answers];
            const correctAnswerAdded = prevCopy.map((item: any, index: any) => {
                if (Number(correctAnswer) === Number(index)) {
                    return {
                        ...item,
                        correct: 1,
                    }
                } else {
                    return {
                        ...item,
                        correct: 0
                    }
                }
            });
            return {
                ...prev,
                answers: [...correctAnswerAdded]
            }
        })
    }, [correctAnswer])

    useEffect(() => {
        console.log(data)
    }, [data])

    return <Card>
        <Tabs defaultActiveKey="1">
            {
                languages.map((lang: any) => {
                    return <Tabs.TabPane key={lang.id} tab={lang.iso?.toUpperCase()} forceRender>
                        <Row gutter={[24, 16]}>
                            <Col span={12}>
                                <ImgUploader
                                    data={data}
                                    uniqueId={"question_" + qind}
                                    returnImage={setImage}
                                />
                                <b style={{ display: "block" }}>Jautājuma teksts:</b>
                                <Input.TextArea
                                    value={data[`description_${lang.iso}`]}
                                    style={{ marginTop: 15 }}
                                    autoSize={{ minRows: 5, maxRows: 5 }}
                                    onChange={(e: any) => {
                                        e.persist();
                                        setData((prev: any) => {
                                            return {
                                                ...prev,
                                                [`description_${lang.iso}`]: e.target.value
                                            }
                                        })
                                    }}
                                />
                                <b style={{ display: "block", marginTop: 30 }}>Jautājums:</b>
                                <Input.TextArea
                                    value={data[`question_${lang.iso}`]}
                                    style={{ marginTop: 15 }}
                                    autoSize={{ minRows: 2, maxRows: 2 }}
                                    onChange={(e: any) => {
                                        e.persist();
                                        setData((prev: any) => {
                                            return {
                                                ...prev,
                                                [`question_${lang.iso}`]: e.target.value
                                            }
                                        })
                                    }}
                                />
                                {
                                    videoQuestion
                                        ? <Row justify="space-between" align="middle" style={{ marginTop: 30 }}>
                                            <b>Laika ierobežojums atbildei (sekundēs): </b>
                                            <Input
                                                value={data.answer_time_limit}
                                                type="number"
                                                min={0}
                                                step={1}
                                                style={{ width: 120 }}
                                                onChange={(e: any) => {
                                                    e.persist();
                                                    setData((prev: any) => {
                                                        return {
                                                            ...prev,
                                                            answer_time_limit: e.target.value
                                                        }
                                                    })
                                                }}
                                            />
                                        </Row>
                                        : <></>
                                }

                            </Col>
                            <Col span={12}>
                                <h3>Atbilžu varianti</h3>
                                <Radio.Group
                                    value={correctAnswer}
                                    onChange={(e: any) => {
                                        setCorrectAnswer(e.target.value)
                                    }}
                                    style={{ width: "100%" }}
                                >
                                    {
                                        data?.answers?.map((answer: any, index: any) => {
                                            return <div key={index} style={{ display: "flex", width: "100%", margin: "5px 0" }}>
                                                <Radio
                                                    value={index}
                                                />
                                                <Card bodyStyle={{ padding: "8px" }} style={{ width: "100%" }}>
                                                    <div style={{ position: "relative" }}>
                                                        <ImgUploader
                                                            data={answer}
                                                            uniqueId={"answer_" + qind + "_" + index}
                                                            returnImage={setImage}
                                                            ind={index}
                                                        />
                                                        <Button
                                                            type="text"
                                                            danger
                                                            style={{ marginLeft: 10, position: "absolute", top: 0, right: 0 }}
                                                            onClick={(e: any) => removeAnswer(index)}
                                                            icon={<DeleteOutlined style={{ pointerEvents: "none" }}
                                                            />}>
                                                        </Button>
                                                        <Input.TextArea
                                                            value={answer[`answer_${lang.iso}`]}
                                                            autoSize={{ maxRows: 5, minRows: 5 }}
                                                            onChange={(e: any) => {
                                                                e.persist();
                                                                setData((prev: any) => {
                                                                    const prevCopy = { ...prev }
                                                                    prevCopy.answers[index] = {
                                                                        ...prev.answers[index],
                                                                        [`answer_${lang.iso}`]: e.target.value
                                                                    }
                                                                    return {
                                                                        ...prevCopy
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </Card>
                                            </div>
                                        })
                                    }
                                </Radio.Group>
                                <Row justify="end" style={{ marginTop: 15 }}>
                                    <Button onClick={addAnswer}>Pievienot atbildi +</Button>
                                </Row>
                                <Row justify="end" style={{ marginTop: 30 }}>
                                    {/* <Button onClick={() => console.log(question, data)}>test</Button> */}
                                    <Button onClick={saveQuestion} type="primary">Saglabāt</Button>
                                </Row>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                })
            }
        </Tabs>
    </Card>
}