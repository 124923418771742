import { Button, Card, Checkbox, Col, Space, Tag, message } from "antd"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { http } from "../../helpers/http";
import { ManualExamSignup } from "./ManualExamSignup";
import { AxiosResponse } from "axios";

type DrivingExamSettingsProps = {
    studentCategory: any;
    categories: any;
    student: any;
}

export const DrivingExamSettings = ({ studentCategory, categories, student }: DrivingExamSettingsProps) => {
    const { id }: { id: string } = useParams();
    const [examStats, setExamStats] = useState({
        activeExam: false,
        canGetExam: false,
        hadExam: 0,
        sentCsdd: 0,
        howMuchExams: 0
    });
    const [studentCsddInfo, setStudentCsddInfo] = useState(null as any);

    const getExamData = () => {
        const category = categories.find((cat: any) => cat.id === studentCategory.category_id)
        http.get(`/rest/student-progress/has-active-exam/${id}`, { params: { category: studentCategory.category_id } }).then((resp: any) => {
            // this.setState({ activeExam: resp.data ? true : false })
            setExamStats((prev: any) => {
                return {
                    ...prev,
                    activeExam: resp.data ? true : false
                }
            })
        });
        http.get(`/rest/student-progress/passed-lessons/${id}`, { params: { category: studentCategory.category_id } }).then((resp: any) => {
            const canExam = resp.data >= category?.min_driving_lessons
            // this.setState({ canGetExam: canExam })
            setExamStats((prev: any) => {
                return {
                    ...prev,
                    canGetExam: canExam
                }
            })
            console.log(canExam)
        });
        http.get(`/rest/student-progress/has-exam/${id}`, { params: { category: studentCategory.category_id } }).then((resp: any) => {
            const hadExam = resp.data?.passed
            const sentCsdd = resp.data?.sent_csdd
            console.log(resp.data)
            // this.setState({ hadExam: hadExam, sentCsdd: sentCsdd })
            setExamStats((prev: any) => {
                return {
                    ...prev,
                    hadExam: hadExam,
                    sentCsdd: sentCsdd
                }
            })

        });
        http.post(`/rest/student-progress/bulk-driven-exams/${studentCategory.category_id}`, { ids: [id] }).then((resp: any) => {
            console.log(resp)
            // this.setState({ howMuchExams: resp.data[1]?.exam_counts[id] || 0 })
            setExamStats((prev: any) => {
                return {
                    ...prev,
                    howMuchExams: resp.data[1]?.exam_counts[id] || 0
                }
            })
        });
    }

    const toCsddHandler = (passed: any) => {
        http.post(`/rest/student-progress/sent-csdd/${id}`, { change: passed, category: studentCategory.category_id }).then((response: any) => {
            // this.setState({ sentCsdd: response.data.sent_csdd })
            setExamStats((prev: any) => {
                return {
                    ...prev,
                    sentCsdd: response.data.sent_csdd
                }
            })
        });
    };

    const changeCsddRelation = (state: number) => {
        http.post(`rest/change-sent-to-csdd-relation/${id}/${studentCategory.category_id}/${state}`).then(() => {
            message.success("Status ir nomainīts!")
            getStudentCsddInfo();
        }).catch(() => {
            message.error("Kļūda!")
        })
    }

    const examPassedChanger = (passed: any) => {
        if (passed) {
            http.post(`/rest/student-progress/passed-exam/${id}`, { category: studentCategory.category_id }).then((response: any) => {
                // this.setState({ hadExam: true })
                setExamStats((prev: any) => {
                    return {
                        ...prev,
                        hadExam: true
                    }
                })
            })
        } else {
            http.post(`/rest/student-progress/failed-exam/${id}`, { category: studentCategory.category_id }).then((response: any) => {
                // this.setState({ hadExam: false })
                setExamStats((prev: any) => {
                    return {
                        ...prev,
                        hadExam: false
                    }
                })
            })
        }
    };

    const cancelExam = () => {
        http.post(`rest/student-progress/remove-exams/${id}`, { category: studentCategory.category_id }).then((response: any) => {
            console.log(response)
            // this.setState({ activeExam: false });
            setExamStats((prev: any) => {
                return {
                    ...prev,
                    activeExam: false
                }
            })
        })
    }

    const signToExam = () => {
        http.post(`rest/student-progress/create-exam/${id}`, { category: studentCategory.category_id }).then((response: any) => {
            console.log(response)
            // this.setState({ activeExam: true });
            setExamStats((prev: any) => {
                return {
                    ...prev,
                    activeExam: true
                }
            })
        })
    }

    const getStudentCsddInfo = () => {
        http.get(`rest/get-driving-csdd-allowance/${id}/${studentCategory.category_id}`).then((res: AxiosResponse) => {
            setStudentCsddInfo(res.data.data.length > 0 ? res.data.data[0] : null)
        })
    }

    useEffect(() => {
        if (categories.length > 0) {
            getExamData();
        }
    }, [categories])

    useEffect(() => {
        console.log(studentCategory)
        getStudentCsddInfo();
    }, [])

    return <Card
        title={<strong>Braukšanas eksāmeni</strong>}
    >
        <h3><b>Informācija par braukšanas eksāmena gaitu</b></h3>
        {
            studentCategory.got_licence > 0
                ? <Tag color="green">Ir iegūta vadītāja apliecība</Tag>
                : studentCsddInfo?.allow_send_to_csdd_without_school_exam
                    ? <div>
                        {
                            !studentCsddInfo?.sent_to_csdd_driving
                                ? <Col span={24} style={{ paddingBottom: 20 }}>
                                    <Checkbox
                                        style={{ marginRight: 10 }}
                                        onClick={(e: any) => changeCsddRelation(e.target.checked ? 1 : 0)}
                                    />
                                    <b>Novirzīt uz CSDD braukšanas eksāmenu</b>
                                </Col>
                                : <div>
                                    <Tag style={{ marginBottom: 20 }} color="green">Students novirzīts CSDD eksāmenam</Tag>
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        onClick={(e: any) => changeCsddRelation(0)}
                                    >
                                        Atcelt
                                    </Button>
                                </div>
                        }
                    </div>
                    : <div style={{ marginBottom: 10 }}><div>Kārtoti braukšanas eksāmeni: {examStats.howMuchExams}</div>
                        {
                            examStats.sentCsdd
                                ? <div>
                                    <Tag style={{ margin: "5px 0" }} color="green">Students ir pielaists CSDD braukšanas eksāmenam</Tag>
                                    <Button onClick={() => toCsddHandler(0)} style={{ marginLeft: 20 }}>Atcelt</Button>
                                </div>
                                : examStats.activeExam && examStats.howMuchExams < 3
                                    ? <div style={{ margin: "5px 0" }}>
                                        <Space>
                                            Students ir pieteikts skolas braukšanas eksāmenam
                                            <Button onClick={cancelExam} danger type="primary">Atcelt</Button>
                                        </Space>
                                    </div>
                                    : examStats.canGetExam && !examStats.hadExam && examStats.howMuchExams < 3
                                        ? <div style={{ margin: "5px 0" }}>
                                            <Button onClick={signToExam} type="primary">Pieteikt braukšanas eksāmenam</Button>
                                        </div>
                                        : <></>
                        }
                        {
                            examStats.hadExam
                                ? <b>Skolas braukšanas eksāmens ir nokārtots:
                                    <Button onClick={() => examPassedChanger(0)} style={{ marginLeft: 15 }} danger>Mainīt</Button><br />
                                    {
                                        !examStats.sentCsdd
                                            ? <Button onClick={() => toCsddHandler(1)} style={{ margin: "5px 0" }} danger>Novirzīt CSDD braukšanas eksāmenam</Button>
                                            : <></>
                                    }

                                </b>
                                : examStats.activeExam
                                    ? <b>Skolas braukšanas eksāmens nav nokārtots:
                                        <Button onClick={() => examPassedChanger(1)} style={{ marginLeft: 15 }} danger>Mainīt</Button></b>
                                    : <></>
                        }</div>

        }
        <ManualExamSignup category={studentCategory} studentId={id} />
    </Card >
}