import { Tabs } from "antd"
import React from "react"
import { DrivingBasicSettings } from './DrivingBasicSettings';
import { DrivingExamSettings } from "./DrivingExamSettings";
import { IndividualDrivingSettings } from "./IndividualDrivingSettings";

type DrivingSettingsProps = {
    student: any;
    categories: any;
}

export const DrivingSettings = ({ student, categories }: DrivingSettingsProps) => {

    return <Tabs>
        {
            student.categories.map((category: any) => {
                return <Tabs.TabPane tab={category.category} key={category.category_id}>
                    {
                        category.study_group_id === Number(process.env.REACT_APP_NO_GROUP_ID)
                        && <IndividualDrivingSettings
                            studentCategory={category}
                        />
                    }
                    <DrivingBasicSettings
                        studentCategory={category}
                        categories={categories}
                    />
                    <DrivingExamSettings
                        studentCategory={category}
                        categories={categories}
                        student={student}
                    />
                </Tabs.TabPane>
            })
        }
    </Tabs>
}