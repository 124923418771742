import { Button, Card, Collapse, Input, message, Row, Tabs } from "antd";
import { DeleteOutlined, SaveTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";

const { Panel } = Collapse;

export const FaqList = (props: any) => {
    const { languages, cid, faqs } = props;

    const [faqQuestions, setFaqQuestions] = useState([] as any);

    const addQuestion = () => {
        setFaqQuestions((prev: any) => {
            return [
                ...prev,
                {
                    e_chapter_id: Number(cid),
                    ind: prev.length,
                    id: null,
                    question_lv: null,
                    question_en: null,
                    question_ru: null,
                    answer_lv: null,
                    answer_en: null,
                    answer_ru: null,
                }
            ]
        })
    };

    const saveQuestion = (index: any) => {
        const toSave = faqQuestions[index];
        toSave.ind = index;
        if (toSave.id === null) {
            http.post(`/rest/learning-faqs`, toSave).then((response: any) => {
                message.success("Jautājums ir saglabāts")
            }).catch(() => {
                message.error("Saglabāšanas kļūda!")
            });
        } else {
            http.patch(`/rest/learning-faqs/${toSave.id}`, toSave).then((response: any) => {
                message.success("Jautājums ir saglabāts")
            }).catch(() => {
                message.error("Saglabāšanas kļūda!")
            });
        }
    };

    const removeQuestionHandler = (e: any, ind: any) => {
        const question = faqQuestions[ind];
        if (question.id !== null) http.delete(`/rest/learning-faqs/${question.id}`)
        e.stopPropagation()
        setFaqQuestions((prev: any) => {
            const editedArray = prev.filter((item: any, index: any) => index !== ind)
            return editedArray;
        })
    }

    useEffect(() => {
        // setFaqQuestions(faqs);
    }, []);

    useEffect(() => {
        setFaqQuestions(faqs)
    }, [faqs])

    return <Card title="Biežāk uzdotie jautājumi">
        <Tabs defaultActiveKey="1">
            {
                languages.map((lang: any) => {
                    return <Tabs.TabPane key={lang.id} tab={lang.iso?.toUpperCase()}>
                        <Collapse>
                            {
                                faqQuestions?.map((module: any, index: any) => {
                                    return <Panel
                                        header={<Row justify="space-between">
                                            {/* <div>Random jautajums testa nolūkos?</div> */}
                                            <Input
                                                style={{ width: 400 }}
                                                value={module[`question_${lang.iso}`]}
                                                // onClick={}
                                                onChange={(e: any) => {
                                                    e.persist();
                                                    setFaqQuestions((prev: any) => {
                                                        prev[index] = {
                                                            ...prev[index],
                                                            [`question_${lang.iso}`]: e.target.value
                                                        }
                                                        return [...prev]
                                                    })
                                                }}
                                            />
                                            {
                                                lang.iso !== "lv"
                                                    ? <span style={{ color: "#cccccc" }}>LV: {module.question_lv}</span>
                                                    : <></>
                                            }
                                            <Button
                                                type="text"
                                                danger
                                                style={{ marginLeft: 10 }}
                                                onClick={(e: any) => removeQuestionHandler(e, index)}
                                                icon={<DeleteOutlined style={{ pointerEvents: "none" }}
                                                />}>
                                            </Button>
                                        </Row>}
                                        key={index + 1}
                                    >
                                        <b>Atbilde: </b>
                                        <Input.TextArea
                                            style={{ marginTop: 8 }}
                                            autoSize={{ maxRows: 3, minRows: 3 }}
                                            value={module[`answer_${lang.iso}`]}
                                            onChange={(e: any) => {
                                                e.persist();
                                                setFaqQuestions((prev: any) => {
                                                    prev[index] = {
                                                        ...prev[index],
                                                        [`answer_${lang.iso}`]: e.target.value
                                                    }
                                                    return [...prev]
                                                })
                                            }}
                                        />
                                        {
                                            lang.iso !== "lv"
                                                ? <span style={{ color: "#cccccc" }}>LV: {module.answer_lv}</span>
                                                : <></>
                                        }
                                        <Row justify="end" style={{ marginTop: 10 }}>
                                            <Button onClick={() => saveQuestion(index)} type="text" icon={<SaveTwoTone style={{ fontSize: 20 }} />}></Button>
                                        </Row>
                                    </Panel>
                                })
                            }
                        </Collapse>
                    </Tabs.TabPane>
                })
            }
        </Tabs>
        <Row justify="end">
            <Button onClick={addQuestion} style={{ marginTop: 15 }}>Pievienot jautajumu +</Button>
        </Row>
    </Card>

}