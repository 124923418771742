import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Layout, PageHeader, Row, Space, TimePicker, Typography } from "antd";
import { DeleteOutlined, SaveOutlined, PlusCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { LessonForm } from "./LessonForm";
import { http } from "../../helpers/http";

const { Title } = Typography;

export const EditLessonsSchedule = withRouter((props: any) => {
  const [lessons, setLessons] = useState(props.lessons);
  const { groupID } = props;

  const addLesson = () => {
    const newLessonList = [...lessons];
    newLessonList.push({
      id: uuidv4(),
      lesson_date: null,
      time_from: props.groupData.start_time,
      time_to: props.groupData.end_time,
    })
    setLessons(newLessonList);
  }

  useEffect(() => {
    setLessons(props.lessons)
  }, [props.lessons])

  const updateLessons = (value: any) => {
    
    const newLessonList = lessons.filter((lesson: any) => {
      console.log(lesson.id, value)
      return String(lesson.id) !== String(value);
    })
    setLessons(newLessonList);
    props.updateLessons(newLessonList);
  }

  return (
    <>
      <Row>
        <Col style={{ width: "100%", margin: 15 }}>
          {/* <Button onClick={() => console.log(props)}>!</Button> */}
          {lessons ? lessons.map((lesson: any, index: any) => {
            return (
              <LessonForm
                key={lesson.id}
                lesson={lesson}
                index={index}
                updateLessons={(value: any) => updateLessons(value)}
                groupID={groupID}
                saveLessons={props.saveLessons}
                lecturers={props.groupData.lecturers}
                refresh={props.refresh}
              />
            )
          }) : <></>}
          <Button onClick={addLesson} >Pievienot nodarbību <PlusCircleOutlined /></Button>
        </Col>
      </Row>
    </>
  )
})