import { Button, Card, Checkbox, Col, Input, Row, Select, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { NotificationTypeForm } from "./NotificationTypeForm";

export const ExtraNotificationsForm = (props: any) => {
    const [classifiers, setClassifiers] = useState({
        categories: [],
        languages: [],
    });
    const [notificationTypes, setNotificationTypes] = useState([] as any);
    const [texts, setTexts] = useState({} as any);
    const [groups, setGroups] = useState([] as any);
    const [statuses, setStatuses] = useState([] as any);

    const fetchNotificationsTypes = async () => {
        await http.get(`rest/student-notification-types`).then((response: any) => {
            setNotificationTypes(response.data)
        })
    };

    const fetchNotificationStatus = async () => {
        await http.get('rest/get-notification-types-status-values').then((response: any) => {
            setStatuses(response.data)
        })
    }

    const fetchTexts = async () => {
        await http.get(`rest/student-notification-texts`).then((response: any) => {
            setTexts(response.data.data);
        });
    };

    const fetchTypeGroups = async () => {
        await http.get(`rest/get-notification-types-group-values`).then((response: any) => {
            setGroups(response.data);
        });
    };

    const fetchClassifiers = async () => {
        const languages = await http.get(`rest/languages`).then((response: any) => {
            return response.data.data
        });
        const categories = await http.get(`rest/categories`).then((response: any) => {
            return response.data.data
        });
        setClassifiers({
            categories,
            languages
        })
    };


    useEffect(() => {
        fetchClassifiers();
        fetchNotificationsTypes();
        fetchTypeGroups();
        fetchNotificationStatus();
        fetchTexts();
    }, []);

    return notificationTypes.length > 0
        ? notificationTypes?.map((type: any) => {
            return <NotificationTypeForm
                key={type.id}
                type={type}
                allLanguages={classifiers.languages}
                allCategories={classifiers.categories}
                texts={texts[type.id]}
                groups={groups}
                statuses={statuses}
            />
        })
        : <></>
}