import { Button, Card, Layout, Modal, PageHeader, Tabs, Input, message, Tag, Row, Col, Checkbox, DatePicker, TimePicker } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../helpers/http";
import { ExtraNotificationsForm } from "./ExtraNotificationsForm";
import moment from "moment";
import { ChooseTemplateModal } from "./ChooseTemplateModal";
// import { NotificationsLog } from "../Groups/NotificationsLog";

export const NotificationsView = (props: any) => {
    const history = useHistory();
    const [messages, setMessages] = useState({
        "1": "",
        "2": "",
        "3": ""
    }) as any;
    const [languages, setLanguages] = useState([]);
    const [apiSettings, setApiSettings] = useState({
        use_whatsapp: 0,
        whatsapp_api_token: null as any,
        whatsapp_api_business_account_id: null as any,
        use_sms: 0,
        sms_api_key: null as any,
        sms_api_sender: null as any
    });
    const [till, setTill] = useState({
        date: undefined as undefined | string,
        time: undefined as undefined | string
    });

    const fetchLangs = async () => {
        await http.get('/rest/languages').then((response: any) => {
            setLanguages(response.data.data)
        })
    };

    const fetchApiSettings = async () => {
        await http.get(`rest/get-notification-api-config`).then((response: any) => {
            setApiSettings(response.data.data)
        })
    }

    const saveApiSettings = async () => {
        await http.post(`rest/set-notification-api-config`, apiSettings).then((response: any) => {
            message.success("Paziņojumu kanālu Api uzstādījumi ir saglabāti")
        }).catch((err: any) => {
            message.error("Kļūda!")
        })
    }

    const sendNotification = () => {
        const cantSend = messages["1"].length === 0
            || messages["2"].length === 0
            || messages["3"].length === 0
        const noTill = till.date === undefined || till.time === undefined
        Modal.confirm({
            title: 'Vai jūs vēlaties nosūtīt šo paziņojumu visiem studentiem?',
            icon: <ExclamationCircleOutlined />,
            content: <>
                {
                    cantSend
                    && <Tag color="red">Uzmanību! Nav ievadīts teksts kādā no valodām!</Tag>
                }
                {
                    noTill
                    && <Tag color="red">Uzmanību! Nav ievadīts rādīšanas beigu laiks!</Tag>
                }
            </>,
            onOk() {
                if (cantSend || noTill) return;
                http.post(`/rest/custom-notification`, { messages: messages, date_till: till.date + " " + till.time }).then((response: any) => {
                    setMessages({
                        "1": "",
                        "2": "",
                        "3": ""
                    })
                    message.success('Paziņojums ir nosūtīts')
                }).catch((err: any) => {
                    message.error('Kļūda')
                })
            }
        });
    };

    const setSpamFromTemplate = (record: any) => {
        let templateMessages = {
            "1": "",
            "2": "",
            "3": ""
        }
        Object.keys(record).forEach((langKey: any) => {
            if (langKey === "title") return;
            templateMessages = {
                ...templateMessages,
                [langKey]: record[langKey]?.db
            }
        })
        setMessages(templateMessages)
    }

    useEffect(() => {
        fetchLangs();
        fetchApiSettings();
    }, []);

    return <Layout>
        <PageHeader
            title="Paziņojumi"
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF", width: "100%" }}
        />

        {/* <Row style={{ margin: 20 }}>
            <Col span={24}>
                <NotificationsLog studentId={5} />
            </Col>
        </Row> */}

        <Card
            title="Vienreizējie paziņojumi"
            style={{ margin: 20 }}
        >
            <Tabs style={{ marginBottom: 20 }}>
                {
                    Object.keys(messages).map((key: any) => {
                        const lang = languages.find((el: any) => String(el.id) === key) as any;
                        return <Tabs.TabPane tab={lang?.value} key={key} forceRender>
                            <Input.TextArea
                                value={messages[key]}
                                onChange={(event: any) => {
                                    event.persist();
                                    setMessages((prev: any) => {
                                        return {
                                            ...prev,
                                            [key]: event?.target.value
                                        }
                                    })
                                }}
                            />
                        </Tabs.TabPane>
                    })
                }
            </Tabs>
            <div style={{ marginBottom: 10 }}>
                <p style={{ marginBottom: 5 }}><b>Rādīt līdz:</b></p>
                <DatePicker
                    value={till.date === undefined ? undefined : moment(till.date, "YYYY-MM-DD")}
                    onChange={e => {
                        setTill(prev => {
                            return {
                                ...prev,
                                date: !e ? undefined : moment(e).format("YYYY-MM-DD")
                            }
                        })
                    }}
                />
                <TimePicker
                    style={{ marginLeft: 10 }}
                    format="HH:mm"
                    value={till.time === undefined ? undefined : moment(till.time, "HH:mm")}
                    onChange={e => {
                        setTill(prev => {
                            return {
                                ...prev,
                                time: !e ? undefined : moment(e).format("HH:mm")
                            }
                        })
                    }}
                />
            </div>
            <Row justify="end">
                <ChooseTemplateModal
                    setSpam={setSpamFromTemplate}
                    dbOnly={true}
                />
            </Row>
            <Button
                type="primary"
                onClick={sendNotification}
            >
                Nosūtīt
            </Button>
        </Card>
        <Card
            style={{ margin: 20 }}
            title="Kanālu uzstādījumi"
        >
            <Row gutter={24}>
                <Col>
                    <Checkbox
                        style={{ marginRight: 8 }}
                        checked={!!apiSettings.use_whatsapp}
                        onChange={(e: any) => {
                            setApiSettings((prev: any) => {
                                return {
                                    ...prev,
                                    use_whatsapp: e.target.checked ? 1 : 0
                                }
                            })
                        }}
                    />
                    <b>Izmantot whatsapp</b>
                    <div style={{ margin: "5px 0" }}>
                        <b>Whatsapp api token</b><br />
                        <Input
                            value={apiSettings.whatsapp_api_token}
                            style={{ margin: "4px 0" }}
                            disabled={!(!!apiSettings.use_whatsapp)}
                            onChange={(e: any) => {
                                e.persist()
                                setApiSettings((prev: any) => {
                                    return {
                                        ...prev,
                                        whatsapp_api_token: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                    <div style={{ margin: "5px 0" }}>
                        <b>Whatsapp api bussines account</b><br />
                        <Input
                            value={apiSettings.whatsapp_api_business_account_id}
                            style={{ margin: "4px 0" }}
                            disabled={!(!!apiSettings.use_whatsapp)}
                            onChange={(e: any) => {
                                e.persist()
                                setApiSettings((prev: any) => {
                                    return {
                                        ...prev,
                                        whatsapp_api_business_account_id: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                </Col>
                <Col>
                    <Checkbox
                        style={{ marginRight: 8 }}
                        checked={!!apiSettings.use_sms}
                        onChange={(e: any) => {
                            setApiSettings((prev: any) => {
                                return {
                                    ...prev,
                                    use_sms: e.target.checked ? 1 : 0
                                }
                            })
                        }}
                    />
                    <b>Izmantot SMS</b>
                    <div style={{ margin: "5px 0" }}>
                        <b>SMS api key</b><br />
                        <Input
                            value={apiSettings.sms_api_key}
                            style={{ margin: "4px 0" }}
                            disabled={!(!!apiSettings.use_sms)}
                            onChange={(e: any) => {
                                e.persist()
                                setApiSettings((prev: any) => {
                                    return {
                                        ...prev,
                                        sms_api_key: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                    <div style={{ margin: "5px 0" }}>
                        <b>SMS api sender</b><br />
                        <Input
                            value={apiSettings.sms_api_sender}
                            style={{ margin: "4px 0" }}
                            disabled={!(!!apiSettings.use_sms)}
                            onChange={(e: any) => {
                                e.persist()
                                setApiSettings((prev: any) => {
                                    return {
                                        ...prev,
                                        sms_api_sender: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                </Col>
                <Col
                    style={{ display: "flex", flexDirection: "column-reverse" }}
                >
                    <Button
                        type="primary"
                        onClick={saveApiSettings}
                    >
                        Saglabāt
                    </Button>
                </Col>
            </Row>
        </Card>
        <Card
            title="Paziņojumu uzstādījumi"
            style={{ margin: 20, backgroundColor: "#CCCCCC" }}
        >
            <ExtraNotificationsForm />
        </Card>
    </Layout>
}