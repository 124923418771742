import { Row, Col, Card, Typography, Space } from "antd";
import React, { useState } from "react";
import { InstructorCustomPrices } from "./InstructorCustomPrices";

const { Title, Text } = Typography;

export const InstructorHandler = (props: any) => {
  const instructors = props.instructors;
//   const customPriceSelection = 
//   const [instructors, setInstructors] = useState(props.instructors);
  const [modalVisible, setModalVisible] = useState(false);
//   const [customPriceSelection, setCustomPriceSelection] = useState({} as any)
  
//   const removeInstructor = (id: any) => {
//     const newInstructorList = instructors.filter((instructor: any) => {
//       return instructor.id !== id;
//     })
//     setInstructors(newInstructorList)
//   }

//   const openCustomPrices = (e: any) => {
//     const instructor = instructors.filter((instructor: any) => {
//       return instructor.id === Number(e.target.value);
//     })
//     console.log(instructor)
//     setCustomPriceSelection(instructor[0]);
//     setModalVisible(true);
//   }

  return (
    <Row style={{ marginTop: 30 }}>
      <Col span={24}>
        <Row justify={"space-between"} style={{ paddingBottom: 20 }}>
          <Title level={4} >Instruktori</Title>
          {/* <Link to="/groups/add-instructor"><Button>+ Pievienot instruktoru</Button></Link> */}
        </Row>
      </Col>
      <Col span={24}>
        {instructors.length > 0 && instructors[0] !== null ? instructors.map((item: any) => {
          console.log(item, instructors)
          return (
            <Card key={item.id} style={{ width: "100%" }}>
              <Row>
                <Col span={6}>{item.instructor.first_name}</Col>
                <Col span={6}>{item.instructor.last_name}</Col>
                <Col span={8}>
                  <Space>
                    {item.instructor_rate} EUR / stundā
                    {/* <Button
                      value={item.id}
                      onClick={openCustomPrices}
                    >
                      <span style={{ pointerEvents: "none" }}>Pielāgot cenas</span>
                    </Button> */}
                    {/* <InstructorCustomPrices
                    //   item={customPriceSelection}
                      showModal={modalVisible}
                      setShowModal={setModalVisible} /> */}
                  </Space>
                </Col>
                {/* <Button style={{ margin: "0 0 0 auto" }} onClick={() => removeInstructor(item.id)} ><DeleteOutlined /></Button> */}
              </Row>
            </Card>
          )
        }) : <Text>Nav piesaistīts neviens instruktors</Text>}
      </Col>
    </Row>
  )
}