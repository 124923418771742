import { Card, Row, Col, Form, Select, Button, message } from "antd"
import { AxiosResponse } from "axios";
import React, { useState } from "react"
import { http } from "../../helpers/http";

type CategoriesInformationProps = {
    student: any;
    categories: any;
    cities: any;
    languages: any;
    refresh: () => void;
}

export const CategoriesInformation = ({ student, categories, cities, refresh }: CategoriesInformationProps) => {
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState(null as number | null);

    const onFinish = (values: any) => {

        console.log(values)
        processData(values)
    }

    const processData = (data: any) => {

        let objectToSend = {} as any;
        Object.keys(data).forEach((key: any) => {
            objectToSend = {
                ...objectToSend,
                [key.split("-")[1]]: {
                    ...objectToSend[key.split("-")[1]],
                    [key.split("-")[0]]: data[key]
                }
            }
            // if (objectToSend.hasOwnProperty([key.split("-")[1]])) {
            //     objectToSend = {
            //         ...objectToSend,
            //         [key.split("-")[1]]: {
            //             ...objectToSend[key.split("-")[1]],
            //             [key.split("-")[0]]: data[key]
            //         }
            //     }
            // } else {
            //     objectToSend = {
            //         ...objectToSend,
            //         [key.split("-")[1]]: {
            //             [key.split("-")[0]]: data[key]
            //         }
            //     }
            // }

        })
        setLoading(true)
        http.post(`/rest/change-multi-cat/${student.id}`, {
            multidata: objectToSend
        }).then((res: AxiosResponse) => {
            setLoading(false)
            message.success("Kategoriju informācija ir atjaunota")
        }).catch(() => {
            setLoading(false)
            message.error("Kļūda!")
        })
    }

    const addCategory = () => {
        setLoading(true);
        if (category) {
            http.get(`/rest/student-add-category/${student.id}/${category}`).then((res: AxiosResponse) => {
                setLoading(false);
                setCategory(null);
                refresh();
            }).catch(() => {
                message.error("Kļūda!");
                setLoading(false);
            })
        }
    }

    const deleteCategory = (categoryID: number) => {
        setLoading(true);
        http.get(`/rest/student-remove-category/${student.id}/${categoryID}`).then((res: AxiosResponse) => {
            refresh();
            setLoading(false);
        }).catch(() => {
            message.error("Kļūda!");
            setLoading(false);
        })
    }

    return <Card
        style={{ marginBottom: 20 }}
        title={
            <>
                <strong>Mācību informācija</strong>

            </>}
        loading={Object.keys(student).length === 0 || categories.length === 0 || cities.length === 0}
    >
        <Form
            onFinish={onFinish}
        >
            {
                student.categories?.map((category: any) => {
                    return <Row gutter={20} key={category.id}>
                        <Col flex={1}>
                            <Form.Item
                                name={`category_id-${category.id}`}
                                required
                                rules={[{ required: true, message: "Obligāts lauks" }]}
                                label="Kategorija"
                                initialValue={category.category_id}
                            >
                                <Select
                                    style={{ width: 150 }}
                                // onChange={(e: any) => this.studyGroupFilterHandler(e, "category")}
                                >
                                    {
                                        categories
                                            ? categories.filter((cat: any) => {
                                                return !cat.exam
                                            }).map((cat: any) => {
                                                return <Select.Option key={cat.id} value={cat.id}>{cat.value}</Select.Option>
                                            }) : null
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item
                                name={`transmission-${category.id}`}
                                required
                                rules={[{ required: true, message: "Obligāts lauks" }]}
                                label="Pārnesumkārba"
                                initialValue={category.transmission}
                            >
                                <Select style={{ width: 150 }}>
                                    <Select.Option value="manual">Manuālā</Select.Option>
                                    <Select.Option value="auto">Automātiskā</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item
                                name={`city_id-${category.id}`}
                                required rules={[{ required: true, message: "Obligāts lauks" }]}
                                label="Pilsēta"
                                initialValue={category.city_id}
                            >
                                <Select
                                    style={{ width: 150 }}
                                // onChange={(e: any) => this.studyGroupFilterHandler(e, "cities")}
                                >
                                    {
                                        cities
                                            ? cities.map((city: any) => {
                                                return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                                            }) : null
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        {
                            !category.only_driving && (category.study_group_id === null || Number(category.study_group_id) === Number(process.env.REACT_APP_NO_GROUP_ID))
                            && <Col>
                                <Button
                                    danger
                                    type="primary"
                                    onClick={() => deleteCategory(category.category_id)}
                                    loading={loading}
                                >
                                    Dzēst
                                </Button>

                            </Col>
                        }
                    </Row>
                })
            }
            <Row justify="end">
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                >
                    Saglabāt
                </Button>
            </Row>
        </Form>
        <h3><b>Pievienot papildus kategoriju</b></h3>
        <Select
            style={{ width: 150 }}
            value={category ?? undefined}
            onChange={(e: any) => {
                setCategory(e === undefined ? null : e)
            }}
        >
            {
                categories
                    ? categories.map((cat: any) => {
                        return <Select.Option key={cat.id} value={cat.id}>{cat.value}</Select.Option>
                    }) : null
            }
        </Select>
        <Button
            style={{ marginLeft: 10 }}
            onClick={addCategory}
        >
            Pievienot
        </Button>
    </Card>
}
