import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Space, Table, Tag } from "antd";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useHistory } from "react-router";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { http } from "../../helpers/http";

export const BooksShippingTable = (props: any) => {
    const { paid } = props;
    const dateFormat = "DD.MM.YYYY"
    const [searchProps, setSearchProps] = useState({
        data: [] as any,
        pagination: {
            current: 1,
            pageSize: 15,
            total: 1,
        },
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch();
    }, [])

    useEffect(() => {
        Object.keys(searchProps).length > 0 ? setLoading(false) : setLoading(true);
    }, [searchProps])

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters,
        });
    };

    const fetch = (params: any = { pagination: { current: 1 } }) => {
        http.get("/rest/crm-books", { params: getParams(params) }).then(result => {
            const { data } = result.data;
            setSearchProps({
                data,
                pagination: {
                    current: result.data.current_page,
                    pageSize: result.data.per_page,
                    total: result.data.total
                },
            });
        });
    }

    const deliveredHandle = (id: any, extra: boolean) => {
        Modal.confirm({
            title: "Apstiprināt",
            content: "Apstiprināt, ka piegāde ir veikta?",
            onOk() {
                if (extra) {
                    http.post(`rest/deliver-extra-omniva/${id}`).then((response: any) => {
                        console.log(response)
                        fetch();
                    })
                    return;
                }
                http.post(`rest/student-study-group-services/change-service-delivered/${id}`, { delivered: 1 }).then((response: any) => {
                    fetch();
                })
            }
        })
    }

    const getParams = (params: any) => {
        return {
            paid: paid ? 1 : 0,
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters
        }
    }

    const shippingColsPaid = [
        // {
        //     title: "Grāmatu komplekts",
        //     dataIndex: "books",
        //     key: "books",
        //     render: (book: any) => book.value_lv,
        //     ...getColumnSearchProps(),
        // },
        {
            title: "Pakomāts",
            dataIndex: "name",
            key: 'name',
            // render: (terminal: any) => terminal.name,
            ...getColumnSearchProps(),
        },
        {
            title: "Vārds uzvāŗds",
            dataIndex: "full_name",
            key: 'full_name',
            // render: (customer: any) => customer.name,
            ...getColumnSearchProps(),
        },
        {
            title: "Tālrunis",
            dataIndex: "phone_number",
            key: 'phone_number',
            // render: (customer: any) => customer.phone_number,
            ...getColumnSearchProps(),
        },
        {
            title: "Apmaksas datums",
            sorter: true,
            dataIndex: "paid_date",
            key: 'paid_date',
            align: "center",
            ...getColumnSearchProps('calendar'),
            // render: (date: any) => {
            //     const onlyDate = date !== "Not paid" ? date.split("T")[0] : undefined;
            //     return onlyDate === undefined
            //         ? ""
            //         : moment(onlyDate).format(dateFormat);
            // }
        },
        {
            title: "Darbība",
            dataIndex: 'action',
            key: 'action',
            render: (all: any, record: any) => {
                const id = !!record.extra_omniva
                    ? record.extra_omniva
                    : record.service_id
                return <Button
                    onClick={() => deliveredHandle(id, !!record.extra_omniva)}
                >
                    Piegādāts
                </Button>
            },
        },
    ];

    const shippingColsUnpaid = [
        // {
        //     title: "Grāmatu komplekts",
        //     dataIndex: "book",
        //     key: "book",
        //     // render: (book: any) => book.name,
        //     ...getColumnSearchProps(),
        // },
        {
            title: "Pakomāts",
            dataIndex: "name",
            key: 'name',
            // render: (terminal: any) => terminal.name,
            ...getColumnSearchProps(),
        },
        {
            title: "Vārds uzvāŗds",
            dataIndex: "full_name",
            key: 'full_name',
            // render: (customer: any) => customer.name,
            ...getColumnSearchProps(),
        },
        {
            title: "Tālrunis",
            dataIndex: "phone_number",
            key: 'phone_number',
            // render: (customer: any) => customer.phone_number,
            ...getColumnSearchProps(),
        },
    ] as any;

    return (
        <Card style={{ width: "100%", marginBottom: 20 }} title={paid ? "Apmaksātie" : "Neapmaksātie"}>
            <Table
                rowKey={record => record.id}
                dataSource={searchProps.data}
                columns={paid ? shippingColsPaid : shippingColsUnpaid}
                pagination={searchProps.pagination}
                onChange={handleTableChange}
                loading={loading}
                expandable={{
                    expandedRowRender: (record: any) => {
                        return record.books.map((book: any) => {
                            return <div>
                                <Space>
                                    <b>{book.value_lv}</b>
                                    {
                                        book?.pivot?.paid
                                            ? <Tag color="success">Apmaksāts</Tag>
                                            : <Tag color="red">Nav apmaksāts</Tag>
                                    }
                                </Space>
                            </div>
                        })
                    }
                }}
            />
        </Card>
    )
}