import { Layout, Space, Button, Table } from "antd";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps"
import { http } from "../../helpers/http";

const { Header, Content } = Layout;

export type LecturersPageProps = RouteComponentProps & {
  windowHeight: number
}

export type LecturersPageState = {
  data: any,
  pagination: any,
  loading: any,
  classifiers: any
}

export class BaseLecturersPage extends React.Component<LecturersPageProps, LecturersPageState> {
  constructor(props: LecturersPageProps) {
    super(props);
    this.state = {
      data: [],
      pagination: {
        current: 1,
        pageSize: 15,
      },
      loading: true,
      classifiers: {},
    };
  }

  componentDidMount() {
    this.loadClassifiers().then(() => {
      this.fetch();
    });
  }

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  async loadClassifiers() {
    const languages = await http.get("/rest/languages");
    const cities = await http.get("/rest/cities");
    const categories = await http.get("/rest/categories");

    this.setState({
      classifiers: {
        languages: languages.data.data,
        cities: cities.data.data,
        categories: categories.data.data
      }
    });
  }

  fetch(params: any = { pagination: { current: 1 } }) {
    const timeout = setTimeout(() => { this.setState({ loading: true }); }, 150)
    http.get("/rest/lecturers", { params: this.getParams(params) }).then(result => {
      const { data, meta } = result.data;
      clearTimeout(timeout);
      this.setState({
        data,
        pagination: {
          current: meta.current_page,
          pageSize: meta.per_page,
          total: meta.total
        },
        loading: false
      });
    });
  }

  getParams(params: any) {
    return {
      page: params.pagination.current,
      sortField: params.sortField,
      sortOrder: params.sortOrder,
      filters: params.filters
    }
  }

  render() {
    const columns = [
      {
        title: 'Vārds',
        width: 35,
        sorter: true,
        dataIndex: 'first_name',
        key: 'first_name',
        fixed: 'left',
        ...getColumnSearchProps(),
        onCell: (record: any, rowIndex: any) => {
          return {
            onClick: (event: any) => {
              this.props.history.push(`/lecturers/list/edit/${record.id}`);
            }, // click row
            onDoubleClick: (event: any) => { }, // double click row
            onContextMenu: (event: any) => { }, // right button click row
            onMouseEnter: (event: any) => { }, // mouse enter row
            onMouseLeave: (event: any) => { }, // mouse leave row
          };
        }
      },
      {
        title: 'Uzvārds',
        width: 35,
        sorter: true,
        dataIndex: 'last_name',
        key: 'last_name',
        fixed: 'left',
        ...getColumnSearchProps(),
        onCell: (record: any, rowIndex: any) => {
          return {
            onClick: (event: any) => {
              this.props.history.push(`/lecturers/list/edit/${record.id}`);
            }, // click row
            onDoubleClick: (event: any) => { }, // double click row
            onContextMenu: (event: any) => { }, // right button click row
            onMouseEnter: (event: any) => { }, // mouse enter row
            onMouseLeave: (event: any) => { }, // mouse leave row
          };
        }
      },
    //   {
    //     title: 'Pilsētas',
    //     dataIndex: 'cities',
    //     key: 'cities',
    //     width: 40,
    //     render: (cities: any) => cities.map((d: any) => d.value).join(", "),
    //     filters: this.state.classifiers.cities != null ? this.state.classifiers.cities.map((c: any) => ({
    //       text: c.value, value: c.id
    //     })) : [],
    //     onCell: (record: any, rowIndex: any) => {
    //       return {
    //         onClick: (event: any) => {
    //           this.props.history.push(`/lecturers/edit/${record.id}`);
    //         }, // click row
    //         onDoubleClick: (event: any) => { }, // double click row
    //         onContextMenu: (event: any) => { }, // right button click row
    //         onMouseEnter: (event: any) => { }, // mouse enter row
    //         onMouseLeave: (event: any) => { }, // mouse leave row
    //       };
    //     }
    //   },
      {
        title: 'Valodas',
        dataIndex: 'languages',
        key: 'languages',
        width: 40,
        render: (languages: any) => languages.map((d: any) => d.value).join(", "),
        filters: this.state.classifiers.languages != null ? this.state.classifiers.languages.map((c: any) => ({
          text: c.value, value: c.id
        })) : [],
        onCell: (record: any, rowIndex: any) => {
          return {
            onClick: (event: any) => {
              this.props.history.push(`/lecturers/list/edit/${record.id}`);
            }, // click row
            onDoubleClick: (event: any) => { }, // double click row
            onContextMenu: (event: any) => { }, // right button click row
            onMouseEnter: (event: any) => { }, // mouse enter row
            onMouseLeave: (event: any) => { }, // mouse leave row
          };
        }
      },
    //   {
    //     title: 'Kategorijas',
    //     dataIndex: 'services',
    //     key: 'categories',
    //     width: 30,
    //     render: (services: any) => services.map((service: any) => <div key={service.id}>{service.category.value}</div>),
    //     onCell: (record: any, rowIndex: any) => {
    //       return {
    //         onClick: (event: any) => {
    //           this.props.history.push(`/lecturers/edit/${record.id}`);
    //         }, // click row
    //         onDoubleClick: (event: any) => { }, // double click row
    //         onContextMenu: (event: any) => { }, // right button click row
    //         onMouseEnter: (event: any) => { }, // mouse enter row
    //         onMouseLeave: (event: any) => { }, // mouse leave row
    //       };
    //     }
    //   },
    //   {
    //     title: 'Stundas likmes',
    //     dataIndex: 'services',
    //     key: 'total_rate',
    //     width: 30,
    //     onCell: (record: any, rowIndex: any) => {
    //       return {
    //         onClick: (event: any) => {
    //           this.props.history.push(`/lecturers/edit/${record.id}`);
    //         }, // click row
    //         onDoubleClick: (event: any) => { }, // double click row
    //         onContextMenu: (event: any) => { }, // right button click row
    //         onMouseEnter: (event: any) => { }, // mouse enter row
    //         onMouseLeave: (event: any) => { }, // mouse leave row
    //       };
    //     },
    //     render: (services: any) => services.map((service: any) => <div key={service.id}>{Number(service.total_rate_real).toFixed(2)} EUR ({service.category.value})</div>)
    //   },
      {
        title: 'Lietotājs',
        dataIndex: 'username',
        key: 'username',
        sorter: true,
        width: 50,
        ...getColumnSearchProps(),
        onCell: (record: any, rowIndex: any) => {
          return {
            onClick: (event: any) => {
              this.props.history.push(`/lecturers/list/edit/${record.id}`);
            }, // click row
            onDoubleClick: (event: any) => { }, // double click row
            onContextMenu: (event: any) => { }, // right button click row
            onMouseEnter: (event: any) => { }, // mouse enter row
            onMouseLeave: (event: any) => { }, // mouse leave row
          };
        }
      },
      {
        title: 'E-pasts',
        dataIndex: 'email',
        key: 'email',
        width: 50,
        sorter: true,
        ...getColumnSearchProps(),
        onCell: (record: any, rowIndex: any) => {
          return {
            onClick: (event: any) => {
              this.props.history.push(`/lecturers/list/edit/${record.id}`);
            }, // click row
            onDoubleClick: (event: any) => { }, // double click row
            onContextMenu: (event: any) => { }, // right button click row
            onMouseEnter: (event: any) => { }, // mouse enter row
            onMouseLeave: (event: any) => { }, // mouse leave row
          };
        }
      },
      {
        title: 'Tālrunis',
        dataIndex: 'phone_number',
        key: 'phone_number',
        sorter: true,
        width: 50,
        ...getColumnSearchProps(),
        onCell: (record: any, rowIndex: any) => {
          return {
            onClick: (event: any) => {
              this.props.history.push(`/lecturers/list/edit/${record.id}`);
            }, // click row
            onDoubleClick: (event: any) => { }, // double click row
            onContextMenu: (event: any) => { }, // right button click row
            onMouseEnter: (event: any) => { }, // mouse enter row
            onMouseLeave: (event: any) => { }, // mouse leave row
          };
        }
      },
    //   {
    //     title: '',
    //     dataIndex: '',
    //     key: '',
    //     width: 25,
    //     render: () => <Button type="link">Ielogoties</Button>,
    //     onCell: (record: any, rowIndex: any) => {
    //       return {
    //         onClick: (event: any) => {
    //           const token = record.api_token;
    //           const link = `http://einsteins.webplace.lv:3001/?bt=${token}`;
    //           window.open(link);
    //           //this.props.history.push(`/lecturers/edit/${record.id}`);
    //         }, // click row
    //         onDoubleClick: (event: any) => { }, // double click row
    //         onContextMenu: (event: any) => { }, // right button click row
    //         onMouseEnter: (event: any) => { }, // mouse enter row
    //         onMouseLeave: (event: any) => { }, // mouse leave row
    //       };
    //     }
    //   }
    ];

    const { data, pagination, loading } = this.state;
    return (
      <Layout>
        <Header style={{ padding: "0 16px", background: "rgb(240, 242, 245)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}>
          <Space>
            <Link to='/lecturers/list/new'><Button type="primary">Pievienot lektoru</Button></Link>
          </Space>
        </Header>
        <Content>
          <Table
            rowKey={record => record.id}
            columns={columns as any}
            rowClassName="clickable-row"
            pagination={{
              showSizeChanger: false,
              size: 'small',
              showTotal: (total) => <div>Kopā {total} lektori</div>,
              position: ['bottomCenter'],
              pageSizeOptions: ['15'],
              defaultPageSize: 15,
              ...pagination
            }}
            dataSource={data}
            loading={loading}
            scroll={{ x: 1600, y: this.props.windowHeight - 185 }}
            onChange={this.handleTableChange}
          />
        </Content>
      </Layout>
    );
  }
}

export const LecturerPage = withRouter(BaseLecturersPage);