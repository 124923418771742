import { Layout, Card, Table, Row, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import DrivenStatsFilter from './DrivenStatsFilter'
import { http } from '../../helpers/http'
import { AxiosResponse } from 'axios'

function DrivenStats() {
    const [filter, setFilter] = useState({
        period: null
    } as any);
    const [allData, setAllData] = useState([] as any);
    const [overviewData, setOverviewData] = useState([] as any);
    const [expandedRows, setExpandedRows] = useState([] as any);
    const [loading, setLoading] = useState(false);

    const fetchDrivingStats = (clearPeriod: boolean = false) => {
        setLoading(true);
        let fetchPeriod: any[];
        if (filter.period === null) {
            fetchPeriod = [undefined, undefined]
        } else {
            fetchPeriod = [filter.period[0].format("YYYY-MM-DD"), filter.period[1].format("YYYY-MM-DD")]
        }
        if (clearPeriod) fetchPeriod = [undefined, undefined];
        http.get(`rest/driving-outside-lessons`, {
            params: {
                from: fetchPeriod[0],
                to: fetchPeriod[1],
            }
        }).then((res: AxiosResponse) => {
            setAllData(res.data);
            let groupedInstructors = {} as any;
            res.data.forEach((item: any) => {
                if (!groupedInstructors[item.instructor_id]) {
                    groupedInstructors[item.instructor_id] = {
                        instructor_id: item.instructor_id,
                        instructor: item.instructor,
                        totalDistance: 0,
                    }
                }
                groupedInstructors[item.instructor_id].totalDistance += item.distance;
            });
            setOverviewData(Object.keys(groupedInstructors).map((key) => groupedInstructors[key]));
            setLoading(false);
        }).catch((err) => {
            console.log(err)
            setLoading(false);
        });
    }

    const search = () => {
        fetchDrivingStats();
    };

    const cancel = () => {
        setFilter({
            period: null
        });
        fetchDrivingStats(true);
    };

    useEffect(() => {
        fetchDrivingStats();
    }, [])

    return <Layout>
        <Card
            title="Instruktoru nobraukumi"
        >
            <DrivenStatsFilter
                onFilterChange={setFilter}
                filter={filter}
                search={search}
                cancel={cancel}
            />
            <Table
                loading={loading}
                rowKey={(record) => record.instructor_id}
                dataSource={overviewData}
                columns={[
                    {
                        title: 'Instruktors',
                        dataIndex: 'instructor',
                        key: 'instructor',
                    },
                    {
                        title: 'Nobraukums ārpus nodarbībām',
                        dataIndex: 'totalDistance',
                        key: 'totalDistance',
                        render: (text: any) => <div>{text / 1000} km</div>
                    }
                ]}
                pagination={false}
                expandable={{
                    rowExpandable: (record) => true,
                    expandedRowKeys: expandedRows,
                    expandedRowRender: (record: any) => {
                        return <div>
                            {
                                allData.filter((item: any) => item.instructor_id === record.instructor_id).map((item: any) => {
                                    return <Card>
                                        <Row>
                                            <Col span={24}>
                                                <p><b>Laika posms starp nodarbībām: {item.date_gap_from} - {item.date_gap_to}</b></p>
                                            </Col>
                                            <Col span={24}>
                                                <Row>
                                                    <p><b>{item.date_time_from} {item.date_time_to}</b></p>
                                                    <p style={{ marginLeft: 10, color: "#f40606" }}><b>{item.distance / 1000} km</b></p>
                                                    <p style={{ marginLeft: 10 }}><b>({item.vehicle}, {item.licence_plate})</b></p>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                <p><b>Sākuma punkts: {item.start_location}; Beigu punkts: {item.stop_location}</b></p>
                                            </Col>
                                        </Row>
                                    </Card>
                                })
                            }
                        </div>
                    },
                    onExpand(expanded, record) {
                        console.log(expanded, record)
                        if (expanded) {
                            setExpandedRows((prev: any) => {
                                return [...prev, record.instructor_id]
                            })
                        } else {
                            setExpandedRows((prev: any) => {
                                return prev.filter((item: any) => item !== record.instructor_id)
                            })
                        }
                    },
                }}
            />
        </Card>
    </Layout>
}

export default DrivenStats