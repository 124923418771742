import { Button, Card, Checkbox, Input, message, Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../helpers/http";
import { AxiosResponse } from 'axios';

export type School = {
    [key: string]: string | null;
    name: string | null;
    registration_nr: string | null;
    legal_address: string | null;
    bank: string | null;
    swift: string | null;
    bank_account: string | null;
}

export const SchoolGlobalSettings = () => {
    const [globalSettings, setGlobalSettings] = useState<School>({
        name: null,
        registration_nr: null,
        legal_address: null,
        bank: null,
        swift: null,
        bank_account: null
    });
    const [newSettings, setNewSettings] = useState<School>({
        name: null,
        registration_nr: null,
        legal_address: null,
        bank: null,
        swift: null,
        bank_account: null
    });

    const getGlobalSchoolSettings = () => {
        http.get(`rest/global-settings-by-scope/requisites`).then((res: AxiosResponse) => {
            console.log(res)
            const { data } = res.data;
            setGlobalSettings((prev: School): School => {
                const copy = { ...prev };
                Object.keys(copy).forEach(key => {
                    copy[key] = data.find((item: any) => {
                        return item.key === key
                    })?.value ?? null
                })
                return copy
            })
        })
    }

    const setSchoolSettings = () => {
        const copy = { ...newSettings };
        Object.keys(copy).forEach(key => {
            if (!copy[key] || copy[key] === globalSettings[key]) return;
            http.post(`rest/global-settings`, {
                key: key,
                scope: "requisites",
                value: copy[key]
            }).then((response: any) => {
                console.log(response)
                message.success(`${keys[key]} Saglabāts!`);
            }).catch(() => {
                message.error("Kļūda!");
            });
        })

    };

    const keys: School = {
        name: "Nosaukums",
        registration_nr: "Reģistrācijas Nr.",
        legal_address: "Juridiskā adrese",
        bank: "Banka",
        swift: "SWIFT",
        bank_account: "Konta Nr."
    }

    useEffect(() => {
        getGlobalSchoolSettings();
    }, []);

    useEffect(() => {
        setNewSettings(globalSettings)
    }, [globalSettings]);

    return <>
        {
            newSettings
            && Object.keys(keys).map(key => {
                return <div key={key} style={{ marginBottom: 10 }}>
                    <p>{keys[key]}</p>
                    <Input
                        value={newSettings[key] ?? undefined}
                        name={key}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.persist();
                            setNewSettings((prev: School): School => {
                                return {
                                    ...prev,
                                    [e.target.name]: e.target.value
                                }
                            })
                        }}
                    />
                </div>
            })
        }
        <Row justify="end">
            <Button
                type="primary"
                onClick={() => setSchoolSettings()}
            >
                Saglabāt
            </Button>
        </Row>
    </>
}