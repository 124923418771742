import { Card, Col, Empty, Pagination, Row } from "antd"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"

type ResultsCardProps = {
    exams: any[any],
    pagination: {
        current: number,
        total: number
    },
    changePage: (page: number) => void;
}

export const ResultsCard = ({ exams, pagination, changePage }: ResultsCardProps) => {
    const history = useHistory();

    return <Card style={{ margin: 20, marginTop: 0 }}>
        {
            exams
                ? exams.length > 0
                    ? <>
                        {
                            exams.map((exam: any) => {
                                return <Card
                                    key={exam.exam.id}
                                    style={{ marginBottom: 5 }}
                                    hoverable
                                    onClick={() => history.push(`/e-learning/results/${exam.exam.id}`)}
                                >
                                    <Row justify="space-between">
                                        <Col span={24} sm={6}>
                                            <p style={{ marginBottom: 4 }}><b>Students:</b></p>
                                            {exam?.exam?.student}
                                        </Col>
                                        <Col span={24} sm={4}>
                                            <p style={{ marginBottom: 4 }}><b>Modulis:</b></p>
                                            {exam?.exam?.module}
                                        </Col>
                                        <Col span={24} sm={4}>
                                            <p style={{ marginBottom: 4 }}><b>Kategorija:</b></p>
                                            {exam?.exam?.category}
                                        </Col>
                                        <Col span={24} sm={6}>
                                            <p style={{ marginBottom: 4 }}><b>Datums:</b></p>
                                            {exam?.exam?.started_date}
                                        </Col>
                                        <Col span={24} sm={4}>
                                            <p style={{ marginBottom: 4 }}><b>Rezultāts:</b></p>
                                            {exam?.questions?.filter((el: any) => exam.answers.find((ans: any) => ans.question_id === el.id)?.correct).length}/{exam?.questions?.length}
                                        </Col>
                                    </Row>
                                </Card>
                            })
                        }
                        <Pagination
                            current={pagination.current}
                            total={pagination.total}
                            pageSize={15}
                            showSizeChanger={false}
                            style={{ textAlign: "center", marginTop: 20 }}
                            showTotal={(total: number) => {
                                return <b>Kopā {total} ieraksti</b>
                            }}
                            onChange={(e) => changePage(e)}
                        />
                    </>
                    : <Empty />
                : <Empty description="Izvēlieties datus atlases kritērijus" />
        }
    </Card>
}