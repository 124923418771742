import { Input, message, Modal } from "antd";
import React, { useState } from "react";
import { http } from "../../../helpers/http";

export const EditPriceModal = (props: any) => {
    const { visible, setVisible, slot, updateSlots } = props;
    const [newPrice, setNewPrice] = useState(null as any);

    const saveNewPrice = () => {
        http.post(`rest/change-theory-exam-price/${slot.id}`, { price: newPrice === null ? slot.price : newPrice })
            .then((response: any) => {
                console.log(response)
                updateSlots();
            }).catch(() => {
                message.error("Kļūda!")
            })
    }

    return <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => saveNewPrice()}
    >
        <b style={{ marginBottom: 10, display: "block" }}>Vecā cena: {slot.price}&euro;</b>
        <b>Jaunā cena: </b>
        <Input
            value={newPrice}
            onChange={(e: any) => {
                e.persist();
                setNewPrice(e.target.value)
            }}
            type="number"
            min={0}
            step={0.01}
            style={{ width: 100 }}
        /><br />
        <small>*Jaunā cena nedrīkst būt lielāka par veco cenu!</small>
    </Modal>
}