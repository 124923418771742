import { Button, Card, Col, Form, Input, Layout, message, Modal, PageHeader, Row, Tabs } from "antd";
import { DeleteOutlined, ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { http } from "../../helpers/http";

const ChapterCard = (props: any) => {
    const history = useHistory();
    const {
        seq,
        removeChapter,
        saveChapters,
        moveChapter,
        lastInd,
        chapter,
        category,
        module
    } = props;

    const ind = seq - 1;
    const editChapter = () => {
        history.push(`/e-learning/${category}/edit-module/${module}/edit-chapter/${chapter.id}`);
    }

    const removeChapterHandler = (e: any) => {
        e.stopPropagation()
        Modal.confirm({
            title: "Dzēst nodaļu?",
            content: "Vai tiešām dzēst izvēlēto nodaļu?",
            onOk: () => {
                if (chapter.id !== null) http.delete(`/rest/learning-chapters/${chapter.id}`)
                removeChapter(ind);
            }
        })
    }

    return <Card
        style={{ margin: "15px 0" }}
        hoverable={chapter.id !== null}
        onClick={chapter.id !== null ? editChapter : () => { }}
        bodyStyle={chapter.id === null ? { backgroundColor: "rgb(242 241 241)", border: "1px solid #aaaaaa" } : {}}
    >
        {/* <div>
            {index === services.length - 1 ? <></> : <Button value={index} onClick={moveDown} icon={<ArrowDownOutlined style={{ pointerEvents: "none" }} />}></Button>}
            {index !== 0 ? <Button value={index} onClick={moveUp} icon={<ArrowUpOutlined style={{ pointerEvents: "none" }} />}></Button> : <></>}
            <Button onClick={removeService} value={service.id} style={{ marginLeft: 10 }} icon={<DeleteOutlined style={{ pointerEvents: "none" }} />}></Button>
        </div> */}
        <div style={{ position: "absolute", top: 5, right: 5, zIndex: 1 }}>
            {/* {
                ind === 0
                    ? <></>
                    : <Button onClick={(e: any) => { e.stopPropagation(); moveChapter(ind, "up") }} type="default" icon={<ArrowUpOutlined style={{ pointerEvents: "none" }} />}></Button>

            }
            {
                lastInd === ind
                    ? <></>
                    : <Button onClick={(e: any) => { e.stopPropagation(); moveChapter(ind, "down") }} type="default" icon={<ArrowDownOutlined style={{ pointerEvents: "none" }} />}></Button>

            } */}
            <Button onClick={(e: any) => removeChapterHandler(e)} type="default" style={{ marginLeft: 10 }} icon={<DeleteOutlined style={{ pointerEvents: "none" }} />}></Button>
        </div>
        <Row>
            <Col span={8}>
                <b>{seq}. Nodaļa</b>
            </Col>
            <Col span={8}>
                <b>{chapter.title_lv || "Nav nosaukuma"}</b>
            </Col>
            <Col span={7}>
                <b></b>
            </Col>
        </Row>
    </Card>
}

export const ModuleView = (props: any) => {
    const history = useHistory();

    const { mid, id } = useParams() as any;

    const [chapters, setChapters] = useState([] as any);
    const [languages, setLanguages] = useState([] as any);
    const [moduleData, setModuleData] = useState({
        title_lv: "" as string,
        title_en: "" as string,
        title_ru: "" as string,
        description_lv: "" as string,
        description_en: "" as string,
        description_ru: "" as string,
    } as any);


    const fetchLanguages = async () => {
        await http.get(`/rest/languages`).then((response: any) => {
            setLanguages(response.data.data);
        });
    };

    const fetchModuleData = async () => {
        await http.get(`/rest/learning-modules/${mid}`).then((response: any) => {
            setModuleData(response.data)
            setChapters(response.data.chapters)
        })
    }

    const goToExamQuestions = () => {
        history.push(`/e-learning/${id}/edit-module/${mid}/exam-questions`)
    }

    const saveModuleData = () => {
        http.patch(`/rest/learning-modules/${mid}`, moduleData).then((response: any) => {
            console.log(response)
            message.success("Dati saglabāti")
        }).catch(() => {
            message.error("Kļūda!");
        })
    }

    const addChapter = () => {
        // setSaved(false);
        setChapters((prev: any) => {
            return [
                ...prev,
                {
                    e_module_id: Number(mid),
                    ind: prev.length,
                    id: null,
                    title_lv: null,
                    title_en: null,
                    title_ru: null,
                    description_lv: null,
                    description_en: null,
                    description_ru: null,
                }
            ]
        })
    }

    const removeChapter = (ind: any) => {
        // setSaved(false);
        setChapters((prev: any) => {
            const newList = prev.filter((item: any, index: any) => {
                return index !== ind;
            })
            return newList;
        })
    }

    const moveChapter = (ind: any, direction: string) => {
        if (direction === "up" && ind !== 0) {
            setChapters((prev: any) => {
                const toMove = prev[ind];
                const inPlaceOf = prev[ind - 1];
                prev[ind] = inPlaceOf;
                prev[ind - 1] = toMove;
                return [...prev];
            })
        } else if (direction === "down" && ind !== chapters.length - 1) {
            setChapters((prev: any) => {
                const toMove = prev[ind];
                const inPlaceOf = prev[ind + 1];
                prev[ind] = inPlaceOf;
                prev[ind + 1] = toMove;
                return [...prev];
            })
        }
    }

    const saveChapters = () => {
        const toSend = chapters.map((module: any, index: any) => {
            return {
                ...module,
                ind: index
            }
        });
        http.post(`/rest/learning-chapters`, { chapters: toSend }).then((response: any) => {
            fetchModuleData();
            message.success("Dati saglabāti")
        }).catch(() => {
            message.error("Kļūda!");
        });
    }

    useEffect(() => {
        fetchLanguages();
        fetchModuleData();
    }, []);

    return <Layout className="main-content">
        <PageHeader
            title={`${moduleData.ind + 1 || ""}. Modulis`}
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF" }}
        />
        <Card style={{ margin: 15 }}>
            <Card>
                <Tabs>
                    {
                        languages.map((lang: any) => {
                            return <Tabs.TabPane key={lang.id} tab={lang.iso?.toUpperCase()} forceRender>
                                <Row gutter={[16, 16]}>
                                    <Col span={6}>
                                        <div>
                                            <b>Nosaukums:</b><br />
                                            <Input
                                                value={moduleData[`title_${lang.iso}`]}
                                                style={{ width: 220, marginTop: 8 }}
                                                onChange={(e: any) => {
                                                    e.persist();
                                                    setModuleData((data: any) => {
                                                        return {
                                                            ...data,
                                                            [`title_${lang.iso}`]: e.target.value
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <b>Apraksts</b>
                                        <Input.TextArea
                                            value={moduleData[`description_${lang.iso}`]}
                                            style={{ marginTop: 8 }}
                                            autoSize={{ maxRows: 5, minRows: 5 }}
                                            onChange={(e: any) => {
                                                e.persist();
                                                setModuleData((data: any) => {
                                                    return {
                                                        ...data,
                                                        [`description_${lang.iso}`]: e.target.value
                                                    }
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                        })
                    }
                </Tabs>
                <Row>
                    <Col span={24} style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                        <Button type="primary" onClick={saveModuleData}>Saglabāt</Button>
                    </Col>
                </Row>
            </Card>
            <Button
                style={{ width: "100%", padding: 13, height: "unset", margin: "30px 0" }}
                onClick={goToExamQuestions}
            >
                <b>Moduļa gala pārbaudījuma uzstādījumi</b>
            </Button>
            {
                chapters.map((chapter: any, index: any) => {
                    return <ChapterCard
                        key={index}
                        seq={index + 1}
                        lastInd={chapters.length - 1}
                        chapter={chapter}
                        module={mid}
                        category={id}
                        removeChapter={removeChapter}
                        saveChapters={saveChapters}
                        moveChapter={moveChapter}
                    />
                })
            }
            <Row justify="space-between" style={{ marginTop: 30 }}>
                <Button onClick={addChapter}>
                    Pievienot Nodaļu +
                </Button>
                <Button type="primary" onClick={saveChapters}>
                    Saglabāt
                </Button>
            </Row>
        </Card>
    </Layout>
}