import React, { useEffect } from "react";
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Layout, PageHeader, Row, TimePicker } from "antd";
import { withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import moment from "moment";

export const NewMedicalGroup = withRouter((props: any) => {
    // const fetch = async () => {
    //     await http.get(`/rest/medical-groups`).then((response: any) => {
    //         console.log(response)
    //     })
    // }

    useEffect(() => {
        // fetch();
    }, [])

    const saveGroup = (values: any) => {
        values.medical_date = moment(values.medical_date).format("YYYY-MM-DD");
        values.online_date = moment(values.online_date).format("YYYY-MM-DD");
        values.medical_from = moment(values.medical_from_to[0]).format("HH:mm");
        values.medical_to = moment(values.medical_from_to[1]).format("HH:mm");
        values.online_from = moment(values.online_from_to[0]).format("HH:mm");
        values.online_to = moment(values.online_from_to[1]).format("HH:mm");
        console.log(values)
        http.post("/rest/medical-groups", values).then(() => {
            window.location.replace('/groups-medical/groups');
        });
    }

    return (
        <Layout>
            <PageHeader
                title="Pirmās palīdzības mācību grupas"
                onBack={props.history.goBack}
                style={{ backgroundColor: "#FFFFFF", width: "100%" }}
            />
            <Row>
                <Col span={24} style={{ padding: 15 }}>
                    <Card>
                        <Form
                            layout="vertical"
                            onFinish={saveGroup}
                        >
                            <Row>
                                <Col span={24} style={{ padding: "0 20px" }}>
                                    <Form.Item name="name" label="Grupas nosaukums" style={{ maxWidth: 200 }}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Card style={{ margin: "0 20px 20px" }} title="1. Teorētiskā nodarbība">
                                        <Row gutter={16}>
                                            <Col>
                                                <Form.Item name="online_date" label="Datums">
                                                    <DatePicker />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item name="online_from_to" label="Nodarbības laiks">
                                                    <TimePicker.RangePicker format="HH:mm" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="zoom_link" label="Zoom saite">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="zoom_info" label="Zoom papildus informācija">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card style={{ margin: "0 20px 20px" }} title="1. Praktiskā nodarbība">
                                        <Row gutter={16}>
                                            <Col>
                                                <Form.Item name="medical_date" label="Datums">
                                                    <DatePicker />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item name="medical_from_to" label="Nodarbības laiks">
                                                    <TimePicker.RangePicker format="HH:mm" />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item name="slots" label="Studentu skaits">
                                                    <InputNumber max={15} min={1} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="address" label="Adrese">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={12} style={{ padding: "0 20px" }}>
                                    <Form.Item name="comment" label="Komentārs">
                                        <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ padding: "0 20px" }}>
                                <Form.Item name="admin_comment" label="Sistēmas komentārs">
                                        <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Button htmlType="submit" type="primary">Saglabāt</Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
})