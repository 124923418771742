import React, { useEffect, useState } from "react";
import { Button, Card, Layout, PageHeader, Table, Tag, Typography } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import { useHistory, withRouter } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { http } from "../../helpers/http";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";

const { Title, Text } = Typography;

export const GroupTemplatesPage = withRouter((props: any) => {
  const history = useHistory();
  const [allTpls, setTpls] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [classifiers, setClassifiers] = useState({} as any);
  const [pagination, setPagination] = useState(
    {} as any
  );

  const fetchClassifiers = async () => {
    const cities = await http.get("/rest/cities");
    const categories = await http.get("/rest/categories");
    const languages = await http.get("/rest/languages");
    const course_type = await http.get("/rest/study-groups-types");

    setClassifiers({
      cities: cities.data.data,
      categories: categories.data.data,
      languages: languages.data.data,
      course_type: course_type.data.data,
    })
  }

  const fetch = async (params: any = { pagination: { current: 1 } }) => {
    await http.get("/rest/study-groups-templates", { params: getParams(params) }).then((response: any) => {
      console.log(response.data)
      const { data, meta } = response.data;
      setTpls(data);
      setPagination({
        current: meta.current_page,
        pageSize: meta.per_page,
        total: meta.total
      });
      console.log(response.data.data);
    });
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  const getParams = (params: any) => {
    console.log(params)
    return {
      page: params.pagination.current,
      sortField: params.sortField,
      sortOrder: params.sortOrder,
      filters: params.filters,
    }
  }

  const deleteTpl = (event: any, id: any) => {
    event.stopPropagation();
    http.delete(`rest/study-groups-templates/${id}`).then((response: any) => {
      fetch();
    })
  }

  useEffect(() => {
    fetchClassifiers().then(() =>
      fetch().then(() => {
        setLoading(false);
      })
    )

  }, [])

  const cols = [
    {
      title: 'Nosaukums',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps()
    },
    {
      title: 'Pilsētas',
      dataIndex: 'cities',
      key: 'cities',
      render: (cities: any) => cities.map((city: any) => <Tag key={city.id}>{city.value}</Tag>),
      filters: classifiers.cities !== undefined ? classifiers.cities.map((city: any) => {
        return { text: city.value, value: city.id }
      }) : [],
    },
    {
      title: 'Kategorija',
      dataIndex: 'category',
      key: 'category',
      render: (category: any) => category.value,
      filters: classifiers.categories !== undefined ? classifiers.categories.map((cat: any) => {
        return { text: cat.value, value: cat.id }
      }) : [],
    },
    {
      title: 'Valodas',
      dataIndex: 'languages',
      key: 'languages',
      render: (languages: any) => languages.map((language: any) => <Tag key={language.id}>{language.value}</Tag>),
      filters: classifiers.languages !== undefined ? classifiers.languages.map((lang: any) => {
        return { text: lang.value, value: lang.id }
      }) : [],
    },
    {
      title: 'Apmācības veids',
      dataIndex: 'course_type',
      key: 'course_type',
      render: (course: any) => course.value,
      filters: classifiers.course_type !== undefined ? classifiers.course_type.map((type: any) => {
        return { text: type.value, value: type.id }
      }) : [],
    },
    {
      title: '',
      dataIndex: 'sg_template_id',
      key: 'sk_template_id',
      render: (id: any) => <Button onClick={(event: any) => deleteTpl(event, id)} style={{ border: 0, fontSize: 18 }}><DeleteTwoTone twoToneColor="#ff1818" /></Button>
    }
  ]
  return (
    <Layout>
      <PageHeader
        title={props.location.state === undefined ? "Grupu sagataves" : "Izvēlieties sagatavi"}
        onBack={() => history.goBack()}
        style={{ backgroundColor: "#FFFFFF" }}
      />
      <Card style={{ width: "100%" }}>
        {/* <Button onClick={()=>console.log(allTpls)}>PROPS</Button> */}
        <Title level={4}>Saglabātās sagataves</Title>
        <Table
          rowKey={(record: any) => record.id}
          dataSource={allTpls}
          columns={cols}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                console.log(record)
                props.location.state === undefined
                  ? history.push(`/groups/templates/${record.sg_template_id}`) //Pēc ID
                  : history.push({ pathname: '/groups/new', state: record })
              }, // click row
              onDoubleClick: event => { }, // double click row
              onContextMenu: event => { }, // right button click row
              onMouseEnter: event => { }, // mouse enter row
              onMouseLeave: event => { }, // mouse leave row
            };
          }}
          pagination={{
            showSizeChanger: false,
            size: 'small',
            showTotal: (total: any) => <div>Kopā {total} Sagataves</div>,
            position: ['bottomCenter'],
            pageSizeOptions: ['15'],
            defaultPageSize: 15,
            ...pagination
          }}
          onChange={handleTableChange}
          loading={loading}
        />
        <Link to="/groups/templates/new"><Button type="primary">Jauna sagatave</Button></Link>
      </Card>
    </Layout>
  )
})