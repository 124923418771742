import { Button, Card, Input, message, Row } from "antd"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { http } from "../../helpers/http";
import { AxiosResponse } from 'axios';

type DrivingBasicSettingsProps = {
    categories: any;
    studentCategory: any;
}

export const DrivingBasicSettings = ({ categories, studentCategory }: DrivingBasicSettingsProps) => {
    const { id }: { id: string } = useParams();
    const [drivingLimit, setDrivingLimit] = useState(null as null | number);
    const [defaultLimit, setDefaultLimit] = useState(0 as number);
    const [loading, setLoading] = useState(false);

    const saveLimit = () => {
        setLoading(true)
        http.post(`/rest/change-multi-cat/${id}`, {
            multidata: {
                [studentCategory.id]: {
                    custom_driving_hours: drivingLimit
                }
            }
        }).then((res: AxiosResponse) => {
            setLoading(false)
            message.success("Braukšanu limits ir atjaunots")
        }).catch(() => {
            setLoading(false)
            message.error("Kļūda!")
        })
    }

    useEffect(() => {
        if (categories.length > 0) {
            setDefaultLimit(categories.find((cat: any) => cat.id === studentCategory.category_id).daily_driving_limit);
            setDrivingLimit(studentCategory.custom_driving_hours);
        }
    }, [categories, studentCategory])

    return <Card style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 8 }}>
            <p style={{ margin: 0 }}>Braukšanas limits dienā (akadēmiskās stundas)</p>
            <small>Ja lauks ir tukšs, tad pēc noklusējuma ierobežojums ir {defaultLimit} akadēmiskās stundas</small>
        </div>
        <Input
            type="number"
            style={{ width: 120 }}
            value={drivingLimit ?? undefined}
            onChange={(e) => {
                e.persist();
                setDrivingLimit(e.target.value === undefined ? null : Number(e.target.value))
            }}
        />
        <Row justify="end">
            <Button
                type="primary"
                onClick={saveLimit}
                disabled={drivingLimit === null}
                loading={loading}
            >
                Saglabāt
            </Button>
        </Row>
    </Card>
}