import { Row, Col, Select, Input, Checkbox, DatePicker } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { StudentFilter } from "./StudentSpamFilterLayout";

export const StudyGroupFilterForm = (props: any) => {
    const { setStudentFilter, classifiers } = props;
    const studentFilter: StudentFilter = props.studentFilter;
    const [multiplier, setMultiplier] = useState(1);

    const setStudyGroupFilter = (data: StudentFilter) => {
        setStudentFilter((prev: StudentFilter) => {
            return {
                ...prev,
                ...data
            }
        })
    }

    const deleteFilterField = (key: string) => {
        setStudentFilter((prev: StudentFilter) => {
            const copy = JSON.parse(JSON.stringify(prev));
            delete copy[key]
            return {
                ...copy
            }
        })
    }

    return <Row gutter={24}>
        <Col>
            <p style={{ marginBottom: 4 }}>Laika periods no grupas sākuma datuma</p>
            <DatePicker.RangePicker
                style={{ marginBottom: 20 }}
                value={
                    studentFilter.days_since_start_of_study_from
                        ? [
                            moment(studentFilter.days_since_start_of_study_from, "YYYY-MM-DD"),
                            moment(studentFilter.days_since_start_of_study_till, "YYYY-MM-DD"),
                        ]
                        : null
                }
                onChange={(date: any) => {
                    if (!date) {
                        deleteFilterField("days_since_start_of_study_from");
                        deleteFilterField("days_since_start_of_study_till");
                        return;
                    }
                    setStudyGroupFilter({
                        days_since_start_of_study_from: date[0].format("YYYY-MM-DD"),
                        days_since_start_of_study_till: date[1].format("YYYY-MM-DD"),
                    })
                }}
            />
            <p style={{ marginBottom: 4 }}>Apmācības veids</p>
            <Select
                value={studentFilter.study_type}
                style={{ width: 200, marginBottom: 20 }}
                mode="multiple"
                onChange={(e: any) => {
                    if (e.length === 0) {
                        deleteFilterField("study_type");
                        return;
                    }
                    setStudyGroupFilter({ study_type: e });
                }}
            >
                <Select.Option value={"full_time"}>Klātiene</Select.Option>
                <Select.Option value={"e_learning"}>Online</Select.Option>
            </Select>
            <p style={{ marginBottom: 4 }}>Pilsēta, kur klātienes nod.</p>
            <Select
                value={studentFilter.study_city}
                style={{ width: 200, marginBottom: 20 }}
                mode="multiple"
                onChange={(e: any) => {
                    if (e.length === 0) {
                        deleteFilterField("study_city");
                        return;
                    }
                    setStudyGroupFilter({ study_city: e });
                }}
            >
                {
                    classifiers?.cities?.map((city: any) => {
                        return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                    })
                }
            </Select>
        </Col>
        <Col>
            <p style={{ marginBottom: 4 }}>Ir apgūtas teorijas nodarbības (skaits/intervāls)</p>
            <div>
                <Input
                    value={studentFilter.attended_lessons_from}
                    type="number"
                    style={{ width: 80, marginBottom: 20, marginRight: 10 }}
                    onChange={(e: any) => {
                        if (e.target.value.length === 0) {
                            deleteFilterField("attended_lessons_from");
                            return;
                        }
                        setStudyGroupFilter({ attended_lessons_from: Number(e.target.value) });
                    }}
                />
                <Input
                    value={studentFilter.attended_lessons_to}
                    type="number"
                    style={{ width: 80, marginBottom: 20 }}
                    onChange={(e: any) => {
                        if (e.target.value.length === 0) {
                            deleteFilterField("attended_lessons_to");
                            return;
                        }
                        setStudyGroupFilter({ attended_lessons_to: Number(e.target.value) });
                    }}
                />
            </div>
            <p style={{ marginBottom: 4 }}>Nav apgūtas teorijas nodarbības (skaits/intervāls)</p>
            <div>
                <Input
                    value={studentFilter.unattended_lessons_from}
                    type="number"
                    style={{ width: 80, marginBottom: 20, marginRight: 10 }}
                    onChange={(e: any) => {
                        if (e.target.value.length === 0) {
                            deleteFilterField("unattended_lessons_from");
                            return;
                        }
                        setStudyGroupFilter({ unattended_lessons_from: Number(e.target.value) });
                    }}
                />
                <Input
                    value={studentFilter.unattended_lessons_to}
                    type="number"
                    style={{ width: 80, marginBottom: 20 }}
                    // disabled={studentFilter.unattended_lessons_from === undefined}
                    onChange={(e: any) => {
                        // if (studentFilter.unattended_lessons_from === undefined) {
                        //     deleteFilterField("unattended_lessons_to");
                        //     return;
                        // }
                        if (e.target.value.length === 0) {
                            deleteFilterField("unattended_lessons_to");
                            return;
                        }
                        setStudyGroupFilter({ unattended_lessons_to: Number(e.target.value) });
                    }}
                />
            </div>
            <p style={{ marginBottom: 4 }}>Ir kavētas nodarbība (skaits/intervāls)</p>
            <div>
                <Input
                    value={studentFilter.skipped_lessons_from}
                    type="number"
                    style={{ width: 80, marginBottom: 20, marginRight: 10 }}
                    onChange={(e: any) => {
                        if (e.target.value.length === 0) {
                            deleteFilterField("skipped_lessons_from");
                            return;
                        }
                        setStudyGroupFilter({ skipped_lessons_from: Number(e.target.value) });
                    }}
                />
                <Input
                    value={studentFilter.skipped_lessons_to}
                    type="number"
                    style={{ width: 80, marginBottom: 20 }}
                    onChange={(e: any) => {
                        if (e.target.value.length === 0) {
                            deleteFilterField("skipped_lessons_to");
                            return;
                        }
                        setStudyGroupFilter({ skipped_lessons_to: Number(e.target.value) });
                    }}
                />
            </div>
        </Col>
        <Col>
            <Row gutter={10}>
                <Col>
                    <p style={{ marginBottom: 4 }}>Kursanti kam nav piesaistīta grupa</p>
                    <Select
                        value={studentFilter.have_group}
                        style={{ width: 200, marginBottom: 20 }}
                        onChange={(e: any) => {
                            if (e.length === 0) {
                                deleteFilterField("have_group");
                                return;
                            }
                            setStudyGroupFilter({ have_group: e });
                        }}
                    >
                        {
                            studentFilter.have_group !== undefined
                                ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                                : <></>
                        }
                        <Select.Option value={1}>Jā</Select.Option>
                        <Select.Option value={0}>Nē</Select.Option>
                    </Select>
                    <p style={{ marginBottom: 4 }}>Ir CSDD teorijas eksāmens</p>
                    <Select
                        value={studentFilter.csdd_theory_passed}
                        style={{ width: 200, marginBottom: 20 }}
                        onChange={(e: any) => {
                            if (e.length === 0) {
                                deleteFilterField("csdd_theory_passed");
                                return;
                            }
                            setStudyGroupFilter({ csdd_theory_passed: e });
                        }}
                    >
                        {
                            studentFilter.csdd_theory_passed !== undefined
                                ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                                : <></>
                        }
                        <Select.Option value={1}>Jā</Select.Option>
                        <Select.Option value={0}>Nē</Select.Option>
                    </Select>
                    <p style={{ marginBottom: 4 }}>Ir skolas teorijas eksāmens</p>
                    <Select
                        value={studentFilter.school_theory_exam_passed}
                        style={{ width: 200, marginBottom: 20 }}
                        onChange={(e: any) => {
                            if (e.length === 0) {
                                deleteFilterField("school_theory_exam_passed");
                                return;
                            }
                            setStudyGroupFilter({ school_theory_exam_passed: e });
                        }}
                    >
                        {
                            studentFilter.school_theory_exam_passed !== undefined
                                ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                                : <></>
                        }
                        <Select.Option value={1}>Jā</Select.Option>
                        <Select.Option value={0}>Nē</Select.Option>
                    </Select>
                </Col>
                <Col>
                    <p style={{ marginBottom: 4 }}>Pabeigta teorijas apmācība</p>
                    <Select
                        value={studentFilter.school_theory_finished}
                        style={{ width: 200, marginBottom: 20 }}
                        onChange={(e: any) => {
                            if (e.length === 0) {
                                deleteFilterField("school_theory_finished");
                                return;
                            }
                            setStudyGroupFilter({ school_theory_finished: e });
                        }}
                    >
                        {
                            studentFilter.school_theory_finished !== undefined
                                ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                                : <></>
                        }
                        <Select.Option value={1}>Jā</Select.Option>
                        <Select.Option value={0}>Nē</Select.Option>
                    </Select>
                    <p style={{ marginBottom: 4 }}>Nav pieteicis teorijas eksāmenu</p>
                    <Select
                        value={studentFilter.school_theory_exam_not_applied}
                        style={{ width: 200, marginBottom: 20 }}
                        onChange={(e: any) => {
                            if (e.length === 0) {
                                deleteFilterField("school_theory_exam_not_applied");
                                return;
                            }
                            setStudyGroupFilter({ school_theory_exam_not_applied: e });
                        }}
                    >
                        {
                            studentFilter.school_theory_exam_not_applied !== undefined
                                ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                                : <></>
                        }
                        <Select.Option value={1}>Jā</Select.Option>
                        <Select.Option value={0}>Nē</Select.Option>
                    </Select>
                </Col>
            </Row>
        </Col>
    </Row>
}