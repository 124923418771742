import { Layout, PageHeader, Card, Row, Checkbox, message, Col, Spin, Button, Modal } from "antd";
import { ExclamationCircleTwoTone, UnlockTwoTone, LockTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { http } from "../../helpers/http";
import moment from "moment";
import { useTranslation } from "react-i18next";

const activityTypes = {
    group_change: "Grupu maiņa",
    auto_notifications: "Automātiskie paziņojumi",
    status_change: "Statusa maiņa",
    manual: "Menedžera komentārs"
}

const status = {
    "school_exam": "Novirzīts skolas braukšanas eksāmenam",
    "csdd": "Novirzīts CSDD braukšanas eksāmenam",
    "e_learning": "E-apmācība",
    "extra_pmp": "Papildus pirmās palīdzības apmācība",
    "extra_medical": "Papildus medicīniskās izziņas pakalpojums",
    "cancelled_individual_driving_registration": "Students atteica individuālas braukšanas reģistrāciju",
    "student_want_med": "Students pieteicis medicīnisko izziņu",
    "student_want_pmp": "Students ir pieteicis pirmo medicīnisko palīdzību",
    "study_group_status_change": "Nomainīts rezervācijas veids grupā"
} as any;

export const DelegatedReminders = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [delegatedJobs, setDelegatedJobs] = useState([] as any);
    const [users, setUsers] = useState([] as any);

    const fetchCrms = async () => {
        setLoading(true);
        await http.get(`/rest/get-delegated-crms`).then((response: any) => {
            setDelegatedJobs(response.data.data)
            console.log(response.data.data);
            setLoading(false);
        }).catch(() => {
            message.error("Neizdevās ielādēt pieteikumus");
            setLoading(false);
        })
    };

    const fetchUsers = async () => {
        setLoading(true);
        await http.get(`/rest/admins`).then((response: any) => {
            setUsers(response.data.data.filter((user: any) => user.active === 1))
            setLoading(false);
        }).catch(() => {
            message.error("Neizdevās ielādēt lietotājus");
            setLoading(false);
        })
    };

    const unsetJob = (id: any) => {
        Modal.confirm({
            title: 'Vai tiešām vēlaties atsiet pieteikumu?',
            icon: <ExclamationCircleTwoTone />,
            onOk() {
                setLoading(true);
                http.get(`rest/unset-delegated-crm/${id}`).then(() => {
                    fetchCrms();
                    setLoading(false);
                }).catch(() => {
                    setLoading(false)
                    message.error("Neizdevās atsiet pieteikumu")
                });
            }
        });
    }

    const unsetAll = () => {
        Modal.confirm({
            title: 'Vai tiešām vēlaties atsiet visus pieteikumus?',
            icon: <ExclamationCircleTwoTone />,
            onOk() {
                setLoading(true);
                http.get(`rest/unset-delegated-crms`).then(() => {
                    fetchCrms();
                    setLoading(false);
                }).catch(() => {
                    setLoading(false)
                    message.error("Neizdevās atsiet pieteikumus")
                });
            }
        });
    }

    useEffect(() => {
        fetchUsers();
        fetchCrms();
    }, []);

    return <Layout>
        <PageHeader
            style={{ backgroundColor: "#FFFFFF" }}
            title={"Pieteikumu piekļuves"}
            onBack={history.goBack}
        />
        <Card style={{ width: "100%" }} className="crm-card">
            {
                loading
                && <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(200, 200, 200, .3)", zIndex: 100 }} >
                    <Spin spinning={loading} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                </div>
            }
            <b>*Šobrīd lietotājiem piešķirtie pieteikumi</b>
            {
                delegatedJobs.length === 0
                    ? <h3 style={{ marginTop: 20 }}>Nav piešķirts neviens pieteikums</h3>
                    : delegatedJobs.map((job: any, index: any) => {
                        const user = users.find((user: any) => user.id === job?.delegated_to);
                        return <Card
                            key={index}
                            style={{ overflow: "auto", marginTop: 20 }}
                        >
                            <Row style={{ flexWrap: "nowrap" }}>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b>Tips:</b>
                                    <p style={{ marginTop: 10 }}>{activityTypes[job?.activity_type as keyof typeof activityTypes]}</p>
                                    {
                                        !!job.from_app
                                        && <p style={{ color: "red", fontSize: 10 }}>
                                            (APP)
                                        </p>
                                    }
                                </div>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b>Klients:</b>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ color: !!job.with_requisites ? "green" : "black" }}>{job?.student?.name}</span>
                                        {
                                            !!job.scholarship
                                            && <>
                                                <br />
                                                <span style={{ color: "#0200ff" }}>Stipendija</span>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b>Kategorija:</b>
                                    <p style={{ marginTop: 10 }}>{job?.category ? job?.category.value : ""}</p>
                                </div>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b>Pilsēta:</b>
                                    <p style={{ marginTop: 10 }}>{job?.city?.value}</p>
                                </div>
                                {
                                    job?.fake_group === String(job?.study_group_id)
                                        ? <></>
                                        : job?.study_group.start_time
                                            ? <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0", minWidth: 125 }}>
                                                <b>Grupa:</b>
                                                <div style={{ marginTop: 10 }}>
                                                    <p style={{ fontSize: 12 }}>
                                                        {job?.study_group?.start_date}
                                                    </p>
                                                    <p style={{ fontSize: 12 }}>
                                                        {moment(job?.study_group?.start_time, "HH:mm:ss").format("HH:mm") +
                                                            " - " + moment(job?.study_group?.end_time, "HH:mm:ss").format("HH:mm")}
                                                    </p>
                                                </div>
                                            </div>
                                            : <></>
                                }
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0", minWidth: 200 }}>
                                    <b>Veids:</b>
                                    <p style={{ marginTop: 10, marginBottom: 3 }}>
                                        {
                                            status[job?.manager] === undefined
                                                ? job?.activity_type === "auto_notifications" || job?.activity_type === "group_change"
                                                    ? t(`auto_notifications.${job?.manager.trim()}`)
                                                    : job?.manager
                                                : status[job?.manager]
                                        }
                                    </p>
                                    {
                                        !!job?.from_app
                                        && <p style={{ fontSize: 10, color: "red" }}>
                                            (APP)
                                        </p>
                                    }
                                </div>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b style={{ whiteSpace: "nowrap" }}>Aktivitātes datums:</b>
                                    <p style={{ marginTop: 10 }}>{job?.next_action_date}</p>
                                </div>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b style={{ whiteSpace: "nowrap" }}>Piešķirts:</b>
                                    <p style={{ marginTop: 10 }}>{user?.first_name} {user?.last_name}</p>
                                    <Button
                                        type="primary"
                                        onClick={() => unsetJob(job?.id)}
                                    >
                                        Atsiet
                                    </Button>
                                </div>
                            </Row>
                        </Card>
                    })
            }
            {
                delegatedJobs.length > 0
                && <Row justify="end" style={{ paddingTop: 20 }}>
                    <Button
                        type="primary"
                        onClick={() => unsetAll()}
                    >
                        Atsiet visus pieteikumus
                    </Button>
                </Row>
            }
        </Card>
    </Layout>
}