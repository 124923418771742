import { Button, Modal, Row, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosResponse } from "axios";

type TemplatesListProps = {
    languages: [any];
    setTemplateForEdit: (data: any) => void;
    templates: any;
    refresh: () => void;
}

export const TemplatesList = ({ languages, setTemplateForEdit, templates, refresh }: TemplatesListProps) => {

    const deleteTemplate = (hash: string) => {
        Modal.confirm({
            onOk: () => {
                http.post(`rest/custom-notification-for-channels`, { hash }).then(() => {
                    message.success("Paziņojums dzēsts!");
                    refresh();
                }).catch(() => {
                    message.error("Kļūda!");
                    refresh();
                })
            },
            content: "Dzēst izvēlēto sagatavi?"
        })
    }

    const columns = [
        {
            title: "Nosaukums",
            render: (x: any, record: any) => {
                const hash = Object.keys(record)[0];
                return record[hash].title
            }
        },
        {
            title: "",
            render: (x: any, record: any) => {
                const hash = Object.keys(record)[0];
                return <Row justify="end">
                    <Button
                        type="primary"
                        style={{ marginRight: 10 }}
                        onClick={() => setTemplateForEdit(record)}
                    >
                        Labot
                    </Button>
                    <Button
                        danger
                        onClick={() => deleteTemplate(hash)}
                    >
                        Dzēst
                    </Button >
                </Row>
            },
            align: "end"
        },
    ] as any;

    return <div>
        <h1><b>Sagataves:</b></h1>
        <Table
            rowKey={record => Object.keys(record)[0]}
            dataSource={Object.keys(templates).map((key: string) => { return { [key]: templates[key] } })}
            columns={columns}
        />
    </div>
}