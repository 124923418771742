import { Layout, PageHeader, Card, Row, Table, Button, Modal, Select, Col, Checkbox } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { http } from "../../helpers/http";
import { MultiChannelLanguagesSpammer } from "../StudentSpamFilter/MultiChannelLanguagesSpammer";

export const CityDetails = () => {
    const history = useHistory();
    const { id, cid, lid } = useParams() as any;
    const [cities, setCities] = useState([] as any);
    const [city, setCity] = useState(id === "all" ? -1 : Number(id));
    const [categories, setCategories] = useState([] as any);
    const [category, setCategory] = useState(cid === "all" ? -1 : Number(cid));
    const [languages, setLanguages] = useState([] as any);
    const [language, setLanguage] = useState(lid === "all" ? -1 : Number(lid));
    const [data, setData] = useState(undefined as any);
    const [selectedStudents, setSelectedStudents] = useState({
        table: [],
        individual: []
    });
    const [studentsForSpam, setStudentsForSpam] = useState([] as any);

    const fetchLanguages = () => {
        http.get(`rest/languages`).then((res: AxiosResponse) => {
            setLanguages(res.data.data)
        })
    }

    const fetchCategories = () => {
        http.get(`rest/categories`).then((res: AxiosResponse) => {
            setCategories(res.data.data)
        })
    }

    const fetchCities = () => {
        http.get(`rest/cities`).then((res: AxiosResponse) => {
            setCities(res.data.data)
        })
    }

    const fetchData = () => {
        const trueCity = city > 0 ? city : "all"
        const trueCategory = category > 0 ? category : "all"
        const trueLanguage = language > 0 ? language : "all"
        http.get(`/rest/get-module-avg-details/${trueCity}/${trueCategory}`, { params: { language: trueLanguage } }).then((res: AxiosResponse) => {
            setData(res.data)
        })
    }

    const changeCity = () => {
        let cityID = city;
        let catID = category;
        let langID = language;
        Modal.confirm({
            title: "Mainīt pilsētu, kategoriju vai valodu",
            content: <div>
                <Select
                    defaultValue={city}
                    style={{ width: 200, marginRight: 10 }}
                    onChange={e => {
                        cityID = e
                    }}
                >
                    <Select.Option value={-1}>
                        Visas pilsētas
                    </Select.Option>
                    {
                        cities.map((city: any) => {
                            return <Select.Option value={city.id} key={city.id}>
                                {city.value}
                            </Select.Option>
                        })
                    }
                </Select>
                <Select
                    defaultValue={category}
                    style={{ width: 200 }}
                    onChange={e => {
                        catID = e
                    }}
                >
                    <Select.Option value={-1}>
                        Visas Kategorijas
                    </Select.Option>
                    {
                        categories.map((category: any) => {
                            return <Select.Option value={category.id} key={category.id}>
                                {category.value}
                            </Select.Option>
                        })
                    }
                </Select>
                <Select
                    defaultValue={language}
                    style={{ width: 200 }}
                    onChange={e => {
                        langID = e
                    }}
                >
                    <Select.Option value={-1}>
                        Visas Valodas
                    </Select.Option>
                    {
                        languages.map((language: any) => {
                            return <Select.Option value={language.id} key={language.id}>
                                {language.value}
                            </Select.Option>
                        })
                    }
                </Select>
            </div>,
            onOk: () => {
                setCity(cityID);
                setCategory(catID);
                setLanguage(langID);
                history.push(`/groups/online-stats/${cityID < 0 ? "all" : cityID}/${catID < 0 ? "all" : catID}/${langID < 0 ? "all" : langID}`)
            }
        });
    }

    const columns = [
        {
            title: "Modulis",
            dataIndex: "name",
            // align: "center"
        },
        {
            title: "Mācās",
            dataIndex: "learning",
            align: "center"
        },
        {
            title: "Ā|V|L",
            align: "center",
            render: (nothing: null, record: any) => {
                return `${record.fast}|${record.medium}|${record.slow}`
            }
        },
        {
            title: "Darbības",
            dataIndex: "module_id",
            align: "center",
            render: (moduleId: any) => {
                return <Link to={`/groups/online-stats/${id}/${cid}/${lid}/${moduleId}`}>
                    <Button>
                        Detalizēti
                    </Button>
                </Link>
            }
        }
    ] as any

    const getIndividualStudents = () => {
        const dataCopy = JSON.parse(JSON.stringify(selectedStudents));
        const individualStudents = [...dataCopy.table, ...dataCopy.individual].filter((value: any, index: any, self: any) => {
            return self.indexOf(value) === index;
        });
        setStudentsForSpam(individualStudents);
    }

    useEffect(() => {
        fetchCities();
        fetchCategories();
        fetchLanguages();
    }, []);

    useEffect(() => {
        fetchData();
    }, [city, category, language]);

    useEffect(() => {
        getIndividualStudents();
    }, [selectedStudents]);

    return <Layout>
        <PageHeader
            title="Online apmācības statistika"
            style={{ backgroundColor: "#FFFFFF" }}
            onBack={history.goBack}
        />
        <Card style={{ margin: 20 }}>
            <h2 style={{ textAlign: "center" }}>
                {
                    city < 0
                        ? "Visās pilsētās"
                        : cities.find((item: any) => {
                            return item.id === city
                        })?.value
                },
                {
                    category < 0
                        ? " Visas kategorijas"
                        : " " + categories.find((item: any) => {
                            return item.id === category
                        })?.value + " kategorija"
                }
                {
                    language < 0
                        ? " Visas valodas"
                        : " " + languages.find((item: any) => {
                            return item.id === language
                        })?.value + " valoda"
                }
                <Button
                    type="text"
                    style={{ marginLeft: 8, fontSize: 18 }}
                    onClick={changeCity}
                >
                    <EditOutlined />
                </Button>
            </h2>
            <table style={{ borderCollapse: "collapse", border: "1px solid #e1e1e1", marginBottom: 20 }}>
                <tbody>
                    <tr>
                        <td style={{ border: "1px solid #e1e1e1", padding: 10 }}>Nav uzsākuši:</td>
                        <td style={{ border: "1px solid #e1e1e1", padding: 10 }}><b>{data === undefined ? "--" : data.not_started}</b></td>
                    </tr>
                </tbody>
            </table>
            <Table
                rowKey={record => record.name + record.students.length + record.fast + record.learning}
                columns={columns}
                rowSelection={{
                    type: "checkbox",
                    onChange: (selectedRowKeys: any, selectedRows: any) => {
                        setSelectedStudents((prev: any) => {
                            return {
                                ...prev,
                                table: selectedRows.reduce((prevValue: any, currentValue: any) => {
                                    return [...prevValue, ...currentValue.students]
                                }, [])
                            }
                        })
                    }
                }}
                dataSource={data !== undefined ? data.data : []}
                pagination={false}
            />
            <Row gutter={20}>
                <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
                    <table style={{ borderCollapse: "collapse", border: "1px solid #e1e1e1", marginTop: 20, marginBottom: 10 }}>
                        <tbody>
                            <tr>
                                <td style={{ border: "1px solid #e1e1e1", padding: 10 }}>Pēc vienas nedēļas pabeigs:</td>
                                <td style={{ border: "1px solid #e1e1e1", padding: 10, width: 60, textAlign: "center" }}><b>{data === undefined ? "--" : data.after_week}</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{ borderCollapse: "collapse", border: "1px solid #e1e1e1", marginBottom: 10 }}>
                        <tbody>
                            <tr>
                                <td style={{ border: "1px solid #e1e1e1", padding: 10 }}>Pēc divām nedēļām pabeigs:</td>
                                <td style={{ border: "1px solid #e1e1e1", padding: 10, width: 60, textAlign: "center" }}><b>{data === undefined ? "--" : data.after_two_week}</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{ borderCollapse: "collapse", border: "1px solid #e1e1e1", marginBottom: 10 }}>
                        <tbody>
                            <tr>
                                <td style={{ border: "1px solid #e1e1e1", padding: 10 }}>Pēc trijām nedēļām pabeigs:</td>
                                <td style={{ border: "1px solid #e1e1e1", padding: 10, width: 60, textAlign: "center" }}><b>{data === undefined ? "--" : data.after_three_week}</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{ borderCollapse: "collapse", border: "1px solid #e1e1e1", marginBottom: 10 }}>
                        <tbody>
                            <tr>
                                <td style={{ border: "1px solid #e1e1e1", padding: 10 }}>Pēc mēneša pabeigs:</td>
                                <td style={{ border: "1px solid #e1e1e1", padding: 10, width: 60, textAlign: "center" }}><b>{data === undefined ? "--" : data.after_month}</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <table
                        style={{ borderCollapse: "collapse", border: "2px solid #99daed", cursor: "pointer", backgroundColor: "#f0f0f0" }}
                        onClick={() => history.push(`/groups/online-stats/${id}/${cid}/${lid}/not_signed_to_lesson`)}
                    >
                        <tbody>
                            <tr>
                                <td style={{ border: "2px solid #99daed", padding: 10 }}>
                                    <Row justify="space-between">
                                        <p style={{ margin: 0 }}>Pabeiguši un nav pieteikušies klātienei</p>
                                        <Checkbox
                                            style={{ marginLeft: 10 }}
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                e.persist();
                                                setSelectedStudents((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        individual: e.target.checked ? data.done_not_signed.students : []
                                                    }
                                                })
                                            }}
                                            disabled={data === undefined}
                                        />
                                    </Row>
                                </td>
                                <td style={{ border: "2px solid #99daed", padding: 10, width: 60, textAlign: "center" }}><b>{data === undefined ? "--" : data.done_not_signed.count}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col span={16} style={{ paddingTop: 20 }}>
                    <p>Atlasīti <b>{studentsForSpam.length}</b> studenti</p>
                    <MultiChannelLanguagesSpammer
                        students={studentsForSpam}
                        languages={languages}
                    />
                </Col>
            </Row>
        </Card>
    </Layout>
}