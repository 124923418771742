import { Tabs, Button } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
//@ts-ignore
import ReactHtmlParser from 'react-html-parser';

type StudentAgreementsProps = {
    id: string;
}

export const StudentAgreements = ({ id }: StudentAgreementsProps) => {
    const [agreements, setAgreements] = useState([] as any);

    const getAgreements = async () => {
        await http.get(`/rest/student-agreements/${id}`).then((response: any) => {
            console.log(response.data.data)
            // const agreements = response.data.data;
            // if (agreements.length > 0) {
            //     const allAgreementKeys = Object.keys(agreement[0]).filter((item: any) => {
            //         return item.includes("agreement");
            //     })
            //     const allAgreements = allAgreementKeys.map((key: any) => {
            //         return { [key]: agreement[0][key] }
            //     })
            //     this.setState({ agreement: allAgreements });
            // }
            setAgreements(response.data.data)
        })
    };

    useEffect(() => {
        getAgreements();
    }, []);

    useEffect(() => {
        console.log(agreements)
    }, [agreements])

    return <Tabs defaultActiveKey="1">
        {
            agreements.map((agreement: any, index: number) => {
                return <Tabs.TabPane tab={agreement.category.name} key={index}>
                    {
                        agreement.agreement
                            ? <Tabs>
                                {
                                    Object.keys(agreement.agreement).filter((key: any) => {
                                        return key.includes("agreement");
                                    }).map((agreementKey: any, ind: any) => {
                                        const language = agreementKey.split("_");
                                        return <Tabs.TabPane key={String(ind + 1)} tab={language[language.length - 1].toUpperCase()}>
                                            <div className="agrement-wrap-box">
                                                {ReactHtmlParser(agreement.agreement[agreementKey])}
                                            </div>
                                        </Tabs.TabPane>
                                    })
                                }
                            </Tabs>
                            : "Nav līguma"
                    }
                </Tabs.TabPane>
            })
            //     this.state.agreement.map((agreement: any, index: any) => {
            //         const key = Object.keys(agreement)[0];
            // const language = key.split("_");
            // return <Tabs.TabPane key={String(index + 1)} tab={language[language.length - 1].toUpperCase()}>
            //     <div className="agrement-wrap-box">
            //         {ReactHtmlParser(agreement[key])}
            //     </div>
            //     <Button onClick={() => this.setState({ showAgreement: false })}>Paslēpt līgumu</Button>
            // </Tabs.TabPane>
            //     })
        }
    </Tabs>
}