import { Button, Space, Table, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { DeleteOutlined } from "@ant-design/icons";

const { confirm } = Modal;

const columns = [
    {
        title: <Space><span>Summa ar PVN</span><span>/</span><span>Pozīcijas bez PVN</span></Space>,
        width: 20,
        key: 'sum',
        render: (data: any) => {
            const prices = data.student_lesson.lesson_time_slot.lesson_time_slot_prices
            return <Space direction="vertical">
                <span style={{ fontWeight: "bold" }}>{(data.amount * -1)} {data.bonus_payment_id && data.promo && Number(data.amount) > 0 ? "EIN" : "EUR"}</span>
                {prices.map((p: any, i: any) => {
                    // console.log(p)
                    return <Space key={i}>
                        <span>{(p.rate_calculated.instructor_rate_total)?.toFixed(2)}</span>
                        <span>(PVN {Number(p.rate_vat)}%)</span>
                        <span>{p.position}</span>
                    </Space>
                })}
            </Space>;
        }
    },
    {
        title: 'Students',
        width: 30,
        key: 'student',
        render: (data: any) => {
            const student = data.student_lesson.student;
            return `${student.first_name} ${student.last_name}`;
        }
    },
    {
        title: 'Nodarbības datums',
        width: 30,
        key: 'date',
        render: (data: any) => {
            const date = data.student_lesson.lesson_time_slot.date;
            return date;
        }
    },
    {
        title: 'Kategorija (pakalpojums)',
        width: 30,
        key: 'category',
        render: (data: any) => {
            const instructor_service = data.student_lesson.lesson_time_slot.instructor_service;
            return <Space direction="vertical">
                <span>{instructor_service.category.value} kategorija</span>
                <span>{instructor_service.vehicle}; {instructor_service.licence_plate}</span>
            </Space>;
        }
    },
];

function convertDataToCSV(data: any) {
    const rows = data.map((row: any) => {
        const timeSlot = row.student_lesson.lesson_time_slot;
        const prices = timeSlot.lesson_time_slot_prices
            .reduce((object: any, p: any) => {
                object[p.position] = (p.rate * (1 + p.rate_vat / 100))?.toFixed(2);
                object[`${p.position} % PVN`] = Number(p.rate_vat)?.toFixed(2);
                return object;
            }, {});
        const instructor_service = timeSlot.instructor_service;

        return {
            sum: row.amount * -1,
            ...prices,
            student: `${row.student_lesson.student.first_name} ${row.student_lesson.student.last_name}`,
            how_many_lessons: timeSlot.how_many_lessons == 5 ? 1 : timeSlot.how_many_lessons,
            date: timeSlot.date,
            time_from: timeSlot.time_from,
            time_to: timeSlot.time_to,
            category: instructor_service.category.value,
            vehicle: `${instructor_service.vehicle}; ${instructor_service.licence_plate}`
        };
    });

    let columns: any = [];
    rows.forEach((element: any) => {
        Object.keys(element).forEach((col: any) => {
            columns.push(col);
        });
    });

    columns = columns.filter((value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    });

    return Papa.unparse(rows, {
        quotes: true,
        columns
    });
}

function downloadCSV(csv: any) {
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, "export.csv");
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "export.csv");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export const PaymentsTable = (props: any) => {
    const instructor_id = props.instructor_id;
    const monthFilter = props.monthFilter;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setData([]);
        if (instructor_id && monthFilter !== null) {
            setLoading(true);
            let params: any;
            if (monthFilter) {
                const [fromMonth, toMonth] = monthFilter;
                params = { fromMonth, toMonth };
            }
            http.get(`/payments/for-instructor/${instructor_id}`, { params }).then(result => {
                setData(result.data.data);
                setLoading(false);
            });
        }
    }, [instructor_id, monthFilter]);

    async function deletePayment(id: any) {
        confirm({
            title: 'Vai tiešām vēlaties dzēst Ierakstu',
            icon: <ExclamationCircleOutlined />,
            okText: 'Dzēst',
            okType: 'danger',
            cancelText: 'Nē',
            onOk() {
                setLoading(true);
                let params: any;
                if (monthFilter) {
                    const [fromMonth, toMonth] = monthFilter;
                    params = { fromMonth, toMonth };
                }
                return http.post(`/payments/delete-student-payment/${id}`).then(() => {
                    // http.get(`/payments/for-instructor/${instructor_id}`, { params }).then(result => {

                    //     setLoading(false);
                    // });
                    setData((data: any) => {
                        const filteredData = data.filter((item: any) => item.id !== id)
                        return filteredData
                    });
                    setLoading(false);
                }).catch(() => {
                    message.error('Dzēšanas kļūda')
                })
            },
        });

    }

    const allColumns = columns.concat([
        {
            title: "",
            width: 5,
            render: (row: any) => {
                // console.log("dddd", row);
                return <Button danger icon={<DeleteOutlined onClick={() => {
                    // setLoading(true);
                    deletePayment(row.id);
                }} />}></Button>
            }
        } as any
    ]);

    return <div>
        {data.length > 0 ? <div style={{ padding: "6px 16px", background: "rgb(240, 242, 245)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}>
            <Button onClick={() => { downloadCSV(convertDataToCSV(data)); }}>Eksportēt CSV</Button>
        </div> : null}
        <Table
            columns={allColumns as any}
            loading={loading}
            pagination={{
                showSizeChanger: false,
                size: 'small',
                showTotal: (total) => <div>Kopā {total} ieraksti</div>,
                position: ['bottomCenter'],
                pageSizeOptions: ['15'],
                defaultPageSize: 15,
            }}
            dataSource={data}
            rowClassName={(record: any, index) => {
                return record.bonus_payment_id && record.promo && Number(record.amount) > 0 ? 'bonus-payment-driving' : '';
            }}
        ></Table>
    </div>;
}