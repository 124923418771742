import { Layout, Space, Button, Table, Tag, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { getColumnSearchProps } from "../helpers/getColumnSearchProps"
import { http } from "../helpers/http";
import { DeleteSlotModal } from "./DeleteSlotModal";

const { Header, Content } = Layout;

export type InstructorsPageProps = RouteComponentProps & {
    windowHeight: number,
    prevLocation: any,
    mutateHistory: any
}

export type InstructorsPageState = {
    data: any,
    pagination: any,
    loading: any,
    classifiers: any,
    tableState: any,
    deleteSlotModalVisible: boolean
}

export class BaseInstructorsPage extends React.Component<InstructorsPageProps, InstructorsPageState> {
    constructor(props: InstructorsPageProps) {
        super(props);
        this.state = {
            data: [],
            pagination: {
                current: 1,
                pageSize: 15,
            },
            loading: true,
            classifiers: {},
            tableState: {} as any,
            deleteSlotModalVisible: false
        };
    };

    componentDidMount() {
        const { prevLocation } = this.props;
        const resetFilter = prevLocation !== undefined && !["instructor", "payments"].some((el: any) => prevLocation.includes(el));
        // console.log("reset:", resetFilter, prevLocation)
        if (resetFilter) {
            sessionStorage.removeItem("instructorsTableState");
        }
        // console.log(this.props.prevLocation)
        this.loadClassifiers().then(() => {
            if (sessionStorage.getItem("instructorsTableState")) {
                const instructorsTableState = JSON.parse(sessionStorage.instructorsTableState)
                this.setState({ tableState: instructorsTableState })
                const { pagination, filters, sorter } = instructorsTableState;
                this.handleTableChange(pagination, filters, sorter)
            } else {
                this.fetch();
            }
        })
    }

    componentWillUnmount() {
        this.props.mutateHistory(window.location.href)
    }

    handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const tableState = {
            pagination,
            filters,
            sorter: sorter.hasOwnProperty("field")
                ? {
                    columnKey: sorter.columnKey,
                    field: sorter.field,
                    order: sorter.order
                }
                : {}
        }
        console.log(tableState)
        this.setState({ tableState: tableState })
        sessionStorage.setItem("instructorsTableState", JSON.stringify(tableState));
        this.fetch({
            sortField: sorter.order ? sorter.field : null,
            sortOrder: sorter.order || null,
            pagination,
            filters,
        });
    };

    async loadClassifiers() {
        const languages = await http.get("/rest/languages");
        const cities = await http.get("/rest/cities");
        const categories = await http.get("/rest/categories");

        this.setState({
            classifiers: {
                languages: languages.data.data,
                cities: cities.data.data,
                categories: categories.data.data
            }
        });
    }

    async fetch(params: any = { pagination: { current: 1 } }) {
        this.setState({ loading: true });
        await http.get("/rest/instructors", { params: this.getParams(params) }).then(result => {
            console.log(result)
            const { data } = result.data;
            const meta = {
                current_page: result.data.current_page,
                per_page: result.data.per_page,
                total: result.data.total
            }
            this.setState({
                data,
                pagination: {
                    current: meta.current_page,
                    pageSize: meta.per_page,
                    total: meta.total
                },
                loading: false
            });
        });
    }

    getParams(params: any) {
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters
        }
    }

    render() {
        const { tableState } = this.state;
        const columns = [
            {
                title: 'Aktīvs',
                dataIndex: 'active',
                key: 'active',
                width: 80,
                filters: [
                    { text: 'Aktīvs', value: 1 },
                    { text: 'Neaktīvs', value: 0 },
                ],
                render: (active: boolean) => {
                    return active ? <Tag color="green">Aktīvs</Tag> : <Tag color="red">Neaktīvs</Tag>
                }
            },
            {
                title: 'Vārds',
                width: "115px",
                sorter: true,
                sortOrder: tableState?.sorter?.columnKey === 'first_name' && tableState?.sorter?.order,
                dataIndex: 'first_name',
                key: 'first_name',
                fixed: 'left',
                ...getColumnSearchProps(),
                filteredValue: tableState?.filters?.first_name || null,
                onCell: (record: any, rowIndex: any) => {
                    return {
                        onClick: (event: any) => {
                            this.props.history.push(`/instructors/edit/${record.id}`);
                        }, // click row
                        onDoubleClick: (event: any) => { }, // double click row
                        onContextMenu: (event: any) => { }, // right button click row
                        onMouseEnter: (event: any) => { }, // mouse enter row
                        onMouseLeave: (event: any) => { }, // mouse leave row
                    };
                },
                render: (name: string, record: any) => {
                    return <div>
                        {
                            record.comment
                                ? <Tooltip placement="top" title={record.comment}>
                                    <InfoCircleOutlined style={{ color: "red", marginRight: 8 }} />
                                </Tooltip>
                                : <></>
                        }
                        <span>{name}</span>
                    </div>
                }
            },
            {
                title: 'Uzvārds',
                width: "115px",
                sorter: true,
                sortOrder: tableState?.sorter?.columnKey === 'last_name' && tableState?.sorter?.order,
                dataIndex: 'last_name',
                key: 'last_name',
                fixed: 'left',
                ...getColumnSearchProps(),
                filteredValue: tableState?.filters?.last_name || null,
                onCell: (record: any, rowIndex: any) => {
                    return {
                        onClick: (event: any) => {
                            this.props.history.push(`/instructors/edit/${record.id}`);
                        }, // click row
                        onDoubleClick: (event: any) => { }, // double click row
                        onContextMenu: (event: any) => { }, // right button click row
                        onMouseEnter: (event: any) => { }, // mouse enter row
                        onMouseLeave: (event: any) => { }, // mouse leave row
                    };
                }
            },
            {
                title: 'Pilsētas',
                dataIndex: 'cities',
                key: 'cities',
                width: "115px",
                render: (cities: any) => {
                    const uniqueCities = cities.filter((value: any, index: any, self: any) => {
                        return self.indexOf(value) === index;
                    });
                    const citiesArray = uniqueCities.map((city: any) => {
                        return this.state.classifiers.cities.find((item: any) => item.id === city)
                    }).filter((item: any) => item !== undefined);
                    return citiesArray.map((city: any) => {
                        return <Tag key={city.id}>{city.value}</Tag>
                    })
                },
                filters: this.state.classifiers.cities != null ? this.state.classifiers.cities.map((c: any) => ({
                    text: c.value, value: c.id
                })) : [],
                filteredValue: tableState?.filters?.cities || null,
                onCell: (record: any, rowIndex: any) => {
                    return {
                        onClick: (event: any) => {
                            this.props.history.push(`/instructors/edit/${record.id}`);
                        }, // click row
                        onDoubleClick: (event: any) => { }, // double click row
                        onContextMenu: (event: any) => { }, // right button click row
                        onMouseEnter: (event: any) => { }, // mouse enter row
                        onMouseLeave: (event: any) => { }, // mouse leave row
                    };
                }
            },
            {
                title: 'Valodas',
                dataIndex: 'languages',
                key: 'languages',
                width: "115px",
                render: (languages: any) => {
                    const uniqueLanguages = languages.filter((value: any, index: any, self: any) => {
                        return self.indexOf(value) === index;
                    });
                    const languagesArray = uniqueLanguages.map((language: any) => {
                        return this.state.classifiers.languages.find((item: any) => item.id === language)
                    }).filter((item: any) => item !== undefined);
                    return languagesArray.map((language: any) => {
                        return <Tag key={language.id}>{language.value}</Tag>
                    })
                },
                filters: this.state.classifiers.languages != null ? this.state.classifiers.languages.map((c: any) => ({
                    text: c.value, value: c.id
                })) : [],
                filteredValue: tableState?.filters?.languages || null,
                onCell: (record: any, rowIndex: any) => {
                    return {
                        onClick: (event: any) => {
                            this.props.history.push(`/instructors/edit/${record.id}`);
                        }, // click row
                        onDoubleClick: (event: any) => { }, // double click row
                        onContextMenu: (event: any) => { }, // right button click row
                        onMouseEnter: (event: any) => { }, // mouse enter row
                        onMouseLeave: (event: any) => { }, // mouse leave row
                    };
                }
            },
            {
                title: 'Kategorijas',
                dataIndex: 'category_letters',
                key: 'categories',
                width: "85px",
                render: (services: any, record: any) => {
                    return Object.keys(services).map((cat: any, index: any) => {
                        return <div key={index}>{services[cat]}</div>
                    })
                },
                onCell: (record: any, rowIndex: any) => {
                    return {
                        onClick: (event: any) => {
                            this.props.history.push(`/instructors/edit/${record.id}`);
                        }, // click row
                        onDoubleClick: (event: any) => { }, // double click row
                        onContextMenu: (event: any) => { }, // right button click row
                        onMouseEnter: (event: any) => { }, // mouse enter row
                        onMouseLeave: (event: any) => { }, // mouse leave row
                    };
                }
            },
            {
                title: 'Stundas likmes',
                dataIndex: 'services',
                key: 'total_rate',
                width: "100px",
                onCell: (record: any, rowIndex: any) => {
                    return {
                        onClick: (event: any) => {
                            this.props.history.push(`/instructors/edit/${record.id}`);
                        }, // click row
                        onDoubleClick: (event: any) => { }, // double click row
                        onContextMenu: (event: any) => { }, // right button click row
                        onMouseEnter: (event: any) => { }, // mouse enter row
                        onMouseLeave: (event: any) => { }, // mouse leave row
                    };
                },
                render: (services: any, record: any) => {
                    const uniqueServices = services.filter((value: any, index: any, self: any) => {
                        return self.indexOf(value) === index;
                    });
                    const servicesArray = uniqueServices.map((service: any) => {
                        const categoryID = Object.keys(record.service_prices[service])[0];
                        const category = record.category_letters[categoryID];
                        const price = record.service_prices[service][categoryID].toFixed(2);                        // (Math.round(s.total_rate * 10) / 10).toFixed(2)
                        return {
                            category,
                            price
                        }
                    });
                    return servicesArray.map((cat: any, index: any) => {
                        return <div key={index}>{cat.price} EUR ({cat.category})</div>
                    })
                },
            },
            {
                title: 'Lietotājs',
                dataIndex: 'username',
                key: 'username',
                sorter: true,
                sortOrder: tableState?.sorter?.columnKey === 'username' && tableState?.sorter?.order,
                width: "100px",
                ...getColumnSearchProps(),
                filteredValue: tableState?.filters?.username || null,
                onCell: (record: any, rowIndex: any) => {
                    return {
                        onClick: (event: any) => {
                            this.props.history.push(`/instructors/edit/${record.id}`);
                        }, // click row
                        onDoubleClick: (event: any) => { }, // double click row
                        onContextMenu: (event: any) => { }, // right button click row
                        onMouseEnter: (event: any) => { }, // mouse enter row
                        onMouseLeave: (event: any) => { }, // mouse leave row
                    };
                }
            },
            {
                title: 'E-pasts',
                dataIndex: 'email',
                key: 'email',
                width: "100px",
                sorter: true,
                sortOrder: tableState?.sorter?.columnKey === 'email' && tableState?.sorter?.order,
                ...getColumnSearchProps(),
                filteredValue: tableState?.filters?.email || null,
                onCell: (record: any, rowIndex: any) => {
                    return {
                        onClick: (event: any) => {
                            this.props.history.push(`/instructors/edit/${record.id}`);
                        }, // click row
                        onDoubleClick: (event: any) => { }, // double click row
                        onContextMenu: (event: any) => { }, // right button click row
                        onMouseEnter: (event: any) => { }, // mouse enter row
                        onMouseLeave: (event: any) => { }, // mouse leave row
                    };
                }
            },
            {
                title: 'Tālrunis',
                dataIndex: 'phone_number',
                key: 'phone_number',
                sorter: true,
                sortOrder: tableState?.sorter?.columnKey === 'phone_number' && tableState?.sorter?.order,
                width: "100px",
                ...getColumnSearchProps(),
                filteredValue: tableState?.filters?.phone_number || null,
                onCell: (record: any, rowIndex: any) => {
                    return {
                        onClick: (event: any) => {
                            this.props.history.push(`/instructors/edit/${record.id}`);
                        }, // click row
                        onDoubleClick: (event: any) => { }, // double click row
                        onContextMenu: (event: any) => { }, // right button click row
                        onMouseEnter: (event: any) => { }, // mouse enter row
                        onMouseLeave: (event: any) => { }, // mouse leave row
                    };
                }
            },
            {
                title: '',
                dataIndex: '',
                key: '',
                width: "85px",
                render: () => <Button type="link">Ielogoties</Button>,
                onCell: (record: any, rowIndex: any) => {
                    return {
                        onClick: (event: any) => {
                            event.stopPropagation();
                            const token = record.api_token;
                            const stringSelf = localStorage.getItem('self');
                            const self = JSON.parse(stringSelf ? stringSelf : "")
                            const link = `${process.env.REACT_APP_INSTRUCTOR_URL}?bt=${token}&admin=${self?.id}`;
                            window.open(link);
                        }, // click row
                        onDoubleClick: (event: any) => { }, // double click row
                        onContextMenu: (event: any) => { }, // right button click row
                        onMouseEnter: (event: any) => { }, // mouse enter row
                        onMouseLeave: (event: any) => { }, // mouse leave row
                    };
                }
            }
        ];

        const { data, pagination, loading } = this.state;
        return (
            <Layout>
                <Header style={{ padding: "0 16px", background: "rgb(240, 242, 245)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}>
                    <Space>
                        <Link to='/instructors/new'><Button type="primary">Pievienot instruktoru</Button></Link>
                        {
                            data.length === 0 || data.length > 1
                                ? <></>
                                : <Button
                                    onClick={() => this.setState({ deleteSlotModalVisible: true })}
                                    disabled={this.state.data.length > 1}
                                >
                                    Atcelt nodarbību
                                </Button>
                        }
                        <DeleteSlotModal
                            instructor={data[0]}
                            deleteSlotModalVisible={this.state.deleteSlotModalVisible}
                            setDeleteSlotModalVisible={(state: boolean) => this.setState({ deleteSlotModalVisible: state })}
                        />
                    </Space>
                </Header>
                <Content>
                    <Table
                        rowKey={record => record.id}
                        columns={columns as any}
                        rowClassName="clickable-row"
                        pagination={{
                            showSizeChanger: false,
                            size: 'small',
                            showTotal: (total) => <div>Kopā {total} instruktori</div>,
                            position: ['bottomCenter'],
                            pageSizeOptions: ['15'],
                            defaultPageSize: 15,
                            ...pagination
                        }}
                        dataSource={data}
                        loading={loading}
                        scroll={{ x: 1200, y: this.props.windowHeight - 185 }}
                        onChange={this.handleTableChange}
                    />
                </Content>
            </Layout>
        );
    }
}

export const InstructorsPage = withRouter(BaseInstructorsPage);