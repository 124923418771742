import { Button, Card, Collapse, Layout, PageHeader, Skeleton, Space, Tabs } from "antd";
import { LoadingOutlined, EuroCircleFilled } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams, useHistory } from 'react-router-dom';
import { AddPaymentForm } from "./AddPaymentForm";
import { http } from "../../helpers/http";
import { StudentAgreements } from "./StudentAgreements";
import { AxiosResponse } from 'axios';
import { StudentGeneralInformation } from "./StudentGeneralInformation";
import { NotificationSettings } from "./NotificationSettings";
import { ChangePassword } from './ChangePassword';
import { CategoriesInformation } from "./CategoriesInformation";
import { StudentCredit } from './StudentCredit';
import { DupesCheck } from "./DupesCheck.tsx";
import { DrivingSettings } from "./DrivingSettings";
import { GroupSettings } from './GroupSettings';
import { StudentExamSettings } from "./StudentExamSettings";
import { DiscountsSettings } from "./DiscountSettings";
import { AddBonusForm } from "./AddBonusForm";

export const EditStudent = ({ permissions }: any) => {
    const { id }: { id: string } = useParams();
    const history = useHistory();
    const [studentBalance, setStudentBalance] = useState(null as number | null);
    const [studentBonus, setStudentBonus] = useState(null as number | null);
    const [student, setStudent] = useState({} as any);
    const [loading, setLoading] = useState(true);
    const [classifiers, setClassifiers] = useState({
        languages: [] as any,
        cities: [] as any,
        categories: [] as any,
        gender: [] as any
    });

    const loadBalance = async () => {
        const response = await http.get(`payments/student-balance/${id}`);
        setStudentBalance(response.data.data.balance as number);
        setStudentBonus(response.data.data.bonus_balance as number);
    };

    const fetchStudent = () => {
        setLoading(true);
        http.get(`/rest/students/${id}`).then((res: AxiosResponse) => {
            console.log(res);
            setStudent(res.data.data);
            setLoading(false);
        })
    }

    const loadClassifiers = async () => {
        const languages = await http.get("/rest/languages");
        const cities = await http.get("/rest/cities");
        const categories = await http.get("/rest/categories");
        const gender = await http.get("/rest/student-gender-values");
        setClassifiers({
            languages: languages.data.data,
            cities: cities.data.data,
            categories: categories.data.data,
            gender: gender.data,
        });
    };

    useEffect(() => {
        loadBalance();
        loadClassifiers();
        fetchStudent();
    }, []);

    return <Layout>
        <PageHeader
            style={{ padding: "0 16px", background: "rgb(255, 255, 255)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}
            onBack={() => { history.goBack(); }}
            title={
                <>
                    <span style={{ fontSize: "0.7em", fontWeight: "normal" }}>Doties atpakaļ</span>
                    <span style={{ marginLeft: 40 }}>{loading ? `` : `${student.first_name} ${student.last_name}`}</span>
                    {
                        student?.hasOwnProperty("id")
                        && <>
                            <Button
                                onClick={() => window.open(`${process.env.REACT_APP_STUDENT_URL}?bt=${student.api_token}`)}
                                style={{ marginLeft: 40 }}
                            >
                                Ielogoties
                            </Button>
                            {
                                student?.school_data?.school
                                && <Button
                                    onClick={() => window.open(`${process.env.REACT_APP_EDRIVE_STUDENT_URL}?bt=${student.api_token}`)}
                                    style={{ marginLeft: 40 }}
                                >
                                    Ielogoties eDrive
                                </Button>
                            }
                        </>
                    }
                </>
            }
        // extra={loading ? null : null}
        />
        <div style={{ margin: 20 }}>
            {
                !!(permissions?.perms?.some((el: any) => el === "add_balance" || el === "bonus_master") || permissions.ruler_of_universe)
                && <Collapse bordered={true} style={{ marginBottom: 20 }}>
                    <Collapse.Panel
                        extra={
                            <Space style={{ fontSize: 14 }}>
                                Konta atlikums <span style={{ fontSize: 18 }}>{
                                    studentBalance != null
                                        ? studentBalance
                                        : <LoadingOutlined style={{ fontSize: 16 }} spin />}</span>
                                <Button
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <Link to={`/students/payments/${id}`}>Maksājumi</Link>
                                </Button>
                            </Space>
                        }
                        style={{ background: "white", padding: 10 }}
                        showArrow={true}
                        header={<Button type="text" icon={<EuroCircleFilled />}>Reģistrēt maksājumu</Button>} key="1"
                    >
                        <div style={{ marginTop: 0 }}>
                            <AddPaymentForm onFinish={() => { loadBalance() }} student_id={id} />
                        </div>
                        {
                            !!(permissions?.perms?.some((el: any) => el === "bonus_master") || permissions.ruler_of_universe)
                            && <>
                                <h2>Papildināt Einšteinu maciņu</h2>
                                <b>Šobrīd studentam ir {studentBonus?.toFixed(2)} EIN</b>
                                <div style={{ marginTop: 0 }}>
                                    <AddBonusForm onFinish={() => { loadBalance() }} student_id={id} />
                                </div>
                            </>
                        }
                    </Collapse.Panel>
                </Collapse>
            }
            <Card>
                <Tabs>
                    <Tabs.TabPane tab="Pamatinformācija" key={1}>
                        {
                            Object.keys(student).length > 0
                            && <DupesCheck
                                studentID={student.id}
                                studentPersID={student.personal_identity_number}
                            />
                        }
                        <StudentGeneralInformation
                            student={student}
                            genders={classifiers.gender}
                            cities={classifiers.cities}
                            languages={classifiers.languages}
                            categories={classifiers.categories}
                            refreshStudent={fetchStudent}
                            permissions={permissions}
                        />
                        <CategoriesInformation
                            student={student}
                            cities={classifiers.cities}
                            languages={classifiers.languages}
                            categories={classifiers.categories}
                            refresh={fetchStudent}
                        />
                        <NotificationSettings student={student} />
                        {
                            permissions?.perms?.some((el: any) => el === "credit") || permissions.ruler_of_universe
                                ? <StudentCredit student={student} />
                                : <></>
                        }
                        <ChangePassword student={student} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Līgumi" key={2}>
                        {
                            Object.keys(student).length === 0
                                ? <Skeleton />
                                : <StudentAgreements
                                    id={id}
                                />
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Braukšana" key={3}>
                        {
                            Object.keys(student).length === 0
                                ? <Skeleton />
                                : <DrivingSettings
                                    student={student}
                                    categories={classifiers.categories}
                                />
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Grupas" key={4}>
                        {
                            Object.keys(student).length === 0
                                ? <Skeleton />
                                : <div>
                                    <GroupSettings
                                        student={student}
                                        refreshStudent={fetchStudent}
                                    />
                                    <StudentExamSettings
                                        studentCategories={student.categories}
                                        categories={classifiers.categories}
                                    />
                                </div>
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Atlaides" key={5}>
                        <DiscountsSettings studentID={student.id} />
                    </Tabs.TabPane>
                </Tabs>
            </Card>
        </div>
    </Layout>
}