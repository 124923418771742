import { Button, Col, DatePicker, message, Modal, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../helpers/http";

export const DeleteSlotModal = (props: any) => {
    const { deleteSlotModalVisible, setDeleteSlotModalVisible, instructor } = props;
    const [date, setDate] = useState(null);
    const [slots, setSlots] = useState([] as any);
    const [selectedSlot, setSelectedSlot] = useState(null as any);

    const fetchSlots = () => {
        http.post(`rest/get-instructor-regerated-slot/${instructor.id}`, {
            date: moment(date).format("YYYY-MM-DD")
        }).then((response: any) => {
            console.log(response)
            setSlots(response.data)
        })
    };

    const deleteSlot = () => {
        http.delete(`rest/time_slots/${selectedSlot}/${instructor.id}`).then((response: any) => {
            message.success("Nodarbība ir dzēsta!");
            setDeleteSlotModalVisible(false);
            setSelectedSlot(null);
            setDate(null);
            setSlots([]);
        }).catch(() => {
            message.error("Kļūda!");
        })
    }

    useEffect(() => {
        if (date !== null) {
            fetchSlots();
        }
        console.log(date)
    }, [date])

    return <Modal
        visible={deleteSlotModalVisible}
        onCancel={() => {
            setSelectedSlot(null);
            setDate(null);
            setSlots([]);
            setDeleteSlotModalVisible(false)
        }}
        width={800}
        footer={<></>}
        destroyOnClose={true}
    >
        <Row gutter={12}>
            <Col>
                <p>Instruktors:</p>
                <b>{instructor?.first_name} {instructor?.last_name}</b>
            </Col>
            <Col>
                <p>Datums:</p>
                <DatePicker
                    value={date}
                    onChange={(e: any) => {
                        setDate(e)
                    }}
                    format="DD.MM.YYYY"
                />
            </Col>
            <Col>
                <p>Nodarbības:</p>
                <Select
                    style={{ width: 200 }}
                    disabled={slots.length === 0}
                    value={selectedSlot}
                    onChange={(e: any) => setSelectedSlot(e)}
                >
                    {
                        slots?.map((slot: any) => {
                            return <Select.Option key={slot.id} value={slot.id}>
                                {slot.time_from} - {slot.time_to}&nbsp;
                                {
                                    slot.student_id !== null
                                        ? "Aizņemts"
                                        : "Brīvs"
                                }
                            </Select.Option>
                        })
                    }
                </Select>
            </Col>
            <Col style={{ display: "flex", flexDirection: "column-reverse" }}>
                <Button
                    type="primary"
                    disabled={selectedSlot === null}
                    onClick={() => deleteSlot()}
                >
                    Dzēst
                </Button>
            </Col>
        </Row>
    </Modal>
}