import { Layout, PageHeader, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import { StudentPaymentsTable } from "./StudentPaymentsTable";

export const StudentPaymentsPage = withRouter((props: any) => {

  const [student, setStudent] = useState(null) as any;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    http.get(`/rest/students/${props.match.params.id}`, { params: { no_paginate: 1 } })
      .then(result => { setStudent(result.data.data); setLoading(false); });
  }, [props.match.params.id, setStudent]);

  return (
    <Layout>
      <PageHeader
        style={{ padding: "0 16px", background: "rgb(255, 255, 255)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}
        onBack={() => { props.history.goBack(); }}
        title={
          <>
            <span style={{ fontSize: "0.7em", fontWeight: "normal" }}>Doties atpakaļ</span>
            <span style={{ marginLeft: 40 }}>{loading ? `` : `${student.first_name} ${student.last_name}`} / Maksājumi</span>
          </>
        }
      />
      <Layout.Content>
        {loading ? <Skeleton /> : <StudentPaymentsTable student_id={student.id} />}
      </Layout.Content>
    </Layout>
  );
});