import { Row, Table, Col } from "antd"
import React, { useState } from "react"
import { withRouter } from "react-router-dom";
// import { getColumnSearchProps } from "../../../helpers/getColumnSearchProps";
import { http } from "../../../helpers/http";

export const InstructorsBalanceTab = withRouter((props: any) => {
  const [data, setData] = useState([] as Array<any>);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: 'Instruktors',
      key: 'instructor_id',
      render: (instructor: any) => instructor.first_name + " " + instructor.last_name,
      // ...getColumnSearchProps()
    },
    // {
    //   title: 'Uzkrājums',
    //   key: 'balance' + 'id',
    //   render: (instructor: any) => {
    //     let amount = 0;
    //     instructor.student_lessons.forEach((lesson: any) => {
    //       const timeSlot = lesson.lesson_time_slot;
    //       const howManyLessons = timeSlot.how_many_lessons == 5 ? 1 : timeSlot.how_many_lessons;
    //       timeSlot.lesson_time_slot_prices.forEach((p: any) => {
    //         if (p.position === "Instruktors" && lesson.instructor_invoice_id == null) {
    //           amount += howManyLessons * (p.rate * (1 + p.rate_vat / 100));
    //         }
    //       });
    //     });
    //     return amount.toFixed(2) + " " + "EUR";
    //   }
    // }
  ];

  React.useEffect(() => {
    http.get("/invoices/get-instructors-for-savings", { params: { no_paginate: 1 } })
      .then(result => {
        setData(result.data.data);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Row gutter={[12, 12]} align="middle" style={{ margin: "10px 0" }}>
        <Col>
          {/* <Select
            loading={instructors.length < 1}
            value={hasInstructor ? Number(instructor_id) : undefined}
            onChange={(value: any) => {
              props.history.push(`/invoices/${value}`);
            }}
            placeholder="Izvēlieties instruktoru..."
            showSearch
            style={{ width: 200 }}
            filterOption={((input: any, option: any) => {
              return option && option.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }) as any}
          >
            {instructors.map((instructor: any) =>
              <Select.Option
                key={`instructor-${instructor.id}`}
                value={instructor.id}
              >
                {instructor.first_name} {instructor.last_name}
              </Select.Option>
            )}
          </Select> */}
        </Col>
      </Row>
      <Table
        loading={loading}
        rowKey={record => record.id}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              props.history.push(`${props.match.url}/${record.id}`);
            }, // click row
            onDoubleClick: event => { }, // double click row
            onContextMenu: event => { }, // right button click row
            onMouseEnter: event => { }, // mouse enter row
            onMouseLeave: event => { }, // mouse leave row
          };
        }}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </>
  )
})