import { Button, Checkbox, Input, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import React from 'react'

export type Answer = {
    id: number;
    answer: string;
    correct: 1 | 0;
}

type AnswerProps = {
    answer: Answer;
    locale: "lv" | "en" | "ru";
    modifyAnswer: (answer: Answer) => void;
    removeAnswer: (ind: number, id: number | undefined) => void;
    ind: number;
}

const answerStyle = {
    border: "1px solid #E0E0E0",
    padding: "11px 20px",
    marginBottom: 10
}

function Answer(props: AnswerProps) {
    const { answer, locale } = props;

    return <div style={answerStyle}>
        <div>
            <Row justify="end">
                <Button
                    ghost
                    danger
                    onClick={() => props.removeAnswer(props.ind, answer?.id)}
                >
                    <DeleteOutlined />
                </Button>
            </Row>
            <p><b>Atbilde {locale.toUpperCase()}</b></p>
            <Input.TextArea
                placeholder={`Atbilde ${locale.toUpperCase()}`}
                value={answer?.answer ?? undefined}
                onChange={(e) => {
                    e.persist();
                    props.modifyAnswer({
                        ...answer,
                        answer: e.target.value
                    })
                }}
            />
            <Row>
                <Checkbox
                    checked={!!answer?.correct}
                    style={{ marginRight: 8 }}
                    onChange={(e) => {
                        props.modifyAnswer({
                            ...answer,
                            correct: e.target.checked ? 1 : 0
                        })
                    }}
                />
                <b>Pareizā atbilde</b>
            </Row>
        </div>
    </div>
}

export default Answer