import { Button, Card, Col, Layout, PageHeader, Row, Skeleton, Tabs } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from 'react-router';
import { http } from "../../helpers/http";
import { StudentEdit } from "./StudentEdit";
import { AxiosResponse } from 'axios';

const { TabPane } = Tabs;

export const StudentGroupOpening = (props: any) => {
    const history = useHistory();
    const location: any = useLocation();
    const { id }: { id: string } = useParams();
    const { permissions } = props;
    const [student, setStudent] = useState({} as any);
    const [loadingStudent, setLoadingStudent] = useState(false);
    const [openedCategory, setOpenedCategory] = useState(null as number | null)
    const [defaultTab, setDefaultTab] = useState(undefined as undefined | string);
    const [signedToGroups, setSignedToGroups] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [commonServices, setCommonServices] = useState([] as any);

    const getGroups = () => {
        setLoading(true);
        http.get(`/rest/student-study-group/${id}`).then((response: any) => {
            if (response.data.length > 0) {
                setSignedToGroups(response.data)
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    const getCommonServices = () => {
        http.get(`/rest/get-student-common-services/${id}`).then((res: AxiosResponse) => {
            setCommonServices(res.data)
        })
    }

    const fetchStudent = async () => {
        setLoadingStudent(true);
        const student = await http.get(`/rest/students/${id}`).then((response: any) => {
            return response.data.data;
        });
        const balance = await http.get(`/rest/balance/${id}`).then((response: any) => {
            return response.data.data;
        });
        // const reservation_type = await http.get(`/rest/study-groups-get-student-reservation/${id}/${study_group_id}`).then((response: any) => {
        //     return response.data;
        // });
        if (defaultTab === undefined) {
            setOpenedCategory(Number(student.categories[0].category_id))
            setDefaultTab(String(student.categories[0].category_id))
        }
        setStudent((data: any) => {
            setLoadingStudent(false);
            return {
                registration_type: "Online",
                ...student,
                balance: balance,
                // reservation_type,
            }
        });
    };

    const setTab = (tab: string) => {
        sessionStorage.setItem("defaultTab", tab);
        setOpenedCategory(Number(tab))
        setDefaultTab(tab)
    }

    useEffect(() => {
        getGroups();
        fetchStudent();
        getCommonServices();
        // const defaultTab = sessionStorage.getItem("defaultTab")
        // if (defaultTab) {
        //     console.log(defaultTab)
        //     setDefaultTab(Number(defaultTab))
        // }
        return () => {
            sessionStorage.removeItem("defaultTab")
        }
    }, []);

    useEffect(() => {
        const defaultTab = sessionStorage.getItem("defaultTab")
        if (defaultTab) {
            setDefaultTab(defaultTab)
            setOpenedCategory(Number(defaultTab))
        }
        else if (location.state?.hasOwnProperty("defaultCategory")) {
            setDefaultTab(String(location.state.defaultCategory))
            setOpenedCategory(location.state.defaultCategory)
        }
    }, [location])

    return <Layout>
        <Row>
            <Col span={24}>
                <PageHeader
                    className="student-edit-header"
                    style={
                        {
                            padding: "0 16px",
                            background: "rgb(255, 255, 255)",
                            borderBottom: "2px solid #e7e7e7",
                            flexWrap: 'nowrap',
                            width: '100%',
                            position: "fixed",
                            zIndex: 1
                        }}
                    // onBack={() => history.push(study_group_id === fakeGroup ? `/crm/reminders` : `/groups/edit/${study_group_id}`)}
                    title={
                        <h4 style={{ marginLeft: 40, marginBottom: 0 }}>Students:</h4>
                    }
                    subTitle={
                        loadingStudent
                            ? <></>
                            : <Row gutter={[24, 0]} align="middle">
                                <Col>
                                    {/* <Text strong >Vārds uzvārds<br /></Text> */}
                                    <b style={{ color: "black" }}>{`${student.first_name} ${student.last_name}`}</b>
                                </Col>
                                <Col>
                                    {/* <Text strong >Tālruņa nr.<br /></Text> */}
                                    <b style={{ color: "black" }}><PhoneOutlined /> : {student.phone_number}</b>
                                </Col>
                                <Col>
                                    {/* <Text strong >E-pasts<br /></Text> */}
                                    <b style={{ color: "black" }}><MailOutlined /> : {student.email}</b>
                                </Col>
                                <Col>
                                    <b style={{ color: "black" }}>Depozīts: </b>
                                    <b style={{ color: "black" }}>{`${student.balance} EUR`}</b>
                                </Col>
                                <Col>
                                    <Button
                                        onClick={() => window.open(`${process.env.REACT_APP_STUDENT_URL}?bt=${student.api_token}`)}
                                        style={{ marginLeft: 40 }}
                                    >
                                        Ielogoties
                                    </Button>
                                </Col>
                                {
                                    student?.school_data?.school
                                    && <Col>
                                        <b style={{ color: "rgb(72, 6, 124)", fontSize: 24 }}>eDrive</b>
                                    </Col>
                                }
                            </Row>

                    }
                // extra={this.state.loading ? null : null}
                />
                <Card style={{ margin: "60px 0px 20px" }} loading={loading}>
                    <Tabs
                        onChange={(e) => setTab(e)}
                        // defaultActiveKey={defaultTab}
                        activeKey={defaultTab}
                    >
                        {
                            student.categories?.map((category: any) => {
                                const fakeId = Number(process.env.REACT_APP_NO_GROUP_ID);
                                const group = signedToGroups.find((group: any) => group.id === category.study_group_id)
                                const isFake = group === undefined || group?.id === fakeId
                                const isEdrive = student?.school_data?.only_school
                                    || student?.school_data?.school_groups?.some((group: any) => group.category_id === category.category_id)
                                return <TabPane tab={category.category + (isEdrive ? " (eDrive)" : "")} key={category.category_id}>
                                    <StudentEdit
                                        study_group_id={group?.id ?? fakeId}
                                        studentGroup={group ?? {}}
                                        setStudent={(data: any) => setStudent((prev: any) => {
                                            return {
                                                ...prev,
                                                data
                                            }
                                        })}
                                        student={student}
                                        fetchStudent={fetchStudent}
                                        permissions={permissions}
                                        openedCategory={openedCategory}
                                        categoryID={category.category_id}
                                        isFake={isFake}
                                        commonServices={commonServices}
                                    />
                                </TabPane>
                            })
                        }
                    </Tabs>
                </Card>
            </Col>
        </Row>
    </Layout>
}