import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Divider, Input, Layout, message, Modal, PageHeader, Row, Select, Space, Table, Tag } from "antd";
import { PlusCircleOutlined, UserOutlined } from "@ant-design/icons";
import { http } from "../helpers/http";
import moment from "moment";
import { getColumnSearchProps } from "../helpers/getColumnSearchProps";
import { useHistory } from "react-router-dom";

export const StudentsFromWeb = (props: any) => {
    const [students, setStudents] = useState([] as any);
    const [city, setCity] = useState(undefined as any);
    const [language, setLanguage] = useState(undefined as any);
    const [category, setCategory] = useState(undefined as any);
    const [classifiers, setClassifiers] = useState({} as any);
    const [selectedStudent, selectStudent] = useState({} as any);
    const [selectedGroup, selectGroup] = useState(undefined as any);
    const [addStudentModalVisible, setAddStudentModalVisible] = useState(false);
    const [studyGroups, setStudyGroups] = useState(undefined as any);
    const [onsite, setOnsite] = useState(false);
    const [withStudyGroup, setWithStudyGroup] = useState(true);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15
    })

    const history = useHistory();

    const fetchStudents = async (params: any = { pagination: { current: 1 } }) => {
        if (withStudyGroup) {
            await http.get(`/rest/student-by-group-status`, { params: getParams(params) }).then((response: any) => {
                setStudents(response.data.data);
                setPagination(() => {
                    return {
                        current: response.data.current_page,
                        pageSize: response.data.per_page,
                        total: response.data.total,
                    }
                })
            });
        } else {
            await http.get("/rest/individual-students", { params: getParams(params) }).then((response: any) => {
                const students = response.data.data.map((student: any) => {
                    return {
                        ...student,
                        web_category: student.individual[0].web_category
                    }
                })
                console.log(students);
                setStudents(students);
                setPagination(() => {
                    return {
                        current: response.data.current_page,
                        pageSize: response.data.per_page,
                        total: response.data.total,
                    }
                })
            });
        }
    }

    const fetchGroups = async (params: any = { no_paginate: 1 }) => {
        await http.get("/rest/study-groups", { params: params }).then(result => {
            const { data } = result.data;
            console.log(result)
            setStudyGroups(data);
        })
    }

    const fetchClassifiers = async () => {
        const languages = await http.get(`/rest/languages`).then((response: any) => {
            return response.data.data;
        });
        const cities = await http.get(`/rest/cities`).then((response: any) => {
            return response.data.data;
        });
        const categories = await http.get(`/rest/categories`).then((response: any) => {
            return response.data.data;
        });
        setClassifiers({
            cities: cities,
            languages: languages,
            categories: categories
        });
    }

    useEffect(() => {
        fetchStudents();
    }, [withStudyGroup]);

    useEffect(() => {
        fetchClassifiers();
    }, [])

    useEffect(() => {
        if (language && city) {
            fetchGroups({
                filters: {
                    onsite: onsite ? ['1'] : [],
                    cities: onsite ? [] : [city],
                    languages: [language],
                    category: [selectedStudent.category !== null ? selectedStudent.category.id : category]
                }
            })
        }
    }, [city, language, onsite])

    useEffect(() => {
        console.log(selectedGroup)
    }, [selectedGroup])

    const getParams = (params: any) => {
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters
        }
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(filters);
        fetchStudents({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters,
        });
    };

    const setStudentToGroup = (slot: any) => {
        console.log(selectedGroup, city, language)
        setAddStudentModalVisible(true);
        selectStudent(slot);
    }

    const resetModal = () => {
        setAddStudentModalVisible(false);
        selectStudent(undefined);
        selectGroup(undefined);
        setCity(undefined);
        setLanguage(undefined);
        setStudyGroups(undefined);
    }

    const saveData = () => {
        const valuesToSubmit = {
            city: city,
            language: language,
        } as any;
        if (category !== undefined) valuesToSubmit.category = category;
        http.patch(`/rest/students/${selectedStudent.id}`, valuesToSubmit).then((response: any) => {
            console.log(response);
        }).catch((e: any) => {
            message.error("Neizdevās saglabāt");
        });
        const values = {
            reservation_type: ["interested"],
            student: [selectedStudent]
        }
        http.post(`/rest/study-groups/add-student/${selectedGroup}`, values).then((response) => {
            message.success("Students ir pievienots grupai");
            fetchStudents();
        }).catch(() => {
            message.error("Kļūda!");
        });
    }

    const columns = [
        {
            title: "Students",
            dataIndex: "",
            key: "student",
            render: (record: any, all: any) => {
                return all.first_name + " " + all.last_name
            },
            ...getColumnSearchProps()
        },
        {
            title: "E-pasts",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps()
        },
        {
            title: "Tālruņa nr.",
            dataIndex: "phone_number",
            key: "phone_number",
            ...getColumnSearchProps()
        },
        {
            title: "Kategorija",
            dataIndex: "category",
            key: "category",
            align: "center",
            render: (record: any, all: any) => {
                return record ? record.value + " Kategorija" : <span>{all.web_category} <Tag color="geekblue">WEB</Tag></span>
            },
            filters: classifiers.categories?.map((category: any) => {
                return { text: category.value, value: category.id };
            }),
        },
        {
            title: "Pieteikšanās datums",
            dataIndex: "registered_at",
            key: "registered_at_date",
            align: "center",
            sorter: true,
            render: (record: any) => moment(record).format("YYYY-MM-DD"),
            ...getColumnSearchProps('calendar')
        },
        {
            title: "Pieteikt mācību grupai",
            dataIndex: "id",
            key: "sign_to_group",
            align: "center",
            width: 150,
            render: (record: any, all: any) => <Button
                onClick={() => setStudentToGroup(all)}
                type="primary"
                icon={<PlusCircleOutlined style={{ fontSize: 18 }}
                />}></Button>
        },
    ] as any;

    const columnsSG = [
        {
            title: "Vārds",
            dataIndex: "first_name",
            key: "st.first_name",
            fixed: "left",
            ...getColumnSearchProps()
        },
        {
            title: "Uzvārds",
            dataIndex: "last_name",
            key: "st.last_name",
            fixed: "left",
            ...getColumnSearchProps()
        },
        {
            title: "E-pasts",
            dataIndex: "email",
            key: "st.email",
            ...getColumnSearchProps()
        },
        {
            title: "Tālruņa nr.",
            dataIndex: "phone_number",
            key: "st.phone_number",
            ...getColumnSearchProps()
        },
        {
            title: "Kategorija",
            dataIndex: "category",
            key: "st.category_id",
            align: "center",
            filters: classifiers.categories?.map((category: any) => {
                return { text: category.value, value: category.id };
            }),
        },
        {
            title: "Pilsēta",
            dataIndex: "city",
            key: "st.city_id",
            align: "center",
            filters: classifiers.cities?.map((city: any) => {
                return { text: city.value, value: city.id };
            }),
        },
        {
            title: "Pieteikšanās datums",
            dataIndex: "created_at",
            key: "st.created_at",
            align: "center",
            render: (record: any) => moment(record).format("YYYY-MM-DD"),
            ...getColumnSearchProps('calendar')
        },
        {
            title: "CSDD",
            dataIndex: "csdd_nr",
            key: "sg.csdd_nr",
            align: "center",
            width: 150,
            ...getColumnSearchProps()
        },
        {
            title: "Veids",
            dataIndex: "reservation_type",
            key: "ssg.reservation_type",
            align: "center",
            width: 150,
            filters: [
                { text: "Hard", value: "hard" },
                { text: "Soft", value: "soft" },
                { text: "Interested", value: "interested" },
            ]
        },
        {
            title: "WEB ref",
            dataIndex: "web_app_ref",
            key: "st.web_app_ref",
            align: "center",
            width: 150,
            ...getColumnSearchProps()
        },
    ] as any;

    return (
        <Layout>
            <Modal
                title="Pievienot studentu mācību grupai"
                visible={addStudentModalVisible}
                onCancel={resetModal}
            // footer={<></>}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Space>
                            <UserOutlined style={{ fontSize: 18 }} />
                            <b>
                                {selectedStudent?.first_name} {selectedStudent?.last_name},&nbsp;
                                {
                                    selectedStudent?.category
                                        ? selectedStudent.category.value + " Kategorija"
                                        : selectedStudent?.web_category
                                }
                            </b>
                        </Space>
                        {
                            category || selectedStudent?.category
                                ? <div style={{ marginTop: 20 }}>
                                    Neklātiene <Checkbox checked={onsite} onChange={(e: any) => setOnsite(e.target.checked)} />
                                    <Select value={city} style={{ width: 120, marginLeft: 20 }} onChange={setCity} placeholder="Pilsēta">
                                        {classifiers.cities?.map((city: any) => {
                                            return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                                        })}
                                    </Select>
                                    <Select value={language} style={{ width: 120, marginLeft: 20 }} onChange={setLanguage} placeholder="Valoda">
                                        {classifiers.languages?.map((language: any) => {
                                            return <Select.Option key={language.id} value={language.id}>{language.value}</Select.Option>
                                        })}
                                    </Select>
                                </div>
                                : <>
                                    <div>
                                        <b>Lūdzu norādiet studenta kategoriju sistēmā</b>
                                        <Select style={{ width: 120, marginLeft: 20 }} onChange={setCategory}>
                                            {classifiers.categories?.map((category: any) => {
                                                return <Select.Option key={category.id} value={category.id}>{category.value}</Select.Option>
                                            })}
                                        </Select>
                                    </div>
                                </>
                        }


                    </Col>
                    {
                        studyGroups
                            ? studyGroups.length > 0
                                ? <Col span={24} style={{ marginTop: 20 }}>
                                    <b>Izvēlieties mācību grupu</b>
                                    <Select value={selectedGroup} style={{ width: "100%", marginTop: 20 }} onChange={selectGroup}>
                                        {studyGroups.map((group: any) => {
                                            return <Select.Option key={group.id} value={group.id}>
                                                <b>{group.name}</b>,&nbsp;
                                                <b>{group.category.value}</b>&nbsp;
                                                Kategorija,&nbsp;
                                                {group.start_date}
                                                {group.cities.map((city: any) => <>, {city.value}</>)}
                                                {group.languages.map((language: any) => <>, {language.value}</>)}
                                            </Select.Option>
                                        })}
                                    </Select>
                                </Col>
                                : "Nav atrasta piemērota mācību grupa"
                            : <></>
                    }
                    {
                        selectedGroup
                            ? <Button onClick={saveData} style={{ margin: "20px auto 0" }} type="primary">Pievienot</Button>
                            : <></>
                    }
                </Row>
            </Modal>
            <PageHeader
                title={<>
                    Studentu pieteikumi no WEB
                    <Space style={{ marginLeft: 20 }}>
                        <Button
                            type={withStudyGroup ? "primary" : "default"}
                            onClick={() => setWithStudyGroup(true)}
                        >
                            Ar mācību grupu
                        </Button>
                        <Button
                            type={!withStudyGroup ? "primary" : "default"}
                            onClick={() => setWithStudyGroup(false)}
                        >
                            Bez mācību grupas
                        </Button>
                    </Space>
                </>}
                onBack={history.goBack}
                style={{ backgroundColor: "#FFFFFF", marginBottom: 15 }}
            />
            <Table
                rowKey={record => record.id}
                dataSource={students}
                columns={withStudyGroup ? columnsSG : columns}
                onChange={handleTableChange}
                pagination={{
                    showSizeChanger: false,
                    size: 'small',
                    showTotal: (total) => <div>Kopā {total} pieteikumi</div>,
                    position: ['bottomCenter'],
                    pageSizeOptions: ['15'],
                    defaultPageSize: 15,
                    ...pagination
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            if (record.study_group_id === Number(process.env.REACT_APP_NO_GROUP_ID)) {
                                history.push(`/students/edit/${record.id}`);
                                return;
                            }
                            if (withStudyGroup) {
                                // history.push(`/groups/edit/edit-student/${record.id}/${record.study_group_id}`)
                                history.push(`/groups/student-opening/${record.id}`)
                            }
                        }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { }, // mouse leave row
                    };
                }}
                scroll={{ x: 1600, y: props.windowHeight - 185 }}
            />
        </Layout>
    )
}