import { Tag } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";

export const DupesCheck = (props: any) => {
    const { studentID, studentPersID } = props;
    const [dupes, setDupes] = useState([] as any);

    const checkForDupes = () => {
        http.get(`/rest/check-student-duplicates/${studentID}/${studentPersID}`).then((response: any) => {
            console.log(response)
            setDupes(response.data.data)
        })
    }

    useEffect(() => {
        checkForDupes();
    }, [studentID])

    return dupes.length === 0
        ? <></>
        : <div style={{ marginBottom: 30 }}>
            <Tag color="red">Uzmanību! Šim studentam ir atrasti dublikāti:</Tag>
            {
                dupes.map((dupe: any, index: any) => {
                    return <a style={{ marginLeft: 10 }} key={index} href={`${process.env.REACT_APP_ADMIN_URL}students/edit/${dupe.id}`}>Saite</a>
                })
            }
        </div>
}