import { Button, Card, Checkbox, Col, DatePicker, Input, Layout, PageHeader, Row, Select, TimePicker, message, Tag } from 'antd';
import moment from "moment";
import React, { useEffect, useState } from "react"
import { Route, Switch, useHistory } from "react-router-dom"
import { http } from "../../helpers/http";
import { ResultsCard } from "./ResultsCard";
import { AxiosResponse } from 'axios';
import { ExamResults } from './ExamResults';

export const ResultsPage = () => {
    const history = useHistory();
    const [categories, setCategories] = useState([] as any);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 1,
    });
    const [filters, setFilters] = useState({
        student: undefined as [string] | undefined,
        category: undefined as [number] | undefined,
        date_range: [moment().subtract(30, "days"), moment()] as any,
        failures: 1 as 1 | 0,
    })
    const [exams, setExams] = useState(null as any);
    const [loading, setLoading] = useState(false);

    const getCategories = () => {
        http.get(`/rest/categories`).then((res: AxiosResponse) => {
            setCategories(res.data.data)
        })
    }

    const getExams = (page: number = 1) => {
        if (filters.date_range === undefined) return;
        setLoading(true);
        http.get(`/rest/elearning-student-exam-history`, {
            params: {
                page: page,
                filters: {
                    student: filters.student,
                    category: filters.category,
                    failures: [filters.failures],
                    date_from: filters.date_range !== undefined ? [moment(filters.date_range[0]).format("YYYY-MM-DD")] : undefined,
                    date_to: filters.date_range !== undefined ? [moment(filters.date_range[1]).format("YYYY-MM-DD")] : undefined,
                }
            }
        }).then((res: AxiosResponse) => {
            console.log(res)
            setExams(res.data.data.data);
            setPagination({
                current: Number(res.data.data.page),
                total: res.data.data.total
            })
            setLoading(false);
        }).catch(() => {
            message.error("Kļūda!");
            setLoading(false);
        })
    }

    useEffect(() => {
        getCategories();
    }, [])

    useEffect(() => {
        console.log(filters)
    }, [filters])

    return <Layout>
        <Switch>
            <Route path="/e-learning/results/:id">
                <ExamResults
                    exams={exams}
                />
            </Route>
            <Route path="/e-learning/results">
                <PageHeader
                    title="Studentu eksāmenu rezultāti"
                    onBack={history.goBack}
                    style={{ backgroundColor: "#FFFFFF" }}
                />
                <Card style={{ margin: 20 }}>
                    <Row gutter={16} align="bottom">
                        <Col>
                            <p>Students</p>
                            <Input
                                value={filters.student !== undefined ? filters.student[0] : undefined}
                                onChange={(e) => {
                                    e.persist();
                                    setFilters((prev: typeof filters) => {
                                        return {
                                            ...prev,
                                            student: e.target.value !== undefined
                                                ? e.target.value.length === 0
                                                    ? undefined
                                                    : [e.target.value]
                                                : undefined
                                        }
                                    })
                                }}
                            />
                        </Col>
                        <Col>
                            <p>Kategorija</p>
                            <Select
                                style={{ width: 120 }}
                                value={filters.category !== undefined ? filters.category[0] : undefined}
                                onChange={(e: number) => {
                                    setFilters((prev: typeof filters) => {
                                        return {
                                            ...prev,
                                            category: e === -1 ? undefined : [e]
                                        }
                                    })
                                }}
                            >
                                {
                                    filters.category !== undefined
                                    && <Select.Option value={-1}>
                                        {" "}
                                    </Select.Option>
                                }
                                {
                                    categories?.map((category: any) => {
                                        return <Select.Option key={category.id as number} value={category.id}>
                                            {category.value}
                                        </Select.Option>
                                    })
                                }

                            </Select>
                        </Col>
                        <Col>
                            <p>
                                Datums
                                {
                                    filters.date_range === undefined
                                    && <Tag style={{ marginLeft: 10 }} color="red">Šis lauks ir obligāts!</Tag>
                                }
                            </p>
                            <DatePicker.RangePicker
                                value={filters.date_range}
                                onChange={(e) => {
                                    setFilters((prev: typeof filters) => {
                                        console.log(e)
                                        return {
                                            ...prev,
                                            date_range: e ? e : undefined
                                        }
                                    })
                                }}
                            />
                        </Col>
                        <Col>
                            <p>Tikai ar kļūdām</p>
                            <Checkbox
                                checked={!!filters.failures}
                                onChange={(e) => {
                                    setFilters((prev: typeof filters) => {
                                        return {
                                            ...prev,
                                            failures: e.target.checked ? 1 : 0
                                        }
                                    })
                                }}
                            />
                        </Col>
                        <Button
                            type="primary"
                            style={{ marginLeft: 20 }}
                            onClick={() => getExams()}
                            loading={loading}
                        >
                            Atlasīt
                        </Button>
                    </Row>
                </Card>
                <ResultsCard
                    exams={exams}
                    pagination={pagination}
                    changePage={(page: number) => getExams(page)}
                />
            </Route>
        </Switch>
    </Layout>
}