import { Button, Card, Input, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";


export const StudentFreeTheoryLessons = (props: any) => {
    const { student, refresh } = props;
    const [visibleModal, setVisibleModal] = useState(false);
    const [freeLessons, setFreeLessons] = useState(0);

    const setFreeAttendableLessonCount = () => {
        http.patch(`/rest/students/${student.id}`, {
            free_theory_lessons: freeLessons
        }).then(() => {
            message.success("Saglabāts!")
            refresh();
            setVisibleModal(false);
        }).catch(() => {
            message.error("Kļūda!")
        })
    }

    return <Card title="Bezmaksas nodarbības">
        <Modal
            visible={visibleModal}
            onCancel={() => setVisibleModal(false)}
            footer={<></>}
        >
            <h3>Mainīt bezmaksas nodarbību daudzumu</h3>
            <div>
                <Input
                    style={{ width: 120, marginRight: 10 }}
                    type="number"
                    value={Number(freeLessons)}
                    onChange={e => {
                        e.persist();
                        console.log(Number(e.target.value))
                        setFreeLessons(Number(e.target.value))
                    }}
                />
                <Button
                    disabled={freeLessons < student.free_theory_lesson_spent}
                    onClick={setFreeAttendableLessonCount}
                >
                    Apstiprināt
                </Button>
            </div>
        </Modal>
        <p><b>Studentam piesķirtās bezmaksas moduļu pieteikšanās reizes: {student.free_theory_lessons}</b></p>
        <p><b>Studenta atlikušās bezmaksas moduļu pieteikšanās reizes: {student.free_theory_lessons_left}</b></p>
        <Button
            onClick={() => setVisibleModal(true)}
        >
            Mainīt
        </Button>
    </Card>
}