import { Modal, Card, Row, Col, Space, Input, Button, message, Tag, Select } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { http } from "../../../helpers/http";
import { SelectValue } from "antd/lib/select";
import { AxiosError, AxiosResponse } from "axios";

export const AddStudentModal = (props: any) => {
    const {
        slot,
        setModalVisible,
        modalVisible,
        updateSlots,
        categories
    } = props;
    const [studentNumber, setStudentNumber] = useState("");
    const [errorPhone, setErrorPhone] = useState(false);
    const [studentAdded, setStudentAdded] = useState(false);
    const [category, setCategory] = useState(undefined as undefined | number);
    const [error, setError] = useState("")

    const addStudentToSlot = () => {
        http.post(`/rest/check-allowed-theory-exam-by-driving`, {
            student: studentNumber,
            category
        }).then((res: AxiosResponse) => {
            console.log(res)
            const { allowed, msg } = res.data;
            if (allowed) {
                http.post(`rest/buy-theory-exam-by-datetime-phone`, {
                    exam_date: slot.exam_date,
                    time_from: slot.time_from,
                    phone: studentNumber
                }).then((response: any) => {
                    const { status } = response.data;
                    if (status === "error") {
                        const errorMSG = response.data.msg.includes("balance")
                            ? "Nepietiek līdzekļu"
                            : "Kļūda!"
                        message.error(errorMSG);
                        return;
                    }
                    setError("")
                    updateSlots()
                    // else 
                    resetModal();
                    console.log(response)
                }).catch((err: any) => {
                    const { data } = err.response;
                    errorMessageResolver(err);
                    if (data.message === "Student not found!") {
                        setErrorPhone(true);
                    }
                    message.error(data.message);
                })
            } else {
                message.error(msg, 10)
            }
        }).catch((err: AxiosError) => {
            console.log(err.response)
            message.error("Kļūda!");
        })

    };

    const errorMessageResolver = (err: any) => {
        const { data, status } = err.response;
        if (status === 403) {
            if (data?.length > 0) {
                const errorType = { time_taken: "Izvēlētais laiks ir aizņemts!" } as any;
                const activities = {
                    driving_lesson: "Braukšanas nodarbība",
                    theory_lesson: "Teorijas nodarbība",
                    pmp_lesson: "Pirmās palīdzības nodarbība",
                    theory_exam: "Teorijas eksāmens"
                } as any;
                setError(`${errorType[data[0].message_type]} ${data[0].info} ${activities[data[0].reason]}`);
                return;
            }
            if (Object.keys(data)?.length > 0) {
                const errorType = {
                    expired_theory: "Teorijas 1 gada termiņš ir pārsniegts!",
                    theory_not_finished: "Teorijas apmācība nav pabeigta!",
                } as any;
                setError(errorType[data?.message_type] ?? "Kļūda!");
                return;
            }
        } else {
            setError("")
        }
    }

    const resetModal = () => {
        setStudentNumber("");
        setModalVisible(false);
    }

    useEffect(() => {
        // console.log(slot)
    }, [modalVisible]);

    return <Modal
        visible={props.modalVisible}
        onOk={() => props.setModalVisible(false)}
        onCancel={() => props.setModalVisible(false)}
        title={<b>{moment(slot.exam_date).format("DD.MM.YYYY")}&nbsp;&nbsp;&nbsp;
            {moment(slot.time_from, "HH:mm:ss").format("HH:mm")} - {moment(slot.time_to, "HH:mm:ss").format("HH:mm")}</b>}
        footer={() => <></>}
        zIndex={1031}
    >
        {
            error.length > 0
            && <Tag color="red" style={{ marginBottom: 10, whiteSpace: "normal" }}>
                {error}
            </Tag>
        }
        <Card
            title="Pievienot studentu"
        >
            <Row>
                <Col>
                    <Row gutter={[20, 20]}>
                        {/* <Col span={24}>
                            <Tag color="red" style={{ whiteSpace: "normal", textAlign: "center" }}>
                                Uzmanību! Piesakot eksāmenu, pārliecinies, ka studentam ir nokārtota pirmā medicīniskā palīdzība un medicīniskā izziņa!
                            </Tag>
                        </Col> */}
                        <Col span={24}>
                            <p><b style={{ marginBottom: 8 }}>Studenta tālruņa numurs</b></p>
                            <Input
                                value={studentNumber}
                                onChange={(e: any) => {
                                    setStudentNumber(e.target.value)
                                    setErrorPhone(false);
                                }}
                            />
                        </Col>
                        <Col span={24}>
                            <p><b style={{ marginBottom: 8 }}>Kategorija</b></p>
                            <Select
                                style={{ width: 100 }}
                                onChange={(e: any) => setCategory(e)}
                            >
                                {
                                    categories.map((category: any) => {
                                        return <Select.Option key={category.id} value={category.id}>
                                            {category.value}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                disabled={studentNumber.length === 0 || category === undefined}
                                onClick={addStudentToSlot}
                            >
                                Pievienot
                            </Button>
                        </Col>
                    </Row>
                    {
                        errorPhone
                            ? <div><b style={{ color: "red" }}>Students nav atrasts!</b></div>
                            : <></>
                    }
                </Col>
            </Row>
        </Card>
    </Modal>
}