import React, { useEffect, useState } from "react";
import { BannerEditor } from "./BannerEditor";
import { http } from "../../helpers/http";
import { StaticBanner } from "./StaticBanner";
import { Button, Card, Layout, PageHeader, Row } from "antd";
import { Route, Switch, useHistory } from "react-router-dom";
import { BannerForm } from "./BannerForm";

export const Banners = () => {
    const history = useHistory();
    const [banners, setBanners] = useState([] as any);
    const [categories, setCategories] = useState([] as any);
    const [languages, setLanguages] = useState([] as any);

    const getCategories = () => {
        http.get(`rest/categories`).then((response) => {
            setCategories(response.data.data);
        })
    }

    const getLanguages = () => {
        http.get(`rest/languages`).then((response) => {
            setLanguages(response.data.data);
        })
    }

    const getBanners = () => {
        http.get(`rest/banners`).then((response) => {
            setBanners(response.data.data);
        })
    }

    useEffect(() => {
        getCategories();
        getLanguages();
        getBanners();
    }, [])

    return <Layout>
        <PageHeader
            title="Baneri"
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF" }}
        />
        <Switch>
            <Route path="/options/banners/edit/:id">
                <BannerForm categories={categories} languages={languages} refresh={getBanners} />
            </Route>
            <Route path="/options/banners/new">
                <BannerForm categories={categories} languages={languages} refresh={getBanners} />
            </Route>
            <Route path="/options/banners" component={() => <Card style={{ margin: 20 }}>
                <Row style={{ marginBottom: 20 }}>
                    <Button
                        type="primary"
                        onClick={() => history.push("/options/banners/new")}
                    >
                        Jauns baneris
                    </Button>
                </Row>
                {
                    banners?.map((banner: any) => {
                        return <div key={banner.id} style={{ marginBottom: 20 }}>
                            <StaticBanner banner={banner} />
                        </div>
                    })
                }
            </Card>} />
        </Switch>
    </Layout >
}