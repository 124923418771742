import { Button, Card, Col, Layout, PageHeader, Row, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";
import { UserForm } from "./UserForm";

export const UsersMain = withRouter((props: any) => {
    const { t } = useTranslation();

    const history = useHistory();

    const [users, setUsers] = useState([] as any);
    const [showForm, setShowForm] = useState(false);
    const [toEdit, setToEdit] = useState({} as any);

    const fetchUsers = async () => {
        setShowForm(false);
        await http.get(`/rest/admins`).then((response: any) => {
            setUsers(response.data.data)
        })
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        if (Object.keys(toEdit).length > 0) {
            setShowForm(true);
        }
    }, [toEdit]);

    const cols = [
        {
            title: "Aktīvs",
            dataIndex: "active",
            key: "active",
            render: (active: 1 | 0) => {
                return active === 1 ? <Tag color="green">Aktīvs</Tag> : <Tag color="red">Neaktīvs</Tag>
            }
        },
        {
            title: "Vārds",
            dataIndex: "first_name",
            key: "first_name"
        },
        {
            title: "Uzvārds",
            dataIndex: "last_name",
            key: "last_name"
        },
        {
            title: "E-pasts",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Atļaujas",
            dataIndex: "permission",
            key: "permission",
            render: (record: any, all: any) => {
                console.log(all)
                if (all.ruler_of_universe) {
                    return <Tag color="red">Administrātors</Tag>
                }
                return record.map((perm: any, index: any) => {
                    return <Tag key={index}>{t(`permissions.${perm}`)}</Tag>
                })
            }
        },
    ] as any;

    return (
        <Layout>
            <PageHeader
                title="Lietotaji"
                style={{ backgroundColor: "#FFFFFF" }}
                onBack={history.goBack}
            />
            <Row>
                <Col span={24}>
                    <Card style={{ marginTop: 10 }}>
                        <Row justify="end">
                            <Button
                                style={{ marginBottom: 10 }}
                                type="primary"
                                onClick={() => {
                                    setShowForm(!showForm);
                                    setToEdit({});
                                }}
                            >
                                {!showForm ? "Pievienot lietotāju" : "Atcelt"}
                            </Button>
                        </Row>

                        {
                            showForm
                                ? <UserForm refresh={fetchUsers} toEdit={toEdit} rights={props.rights} />
                                : <></>
                        }
                        <Table
                            columns={cols}
                            dataSource={users}
                            rowKey={record => record.id}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        setToEdit(record)
                                    }, // click row
                                    onDoubleClick: event => { }, // double click row
                                    onContextMenu: event => { }, // right button click row
                                    onMouseEnter: event => { }, // mouse enter row
                                    onMouseLeave: event => { }, // mouse leave row
                                };
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
})