import { Card, Layout, PageHeader, Spin } from 'antd'
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { http } from '../../helpers/http';
import CategoryCitySearchExceptionForm from './CategoryCitySearchExceptionForm';
import { CityInstructorApperanceRestrictions } from './CityInstructorApperanceRestrictions';

function ExceptionsForInstructorsSearch() {
    const history = useHistory();
    const [exceptions, setExceptions] = useState([] as any);
    const [cities, setCities] = useState([] as any);
    const [categories, setCategories] = useState([] as any);
    const [loading, setLoading] = useState(true);

    const getExceptions = () => {
        http.get(`rest/disable-available-instructor-week-check`).then((res: AxiosResponse) => {
            setExceptions(res.data.data)
            setCities(res.data.cities)
            setCategories(res.data.categories)
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        getExceptions();
    }, [])

    return <Layout>
        <PageHeader
            title="Izņēmumi instruktoru meklēšanā"
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF" }}
        />
        {
            loading
            && <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(200, 200, 200, .3)", zIndex: 100 }} >
                <Spin spinning={loading} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            </div>
        }
        <Card style={{ margin: 20 }}>
            <p><b>* Jauniem studentiem netiek attēloti instruktori, kuriem tuvāko 3 nedēļu laikā nav pieejamas nodarbības. Šajā sadaļā ir iespējams pievienot izņēmumus pilsētām un kategorijām, kurās instruktori tiks attēloti jebkurā gadījumā.</b></p>
            <CategoryCitySearchExceptionForm
                categories={categories}
                cities={cities}
                exception={undefined}
                refresh={getExceptions}
                setLoading={setLoading}
            />
            {
                exceptions.map((exception: any) => <CategoryCitySearchExceptionForm
                    key={exception.id}
                    categories={categories}
                    cities={cities}
                    exception={exception}
                    refresh={getExceptions}
                    setLoading={setLoading}
                />)
            }
        </Card>
        <CityInstructorApperanceRestrictions />
    </Layout>
}

export default ExceptionsForInstructorsSearch