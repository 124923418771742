import { Card, Row, Col, Select, Checkbox, Tabs, Input, Button, message, Modal, Collapse } from "antd";
import { EditTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";

export const NotificationTypeForm = (props: any) => {
    const {
        allCategories,
        allLanguages,
        texts,
        groups,
        statuses
    } = props;

    const [channels, setChannels] = useState({
        email: false,
        sms: false,
        whatsapp: false,
        db: false,
        fcm: false
    }) as any;
    const [categories, setCategories] = useState([] as any);
    const [formData, setFormData] = useState([] as any);
    const [massFormUpdateData, setMassFormUpdateData] = useState([
        {
            language_id: null,
            email_subject: "",
            email_content: "",
            sms_content: "",
            whatsapp_content: "",
            db_content: "",
            fcm_content: "",
            fcm_title: "",
            fcm_full_content: ""
        } as any
    ]);
    const [langTab, setLangTab] = useState("1");
    // const [group, setGroup] = useState(props.type.notification_group);
    // const [status, setStatus] = useState(type.status);
    const [editTypeNameModalVisible, setEditTypeNameModalVisible] = useState(false);
    const [typeName, setTypeName] = useState(
        props.type?.notification_type_name === null
            ? props.type?.notification_type
            : props.type?.notification_type_name
    )
    const [advancedSettingsVisible, setAdvancedSettingsVisible] = useState(false);
    const [type, setType] = useState(props.type);

    const channelTypes = {
        email: "E-pasts",
        sms: "SMS",
        whatsapp: "Whatsapp",
        db: "Portālā",
        fcm: "Mobilajā aplikācijā"
    } as any;

    const groupNames = {
        driving: "Braukšana",
        theory: "Teorija",
        administration: "Administratīvie"
    } as any;

    const statusNames = {
        success: "Veiksmīgs",
        warning: "Brīdinājums",
        error: "Kļūda"
    } as any;

    const variableNames = {
        csdd_nr: "CSDD numurs",
        start_date: "Sākuma datums",
        start_time: "Sākuma laiks",
        date: "Datums",
        name: "Nosaukums",
        theory_attained_count: "Apmeklēto lekciju daudzums",
        lesson_date: "Nodarbības laiks",
        exam_price: "Eksāmena cena",
        free_exam_days: "Bezmaksas eksāmena dienas",
        till_date: "Datums līdz",
        title: "E-apmācības moduļa nosaukums",
        student_full_name: "Studenta vārds",
        left_module_count: "Cik moduļi atlikuši",
        module_count: "Moduļi kopā",
        study_frequency: "Cik nedēļā moduļi",
        online_attained_count: "Cik moduļi apmeklēti"
    } as any;

    const saveNotification = async () => {
        const categoryEntries = formData.filter((item: any) => {
            return categories.some((el: any) => el.id === item.category_id)
        });
        const processedData = categoryEntries.map((item: any) => {
            return {
                ...item,
                email_content: channels.email ? item.email_content : null,
                email_subject: channels.email ? item.email_subject : null,
                sms_content: channels.sms ? item.sms_content : null,
                whatsapp_content: channels.whatsapp ? item.whatsapp_content : null,
                db_content: channels.db ? item.db_content : null,
                fcm_content: channels.fcm ? item.fcm_content : null,
                fcm_title: channels.fcm ? item.fcm_title : null,
                fcm_full_content: channels.fcm ? item.fcm_full_content : null,
                notification_type_id: type.id
            }
        });
        if (processedData.length === 0) processedData.push({ notification_type_id: type.id })
        await http.patch(`rest/student-notification-types/${type.id}`, {
            notification_type: type.notification_type,
            notification_group: type.notification_group,
            status: type.status,
            send_limit: type.send_limit,
            one_timer: type.one_timer,
            cron_notification: type.cron_notification
        }).then((response: any) => {
            message.success("Paziņojuma tips ir atjaunots")
        })
        await http.post(`rest/student-notification-texts`, { notification: processedData }).then((response: any) => {
            message.success("Paziņojums saglabāts")
        }).catch((err: any) => {
            message.error("Kļūda!")
        });
    };

    const setData = (data: any) => {
        setChannels({
            email: data.some((el: any) => el.email_content !== null),
            sms: data.some((el: any) => el.sms_content !== null),
            whatsapp: data.some((el: any) => el.whatsapp_content !== null),
            db: data.some((el: any) => el.db_content !== null),
            fcm: data.some((el: any) => el.fcm_content !== null)
        });
        setCategories(() => {
            const uniqueCategories = data.reduce((prevItem: any, currentItem: any) => {
                const hasThisCategory = prevItem.some((el: any) => el.id === currentItem.category_id)
                if (hasThisCategory) return prevItem;
                return [
                    ...prevItem,
                    allCategories.find((el: any) => el.id === currentItem.category_id)
                ]
            }, []);
            return uniqueCategories
        });
        setFormData(data);
    };

    const massUpdateFormHandler = (key: string, lang: any, text: string) => {
        setMassFormUpdateData((prev: any) => {
            const toChange = prev.find((el: any) => {
                return lang.id === el.language_id
            });
            if (toChange === undefined) {
                let newArr = JSON.parse(JSON.stringify(prev));
                if (prev.some((el: any) => el.language_id === null)) {
                    newArr = prev.map((item: any) => {
                        if (item.language_id === null) {
                            return {
                                language_id: lang.id,
                                [key]: text
                            }
                        } else return item
                    });
                    return newArr;
                } else {
                    newArr.push({
                        language_id: lang.id,
                        [key]: text
                    });
                    return newArr;
                }
            } else {
                const newArr = prev.map((item: any) => {
                    if (item.language_id === toChange.language_id) {
                        return {
                            ...toChange,
                            [key]: text
                        }
                    } else return item;
                });
                return newArr;
            }
        })
    };

    const formUpdateHandler = (key: string, category: any, language: any, text: string) => {
        setFormData((prev: any) => {
            const toChange = prev?.find((el: any) => {
                return el.category_id === category.id && el.language_id === language.id
            });
            if (toChange === undefined) {
                return [
                    ...prev,
                    {
                        category_id: category.id,
                        language_id: language.id,
                        notification_type_id: type.id,
                        [key]: text,
                    }
                ]
            } else {
                const arrCopy = JSON.parse(JSON.stringify(prev))
                const newArr = arrCopy?.map((item: any) => {
                    if (item.category_id === category.id && item.language_id === language.id) {
                        return {
                            ...item,
                            [key]: text
                        }
                    } else return item;
                });
                return newArr;
            }
        })
    };

    const massUpdateForms = (channel: any, email: boolean = false, fcm: boolean = false) => {
        Modal.confirm({
            title: "Vai tiešām pielietot ievadīto tekstu visām kategorijām?",
            content: "Apstiprinot, tiks izmainīti visu kategoriju paziņojuma teksts attiecīgajā kanālā.",
            onOk() {
                let newArr = [];
                let copyArr = JSON.parse(JSON.stringify(formData));
                const copyMassArr = JSON.parse(JSON.stringify(massFormUpdateData))
                if (copyArr.length === 0) {
                    const newArr = copyMassArr.map((item: any) => {
                        return categories.map((cat: any) => {
                            const textObj =
                                email
                                    ? {
                                        [`${channel}_subject`]: item[`${channel}_subject`],
                                        [`${channel}_content`]: item[`${channel}_content`]
                                    }
                                    : fcm
                                        ? {
                                            [`${channel}_title`]: item[`${channel}_title`],
                                            [`${channel}_content`]: item[`${channel}_content`]
                                        }
                                        : {
                                            [`${channel}_content`]: item[`${channel}_content`]
                                        }
                            return {
                                ...textObj,
                                language_id: item.language_id,
                                category_id: cat.id
                            }
                        });
                    })
                    const flattenArr = [].concat(...newArr)
                    setFormData(flattenArr)
                    return;
                }
                const differentLangs = copyMassArr.filter((item: any) => !copyArr.some((el: any) => el.language_id === item.language_id))
                if (differentLangs.length > 0) {
                    const newEntries = differentLangs.map((item: any) => {
                        return categories.map((cat: any) => {
                            const textObj =
                                email
                                    ? {
                                        [`${channel}_subject`]: item[`${channel}_subject`],
                                        [`${channel}_content`]: item[`${channel}_content`]
                                    }
                                    : fcm
                                        ? {
                                            [`${channel}_title`]: item[`${channel}_title`],
                                            [`${channel}_content`]: item[`${channel}_content`]
                                        }
                                        : {
                                            [`${channel}_content`]: item[`${channel}_content`]
                                        }
                            return {
                                ...textObj,
                                language_id: item.language_id,
                                category_id: cat.id
                            }
                        });
                    })
                    const flattenArr = [].concat(...newEntries)
                    copyArr = copyArr.concat(flattenArr)
                }
                const allCategoriesArray = copyMassArr.map((item: any) => {
                    return categories.map((cat: any) => {
                        const toReturn = copyArr.find((el: any) => el.category_id === cat.id)
                        if (toReturn !== undefined) return toReturn;
                        else return {
                            category_id: cat.id,
                            language_id: item.language_id
                        }
                    })
                });
                copyArr = copyArr.concat(...allCategoriesArray)
                newArr = copyArr.map((item: any) => {
                    const related = copyMassArr.find((el: any) => {
                        return el.language_id === item.language_id;
                    }) as any;
                    const content = related?.[`${channel}_content`] !== undefined
                        ? related[`${channel}_content`].length === 0
                            ? item?.[`${channel}_content`]
                            : related[`${channel}_content`]
                        : item?.[`${channel}_content`]
                    if (email) {
                        const subject = related[`${channel}_subject`] !== undefined
                            ? related[`${channel}_subject`].length === 0
                                ? item?.[`${channel}_subject`]
                                : related[`${channel}_subject`]
                            : item?.[`${channel}_subject`]
                        return {
                            ...item,
                            [`${channel}_content`]: content,
                            [`${channel}_subject`]: subject,
                        }
                    } else if (fcm) {
                        const title = related[`${channel}_title`] !== undefined
                            ? related[`${channel}_title`].length === 0
                                ? item?.[`${channel}_title`]
                                : related[`${channel}_title`]
                            : item?.[`${channel}_title`]
                        return {
                            ...item,
                            [`${channel}_content`]: content,
                            [`${channel}_title`]: title,
                        }
                    } else {
                        return {
                            ...item,
                            [`${channel}_content`]: content,
                        }
                    };
                });
                setFormData(newArr)
            }
        })
    };

    const saveTypeName = async () => {
        await http.patch(`rest/student-notification-types/${type.id}`, {
            notification_type: type.notification_type,
            notification_type_name: typeName
        }).then((response: any) => {
            message.success("Paziņojuma nosaukums ir atjaunots")
        }).catch((err: any) => {
            message.error("Kļūda!")
        });
        setEditTypeNameModalVisible(false)
    }

    useEffect(() => {
        if (texts?.length > 0 && allLanguages.length > 0) {
            setData(texts)
        }
    }, [texts, allLanguages]);

    const updateType = (value: any, name: any) => {
        setType((prev: any) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const cardHeader = <>
        <Row justify="space-between">
            <div style={{ display: "flex" }}>
                <p style={{ whiteSpace: "normal" }}>{typeName}</p>
                <Button
                    type="text"
                    icon={<EditTwoTone />}
                    onClick={() => setEditTypeNameModalVisible(true)}
                />
                <Modal
                    visible={editTypeNameModalVisible}
                    onCancel={() => {
                        setEditTypeNameModalVisible(false)
                        setTypeName(
                            type?.notification_type_name === null
                                ? type?.notification_type
                                : type?.notification_type_name
                        )
                    }}
                    onOk={saveTypeName}
                >
                    <h3>Ievadiet paziņojuma nosaukumu</h3>
                    <Input.TextArea
                        autoSize={{ maxRows: 3, minRows: 3 }}
                        value={typeName}
                        style={{ width: "100%" }}
                        onChange={(e: any) => {
                            e.persist();
                            setTypeName(e.target.value)
                        }}
                    />
                </Modal>
            </div>
            <Button
                onClick={() => setAdvancedSettingsVisible(!advancedSettingsVisible)}
            >
                {
                    advancedSettingsVisible
                        ? "Paslēpt papildus uzstādījumus"
                        : "Papildus uzstādījumi"
                }
            </Button>
        </Row>
        {
            advancedSettingsVisible
                ? <Row gutter={16}>
                    <Col>
                        <div style={{ paddingBottom: 5, display: "flex", justifyContent: "space-between" }}>
                            <span style={{ fontSize: 14 }}>Status: </span>
                            <Select
                                style={{ width: 200, marginLeft: 8 }}
                                value={type.status}
                                onChange={(e: any) => updateType(e, "status")}
                            >
                                {
                                    statuses.map((status: any) => {
                                        return <Select.Option
                                            value={status}
                                            key={status}
                                        >
                                            {statusNames[status]}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div style={{ paddingBottom: 5, display: "flex", justifyContent: "space-between" }}>
                            <span style={{ fontSize: 14 }}>Veids: </span>
                            <Select
                                style={{ width: 200, marginLeft: 8 }}
                                value={type.notification_group}
                                onChange={(e: any) => updateType(e, "notification_group")}
                            >
                                {
                                    groups.map((group: any, index: any) => {
                                        return <Select.Option
                                            value={group}
                                            key={index}
                                        >
                                            {groupNames[group]}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div style={{ paddingBottom: 5, display: "flex", justifyContent: "space-between" }}>
                            <span style={{ fontSize: 14 }}>Cik daudz reizes sūtīt (max):&nbsp;</span>
                            <Input
                                value={type.send_limit}
                                style={{ width: 50, marginLeft: 8 }}
                                onChange={(e: any) => {
                                    let text = e.target.value;
                                    if (text.length === 0) text = null
                                    updateType(text, "send_limit")
                                }}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div style={{ paddingBottom: 5, display: "flex", justifyContent: "space-between" }}>
                            <span style={{ fontSize: 14 }}>Cron paziņojums: </span>
                            <Checkbox
                                checked={type.cron_notification}
                                style={{ width: 50, marginLeft: 8 }}
                                onChange={(e: any) => {
                                    updateType(e.target.checked ? 1 : 0, "cron_notification")
                                }}
                            />
                        </div>
                        <div style={{ paddingBottom: 5, display: "flex", justifyContent: "space-between" }}>
                            <span style={{ fontSize: 14 }}>Sūta vienreiz: </span>
                            <Checkbox
                                checked={type.one_timer}
                                style={{ width: 50, marginLeft: 8 }}
                                onChange={(e: any) => {
                                    updateType(e.target.checked ? 1 : 0, "one_timer")
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                : <></>
        }
    </>


    return <Card
        key={type?.id}
        title={cardHeader}
        style={{ marginBottom: 20 }}
    >
        <Row gutter={24}>
            <Col>
                <Select
                    value={categories.length === 0 ? [] : categories?.map((cat: any) => cat.id)}
                    mode="multiple"
                    style={{ width: 200 }}
                    onChange={(e: any) => {
                        setCategories(() => {
                            const selectedCategories = e.map((id: any) => {
                                return allCategories.find((el: any) => el.id === id)
                            });
                            return selectedCategories;
                        });
                    }}
                >
                    {
                        allCategories?.map((category: any) => {
                            return <Select.Option key={category.id} value={category?.id}>
                                {category.value}
                            </Select.Option>
                        })
                    }
                </Select>
                <div style={{ marginTop: 8 }}>
                    <span>Visas kategorijas:</span>
                    <Checkbox
                        style={{ marginLeft: 8 }}
                        onChange={(e: any) => {
                            if (e.target.checked) {
                                setCategories(allCategories)
                            } else {
                                setCategories([])
                            }

                        }}
                    />
                </div>
            </Col>
            <Col>
                <p>Kanāli:</p>
                {
                    Object.keys(channelTypes).map((channel: any, index: any) => {
                        return <div style={{ marginTop: 8 }} key={index}>
                            <Checkbox
                                checked={channels[channel]}
                                style={{ marginRight: 8 }}
                                disabled={categories.length === 0}
                                onChange={(e: any) => {
                                    setChannels((prev: any) => {
                                        return {
                                            ...prev,
                                            [channel]: e.target.checked
                                        }
                                    });
                                }}
                            />
                            <span>{channelTypes[channel]}</span>
                        </div>
                    })
                }
            </Col>
            <Col>
                <Collapse>
                    <Collapse.Panel
                        disabled={Object.keys(channels).filter((key: any) => channels[key]).length === 0}
                        key={1}
                        header="Atjaunot paziņojuma tekstus visām kategorijām"
                    >
                        {
                            Object.keys(channels).map((channel: any, index: any) => {
                                if (!channels[channel]) return <div key={index}></div>
                                return <div key={index}>
                                    <p style={{ marginBottom: 5 }}>Pamata teksts {channelTypes[channel]}</p>
                                    <Tabs activeKey={langTab} style={{ flexDirection: "column" }} onChange={(e: any) => setLangTab(e)}>
                                        {
                                            allLanguages.map((lang: any, index: any) => {
                                                const content = massFormUpdateData?.find((el: any) => el.language_id === lang.id);
                                                return <Tabs.TabPane tab={lang.iso?.toUpperCase()} key={lang.id}>
                                                    {
                                                        channel === 'email'
                                                            ? <>
                                                                <p>Paziņojuma virsraksts</p>
                                                                <Input
                                                                    style={{ width: 500 }}
                                                                    value={content?.email_subject}
                                                                    onChange={(e: any) => {
                                                                        e.persist();
                                                                        massUpdateFormHandler('email_subject', lang, e.target.value);
                                                                    }}
                                                                />
                                                                {
                                                                    props.type.variables.length > 0
                                                                        ? <span>Pievienot mainīgos: </span>
                                                                        : <></>
                                                                }
                                                                {
                                                                    props.type.variables.map((variable: any) => {
                                                                        return <Button
                                                                            key={variable}
                                                                            style={{ marginRight: 8, marginTop: 8 }}
                                                                            onClick={() => {
                                                                                const newString = content?.email_subject !== undefined
                                                                                    ? content?.email_subject + `{$${variable}}`
                                                                                    : `{$${variable}}`
                                                                                massUpdateFormHandler(`email_subject`, lang, newString);
                                                                            }}
                                                                        >
                                                                            {
                                                                                variableNames[variable] || variable
                                                                            }
                                                                        </Button>
                                                                    })
                                                                }
                                                            </>
                                                            : <></>
                                                    }
                                                    {
                                                        channel === 'fcm'
                                                            ? <>
                                                                <p>Paziņojuma virsraksts</p>
                                                                <Input
                                                                    style={{ width: 500 }}
                                                                    value={content?.fcm_title}
                                                                    onChange={(e: any) => {
                                                                        e.persist();
                                                                        massUpdateFormHandler('fcm_title', lang, e.target.value);
                                                                    }}
                                                                />
                                                                {
                                                                    props.type.variables.length > 0
                                                                        ? <span>Pievienot mainīgos: </span>
                                                                        : <></>
                                                                }
                                                                {
                                                                    props.type.variables.map((variable: any) => {
                                                                        return <Button
                                                                            key={variable}
                                                                            style={{ marginRight: 8, marginTop: 8 }}
                                                                            onClick={() => {
                                                                                const newString = content?.fcm_title !== undefined
                                                                                    ? content?.fcm_title + `{$${variable}}`
                                                                                    : `{$${variable}}`
                                                                                massUpdateFormHandler(`fcm_title`, lang, newString);
                                                                            }}
                                                                        >
                                                                            {
                                                                                variableNames[variable] || variable
                                                                            }
                                                                        </Button>
                                                                    })
                                                                }
                                                            </>
                                                            : <></>
                                                    }
                                                    <p style={{ marginTop: 20 }}>Paziņojuma teksts</p>
                                                    <Input.TextArea
                                                        style={{ width: 500, marginBottom: 15 }}
                                                        autoSize={{ minRows: 5, maxRows: 5 }}
                                                        value={content?.[`${channel}_content` as any]}
                                                        onChange={(e: any) => {
                                                            e.persist();
                                                            massUpdateFormHandler(`${channel}_content`, lang, e.target.value);
                                                        }}
                                                    />
                                                    {
                                                        props.type.variables.length > 0
                                                            ? <span>Pievienot mainīgos: </span>
                                                            : <></>
                                                    }
                                                    {
                                                        props.type.variables.map((variable: any) => {
                                                            return <Button
                                                                key={variable}
                                                                style={{ marginRight: 8, marginTop: 8 }}
                                                                onClick={() => {
                                                                    const newString = content?.[`${channel}_content` as any] !== undefined
                                                                        ? content?.[`${channel}_content` as any] + `{$${variable}}`
                                                                        : `{$${variable}}`
                                                                    massUpdateFormHandler(`${channel}_content`, lang, newString);
                                                                }}
                                                            >
                                                                {
                                                                    variableNames[variable] || variable
                                                                }
                                                            </Button>
                                                        })
                                                    }
                                                    {
                                                        channel === 'fcm'
                                                        && <>
                                                            <p style={{ marginTop: 20 }}>Paziņojuma pilnais teksts</p>
                                                            <Input.TextArea
                                                                style={{ width: 500, marginBottom: 15 }}
                                                                autoSize={{ minRows: 5, maxRows: 5 }}
                                                                value={content?.[`${channel}_full_content` as any]}
                                                                onChange={(e: any) => {
                                                                    e.persist();
                                                                    massUpdateFormHandler(`${channel}_full_content`, lang, e.target.value);
                                                                }}
                                                            />
                                                            {
                                                                props.type.variables.length > 0
                                                                    ? <span>Pievienot mainīgos: </span>
                                                                    : <></>
                                                            }
                                                            {
                                                                props.type.variables.map((variable: any) => {
                                                                    return <Button
                                                                        key={variable}
                                                                        style={{ marginRight: 8, marginTop: 8 }}
                                                                        onClick={() => {
                                                                            const newString = content?.[`${channel}_full_content` as any] !== undefined
                                                                                ? content?.[`${channel}_full_content` as any] + `{$${variable}}`
                                                                                : `{$${variable}}`
                                                                            massUpdateFormHandler(`${channel}_full_content`, lang, newString);
                                                                        }}
                                                                    >
                                                                        {
                                                                            variableNames[variable] || variable
                                                                        }
                                                                    </Button>
                                                                })
                                                            }
                                                        </>
                                                    }
                                                </Tabs.TabPane>
                                            })
                                        }
                                    </Tabs>
                                    <Row justify="end">
                                        <Button
                                            type="primary"
                                            style={{ marginTop: 10 }}
                                            onClick={() => massUpdateForms(channel, channel === "email", channel === "fcm")}
                                        >
                                            Aizpildīt visas formas
                                        </Button>
                                    </Row>
                                </div>
                            })
                        }
                    </Collapse.Panel>
                </Collapse>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                {
                    Object.keys(channels).map((channel: any, index: any) => {
                        if (!channels[channel]) return <></>
                        return <Card
                            style={{ marginTop: 10 }}
                            title={channelTypes[channel]}
                            key={index}
                        >
                            <Tabs>
                                {
                                    categories.map((category: any, index: any) => {
                                        return <Tabs.TabPane key={index} tab={category.value}>
                                            <Tabs activeKey={langTab} onChange={(e: any) => setLangTab(e)}>
                                                {
                                                    allLanguages.map((language: any, index: any) => {
                                                        const data = formData.find((el: any) => {
                                                            return (el.category_id === category.id && el.language_id === language.id)
                                                                || (!el.hasOwnProperty('category_id') && el.language_id === language.id)
                                                        })
                                                        return <Tabs.TabPane key={language.id} tab={language.iso?.toUpperCase()}>
                                                            {
                                                                channel === "email"
                                                                    ? <>
                                                                        <p>Paziņojuma virsraksts</p>
                                                                        <Input
                                                                            value={data?.['email_subject']}
                                                                            onChange={(e: any) => {
                                                                                e.persist();
                                                                                formUpdateHandler('email_subject', category, language, e.target.value);
                                                                            }}
                                                                        />
                                                                        {
                                                                            props.type.variables.length > 0
                                                                                ? <span>Pievienot mainīgos: </span>
                                                                                : <></>
                                                                        }
                                                                        {
                                                                            props.type.variables.map((variable: any) => {
                                                                                return <Button
                                                                                    key={variable}
                                                                                    style={{ marginRight: 8, marginTop: 8 }}
                                                                                    onClick={() => {
                                                                                        const newString = data?.email_subject !== undefined
                                                                                            ? data?.email_subject + `{$${variable}}`
                                                                                            : `{$${variable}}`
                                                                                        formUpdateHandler('email_subject', category, language, newString);
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        variableNames[variable] || variable
                                                                                    }
                                                                                </Button>
                                                                            })
                                                                        }
                                                                    </>
                                                                    : <></>
                                                            }
                                                            {
                                                                channel === "fcm"
                                                                    ? <>
                                                                        <p>Paziņojuma virsraksts</p>
                                                                        <Input
                                                                            value={data?.['fcm_title']}
                                                                            onChange={(e: any) => {
                                                                                e.persist();
                                                                                formUpdateHandler('fcm_title', category, language, e.target.value);
                                                                            }}
                                                                        />
                                                                        {
                                                                            props.type.variables.length > 0
                                                                                ? <span>Pievienot mainīgos: </span>
                                                                                : <></>
                                                                        }
                                                                        {
                                                                            props.type.variables.map((variable: any) => {
                                                                                return <Button
                                                                                    key={variable}
                                                                                    style={{ marginRight: 8, marginTop: 8 }}
                                                                                    onClick={() => {
                                                                                        const newString = data?.fcm_title !== undefined
                                                                                            ? data?.fcm_title + `{$${variable}}`
                                                                                            : `{$${variable}}`
                                                                                        formUpdateHandler('fcm_title', category, language, newString);
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        variableNames[variable] || variable
                                                                                    }
                                                                                </Button>
                                                                            })
                                                                        }
                                                                    </>
                                                                    : <></>
                                                            }
                                                            <p style={{ marginTop: 20 }}>Paziņojuma teksts</p>
                                                            <Input.TextArea
                                                                autoSize={{ maxRows: 5, minRows: 5 }}
                                                                value={data?.[`${channel}_content`]}
                                                                onChange={(e: any) => {
                                                                    e.persist();
                                                                    formUpdateHandler(`${channel}_content`, category, language, e.target.value);
                                                                }}
                                                            />
                                                            {
                                                                props.type.variables.length > 0
                                                                    ? <span>Pievienot mainīgos: </span>
                                                                    : <></>
                                                            }
                                                            {
                                                                props.type.variables.map((variable: any) => {
                                                                    return <Button
                                                                        key={variable}
                                                                        style={{ marginRight: 8, marginTop: 8 }}
                                                                        onClick={() => {
                                                                            const newString = data?.[`${channel}_content`] !== undefined
                                                                                ? data?.[`${channel}_content`] + `{$${variable}}`
                                                                                : `{$${variable}}`
                                                                            formUpdateHandler(`${channel}_content`, category, language, newString);
                                                                        }}
                                                                    >
                                                                        {
                                                                            variableNames[variable] || variable
                                                                        }
                                                                    </Button>
                                                                })
                                                            }
                                                            {
                                                                channel === "fcm"
                                                                && <>
                                                                    <p style={{ marginTop: 20 }}>Paziņojuma pilnais teksts</p>
                                                                    <Input.TextArea
                                                                        autoSize={{ maxRows: 5, minRows: 5 }}
                                                                        value={data?.[`${channel}_full_content`]}
                                                                        onChange={(e: any) => {
                                                                            e.persist();
                                                                            formUpdateHandler(`${channel}_full_content`, category, language, e.target.value);
                                                                        }}
                                                                    />
                                                                    {
                                                                        props.type.variables.length > 0
                                                                            ? <span>Pievienot mainīgos: </span>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        props.type.variables.map((variable: any) => {
                                                                            return <Button
                                                                                key={variable}
                                                                                style={{ marginRight: 8, marginTop: 8 }}
                                                                                onClick={() => {
                                                                                    const newString = data?.[`${channel}_full_content`] !== undefined
                                                                                        ? data?.[`${channel}_full_content`] + `{$${variable}}`
                                                                                        : `{$${variable}}`
                                                                                    formUpdateHandler(`${channel}_full_content`, category, language, newString);
                                                                                }}
                                                                            >
                                                                                {
                                                                                    variableNames[variable] || variable
                                                                                }
                                                                            </Button>
                                                                        })
                                                                    }
                                                                </>
                                                            }
                                                        </Tabs.TabPane>
                                                    })
                                                }
                                            </Tabs>
                                        </Tabs.TabPane>
                                    })
                                }
                            </Tabs>
                        </Card>
                    })
                }
            </Col>
        </Row>
        <Row justify="end">
            <Button
                type="primary"
                onClick={saveNotification}
                style={{ marginTop: 8 }}
            >
                Saglabāt
            </Button>
        </Row>
    </Card>
}