import { Button, Card, Row } from "antd";
import React from "react";
import { banner as bannerTpl } from "./bannerHtml";
import { useHistory } from "react-router-dom";

export const StaticBanner = ({ banner }: any) => {
    const history = useHistory();

    return !!banner
        ? <Card
            style={{ border: "1px solid #f0f0f0" }}
            hoverable
            onClick={() => history.push(`banners/edit/${banner?.id}`)}
        >
            <div dangerouslySetInnerHTML={{ __html: bannerTpl?.replace("banner-img", process.env.REACT_APP_API_URL + banner?.[`img_popup_lv`])?.replace("{CONTENT}", banner?.[`banner_popup_lv`]) }} />
            <Row justify="end">
                <Button
                    type="primary"
                    style={{ backgroundColor: "#482E80", borderColor: "unset", borderRadius: 8 }}
                >
                    {banner?.[`popup_btn_lv`]}
                </Button>
            </Row>
        </Card>
        : <></>
}