import { Button, Card, Tag } from "antd";
import React, { useState } from "react";
import { http } from "../../helpers/http";
import { set } from "lodash";

type CheckIfSpamSentProps = {
    isSent: boolean;
    refreshStudent: () => void;
    studentId: any;
    groupId: any;
}

export const CheckIfSpamSent = ({ isSent, refreshStudent, studentId, groupId }: CheckIfSpamSentProps) => {
    const [loading, setLoading] = useState(false);

    const sendSpam = () => {
        setLoading(true);
        http.get(`rest/send-new-web-app-notification/${groupId}/${studentId}`).then(() => {
            refreshStudent();
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }

    return <Card>
        <h3>Piekļuve</h3>
        <Tag color={isSent ? "green" : "red"}>Studenta reģistrācijas piekļuves {isSent ? "ir" : "nav"} nosūtītas</Tag>
        <Button
            onClick={sendSpam}
            loading={loading}
        >
            Nosūtīt piekļuves e-pastu
            {
                isSent
                    ? " atkārtoti"
                    : ""
            }
        </Button>
    </Card>
}