import { Button, Calendar, Card, Col, Input, Modal, Popover, Row, Space, Tag } from "antd"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { http } from "../../../helpers/http";
import { AddStudentModal } from "./AddStudentModal";

const OpenDayButton = (props: any) => {
    const { date, setSelectedDay, setPlannerForDay } = props;
    return <Button
        onClick={() => {
            setSelectedDay(date);
            setPlannerForDay(true);
        }}
        style={{
            fontSize: 9,
            position: "absolute",
            top: 4,
            padding: "1px 5px",
            height: "auto",
        }}
    >
        Atvērt dienas plānu
    </Button>
}

const ExamSlot = (props: any) => {
    const { slot, updateSlots, languages, categories } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [slotToModify, setSlotToModify] = useState({} as any);
    const hasStudents = slot.student_slots.filter((slot: any) => {
        return slot.student_id !== null
            && !slot.status.includes("cancelled");
    });
    const examIsOld = moment(slot.exam_date + " " + slot.time_from, "YYYY-MM-DD HH:mm:ss").isBefore(moment())
    const allStudentsApproved = !slot.student_slots?.some((el: any) => (
        el.status === "student_added"
    ))
    const bubbleColor = hasStudents.length === 0
        ? "#A1ABB3"
        : allStudentsApproved
            ? "#2C9C3B"
            : "#FA6400"

    const StudentSlot = (props: any) => {
        const { student } = props;
        const examTry = student?.student?.exams?.findIndex((el: any) => {
            return el.id === student.id
        });
        return <Row style={{ borderBottom: "1px solid #D5DCDF", padding: "8px 0" }}>
            <Col span={24}>
                <a
                    href={`${process.env.REACT_APP_ADMIN_URL}groups/student-opening/${student?.student_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={!!student?.year_limit_warning ? { color: "red" } : {}}
                >
                    <b>{student?.student?.full_name}</b>
                    {
                        !!student?.year_limit_warning
                        && <><br /><small color="red">Gads teorijas apmācībai: {moment(student?.year_limit_date).format("DD.MM.YYYY")}</small></>
                    }
                </a><br />
                {
                    !!student?.student?.study_group
                        ? <>
                            <b>Grupa: {student?.student?.study_group}</b><br />
                        </>
                        : <></>
                }

                <span>{student?.student?.category} kategorija, Mēģinājums: {examTry + 1}</span>&nbsp;
                <b style={{ color: "red" }}>{student.status.includes("cancelled") ? "Atcelts" : ""}</b>
            </Col>
        </Row>
    }

    const studentSlots = () => {
        return slot.student_slots?.map((slot: any, index: any) => {
            if (slot.student_id === null) {
                return <div key={index} style={{ borderBottom: "1px solid #D5DCDF", padding: "8px 0" }}>
                    Brīva vieta
                    <Button
                        type="link"
                        onClick={() => {
                            setModalVisible(true);
                            setSlotToModify(slot)
                        }}
                        disabled={examIsOld}
                    >
                        {
                            examIsOld
                                ? "Pieteikšanās laiks ir beidzies"
                                : "Pievienot studentu"
                        }
                    </Button>
                    <p style={{ margin: 0 }}>
                        Valoda: {
                            slot.languages.map((lang: any) => {
                                return languages.find((el: any) => el.id === lang)?.iso?.toUpperCase() + " "
                            })
                        }
                    </p>
                </div>
            } else {
                return <StudentSlot key={index} student={slot} />
            }
        })
    };

    const content = <Row style={{ padding: "0px 4px 8px" }}>
        <Col span={24}>
            {
                studentSlots()
            }
        </Col>
        <AddStudentModal
            modalVisible={modalVisible}
            setModalVisible={(state: any) => setModalVisible(state)}
            slot={slotToModify}
            updateSlots={() => updateSlots()}
            categories={categories}
        />
    </Row>;

    return <Popover
        content={content}
        trigger="hover"
        placement="right"
    >
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // paddingLeft: 10,
            position: "relative",
            border: "1px solid #D4DADE",
            borderRadius: 3,
            height: 22,
            marginBottom: 4,
            boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)",
        }}>
            <p style={{ fontSize: 11, margin: 0 }}>
                {moment(slot.time_from, "HH:mm:ss").format("HH:mm")} - {moment(slot.time_to, "HH:mm:ss").format("HH:mm")}
            </p>
            <div
                style={{
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    height: 16,
                    width: 16,
                    top: 3,
                    right: 3,
                    backgroundColor: bubbleColor
                }}
            >
                <span style={{ fontSize: 9, color: "#FFFFFF" }}>{hasStudents.length}</span>
            </div>
        </div>
    </Popover>
}

export const ExamCalendar = (props: any) => {
    const {
        examSlots,
        updateSlots,
        setPlannerForDay,
        setSelectedDay,
        languages,
        categories
    } = props;

    const dateCellRender = (value: any = moment()) => {
        const renderSlots = examSlots.filter((slot: any) => {
            return slot.exam_date === moment(value).format("YYYY-MM-DD")
        }).map((item: any, index: any) => {
            return <ExamSlot
                languages={languages}
                updateSlots={() => updateSlots()}
                key={index}
                slot={item}
                categories={categories}
            />
        });
        return [
            <OpenDayButton
                key={-1}
                date={value}
                setSelectedDay={(date: any) => setSelectedDay(date)}
                setPlannerForDay={(state: boolean) => setPlannerForDay(state)}
            />,
            ...renderSlots
        ]
    };

    return <Calendar
        dateCellRender={dateCellRender}
        onSelect={(date: any) => props.setSelectedDay(date)}
        value={props.selectedDay}
    />
}