import { Card, Layout, PageHeader } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { TranslationsForm } from "./TranslationsForm";

export const TranslationsView = (props: any) => {
    const history = useHistory();

    return <Layout className="main-layout">
        <PageHeader
            style={{ backgroundColor: "#FFFFFF" }}
            title="Tulkojumi"
            onBack={history.goBack}
        />
        <Card style={{ margin: 10 }} title="Studentu portāls">
            <TranslationsForm />
        </Card>
    </Layout>
}