import { Button, Card, Col, Input, Layout, message, PageHeader, Row, Select, Table, Tag } from "antd";
import { GetRowKey } from "antd/lib/table/interface";
import { AxiosResponse } from "axios";
import { ColumnsType } from "rc-table/lib/interface";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../helpers/http";
import { SchoolForm } from "./SchoolForm";

export interface School {
    id?: number,
    name: string | undefined;
    reg_nr: string | undefined;
    active: 1 | 0 | undefined;
    allow_school_study: 1 | 0;
    [propName: string]: any;
};

export interface Admin {
    id?: number;
    school_id?: number;
    first_name: string | undefined;
    last_name: string | undefined;
    email: string | undefined;
    phone: string | undefined;
    ruler_of_universe: 1 | 0;
    new_school_user: 1 | 0;
}

export const SchoolsPage = () => {
    const history = useHistory();
    const [schools, setSchools] = useState<[School] | []>([]);
    const [admin, setAdmin] = useState<[Admin]>([
        {
            first_name: undefined,
            last_name: undefined,
            email: undefined,
            phone: undefined,
            ruler_of_universe: 1,
            new_school_user: 1,
        }
    ]);
    const [showAddSchoolForm, setShowAddSchoolForm] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState<School>({
        name: undefined,
        reg_nr: undefined,
        allow_school_study: 0,
        active: 0
    });
    const [editSchool, setEditSchool] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchSchools = (params: { all: 1 } | "" = { all: 1 }) => {
        setLoading(true);
        http.get(`rest/schools`, { params }).then((res: AxiosResponse) => {
            console.log(res)
            setSchools(res.data.data);
            setLoading(false);
        }).catch(() => {
            message.error("Kļūda!");
            setLoading(false);
        })
    };

    const showAddSchoolFormHandler = (show: boolean) => {
        if (show) {
            setEditSchool(false);
            setSelectedSchool({
                name: undefined,
                reg_nr: undefined,
                active: 0,
                allow_school_study: 0
            })
            setAdmin([
                {
                    first_name: undefined,
                    last_name: undefined,
                    email: undefined,
                    phone: undefined,
                    ruler_of_universe: 1,
                    new_school_user: 1
                }
            ])
        }
        setShowAddSchoolForm(show);
    };

    const showEditSchoolFormHandler = (id: number) => {
        setEditSchool(true);
        setShowAddSchoolForm(true);
        const schoolAdmin = selectedSchool.admin.length > 0
            ? [
                {
                    id: selectedSchool.admin[0].id,
                    first_name: selectedSchool.admin[0].first_name,
                    last_name: selectedSchool.admin[0].last_name,
                    email: selectedSchool.admin[0].email,
                    phone: selectedSchool.admin[0].phone,
                    ruler_of_universe: 1,
                    new_school_user: 1,
                    school_id: selectedSchool.id
                }
            ] as [Admin]
            : [
                {
                    first_name: undefined,
                    last_name: undefined,
                    email: undefined,
                    phone: undefined,
                    ruler_of_universe: 1,
                    new_school_user: 1,
                    school_id: selectedSchool.id
                }
            ] as [Admin]
        setAdmin(schoolAdmin)
    }

    useEffect(() => {
        fetchSchools();
    }, [])

    useEffect(() => {
        if (selectedSchool.hasOwnProperty("id")) {
            showEditSchoolFormHandler(selectedSchool.id ?? 0)
        }
    }, [selectedSchool]);

    useEffect(() => {
        console.log(admin)
    }, [admin])

    const columns: ColumnsType<School> = [
        {
            title: "Statuss",
            dataIndex: "active",
            key: "active",
            width: "100px",
            render: (status: School['active']) => {
                console.log(status)
                return <Tag color={!!status ? "green" : "red"}>
                    {
                        !!status
                            ? "Aktīva"
                            : "Neaktīva"
                    }
                </Tag>
            }
        },
        {
            title: "Skola",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Administrātors",
            dataIndex: "admin",
            key: "admin",
            render: (nothing, record: any) => {
                return record.admin.length > 0 ? "Ir" : "Nav"
            }
        },
        {
            title: "E-pasts",
            dataIndex: "",
            key: "e-mail",
            render: (nothing, record: any) => {
                return record.admin.length > 0
                    ? record.admin[0].email
                    : <></>
            }
        },
        {
            title: "Tālruņa nr.",
            dataIndex: "",
            key: "phone",
            render: (nothing, record: any) => {
                return record.admin.length > 0
                    ? record.admin[0].phone
                    : <></>
            }
        },
        {
            dataIndex: "admin",
            render: (nothing, record: any) => {
                const token = record.admin.length > 0
                    ? record.admin[0].api_token
                    : undefined
                if (token === undefined) return <></>
                return <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        window.open(`${process.env.REACT_APP_EDRIVE_ADMIN_URL}?bt=${token}`)
                    }}
                    style={{ marginLeft: 40 }}
                >
                    Ielogoties
                </Button>
            }
        }
    ];

    return <Layout>
        <PageHeader
            title="Skolas"
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF" }}
        />
        <Card>
            {
                showAddSchoolForm
                && <SchoolForm
                    school={selectedSchool}
                    admin={admin}
                    setAdmin={(data: [Admin]) => setAdmin(data)}
                    setSchool={(data: School) => setSelectedSchool(data)}
                    edit={editSchool}
                    refreshSchools={fetchSchools}
                />
            }
            <div
                style={{ padding: "10px 0" }}
            >
                <Button
                    type="primary"
                    onClick={() => showAddSchoolFormHandler(!showAddSchoolForm)}
                >
                    {
                        showAddSchoolForm
                            ? "Aizvērt"
                            : "Pievienot"
                    }
                </Button>
            </div>
            <Table
                rowKey={(record: any) => record.id}
                columns={columns}
                dataSource={schools}
                loading={loading}
                onRow={(record: School) => {
                    return {
                        onClick: (event) => {
                            console.log(record)
                            setSelectedSchool(record)
                        }
                    };
                }}
            />
        </Card>
    </Layout>
}