import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const StatisticsTable = (props: any) => {
    const [cities, setCities] = useState([] as any);

    const citiesColumns = [
        {
            dataIndex: "city"
        }
    ]

    const expandedRowRender = (record: any) => {
        const modulesData = props.data?.[record.city]
        const modulesColumns = Object.keys(props?.moduleNames)?.map((key: any) => {
            return {
                title: props?.moduleNames[key],
                dataIndex: "theory_lessons",
                render: (theory_lessons: any, group: any) => {
                    const lesson = theory_lessons.find((tl: any) => {
                        return tl.name === props?.moduleNames[key]
                    })
                    const isFull = Number(lesson?.taken_free_stats.split("/")[0]) === Number(lesson?.taken_free_stats.split("/")[1])
                    return lesson === undefined
                        ? <></>
                        : <div>
                            <Link style={{ display: "block" }} to={`/groups/edit/${group?.id}`}>
                                <Button type="link">
                                    {group?.csdd_nr}
                                </Button>
                            </Link>
                            <b style={{ color: isFull ? "red" : "" }}>
                                {lesson?.lesson_date} ({lesson?.taken_free_stats})
                            </b>
                            &nbsp;{lesson?.lecturer}
                        </div>
                }
            }
        })

        return <div style={{ width: "100%" }}>
            <Table
                rowKey={record => { return record.id }}
                columns={modulesColumns}
                dataSource={modulesData}
                pagination={false}
            />
        </div>
    }

    useEffect(() => {
        setCities(
            Object.keys(props.data).map((city: any) => {
                return { city }
            })
        )
    }, [props.data]);

    useEffect(() => {
        console.log(props)
    }, [props])

    return cities.length > 0
        ? <Table
            rowKey={record => record.city}
            columns={citiesColumns}
            dataSource={cities}
            expandable={{
                expandedRowRender,
                defaultExpandAllRows: true
            }}
            pagination={false}
            loading={props.loading}
        />
        : <></>
}