import { Button, Input, message } from "antd";
import React, { useState } from "react";
import { http } from "../../../helpers/http";

export const QuestionAnswerCard = ({ ...props }) => {
    const [answer, setAnswer] = useState("");

    const sendAnswer = () => {
        http.post(`rest/elearning-chapter-question-answer/${props.question.id}`, { answer }).then(() => {
            props.setAnswer(answer);
        }).catch(() => {
            message.error("Kļūda!");
        });
    }

    return <div>
        <b>Atbilde:</b>
        {
            props.question.answer
                ? <p>{props.question.answer}</p>
                : <div><Input.TextArea
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    style={{ marginBottom: 10 }}
                />
                    <Button
                        type="primary"
                        onClick={() => {
                            if (answer.length === 0) return;
                            sendAnswer();
                        }}
                    >
                        Atbildēt
                    </Button>
                </div>
        }
    </div>
}