import { Button, Calendar, Card, DatePicker, Layout, PageHeader, Row, Space, Table, Tabs } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ExamCalendar } from "./Planner/ExamCalendar";
import { TheoryExamSettings } from "./Settings/TheoryExamSettings";
import { DayOverview } from "./Planner/DayOverview"
import { http } from "../../helpers/http";
import { QuestionsLayout } from "./Questions/QuestionsLayout";

export const TheoryExams = (props: any) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [selectedDay, setSelectedDay] = useState(moment() as any);
    const [plannerForDay, setPlannerForDay] = useState(false);
    const [examSlots, setExamSlots] = useState([] as any);
    const [rawSlots, setRawSlots] = useState([] as any);
    const [languages, setLanguages] = useState([] as any);
    const [categories, setCategories] = useState([] as any);

    const fetchExams = async (date: any = moment()) => {
        setLoading(true);
        let freeSlots = [] as any;
        let takenSlots = [] as any;
        let badSlots = [] as any;
        await http.get(`rest/get-theory-exam-slots`, {
            params: {
                year: date.format("YYYY"),
                month: date.format("M")
            }
        }).then((response: any) => {
            const { free_slots, taken_slots, bad_slots } = response.data.data;
            freeSlots = free_slots;
            takenSlots = taken_slots;
            badSlots = bad_slots;
        });
        setLoading(false);
        const groupedSlots = [...freeSlots, ...takenSlots, ...badSlots];
        setRawSlots(groupedSlots);
        return true;
    };

    const fetchLanguages = () => {
        http.get(`rest/languages`).then((response: any) => {
            setLanguages(response.data.data)
        })
    };

    const fetchCategories = () => {
        http.get(`rest/categories`).then((response: any) => {
            setCategories(response.data.data)
        })
    };

    const updateSlots = () => {
        // console.log(slot)
        fetchExams(selectedDay);
        // setRawSlots((prev: any) => {
        //     return prev.map((examSlot: any) => {
        //         if (examSlot.id === slot.id) {
        //             return slot;
        //         } else return examSlot
        //     })
        // })
    };

    const selectDay = (day: any) => {
        if (!day.isSame(selectedDay, "month")) {
            fetchExams(day)
        }
        setSelectedDay(day);
    }

    const mergeSlots = (groupedSlots: any) => {
        const mergedSlots = new Array;
        groupedSlots?.forEach((slot: any) => {
            const timeSlotInd = mergedSlots.findIndex((el: any) => {
                return el.exam_date === slot.exam_date
                    && el.time_from === slot.time_from
                    && el.time_to === slot.time_to
            })
            if (timeSlotInd >= 0) {
                mergedSlots[timeSlotInd].student_slots.push(slot);
            } else {
                mergedSlots.push({
                    exam_date: slot.exam_date,
                    time_from: slot.time_from,
                    time_to: slot.time_to,
                    student_slots: [
                        slot
                    ]
                })
            }
        })
        const sortedByDateTimeSlots = mergedSlots.sort((a: any, b: any) => {
            const timeDateA = moment(`${a.exam_date} ${a.time_from}`, "YYYY-MM-DD HH:mm:ss");
            const timeDateB = moment(`${b.exam_date} ${b.time_from}`, "YYYY-MM-DD HH:mm:ss");
            return timeDateA.isBefore(timeDateB) ? -1 : 1
        });
        const withStudentsFirst = sortedByDateTimeSlots.map((slot: any) => {
            const studentsFirst = slot.student_slots.sort((a: any, b: any) => {
                return a.student_id === null ? 1 : -1
            })
            return {
                ...slot,
                student_slots: studentsFirst
            }
        });
        setExamSlots(withStudentsFirst);
    };

    useEffect(() => {
        fetchExams();
        fetchLanguages();
        fetchCategories();
    }, []);

    useEffect(() => {
        mergeSlots(rawSlots);
    }, [rawSlots]);

    return <Layout>
        <PageHeader
            title="Eksāmeni"
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF" }}
        />
        <Tabs className="theory-exam-tabs">
            <Tabs.TabPane key={1} tab="Teorijas eksāmeni">
                <Card
                    // loading={loading}
                    title={<Row justify="space-between">
                        <Space>
                            <h3>Teorijas eksāmenu pieņemšanas grafiks</h3>
                            <Button
                                type="text"
                                onClick={() => fetchExams(selectedDay)}
                                style={{ height: "unset", width: "unset", padding: "5px 5px 0" }}
                                icon={<ReloadOutlined style={{ fontSize: 24 }} spin={loading} />}
                            />
                        </Space>
                        <div>
                            {
                                plannerForDay
                                    ? <></>
                                    : <DatePicker
                                        value={selectedDay}
                                        onChange={(e) => {
                                            if (e === null) return;
                                            selectDay(e)
                                        }}
                                        format="DD.MM.YYYY"
                                    />
                            }
                            <Button
                                type="primary"
                                style={{ marginLeft: 10 }}
                                onClick={() => setPlannerForDay(!plannerForDay)}
                            >
                                {
                                    plannerForDay
                                        ? "Atgriezties uz kalendāru"
                                        : "Atvērt dienas plānu"
                                }
                            </Button>
                        </div>
                    </Row>}
                    style={{ margin: "8px 24px 24px" }}
                >
                    {
                        plannerForDay
                            ? <DayOverview
                                examSlots={examSlots}
                                setSelectedDay={(date: any) => selectDay(date)}
                                selectedDay={selectedDay}
                                updateSlots={() => fetchExams(selectedDay)}
                                loading={loading}
                                languages={languages}
                                categories={categories}
                            />
                            : <ExamCalendar
                                setSelectedDay={(date: any) => selectDay(date)}
                                selectedDay={selectedDay}
                                examSlots={examSlots}
                                updateSlots={() => fetchExams(selectedDay)}
                                loading={loading}
                                setPlannerForDay={(state: boolean) => setPlannerForDay(state)}
                                languages={languages}
                                categories={categories}
                            />
                    }

                </Card>
            </Tabs.TabPane>
            <Tabs.TabPane key={2} tab="Uzstādījumi">
                <Card
                    title="Uzstādījumi"
                    style={{ margin: "8px 24px 24px" }}
                >
                    <TheoryExamSettings />
                </Card>
            </Tabs.TabPane>
            <Tabs.TabPane key={3} tab="Jautājumi">
                <QuestionsLayout />
            </Tabs.TabPane>
        </Tabs>
    </Layout>
}