import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Row, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
import { CheckCircleTwoTone, CheckOutlined } from "@ant-design/icons";
import moment from "moment";
import { set } from "lodash";

const { Title, Text } = Typography;

const status = {
    exam_passed: "Nokārtots",
    exam_failed: "Nav nokārtots",
} as any;

export const Checkpoint = (props: any) => {
    const { checkpoint, done, changeDate, actual } = props;
    const [theoryExam, setTheoryExam] = useState(null as any);

    const setCompleted = (status: boolean) => {
        done(checkpoint, status);
    }

    const setCPDate = (value: any) => {
        changeDate(checkpoint, moment(value).format("YYYY-MM-DD"))
    }

    const setActual = (status: boolean) => {
        actual(checkpoint, status);
    }

    useEffect(() => {
        setTheoryExam(props.studentGroup?.has_theory_exam[props.studentGroup?.has_theory_exam?.length - 1])
    }, [props.studentGroup])

    return (
        <Card className="check-item">
            <Row>
                <Col span={24}>
                    {checkpoint.passed
                        ? <>
                            <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 34 }} />
                            <Text style={{ marginLeft: 20 }}>{checkpoint.value_lv}</Text>
                            {/* <DatePicker
            onChange={setCPDate}
            style={{ marginLeft: 20 }}
            value={}
          /> */}
                            <Text style={{ marginLeft: 20 }}>{checkpoint.pass_date === undefined ? "Datums nav norādīts" : moment(checkpoint.pass_date).format("DD.MM.YYYY")}</Text>
                            <Button style={{ marginLeft: 20 }} onClick={() => setCompleted(false)}>Labot</Button>
                        </>
                        : checkpoint.hasOwnProperty('pivot') ? (<>
                            <Checkbox onChange={() => setCompleted(true)} />
                            <Text style={{ margin: "0 20px" }}>{checkpoint.value_lv}</Text>
                            <DatePicker defaultValue={checkpoint.pass_date === undefined ? undefined : moment(checkpoint.pass_date, "YYYY-MM-DD")} onChange={setCPDate} style={{ marginRight: 20 }} />
                            <Button onClick={() => setActual(false)}>Nav</Button>
                        </>)
                            : (<>
                                <Text style={{ marginRight: 20, color: "grey" }}>{checkpoint.value_lv}</Text>
                                <Text strong>NAV</Text>
                                <Button style={{ marginLeft: 20 }} onClick={() => setActual(true)}>Ir</Button>
                            </>)}
                </Col>
                <Col>
                    {
                        !!checkpoint.is_school_theory_exam
                        && <div style={{ width: "100%" }}>
                            <b>Pieteikts: {
                                theoryExam
                                    ? `${moment(theoryExam?.exam_date).format("DD.MM.YYYY")} ${theoryExam?.time_from} ${theoryExam?.status === "exam_passed" || theoryExam?.status === "exam_failed" ? `, Status: ${status[theoryExam.status]}` : ""}`
                                    : "NAV"
                            }
                            </b>
                        </div>
                    }
                    {
                        !!checkpoint.is_school_theory_exam
                        && props.studentGroup?.has_theory_exam?.reverse()?.map((exam: any, index: any, self: any) => {
                            if (index === 0) return;
                            return <div style={{ width: "100%" }} key={index}>
                                <b>
                                    {
                                        `${moment(exam?.exam_date).format("DD.MM.YYYY")} ${exam?.time_from} ${exam?.status === "exam_passed" || exam?.status === "exam_failed" ? `, Status: ${status[exam?.status]}` : ""}`
                                    }
                                </b>
                            </div>
                        })
                    }
                </Col>
            </Row>
        </Card>
    )
}