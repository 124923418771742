export const banner = `<!DOCTYPE html>
<html lang="lv">
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta charset="utf-8" />
    <style>
      @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
      .banner .main-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .banner .img-container {
        width: 100%;
        overflow: hidden;
      }
      .banner .img-container img {
        width: 100%;
      }
      .banner .content {
        width: 100%;
      }
      @media screen and (min-width: 728px) {
        .banner .img-container {
            width: 45%;
            border-radius: 8px;
        }
        .banner .img-container img {
            height: 100%;
            max-height: 400px;
            width: auto;
            border-radius: 8px;
        }
        .banner .content {
          width: 55%;
        }
      }
      .banner .content-wrapper {
        padding: 20px 24px 24px;
      }
      .banner h1 {
        margin: 0;
        margin-bottom: 8px;
        font-size: 21px;
        line-height: 28px;
        letter-spacing: -0.4px;
      }
      .banner h2 {
        margin: 0;
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.2px;
      }
      .banner p {
        margin: 0;
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.2px;
        color: #5c5f70;
      }
      .banner ul {
        margin: 0;
        margin-bottom: 8px;
        padding: 0;
        list-style-type: none;
      }
      .banner li {
        min-height: 23px;
        align-items: center;
        margin-bottom: 4px;
        margin-left: 27px;
        position: relative;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.2px;
        font-weight: 600;
      }
      .banner li:before {
        content: ' ';
        width: 20px;
        height: 20px;
        position: absolute;
        top: 2px;
        left: -27px;
        /* background-color: red; */
        background-image: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM15.7071 7.70711C16.0976 7.31658 16.0976 6.68342 15.7071 6.29289C15.3166 5.90237 14.6834 5.90237 14.2929 6.29289L9 11.5858L7.70711 10.2929C7.31658 9.90237 6.68342 9.90237 6.29289 10.2929C5.90237 10.6834 5.90237 11.3166 6.29289 11.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.7071 7.70711Z' fill='%2329A370'></path></svg>");
      }
    </style>
  </head>
  <div class="banner">
    <div class="main-container">
      <div class="img-container">
        <img src="banner-img" alt="Banner" />
      </div>
      <div class="content">
        <div class="content-wrapper">
          {CONTENT}
        </div>
      </div>
    </div>
  </div>
</html>
`;