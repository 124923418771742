import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, InputNumber, Modal, Table, Tag, Typography, Select, Row, Tooltip, Input, Col, message } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from "uuid";
import { withRouter } from "react-router";
import { http } from "../../helpers/http";
import moment from "moment";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { AxiosError } from "axios";

const { Text } = Typography;
const { confirm } = Modal;

const SettingsModal = (props: any) => {
    const {
        visible,
        setVisible,
        studentService,
        serviceGroups,
        omniva,
        group,
        reload,
        extraPMP,
        refreshStudent
    } = props;
    const [selectedGroup, setSelectedGroup] = useState([] as any)
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState("");
    const [terminals, setTerminals] = useState([]);
    const [terminal, setTerminal] = useState("");
    // const [group, setGroup] = useState("");
    const [rowKey, setRowKey] = useState([] as any);
    const [error, setError] = useState("")

    const groupColumns = [
        {
            title: "Nosaukums",
            dataIndex: "name",
            key: "name",
            fixed: 'left',
            render: (name: any, record: any) => {
                return <>
                    <span style={{ marginRight: 8 }}>{name}</span>
                    {
                        record.comment
                        && <Tooltip placement="top" title={record.comment}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    }
                </>
            },
            ...getColumnSearchProps()
        },
        {
            title: "Teorētiskās nodarbības datums",
            dataIndex: "online_date",
            key: "online_date",
            sorter: true,
            ...getColumnSearchProps('calendar')
        },
        {
            title: "Teorētiskās nodarbības sākums",
            dataIndex: "online_from",
            key: "online_from"
        },
        {
            title: "Praktiskās nodarbības datums",
            dataIndex: "medical_date",
            key: "medical_date",
            sorter: true,
            ...getColumnSearchProps('calendar')
        },
        {
            title: "Praktiskās nodarbības sākums",
            dataIndex: "medical_from",
            key: "medical_from"
        },
    ] as any;

    const fetchCities = async () => {
        await http.get("/rest/omniva-terminals/get-cities/LV").then((response) => {
            console.log(response)
            setCities(response.data);
        });
    }

    const fetchTerminals = async (city: string) => {
        await http.get(`/rest/omniva-terminals/get-address/${city}`).then((response) => {
            console.log(response);
            setTerminals(response.data);
        });
    }

    useEffect(() => {
        if (omniva && visible) {
            fetchCities();
        }
        if (!omniva && visible) {
            // fetchMedicalGroup();
        }
    }, [visible])

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedGroup(selectedRows);
            setRowKey(selectedRowKeys);
        },
        getCheckboxProps: (record: any) => ({
            name: record.name,
        }),
        selectedRowKeys: rowKey,
        hideSelectAll: true,
    };

    const resetModal = () => {
        setVisible(false);
        setCities([]);
        setCity("");
        setTerminal("");
        setTerminals([]);
        setSelectedGroup([]);
        setRowKey([]);
    }

    const saveHandler = () => {
        saveGroup();
        // resetModal();
    }

    const saveGroup = async () => {
        !omniva
            ? selectedGroup.length > 0 ? await http.post(`/rest/medical-groups-attach/${studentService.pivot.student_id}/${studentService.pivot.study_group_id}`, { medical_group: selectedGroup[0].id, main: extraPMP ? 0 : 1 }).then((response) => {
                console.log(response)
                if (extraPMP) {
                    http.post(`rest/attach-extra-pmp-to-group/${extraPMP.id}`, { group_relation_id: selectedGroup[0].id }).then((response: any) => {
                        refreshStudent();
                    })
                }
                reload();
            }).catch((err: AxiosError) => {
                errorMessageResolver(err);
                console.log(err.response?.data?.msg)
                const { msg } = err.response?.data;
                message.error("Kļūda! " + msg)
            })
                : console.log()
            : props.saveOmniva(terminals.find((el: any) => el.name === terminal));
        resetModal();
    }

    const errorMessageResolver = (err: any) => {
        const { data, status } = err.response;
        if (status === 403) {
            if (data?.length > 0) {
                const errorType = { time_taken: "Izvēlētais laiks ir aizņemts!" } as any;
                const activities = {
                    driving_lesson: "Braukšanas nodarbība",
                    theory_lesson: "Teorijas nodarbība",
                    pmp_lesson: "Pirmās palīdzības nodarbība",
                    theory_exam: "Teorijas eksāmens"
                } as any;
                setError(`${errorType[data[0].message_type]} ${data[0].info} ${activities[data[0].reason]}`);
            }
        } else {
            setError("")
        }
    }

    return (
        <Modal
            visible={visible}
            onCancel={resetModal}
            onOk={saveHandler}
            title={!omniva ? (
                <Text>
                    Grupas izvēle: {group}
                </Text>
            ) : <>Omniva</>}
            width={800}
        >
            {
                error.length > 0
                && <Tag color="red" style={{ marginBottom: 10, whiteSpace: "normal" }}>
                    {error}
                </Tag>
            }
            {!omniva ? (
                <Table
                    rowKey={(record: any) => record.id}
                    scroll={{ x: 720 }}
                    dataSource={serviceGroups}
                    columns={groupColumns}
                    onRow={(record: any, index: any) => {
                        return {
                            onClick: (event: any) => {
                                console.log(event.target)

                            }
                        }
                    }}
                    rowSelection={
                        {
                            type: "radio",
                            ...rowSelection
                        }
                    }
                    pagination={{ ...props.pagination }}
                    onChange={props.tableChange}
                />
            ) : <>
                <Text strong>Pilsēta</Text><br />
                <Select showSearch value={city} style={{ width: 200, marginTop: 5 }} onChange={(value: any) => { fetchTerminals(value); setCity(value) }}>
                    {cities.map((city: any) => {
                        if (city.city === "") return <Select.Option key={city.county} value={city.county}>{city.county}</Select.Option>
                        return <Select.Option key={city.city} value={city.city}>{city.city}</Select.Option>
                    })}
                </Select>
                {terminals.length > 0
                    ? <div style={{ marginTop: 20 }}>
                        <Text strong>Pakomāts</Text><br />
                        <Select
                            showSearch
                            value={terminal}
                            style={{ width: "100%", marginTop: 5 }}
                            onChange={(value: any) => setTerminal(value)}
                        >
                            {terminals.map((terminal: any) => {
                                return <Select.Option key={terminal.id} value={terminal.name}>{terminal.name}</Select.Option>
                            })}
                        </Select>
                    </div>
                    : <></>
                }

            </>}
        </Modal>
    )
}

export const Service = withRouter((props: any) => {

    const {
        service,
        services,
        edit,
        add,
        remove,
        changeGroup,
        extraServices,
        refreshStudent,
        commonService } = props;

    const [modalVisible, setModalVisible] = useState(false);
    const [omniva, setOmniva] = useState(false);
    const [serviceGroups, setServiceGroups] = useState([] as any);
    const [terminal, setTerminal] = useState("");
    const [extraTerminals, setExtraTerminals] = useState([] as any);
    const [pmpGroup, setPmpGroup] = useState("");
    const [reasignValue, setReasignValue] = useState(0);
    const [reasignModalVisible, setReasignModalVisible] = useState(false);
    const [individualPrice, setIndividualPrice] = useState(null as any);
    const [extraPmp, setExtraPmp] = useState(undefined as any);
    const [relatedGroups, setRelatedGroups] = useState({} as any);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15,
        total: 0
    })

    const fetchGroups = async (params: any = {}) => {
        await http.get(`/rest/medical-groups`, {
            params: {
                page: params?.pagination?.current,
                sortField: params?.sortField,
                sortOrder: params?.sortOrder,
                filters: params?.filters,
                availableOnly: 1,
                fe: 1
            }
        }).then((response: any) => {
            const { meta, data } = response.data
            setServiceGroups(data);
            setPagination({
                current: meta.current_page,
                pageSize: meta.per_page,
                total: meta.total
            })
        })
    };

    const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
        console.log(pagination, filters, sorter)
        fetchGroups({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters
        })
    }

    const getServiceIndex = (fromList: any, fromStudent: any) => {
        return fromStudent.findIndex((item: any) => {
            return item.id === fromList.id;
        })
    }



    const studentService = services[getServiceIndex(service, services)];


    const settingsHandler = (omniva: boolean = false, extraPmp: any = undefined) => {
        setExtraPmp(extraPmp)
        setOmniva(omniva);
        setModalVisible(true);
    }

    const fetchTerminal = async () => {
        return http.get(`/rest/omniva-terminals/get-address-by-id/${studentService.pivot.omniva_terminal_id}`).then((response: any) => {
            setTerminal(`${response.data.county}, ${response.data.name}`);
        })
    }

    const fetchExtraTerminals = async () => {
        const extraOmniva = extraServices.filter((service: any) => {
            return !!service.omniva_terminal_id;
        })
        if (extraOmniva.length === 0) return;
        const terminals = await Promise.all(extraOmniva.map(async (service: any) => {
            return await http.get(`/rest/omniva-terminals/get-address-by-id/${service.omniva_terminal_id}`).then((response: any) => {
                return {
                    omniva_id: service.id,
                    terminal: `${response.data.county}, ${response.data.name}`
                };
            })
        }))
        setExtraTerminals(terminals.filter((terminal: any) => terminal !== undefined));
    }

    useEffect(() => {
        setIndividualPrice(services[getServiceIndex(service, services)]
            ? Number(services[getServiceIndex(service, services)].pivot.individual_price)
            : null)
        if (studentService?.pivot.omniva_terminal_id > 0) {
            fetchTerminal();
        }
        if (studentService?.show_medical_groups) {
            fetchGroups();
        }
    }, [services])

    useEffect(() => {
        if (studentService?.show_medical_groups === 1) {
            fetchMedicalGroup();
        }
        if (studentService?.pivot.omniva_terminal_id > 0) {
            fetchTerminal();
        }
    }, []);

    useEffect(() => {
        setReasignValue(Number(studentService?.price));
        console.log(extraServices)
    }, [studentService]);

    useEffect(() => {
        if (!!studentService?.show_omniva) {
            fetchExtraTerminals();
        }
    }, [extraServices])

    const updateServiceGroups = (newGroup: any, oldGroup: any) => {
        const copyOfGroups = serviceGroups.filter((group: any) => {
            return group.id !== oldGroup.id
        })
        copyOfGroups.push(newGroup);
        console.log(copyOfGroups);
        setServiceGroups(copyOfGroups);
    }

    const fetchMedicalGroup = async () => {
        await http.get(`/rest/get-student-medical/${studentService.pivot.student_id}/${studentService.pivot.study_group_id}`).then((response) => {
            const { medical_groups } = response.data.data;
            const { data } = response.data;
            setRelatedGroups(response.data.data)
            const mainRelation = data.relations.reverse().find((el: any) => el.main)
            const medicalGroup = medical_groups.length > 0 ? medical_groups.find((el: any) => el.id === mainRelation?.medical_group_id) : undefined
            setPmpGroup(medicalGroup ? medicalGroup.name + " " + medicalGroup.medical_date : "");
        });
    }

    const addService = () => {
        const toSend = {
            ...service,
            individual_price: individualPrice
        }
        add(toSend);
    }

    const removeService = () => {
        remove(studentService)
    }

    const unsignService = () => {
        confirm({
            title: 'Noraidīt pakalpojumu',
            icon: <ExclamationCircleOutlined />,
            content: <div>
                <b>Noraidīt pakalpojumu?</b><br />
            </div>,
            onOk() {
                removeService();
            },
            onCancel() { },
        });
    };

    const reasignCall = () => {
        if (!!studentService.show_medical_groups) {
            http.post('rest/create-extra-pmp', { amount: reasignValue, student_id: studentService.pivot.student_id, service_id: studentService.id }).then((response: any) => {
                setReasignModalVisible(false);
                refreshStudent();
            })
        } else if (!!studentService.show_omniva) {
            http.post('rest/create-extra-omniva', { amount: reasignValue, student_id: studentService.pivot.student_id, service_id: studentService.id }).then((response: any) => {
                setReasignModalVisible(false);
                refreshStudent();
            })
        } else {
            http.post('rest/create-extra-medical', { amount: reasignValue, student_id: studentService.pivot.student_id, service_id: studentService.id }).then((response: any) => {
                setReasignModalVisible(false);
                refreshStudent();
            })
        }
    }

    const deleteService = () => {
        confirm({
            title: 'Anulēt pakalpojumu',
            icon: <ExclamationCircleOutlined />,
            content: <div>
                <b>Anulēt pakalpojumu?</b><br />
            </div>,
            onOk() {
                removeService();
            },
            onCancel() { },
        });
    }

    const editService = (service: any) => {
        const copyService = { ...service }
        copyService.paid = 1;
        if (individualPrice > 0) {
            copyService.individual_price = individualPrice;
        }
        setIndividualPrice(0);
        edit(copyService);
    }

    const saveOmniva = (omniva: any) => {
        console.log(omniva);
        if (omniva === undefined) return;
        if (extraPmp !== undefined) {
            console.log(extraPmp, omniva.id)
            http.post(`rest/add-terminal-extra-omniva/${extraPmp.id}`, { terminal: omniva.id }).then((response: any) => {
                refreshStudent();
            })
            setExtraPmp(undefined)
            return
        }
        studentService.omniva_terminal_id = omniva.id;
        edit(studentService);
    }

    const customPriceHandler = (val: any) => {
        setIndividualPrice(val);
    }

    const cancelExtraMedical = (id: any) => {
        confirm({
            title: "Atcelt pakalpojumu",
            icon: <ExclamationCircleOutlined />,
            content: <p>Vai vēlaties atcelt pakalpojumu?</p>,
            okText: "Jā",
            cancelText: "Nē",
            onOk: () => {
                if (studentService?.show_medical_groups) {
                    http.post(`rest/cancel-extra-pmp/${id}`).then((response: any) => {
                        message.success("Pieteikums veiksmīgi dzēsts")
                        refreshStudent();
                    }).catch((err: any) => {
                        message.error("Kļūda");
                    })
                } else if (studentService?.show_omniva) {
                    http.post(`/rest/cancel-extra-omniva/${id}`).then((response: any) => {
                        message.success("Pieteikums veiksmīgi dzēsts")
                        refreshStudent();
                    }).catch((err: any) => {
                        message.error("Kļūda");
                    })
                } else {
                    http.post(`/rest/cancel-extra-medical/${id}`).then((response: any) => {
                        message.success("Pieteikums veiksmīgi dzēsts")
                        refreshStudent();
                    }).catch((err: any) => {
                        message.error("Kļūda");
                    })
                }
            }
        })
    }



    return (
        <li key={service.id} style={{ paddingBottom: 10, listStyle: "none" }}>
            <Card style={{ position: "relative" }}>
                <Text>{service.value_lv}</Text>
                {getServiceIndex(service, services) < 0
                    ? <>
                        <Button onClick={addService} style={{ paddingLeft: 10, marginLeft: 10 }}>Pieteikt</Button>
                        <span style={{ float: "right" }}>
                            <Text style={{}}>Skolas cena: <Text strong>{service.price}</Text> EUR</Text>
                            <Text style={{ margin: 10 }}>Individuāla cena: </Text>
                            <InputNumber onChange={(value: any) => customPriceHandler(value)} min={0} style={{ margin: 10 }} />
                            <Text>EUR</Text>
                        </span>
                    </>
                    : <>
                        {studentService.pivot.delivered
                            ? <Tag style={{ marginLeft: 10 }} color="cyan">Piegādāts</Tag>
                            : studentService.pivot.paid ? <></> : <Button onClick={unsignService} style={{ marginLeft: 10 }}>Noraidīt</Button>}
                        <Tag style={{ marginLeft: 10 }} color="green">Pieteikts</Tag>
                        {studentService.pivot.from_web
                            ? <Tag style={{ marginLeft: 10 }} color="cyan">WEB</Tag>
                            : <></>}
                        {studentService.show_medical_groups && extraServices?.length === 0
                            ? <Button onClick={() => settingsHandler()} style={{ margin: 10 }}>Grupa</Button>
                            : <></>}
                        {studentService.show_omniva && !studentService.pivot.delivered && extraServices?.length === 0
                            ? <Button onClick={() => settingsHandler(true)} style={{ margin: 10 }}>Piegāde</Button>
                            : <></>}
                        {studentService.paid
                            ? <>
                                <Tag style={{ marginLeft: 10 }} color="success">Apmaksāts</Tag>
                                <span style={{ float: "right" }}>
                                    <Text style={{}}>Skolas cena: <Text strong>{service.price}</Text> EUR</Text>
                                    <Text style={{ margin: 10 }}>
                                        Individuāla cena:
                                        {individualPrice > 0
                                            ? <Text strong> {studentService.pivot.individual_price} </Text>
                                            : <Text strong> {service.price} </Text>}
                                        EUR
                                    </Text>
                                </span>
                                {
                                    (studentService.show_omniva || studentService.medical_check || studentService.show_medical_groups) && (extraServices?.length === 0 || !!extraServices[extraServices.length - 1]?.paid)
                                        ? <Button onClick={() => setReasignModalVisible(true)}>Pieteikt atkārtoti</Button>
                                        : <></>
                                }
                                {
                                    extraServices.length > 0
                                        ? <></>
                                        : <Button onClick={deleteService} style={{ marginLeft: 10 }}>Anulēt</Button>
                                }
                            </>
                            : <>
                                <Tag color="red" >Nav apmaksāts</Tag>
                                <span style={{ float: "right" }}>
                                    <Text style={{}}>Skolas cena: <Text strong>{service.price}</Text> EUR</Text>
                                    {/* <Text style={{ margin: 10 }}>Individuāla cena: </Text> */}
                                    {/* <InputNumber onChange={(value: any) => customPriceHandler(value)} min={0} style={{ margin: 10 }} /> */}
                                    {
                                        studentService.pivot.individual_price !== null
                                            ? <Text style={{ margin: 10 }}>Individuāla cena: <Text strong>{studentService.pivot.individual_price}</Text> EUR</Text>
                                            : <></>
                                    }
                                    {/* <Text>EUR</Text> */}
                                </span>
                            </>}
                        {studentService.pivot.omniva_terminal_id > 0
                            ?
                            <Row>
                                Omniva: {terminal}
                            </Row>
                            : <></>
                        }
                        {studentService.show_medical_groups === 1
                            ?
                            <Row>
                                Grupa: {pmpGroup !== "" ? pmpGroup : "Grupa nav izvēlēta"}
                            </Row>
                            : <></>
                        }
                    </>}
                {/* <Button onClick={() => console.log(getServiceIndex(service, services))}>SPIED TE</Button> */}
                <Modal
                    visible={reasignModalVisible}
                    onCancel={() => setReasignModalVisible(false)}
                    onOk={() => reasignCall()}
                >
                    <Row>
                        <Col>
                            <b>Atkārtota pakalpojuma cena</b>
                            <Input
                                type="number"
                                value={reasignValue}
                                onChange={(e: any) => {
                                    e.persist();
                                    setReasignValue(e.target.value);
                                }}
                                min={0}
                                step={0.01}
                                style={{ width: 100, marginLeft: 10 }}
                            />
                        </Col>
                    </Row>
                </Modal>
                <SettingsModal
                    service={service}
                    studentService={services[getServiceIndex(service, services)]}
                    visible={modalVisible}
                    setVisible={setModalVisible}
                    serviceGroups={serviceGroups}
                    updateGroups={updateServiceGroups}
                    changeGroup={changeGroup}
                    add={add}
                    omniva={omniva}
                    saveOmniva={saveOmniva}
                    group={pmpGroup}
                    reload={() => fetchMedicalGroup()}
                    refreshStudent={() => refreshStudent()}
                    extraPMP={extraPmp || false}
                    pagination={pagination}
                    tableChange={tableChangeHandler}
                />
                {
                    commonService !== undefined
                    && <p><b>Pakalpojums ir pieteikts {!!commonService.paid ? "un apmaksāts" : ""} citā grupā</b></p>
                }
                {
                    extraServices.length > 0
                        ? <Card style={{ marginTop: 8 }}>
                            <b style={{ marginBottom: 8, display: "block" }}>Atkārtoti pieteiktie pakalpojumi</b>
                            {
                                extraServices.map((extraService: any, index: any) => {
                                    const group = relatedGroups?.medical_groups
                                        ?.find((el: any) => el?.id === relatedGroups?.relations
                                            ?.find((el: any) => el?.id === extraService?.medical_group_student_id)?.medical_group_id)
                                    return <Row key={extraService.id} gutter={16} style={{ borderBottom: "1px solid #DDDDDD", marginBottom: 8 }}>
                                        <Col>
                                            <p>Pieteikuma datums: <b>{moment(extraService?.created_at).format("DD.MM.YYYY")}</b></p>
                                        </Col>
                                        <Col>
                                            <p>Apmaksāts: <b>{extraService?.paid ? "Ir" : "Nav"}</b></p>
                                        </Col>
                                        {
                                            studentService.show_medical_groups && extraServices.length - 1 === index
                                                ? <Col>
                                                    <Button onClick={() => settingsHandler(false, extraService)}>Grupa</Button>
                                                </Col>
                                                : <></>
                                        }
                                        {
                                            studentService.show_omniva && extraServices.length - 1 === index
                                                ? <Button onClick={() => settingsHandler(true, extraService)} style={{ margin: "0 10px" }}>Piegāde</Button>
                                                : <></>
                                        }
                                        <p style={{ margin: 0 }}>
                                            {
                                                extraTerminals.find((el: any) => el.omniva_id === extraService.id)?.terminal || ""
                                            }
                                        </p>
                                        {
                                            extraService.medical_group_student_id
                                                ? <Col>
                                                    <p>Grupa: <b>{

                                                        group?.name + " " + group?.medical_date
                                                    }</b></p>
                                                </Col>
                                                : <></>
                                        }
                                        {
                                            extraServices.length - 1 === index
                                                ? <Col style={{ margin: "0 0 0 auto" }}>
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        onClick={() => cancelExtraMedical(extraService.id)}
                                                    >
                                                        Atcelt
                                                    </Button>
                                                </Col>
                                                : <></>
                                        }
                                    </Row>
                                })
                            }
                        </Card>
                        : <></>
                }
            </Card>
        </li>
    )
})