import { Button, Card, Col, DatePicker, Input, Modal, Row, Select, Space, Table, Tabs } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { http } from "../../../helpers/http";
import moment from "moment";
import { AxiosResponse } from "axios";

export const TransactionsDetails = withRouter((props: any) => {
    const { record, setSelectedTransaction } = props;
    const [type, setType] = useState("student");
    const [clientType, setClientType] = useState("student");
    const [filter, setFilter] = useState({
        date_from: null as string | null,
        date_to: null as string | null,
        amount: null as string | null
    });
    const [clientFilter, setClientFilter] = useState({
        first_name: null as string[] | null,
        last_name: null as string[] | null,
        email: null as string[] | null,
        phone: null as string[] | null
    });
    const [data, setData] = useState([] as any);
    const [clientData, setClientData] = useState([] as any);
    const [mergedRecord, setMergedRecord] = useState(null as any)
    const [merged, setMerged] = useState(false);
    const [loading, setLoading] = useState(false);
    const systemRecordTypes = {
        'student': "Students",
        'instructor': "Instruktors",
        'school': "Skola",
        'comission': "Komisija"
    };
    const clientTypes = {
        'student': "Students",
        'instructor': "Instruktors",
    };

    const searchRecords = () => {
        setLoading(true);
        let newFilters = {} as any;
        Object.keys(filter).filter((key: string) => {
            return filter[key as keyof typeof filter]
        }).forEach((entry: string) => {
            newFilters = {
                ...newFilters,
                [entry]: filter[entry as keyof typeof filter]
            }
        })
        newFilters.type = type;
        console.log(newFilters)
        http.post(`/rest/find-free-student-transaction`, { ...newFilters }).then((res: AxiosResponse) => {
            console.log(res)
            if (type === "instructor") {
                setData(res.data.map((item: any) => {
                    return convertInstructorData(item)
                }))
            } else {
                setData(res.data)
            }
            // setPagination({
            //     total: res.data.total,
            //     page: Number(res.data.page)
            // })
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }

    const convertInstructorData = (data: any) => {
        return {
            amount: data?.total,
            payee: data?.instructor,
            created_at: data?.date,
            reason: data?.invoice_number,
            id: data?.id
        }
    }

    const fetchClients = () => {
        setLoading(true);
        let newFilters = {} as any;
        Object.keys(clientFilter).filter((key: string) => {
            return clientFilter[key as keyof typeof clientFilter]
        }).forEach((entry: string) => {
            newFilters = {
                ...newFilters,
                [entry]: clientFilter[entry as keyof typeof clientFilter]
            }
        })
        http.get(`/rest/` + (clientType === "student" ? `students` : `instructors`), { params: { filters: newFilters, no_paginate: 1 } })
            .then((res: AxiosResponse) => {
                setLoading(false);
                setClientData(processClientData(res.data.data))
            }).catch(() => {
                setLoading(false);
            })
    }

    const processClientData = (data: any[]) => {
        return data.map((item: any) => {
            return {
                id: item.id,
                payee: item.first_name + " " + item.last_name,
                phone_number: item.phone_number,
                email: item.email,
                first_name: item.first_name,
                last_name: item.last_name,
                merged_type: "client"
            }
        })
    }

    const mergeRecord = () => {
        Modal.confirm({
            title: "Piesiet ierakstus",
            content: "Apstiprinot, bankas ieraksts tiks piesiets sistēmas ierakstam!",
            onOk: () => {
                if (type === "instructor") {
                    http.get(`/rest/set-instructor-transaction-link/${record.payment_merger_id}/${mergedRecord.id}`).then((res: AxiosResponse) => {
                        props.refresh();
                    })
                } else {
                    http.get(`/rest/set-student-transaction-link/${record.payment_merger_id}/${mergedRecord.id}`).then((res: AxiosResponse) => {
                        props.refresh();
                    })
                }
            }
        })
    }

    const unMerge = () => {
        Modal.confirm({
            title: "Atsiet ierakstus",
            content: "Apstiprinot, bankas ieraksts tiks atsiets no sistēmas ieraksta!",
            onOk: () => {
                if (type === "instructor") {
                    http.get(`/rest/remove-instructor-transaction-link/${record.payment_merger_id}`).then((res: AxiosResponse) => {
                        props.refresh();
                    })
                } else {
                    http.get(`/rest/remove-student-transaction-link/${record.payment_merger_id}`).then((res: AxiosResponse) => {
                        props.refresh();
                    })
                }
            }
        })
    }

    const mergeWithClient = () => {
        setLoading(true);
        http.get(`/rest/set-${clientType}-link/${record.payment_merger_id}`, {
            params: {
                processed: 1,
                [clientType]: mergedRecord.id
            }
        }).then(() => {
            setLoading(false);
            props.refresh();
        }).catch(() => {
            setLoading(false);
        })
    }

    const unmergeClient = () => {
        setLoading(true);
        console.log(clientType)
        http.get(`/rest/set-${mergedRecord.type}-link/${record.payment_merger_id}`, {
            params: {
                processed: 0
            }
        }).then(() => {
            setLoading(false);
            props.refresh();
        }).catch(() => {
            setLoading(false);
        })
    }

    const columns = [
        {
            title: "Datums",
            dataIndex: "created_at",
            key: "created_at"
        },
        {
            title: "Maksātājs",
            dataIndex: "student",
            key: "student",
            render: (student: any, record: any) => {
                return student ? student : record?.payee
            }
        },
        {
            title: "Pamatojums",
            dataIndex: "reason",
            key: "reason"
        },
        {
            title: "Summa",
            dataIndex: "amount",
            key: "amount"
        },
        // {
        //     title: "Status",
        //     dataIndex: "status",
        //     key: "status"
        // }
    ] as any;

    const clientColumns = [
        {
            title: "Vārds",
            dataIndex: "first_name",
        },
        {
            title: "Uzvārds",
            dataIndex: "last_name",
        },
        {
            title: "E-pasts",
            dataIndex: "email",
        },
        {
            title: "Tālrunis",
            dataIndex: "phone_number",
        },

    ]

    const transactionTitle = <div>
        <Row gutter={24}>
            <Col>
                <Button
                    onClick={() => setSelectedTransaction(null)}
                >
                    <ArrowLeftOutlined />
                </Button>
            </Col>
            <Col>
                <b>Maksātājs:</b><br />
                {
                    Object.keys(record.merged_with).length > 0
                        ? record.merged_with.payee
                        : record.incoming_outgoing === 1
                            ? record.payee
                            : Object.keys(record.merged_with).length > 0
                                ? record.merged_with.money_user
                                : "Depozītkonts"
                }
            </Col>
            <Col>
                <b>Bankas pamatojums:</b><br />
                {record.klix_log_reason}
            </Col>
            <Col>
                <b>Maksājuma datums:</b><br />
                {record.paid_on}
            </Col>
            <Col>
                <b>Saņēmējs:</b><br />
                {
                    Object.keys(record.merged_with).length > 0
                        ? record.merged_with.money_user
                        : record.incoming_outgoing === 0
                            ? record.payee
                            : Object.keys(record.merged_with).length > 0
                                ? record.merged_with.money_user
                                : "Nav atrasts"
                }
            </Col>
            <Col>
                <b>Summa:</b><br />
                {record.klix_log_amount}
            </Col>
            <Col>
                <b>Status:</b><br />
                {
                    merged
                        ? <Space>
                            <b style={{ color: "green" }}>Piesiets</b>
                        </Space>
                        : <b style={{ color: "red" }}>Nav piesiets</b>
                }
            </Col>
        </Row>
    </div>

    const mergedWith = <div>
        <Row gutter={24} style={{ marginTop: 10, paddingTop: 10, border: "1px solid #F0F0F0", paddingLeft: 67 }}>
            <Col>
                <b>Maksātājs:</b><br />
                {mergedRecord?.student ?? mergedRecord?.money_user}
            </Col>
            <Col>
                <b>{mergedRecord?.merged_type === "client" ? "E-pasts" : "Sistēmas Pamatojums"}:</b><br />
                {mergedRecord?.reason ?? mergedRecord?.email}
            </Col>
            <Col>
                <b>{mergedRecord?.merged_type === "client" ? "Tālrunis" : "Maksājuma datums"}:</b><br />
                {mergedRecord?.created_at ?? mergedRecord?.phone_number}
            </Col>
            {
                !(mergedRecord?.merged_type === "client")
                && <Col>
                    <b>Summa:</b><br />
                    {Number(mergedRecord?.amount)?.toFixed(2)}
                </Col>
            }
            <Col>
                {
                    merged
                        ? <Button
                            onClick={() => mergedRecord?.merged_type === "client" ? unmergeClient() : unMerge()}
                        >
                            Atsiet
                        </Button>
                        : mergedRecord
                            ? <Button
                                onClick={() => mergedRecord?.merged_type === "client" ? mergeWithClient() : mergeRecord()}
                            >
                                Piesiet
                            </Button>
                            : <></>
                }

            </Col>
        </Row>
    </div>

    useEffect(() => {
        console.log(clientType)
    }, [clientType]);

    useEffect(() => {
        setMerged(!!record.processed)
        if (record) {
            setFilter((prev: any) => {
                return {
                    ...prev,
                    amount: String(Math.abs(record.klix_log_amount).toFixed(2))
                }
            })
            if (Object.keys(record.merged_with).length > 0 && !!record.processed) {
                setClientType(record.merged_with.type)
                setMergedRecord(record.merged_with)
            } else {
                setMergedRecord(null)
            }
        }
    }, [record])

    return <Card
        title={<>
            {transactionTitle}
            {
                mergedRecord
                && mergedWith
            }
        </>}
        style={{ margin: "20px 0" }}
    >
        {
            merged
                ? <></>
                : <Tabs>
                    <Tabs.TabPane tab="Māksājumi" key="1">
                        <h3>Piesiet maksājuma ierakstam:</h3>
                        <Row style={{ marginBottom: 30 }}>
                            <Col>
                                <b>Periods:</b><br />
                                <DatePicker.RangePicker
                                    style={{ marginRight: 20, marginTop: 8 }}
                                    value={filter.date_from ? [moment(filter.date_from, "YYYY-MM-DD"), moment(filter.date_to, "YYYY-MM-DD")] : undefined}
                                    onChange={(e) => {
                                        setFilter((prev: typeof filter) => {
                                            return {
                                                ...prev,
                                                date_from: e ? moment(e[0]).format("YYYY-MM-DD") : null,
                                                date_to: e ? moment(e[1]).format("YYYY-MM-DD") : null,
                                            }
                                        })
                                    }}
                                />
                            </Col>
                            <Col>
                                <b>Summa:</b><br />
                                <Input
                                    type="number"
                                    style={{ width: 200, marginRight: 20, marginTop: 8 }}
                                    value={filter.amount === null ? "" : filter.amount}
                                    step={0.01}
                                    onChange={(e) => {
                                        e.persist();
                                        setFilter((prev: typeof filter) => {
                                            return {
                                                ...prev,
                                                amount: e.target.value.length === 0 ? null : e.target.value
                                            }
                                        })
                                    }}
                                />
                            </Col>
                            <Col>
                                <b>Veids:</b><br />
                                <Select
                                    value={type}
                                    style={{ width: 200, marginRight: 24, marginTop: 8 }}
                                    onChange={(e: any) => {
                                        setType(e)
                                    }}
                                >
                                    {
                                        Object.keys(systemRecordTypes).map((key: any) => {
                                            return <Select.Option value={key} key={key}>
                                                {systemRecordTypes[key as keyof typeof systemRecordTypes]}
                                            </Select.Option>
                                        })
                                    }
                                </Select>
                            </Col>
                            {/* {
                            type === "student"
                                ? <Row gutter={16}>
                                    <Col>
                                        <b>Students:</b><br />
                                        <Input />
                                    </Col>
                                    <Col>
                                        <b>Epasts:</b><br />
                                        <Input />
                                    </Col>
                                </Row>
                                : type === "instructor"
                                    ? <Row gutter={16}>
                                        <Col>
                                            <b>Instruktors:</b><br />
                                            <Input />
                                        </Col>
                                        <Col>
                                            <b>Rēķina Nr.</b><br />
                                            <Input />
                                        </Col>
                                    </Row>
                                    : type === "school"
                                        ? <></>
                                        : type === "bank"
                                            ? <></>
                                            : <></>
                        } */}
                            <Col style={{ display: "flex", flexDirection: "column-reverse", marginLeft: 20 }}>
                                <Button
                                    onClick={searchRecords}
                                >
                                    Atlasīt
                                </Button>
                            </Col>
                        </Row>
                        <Table
                            rowKey={record => record.id}
                            columns={columns}
                            dataSource={data}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        setMergedRecord(record)
                                    },
                                };
                            }}
                            loading={loading}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Klienti" key="2">
                        <h3>Piesiet klientam:</h3>
                        <Row style={{ marginBottom: 30 }}>
                            <Col>
                                <b>Klienta veids:</b><br />
                                <Select
                                    value={clientType}
                                    style={{ width: 200, marginRight: 24, marginTop: 8 }}
                                    onChange={(e: any) => {
                                        setClientType(e)
                                    }}
                                >
                                    {
                                        Object.keys(clientTypes).map((key: any) => {
                                            return <Select.Option value={key} key={key}>
                                                {clientTypes[key as keyof typeof clientTypes]}
                                            </Select.Option>
                                        })
                                    }
                                </Select>
                            </Col>
                            <Row gutter={16}>
                                <Col>
                                    <b>{clientTypes[clientType as keyof typeof clientTypes]}:</b><br />
                                    <Input
                                        value={clientFilter.first_name ? clientFilter.first_name[0] : ""}
                                        placeholder={"Vārds"}
                                        style={{ marginTop: 8, width: 200 }}
                                        onChange={e => {
                                            e.persist();
                                            setClientFilter((prev: any) => {
                                                return {
                                                    ...prev,
                                                    first_name: e.target.value.length > 0 ? [e.target.value] : null
                                                }
                                            })
                                        }}
                                    />
                                    <Input
                                        value={clientFilter.last_name ? clientFilter.last_name[0] : ""}
                                        placeholder={"Uzvārds"}
                                        style={{ marginTop: 8, width: 200, marginLeft: 16 }}
                                        onChange={e => {
                                            e.persist();
                                            setClientFilter((prev: any) => {
                                                return {
                                                    ...prev,
                                                    last_name: e.target.value.length > 0 ? [e.target.value] : null
                                                }
                                            })
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <b>Epasts:</b><br />
                                    <Input
                                        value={clientFilter.email ? clientFilter.email[0] : ""}
                                        style={{ marginTop: 8, width: 200 }}
                                        onChange={e => {
                                            e.persist();
                                            setClientFilter((prev: any) => {
                                                return {
                                                    ...prev,
                                                    email: e.target.value.length > 0 ? [e.target.value] : null
                                                }
                                            })
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <b>Tālrunis:</b><br />
                                    <Input
                                        value={clientFilter.phone ? clientFilter.phone[0] : ""}
                                        style={{ marginTop: 8, width: 200 }}
                                        onChange={e => {
                                            e.persist();
                                            setClientFilter((prev: any) => {
                                                return {
                                                    ...prev,
                                                    phone: e.target.value.length > 0 ? [e.target.value] : null
                                                }
                                            })
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Col style={{ display: "flex", flexDirection: "column-reverse", marginLeft: 20 }}>
                                <Button
                                    onClick={fetchClients}
                                >
                                    Atlasīt
                                </Button>
                            </Col>
                        </Row>
                        <Table
                            rowKey={record => record.id}
                            columns={clientColumns}
                            dataSource={clientData}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        setMergedRecord(record)
                                    },
                                };
                            }}
                            loading={loading}
                        />
                    </Tabs.TabPane>
                </Tabs>
        }
    </Card>
})