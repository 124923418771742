import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Collapse, Form, Input, Row, Select, Space } from "antd";
import { SaveOutlined, DeleteTwoTone, UpOutlined, DownOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { http } from "../helpers/http";

const { Panel } = Collapse;

export const CSDDExamService = withRouter((props: any) => {
    const { deleteService, cities } = props;
    const [form] = Form.useForm();
    const [selectedCities, selectCities] = useState([] as any);
    const [allSelected, setAllSelected] = useState(false);

    const cityChoiceHandler = (e: any) => {
        selectCities(e);
    }

    useEffect(() => {
        console.log(props.service)
        if (props.service.hasOwnProperty("prices") && Object.keys(cities).length > 0) {
            initializeForm(props.service);
        }
    }, [cities])

    useEffect(() => {
        if (props.save === "collect") {
            finishHandler();
        }
    }, [props.save])

    useEffect(() => {
        console.log(selectedCities)
    }, [props.selectedCities])

    const initializeForm = (data: any) => {
        selectCities(() => {
            const serviceCities = data.prices.map((price: any) => {
                console.log(price)
                return cities.find((el: any) => el.id === price.city_id) !== undefined
                ? price.city_id
                : "DZĒSTS"
            })
            form.setFieldsValue({ cities: serviceCities })
            return serviceCities
        })
    }

    const formHandler = (e: any) => {
        if (e.target.id === 'all_cities') {
            switch (e.target.checked) {
                case true:
                    const allCities = cities.map((city: any) => city.id)
                    form.setFieldsValue({ cities: allCities })
                    selectCities(() => {
                        return allCities
                    });
                    setAllSelected(true);
                    break;
                case false:
                    form.setFieldsValue({ cities: [] })
                    selectCities(() => {
                        return []
                    });
                    setAllSelected(false);
                    break;
            }
        }
    }

    const finishHandler = () => {
        // const values = form.getFieldsValue();
        // console.log(values);
        form.validateFields().then((values: any) => {
            let citiesObject = {} as any;

            selectedCities.forEach((cityId: any) => {
                citiesObject = {
                    ...citiesObject,
                    [cityId]: {
                        price: values[`city-${cityId}`] === undefined ? values.price : values[`city-${cityId}`]
                    }
                }
            });

            const toSubmit = {
                id: props.service.id,
                name_lv: values.name_lv,
                name_en: values.name_en,
                name_ru: values.name_ru,
                cities: citiesObject,
                school: values.school ? 1 : 0,
                school_exam: values.school_exam ? 1 : 0,
                price: values.price
            }
            props.returnFormData(toSubmit)
        })
    }

    return (
        <Card style={
            {
                marginBottom: 15,
                boxShadow: "0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)"
            }
        }
        >
            <Form
                form={form}
                layout="horizontal"
                onChange={formHandler}
                initialValues={props.service}
            >
                <Row justify="space-between" align="middle">
                    <Col>
                        <h4>Nosaukums</h4>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <Form.Item
                                name="name_lv"
                                style={{ marginRight: 8 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Obligāts lauks',
                                    },
                                ]}
                            >
                                <Input placeholder="LV" />
                            </Form.Item>
                            <Form.Item name="name_en" style={{ marginRight: 8 }}>
                                <Input placeholder="EN" />
                            </Form.Item>
                            <Form.Item name="name_ru" style={{ marginRight: 8 }}>
                                <Input placeholder="RU" />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col>
                        <div style={{ marginBottom: 10, display: "flex", justifyContent: "end" }}>
                            <Button style={{ marginRight: 4 }} onClick={props.up}><UpOutlined /></Button>
                            <Button onClick={props.down}><DownOutlined /></Button>
                        </div>
                    </Col>
                </Row>
                <Card>
                    <Row gutter={24} justify="space-between">
                        <Col span={6}>
                            <b style={{ margin: 0 }}>Pilsētas</b>
                            <Form.Item
                                valuePropName="checked"
                                name="all_cities"
                                style={{ margin: 0 }}
                                label="Visas eksāmena pilsētas"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                name="cities"
                                style={{ margin: 0, maxWidth: 200 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Obligāts lauks',
                                    },
                                ]}
                            >
                                <Select value={selectedCities} disabled={allSelected} mode="multiple" onChange={cityChoiceHandler}>
                                    {cities.map((city: any) => {
                                        return <Select.Option
                                            key={city.id}
                                            value={city.id}
                                        >
                                            {city.value}
                                        </Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <b style={{ margin: 0 }}>Cena &euro;</b>
                            <Row>
                                <Col span={24} style={{ display: "flex", flexDirection: "row" }}>
                                    <Form.Item
                                        name="price"
                                        style={{ margin: "0 0 10px 0" }}
                                        label={"Visās atlasītajās pilsetās"}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Obligāts lauks',
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{ width: 100 }}
                                            type="number"
                                            step={0.01}
                                            min={0}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Collapse style={{ width: 400 }}>
                                <Panel header="Pielāgot cenas" key="1" forceRender>
                                    {
                                        selectedCities.length > 0
                                            ? selectedCities.map((city: any) => {
                                                return <Row key={city}>
                                                    <Col span={8}>
                                                        <b>
                                                            {
                                                                cities.find((el: any) => el.id === city) !== undefined
                                                                    ? cities.find((el: any) => el.id === city).value
                                                                    : "DZĒSTS"
                                                            }
                                                        </b>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name={`city-${city}`}
                                                            style={{ margin: "0 0 10px 10px" }}
                                                            initialValue={
                                                                props.service?.prices?.find((el: any) => {
                                                                    return el.city_id === city
                                                                })?.price
                                                            }
                                                        >
                                                            <Input
                                                                type="number"
                                                                step={0.01}
                                                                min={0}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            })
                                            : <b>Nav izvēlētas pilsētas</b>
                                    }
                                </Panel>
                            </Collapse>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                valuePropName="checked"
                                name="school"
                                style={{ margin: "0 0 10px 10px" }}
                                label="Skolas pakalpojums"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                valuePropName="checked"
                                name="school_exam"
                                style={{ margin: "0 0 10px 10px" }}
                                label="Skolas eksāmens"
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="end">
                        <Button
                            type="text"
                            onClick={() => deleteService(props.service.id)}
                        >
                            <DeleteTwoTone twoToneColor={"red"} />
                        </Button>
                    </Row>
                </Card>
            </Form>
        </Card>
    )
})