import React, { useEffect, useState } from "react";
import { Button, Card, Col, Layout, PageHeader, Row, Table, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";

export const MedicalGroups = withRouter((props: any) => {
    const [medGroups, setMedGroups] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15,
        total: 0
    })

    const fetch = async (params: any = {}) => {
        await http.get(`/rest/medical-groups`, {
            params: {
                fe: 1,
                page: params?.pagination?.current,
                sortField: params?.sortField,
                sortOrder: params?.sortOrder,
                filters: params?.filters
            }
        }).then((response: any) => {
            const { meta, data } = response.data
            setMedGroups(data);
            setPagination({
                current: meta.current_page,
                pageSize: meta.per_page,
                total: meta.total
            })
        })
    };

    const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
        fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters
        })
    }

    const columns = [
        {
            title: "Nosaukums",
            dataIndex: "name",
            key: "name",
            render: (name: any, record: any) => {
                return <Row justify="space-between" align="middle">
                    <p style={{ margin: 0 }}>{name}</p>
                    {
                        record.admin_comment?.length > 0
                            ? <Tooltip placement="top" title={record.admin_comment}>
                                <InfoCircleOutlined />
                            </Tooltip>
                            : <></>
                    }
                </Row>
            },
            ...getColumnSearchProps()
        },
        {
            title: "Sloti/Aizņemti",
            dataIndex: "",
            key: "online_date",
            align: "center",
            render: (_: any, record: any) => {
                return <p style={{ margin: 0 }}><b>{record.slots}/{record.slots_taken}</b></p>
            }
        },
        {
            title: "Teorētiskās nodarbības datums",
            dataIndex: "online_date",
            key: "online_date",
            sorter: true,
            ...getColumnSearchProps('calendar')
        },
        {
            title: "Teorētiskās nodarbības sākums",
            dataIndex: "online_from",
            key: "online_from",
            // ...getColumnSearchProps()
        },
        {
            title: "Praktiskās nodarbības datums",
            dataIndex: "medical_date",
            key: "medical_date",
            sorter: true,
            ...getColumnSearchProps('calendar')
        },
        {
            title: "Praktiskās nodarbības sākums",
            dataIndex: "medical_from",
            key: "medical_from",
            // ...getColumnSearchProps()
        },
    ]


    useEffect(() => {
        // setLoaded(true);
    }, [medGroups])

    useEffect(() => {
        fetch().then(() => setLoaded(true));
    }, [])

    return (
        <Layout>
            <PageHeader
                title="Pirmās palīdzības mācību grupas"
                onBack={props.history.goBack}
                style={{ backgroundColor: "#FFFFFF", width: "100%" }}
            />
            <Row>
                <Col span={24} style={{ padding: 15 }}>
                    <Card>
                        <Link to="/groups-medical/groups/new"><Button type="primary">Pievienot PMP grupu</Button></Link>
                        <Table
                            rowKey={(record: any) => record.id}
                            style={{ marginTop: 15 }}
                            loading={!loaded}
                            dataSource={medGroups}
                            columns={columns as any}
                            onChange={tableChangeHandler}
                            onRow={(record: any, rowIndex) => {
                                return {
                                    onClick: event => props.history.push(`/groups-medical/groups/${record.id}`)
                                }
                            }}
                            pagination={{
                                ...pagination
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
})