import { Row, Col, Select, Checkbox, DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { StudentFilter } from "./StudentSpamFilterLayout";

export const ServicesFilterForm = (props: any) => {
    const { studentFilter, setStudentFilter, classifiers } = props;

    const setServicesFilter = (data: StudentFilter) => {
        setStudentFilter((prev: StudentFilter) => {
            return {
                ...prev,
                ...data
            }
        })
    };

    const removeFilter = (key: string) => {
        setStudentFilter((prev: any) => {
            const copy = JSON.parse(JSON.stringify(prev));
            delete copy[key];
            return copy;
        });
    };

    return <Row gutter={20}>
        <Col>
            <p style={{ marginBottom: 4 }}>Grāmatas</p>
            <Select
                value={studentFilter.books}
                style={{ width: 200, marginBottom: 20 }}
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("books");
                        return;
                    }
                    setServicesFilter({ books: e });
                }}
            >
                {
                    studentFilter.books !== undefined
                        ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                        : <></>
                }
                <Select.Option value={1}>Ir</Select.Option>
                <Select.Option value={0}>Nav</Select.Option>
            </Select>
            <p style={{ marginBottom: 4 }}>Pieteikums</p>
            <Select
                value={studentFilter.paid_registration}
                style={{ width: 200, marginBottom: 20 }}
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("paid_registration");
                        return;
                    }
                    setServicesFilter({ paid_registration: e });
                }}
            >
                {
                    studentFilter.paid_registration !== undefined
                        ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                        : <></>
                }
                <Select.Option value={1}>Apmaksāts</Select.Option>
                <Select.Option value={0}>Nav apmaksāts</Select.Option>
            </Select>
        </Col>
        <Col>
            <p style={{ marginBottom: 4 }}>PMP</p>
            <Select
                value={studentFilter.pmp_passed}
                style={{ width: 200, marginBottom: 20 }}
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("pmp_passed");
                        removeFilter("pmp_from");
                        removeFilter("pmp_till");
                        return;
                    }
                    setServicesFilter({ pmp_passed: e });
                }}
            >
                {
                    studentFilter.pmp_passed !== undefined
                        ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                        : <></>
                }
                <Select.Option value={1}>Ir</Select.Option>
                <Select.Option value={0}>Nav</Select.Option>
            </Select>
            {
                studentFilter.pmp_passed === undefined
                    ? <></>
                    : <>
                        <p style={{ marginBottom: 4 }}>PMP termiņš</p>
                        <DatePicker.RangePicker
                            value={
                                studentFilter.pmp_from
                                    ? [
                                        moment(studentFilter.pmp_from, "YYYY-MM-DD"),
                                        moment(studentFilter.pmp_till, "YYYY-MM-DD"),
                                    ]
                                    : null
                            }
                            onChange={(date: any) => {
                                if (!date) {
                                    removeFilter("pmp_from");
                                    removeFilter("pmp_till");
                                    return;
                                }
                                setServicesFilter({
                                    pmp_from: date[0].format("YYYY-MM-DD"),
                                    pmp_till: date[1].format("YYYY-MM-DD"),
                                })
                            }}
                        />
                    </>
            }
        </Col>
        <Col>
            <p style={{ marginBottom: 4 }}>Medicīniskā izziņa</p>
            <Select
                value={studentFilter.got_med}
                style={{ width: 200, marginBottom: 20 }}
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("got_med");
                        removeFilter("med_from");
                        removeFilter("med_till");
                        return;
                    }
                    setServicesFilter({ got_med: e });
                }}
            >
                {
                    studentFilter.got_med !== undefined
                        ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                        : <></>
                }
                <Select.Option value={1}>Ir</Select.Option>
                <Select.Option value={0}>Nav</Select.Option>
            </Select>
            {
                studentFilter.got_med === undefined
                    ? <></>
                    : <>
                        <p style={{ marginBottom: 4 }}>Medicīniskās izziņas termiņš</p>
                        <DatePicker.RangePicker
                            value={
                                studentFilter.med_from
                                    ? [
                                        moment(studentFilter.med_from, "YYYY-MM-DD"),
                                        moment(studentFilter.med_till, "YYYY-MM-DD"),
                                    ]
                                    : null
                            }
                            onChange={(date: any) => {
                                if (!date) {
                                    removeFilter("med_from");
                                    removeFilter("med_till");
                                    return;
                                }
                                setServicesFilter({
                                    med_from: date[0].format("YYYY-MM-DD"),
                                    med_till: date[1].format("YYYY-MM-DD"),
                                })
                            }}
                        />
                    </>
            }
        </Col>
    </Row>
}