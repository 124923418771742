import { Card, Layout, PageHeader, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getColumnSearchProps } from "../helpers/getColumnSearchProps";
import { http } from "../helpers/http";

export const StudentsCredits = (props: any) => {
    const history = useHistory();

    const [credits, setCredits] = useState([] as any);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15,
        total: 0,
    })
    const [loading, setLoading] = useState(false);

    const getCreditsLog = async (params: any = { pagination }) => {
        setLoading(true);
        await http.get(`rest/get-credit-log`, {
            params: {
                page: params.pagination.current,
                limit: params.pagination.pageSize,
                filters: params.filters,
                order_by: params.order_by,
                order_desc: params.order_desc
            }
        })
            .then((response: any) => {
                setCredits(response.data.data)
                setPagination({
                    current: Number(response.data.page),
                    pageSize: 15,
                    total: Number(response.data.total)
                })
                setLoading(false);
            })
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(pagination, sorter, filters)
        getCreditsLog({
            pagination,
            filters,
            order_by: sorter.order ? sorter.field : null,
            order_desc: sorter.order === "ascend"
                ? "asc"
                : sorter.order === "descend"
                    ? "desc"
                    : null
        })
    }

    const columns = [
        {
            title: "Vārds",
            dataIndex: "first_name",
            key: "first_name",
            ...getColumnSearchProps()
        },
        {
            title: "Uzvards",
            dataIndex: "last_name",
            key: "last_name",
            ...getColumnSearchProps()
        },
        {
            title: "E-pasts",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps()
        },
        {
            title: "Tālruņa nr.",
            dataIndex: "phone_number",
            key: "phone_number",
            ...getColumnSearchProps()
        },
        {
            title: "Piešķirtais kredīts",
            dataIndex: "credit",
            key: "credit",
            sorter: true
        },
        {
            title: "Izmantotais kredīts",
            dataIndex: "real_used_credit",
            key: "real_used_credit",
            sorter: true
        },
        {
            title: "Parāds",
            dataIndex: "debt",
            key: "debt",
            sorter: true
        },
        {
            title: "Kredīts līdz",
            dataIndex: "credit_till",
            key: "credit_till",
            sorter: true,
            render: (credit_till: any) => {
                return moment(credit_till).format("DD.MM.YYYY HH.mm")
            },
            ...getColumnSearchProps('calendar')
        },
    ] as any;

    useEffect(() => {
        getCreditsLog();
    }, [])

    return <Layout>
        <PageHeader
            title="Studentu kredīti"
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF", marginBottom: 15 }}
        />
        <Card
            style={{ margin: 20 }}
        >
            <Table
                rowKey={record => record.id}
                dataSource={credits}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                rowClassName={(record: any) => {
                    const status = Number(record.debt) < 0 && moment(record.credit_till).isBefore(moment())
                        ? "in-debt"
                        : Number(record.debt) === 0 && moment(record.credit_till).isBefore(moment())
                            ? "cleared-credit"
                            : ""
                    return status
                }}
                expandable={{
                    rowExpandable: (record: any) => {
                        return record.requisites !== null
                    },
                    expandedRowRender: (record: any) => {
                        return <div>
                            <b>Maksātājs:</b>
                            <div dangerouslySetInnerHTML={{ __html: record.requisites.replaceAll('\n', '<br />') }} />
                        </div>
                    }
                }}
                loading={loading}
            />
        </Card>
    </Layout>
}