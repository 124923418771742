import { Button, Card, Col, Collapse, Input, Layout, message, PageHeader, Radio, Row, Space } from "antd";
import { DeleteOutlined, ArrowDownOutlined, ArrowUpOutlined, UploadOutlined, SaveTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { QuestionCard } from "./questions/QuestionCard";
import { http } from "../../helpers/http";

const { Panel } = Collapse;

export const ExamQuestionsView = (props: any) => {
    const history = useHistory();

    const { mid } = useParams() as any;
    const [questions, setQuestions] = useState([] as any);
    const [languages, setLanguages] = useState([] as any);
    const [data, setData] = useState({
        exam_time_limit: undefined,
        exam_questions_count: undefined,
        exam_max_errors: undefined,
        exam_attempts: undefined
    })


    const fetchLanguages = async () => {
        await http.get(`/rest/languages`).then((response: any) => {
            setLanguages(response.data.data);
        });
    };

    const fetchModuleData = async () => {
        await http.get(`/rest/learning-modules/${mid}`).then((response: any) => {
            setData({
                exam_time_limit: response.data.exam_time_limit,
                exam_questions_count: response.data.exam_questions_count,
                exam_max_errors: response.data.exam_max_errors,
                exam_attempts: response.data.exam_attempts
            })
        })
    }

    const fetchExamQuestions = async () => {
        await http.get(`/rest/get-learning-module-questions/${mid}`).then((response: any) => {
            setQuestions(response.data.data)
        })
    }

    const addQuestion = () => {
        setQuestions((prev: any) => {
            return [
                ...prev,
                {
                    e_module_id: Number(mid),
                    id: null,
                    question_lv: null,
                    question_en: null,
                    question_ru: null,
                    description_lv: null,
                    description_en: null,
                    description_ru: null,
                    img: null,
                    answers: [
                        // {
                        //     answer_lv: null,
                        //     answer_en: null,
                        //     answer_ru: null,
                        //     correct: undefined,
                        //     img: null
                        // }
                    ]
                }
            ]
        })
    }

    const setImage = (index: any, file: any) => {
        console.log(index, file)
        // setQuestions((prev: any) => {
        //     const copyArr = [...prev] as any;
        //     copyArr[index].img = file
        //     return [...copyArr]
        // })
    }

    const saveExamStuff = () => {
        http.post(`rest/update-module-exam-stuff/${mid}`, {
            exam_time_limit: data.exam_time_limit,
            exam_questions_count: data.exam_questions_count,
            exam_max_errors: data.exam_max_errors,
            exam_attempts: data.exam_attempts
        }).then((response: any) => {
            console.log(response)
            message.success("Pārbaudījuma uzstādījumi ir saglabāti")
        }).catch(() => {
            message.error("Kļūda")
        })
    }

    const removeQuestion = (e: any, ind: any) => {
        const toDelete = questions.find((el: any, index: any) => index === ind)
        const haveID = toDelete.id !== null
        if (haveID) {
            http.delete(`/rest/learning-module-exam-questions/${toDelete.id}`).then((response: any) => {
                message.success("Jautājums dzēsts!");
            }).catch(() => {
                message.error("Kļūda!");
                return;
            })
        }
        setQuestions((prev: any) => {
            return prev.filter((el: any, index: any) => index !== ind)
        })
    }

    useEffect(() => {
        fetchLanguages();
        fetchModuleData();
        fetchExamQuestions();
    }, [])

    return <Layout className="main-content">
        <PageHeader
            title={`Moduļa gala pārbaudījuma uzstādījumi`}
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF" }}
        />
        <Card style={{ margin: 15 }}>
            <Card style={{ marginBottom: 15 }}>
                <Row justify="space-between">
                    <Space>
                        <b>Testa laiks (minūtēs):</b>
                        <Input
                            value={data.exam_time_limit}
                            type="number"
                            min={0}
                            step={0.1}
                            style={{ width: 120, marginLeft: 15 }}
                            onChange={(e: any) => {
                                e.persist();
                                setData((prev: any) => {
                                    return {
                                        ...prev,
                                        exam_time_limit: e.target.value
                                    }
                                })
                            }}
                        />
                        <b>Testa jautājumu skaits:</b>
                        <Input
                            value={data.exam_questions_count}
                            type="number"
                            min={0}
                            step={1}
                            style={{ width: 120, marginLeft: 15 }}
                            onChange={(e: any) => {
                                e.persist();
                                setData((prev: any) => {
                                    return {
                                        ...prev,
                                        exam_questions_count: e.target.value
                                    }
                                })
                            }}
                        />
                         <b>Pieļaujamo kļūdu skaits:</b>
                        <Input
                            value={data.exam_max_errors}
                            type="number"
                            min={0}
                            step={1}
                            style={{ width: 120, marginLeft: 15 }}
                            onChange={(e: any) => {
                                e.persist();
                                setData((prev: any) => {
                                    return {
                                        ...prev,
                                        exam_max_errors: e.target.value
                                    }
                                })
                            }}
                        />
                         <b>Mēģinājumu skaits:</b>
                        <Input
                            value={data.exam_attempts}
                            type="number"
                            min={0}
                            step={1}
                            style={{ width: 120, marginLeft: 15 }}
                            onChange={(e: any) => {
                                e.persist();
                                setData((prev: any) => {
                                    return {
                                        ...prev,
                                        exam_attempts: e.target.value
                                    }
                                })
                            }}
                        />
                    </Space>
                    <Button onClick={saveExamStuff} type="primary">
                        Saglabāt
                    </Button>
                </Row>
            </Card>
            <Collapse>
                {
                    questions.map((question: any, index: any) => {
                        return <Panel
                            header={<Row justify="space-between">
                                <b style={{ margin: 0 }}>{index + 1}. Jautājums</b>
                                <Button
                                    type="text"
                                    danger
                                    style={{ marginLeft: 10 }}
                                    onClick={(e: any) => removeQuestion(e, index)}
                                    icon={<DeleteOutlined style={{ pointerEvents: "none" }}
                                    />}>
                                </Button>
                            </Row>}
                            key={index + 1}
                        >
                            <QuestionCard
                                qind={index}
                                question={question}
                                languages={languages}
                                apiResource={'/rest/learning-module-exam-questions'}
                                saveImage={setImage}
                                refresh={fetchExamQuestions}
                            />
                        </Panel>
                    })
                }
            </Collapse>
            <Row justify="space-between" style={{ marginTop: 30 }}>
                <Button
                    onClick={addQuestion}
                >
                    Pievienot Jautājumu +
                </Button>
            </Row>
        </Card>
    </Layout>
}