import { Button, Checkbox, Input, Row, Select, TimePicker } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import moment from "moment";
import React from "react";

export const TimeSlot = (props: any) => {
    const {
        slot,
        languages,
        updateSlot,
        deleteSlot,
        zoomAccounts
    } = props;

    return <div style={{ marginBottom: 8 }}>
        <Row align="middle">
            <Checkbox
                style={{ marginRight: 10 }}
                disabled={slot.time_from === null}
                checked={props.slot.enabled}
                onChange={(e: any) => {
                    updateSlot({
                        ...slot,
                        enabled: e.target.checked ? 1 : 0
                    })
                }}
            />
            <TimePicker.RangePicker
                style={{ width: 170, marginRight: 15 }}
                format={"HH:mm"}
                value={slot.time_from === null ? null : [moment(slot.time_from, "HH:mm"), moment(slot.time_to, "HH:mm")]}
                onChange={(e: any) => {
                    updateSlot(e !== null && e !== undefined
                        ? {
                            ...slot,
                            time_from: moment(e[0]).format("HH:mm"),
                            time_to: moment(e[1]).format("HH:mm")
                        }
                        : {
                            ...slot,
                            time_from: null,
                            time_to: null,
                            enabled: 0
                        }
                    )
                }}
            />
            {/* <Input
                style={{ width: 60, marginRight: 8 }}
                type="number"
                min={1}
                max={9}
                value={1}
                disabled
                onChange={(e: any) => {
                    updateSlot({
                        ...slot,
                        how_many_exams: Number(e.target.value)
                    })
                }}
            />
            <span>sloti</span> */}
            <Select
                style={{ width: 167, marginLeft: 15, marginRight: 15 }}
                mode="multiple"
                value={slot.languages}
                onChange={(e: any) => {
                    updateSlot({
                        ...slot,
                        languages: e
                    })
                }}
            >
                {
                    languages?.map((lang: any) => {
                        return <Select.Option key={lang?.id} value={lang?.id}>{lang?.iso?.toUpperCase()}</Select.Option>
                    })
                }

            </Select>
            <Select
                style={{ width: 167, marginLeft: 15, marginRight: 15 }}
                placeholder="Zoom konts"
                value={slot.zoom_account_id}
                onChange={(e: any) => {
                    updateSlot({
                        ...slot,
                        zoom_account_id: e
                    })
                }}
            >
                {
                    zoomAccounts.map((account: any, index: any) => {
                        return <Select.Option key={index} value={account.id}>{account.name}</Select.Option>
                    })
                }
            </Select>
            <Button
                style={{ margin: "0 0 0 auto" }}
                type="text"
                icon={<DeleteTwoTone twoToneColor={"red"} />}
                onClick={() => deleteSlot(slot)}
            />
        </Row>
    </div>
}