import React, { useEffect, useState } from "react";
import { Service } from "./Service";

export const StudentServicesNew = (props: any) => {
    const { services, edit, add, remove, changeGroup, allServices, extraServices, refreshStudent, commonServices } = props;
    const [availableServices, setAvailableServices] = useState([]);

    useEffect(() => {
        setAvailableServices(allServices);

    }, [allServices])

    useEffect(() => {
        // console.log(extraServices)
    }, [])

    return (
        <>
            <ul style={{ padding: 0 }}>
                {/* <Button onClick={() => console.log(availableServices)}>SERVICES</Button> */}
                {availableServices.length > 0 ? availableServices.map((service: any) => {
                    let extraServicesRelated = [];
                    const studentHaveService = services.some((el: any) => el.id === service.id)
                    const commonService = commonServices.find((cService: any) => {
                        return cService.study_group_service_type_id === service.study_group_service_type_id
                            && cService.study_group_service_id !== service.id
                    })
                    if (studentHaveService) {
                        extraServicesRelated = extraServices?.filter((el: any) => el.study_group_service_id === service.id)
                    }
                    // console.log(commonService, service, commonServices)
                    return <Service
                        key={service.id}
                        services={services}
                        service={service}
                        changeGroup={changeGroup}
                        edit={edit}
                        add={add}
                        remove={remove}
                        extraServices={extraServicesRelated}
                        refreshStudent={refreshStudent}
                        commonService={commonService}
                    />
                })
                    : "Nav pievienots neviens pakalpojums"}
            </ul>
        </>
    )
}
