import { Button, Card, Col, DatePicker, InputNumber, Row, Modal, Space, Spin, message } from "antd"
import { SendOutlined, DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react"
import { ReactComponent as ExcelLogo } from '../../../resources/svg/icons8-microsoft-excel.svg';
import { http } from "../../../helpers/http";
import moment from "moment";

const { confirm } = Modal;

export const SchoolTab = (props: any) => {
    const [amount, setAmount] = useState(0);
    const [payments, setPayments] = useState([] as any);
    const [period, setPeriod] = useState(null as any);
    const [balances, setBalances] = useState(undefined as any);
    const [unpaidBalance, setUnpaidBalance] = useState(undefined as any)
    const [showInBrowser, setShowInBrowser] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchPayments = async () => {
        if (period === null) return;
        setShowInBrowser(false);
        // const loadingTimer = setInterval(getProgress, 3000);
        setLoading(true);
        const from = moment(period[0]).format('YYYY-MM-DD');
        const to = moment(period[1]).format('YYYY-MM-DD');
        await http.post(`/schoolinvoices/get-accumulates-detalization/${from}/${to}`).then((response: any) => {
            console.log(response);
            // clearInterval(loadingTimer)
            setLoading(false);
            const joinAll = Object.keys(response.data.rows).flatMap((part: any) => {
                return response.data.rows[part];
            });
            setPayments(joinAll)
            setBalances(() => {
                return {
                    ...response.data.balances,
                    calculated_end_balance: response.data.calculated_end_balance
                }
            })
        }).catch(() => {
            message.error("Kļūda (vai nav atrasts neviens ieraksts)");
            setLoading(false);
        })
    }

    const getProgress = async () => {
        await http.get('/schoolinvoices/loading-status').then((response: any) => {
            console.log(response);
        })
    }

    const fetchBalance = async (override: boolean = false) => {
        if (period !== null && !override) {
            return;
        }
        setLoading(true);
        await http.post('schoolinvoices/get-current-balance').then((response: any) => {
            setUnpaidBalance(response.data);
            setLoading(false);
        })
    }

    const getFile = async () => {
        if (period === null) return;
        const from = moment(period[0]).format('YYYY-MM-DD');
        const to = moment(period[1]).format('YYYY-MM-DD');
        setLoading(true);
        await http.get(`/schoolinvoices/export-accumulates-detalization/${from}/${to}`, { responseType: 'blob' }).then((response: any) => {
            setLoading(false);
            return new Blob([response.data]);
        }).then((blob: any) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a') as any;
            link.href = url;
            link.setAttribute('download', `${moment().format('YYYY-MM-DD HH:mm:ss')}.xls`);
            document.body.appendChild(link);
            link.click();
            if (link !== null) link.parentNode.removeChild(link);
        }).catch(() => {
            message.error("Kļūda");
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchBalance();
    }, [])

    // useEffect(() => {
    //     fetchBalance().then(fetchPayments);
    // }, [period]);

    useEffect(() => {
        console.log(payments)
    }, [payments]);

    const sendMoney = () => {
        confirm({
            title: "Apstiprināt",
            icon: <SendOutlined />,
            content: <h3>Pārskaitīt uz savu kontu: {amount.toFixed(2)}</h3>,
            onOk() {
                if (amount <= unpaidBalance?.current_balance) {
                    http.post('schoolinvoices/create-payment', { amount: amount }).then((response: any) => {
                        console.log(response)
                        fetchBalance(true);
                        setPayments([]);
                        setPeriod(null);
                        message.success("Pārskaitījums ir veiksmīgs")
                    }).catch(() => {
                        message.error("Kļūda!")
                    })
                }
            }
        })
    }

    const returnMoney = (id: any) => {
        confirm({
            title: "Dzēst",
            icon: <WarningOutlined color="red" />,
            content: <h3>Dzēst maksājumu?</h3>,
            onOk() {
                http.delete(`schoolinvoices/delete-payment/${id}`).then((response: any) => {
                    console.log(response)
                    fetchPayments();
                    fetchBalance(true);
                    message.success("Maksājums ir dzēsts")
                }).catch(() => {
                    message.error("Kļūda!")
                })
            }
        })
    }

    return (
        <>
            <Card style={{ margin: "20px 0" }}>
                <Row gutter={24}>
                    <Col>
                        <h3>Esošais uzkrājums: {(unpaidBalance?.current_balance)?.toFixed(2) || ""} &euro;</h3>
                    </Col>
                    <Col>
                        <InputNumber
                            value={amount}
                            min={0}
                            step={0.01}
                            onChange={(e: any) => setAmount(e)}
                        />
                        <Button
                            type="primary"
                            style={{ marginLeft: 20 }}
                            onClick={sendMoney}
                        >
                            Pārskaitīt uz savu kontu
                        </Button>
                        {/* <Progress percent={loadingProgress} status="active" />
                        <Button onClick={fakeFetch}>TEST</Button> */}
                    </Col>
                </Row>
            </Card>
            <Row align="middle">
                <DatePicker.RangePicker value={period} onChange={setPeriod} style={{ margin: "10px 15px 10px 0" }} />
                {/* <Input placeholder="Nosaukums" style={{ width: 200, margin: "10px 15px 10px 0" }} />
                    <Input placeholder="Summa" style={{ width: 200, margin: "10px 15px 10px 0" }} /> */}
                {/* <Input placeholder="Numurs" style={{ width: 200, margin: "10px 15px 10px 0" }} /> */}
                <Button onClick={fetchPayments}>Atlasīt</Button>
                <h3 style={{ margin: "0 15px" }}>Ieraksti: <b>{payments.length}</b></h3>
                {
                    payments.length > 0
                        ? <Space>

                            <Button onClick={() => setShowInBrowser(!showInBrowser)} style={{ margin: "10px 15px 10px 0" }} >
                                {showInBrowser ? "Paslēpt rezultātus" : "Rādīt rezultātus pārlūkā"}
                            </Button>
                            <Button className="excel-btn" onClick={getFile}>
                                Eksportēt&nbsp;
                                <ExcelLogo className="excel-btn__logo" />
                            </Button>
                        </Space>
                        : <></>
                }

            </Row>
            <Row justify="center">
                {
                    loading
                        ? <div style={{ display: "flex", flexDirection: "column", alignContent: "center" }}>
                            <Spin size="large" /><br />
                            <small>(Ierakstu atlase var aizņemt vairākas minūtes)</small>
                        </div>
                        : <></>
                }
            </Row>

            {
                showInBrowser
                    ? <>

                        <Row justify="space-between" style={{ padding: 10 }}>
                            <Col span={24}>
                                <Row justify="end">
                                    <h3>Sākuma summa: <b>{balances?.start_balance}</b></h3>
                                </Row>

                                {/* <h3>current_balance: <b>{balances?.current_balance}</b></h3>
                                <h3>end_balance: <b>{balances?.end_balance}</b></h3>
                                <h3>end_balance_data_instructor: <b>{balances?.end_balance_data_instructor}</b></h3>
                                <h3>instructor_sum: <b>{balances?.instructor_sum}</b></h3>
                                <h3>period_balance: <b>{balances?.period_balance}</b></h3>
                                <h3>period_balance_instructor: <b>{balances?.period_balance_instructor}</b></h3>
                                <h3>start_balance: <b>{balances?.start_balance}</b></h3>
                                <h3>start_balance_instructor: <b>{balances?.start_balance_instructor}</b></h3> */}
                            </Col>
                        </Row>
                        <Row style={{ backgroundColor: "#E9E9E9" }}>
                            <Col span={4} style={{ padding: "10px 24px" }}>
                                Datums
                            </Col>
                            <Col span={4} style={{ display: "flex", justifyContent: "center", padding: "10px 24px" }}>
                                Summa
                            </Col>
                            <Col span={6} style={{ display: "flex", justifyContent: "center", padding: "10px 24px" }}>
                                Vārds Uzvārds
                            </Col>
                            <Col span={6} style={{ display: "flex", justifyContent: "center", padding: "10px 24px" }}>
                                Pamatojums
                            </Col>

                            <Col span={4} style={{ display: "flex", justifyContent: "end", padding: "10px 24px" }}>
                                Konta atlikums
                            </Col>
                        </Row>
                        {
                            payments.map((payment: any, index: any, self: any) => {
                                return <Card
                                    key={payment.id}
                                    hoverable
                                    onChange={() => console.log("HI")}
                                    style={{ backgroundColor: !(Number(payment.amount) < 0) ? "rgb(144 238 144)" : "rgb(248 210 210)" }}
                                >
                                    <Row>
                                        <Col span={4}>
                                            {payment.ts}
                                        </Col>
                                        <Col span={4} style={{ display: "flex", justifyContent: "center" }}>
                                            <b>{Number(payment.amount).toFixed(2)}</b>
                                        </Col>
                                        <Col span={6} style={{ display: "flex", justifyContent: "center" }}>
                                            {payment.student}
                                        </Col>
                                        <Col span={6} style={{ display: "flex", justifyContent: "center" }}>
                                            {payment.reason}
                                        </Col>

                                        <Col span={4} style={{ display: "flex", justifyContent: "end" }}>
                                            <b>{payment.balance.toFixed(2)}</b>
                                            {
                                                payment.source === 'school_payments'
                                                    ? <Button
                                                        style={{ marginLeft: 8 }}
                                                        onClick={() => returnMoney(payment.id)}
                                                        icon={<DeleteOutlined />}
                                                    ></Button>
                                                    : <></>
                                            }
                                        </Col>
                                    </Row>
                                </Card>
                            })
                        }
                        <Row justify="end" style={{ padding: 10 }}>
                            <Col span={6} style={{ display: "flex", alignContent: "end", flexDirection: "column", flexWrap: "wrap" }}>
                                <h3>Beigu atlikums: <b>{balances?.calculated_end_balance}</b></h3>

                            </Col>
                        </Row>
                    </>
                    : <></>
            }
            {/* {
                loadingProgress
                    ? <h3>Izvēlieties periodu</h3>
                    : <></>
            } */}

        </>
    )
}