import { Button, Card, Checkbox, Col, Input, message, Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../helpers/http";
import { AxiosResponse } from 'axios';

export type Discounts = {
    [key: string]: number | undefined;
    bonus_school_driving: number | undefined;
    bonus_external_driving: number | undefined;
    bonus_school_driving_exam: number | undefined;
    bonus_external_driving_exam: number | undefined;
    bonus_registration: number | undefined;
    bonus_books: number | undefined;
    bonus_theory: number | undefined;
    bonus_pmp: number | undefined;
    bonus_school_exam: number | undefined;
    bonus_extra_theory: number | undefined;
    bonus_full_training: number | undefined;
    bonus_omniva: number | undefined;
    scholarship_school_driving: number | undefined;
    scholarship_external_driving: number | undefined;
    scholarship_school_driving_exam: number | undefined;
    scholarship_external_driving_exam: number | undefined;
    scholarship_registration: number | undefined;
    scholarship_books: number | undefined;
    scholarship_theory: number | undefined;
    scholarship_pmp: number | undefined;
    scholarship_school_exam: number | undefined;
    scholarship_extra_theory: number | undefined;
    scholarship_full_training: number | undefined;
    scholarship_omniva: number | undefined;
}

export const GlobalDiscountsSettings = () => {
    const [discounts, setDiscounts] = useState<Discounts>({
        affiliate_amount: 0,
        bonus_school_driving: 0,
        bonus_external_driving: 0,
        bonus_school_driving_exam: 0,
        bonus_external_driving_exam: 0,
        bonus_registration: 0,
        bonus_books: 0,
        bonus_theory: 0,
        bonus_pmp: 0,
        bonus_school_exam: 0,
        bonus_extra_theory: 0,
        bonus_full_training: 0,
        bonus_omniva: 0,
        scholarship_school_driving: 0,
        scholarship_external_driving: 0,
        scholarship_school_driving_exam: 0,
        scholarship_external_driving_exam: 0,
        scholarship_registration: 0,
        scholarship_books: 0,
        scholarship_theory: 0,
        scholarship_pmp: 0,
        scholarship_school_exam: 0,
        scholarship_extra_theory: 0,
        scholarship_full_training: 0,
        scholarship_omniva: 0,
    });
    // const [newSettings, setNewSettings] = useState<School>({
    //     name: null,
    //     registration_nr: null,
    //     legal_address: null,
    //     bank: null,
    //     swift: null,
    //     bank_account: null
    // });

    const getDiscounts = () => {
        http.get(`rest/bonus-settings`).then((res: AxiosResponse) => {
            console.log(res)
            if (typeof res.data === "string") return;
            const { data } = res;
            setDiscounts((prev: Discounts): Discounts => {
                const copy = { ...prev };
                Object.keys(copy).forEach(key => {
                    copy[key] = data[key]
                })
                return copy
            })
        })
    }

    // const getGlobalSchoolSettings = () => {
    //     http.get(`rest/global-settings-by-scope/requisites`).then((res: AxiosResponse) => {
    //         console.log(res)
    //         const { data } = res.data;
    //         setGlobalSettings((prev: School): School => {
    //             const copy = { ...prev };
    //             Object.keys(copy).forEach(key => {
    //                 copy[key] = data.find((item: any) => {
    //                     return item.key === key
    //                 })?.value ?? null
    //             })
    //             return copy
    //         })
    //     })
    // }

    const setDiscountSettings = () => {
        http.post(`rest/bonus-settings`, { ...discounts }).then((res: AxiosResponse) => {
            console.log(res)
            message.success(`Saglabāts!`);
        })
    };

    const keys = {
        affiliate_amount: "Einšteinu bonus par katru piesaistīto klientu",
        bonus_school_driving: "(Bonus) Braukšanas nodarbība skolas instruktoriem",
        bonus_external_driving: "(Bonus) Braukšanas nodarbība instruktoriem, kas nav skolas",
        bonus_school_driving_exam: "(Bonus) Braukšanas eksāmens skolas instruktoriem",
        bonus_external_driving_exam: "(Bonus) Braukšanas eksāmens instruktoriem, kas nav skolas",
        bonus_registration: "(Bonus) Reģistrācija mācību grupā",
        bonus_books: "(Bonus) Grāmatas iegādei",
        bonus_theory: "(Bonus) Teorijas pakalpojums",
        bonus_pmp: "(Bonus) PMP pakalpojums",
        bonus_school_exam: "(Bonus) Skolas teorijas eksāmens",
        bonus_extra_theory: "(Bonus) Papildus teorijas nodarbība",
        bonus_full_training: "(Bonus) Pilna apmācība",
        bonus_omniva: "(Bonus) Omniva",
        scholarship_school_driving: "(Stipendija) Braukšanas nodarbība skolas instruktoriem",
        scholarship_external_driving: "(Stipendija) Braukšanas nodarbība instruktoriem, kas nav skolas",
        scholarship_school_driving_exam: "(Stipendija) Braukšanas eksāmens skolas instruktoriem",
        scholarship_external_driving_exam: "(Stipendija) Braukšanas eksāmens instruktoriem, kas nav skolas",
        scholarship_registration: "(Stipendija) Reģistrācija mācību grupā",
        scholarship_books: "(Stipendija) Grāmatas iegādei",
        scholarship_theory: "(Stipendija) Teorijas pakalpojums",
        scholarship_pmp: "(Stipendija) PMP pakalpojums",
        scholarship_school_exam: "(Stipendija) Skolas teorijas eksāmens",
        scholarship_extra_theory: "(Stipendija) Papildus teorijas nodarbība",
        scholarship_full_training: "(Stipendija) Pilna apmācība",
        scholarship_omniva: "(Stipendija) Omniva",
    } as any

    useEffect(() => {
        getDiscounts();
    }, []);

    // useEffect(() => {
    //     setNewSettings(globalSettings)
    // }, [globalSettings]);

    return <Row gutter={[20, 20]}>
        {
            Object.keys(keys).map(key => {
                return <Col key={key} style={{ marginBottom: 10, width: 200, display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                    <p>{keys[key]}</p>
                    <Input
                        value={discounts[key] ?? undefined}
                        name={key}
                        type="number"
                        min={0}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.persist();
                            setDiscounts((prev: Discounts): Discounts => {
                                return {
                                    ...prev,
                                    [e.target.name]: e !== undefined ? Number(e.target.value) : undefined
                                }
                            })
                        }}
                    />
                </Col>
            })
        }
        <Col style={{ marginBottom: 10, width: 200, display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
            <Button
                type="primary"
                onClick={() => setDiscountSettings()}
            >
                Saglabāt
            </Button>
        </Col>
    </Row>
}