import { Button, Checkbox, Col, DatePicker, Input, message, Row, Select, Skeleton, TimePicker } from "antd";
import moment from "moment";
import 'moment/locale/lv'
import React, { useEffect, useState } from "react";
import { http } from "../../../helpers/http";
import { TimeSlot } from "./TimeSlot";

moment.locale("lv");

export const DaysPlanner = (props: any) => {
    const { zoomAccounts } = props;
    const [data, setData] = useState();
    const [slots, setSlots] = useState([] as any);
    const [activeDay, setActiveDay] = useState(1);
    const [languages, setLanguages] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const fetchLangauges = async () => {
        await http.get(`rest/languages`).then((response: any) => {
            setLanguages(response.data.data);
        });
    };

    const fetchSlots = async () => {
        setLoading(true);
        await http.get(`rest/theory_exam_templates`).then((response: any) => {
            setSlots(
                response.data.data.map((slot: any, index: any) => {
                    return {
                        ...slot,
                        ind: index
                    }
                })
            )
            setLoading(false);
        })
    }

    const addTimeSlot = () => {
        setSlots((prev: any) => {
            prev.push({
                day_of_the_week: activeDay,
                time_from: null,
                time_to: null,
                languages: [],
                how_many_exams: 1,
                enabled: 0,
                zoom_account_id: undefined,
                zoom_link: undefined,
                ind: prev.length
            })
            return [...prev]
        })
    };

    const updateSlot = (slot: any) => {
        setSlots((prev: any) => {
            const prevCopy = prev.map((item: any) => {
                if (
                    item.ind === slot.ind
                ) {
                    return slot;
                } else return item;
            })
            return prevCopy
        })
    };

    const deleteSlot = (slot: any) => {
        setSlots((prev: any) => {
            const prevCopy = prev.filter((item: any) => {
                return item.ind !== slot.ind
            });
            const updatedInd = prevCopy.map((slot: any, index: any) => {
                return {
                    ...slot,
                    ind: index
                }
            });
            return updatedInd;
        })
    };

    const saveSlots = async () => {
        const canSave = !slots.some((el: any) => {
            return el.time_from === null
                || el.time_to === null
                || el.how_many_exams === undefined
                || el.languages.length === 0
        })
        console.log(canSave)
        if (canSave) {
            setSaving(true);
            await http.post(`rest/theory_time_slot_templates/sync`, { slots }).then((response: any) => {
                message.success("Eksāmenu sloti ir saglabāti");
                setSlots(
                    response.data.data.map((slot: any, index: any) => {
                        return {
                            ...slot,
                            ind: index
                        }
                    })
                )
                setSaving(false);
            }).catch((err: any) => {
                message.error("Kļūda!");
                setSaving(false);
            })
        } else {
            message.error("Nav aizpildīti visi lauki!");
        }
    };

    const setActiveSlots = (day: number, active: boolean) => {
        setSlots((prev: any) => {
            const prevCopy = prev.map((slot: any) => {
                if (slot.day_of_the_week === day) {
                    return {
                        ...slot,
                        enabled: active ? 1 : 0
                    }
                } else return slot;
            })
            return prevCopy;
        })
    };

    useEffect(() => {
        console.log(slots)
    }, [slots])

    useEffect(() => {
        fetchLangauges();
        fetchSlots();
    }, []);

    return loading
        ? <Skeleton />
        : <Row>
            <Col span={6}>
                <b style={{ display: "block", marginBottom: 15 }}>Pieņemšanas Dienas</b>
                {
                    moment.weekdays(true)?.map((day: string, index: any, self: any) => {
                        return <div
                            style={{
                                border: activeDay === index + 1 ? "1px solid #979797" : "unset",
                                borderRight: activeDay === index + 1 ? "unset" : "",
                                // borderBottom: index === self.length - 1 ? "1px solid #979797" : "unset",
                                backgroundColor: "#FFFFFF",
                                padding: 10,
                                cursor: "pointer",
                                transform: activeDay === index + 1 ? "translateX(1px)" : "",
                                zIndex: 2,
                                position: "relative"
                            }}
                            key={index}
                            onClick={() => setActiveDay(index + 1)}
                        >
                            <Checkbox
                                disabled={!slots.some((el: any) => el.day_of_the_week === index + 1)}
                                checked={
                                    slots.some((el: any) => el.day_of_the_week === index + 1 && el.enabled)
                                }
                                onChange={(e) =>
                                    setActiveSlots(index + 1, e.target.checked)
                                }
                            />
                            <p style={{ display: "inline-block", margin: "0 0 0 8px" }}>
                                {day.charAt(0).toUpperCase() + day.slice(1)}
                            </p>
                        </div>
                    })
                }
            </Col>
            <Col span={18}>
                <b style={{ display: "block", margin: "0 0 15px 20px" }}>Pieņemšanas Laiki</b>
                <div
                    style={{
                        border: "1px solid #979797",
                        minHeight: "calc(100% - 37px)",
                        padding: 20,
                        zIndex: 1,
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                    }}
                >
                    <div>
                        {
                            slots?.filter((slot: any) => {
                                return slot.day_of_the_week === activeDay
                            }).map((slot: any, index: any) => {
                                return <TimeSlot
                                    key={index}
                                    languages={languages}
                                    slot={slot}
                                    updateSlot={updateSlot}
                                    deleteSlot={deleteSlot}
                                    zoomAccounts={zoomAccounts}
                                />
                            })
                        }
                    </div>
                    <Row justify="space-between">
                        <Button
                            style={{ width: 150 }}
                            onClick={() => addTimeSlot()}
                        >
                            Pievienot slotu
                        </Button>
                        <Button
                            type="primary"
                            onClick={saveSlots}
                            loading={saving}
                        >
                            {saving ? "Saglabāšana" : "Saglabāt"}
                        </Button>
                    </Row>
                </div>
            </Col>
        </Row>
}