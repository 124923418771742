import { Button, Col, Row, Modal, Input, Popover, Select, Space, message, Spin, Checkbox } from "antd";
import { InfoCircleOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../../../helpers/http";
import { AddStudentModal } from "./AddStudentModal";
import { StudentSettingsModal } from "./SettingsModal/StudentSettingsModal";
import { v4 as uuid } from 'uuid';
import moment from "moment";
import { EditPriceModal } from "./EditPriceModal";

export const StudentApplySlot = (props: any) => {
    const { slot, zoomAccount, languages, categories } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [settingsModalVisible, setSettingsModalVisible] = useState(false);
    const [resultsModalVisible, setResultsModalVisible] = useState(false);
    const [zoomModalVisible, setZoomModalVisible] = useState(false);
    const [zoomAccID, setZoomAccID] = useState(slot.zoom_account_id);
    const [editPriceModalVisible, setEditPriceModalVisible] = useState(false);
    const [selectedLanguages, setSelectedLanguages] = useState(slot.languages);
    const [loading, setLoading] = useState(false);
    const examIsOld = moment(slot.exam_date + " " + slot.time_from, "YYYY-MM-DD HH:mm:ss").isBefore(moment());
    const examTry = slot?.student?.exams?.findIndex((el: any) => {
        return el.id === slot.id
    });

    const updateSlots = () => {
        setLoading(true);
        props.updateSlots().then(() => { setLoading(false) });
    };

    const deleteSlot = () => {
        let newSlot = false;
        Modal.confirm({
            title: "Dzēst teorijas eksāmena slotu",
            content: <div>
                <p>
                    <b>
                        Vai tiešām dzēst teorijas eksamena slotu - {slot.exam_date}, {slot.time_from} - {slot.time_to}?
                    </b>
                </p>
                <Row>
                    <Checkbox
                        onChange={(e) => {
                            newSlot = e.target.checked
                        }}
                    />
                    <span style={{ marginLeft: 10 }}>Izveidot jaunu slotu</span>
                </Row>
            </div>,
            onOk() {
                setLoading(true);
                http.post(`rest/cancel-theory-exam/${slot.id}`, {
                    new_slot: newSlot ? 1 : undefined
                }).then((response: any) => {
                    updateSlots()
                })
            },
        })
    };

    const updateSlotLanguages = () => {
        http.post(`rest/change-slot-languages/${slot.id}`, { languages: selectedLanguages }).then((response: any) => {
            message.success("Valodu uzstādījumi ir saglabāti!");
        })
    }

    const emptySlot = <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: 10, border: "1px solid #979797", padding: "17px 20px", position: "relative" }}
    >
        {
            loading && <div style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0, backgroundColor: "rgba(209, 209, 209, 0.3)", zIndex: 100 }} >
                <Row justify="center" align="middle" style={{ height: "100%" }}>
                    <Spin />
                </Row>
            </div>
        }
        <Button
            type="link"
            onClick={() => setModalVisible(!modalVisible)}
            disabled={examIsOld}
        >
            {
                examIsOld
                    ? "Pieteikšanās laiks eksāmenam ir beidzies"
                    : "Brīva vieta: Pievienot studentu"
            }
        </Button>
        <div>
            <Select
                style={{ width: 170, marginRight: 10 }}
                mode="multiple"
                value={selectedLanguages}
                onChange={(e: any) => {
                    setSelectedLanguages(e)
                }}
                disabled={examIsOld}
            >
                {
                    languages?.map((lang: any) => {
                        return <Select.Option key={lang.id} value={lang.id}>{lang.iso?.toUpperCase()}</Select.Option>
                    })
                }
            </Select>
            <Button type="primary" icon={<SaveOutlined />} onClick={updateSlotLanguages} />
            {
                examIsOld
                    ? <></>
                    : <Button
                        style={{ marginLeft: 10 }}
                        danger
                        icon={<DeleteOutlined />}
                        onClick={deleteSlot}
                    />
            }
        </div>
        <AddStudentModal
            slot={slot}
            setModalVisible={setModalVisible}
            modalVisible={modalVisible}
            updateSlots={() => updateSlots()}
            categories={categories}
        />
    </Row>

    const generateZoomLink = () => {
        if (zoomAccID !== null) {
            setLoading(true);
            http.post(`rest/zoom-meetings`, {
                zoom_account_id: zoomAccID,
                theory_exam_slot_id: slot.id,
                meeting_topic: "student exam",
                meeting_topic2: `student exam ${slot?.student?.full_name}`,
                meeting_start_time: slot.exam_date + " " + slot.time_from,
                meeting_duration: 60,
                meeting_password: uuid().split("-")[0]
            }).then((response: any) => {
                updateSlots();
            })
        }

    };

    const manualSubmitZoomLink = () => {
        setZoomModalVisible(true);
    }

    useEffect(() => {
        setSelectedLanguages(slot.languages);
    }, [slot]);

    const ManualZoomModal = (props: any) => {
        const { zoomModalVisible, setZoomModalVisible, slot, zoomAccount } = props;
        const [manualZoomLink, setManualZoomLink] = useState("");

        const saveManualZoomLink = () => {
            if (zoomAccount.hasOwnProperty("id") && manualZoomLink.length > 0) {
                setLoading(true);
                http.post(`rest/zoom-meetings`, {
                    zoom_account_id: zoomAccount.id,
                    theory_exam_slot_id: slot.id,
                    meeting_topic: "student exam",
                    meeting_topic2: `student exam ${slot?.student?.full_name}`,
                    meeting_start_time: slot.exam_date + " " + slot.time_from,
                    meeting_duration: 60,
                    meeting_password: uuid().split("-")[0],
                    manual_made: 1,
                    meeting_link: manualZoomLink
                }).then((response: any) => {
                    updateSlots();
                })
            }
        }

        return <Modal
            visible={zoomModalVisible}
            onCancel={() => setZoomModalVisible(false)}
            onOk={() => saveManualZoomLink()}
        >
            <Row>
                <Col span={24}>
                    <b>Lūdzu ievadiet zoom saiti:</b><br />
                    <Input.TextArea
                        value={manualZoomLink}
                        onChange={(e: any) => {
                            e.persist();
                            setManualZoomLink(e.target.value);
                        }}
                    />
                </Col>
            </Row>
        </Modal>
    }

    const switchExamStatus = () => {
        setLoading(true);
        http.post(`rest/fix-fkdup-theory-exam/${slot.id}`, {
            status: slot.status.includes("failed") ? "exam_passed" : "exam_failed"
        }).then((response: any) => {
            updateSlots()
        })
    }

    const allowTest = (allow: 1 | 0) => {
        setLoading(true);
        http.post(`rest/set-allow-exam-by-hash`, { hash: slot.hash, allow_test: allow }).then((response: any) => {
            updateSlots()
        })
    }

    return slot.student_id === null
        ? emptySlot
        : <Row align="middle" style={{ marginBottom: 10, backgroundColor: slot.status !== "student_added" ? "rgb(216 255 229)" : "#D8D8D8", padding: "17px 20px", position: "relative" }}>
            {
                loading && <div style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0, backgroundColor: "rgba(209, 209, 209, 0.3)", zIndex: 100 }} >
                    <Row justify="center" align="middle" style={{ height: "100%" }}>
                        <Spin />
                    </Row>
                </div>
            }
            <Col span={3}>
                <a
                    href={`${process.env.REACT_APP_ADMIN_URL}groups/student-opening/${slot?.student_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={!!slot?.year_limit_warning ? { color: "red" } : {}}
                >
                    {slot?.student?.full_name}
                    {
                        !!slot?.year_limit_warning
                        && <><br /><small color="red">Gads teorijas apmācībai: {moment(slot?.year_limit_date).format("DD.MM.YYYY")}</small></>
                    }
                </a><br />
                {
                    !!slot?.student?.study_group
                        ? <b>{slot?.student?.study_group}</b>
                        : <></>
                }
            </Col>
            <Col span={3}>
                {slot?.student?.category} Kategorija<br />
                {slot?.student?.language}
            </Col>
            <Col span={2}>
                {slot?.price}&euro;&nbsp;
                <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => setEditPriceModalVisible(true)}
                />
                <EditPriceModal
                    visible={editPriceModalVisible}
                    setVisible={(visible: boolean) => setEditPriceModalVisible(visible)}
                    slot={slot}
                    updateSlots={updateSlots}
                />
            </Col>
            <Col span={3}>
                <small>Mēģinajums:</small><br />
                {examTry + 1}
            </Col>
            {
                slot.status === "exam_passed" || slot.status === "exam_failed" || slot.status.includes("cancelled")
                    ? <Col span={6}>
                        <p style={{ margin: 0 }}>
                            {
                                slot.status.includes("cancelled")
                                    ? <b>Eksāmens ir atcelts</b>
                                    : <>
                                        <b>
                                            {
                                                slot.status === "exam_passed"
                                                    ? "Nokārtots"
                                                    : "Nav nokārtots"
                                            }
                                            {
                                                slot.status.includes("exam")
                                                && <Button
                                                    type="ghost"
                                                    style={{ marginLeft: 10 }}
                                                    onClick={() => switchExamStatus()}
                                                >
                                                    Mainīt rezultātu
                                                </Button>
                                            }
                                            <br />
                                            {
                                                slot.exam_results.reduce((prev: any, current: any) => {
                                                    const correctCount = current.correct_count !== null ? current.correct_count : 0
                                                    return prev + correctCount
                                                }, 0)
                                            }/
                                            {
                                                slot.exam_results.reduce((prev: any, current: any) => {
                                                    const questionCount = current.question_count !== null ? current.question_count : 0
                                                    return prev + questionCount
                                                }, 0)
                                            }
                                            <Popover
                                                content={
                                                    slot.exam_results.map((result: any, index: any) => {
                                                        return <div
                                                            style={{
                                                                padding: 20,
                                                                border: "1px solid #c0c0c0",
                                                                borderRadius: 4,
                                                                marginBottom: 4
                                                            }}
                                                            key={index}
                                                        >
                                                            <Row key={index} gutter={24}>
                                                                <Col>
                                                                    <p style={{ marginBottom: 8 }}>Mēģinājums:</p>&nbsp;
                                                                    <b>{examTry + 1}</b>
                                                                </Col>
                                                                <Col>
                                                                    <p style={{ marginBottom: 8 }}>Rezultāts:</p>&nbsp;
                                                                    <b>
                                                                        {result.correct_count !== null ? result.correct_count : 0}/
                                                                        {result.question_count !== null ? result.question_count : 0}
                                                                    </b>
                                                                </Col>
                                                                <Col>
                                                                    <p style={{ marginBottom: 8 }}>Kļūdas:</p>&nbsp;
                                                                    <b style={{ width: "100%", whiteSpace: "normal" }}>{result.mistakes}</b>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    })
                                                }
                                                trigger="hover"
                                                placement="right"
                                            >
                                                <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                            </Popover>
                                        </b>
                                    </>
                            }
                        </p>
                    </Col>
                    : examIsOld || slot.status.includes("cancelled") || slot.status.includes("student_added")
                        ? <></>
                        : <>
                            {/* <Col span={3}>  Q 
                                <small>Zoom konts</small><br />
                                Students 1
                            </Col> */}
                            <Col span={3}>
                                <small>Zoom saite:</small><br />
                                {
                                    slot.zoom_link.length > 0
                                        ? slot.zoom_link[0].success_api === 0 && slot.zoom_link[0].manual_made === 0
                                            ? <>
                                                <small>Konts: {slot.zoom_link[0].name}</small><br />
                                                <b>Saites ģenerēšana nav izdevusies</b>
                                                <Button
                                                    type="link"
                                                    onClick={manualSubmitZoomLink}
                                                >
                                                    Ievadīt Zoom saiti manuāli
                                                </Button>
                                                <ManualZoomModal
                                                    zoomModalVisible={zoomModalVisible}
                                                    setZoomModalVisible={(visible: any) => setZoomModalVisible(visible)}
                                                    slot={slot}
                                                    zoomAccount={zoomAccount}
                                                />
                                            </>
                                            : <>
                                                <small>Konts: {slot.zoom_link[0].name}</small><br />
                                                <Space>
                                                    <a
                                                        href={slot.zoom_link[0].meeting_link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        &nbsp;Studentam
                                                    </a>
                                                    <a
                                                        href={slot.zoom_link[0].host_link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        &nbsp;Pasniedzējam
                                                    </a>
                                                </Space>
                                            </>
                                        : zoomAccID !== null
                                            ? <>
                                                <small>
                                                    Konts: {slot.zoom_link[0]?.name || zoomAccount.find((el: any) => el.id === zoomAccID)?.name}
                                                </small><br />
                                                <Button
                                                    type="link"
                                                    onClick={generateZoomLink}
                                                >
                                                    Ģenerēt linku
                                                </Button>
                                            </>
                                            : <Select
                                                style={{ width: 167, marginLeft: 15, marginRight: 15 }}
                                                placeholder="Zoom konts"
                                                value={zoomAccID}
                                                onChange={(e: any) => {
                                                    setZoomAccID(e)
                                                }}
                                                disabled={zoomAccount.length === 0}
                                            >
                                                {
                                                    zoomAccount.map((account: any, index: any) => {
                                                        return <Select.Option key={index} value={account.id}>{account.name}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                }

                            </Col>
                        </>
            }
            <Col span={7}>
                <Row justify="end">
                    {
                        slot.status === "exam_passed" || slot.status === "exam_failed"
                            ? ""
                            : examIsOld && !slot.status.includes("cancelled")
                                ? <>
                                    <Button
                                        onClick={() => setResultsModalVisible(true)}
                                    >
                                        Ievadīt rezultātus
                                    </Button>
                                    <StudentSettingsModal
                                        modalVisible={resultsModalVisible}
                                        setModalVisible={setResultsModalVisible}
                                        state={"results"}
                                        slot={slot}
                                        student={slot.student}
                                        updateSlots={() => updateSlots()}
                                    />
                                </>
                                : <></>
                    }
                    <Button
                        style={{ marginLeft: 8 }}
                        onClick={() => setSettingsModalVisible(true)}
                    >
                        Atvērt
                    </Button>
                    <Button
                        style={{ marginLeft: 8, marginTop: 5 }}
                        ghost
                        danger
                        onClick={() => allowTest(!!slot.allow_test ? 0 : 1)}
                    // loading={loading}
                    >
                        {!!slot?.allow_test ? "Nerādīt testu" : "Rādīt testu"}
                    </Button>
                    <StudentSettingsModal
                        modalVisible={settingsModalVisible}
                        setModalVisible={setSettingsModalVisible}
                        state={"settings"}
                        slot={slot}
                        student={slot.student}
                        updateSlots={() => updateSlots()}
                    />
                    {
                        examIsOld || slot.status.includes("cancelled")
                            ? <></>
                            : <Button
                                style={{ marginLeft: 10 }}
                                danger
                                icon={<DeleteOutlined />}
                                onClick={deleteSlot}
                            />
                    }

                </Row>
            </Col>
        </Row >
} 