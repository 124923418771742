import { Form, Card, Row, Col, Input, Select, Checkbox, Tag, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { http } from "../../helpers/http";

export const UserForm = (props: any) => {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const [adminChecked, setAdminChecked] = useState(false);
    const [edit, setEdit] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [active, setActive] = useState(0);

    const submitForm = () => {
        form.validateFields().then((values: any) => {
            values.ruler_of_universe = values.ruler_of_universe | 0;
            values.active = active;
            !edit
                ? http.post(`/rest/admins`, values).then((response: any) => {
                    props.refresh();
                    message.success("Lietotājs ir veiksmīgi izveidots")
                }).catch((e: any) => {
                    message.error("Kļūda!")
                })
                : http.patch(`/rest/admins/${props.toEdit.id}`, values).then((response: any) => {
                    props.refresh();
                    message.success("Lietotājs ir saglabāts")
                }).catch((e: any) => {
                    message.error("Kļūda!")
                })
        })
    }

    const getLanguages = () => {
        http.get(`/rest/languages`).then((response: any) => {
            setLanguages(response.data.data);
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            ...props.toEdit,
            forbidden_languages: props.toEdit.bad_lang_ids || [],
            perms: props.toEdit.permission || [],
            active: props.toEdit.active
        })
        if (Object.keys(props.toEdit).length > 0) {
            setEdit(true);
        }
        setAdminChecked(props.toEdit.ruler_of_universe)
    }, [props.toEdit]);

    useEffect(() => {
        getLanguages();
    }, [])

    return (
        <Form
            layout="vertical"
            onFinish={submitForm}
            form={form}
        >
            <Card
                title="Lietotājs"
                style={{ marginBottom: 20 }}
            >
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item name="first_name" label="Vārds" rules={[{ required: true, message: "Obligāts lauks" }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="last_name" label="Uzvārds" rules={[{ required: true, message: "Obligāts lauks" }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="email" label="e-pasts" rules={[{ required: true, message: "Obligāts lauks" }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="password"
                            label={edit ? "Jauna parole" : "Parole"}
                            rules={[{ required: !edit, message: "Obligāts lauks" }]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ display: "flex", flexDirection: "row", alignContent: "start" }}>
                        <p style={{ margin: 0, lineHeight: "32px" }}>Administrātors</p>
                        <Form.Item style={{ margin: "0 10px" }} valuePropName="checked" name="ruler_of_universe" >
                            <Checkbox onChange={(e: any) => setAdminChecked(e.target.checked)} />
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ display: "flex", flexDirection: "row", alignContent: "start" }}>
                        <p style={{ margin: 0, lineHeight: "32px" }}>Aktīvs</p>
                        <Form.Item style={{ margin: "0 10px" }} valuePropName="checked" name="active" >
                            <Checkbox onChange={(e: any) => setActive(e.target.checked ? 1 : 0)} />
                        </Form.Item>
                    </Col>
                    {
                        !adminChecked
                            ? <Col span={6}>
                                <Form.Item name="perms" label="Atļaujas" >
                                    <Select mode="multiple">
                                        {
                                            props.rights.map((perm: any) => {
                                                return <Select.Option value={perm}>{t(`permissions.${perm}`)}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            : <></>
                    }
                    <Col span={6}>
                        <Form.Item name="forbidden_languages" label="Valodas kurās nerunā" >
                            <Select mode="multiple">
                                {
                                    languages?.map((lang: any) => {
                                        return <Select.Option value={lang.id}>{lang.iso.toUpperCase()}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end">
                    <Button htmlType="submit" type="primary">Saglabāt</Button>
                </Row>
            </Card>
        </Form>
    )
}