import React, { useState, useEffect, useRef } from "react";
//@ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
//@ts-ignore
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

type BannerEditorProps = {
    id: string;
    data?: string;
    onChange?: (data: string) => void;
}

export const BannerEditor = ({ ...props }: BannerEditorProps) => {
    const [isLayoutReady, setIsLayoutReady] = useState(false);

    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);

    return <div style={{ marginBottom: 30 }}>
        <div className={`document-editor banner`}>
            <div className={`document-editor__toolbar ${props.id}`}></div>
            <div className={`document-editor__editable-container`}>
                <CKEditor
                    config={{
                        removePlugins: ['BlockQuote', 'MediaEmbed', 'Table', 'TableToolbar', 'TableProperties', 'TableCellProperties', 'FontFamily', 'FontSize', 'FontColor', 'FontBackgroundColor'],
                    }}
                    onReady={(editor: any) => {
                        const toolbarContainer = document.querySelector(`.document-editor__toolbar.${props.id}`);
                        toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
                        const list = Array.from(editor.ui.componentFactory.names())
                    }}
                    onChange={(event: any, editor: any) => {
                        props.onChange && props.onChange(editor.getData());
                    }}
                    editor={DecoupledEditor}
                    data={props.data}
                />
            </div>
        </div>
    </div>
}