import React, { useEffect, useState } from "react";
import { Button, Card, Col, Radio, Row, Space, Table, Tag } from "antd";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useHistory } from "react-router";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { http } from "../../helpers/http";
import { BookInOtherGroup } from "../Groups/LessonsList";

export const MissedLessonsTable = (props: any) => {
    const windowHeight = window.innerHeight;
    const history = useHistory();
    const [data, setData] = useState([] as any);
    const [pagination, setPagination] = useState(
        {
            current: 1,
            pageSize: 15,
            total: null,
        } as any
    )
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [reserved, setReserved] = useState(false);
    const [lesson, setLesson] = useState({} as any);
    const [cities, setCities] = useState([] as any);
    const [categories, setCategories] = useState([] as any);
    const [filters, setFilters] = useState({} as any);
    const [skippedFilter, setSkippedFilter] = useState(undefined as undefined | 1);

    const fetchCities = () => {
        http.get(`rest/cities`).then((response: any) => [
            setCities(response.data.data)
        ])
    };

    const fetchCategories = () => {
        http.get(`rest/categories`).then((response: any) => [
            setCategories(response.data.data)
        ])
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setFilters(filters)
        fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters,
        });
    };

    const fetch = async (params: any = { pagination: { current: 1 } }) => {
        setLoading(true);
        await http.get("/rest/study-groups-skipped-lessons", { params: getParams(params) }).then(result => {
            const { data } = result.data;
            const all = result.data;
            console.log(result)
            setData(data);
            setPagination({
                current: all.current_page,
                pageSize: all.per_page,
                total: all.total,
            })
        });
        setLoading(false);
    };

    const getParams = (params: any) => {
        if (props.online) {
            return {
                page: params.pagination.current,
                sortField: params.sortField,
                sortOrder: params.sortOrder,
                filters: params.filters,
                e_learning: 1,
                skipped_lessons_with_online: skippedFilter
            }
        }
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters
        }
    }

    const shippingCols = props.online
        ? [
            {
                title: "Skaits",
                dataIndex: "skipped",
                fixed: "left",
            },
            {
                title: "Modulis",
                dataIndex: "passed_modules_fancy",
                key: "passed_modules",
                ...getColumnSearchProps(),
            },
            {
                title: "Vārds",
                dataIndex: "first_name",
                key: "st.first_name",
                ...getColumnSearchProps(),
                fixed: "left",
                render: (name: any, record: any) => {
                    return <Button
                        type="link"
                        onClick={() => handleTableChange({ current: 1 }, { "st.id": [record.student_id] }, {})}
                    >
                        {name}
                    </Button>
                }
            },
            {
                title: "Uzvārds",
                dataIndex: "last_name",
                key: "st.last_name",
                ...getColumnSearchProps(),
                fixed: "left",
                render: (name: any, record: any) => {
                    return <Button
                        type="link"
                        onClick={() => handleTableChange({ current: 1 }, { "st.id": [record.student_id] }, {})}
                    >
                        {name}
                    </Button>
                }
            },
            {
                title: "Tālruņa nr.",
                dataIndex: "phone_number",
                key: 'st.phone_number',
                ...getColumnSearchProps(),
                render: (phone: any, record: any) => {
                    return <Button
                        type="link"
                        onClick={() => window.open(`/groups/student-opening/${record.student_id}`, "_blank")}
                    >
                        {phone}
                    </Button>
                }
            },
            {
                title: "E-pasts",
                dataIndex: "email",
                key: 'st.email',
                ...getColumnSearchProps()
            },
            {
                title: "Pilsēta.",
                dataIndex: "cities",
                key: "csg.city_id",
                filters: cities?.map((cat: any) => {
                    return {
                        value: cat.id,
                        text: cat.value
                    }
                })
            },
            {
                title: "Kat.",
                dataIndex: "category",
                key: "sc.category_id",
                align: "center",
                filters: categories?.map((cat: any) => {
                    return {
                        value: cat.id,
                        text: cat.value
                    }
                })
            },
            {
                title: "Lekcija",
                dataIndex: "name",
                key: 'tl.name',
                ...getColumnSearchProps()
            },
            {
                title: "Grupa",
                dataIndex: "csdd_nr",
                key: 'sg.csdd_nr',
                ...getColumnSearchProps()
            },
            {
                title: "Grupas datums",
                dataIndex: "start_date",
                key: 'start_date',
                ...getColumnSearchProps('calendar')
            },
            {
                title: "Darbības",
                dataIndex: 'lesson',
                key: 'actions',
                render: (lesson: any, record: any) => {
                    return <>
                        <Button onClick={(e: any) => {
                            e.stopPropagation()
                            changeGroup(record)
                        }}
                        >
                            Pieteikt lekciju
                        </Button>
                    </>
                },
            },
        ] as any
        : [
            {
                title: "Skaits",
                dataIndex: "skipped",
                fixed: "left",
            },
            {
                title: "Vārds",
                dataIndex: "first_name",
                key: "st.first_name",
                ...getColumnSearchProps(),
                fixed: "left",
                render: (name: any, record: any) => {
                    return <Button
                        style={{ whiteSpace: "normal" }}
                        type="link"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            handleTableChange({ current: 1 }, { "st.id": [record.student_id] }, {})
                        }}
                    >
                        {name}
                    </Button>
                }
            },
            {
                title: "Uzvārds",
                dataIndex: "last_name",
                key: "st.last_name",
                ...getColumnSearchProps(),
                fixed: "left",
                render: (name: any, record: any) => {
                    return <Button
                        style={{ whiteSpace: "normal" }}
                        type="link"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            handleTableChange({ current: 1 }, { "st.id": [record.student_id] }, {})
                        }}
                    >
                        {name}
                    </Button>
                }
            },
            {
                title: "Tālruņa nr.",
                dataIndex: "phone_number",
                key: 'st.phone_number',
                ...getColumnSearchProps(),
                render: (phone: any, record: any) => {
                    return <Button
                        type="link"
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open(`/groups/student-opening/${record.student_id}`, "_blank")
                        }}
                    >
                        {phone}
                    </Button>
                }
            },
            {
                title: "E-pasts",
                dataIndex: "email",
                key: 'st.email',
                ...getColumnSearchProps()
            },
            {
                title: "Kat.",
                dataIndex: "category",
                key: "sc.category_id",
                align: "center",
                filters: categories?.map((cat: any) => {
                    return {
                        value: cat.id,
                        text: cat.value
                    }
                })
            },
            {
                title: "Pilsēta.",
                dataIndex: "cities",
                key: "csg.city_id",
                filters: cities?.map((cat: any) => {
                    return {
                        value: cat.id,
                        text: cat.value
                    }
                })
            },
            {
                title: "Lekcija",
                dataIndex: "name",
                key: 'tl.name',
                ...getColumnSearchProps()
            },
            {
                title: "Veids",
                dataIndex: "is_online",
                key: "is_online",
                render: (is_online: boolean) => {
                    return <Tag
                        color={is_online ? "blue" : "orange"}
                        style={{ marginLeft: 10 }}
                    >
                        {is_online ? "Tiešsaistē" : "Klātienē"}
                    </Tag>
                },
                filters: [
                    { text: "Tiešsaistē", value: 1 },
                    { text: "Klātiene", value: 0 }
                ]
            },
            {
                title: "Grupa",
                dataIndex: "csdd_nr",
                key: 'sg.csdd_nr',
                ...getColumnSearchProps()
            },
            {
                title: "Grupas datums",
                dataIndex: "start_date",
                key: 'start_date',
                ...getColumnSearchProps('calendar')
            },
            {
                title: "Darbības",
                dataIndex: 'lesson',
                key: 'actions',
                render: (lesson: any, record: any) => {
                    return <>
                        <Button onClick={(e: any) => {
                            e.stopPropagation()
                            changeGroup(record)
                        }}
                        >
                            Pieteikt lekciju
                        </Button>
                    </>
                },
            },
        ] as any;

    const changeGroup = (lesson: any) => {
        setLesson(lesson);
        setModalVisible(true);
    }

    useEffect(() => {
        fetchCities();
        fetchCategories();
        // fetch();
    }, []);

    useEffect(() => {
        handleTableChange({ current: 1 }, {}, {})
    }, [skippedFilter])

    return (
        <>
            {
                props.online
                && <Row style={{ marginBottom: 10 }}>
                    <Radio.Group
                        optionType="button"
                        value={skippedFilter}
                        onChange={(e) => {
                            setSkippedFilter(e.target.value)
                        }}
                    >
                        <Radio.Button value={undefined}>Nav pieteikušies klātienes nodarbībām</Radio.Button>
                        <Radio.Button value={1}>Kavē pieteiktās klātienes nodarbības</Radio.Button>
                    </Radio.Group>
                </Row>
            }
            {
                filters.hasOwnProperty("st.id")
                && <Button
                    onClick={() => { fetch(); setFilters({}) }}
                >
                    Atcelt studenta filtru
                </Button>
            }
            <Table
                rowClassName={(record: any) => {
                    return record.deadline_passed
                        ? "red-row"
                        : ""
                }}
                rowKey={record => record.id}
                dataSource={data}
                columns={shippingCols}
                onChange={handleTableChange}
                onRow={(record: any) => {
                    return {
                        onClick: () => {
                            console.log(record)
                            // history.push(`/groups/edit/edit-student/${record.student_id}/${record.study_group_id}`, { active_key: "5" })
                            history.push(`/groups/student-opening/${record.student_id}`, { active_key: "5" })
                        }
                    }
                }}
                loading={loading}
                pagination={{
                    showSizeChanger: false,
                    size: 'small',
                    showTotal: (total) => <div>Kopā {total} atgādinājumi</div>,
                    position: ['bottomCenter'],
                    pageSizeOptions: ['15'],
                    defaultPageSize: 15,
                    ...pagination
                }}
                scroll={{ x: 1050, y: windowHeight - 185 }}
            />
            <BookInOtherGroup
                showModal={modalVisible}
                setShowModal={setModalVisible}
                reserved={setReserved}
                lesson={lesson}
                student={{ id: lesson.student_id }}
                lessonNum={lesson.lesson_counter}
                cities={cities}
            />
        </>
    )
}