import { Button, Card, Checkbox, Col, DatePicker, Input, message, Row, Select, Tabs } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment from "moment";
import React, { SyntheticEvent, useEffect, useState } from "react";
import Avatar from "../ImgUploader";
import { BannerEditor } from "./BannerEditor";
import { useHistory, useParams } from "react-router-dom";
import { http } from "../../helpers/http";

type BannerFormProps = {
    categories: any[];
    languages: any[];
    refresh: () => void;
}

export const BannerForm = ({ ...props }: BannerFormProps) => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [data, setData] = useState({
        exclude_rules: "" as string | null, //string???
        active: 1 as 1 | 0, //Checkbox
        allowed_cats: [] as number[], //Select multiple
        app_link: undefined as string | undefined, //Input
        web_link: undefined as string | undefined, //Input
        available_from: null as string | null, //Date
        available_till: null as string | null, //Date
        banner_popup_lv: undefined as string | undefined, //WYSIWYG multilang
        banner_popup_ru: undefined as string | undefined, //WYSIWYG multilang
        banner_popup_en: undefined as string | undefined, //WYSIWYG multilang
        banner_static_lv: undefined as string | undefined, //WYSIWYG multilang
        banner_static_ru: undefined as string | undefined, //WYSIWYG multilang
        banner_static_en: undefined as string | undefined, //WYSIWYG multilang
        img_popup_lv: null as string | null, //img uploader multilang
        img_popup_ru: null as string | null, //img uploader multilang
        img_popup_en: null as string | null, //img uploader multilang
        img_static_lv: null as string | null, //img uploader multilang
        img_static_ru: null as string | null, //img uploader multilang
        img_static_en: null as string | null, //img uploader multilang
        popup_btn_lv: undefined as string | undefined, //Input multilang
        popup_btn_ru: undefined as string | undefined, //Input multilang
        popup_btn_en: undefined as string | undefined, //Input multilang
        static_btn_lv: undefined as string | undefined, //Input multilang
        static_btn_ru: undefined as string | undefined, //Input multilang
        static_btn_en: undefined as string | undefined, //Input multilang
    });

    const changeData = (key: string, value: any) => {
        console.log(key, value)
        setData((prev: any) => {
            return {
                ...prev,
                [key]: value
            }
        });
    }

    const transformToDate = (date: string | null) => {
        return date ? moment(date, "YYYY-MM-DD") : null
    }

    const getFile = (key: string, file: any) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            changeData(key, reader.result);
        }
        // console.log(file)
        if (file && file?.length > 0) {
            reader.readAsDataURL(file[0].originFileObj);
        }
    }

    const getBannerData = (id: string) => {
        http.get(`rest/banners/${id}`).then((res: any) => {
            setData(res.data.data)
        })
    }

    const saveBannerData = () => {
        if (id) {
            http.patch(`rest/banners/${id}`, data).then((res: any) => {
                message.success("Saglabāts");
                getBannerData(id);
                props.refresh();
            }).catch((err: any) => {
                message.error("Kļūda");
            });
        } else {
            http.post(`rest/banners`, data).then((res: any) => {
                message.success("Saglabāts");
                props.refresh();
                history.goBack();
            }).catch((err: any) => {
                message.error("Kļūda");
            });
        }
    }

    useEffect(() => {
        if (id) {
            getBannerData(id);
        }
    }, [id])

    return <Card>
        <Row style={{ marginBottom: 20 }} align="middle">
            <Checkbox checked={data.active === 1} onChange={(cb: CheckboxChangeEvent) => changeData("active", cb.target.checked ? 1 : 0)}>Aktīvs</Checkbox>
            <DatePicker.RangePicker
                value={[
                    transformToDate(data.available_from),
                    transformToDate(data.available_till)
                ]}
                onChange={(dates: any) => {
                    changeData("available_from", dates[0]);
                    changeData("available_till", dates[1]);
                }}
                format={"DD.MM.YYYY"}
            />
        </Row>
        <Row>
            <div style={{ marginRight: 20 }}>
                <p><b>Atļautas kategorijas</b></p>
                <Select
                    value={data.allowed_cats}
                    mode="multiple"
                    style={{ width: 335 }}
                    onChange={(value: any) => changeData("allowed_cats", value)}
                >
                    {
                        props.categories.map((cat: any) => {
                            return <Select.Option key={cat.id} value={cat.id}>{cat.value}</Select.Option>
                        })
                    }
                </Select>
            </div>
        </Row>
        <Tabs>
            {
                props.languages?.map((lang: any) => {
                    return <Tabs.TabPane key={lang.id} tab={lang.value}>
                        <Row style={{ marginBottom: 20 }}>
                            <div style={{ marginRight: 40 }}>
                                <p><b>Uznirstošā loga banera attēls</b></p>
                                {
                                    (data[`img_popup_${lang.iso}` as keyof typeof data] as string)?.includes("storage") && <img src={`${process.env.REACT_APP_API_URL}${data[`img_popup_${lang.iso}` as keyof typeof data]}` as string} alt="Banner" style={{ width: 300, marginBottom: 20 }} />
                                }
                                <Avatar
                                    getImg={(file: any) => console.log(`img_popup_${lang.iso}`, file)}
                                    returnFile={(file: File) => getFile(`img_popup_${lang.iso}`, file)}
                                    aspect={16 / 9}
                                />
                            </div>
                            <div>
                                <p><b>Statiskā banera attēls</b></p>
                                {
                                    (data[`img_static_${lang.iso}` as keyof typeof data] as string)?.includes("storage") && <img src={`${process.env.REACT_APP_API_URL}${data[`img_static_${lang.iso}` as keyof typeof data]}` as string} alt="Banner" style={{ width: 300, marginBottom: 20 }} />
                                }
                                <Avatar
                                    getImg={(file: any) => console.log(`img_static_${lang.iso}`, file)}
                                    returnFile={(file: File) => getFile(`img_static_${lang.iso}`, file)}
                                    aspect={16 / 9}
                                />
                            </div>
                        </Row>
                        <Row style={{ marginBottom: 20 }}>
                            <div style={{ marginRight: 40 }}>
                                <p><b>Uznirstošā loga banera pogas teksts</b></p>
                                <Input
                                    style={{ width: 335 }}
                                    value={data[`popup_btn_${lang.iso}` as keyof typeof data] as string | undefined}
                                    onChange={(e: SyntheticEvent) => changeData(`popup_btn_${lang.iso}`, (e.target as HTMLInputElement).value)}
                                />
                            </div>
                            <div style={{ marginRight: 40 }}>
                                <p><b>Statiskā banera pogas teksts</b></p>
                                <Input
                                    style={{ width: 335 }}
                                    value={data[`static_btn_${lang.iso}` as keyof typeof data] as string | undefined}
                                    onChange={(e: SyntheticEvent) => changeData(`static_btn_${lang.iso}`, (e.target as HTMLInputElement).value)}
                                />
                            </div>
                        </Row>
                        <Row style={{ marginBottom: 20 }}>
                            <div style={{ marginRight: 20 }}>
                                <p><b>Pogas links APP</b></p>
                                <Input
                                    style={{ width: 335 }}
                                    value={data[`app_link_${lang.iso}` as keyof typeof data] as string | undefined}
                                    onChange={(e: SyntheticEvent) => changeData(`app_link_${lang.iso}`, (e.target as HTMLInputElement).value)}
                                />
                            </div>
                            <div style={{ marginRight: 20 }}>
                                <p><b>Pogas links WEB</b></p>
                                <Input
                                    style={{ width: 335 }}
                                    value={data[`web_link_${lang.iso}` as keyof typeof data] as string | undefined}
                                    onChange={(e: SyntheticEvent) => changeData(`web_link_${lang.iso}`, (e.target as HTMLInputElement).value)}
                                />
                            </div>
                        </Row>
                        <Row gutter={20}>
                            <Col>
                                <p><b>Uznirstošā loga banera formatēts teksts</b></p>
                                <BannerEditor
                                    id={`${lang.iso}_1`}
                                    data={data[`banner_popup_${lang.iso}` as keyof typeof data] as string | undefined}
                                    onChange={(data: any) => changeData(`banner_popup_${lang.iso}`, data)}
                                />
                            </Col>
                            <Col>
                                <p><b>Statiskā banera teksts</b></p>
                                <BannerEditor
                                    id={`${lang.iso}_2`}
                                    data={data[`banner_static_${lang.iso}` as keyof typeof data] as string | undefined}
                                    onChange={(data: any) => changeData(`banner_static_${lang.iso}`, data)}
                                />
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                })
            }
        </Tabs>
        <Row>
            <Button
                type="primary"
                onClick={() => { saveBannerData() }}
            >
                Saglabāt
            </Button>
        </Row>
    </Card>
}