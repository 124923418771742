import { Row, Col, Select, Checkbox, DatePicker, Input } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SelectValue } from "antd/lib/select";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { StudentFilter } from "./StudentSpamFilterLayout";

export const DrivingFilterForm = (props: any) => {
    const { studentFilter, setStudentFilter, classifiers } = props;
    const [instructors, setInstructors] = useState([] as any);

    const setDrivingFilter = (data: StudentFilter) => {
        setStudentFilter((prev: StudentFilter) => {
            return {
                ...prev,
                ...data
            }
        })
    };

    const removeFilter = (key: string) => {
        setStudentFilter((prev: any) => {
            const copy = JSON.parse(JSON.stringify(prev));
            delete copy[key];
            return copy;
        });
    };

    const fetchInstructors = () => {
        http.get(`rest/get-all-instructors-name-email-id`).then((response: any) => {
            setInstructors(response.data)
        })
    };

    useEffect(() => {
        fetchInstructors();
    }, [])

    return <Row gutter={20}>
        <Col>
            <p style={{ marginBottom: 4 }}>Instruktors</p>
            <Select
                value={studentFilter?.instructor ? studentFilter?.instructor[0] : undefined}
                style={{ width: 200, marginBottom: 20 }}
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("instructor");
                        return;
                    }
                    setDrivingFilter({ instructor: [e] })
                }}
                filterOption={(input: any, option: any) => {
                    console.log(option)
                    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                }}
                showSearch
            >
                {
                    studentFilter.instructor !== undefined
                        ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                        : <></>
                }
                {
                    instructors.map((instructor: any) => {
                        return <Select.Option
                            value={instructor.id}
                            key={instructor.id}
                        >
                            {`${instructor.first_name} ${instructor.last_name}, ${instructor.email}`}
                        </Select.Option>
                    })
                }
            </Select>
            <p style={{ marginBottom: 4 }}>Pilsēta, kur brauc</p>
            <Select
                value={studentFilter.driving_city}
                style={{ width: 200, marginBottom: 20 }}
                mode="multiple"
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("driving_city");
                        return;
                    }
                    setDrivingFilter({ driving_city: e })
                }}
            >
                {
                    classifiers?.cities?.map((city: any, index: any) => {
                        return <Select.Option key={index} value={city.id}>{city.value}</Select.Option>
                    })
                }
            </Select>
            <p style={{ marginBottom: 4 }}>Pārnesumkārba</p>
            <Select
                value={studentFilter.transmission}
                style={{ width: 200, marginBottom: 20 }}
                mode="multiple"
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("transmission");
                        return;
                    }
                    setDrivingFilter({ transmission: e })
                }}
            >
                <Select.Option value={"manual"}>Manuālā</Select.Option>
                <Select.Option value={"auto"}>Automātiskā</Select.Option>
            </Select>
        </Col>
        <Col>
            <p style={{ marginBottom: 4 }}>Ir braucis</p>
            <Select
                value={studentFilter.has_driven}
                style={{ width: 200, marginBottom: 20 }}
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("has_driven");
                        return;
                    }
                    setDrivingFilter({ has_driven: e });
                }}
            >
                {
                    studentFilter.has_driven !== undefined
                        ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                        : <></>
                }
                <Select.Option value={1}>Jā</Select.Option>
                <Select.Option value={0}>Nē</Select.Option>
            </Select>
            <p style={{ marginBottom: 4 }}>Ir novirzīts CSDD braukšanas eks.</p>
            <Select
                value={studentFilter.sent_to_driving_csdd}
                style={{ width: 200, marginBottom: 20 }}
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("sent_to_driving_csdd");
                        return;
                    }
                    setDrivingFilter({ sent_to_driving_csdd: e });
                }}
            >
                {
                    studentFilter.sent_to_driving_csdd !== undefined
                        ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                        : <></>
                }
                <Select.Option value={1}>Jā</Select.Option>
                <Select.Option value={0}>Nē</Select.Option>
            </Select>
            <p style={{ marginBottom: 4 }}>Individuālā braukšana</p>
            <Select
                value={studentFilter.individual_driving}
                style={{ width: 200, marginBottom: 20 }}
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("individual_driving");
                        return;
                    }
                    setDrivingFilter({ individual_driving: e });
                }}
            >
                {
                    studentFilter.individual_driving !== undefined
                        ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                        : <></>
                }
                <Select.Option value={1}>Jā</Select.Option>
                <Select.Option value={0}>Nē</Select.Option>
            </Select>
        </Col>
        <Col>
            <p style={{ marginBottom: 4 }}>Studenta braukšanas datums</p>
            <DatePicker.RangePicker
                style={{ marginBottom: 20 }}
                value={
                    studentFilter.driving_from
                        ? [
                            moment(studentFilter.driving_from, "YYYY-MM-DD"),
                            moment(studentFilter.driving_till, "YYYY-MM-DD"),
                        ]
                        : null
                }
                onChange={(date: any) => {
                    if (!date) {
                        removeFilter("driving_from");
                        removeFilter("driving_till");
                        return;
                    }
                    setDrivingFilter({
                        driving_from: date[0].format("YYYY-MM-DD"),
                        driving_till: date[1].format("YYYY-MM-DD"),
                    })
                }}
            />
            <div style={{ border: "1px solid #f0f0f0", borderRadius: 5, padding: 10 }}>
                <p style={{ marginBottom: 4 }}>Pārtraucis braukt</p>
                <Select
                    value={studentFilter.stopped_driving}
                    style={{ width: 200, marginBottom: 20 }}
                    onChange={(e: any) => {
                        if (e.length === 0) {
                            removeFilter("stopped_driving");
                            return;
                        }
                        setDrivingFilter({ stopped_driving: e });
                    }}
                >
                    {
                        studentFilter.stopped_driving !== undefined
                            ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                            : <></>
                    }
                    <Select.Option value={1}>Jā</Select.Option>
                    <Select.Option value={0}>Nē</Select.Option>
                </Select>
                <p style={{ marginBottom: 4 }}>Cik ilgi nav braucis (intervāls)</p>
                No:&nbsp;
                <Input
                    value={studentFilter.didnt_drive_from}
                    type="number"
                    style={{ width: 80, marginBottom: 20, marginRight: 10 }}
                    onChange={(e: any) => {
                        if (e.target.value.length === 0) {
                            removeFilter("didnt_drive_from");
                            return;
                        }
                        setDrivingFilter({ didnt_drive_from: Number(e.target.value) });
                    }}
                />
                Līdz:&nbsp;
                <Input
                    value={studentFilter.didnt_drive_to}
                    type="number"
                    style={{ width: 80, marginBottom: 20 }}
                    onChange={(e: any) => {
                        if (e.target.value.length === 0) {
                            removeFilter("didnt_drive_to");
                            return;
                        }
                        setDrivingFilter({ didnt_drive_to: Number(e.target.value) });
                    }}
                />
            </div>
        </Col>
    </Row>
}