import { Button, Calendar, message, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { http } from '../../helpers/http';

type TheoryLessonsCalendarProps = {
    selectedDates: any;
    lessons: any;
    saveLessons: any;
    groupID: any;
    setSavingLessons: any;
};

export const TheoryLessonsCalendar = ({ ...props }: TheoryLessonsCalendarProps) => {
    const [selectedDates, setSelectedDates] = useState(props.selectedDates as any);
    const [visible, setVisible] = useState(false);
    const [fakeAsyncCounterTrigger, setFakeAsyncCounterTrigger] = useState(0);
    const [fakeAsyncCounter, setFakeAsyncCounter] = useState(0);

    const handleDaySelection = (date: any) => {
        if (selectedDates?.some((d: any) => d.isSame(date, "day"))) {
            let newSelectedDates = [] as any;
            for (let i = 0; i < selectedDates.length; i++) {
                if (!selectedDates[i].isSame(date, "day")) {
                    newSelectedDates.push(selectedDates[i]);
                } else {
                    break;
                }
            }
            if (newSelectedDates.length === 0) {
                setSelectedDates(undefined);
                return;
            }
            newSelectedDates = newSelectedDates.sort((a: any, b: any) => {
                console.log("DIFF", a.diff(b));
                return a.diff(b)
            });
            setSelectedDates(newSelectedDates);
            return;
        }
        if (selectedDates?.length === props.lessons.length) return;
        if (!selectedDates) {
            setSelectedDates([date]);
            return;
        }
        setSelectedDates([...selectedDates, date].sort((a: any, b: any) => a.diff(b)));
    };

    const saveLessonsBulk = () => {
        props.setSavingLessons(true);
        props.lessons.forEach((lesson: any, index: number) => {
            const newLesson = {
                lesson: lesson.id,
                id: lesson.id,
                name: lesson.name,
                lesson_date: selectedDates[index]
                    ? selectedDates[index].format("YYYY-MM-DD")
                    : undefined,
                start_end_time: lesson.time_from
                    ? [moment(lesson.time_from, "HH:mm").format("HH:mm"), moment(lesson.time_to, "HH:mm").format("HH:mm")]
                    : [undefined, undefined],
                comment_lv: lesson.comment_lv,
                comment_en: lesson.comment_en,
                comment_ru: lesson.comment_ru,
                is_online: lesson.is_online,
                address: lesson.address,
                zoom_link: lesson.zoom_link,
                lecturers: lesson.lecturers
            }
            http.post(`/rest/study-groups/add-lesson/${props.groupID}`, newLesson).then((response) => {
                // message.success(`${index + 1}. nodarbība ir saglabāta`);
                setFakeAsyncCounterTrigger(index + 1);
                props.saveLessons(response.data)
            }).catch(() => {
                setFakeAsyncCounterTrigger(index + 1);
                message.error(`${index + 1}. nodarbību neizdevās saglabāt`);
            })
        });
    }

    // useEffect(() => {
    //     console.log("selectedDates", selectedDates);
    //     console.log("props.lessons", props.lessons);
    // }, [selectedDates, props.lessons]);

    useEffect(() => {
        if (fakeAsyncCounterTrigger === 0) return;
        setFakeAsyncCounter(fakeAsyncCounter + 1);
    }, [fakeAsyncCounterTrigger]);

    useEffect(() => {
        if (fakeAsyncCounter === 0) {
            return;
        }
        if (fakeAsyncCounter === props.lessons.length) {
            message.success("Nodarbības ir saglabātas");
            props.setSavingLessons(false);
            setFakeAsyncCounter(0);
            return;
        }
    }, [fakeAsyncCounter])

    return <div>
        <Button
            onClick={() => {
                setVisible(true);
            }}
        >
            Kalendārs
        </Button>
        <Modal
            title="Izvēlieties nodarbību dienas"
            visible={visible}
            width={1100}
            onOk={() => {
                saveLessonsBulk();
                setVisible(false);
            }}
            onCancel={() => {
                setVisible(false);
            }}
        >
            <div
                style={{
                    width: 1000,
                    // height: 400,
                }}
            >
                <Calendar
                    fullscreen={true}
                    defaultValue={selectedDates ? selectedDates[0] : undefined}
                    dateCellRender={(date) => {
                        const index = selectedDates?.findIndex((d: any) => d.isSame(date, "day"));
                        return <div
                            onClick={() => handleDaySelection(date)}
                            style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: selectedDates?.some((d: any) => d.isSame(date, "day")) ? "green" : "white",
                                color: "white",
                                borderRadius: 3,
                                padding: 5,
                            }}
                        >
                            <p>{index + 1}.</p>
                            <p>{props.lessons[index]?.name}</p>
                        </div>
                    }}
                />
            </div>
        </Modal>
    </div>
};