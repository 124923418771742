import { Button, Select } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";

export const ChangeRevenueForm = (props: any) => {
    const {
        item,
        users,
        noRevenue,
        sgID,
        studentID,
        categoryID,
        disabled
    } = props;
    const [changed, setChanged] = useState(-1);
    const [admin, setAdmin] = useState(item?.admin_id);
    const [loading, setLoading] = useState(false);
    const revenue = item?.id;

    const changeAdminRevenue = () => {
        setLoading(true);
        if (noRevenue) {
            http.post(`rest/create-admin-revenue/${studentID}/${sgID}`, { admin_id: admin, category: categoryID }).then((response: any) => {
                console.log(response)
                setLoading(false);
                setChanged(0)
            })
            return;
        }
        http.post(`/rest/change-admin-revenue/${revenue}`, { admin_id: admin }).then((response: any) => {
            console.log(response)
            setLoading(false);
            setChanged(0)
        })
    }

    useEffect(() => {
        setChanged(changed + 1);
    }, [admin])

    return <div style={{ marginBottom: 10, display: "flex", justifyContent: "end" }}>
        <b>Piesaistījis ({item?.csdd_nr}):&nbsp;</b>
        <Select
            value={admin}
            onChange={(e: any) => {
                setAdmin(e)
            }}
            style={{ width: 200 }}
            disabled={disabled}
        >
            {
                users?.map((user: any) => {
                    return <Select.Option key={user.id} value={user.id}>{user.first_name} {user.last_name} {user.active === 0 ? "(NEAKTĪVS)" : ""}</Select.Option>
                })
            }
        </Select>
        {
            changed
                ? <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    style={{ marginLeft: 8 }}
                    onClick={changeAdminRevenue}
                    loading={loading}
                />
                : <></>
        }
    </div>
}