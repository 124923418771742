

import { message, Button, Card, Table, Layout, Modal, PageHeader, Tabs, Row, Col, Spin, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { http } from "../../helpers/http";
import { AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";


export const DrivingSync = () => {
    const history = useHistory();
    const [data, setData] = useState([] as any);
    const [pagination, setPagination] = useState({} as any);
    const [loading, setLoading] = useState(false);
    // const [lessonsToSync, setLessonsToSync] = useState([] as any);
    const [problematicLessons, setProblematicLessons] = useState([] as any);
    const [problemPagination, setProblemPagination] = useState({} as any);
    const [categories, setCategories] = useState([] as any);
    const [expandedRows, setExpandedRows] = useState([] as any);
    const [problematicExpandedRows, setProblematicExpandedRows] = useState([] as any);
    const [detalisationLoading, setDetalisationLoading] = useState(false);
    const [period, setPeriod] = useState(null as any);
    const [instructorDetalisation, setInstructorDetalisation] = useState({
        csdd: null,
        system: null
    } as any);

    const fetchCategories = () => {
        http.get("rest/categories").then((res: AxiosResponse) => {
            setCategories(res.data.data);
        }).catch(() => {
            message.error("Kļūda ielādējot kategorijas!")
        })
    }

    const fetchProblematicDriving = (params: any = { pagination: { current: 1 } }) => {
        setLoading(true);
        http.get(`rest/csdd-driving-summary`, { params: getParams(params) }).then((res: AxiosResponse) => {
            console.log(res.data.data, "DRIVING SUMMARY")
            setProblematicExpandedRows([]);
            setLoading(false);
            setProblematicLessons(res.data.data);
            setProblemPagination({
                current: Number(res.data.page),
                pageSize: 15,
                total: res.data.total
            })
        }).catch((err: any) => {
            message.error("Kļūda ielādējot braukšanas datus!")
            setLoading(false);
        })
    }

    const fetchDrivingCompare = (params: any = { pagination: { current: 1 } }) => {
        setLoading(true);
        http.get(`rest/csdd-driving-compare`, { params: getParams(params) }).then((res: any) => {
            setExpandedRows([]);
            setLoading(false);
            setData(res.data.data)
            setPagination({
                current: Number(res.data.page),
                pageSize: 15,
                total: res.data.total
            })
        }).catch(() => {
            message.error("Kļūda!")
            setLoading(false);
        })
    }

    const fetchDrivingCounter = () => {
        setLoading(true);
        let fetchPeriod: any[];
        if (period === null) {
            fetchPeriod = [undefined, undefined]
        } else {
            fetchPeriod = [period[0].format("YYYY-MM-DD"), period[1].format("YYYY-MM-DD")]
        }
        http.get(`rest/get-csdd-driving-counter`, {
            params: {
                from: fetchPeriod[0],
                to: fetchPeriod[1]
            }
        }).then((res: AxiosResponse) => {
            console.log(res.data)
            setInstructorDetalisation(res.data)
            setLoading(false);
        }).catch((err: any) => {
            message.error("Kļūda ielādējot braukšanas datus!")
            setLoading(false);
        })
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const filtersCopy = { ...filters }
        if (filtersCopy['kt.sum']) {
            filtersCopy['kt.sum'] = filtersCopy['kt.sum'][0]
        }
        fetchDrivingCompare({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters: filtersCopy,
        });
    };

    const handleProblemTableChange = (pagination: any, filters: any, sorter: any) => {
        const filtersCopy = { ...filters }
        if (filtersCopy['kt.sum']) {
            filtersCopy['kt.sum'] = filtersCopy['kt.sum'][0]
        }
        fetchProblematicDriving({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters: filtersCopy,
        });
    };

    const getParams = (params: any) => {
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters
        }
    };

    const getStudentDetalisation = (studentId: number, categoryId: number) => {
        setDetalisationLoading(true);
        http.get(`rest/csdd-driving-detalization/${studentId}/${categoryId}`).then((res: AxiosResponse) => {
            setDetalisationLoading(false);
            setProblematicLessons((prev: any) => {
                return prev.map((item: any) => {
                    if (item.student_id === studentId && item.category_id === categoryId) {
                        return {
                            ...item,
                            lessonsToSync: res.data.data
                        }
                    }
                    return item;
                })
            })
        }).catch((err: any) => {
            message.error("Kļūda ielādējot studenta braukšanu datus!")
            setDetalisationLoading(false);
        });
    }

    const getStudentDrivingsToSync = (record: any) => {
        setDetalisationLoading(true);
        http.get(`rest/csdd-driving-compare/${record.student_id}`, { params: { driving_date: record.driving_date } }).then((res: AxiosResponse) => {
            setDetalisationLoading(false);
            setData((prev: any) => {
                return prev.map((item: any) => {
                    if (item.id === record.id) {
                        return {
                            ...item,
                            lessonsToSync: res.data.data.map((item: any) => { return { ...item, csdd_lesson_id: record.id } })
                        }
                    }
                    return item;
                })
            })
        }).catch((err: any) => {
            message.error("Kļūda ielādējot studenta braukšanu datus!")
            setDetalisationLoading(false);
        });
    }

    const syncDriving = (recordToSyncWith: any) => {
        Modal.confirm({
            title: 'Vai tiešām vēlaties sinhronizēt šo nodarbību?',
            onOk: () => {
                http.get(`rest/set-csdd-driving-sync/${recordToSyncWith.csdd_lesson_id}/${recordToSyncWith.student_lesson_id}/${recordToSyncWith.instructor_id}/${recordToSyncWith.instructor_service_id}/${recordToSyncWith.academic_hours}`).then((res: AxiosResponse) => {
                    setData((prev: any) => {
                        return prev.map((item: any) => {
                            if (item.id === recordToSyncWith.csdd_lesson_id) {
                                return {
                                    ...item,
                                    synchronized: 1
                                }
                            }
                            return item;
                        })
                    })
                    message.success("Braukšanas nodarbība ir veiksmīgi sinhronizēta!")
                }).catch((err: any) => {
                    message.error("Kļūda sinhronizējot braukšanas nodarbību!")
                })
            }
        })
    }

    const columns = [
        {
            title: 'Sinhronizēts',
            dataIndex: 'synchronized',
            key: 'synchronized',
            align: 'center',
            render: (sync: 1 | 0, record: any) => {
                return `${!!sync ? "Jā" : "Nē"}`
            },
            filters: [
                {
                    text: 'Jā',
                    value: 1,
                },
                {
                    text: 'Nē',
                    value: 0,
                }
            ]
        },
        {
            title: 'Datums',
            dataIndex: 'driving_stamp',
            key: 'driving_stamp',
            // render: (record: any) => {
            //     return record?.split(" ")[0]
            // },
            ...getColumnSearchProps("calendar")
        },
        {
            title: 'Akad. st.',
            dataIndex: 'academic_hours',
            key: 'academic_hours',
            align: 'center',
        },
        {
            title: 'Students',
            dataIndex: 'student',
            key: 'student',
            ...getColumnSearchProps()
        },
        {
            title: 'Kategorija',
            dataIndex: 'category',
            key: 'category_id',
            align: 'center',
            filters: categories.map((item: any) => {
                return {
                    text: item.value,
                    value: item.id
                }
            }),
        },
        {
            title: 'Instruktors',
            dataIndex: 'instructor',
            key: 'instructor',
            ...getColumnSearchProps()
        },
        {
            title: 'Studenta Tālrunis',
            dataIndex: 'student_phone',
            key: 'student_phone',
            ...getColumnSearchProps()
        },
        {
            title: 'Studenta Epasts',
            dataIndex: 'student_email',
            key: 'student_email',
            ...getColumnSearchProps()
        },
        {
            title: 'Studenta pers.k.',
            dataIndex: 'person_code',
            key: 'person_code',
        }
    ]

    const expandedTableCols = [
        {
            title: 'Students',
            dataIndex: 'student',
            key: 'student',
        },
        {
            title: 'Instruktors',
            dataIndex: 'instructor',
            key: 'instructor',
        },
        {
            title: 'Datums/Laiks',
            dataIndex: 'driving_stamp',
            key: 'driving_stamp',
        },
        {
            title: 'Akad. st.',
            dataIndex: 'academic_hours',
            key: 'academic_hours',
            align: 'center',
        },
        {
            align: "center",
            render: (record: any) => {
                return <Button
                    type={"primary"}
                    onClick={() => {
                        syncDriving(record)
                    }}
                >
                    Sasiet
                </Button>
            }
        }
    ]

    const problematicColumns = [
        {
            title: 'Students',
            dataIndex: 'student',
            key: 'student',
            ...getColumnSearchProps(),
            render: (student: any, record: any) => {
                return <Button
                    type="link"
                    onClick={() => { window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}students/edit/${record.student_id}`, "_blank") }}

                >
                    {student}
                </Button >
            }
        },
        {
            title: 'Kategorija',
            dataIndex: 'category',
            key: 'category_id',
            align: 'center',
            filters: categories.map((item: any) => {
                return {
                    text: item.value,
                    value: item.id
                }
            }),
        },
        {
            title: 'Skolā (Akad. st.)',
            dataIndex: 'sys_hours',
            key: 'sys_hours',
            align: 'center',
        },
        {
            title: 'CSDD (Akad. st.)',
            dataIndex: 'csdd_hours',
            key: 'csdd_hours',
            align: 'center',
        },
        {
            title: 'Studenta Tālrunis',
            dataIndex: 'student_phone',
            key: 'student_phone',
            ...getColumnSearchProps()
        },
        {
            title: 'Studenta Epasts',
            dataIndex: 'student_email',
            key: 'student_email',
            ...getColumnSearchProps()
        },
        {
            title: 'Studenta pers.k.',
            dataIndex: 'person_code',
            key: 'person_code',
        }
    ]

    const problematicExpandedTableCols = [
        {
            title: 'VRN',
            dataIndex: 'licence_plate',
            key: 'instructor',
        },
        {
            title: 'Instruktors',
            dataIndex: 'instructor',
            key: 'instructor',
        },
        {
            title: 'Datums/Laiks',
            dataIndex: 'driving_stamp',
            key: 'driving_stamp',
        },
        {
            title: 'Akad. st.',
            dataIndex: 'academic_hours',
            key: 'academic_hours',
            align: 'center',
        },
    ]

    const instructorCols = [
        {
            title: 'Instruktors',
            dataIndex: 'instructor',
        },
        {
            title: 'Sistēmā',
            dataIndex: 'cunter',
            align: 'center',
        },
        {
            title: 'CSDD',
            dataIndex: 'id',
            align: 'center',
            render: (id: number) => {
                const csdd = instructorDetalisation.csdd.find((item: any) => item.instructor_id === id);
                return csdd ? csdd.cunter : "Nav datu"
            }
        },
    ]

    useEffect(() => {
        fetchDrivingCompare();
        fetchProblematicDriving();
        fetchCategories();
    }, []);

    useEffect(() => {
        fetchDrivingCounter();
    }, [period])

    return <Layout>
        <PageHeader
            onBack={() => history.goBack()}
            title={"Braukšanas nodarbību sinhronizācija ar CSDD datiem"}
            style={{ backgroundColor: "white" }}
        />
        <Card style={{ marginTop: 30 }}>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="CSDD braucienu detalizācija" key="1">
                    <Table
                        rowKey={record => record.id}
                        dataSource={data}
                        columns={columns as any}
                        pagination={pagination}
                        onChange={handleTableChange}
                        loading={loading}
                        expandable={{
                            rowExpandable: (record: any) => {
                                return record.synchronized === 0
                            },
                            expandedRowKeys: expandedRows,
                            expandedRowRender: (record: any) => {
                                return detalisationLoading
                                    ? <Spin size="large" />
                                    : <Table
                                        rowKey={record => record.id + record.driving_stamp}
                                        dataSource={record.lessonsToSync ?? []}
                                        columns={expandedTableCols as any}
                                        pagination={false}
                                    />
                            },
                            onExpand(expanded, record) {
                                if (expanded) {
                                    getStudentDrivingsToSync(record)
                                    setExpandedRows((prev: any) => {
                                        return [...prev, record.id]
                                    })
                                } else {
                                    setExpandedRows((prev: any) => {
                                        return prev.filter((item: any) => item !== record.id)
                                    })
                                }
                                console.log(expanded, record, "EXPANDED")
                            },
                        }}
                        scroll={{ x: "100%" }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Problemātiskās braukšanas" key="2">
                    <Table
                        rowKey={record => record.student_id}
                        dataSource={problematicLessons}
                        columns={problematicColumns as any}
                        pagination={problemPagination}
                        onChange={handleProblemTableChange}
                        loading={loading}
                        expandable={{
                            rowExpandable: (record: any) => {
                                return true
                            },
                            expandedRowKeys: problematicExpandedRows,
                            expandedRowRender: (record: any) => {
                                return detalisationLoading
                                    ? <Spin size="large" />
                                    : <Row>
                                        <Col span={12}>
                                            <h3>Skolā</h3>
                                            <Table
                                                rowKey={record => record.student_id + " " + record.category_id}
                                                dataSource={record.lessonsToSync ? record.lessonsToSync.filter((item: any) => item.source === "sys") : []}
                                                columns={problematicExpandedTableCols as any}
                                                pagination={false}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <h3>CSDD</h3>
                                            <Table
                                                rowKey={record => record.student_id + " " + record.category_id}
                                                dataSource={record.lessonsToSync ? record.lessonsToSync.filter((item: any) => item.source === "csdd") : []}
                                                columns={problematicExpandedTableCols as any}
                                                pagination={false}
                                            />
                                        </Col>
                                    </Row>
                            },
                            onExpand(expanded, record) {
                                if (expanded) {
                                    getStudentDetalisation(record.student_id, record.category_id)
                                    setProblematicExpandedRows((prev: any) => {
                                        return [...prev, record.student_id]
                                    })
                                } else {
                                    setProblematicExpandedRows((prev: any) => {
                                        return prev.filter((item: any) => item !== record.student_id)
                                    })
                                }
                                console.log(expanded, record, "EXPANDED")
                            },
                        }}
                        scroll={{ x: "100%" }}

                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Instruktoru nodarbību sinrhonizācija" key="3">
                    <Card
                        title={<Row>
                            <DatePicker.RangePicker
                                value={period}
                                onChange={(value) => setPeriod(value)}
                            />
                        </Row>}
                    >
                        <p><b>*Cik ir bijušas notikušas nodarbības šajā laika periodā sistēmā un cik nodarbības reģistrētas csdd</b></p>
                        <Table
                            rowKey={record => record.id}
                            dataSource={instructorDetalisation.system ? instructorDetalisation.system : []}
                            columns={instructorCols as any}
                            loading={loading}
                            pagination={false}
                        />
                    </Card>
                </Tabs.TabPane>
            </Tabs>
        </Card>
    </Layout>
}
