import { Button, DatePicker, Input, message, Modal, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import moment, { Moment } from "moment";
import { ChooseTemplateModal } from "../Notifications/ChooseTemplateModal";

export const MultiChannelLanguagesSpammer = (props: any) => {
    const {
        languages,
        students,
        template,
        templateForEdit,
        resetTemplateForEdit,
        refresh
    } = props;
    const [spam, setSpam] = useState({} as any);
    const [sending, setSending] = useState(false);
    const [dateTill, setDateTill] = useState(moment().add(7, "days") as Moment);
    const [title, setTitle] = useState("");
    const [hash, setHash] = useState(null as any);
    const channels = ["db", "email", "sms", "whatsapp", "fcm"] as any;
    const channelsTranslation = {
        db: "Portāls",
        email: "E-pasts",
        sms: "SMS",
        whatsapp: "Whatsapp",
        fcm: "Push paziņojums APP"
    } as any;

    const sendSpam = () => {
        setSending(true);
        const copyOfSpam = JSON.parse(JSON.stringify(spam))
        console.log(copyOfSpam)
        Object.keys(copyOfSpam).forEach(key => {
            if (hash || template) return;
            if (key === "title") {
                delete copyOfSpam.title;
                return;
            }
            copyOfSpam[key].date_till = dateTill.format("YYYY-MM-DD")
        })
        let toSend = {
            messages: copyOfSpam
        } as any;
        if (template) {
            toSend = {
                ...toSend,
                title
            }
            if (hash) {
                toSend = {
                    ...toSend,
                    hash
                }
                delete toSend.messages.title
            }
        } else {
            toSend = {
                ...toSend,
                students
            }
        }
        Modal.confirm({
            onOk: () => {
                http.post(`rest/custom-notification-for-channels`, {
                    ...toSend
                }).then(() => {
                    message.success(
                        template
                            ? "Paziņojums ir saglabāts!"
                            : "Paziņojumi ir nosūtīti!"
                    );
                    if (template) {
                        refresh();
                    }
                    setSending(false);
                }).catch(() => {
                    message.error("Kļūda!");
                    if (template) {
                        refresh();
                    }
                    setSending(false);
                })
            },
            onCancel: () => setSending(false),
            content: template
                ? hash ? "Saglabāt izmaiņas paziņojuma sagatavē?" : "Saglabāt jaunu paziņojuma sagatavi?"
                : "Vai tiešām vēlaties nosūtīt paziņojumu visiem atlasītajiem studentiem?"
        })
    };

    const cancelEdit = () => {
        setHash(null);
        setTitle("");
        setSpam({});
        resetTemplateForEdit();
    }

    useEffect(() => {
        if (templateForEdit) {
            const hash = Object.keys(templateForEdit)[0]
            setSpam(templateForEdit[hash]);
            setTitle(templateForEdit[hash].title)
            setHash(hash);
        }
    }, [templateForEdit])

    return <div>
        {
            template
            && <div>
                <p><b>Paziņojuma nosaukums</b></p>
                <Input
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                />
            </div>
        }
        <Tabs>
            {
                languages?.map((lang: any, index: any) => {
                    return <Tabs.TabPane
                        key={index}
                        tab={lang.iso?.toUpperCase()}
                        forceRender
                    >
                        <Tabs>
                            {
                                channels?.map((channel: any, index: any) => {
                                    return <Tabs.TabPane
                                        key={index}
                                        tab={channelsTranslation[channel]}
                                        forceRender
                                    >
                                        {
                                            channel === "email"
                                            && <div>
                                                <p>E-pasta tēma</p>
                                                <Input
                                                    style={{ marginBottom: 10 }}
                                                    value={spam[lang.id]?.email_subject}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        setSpam((prev: any) => {
                                                            if (e.target.value.length === 0) {
                                                                const copy = JSON.parse(JSON.stringify(prev));
                                                                if (Object.keys(copy[lang.id]).length === 1) {
                                                                    delete copy[lang.id]
                                                                } else {
                                                                    delete copy[lang.id].email_subject;
                                                                }
                                                                return copy;
                                                            }
                                                            return {
                                                                ...prev,
                                                                [lang.id]: {
                                                                    ...prev[lang.id],
                                                                    email_subject: e.target.value
                                                                }
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            channel === "fcm"
                                            && <div>
                                                <p>Paziņojuma tēma</p>
                                                <Input
                                                    style={{ marginBottom: 10 }}
                                                    value={spam[lang.id]?.fcm_title}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        setSpam((prev: any) => {
                                                            if (e.target.value.length === 0) {
                                                                const copy = JSON.parse(JSON.stringify(prev));
                                                                if (Object.keys(copy[lang.id]).length === 1) {
                                                                    delete copy[lang.id]
                                                                } else {
                                                                    delete copy[lang.id].fcm_title;
                                                                }
                                                                return copy;
                                                            }
                                                            return {
                                                                ...prev,
                                                                [lang.id]: {
                                                                    ...prev[lang.id],
                                                                    fcm_title: e.target.value
                                                                }
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            channel === "fcm"
                                            && <p>īsais saturs</p>
                                        }
                                        <Input.TextArea
                                            autoSize={{ maxRows: 5, minRows: 5 }}
                                            value={spam[lang.id]?.[channel]}
                                            onChange={(e: any) => {
                                                e.persist();
                                                setSpam((prev: any) => {
                                                    if (e.target.value.length === 0) {
                                                        const copy = JSON.parse(JSON.stringify(prev));
                                                        if (Object.keys(copy[lang.id]).length === 1) {
                                                            delete copy[lang.id]
                                                        } else {
                                                            delete copy[lang.id][channel];
                                                        }
                                                        return copy;
                                                    }
                                                    if (channel === "db") {
                                                        return {
                                                            ...prev,
                                                            [lang.id]: {
                                                                ...prev[lang.id],
                                                                [channel]: e.target.value,
                                                                for_all: 0
                                                            }
                                                        }
                                                    }
                                                    if (channel === "fcm") {
                                                        return {
                                                            ...prev,
                                                            [lang.id]: {
                                                                ...prev[lang.id],
                                                                [`fcm_content`]: e.target.value
                                                            }
                                                        }
                                                    }
                                                    return {
                                                        ...prev,
                                                        [lang.id]: {
                                                            ...prev[lang.id],
                                                            [channel]: e.target.value
                                                        }
                                                    }
                                                })
                                            }}
                                        />
                                        {
                                            channel === "db" && !template
                                            && <div style={{ marginTop: 10 }}>
                                                <b>Rādīt līdz:</b>
                                                <DatePicker
                                                    style={{ marginLeft: 10 }}
                                                    value={dateTill}
                                                    onChange={e => {
                                                        setDateTill(e ? e : moment().add(7, "days"))
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            channel === "fcm"
                                            && <div>
                                                <p>garais saturs</p>
                                                <Input.TextArea
                                                    autoSize={{ maxRows: 5, minRows: 5 }}
                                                    value={spam[lang.id]?.[channel]}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        console.log(e)
                                                        setSpam((prev: any) => {
                                                            if (channel === "fcm") {
                                                                return {
                                                                    ...prev,
                                                                    [lang.id]: {
                                                                        ...prev[lang.id],
                                                                        [`fcm_full_content`]: e?.target?.value
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    </Tabs.TabPane>
                                })
                            }
                        </Tabs>
                    </Tabs.TabPane>
                })
            }
        </Tabs>
        {
            !template
            && <Row justify="end">
                <ChooseTemplateModal setSpam={(data: any) => setSpam(data)} />
            </Row>
        }
        <Row justify="end">
            {
                hash
                && <Button
                    style={{ margin: "20px 10px 0 0" }}
                    onClick={cancelEdit}
                >
                    Atcelt
                </Button>
            }
            {
                students && !template
                    ? <Button
                        type="primary"
                        style={{ margin: "20px 0" }}
                        onClick={sendSpam}
                        disabled={students.length === 0 || sending || Object.keys(spam).length === 0}
                    >
                        Sūtīt
                    </Button>
                    : <Button
                        type="primary"
                        style={{ margin: "20px 0" }}
                        onClick={sendSpam}
                        disabled={title.length === 0}
                    >
                        {
                            hash
                                ? "Saglabāt"
                                : "Pievienot"
                        }
                    </Button>
            }
        </Row>
    </div>
}