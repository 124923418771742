import { Button, Col, DatePicker, Form, Input, InputNumber, Row, message, Checkbox } from "antd";
import React, { useState } from "react";
import { http } from "../../helpers/http";

export const AddBonusForm = (props: any) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);

    const { student_id } = props;

    function onFinish(values: any) {

        setSubmitting(true);
        http.post(`/payments/add-student-bonus-payment`, {
            ...values,
            student_id,
        })
            .then(() => {
                setSubmitting(false);
                form.resetFields();
                message.success("Einšteini papildināti");
                props.onFinish();
            })
            .catch(() => { setSubmitting(false); message.error("Notika neparedzēta kļūda"); });
    }

    function onFinishFailed() {
        message.error("Neizdevās reģistrēt maksājumu");
    }

    return <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} layout="vertical">
        <Row gutter={20}>
            <Col span={6}>
                <Form.Item label="Summa EIN" rules={[{ required: true, message: "Obligāts lauks" }]} name="amount">
                    <InputNumber precision={2}></InputNumber>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Pamatojums" rules={[{ required: true, message: "Obligāts lauks" }]} name="reason">
                    <Input />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={20}>
            <Col>
                <Form.Item>
                    <Button type="primary" loading={submitting} htmlType="submit">Papildināt Einšteinu maciņu</Button>
                </Form.Item>
            </Col>
        </Row>
    </Form>;
}