import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Form, Input, message, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { http } from "../helpers/http";

export const StudyTypes = withRouter((props: any) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const params = useParams() as any;
    const [initialData, setInitialData] = useState({} as any);

    const fetchTypes = async () => {
        await http.get(`/rest/study-groups-types/${params.id}`).then((response: any) => {
            console.log(response)
            setInitialData(response.data.data)
        })
    }

    const saveForm = () => {
        form.validateFields().then((values: any) => {
            console.log(values)
            values.full_time = values.full_time ? 1 : 0;
            values.web = values.web ? 1 : 0;
            if (params.hasOwnProperty('id')) {
                http.patch(`/rest/study-groups-types/${params.id}`, values).then((response: any) => {
                    console.log(response);
                    history.goBack();
                }).catch(() => {
                    message.error("Kļūda");
                })
            } else {
                http.post(`/rest/study-groups-types`, values).then((response: any) => {
                    console.log(response);
                    history.goBack();
                }).catch(() => {
                    message.error("Kļūda");
                })
            }
        })
    }

    useEffect(() => {
        if (params.hasOwnProperty('id')) {
            fetchTypes();
        }
    }, []);

    useEffect(() => {
        form.resetFields();
    }, [initialData])

    return (
        <Card
            title={
                <Row justify="space-between">
                    <p>Apmācību veidi</p>
                    <Button type="link" onClick={history.goBack}><ArrowLeftOutlined />Atgriezties</Button>
                </Row>
            }
        >
            <Form
                form={form}
                initialValues={initialData}
            >
                <Row>
                    <Col span={8}>

                        <Form.Item
                            name="value"
                            label="Vērtība"
                        >
                            <Input style={{ width: 150 }} />
                        </Form.Item>
                        <Form.Item
                            name="value_lv"
                            label="Nosaukums LV"
                        >
                            <Input style={{ width: 150 }} />
                        </Form.Item>
                        <Form.Item
                            name="value_en"
                            label="Nosaukums EN"
                        >
                            <Input style={{ width: 150 }} />
                        </Form.Item>
                        <Form.Item
                            name="value_ru"
                            label="Nosaukums RU"
                        >
                            <Input style={{ width: 150 }} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="full_time"
                            label="Klātiene"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            name="web"
                            label="Rādīt WEB"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end">
                    <Button onClick={saveForm} type="primary">Saglabāt</Button>
                </Row>
            </Form>
        </Card>
    )
})