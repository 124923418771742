import { Row, Col, Select, Checkbox, DatePicker } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SelectValue } from "antd/lib/select";
import moment from "moment";
import React, { useEffect } from "react";
import { StudentFilter } from "./StudentSpamFilterLayout";

export const BasicFilterForm = (props: any) => {
    const { studentFilter, setStudentFilter, classifiers } = props;

    const setBasicFilter = (data: StudentFilter) => {
        setStudentFilter((prev: StudentFilter) => {
            return {
                ...prev,
                ...data
            }
        })
    };

    const removeFilter = (key: string) => {
        setStudentFilter((prev: any) => {
            const copy = JSON.parse(JSON.stringify(prev));
            delete copy[key];
            return copy;
        });
    };

    return <Row gutter={20}>
        <Col>
            <p style={{ marginBottom: 4 }}>Dzimums</p>
            <Select
                value={studentFilter.gender}
                style={{ width: 200, marginBottom: 20 }}
                mode="multiple"
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("gender");
                        return;
                    }
                    const values = e.map((el: any) => {
                        if (el === 0) {
                            return undefined;
                        } return el;
                    })
                    setBasicFilter({ gender: values })
                }}
            >
                <Select.Option value={0}>Nav definēts</Select.Option>
                <Select.Option value={"male"}>Vīrietis</Select.Option>
                <Select.Option value={"female"}>Sieviete</Select.Option>
            </Select>
            <p style={{ marginBottom: 4 }}>Vecuma grupa</p>
            <Select
                value={studentFilter.age_group}
                style={{ width: 200, marginBottom: 20 }}
                mode="multiple"
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("age_group");
                        return;
                    }
                    setBasicFilter({ age_group: e })
                }}
            >
                <Select.Option value={1}>14 - 17</Select.Option>
                <Select.Option value={2}>18 - 24</Select.Option>
                <Select.Option value={3}>25 - neierobežoti</Select.Option>
            </Select>
        </Col>
        <Col>
            <p style={{ marginBottom: 4 }}>Kategorija</p>
            <Select
                value={studentFilter.category}
                style={{ width: 200, marginBottom: 20 }}
                mode="multiple"
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("category");
                        return;
                    }
                    setBasicFilter({ category: e })
                }}
            >
                {
                    classifiers?.categories?.map((category: any) => {
                        return <Select.Option key={category.id} value={category.id}>{category.value}</Select.Option>
                    })
                }
            </Select>
            <p style={{ marginBottom: 4 }}>Valoda</p>
            <Select
                value={studentFilter.language}
                style={{ width: 200, marginBottom: 20 }}
                mode="multiple"
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("language");
                        return;
                    }
                    setBasicFilter({ language: e })
                }}
            >
                {
                    classifiers?.languages?.map((lang: any) => {
                        return <Select.Option key={lang.id} value={lang.id}>{lang.value}</Select.Option>
                    })
                }
            </Select>
        </Col>
        <Col>
            <p style={{ marginBottom: 4 }}>Ir tiesības</p>
            <Select
                value={studentFilter.got_licence}
                style={{ width: 200, marginBottom: 20 }}
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("got_licence");
                        return;
                    }
                    setBasicFilter({ got_licence: e });
                }}
            >
                {
                    studentFilter.got_licence !== undefined
                        ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                        : <></>
                }
                <Select.Option value={1}>Jā</Select.Option>
                <Select.Option value={0}>Nē</Select.Option>
            </Select>
            <p style={{ marginBottom: 4 }}>Ir ielogojies</p>
            <Select
                value={studentFilter.logged_in}
                style={{ width: 200, marginBottom: 20 }}
                onChange={(e: any) => {
                    if (e.length === 0) {
                        removeFilter("logged_in");
                        return;
                    }
                    setBasicFilter({ logged_in: e });
                }}
            >
                {
                    studentFilter.logged_in !== undefined
                        ? <Select.Option value={""}>Atcelt filtru</Select.Option>
                        : <></>
                }
                <Select.Option value={1}>Jā</Select.Option>
                <Select.Option value={0}>Nē</Select.Option>
            </Select>
        </Col>
        <Col>
            <p style={{ marginBottom: 4 }}>Studenta reģistrācijas datums</p>
            <DatePicker.RangePicker
                style={{ marginBottom: 20 }}
                value={
                    studentFilter.registered_at_from
                        ? [
                            moment(studentFilter.registered_at_from, "YYYY-MM-DD"),
                            moment(studentFilter.registered_at_till, "YYYY-MM-DD"),
                        ]
                        : null
                }
                onChange={(date: any) => {
                    if (!date) {
                        removeFilter("registered_at_from");
                        removeFilter("registered_at_till");
                        return;
                    }
                    setBasicFilter({
                        registered_at_from: date[0].format("YYYY-MM-DD"),
                        registered_at_till: date[1].format("YYYY-MM-DD"),
                    })
                }}
            />
        </Col>
    </Row>
}