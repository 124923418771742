import { Button, Card, Collapse, Row } from "antd";
import React, { useEffect, useState } from "react";
import QuestionsFilter from "./QuestionsFilter";
import { http } from "../../../helpers/http";
import { AxiosResponse } from "axios";
import QuestionCard from "./QuestionCard";

export const QuestionsLayout = (props: any) => {
    const [filters, setFilters] = useState({} as any);
    const [questions, setQuestions] = useState([] as any);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0
    });
    const [loading, setLoading] = useState(false);
    const [newQuestionFormVisible, setNewQuestionFormVisible] = useState(false);
    const [categories, setCategories] = useState<any[]>([]);
    const [themes, setThemes] = useState<any[]>([]);

    const getCategories = () => {
        http.get(`rest/categories`).then((response) => {
            setCategories(response.data.data)
        }).catch((error) => {
            console.log(error)
        });
    }

    const getThemes = () => {
        http.get(`rest/csdd-test-categories`).then((response) => {
            console.log(response)
            setThemes(response.data)
        }).catch((error) => {
            console.log(error)
        });
    };

    const getQuestions = async (page: number = 1) => {
        setLoading(true);
        const result = await http.get(`rest/csdd-test-data`, { params: { filters, page } }).then((res: AxiosResponse) => {
            setPagination({
                current: res.data.meta.current_page,
                total: res.data.meta.last_page,
            });
            setLoading(false);
            return res.data.data
        }).catch((err) => {
            console.log(err)
            setLoading(false);
            return []
        });
        return result;
    }

    const loadMoreQuestions = () => {
        getQuestions(pagination.current + 1).then((data) => {
            setQuestions([...questions, ...data]);
            scrollToBottom();
        });
    }

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'auto'
        });
    };

    useEffect(() => {
        if (Object.keys(filters).length === 0) {
            getQuestions().then((data) => setQuestions(data));
        }
    }, [filters])

    useEffect(() => {
        getCategories();
        getThemes();
    }, []);

    return <Card
        title={<Row justify="space-between">
            <b>Jautājumi</b>
            <Button
                type="primary"
                onClick={() => setNewQuestionFormVisible(!newQuestionFormVisible)}
            >
                {
                    newQuestionFormVisible ? "Aizvērt" : "Jauns jautājums"
                }
            </Button>
        </Row>}
        style={{ margin: "8px 24px 24px" }}
    >
        {
            newQuestionFormVisible && <QuestionCard
                categories={categories}
                question={{
                    question_lv: "",
                    question_en: "",
                    question_ru: "",
                    csdd_question_category_id: 1,
                    video: 0,
                    answers: {
                        "lv": [
                            {
                                "answer": "",
                                "correct": 0
                            }
                        ],
                        "en": [
                            {
                                "answer": "",
                                "correct": 0
                            }
                        ],
                        "ru": [
                            {
                                "answer": "",
                                "correct": 0
                            }
                        ]
                    }
                }}
                savedQuestion={(question: any) => {
                    setNewQuestionFormVisible(false);
                    setQuestions([question, ...questions]);
                }}
                themes={themes}
            />
        }
        <QuestionsFilter
            onFilterChange={(newF: any) => {
                console.log(newF)
                setFilters((prev: any) => { return { ...prev, ...newF } });
            }}
            filter={filters}
            search={() => getQuestions().then((data) => setQuestions(data))}
            cancel={() => { setFilters({}) }}
            themes={themes}
        />
        {/* <Button
            type="primary"
            onClick={() => http.post(`rest/gen-missin-q`)}
        >FIX Q</Button>
        <Button
            type="primary"
            onClick={() => axios.post(`localhost:4000/whatapp?csdd_test_categories=1`)}
        >FIX Cats</Button> */}
        <Collapse accordion style={{ marginBottom: 20 }}>
            {
                questions.map((question: any) => {
                    return <Collapse.Panel header={question.question_lv} key={question.data_hash}>
                        <QuestionCard
                            question={question}
                            categories={categories}
                            themes={themes}
                        />
                    </Collapse.Panel>
                })
            }
        </Collapse>
        <Row justify="center">
            <Button
                type="primary"
                onClick={loadMoreQuestions}
                loading={loading}
                disabled={pagination.current === pagination.total}
            >
                Ielādēt vairāk
            </Button>
        </Row>
    </Card>
};