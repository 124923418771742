import { Button, Card, Col, DatePicker, Row, message } from 'antd';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import { AgeCheck } from './AgeCheck';

export const StudentExamSettings = (props: any) => {
    const { studentCategories, categories } = props;
    const { id }: { id: string } = useParams();
    const [examSettings, setExamSettings] = useState({} as any);
    const [loading, setLoading] = useState(false);

    const saveNewSettings = () => {
        setLoading(true);
        http.post(`rest/change-multi-cat/${id}`, { multidata: examSettings }).then((res: AxiosResponse) => {
            setLoading(false);
            message.success("Dati ir saglabāt");
        }).catch(() => {
            setLoading(false);
            message.error("Kļūda!")
        })
    }

    const processData = () => {
        let examSettingsObj = {} as any;
        studentCategories.forEach((category: any) => {
            const refCategory = categories.find((cat: any) => cat.id === category.category_id);
            if (!refCategory.has_theory_exam) return;
            examSettingsObj[category.id] = { free_exam_till: category.free_exam_till }
        })
        return examSettingsObj;
    }

    useEffect(() => {
        if (studentCategories.length > 0 && categories.length > 0) {
            setExamSettings(processData());
        }
    }, [studentCategories, categories]);

    useEffect(() => {
        console.log(examSettings)
    }, [examSettings])

    return <Card
        title="Informācija par skolas teorijas eksāmenu"
        loading={Object.keys(examSettings).length === 0}
    >
        {
            Object.keys(examSettings).map((key: any) => {
                const category = studentCategories.find((el: any) => el.id === Number(key))
                return <div key={category.id} style={{ marginBottom: 10 }}>
                    <Row>
                        <Col>
                            <p><b>{category.category} kategorija</b> Pagarināt bezmaksas teorijas eksāmena termiņu līdz:</p>
                            <AgeCheck
                                studentID={id}
                                birthdate={studentCategories}
                                category={category.category_id}
                            />
                            <DatePicker
                                value={examSettings[key].free_exam_till ? moment(examSettings[key].free_exam_till, "YYYY-MM-DD") : null}
                                onChange={(date: any) => {

                                    setExamSettings((prev: any) => {
                                        return {
                                            ...prev,
                                            [key]: { free_exam_till: date ? moment(date).format("YYYY-MM-DD") : null }
                                        }
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            })
        }
        <Row justify="end">
            <Button
                type="primary"
                onClick={saveNewSettings}
                loading={loading}
            >
                Saglabāt
            </Button>
        </Row>
    </Card>
}