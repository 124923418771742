import { Button, Card, Col, Collapse, Form, Input, Layout, message, PageHeader, Radio, Row, Space, Tabs } from "antd";
import { DeleteOutlined, ArrowDownOutlined, ArrowUpOutlined, UploadOutlined, SaveTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { http } from "../../helpers/http";
import { VideoUploader } from "./video/VideoUploader";
import { FaqList } from "./FaqList";
import { QuestionCard } from "./questions/QuestionCard";

const { Panel } = Collapse;

export const ChapterView = (props: any) => {
    const history = useHistory();

    const { mid, cid } = useParams() as any;

    const [questions, setQuestions] = useState([] as any);
    const [videoToken, setVideoToken] = useState(undefined as any);
    const [filesList, setFilesList] = useState([{}, {}, {}] as any);
    const [languages, setLanguages] = useState([] as any);
    const [chapterData, setChapterData] = useState({
        title_lv: null,
        title_en: null,
        title_ru: null,
        description_lv: null,
        description_en: null,
        description_ru: null,
        yt_url_lv: null,
        yt_url_en: null,
        yt_url_ru: null,
    } as any);

    const fetchLanguages = async () => {
        await http.get(`/rest/languages`).then((response: any) => {
            setLanguages(response.data.data);
        });
    };

    const fetchChapterData = async () => {
        await http.get(`/rest/learning-chapters/${cid}`).then((response: any) => {
            setChapterData(response.data)
            setFilesList(response.data.sorted_attachments)
        })
    }

    const fetchQuestions = async () => {
        await http.get(`/rest/get-learning-chapter-questions/${cid}`).then((response: any) => {
            setQuestions(response.data.data)
        })
    }

    const getVideoToken = async () => {
        await http.get(`/rest/get-instruction-token`).then((response: any) => {
            setVideoToken(response.data.token.token)
        })
    }

    const saveChapterData = () => {
        console.log(chapterData)
        const toSend = {
            ...chapterData,
            thumb_lv: chapterData.thumb_lv?.includes("base64") || chapterData.thumb_lv === null ? chapterData.thumb_lv : undefined,
            thumb_en: chapterData.thumb_en?.includes("base64") || chapterData.thumb_en === null ? chapterData.thumb_en : undefined,
            thumb_ru: chapterData.thumb_ru?.includes("base64") || chapterData.thumb_ru === null ? chapterData.thumb_ru : undefined,
        }
        http.patch(`/rest/learning-chapters/${cid}`, toSend).then((response: any) => {
            console.log(response)
            message.success("Dati saglabāti")
        }).catch(() => {
            message.error("Kļūda!");
        })
    }

    const triggerupload = (link: string) => {
        document.getElementById(link)?.click();
    }

    const filesHandler = (files: any, lang: string) => {
        console.log(Object.keys(files))
        const filesArray = Object.keys(files).map((key: any) => {
            return files[key];
        })
        const merged = filesArray.concat(filesList[`attachment_${lang}`]).filter((el: any) => el !== undefined)
        console.log(merged)
        setFilesList((prev: any) => {
            return {
                ...prev,
                [`attachment_${lang}`]: merged
            }
        })
    };

    const deleteFile = (ind: any, lang: string) => {
        if (filesList[`attachment_${lang}`][ind].hasOwnProperty("attachment")) {
            http.delete(`/rest/learning-chapter-attachments/${filesList[`attachment_${lang}`][ind].id}`).then(() => {
                message.success("Fails ir dzēsts")
                fetchChapterData()
            }).catch(() => {
                message.error("Kļūda!")
                fetchChapterData()
            })
        } else {
            setFilesList((prev: any) => {
                const newArray = prev[`attachment_${lang}`].filter((file: any, index: any) => {
                    return index !== ind;
                });
                return {
                    ...prev,
                    [`attachment_${lang}`]: newArray
                }
            });
        }
    };

    const uploadAttachment = (lang: any, langID: any) => {
        const files = new FormData();
        filesList[`attachment_${lang}`].forEach((file: any, index: any) => {
            console.log(typeof file)
            files.append("file_" + index, file);
        });
        files.append("e_chapter_id", cid);
        files.append("language_id", langID);
        const toSend = {
            e_chapter_id: cid,
            language_id: langID,
            files
        }
        http.post(`/rest/learning-chapter-attachments`, files).then((response: any) => {
            message.success("Failu saraksts ir atjaunots")
            fetchChapterData();
        }).catch(() => {
            // message.s
            fetchChapterData();
        });
    }

    const addQuestion = () => {
        setQuestions((prev: any) => {
            return [
                ...prev,
                {
                    e_chapter_id: Number(cid),
                    id: null,
                    question_lv: null,
                    question_en: null,
                    question_ru: null,
                    img: null,
                    answers: [
                        // {
                        //     answer_lv: null,
                        //     answer_en: null,
                        //     answer_ru: null,
                        //     correct: undefined
                        // }
                    ]
                }
            ]
        })
    }

    const removeQuestion = (e: any, ind: any) => {
        const toDelete = questions.find((el: any, index: any) => index === ind)
        const haveID = toDelete.id !== null
        if (haveID) {
            http.delete(`/rest/learning-chapter-questions/${toDelete.id}`).then((response: any) => {
                message.success("Jautājums dzēsts!");
            }).catch(() => {
                message.error("Kļūda!");
                return;
            })
        }
        setQuestions((prev: any) => {
            return prev.filter((el: any, index: any) => index !== ind)
        })
    }

    useEffect(() => {
        fetchLanguages();
        fetchChapterData();
        getVideoToken();
        fetchQuestions();
        console.log(cid)
    }, []);

    useEffect(() => {
        console.log(chapterData)
    }, [chapterData])

    return <Layout className="main-content">
        <PageHeader
            title={`${chapterData.ind + 1 || ""}. Nodaļa`}
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF" }}
        />
        <Card style={{ margin: 15 }}>
            <Card style={{ marginBottom: 30 }}>
                <Tabs>
                    {
                        languages.map((lang: any) => {
                            return <Tabs.TabPane key={lang.id} tab={lang.iso?.toUpperCase()} forceRender>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <div>
                                            <b>Nosaukums:</b><br />
                                            <Input
                                                value={chapterData[`title_${lang.iso}`]}
                                                style={{ margin: "8px 0" }}
                                                onChange={(e: any) => {
                                                    e.persist();
                                                    setChapterData((data: any) => {
                                                        return {
                                                            ...data,
                                                            [`title_${lang.iso}`]: e.target.value
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <b>Apraksts</b>
                                            <Input.TextArea
                                                value={chapterData[`description_${lang.iso}`]}
                                                style={{ marginTop: 8 }}
                                                autoSize={{ maxRows: 5, minRows: 5 }}
                                                onChange={(e: any) => {
                                                    e.persist();
                                                    setChapterData((data: any) => {
                                                        return {
                                                            ...data,
                                                            [`description_${lang.iso}`]: e.target.value
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            !!chapterData?.is_pmp
                                            && <div style={{ marginTop: 20 }}>
                                                <b>Youtube links (PMP)</b>
                                                <Input.TextArea
                                                    value={chapterData[`yt_url_${lang.iso}`]}
                                                    style={{ marginTop: 8 }}
                                                    autoSize={{ maxRows: 5, minRows: 5 }}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        setChapterData((data: any) => {
                                                            return {
                                                                ...data,
                                                                [`yt_url_${lang.iso}`]: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    </Col>
                                    <Col span={12}>
                                        <Card style={{ width: "100%", height: "100%" }}>
                                            <Row justify="space-between">
                                                <Button
                                                    style={{ marginBottom: 10 }}
                                                    icon={<UploadOutlined />}
                                                    onClick={() => triggerupload(`upload-part-pdf-${lang.iso}`)}
                                                >
                                                    Pievienot
                                                </Button>
                                                {
                                                    filesList[`attachment_${lang.iso}`]?.length > 0
                                                        ? <Button
                                                            type="primary"
                                                            onClick={() => uploadAttachment(lang.iso, lang.id)}
                                                        >
                                                            Augšupielādēt
                                                        </Button>
                                                        : <></>
                                                }

                                            </Row>
                                            <input
                                                style={{ display: "none" }}
                                                id={`upload-part-pdf-${lang.iso}`}
                                                accept=".pdf,.xls,.csv,.doc,.xlsx,.docx"
                                                multiple
                                                type="file"
                                                onChange={(e: any) => {
                                                    e.persist();
                                                    console.log(e.target.files);
                                                    filesHandler(e.target.files, lang.iso);
                                                    e.target.value = "";
                                                }}
                                            />
                                            {
                                                filesList[`attachment_${lang.iso}`]?.map((file: any, index: any) => {
                                                    return <Card
                                                        key={index}
                                                        bodyStyle={{ padding: "3px 10px" }}
                                                        style={{ margin: "4px 0", border: !file.name ? "1px solid green" : "1px solid red" }}
                                                    >
                                                        <Row justify="space-between">
                                                            <span style={{ color: "rgb(151 151 151)", display: "inline-block", lineHeight: "30px" }}>
                                                                {file.name || file.original_name}
                                                                {
                                                                    file.name
                                                                        ? <span style={{ display: "inline-block", marginLeft: 20, color: "red" }}>Nepieciešama augšupielāde</span>
                                                                        : <></>
                                                                }
                                                            </span>
                                                            <Button
                                                                type="text"
                                                                title="Dzēst"
                                                                onClick={() => deleteFile(index, lang.iso)}
                                                            >
                                                                <DeleteOutlined />
                                                            </Button>
                                                        </Row>
                                                    </Card>
                                                })
                                            }

                                        </Card>
                                    </Col>
                                    <Col span={24} style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                                        <Button type="primary" onClick={saveChapterData}>Saglabāt</Button>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                        })
                    }
                </Tabs>
                {
                    !!!chapterData?.is_pmp
                    && <Col span={24}>
                        <VideoUploader
                            url={process.env.REACT_APP_API_URL + `admin-api/get-video?e_chapter_id=${cid}&language_id=`}
                            uploadUrl={process.env.REACT_APP_API_URL + `admin-api/rest/upload-video`}
                            uploadProps={{
                                e_chapter_id: cid
                            }}
                            token={videoToken}
                            checkUrl={{
                                url: `/rest/check-video`,
                                param: { e_chapter_id: cid }
                            }}
                            deleteURL={{
                                url: `/rest/delete-video`,
                                param: { e_chapter_id: cid }
                            }}
                            data={chapterData}
                            setData={setChapterData}
                            saveData={saveChapterData}
                        />
                    </Col>
                }
            </Card>
            <Card style={{ marginBottom: 30 }} title="Testa jautājumu varianti">
                {/* defaultActiveKey={questions.map((data: any, index: any) => index + 1)} */}
                <Collapse >
                    {
                        questions.map((question: any, index: any) => {
                            return <Panel
                                header={<Row justify="space-between">
                                    <b style={{ margin: 0 }}>{index + 1}. Jautājums</b>
                                    <Button
                                        type="text"
                                        danger
                                        style={{ marginLeft: 10 }}
                                        onClick={(e: any) => removeQuestion(e, index)}
                                        icon={<DeleteOutlined style={{ pointerEvents: "none" }}
                                        />}>
                                    </Button>
                                </Row>}
                                key={index + 1}
                            >
                                <QuestionCard
                                    qind={index}
                                    question={question}
                                    languages={languages}
                                    apiResource={'/rest/learning-chapter-questions'}
                                    videoQuestion
                                    refresh={fetchQuestions}
                                />
                            </Panel>
                        })
                    }
                </Collapse>
                <Row justify="end">
                    <Button onClick={addQuestion} style={{ marginTop: 15 }}>Pievienot jautajumu +</Button>
                </Row>
            </Card>
            <FaqList
                languages={languages}
                mid={mid}
                cid={cid}
                faqs={chapterData.faqs}
            />
        </Card>
    </Layout>
}