import axios from "axios";

const localhost = "https://api.einsteins.lv:8443/admin-api/";
const adminApiBaseUrl = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL + "admin-api/"
    : localhost;

const http = axios.create({
    baseURL: adminApiBaseUrl,
    headers: {
        Accept: "application/json",
    },
});

window["http" as any] = http as any;

export { http };
