import { Button, Card, Col, DatePicker, Layout, PageHeader, Row, Select } from "antd"
import { AxiosResponse } from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { http } from "../../helpers/http";
import { StatisticsTable } from "./StatisticsTable";

export const GrouptStatisticsLayout = () => {
    const history = useHistory();
    const [filters, setFilters] = useState({
        date_from: undefined as undefined | string,
        date_to: undefined as undefined | string,
        category: undefined as undefined | number,
        cities: undefined as undefined | number[]
    });
    const [categories, setCategories] = useState([] as any);
    const [cities, setCities] = useState([] as any);
    const [moduleNames, setModuleNames] = useState({} as any);
    const [data, setData] = useState({} as any);
    const [loading, setLoading] = useState(false);

    const getCategories = () => {
        http.get(`/rest/categories`).then((res: AxiosResponse) => {
            setCategories(res.data.data)
        })
    };

    const getCities = () => {
        http.get(`/rest/cities`).then((res: AxiosResponse) => {
            setCities(res.data.data)
        })
    };

    const fetchData = () => {
        setLoading(true);
        http.get(`/rest/category-modules/${filters.category}`).then((res: AxiosResponse) => {
            console.log(res)
            setModuleNames(res.data)
            http.get(`/rest/study-group-extra-slots`, {
                params: {
                    filters
                }
            }).then((response: AxiosResponse) => {
                console.log(response)
                setData(response.data)
                setLoading(false)
            })
        }).catch(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getCategories();
        getCities();
    }, []);

    return <Layout>
        <PageHeader
            title="Grupu papildus slotu apmeklējuma statistika"
            style={{ backgroundColor: "#FFFFFF" }}
            onBack={history.goBack}
        />
        <Card>
            <Row gutter={[20, 20]}>
                <Col>
                    <p>Atlases periods:</p>
                    <DatePicker.RangePicker
                        value={
                            filters.date_from !== undefined
                                ? [moment(filters.date_from, "YYYY-MM-DD"), moment(filters.date_to, "YYYY-MM-DD")]
                                : undefined
                        }
                        onChange={e => {
                            setFilters((prev: any) => {
                                return {
                                    ...prev,
                                    date_from: e ? e[0]?.format("YYYY-MM-DD") : undefined,
                                    date_to: e ? e[1]?.format("YYYY-MM-DD") : undefined,
                                }
                            })
                        }}
                    />
                </Col>
                <Col>
                    <p>Kategorija:</p>
                    <Select
                        style={{ width: 120 }}
                        value={filters.category}
                        onChange={e => {
                            setFilters((prev: any) => {
                                return {
                                    ...prev,
                                    category: e
                                }
                            })
                        }}
                    >
                        {
                            categories.map((category: any) => {
                                return <Select.Option key={category.id} value={category.id}>
                                    {category.value}
                                </Select.Option>
                            })
                        }
                    </Select>
                </Col>
                <Col>
                    <p>Pilsētas:</p>
                    <Select
                        style={{ width: 200 }}
                        mode="multiple"
                        value={filters.cities}
                        onChange={e => {
                            setFilters((prev: any) => {
                                return {
                                    ...prev,
                                    cities: e
                                }
                            })
                        }}
                    >
                        {
                            cities.map((city: any) => {
                                return <Select.Option key={city.id} value={city.id}>
                                    {city.value}
                                </Select.Option>
                            })
                        }
                    </Select>
                </Col>
                <Col>
                    <Button
                        style={{ marginTop: 36 }}
                        disabled={
                            filters.category === undefined
                            || filters.date_from === undefined
                            || filters.date_to === undefined
                        }
                        type="primary"
                        onClick={fetchData}
                        loading={loading}
                    >
                        Atlasīt
                    </Button>
                </Col>
            </Row>
        </Card>
        <Card bodyStyle={{ overflow: "scroll", maxHeight: "70vh" }}>
            <StatisticsTable
                data={data}
                moduleNames={moduleNames}
                loading={loading}
            />
        </Card>
    </Layout>
}