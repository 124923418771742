import React, { useState } from "react";
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, message, Row, Select, Space, TimePicker, Typography } from "antd";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import moment from "moment";
import { withRouter } from "react-router";
import { http } from "../../helpers/http";

const { Title } = Typography;

export const LessonForm = withRouter((props: any) => {
    const { lesson, index, groupID, saveLessons } = props;

    const [isOnline, setIsOnline] = useState(lesson.is_online)

    const handleOnlineCB = () => {
        isOnline ? setIsOnline(false) : setIsOnline(true);
    }

    const deleteLesson = (value: any) => {
        if (typeof lesson.id === 'string') {
            props.updateLessons(lesson.id);
        } else {
            http.delete(`/rest/theory-lesson/${lesson.id}`).then((response) => {
                props.updateLessons(lesson.id);
                console.log("Veiksmīgi dzēsts")
            }).catch(() => {
                console.log("Fail")
            })
        }
    }

    const onFinish = (values: any) => {
        console.log(values, props.match.params.id);
        const newLesson = {
            lesson: typeof lesson.id === 'string' ? undefined : lesson.id,
            id: values.lesson_item,
            name: values.name,
            lesson_date: values.start_date === undefined ? undefined : moment(values.start_date).format("YYYY-MM-DD"),
            start_end_time: values.start_end_time === null ? [] : [
                values.start_end_time[0] === undefined ? undefined : moment(values.start_end_time[0]).format("HH:mm"),
                values.start_end_time[1] === undefined ? undefined : moment(values.start_end_time[1]).format("HH:mm"),
            ],
            comment_lv: values.comment_lv,
            comment_en: values.comment_en,
            comment_ru: values.comment_ru,
            is_online: values.online_cb === undefined ? false : values.online_cb,
            address: values.address,
            zoom_link: values.zoom_link,
            lecturers: values.lecturers
        }
        http.post(`/rest/study-groups/add-lesson/${groupID}`, newLesson).then((response) => {
            props.refresh();
            message.success("Nodarbība ir saglabāta");
            props.saveLessons(response.data)
        }).catch(() => {
            message.error("Neizdevās saglabāt");
        })
        // saveLessons(newLesson)
    }

    return (
        <>
            <Form
                key={lesson.id}
                onFinish={onFinish}
                initialValues={
                    {
                        name: lesson.name,
                        start_date: lesson.lesson_date !== null ? moment(lesson.lesson_date, "YYYY-MM-DD") : undefined,
                        start_end_time:
                            [lesson.time_from !== null
                                ? moment(lesson.time_from, "HH:mm")
                                : undefined,
                            lesson.time_to !== null
                                ? moment(lesson.time_to, "HH:mm")
                                : undefined],
                        lesson_item: lesson.id,
                        comment_lv: lesson.comment_lv,
                        comment_en: lesson.comment_en,
                        comment_ru: lesson.comment_ru,
                        online_cb: lesson.is_online,
                        zoom_link: lesson.zoom_link,
                        address: lesson.address,
                        lecturers: lesson.lecturers?.map((lecturer: any) => lecturer.id)
                    }
                }
            >
                <Card style={{ width: "100%", margin: "15px 0" }}>
                    <Row gutter={[2, 2]}>
                        <Space>
                            <Title level={5} style={{ margin: "0 0 22px 0" }}>{index + 1 + ". nodarbība"}</Title>
                            <Col>
                                <Form.Item name="name" rules={[{ required: true, message: "Ievadiet nosaukumu" }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="start_date">
                                    <DatePicker
                                        style={{ width: 200 }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="start_end_time">
                                    <TimePicker.RangePicker
                                        style={{ width: 200 }}
                                        placeholder={["Laiks no", "Laiks līdz"]}
                                        format="HH:mm"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item valuePropName="checked" name="online_cb" label="online">
                                    <Checkbox onChange={handleOnlineCB} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="lesson_item">
                                    <Space>
                                        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}></Button>
                                        <Button value={lesson.id} onClick={deleteLesson} danger icon={<DeleteOutlined style={{ pointerEvents: "none" }} />}></Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Space>
                    </Row>
                    <Title level={5}>Komentārs:</Title>
                    <Row gutter={12}>
                        <Col span={4}>
                            <Form.Item name="comment_lv">
                                <Input.TextArea placeholder="Latviešu val" autoSize={{ maxRows: 3, minRows: 3 }} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="comment_en">
                                <Input.TextArea placeholder="Angļu val" autoSize={{ maxRows: 3, minRows: 3 }} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="comment_ru">
                                <Input.TextArea placeholder="Krievu val" autoSize={{ maxRows: 3, minRows: 3 }} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="lecturers" label="Pasniedzēji">
                                {
                                    props.lecturers.length > 0
                                        ? <Select style={{ width: 200 }} mode="multiple">
                                            {props.lecturers.map((lecturer: any) => {
                                                return <Select.Option key={lecturer.id} value={lecturer.id}>
                                                    {lecturer.first_name} {lecturer.last_name}
                                                </Select.Option>
                                            })}
                                        </Select>
                                        : <b>Grupai nav pievienots pasniedzējs</b>
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        {isOnline
                            ? <Col span={12}>
                                <Form.Item name="zoom_link" label="Zoom: ">
                                    <Input />
                                </Form.Item>
                            </Col>
                            : <Col span={12}>
                            <Form.Item name="address" label="Adrese: ">
                                <Input />
                            </Form.Item>
                        </Col>}
                    </Row>
                </Card>
            </Form>
        </>
    )
})