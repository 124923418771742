import { Button, Checkbox, Col, Input, Row, Select } from "antd";
import { DeleteTwoTone, PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../../../../helpers/http";

export const ExamResultForm = (props: any) => {
    const { slot_id, closeModal, updateSlots } = props;
    const [results, setResults] = useState({
        try_1: {
            theory_exam_slot_id: slot_id,
            correct_count: null,
            question_count: null,
            status: null,
            mistakes: null
        },
    } as any);
    const [theoryPassed, setTheoryPassed] = useState(false);

    const statuses = {
        not_passed: "Nav nokārtots",
        passed: "Nokārtots",
        student_didnt_come: "Neieradās"
    } as any;

    const handleResultsInput = (index: any, key: any, value: any) => {
        setResults((prev: any) => {
            return {
                ...prev,
                [`try_${index + 1}`]: {
                    ...results[`try_${index + 1}`],
                    [key]: value
                }
            }
        })
    };

    const deleteResult = (ind: any) => {
        if (ind !== Object.keys(results).length) return;
        setResults((prev: any) => {
            let newResults = new Object();
            Object.keys(prev).forEach((key: any) => {
                if (key === `try_${ind}`) return;
                newResults = {
                    ...newResults,
                    [key]: prev[key]
                }
            })
            return newResults
        });
    }

    const addResultsForm = () => {
        const length = Object.keys(results).length;
        if (length === 3) return;
        setResults((prev: any) => {
            return {
                ...prev,
                [`try_${length + 1}`]: {
                    theory_exam_slot_id: slot_id,
                    correct_count: null,
                    question_count: null,
                    status: null,
                    mistakes: null
                }
            }
        });
    };

    const saveResults = () => {
        http.post(`rest/theory-exam-results`, { results, theory_exam_passed: theoryPassed ? 1 : 0 }).then((response: any) => {
            closeModal();
            updateSlots();
        })
    }

    useEffect(() => {
        console.log(results)
    }, [results]);

    return <Row>
        <Row gutter={20} style={styles.row}>
            <h2 style={styles.heading}>Eksāmenu rezultāti</h2>
            {
                Object.keys(results).map((key: any, index: any, self: any) => {
                    return <Row gutter={20} key={index} style={{ marginBottom: 30 }}>
                        <Col span={24}>
                            <b>{index + 1}. tests</b>
                            {
                                index === self.length - 1 && index > 0
                                    ? <Button
                                        type="text"
                                        icon={<DeleteTwoTone twoToneColor={"red"} />}
                                        onClick={() => deleteResult(index + 1)}
                                    />
                                    : <></>
                            }
                        </Col>
                        <Col span={8}>
                            <p style={styles.paragraph}>Pareizi atbildēts:</p>
                            <Input
                                type="number"
                                value={results[`try_${index + 1}`].correct_count}
                                onChange={(e: any) => {
                                    e.persist();
                                    handleResultsInput(index, "correct_count", e.target.value);
                                }}
                            />
                        </Col>
                        <Col span={8}>
                            <p style={styles.paragraph}>Jautajumi kopā:</p>
                            <Input
                                type="number"
                                value={results[`try_${index + 1}`].question_count}
                                onChange={(e: any) => {
                                    e.persist();
                                    handleResultsInput(index, "question_count", e.target.value);
                                }}
                            />
                        </Col>
                        <Col span={8}>
                            <p style={styles.paragraph}>Statuss:</p>
                            <Select
                                style={{ width: "100%" }}
                                value={results[`try_${index + 1}`].status}
                                onChange={(e: any) => {
                                    handleResultsInput(index, "status", e);
                                }}
                            >
                                {
                                    Object.keys(statuses).map((key: any) => {
                                        return <Select.Option key={key} value={key}>{statuses[key]}</Select.Option>
                                    })
                                }

                            </Select>
                        </Col>
                        <Col span={24} style={{ paddingTop: 16 }}>
                            <p style={styles.paragraph}>Komentārs:</p>
                            <Input.TextArea
                                autoSize={{ minRows: 3, maxRows: 3 }}
                                value={results[`try_${index + 1}`].mistakes}
                                onChange={(e: any) => {
                                    e.persist();
                                    handleResultsInput(index, "mistakes", e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                })
            }
            <Col span={24}>
                <Checkbox
                    value={theoryPassed}
                    onChange={(e: any) => {
                        setTheoryPassed(e.target.checked)
                    }}
                    style={{ marginRight: 10 }}
                />
                <b>Skolas teorijas eksāmens ir nokārtots</b>
            </Col>
        </Row>
        <Col span={24}>
            <Row style={{ marginTop: 26 }} justify="space-between" align="middle">
                <Button
                    type="text"
                    onClick={addResultsForm}
                >
                    <PlusCircleOutlined />
                    Pievienot papildu eksāmena rezultātus
                </Button>
                <Button
                    type="primary"
                    onClick={saveResults}
                >
                    Saglabāt rezultātus
                </Button>
            </Row>
        </Col>
    </Row>
}

const styles = {
    row: {
        borderBottom: "1px solid #D8D8D8",
        padding: "20px 0 30px",
    },
    paragraph: {
        marginBottom: 9
    },
    heading: {
        marginBottom: 20,
        width: "100%",
        paddingLeft: 10
    }
}