import { Button, message, Row, Space } from "antd";
import React from "react";
import { http } from "../../../../helpers/http";

export const ActionsRow = (props: any) => {
    const { allowApprove, slotID, closeModal, updateSlots } = props;

    const approveStudent = () => {
        http.post(`rest/change-slot-status/${slotID}`, { status: "student_approved" }).then((response: any) => {
            message.success("Students ir apstiprināts");
            closeModal();
            updateSlots();
        }).catch((err: any) => {
            message.error("Kļūda");
        });
    };

    const cancelStudent = () => {
        http.post(`rest/cancel-theory-exam/${slotID}`, {}, { params: { new_slot: 1 } }).then((response: any) => {
            console.log(response);
            updateSlots();
        })
    }

    return <Row justify="space-between">
        <Space>
            <Button type="link" style={{ paddingLeft: 0 }} onClick={cancelStudent}>
                Atcelt studenta pieteikumu
            </Button>
            {/* <Button type="link">
                Pārcelt studentu
            </Button> */}
        </Space>
        {
            props.onlyCancel
                ? <></>
                : <Button
                    type="link"
                    disabled={!allowApprove}
                    onClick={approveStudent}
                >
                    Apstiprināt studentu
                </Button>
        }
    </Row>
}