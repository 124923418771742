import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, message, Modal, Row, Select, Table, Tag, TimePicker, Typography } from "antd";
import { LockTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { http } from "../../helpers/http";
import { withRouter } from "react-router-dom";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { ChangeRevenueForm } from "./ChangeRevenueForm";
import { AxiosResponse } from 'axios';
import { MultiChannelLanguagesSpammer } from "../StudentSpamFilter/MultiChannelLanguagesSpammer";

const { Title } = Typography;
const { Option } = Select;

export const Activities = withRouter((props: any) => {
    const { t } = useTranslation();
    const dateFormat = "DD.MM.YYYY";
    const timeFormat = "HH:mm";
    const { permissions, study_group_id, categoryID, cities, student, refreshStudent, studentGroup } = props;
    const { id } = props.match.params;
    const [classifiers, setClassifiers] = useState({} as any)
    const [history, setHistory] = useState([] as any);
    const [loaded, setLoaded] = useState(false);
    const [pagination, setPagination] = useState({});
    const [formData, setFormData] = useState(
        {
            comment: "",
            action_date: moment(),
            action: undefined,
            next_action_date: undefined,
            next_action: undefined
        }
    )
    const [locked, setLocked] = useState(false);
    const [lockedInfo, setLockedInfo] = useState({} as any);
    const [users, setUsers] = useState([] as any);
    const [revenue, setRevenue] = useState([] as any);
    const [drivingCityPreference, setDrivingCityPreference] = useState(null as null | number);
    const [holidays, setHolidays] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [refuseCauses, setRefuseCauses] = useState([] as any);
    const [refused, setRefused] = useState(false);
    const [disableCrm, setDisableCrm] = useState(studentGroup?.disable_crm === 1);

    const activityTypes = {
        group_change: "Grupu maiņa",
        auto_notifications: "Automātiskie paziņojumi",
        status_change: "Statusa maiņa",
        manual: "Menedžera komentārs"
    }

    const [form] = Form.useForm();

    const fetchClassifiers = async () => {
        const crmStatus = await http.get('/rest/crm-status-list').then((response: any) => {
            console.log(response.data)
            return response.data;
        });
        const crmAction = await http.get('/rest/crm-action-list').then((response: any) => {
            console.log(response.data)
            return response.data;
        });
        const crmNextAction = await http.get('/rest/crm-next-action-list').then((response: any) => {
            console.log(response.data)
            return response.data;
        });
        const languages = await http.get('/rest/languages').then((response: any) => {
            return response.data.data;
        });

        setClassifiers(
            {
                status: crmStatus,
                action: crmAction,
                nextAction: crmNextAction,
                languages
            }
        )
    }

    const fetch = async () => {
        await http.get(`/rest/study-group-crms/${id}/${props.fakeID || study_group_id}`).then((response: any) => {
            const processedData = response.data.data.map((item: any) => {
                return {
                    ...item,
                    next_action_date: {
                        next_action_type: item.next_action,
                        next_action_date: item.next_action_date,
                    }
                }
            })
            setHistory(processedData);
        });
    }

    const fetchAdminRevenue = () => {
        http.get(`rest/get-admin-revenue-by-category/${id}/${categoryID}`).then((response: any) => {
            setRevenue(response.data.data)
        })
    }

    const fetchUsers = () => {
        http.get(`/rest/admins`).then((response: any) => {
            setUsers(response.data.data)
        })
    }

    const fetchHolidays = () => {
        http.get(`/rest/slot-holidays`).then((res: AxiosResponse) => {
            console.log(res.data)
            setHolidays(res.data)
        })
    }

    const checkIsHoliday = (date: moment.Moment) => {
        return holidays.some((holiday: any) => {
            return moment(holiday.holiday_date, "YYYY-MM-DD").isSame(date, "date")
        })
    }

    const unlockCrm = async () => {
        await http.post(`/rest/set-unlocked-crm/${id}`);
    }

    const setActivityFinished = async (id: any) => {
        await http.patch(`/rest/crm/${id}`, { done: 1 }).then((response: any) => {
            filterRequest({ pagination: { current: 1 }, sortField: "next_action_date", sortOrder: "descend" });
            // fetch();
        })
    }

    const filterRequest = async (params: any = { pagination: { current: 1 } }) => {
        setLoading(true);
        await http.get(`/rest/crm-sorter/${id}/${props.fakeID || study_group_id}`, { params: getParams(params) }).then((response: any) => {
            if (response.data.admin.id !== response.data.locker.id) {
                setLocked(true);
                setLockedInfo(response.data.locker)
            }
            const processedData = response.data.data.map((item: any) => {
                return {
                    ...item,
                    next_action_date: {
                        next_action_type: item.next_action,
                        next_action_date: item.next_action_date,
                    }
                }
            })
            setHistory(processedData);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }

    const getParams = (params: any) => {
        console.log(params)
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters,
        }
    }

    const getRefuseCauses = () => {
        http.get(`rest/crm-refuse-list`).then((response: any) => {
            setRefuseCauses(response.data)
        })
    }



    useEffect(() => {
        getRefuseCauses();
        fetchUsers();
        fetchAdminRevenue();
        fetchClassifiers();
        fetchHolidays();
        filterRequest({ pagination: { current: 1 }, sortField: "next_action_date", sortOrder: "descend" });
        window.addEventListener("beforeunload", (e: any) => {
            e.preventDefault();
            unlockCrm();
        });
        return () => {
            window.removeEventListener("beforeunload", (e: any) => {
                e.preventDefault();
                unlockCrm();
            });
            if (!locked) {
                unlockCrm();
            }
        };
    }, []);

    useEffect(() => {
        setLoaded(classifiers.hasOwnProperty("status"));
    }, [classifiers]);

    useEffect(() => {
        form.setFieldsValue({ driving_city: student.categories.find((el: any) => el.category_id === categoryID)?.driving_city })
    }, [student]);

    const status = {
        "school_exam": "Novirzīts skolas braukšanas eksāmenam",
        "csdd": "Novirzīts CSDD braukšanas eksāmenam",
        "e_learning": "E-apmācība",
        "extra_pmp": "Papildus pirmās palīdzības apmācība",
        "extra_medical": "Papildus medicīniskās izziņas pakalpojums",
        "cancelled_individual_driving_registration": "Students atteica individuālas braukšanas reģistrāciju",
        "student_want_med": "Students pieteicis medicīnisko izziņu",
        "student_want_pmp": "Students ir pieteicis pirmo medicīnisko palīdzību",
        "study_group_status_change": "Nomainīts rezervācijas veids grupā"
    } as any;

    const historyCols = [
        {
            title: "Tips",
            dataIndex: "activity_type",
            filters: Object.keys(activityTypes).map((key: any) => ({
                text: activityTypes[key as keyof typeof activityTypes], value: key
            })),
            render: (type: any, record: any) => {
                return <div>
                    <p style={{ marginBottom: 0 }}>{activityTypes[type as keyof typeof activityTypes]}</p>
                </div>

            }
        },
        {
            title: "Svarīgi!",
            dataIndex: "urgent",
            key: "urgent",
            render: (urgent: 1 | 0) => {
                return urgent
                    ? <ExclamationCircleTwoTone twoToneColor={"red"} style={{ fontSize: 24 }} />
                    : <></>
            },
            // filters: [
            //     {
            //         text: "Tikai svarīgie",
            //         value: 1
            //     }
            // ],
        },
        {
            title: `${t("label.date")}`,
            dataIndex: 'action_date',
            key: 'action_date',
            sorter: true,
            ...getColumnSearchProps('calendar'),
        },
        {
            title: `${t("label.next.f")}`,
            dataIndex: 'next_action_date',
            key: 'next_action_date',
            sorter: true,
            render: (activity: any) => {
                return (
                    <div>
                        {activity.next_action_date}
                        <div>{activity.next_action_type !== null ? `(${t(`crm.activity.${activity.next_action_type}`)})` : `${t("status.has_not")}`}</div>
                    </div>
                )
            },
            ...getColumnSearchProps('calendar'),
        },
        {
            title: `${t("label.status")}`,
            dataIndex: 'status',
            key: 'status',
            render: (item: any) => {
                return item === "finished" ? <Tag color="green">{t(`crm.status.${item}`)}</Tag> : t(`crm.status.${item}`)
            }
        },
        {
            title: `${t("label.activity")}`,
            dataIndex: 'action',
            key: 'action',
            render: (item: any) => item === null ? "" : t(`crm.activity.${item}`)
        },
        {
            title: `WEB valoda`,
            dataIndex: 'web_lang',
            key: 'web_lang',
            ...getColumnSearchProps(),
        },
        {
            title: `${t("label.manager")}`,
            dataIndex: 'manager',
            key: 'manager',
            ...getColumnSearchProps(),
            render: (from: any, record: any) => {
                return status[from] === undefined
                    ? record.activity_type === "auto_notifications" || record.activity_type === "group_change"
                        ? t(`auto_notifications.${from.trim()}`)
                        : <div>
                            <p style={{ marginBottom: 0 }}>
                                {from}
                            </p>
                            {
                                (!!record.app && from === "Pieteikums")
                                && <p style={{ color: "red", fontSize: 10 }}>
                                    (APP)
                                </p>
                            }
                        </div>
                    : status[from]
            },
        },
        {
            title: `Apstrādāja`,
            dataIndex: 'admin',
            key: 'admin'
        },
        {
            title: `${t("label.comment")}`,
            dataIndex: 'comment',
            key: 'comment'
        },
        {
            dataIndex: 'id',
            key: "finish",
            render: (record: any, all: any) => {
                if (all.status === "finished" || all.done) return;
                return <Button onClick={() => setActivityFinished(record)}>Apstrādāts</Button>
            }
        }
    ]

    const validateMessages = {
        required: `${t("validation.error.field_is_required")}`,
    };

    const addActivity = async (values: any) => {
        const data = {
            ...values,
            category_id: categoryID,
            action_date: values.action_date === undefined
                ? undefined
                : moment(values.action_date).format("YYYY-MM-DD") + " " + moment(values.action_time).format("HH:mm:ss"),
            next_action_date: values.next_action_date === undefined
                ? undefined
                : moment(values.next_action_date).format("YYYY-MM-DD") + " " + moment(values.next_action_time).format("HH:mm:ss"),
            student: id,
            study_group: props.fakeID || study_group_id,
            urgent: values.urgent ? 1 : 0
        }
        if (data.status === "refused") {
            Modal.confirm({
                title: "Uzmanību!",
                content: "Apstiprinot, students tiks dzēsts no grupas",
                onOk: () => {
                    http.post('/rest/crm', data).then((response: any) => {
                        filterRequest({ pagination: { current: 1 }, sortField: "next_action_date", sortOrder: "descend" });
                        refreshStudent();
                        // history.go(0);
                    });
                }
            })
        } else {
            await http.post('/rest/crm', data).then((response: any) => {
                filterRequest({ pagination: { current: 1 }, sortField: "next_action_date", sortOrder: "descend" });
                refreshStudent();
            });
        }

        form.resetFields();
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        if (filters.manager !== null) {
            filters.manager = convertManagerFilter(filters.manager[0])
        }
        filterRequest({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters,
        });
    };

    const convertManagerFilter = (manager: string) => {
        const trueFilter = Object.keys(status).filter((key: any) => {
            return status[key].toLowerCase().includes(manager.toLowerCase());
        });
        trueFilter.push(manager)
        return trueFilter;
    };

    const activityTypeHandler = (values: any) => {
        if (values.hasOwnProperty('status')) {
            if (values.status === "dont_answer") {
                form.setFieldsValue(
                    {
                        action: "phonecall",
                        action_time: moment(),
                        next_action_date: moment().add(1, 'days'),
                        next_action_time: moment().add(1, 'days'),
                        next_action: "phonecall"
                    }
                )
            }
        }
    }

    const setCurrentAdminRevenue = (id: any) => {
        http.post(`rest/change-admin-revenue-by-client/${id}`, { category: categoryID }).then((response: any) => {
            message.success("Menedžeris ir nomainīts")
            fetchAdminRevenue();
        }).catch(() => {
            message.error("Kļūda!")
        })
    };

    const forceSetRevenue = () => {
        http.post(`rest/create-admin-revenue/${id}/${study_group_id}`, { category: categoryID }).then((res: any) => {
            fetchAdminRevenue();
            message.success("Menedžeris ir nomainīts");
        }).catch((err: any) => {
            message.error("Kļūda!")
        })
    }

    const saveCrmDisabled = () => {
        http.get(`/rest/student-study-group-crm/${student.id}/${studentGroup.id}/${disableCrm ? 1 : 0}`).then(() => {
            message.success("Saglabāts")
        }).catch(() => {
            message.error("Kļūda")
        })
    }

    useEffect(() => {
        setDisableCrm(studentGroup?.disable_crm === 1)
    }, [studentGroup])

    return (
        <>
            {
                (permissions?.perms?.some((el: any) => el === "crm_notification_disabler") || permissions.ruler_of_universe)
                    // ? <Form.Item valuePropName="checked" name="crm_notifications_disabled" label="Nesūtīt automātiskos paziņojumus par šo studentu">
                    //     <Checkbox />
                    // </Form.Item>
                    ? <Card
                        title="Uzstādījumi"
                    >
                        <Row style={{ marginBottom: 10 }}>
                            <Checkbox
                                checked={disableCrm}
                                onChange={(e) => {
                                    setDisableCrm(e.target.checked)
                                }}
                            />
                            <p style={{ margin: 0, marginLeft: 10 }}><b>Nesūtīt automātiskos paziņojumus par šo studentu</b></p>
                        </Row>
                        <Button
                            onClick={saveCrmDisabled}
                            type="primary"
                        >
                            Saglabāt
                        </Button>
                    </Card>
                    : disableCrm
                        ? <p style={{ margin: 20, marginLeft: 0 }}><b>Automātiskie paziņojumi ir atslēgti</b></p>
                        : <></>
            }
            <Card style={{ marginTop: 30 }} loading={!loaded}>
                {
                    loaded
                        ? locked
                            ? <Card>
                                <Title level={3}><LockTwoTone twoToneColor="red" style={{ marginRight: 8 }} />Līdz {moment(history[0].locked_till).format(dateFormat + " " + timeFormat)}</Title>
                                <Title level={4}>Šobrīd šo sadaļu izmanto {lockedInfo.first_name} {lockedInfo.last_name}</Title>
                            </Card>
                            : <>
                                <Row justify="space-between">
                                    <Title level={4}>{t("label.activities")}</Title>
                                    <div>
                                        {
                                            revenue.length > 0
                                                ? revenue?.map((item: any, index: any) => {
                                                    return <ChangeRevenueForm
                                                        key={index}
                                                        index={index}
                                                        item={item}
                                                        setRevenue={setRevenue}
                                                        users={users}
                                                        categoryID={categoryID}
                                                        disabled={!(!!permissions.ruler_of_universe || permissions.perms.some((el: any) => el === "manager_of_managers"))}
                                                    />
                                                })
                                                : <ChangeRevenueForm
                                                    noRevenue
                                                    sgID={study_group_id}
                                                    studentID={id}
                                                    users={users}
                                                    categoryID={categoryID}
                                                    disabled={!(!!permissions.ruler_of_universe || permissions.perms.some((el: any) => el === "manager_of_managers"))}
                                                />
                                        }
                                        {/* {
                                        revenue?.map((item: any, index: any) => {
                                            if (!!item.client_edit) return <></>
                                            return <Button
                                                key={index}
                                                onClick={() => setCurrentAdminRevenue(item.id)}
                                            >
                                                Mans klients
                                            </Button>
                                        })
                                    } */}
                                        {
                                            (revenue.length === 0 && student?.web_groups_paid?.length === 0 && studentGroup.reservation_type !== "hard" && studentGroup.reservation_type !== "soft")
                                            && <Button
                                                onClick={forceSetRevenue}
                                            >
                                                Mans klients
                                            </Button>
                                        }
                                    </div>
                                </Row>
                                <Form
                                    form={form}
                                    validateMessages={validateMessages}
                                    layout="vertical"
                                    style={{ marginBottom: 30 }}
                                    onFinish={addActivity}
                                    initialValues={formData}
                                    onValuesChange={activityTypeHandler}
                                >
                                    <Form.Item name="comment" label={`${t("label.comment")}:`}>
                                        <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
                                    </Form.Item>
                                    <Row gutter={[10, 10]}>
                                        <Col span={6}>
                                            <Form.Item rules={[{ required: true }]} name="status" label={`${t("label.status")}:`}>
                                                <Select
                                                    onChange={(value) => {
                                                        if (value === "refused") {
                                                            setRefused(true)
                                                        } else {
                                                            setRefused(false)
                                                        }
                                                    }}
                                                >
                                                    {classifiers.status.map((status: any) => {
                                                        return <Option key={status} value={status}>{t(`crm.status.${status}`)}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            {
                                                refuseCauses.length > 0 && refused
                                                    ? <Form.Item rules={[{ required: true }]} name="refuse_cause" label="Atteikšanās iemesls:">
                                                        <Select>
                                                            {refuseCauses.map((cause: any) => {
                                                                return <Option key={cause} value={cause}>{t(`refuse_cause.${cause}`)}</Option>
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                    : <></>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4}>
                                            <Form.Item rules={[{ required: true }]} name="action_date" label={`${t("label.date")}:`}>
                                                <DatePicker format="YYYY-MM-DD" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item rules={[{ required: true }]} name="action_time" label={`${t("label.time")}:`}>
                                                <TimePicker format="HH:mm:ss" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item wrapperCol={{ span: 18 }} rules={[{ required: true }]} name="action" label={`${t("label.activity_type")}`}>
                                                <Select>
                                                    {classifiers.action.map((activity: any) => {
                                                        return <Option key={activity} value={activity}>{t(`crm.activity.${activity}`)}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="driving_city"
                                                label="Braukšanas pilsēta"
                                            // initialValue={student.categories.find((el: any) => el.category_id === categoryID)?.driving_city}
                                            >
                                                <Select
                                                    style={{ width: 200 }}
                                                >
                                                    {
                                                        cities.map((city: any) => {
                                                            return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item name="next_action_date" label={`${t("label.next_activity_date")}:`}>
                                                <DatePicker disabledDate={checkIsHoliday} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item name="next_action_time" label={`${t("label.next_activity_time")}:`}>
                                                <TimePicker />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item wrapperCol={{ span: 18 }} name="next_action" label={`${t("label.next_activity")}:`}>
                                                <Select>
                                                    {classifiers.nextAction.map((activity: any) => {
                                                        return <Option key={activity} value={activity}>{t(`crm.activity.${activity}`)}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item valuePropName="checked" name="urgent" label={`Svarīgs:`}>
                                                <Checkbox />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Button htmlType="submit">{t("button.confirm")}</Button>
                                </Form>
                                <Title level={4}>{t("label.history")}</Title>
                                <Table
                                    rowKey={(record) => record.id}
                                    rowClassName={(record) => !!record.urgent ? "urgent" : ""}
                                    dataSource={history}
                                    columns={historyCols}
                                    pagination={false}
                                    onChange={handleTableChange}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => { }, // click row
                                            onDoubleClick: event => { }, // double click row
                                            onContextMenu: event => { }, // right button click row
                                            onMouseEnter: event => { }, // mouse enter row
                                            onMouseLeave: event => { }, // mouse leave row
                                        };
                                    }}
                                    loading={loading}
                                    scroll={{ x: 1300 }}
                                />
                                {/* <Row justify="end">
                            <Button style={{ marginTop: 30 }} type="primary">Beigt darbu</Button>
                        </Row> */}
                            </>
                        : <></>
                }
                <Card title={"Nosūtīt studentam ziņu"}>
                    <MultiChannelLanguagesSpammer
                        students={[student.id]}
                        languages={classifiers?.languages}
                    />
                </Card>
            </Card>
        </>
    )
})