import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Modal, Popover, Rate, Row, Skeleton, Space } from "antd";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import _ from 'lodash';
import { http } from "../../helpers/http";

function UpcomingLessonCard(props: any) {
    const { lesson, removeLesson } = props;

    const [isOpen, setOpen] = useState(false);

    const slot = lesson.lesson_time_slot;
    const service = slot?.instructor_service
    const formattedDate = moment(slot.date).format("dddd, D. MMMM");
    const meetingPlace = service?.city?.find((el: any) => el.city_id === slot?.city_id)?.meeting_place;
    const city = service?.cities?.find((el: any) => el.id === slot?.city_id)?.value;
    function cancelLesson() {
        http.delete(`/rest/lessons/${lesson.id}`).then(() => {
            setOpen(false);
            removeLesson(lesson.id);
        });
    }

    useEffect(() => {
        console.log(slot)

    }, [slot])

    return <Card style={{ marginBottom: 5 }}>
        <Row gutter={20}>
            <Col xs={{ span: 24 }} md={{ span: 5 }} style={{ marginBottom: 15 }}><Badge status="default" text={<strong>{formattedDate}</strong>} /></Col>
            <Col xs={{ span: 24 }} md={{ span: 4 }} style={{ marginBottom: 15 }}>{slot.time_from} - {slot.time_to}</Col>
            <Col xs={{ span: 24 }} md={{ span: 4 }} style={{ marginBottom: 15 }}>
                <Space>
                    <UserOutlined style={{ fontSize: 36 }} />
                    <span>
                        {slot.instructor_service.instructor.first_name} {slot.instructor_service.instructor.last_name}
                    </span>
                </Space>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }} style={{ marginBottom: 15 }}>{meetingPlace}, {city}</Col>
            <Col xs={{ span: 24 }} md={{ span: 5 }} >
                <Space style={{ float: "right" }}>
                    <Button danger type="primary" onClick={() => { setOpen(true); }}>Atcelt</Button>
                    <Modal onCancel={() => { setOpen(false) }} onOk={cancelLesson} visible={isOpen}>
                        Vai tiešām vēlaties atcelt nodarbību?
                    </Modal>
                </Space>
            </Col>
        </Row>
    </Card>;
}

function DoneLessonCard(props: any) {
    const { lesson } = props;

    const slot = lesson.lesson_time_slot;
    const service = slot?.instructor_service
    const formattedDate = moment(slot.date).format("dddd, D. MMMM");
    const meetingPlace = service?.city?.find((el: any) => el.city_id === slot?.city_id)?.meeting_place;
    const city = service?.cities?.find((el: any) => el.id === slot?.city_id)?.value;

    return <Card style={{ marginBottom: 5, backgroundColor: "#90ee90", border: "1px solid transparent" }}>
        <Row gutter={20}>
            <Col xs={{ span: 24 }} md={{ span: 5 }} style={{ marginBottom: 15 }}><Badge status="success" text={<strong>{formattedDate}</strong>} /></Col>
            <Col xs={{ span: 24 }} md={{ span: 4 }} style={{ marginBottom: 15 }}>{slot.time_from} - {slot.time_to}</Col>
            <Col xs={{ span: 24 }} md={{ span: 4 }} style={{ marginBottom: 15 }}>
                <Space>
                    <UserOutlined style={{ fontSize: 36 }} />
                    <span>
                        {slot.instructor_service.instructor.first_name} {slot.instructor_service.instructor.last_name}
                    </span>
                </Space>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }} style={{ marginBottom: 15 }}>{meetingPlace}, {city}</Col>
            <Col xs={{ span: 24 }} md={{ span: 5 }} style={{ marginBottom: 15 }}>
                <Space style={{ float: "right" }}>
                    <Popover placement="left" content={
                        <div style={{ padding: 5 }}>
                            <Rate value={lesson.rating} disabled></Rate>
                            <div style={{ padding: 5, width: 250 }}>{lesson.feedback}</div>
                        </div>
                    }>
                        <Button type="text" icon={<InfoCircleOutlined />} />
                    </Popover>
                </Space>
            </Col>
        </Row>
    </Card>
}

function MissedLessonCard(props: any) {
    const { lesson } = props;

    const slot = lesson.lesson_time_slot;
    const service = slot?.instructor_service
    const formattedDate = moment(slot.date).format("dddd, D. MMMM");
    const meetingPlace = service?.city?.find((el: any) => el.city_id === slot?.city_id)?.meeting_place;
    const city = service?.cities?.find((el: any) => el.id === slot?.city_id)?.value;

    const reason = ({
        "student_didnt_come": "Students neieradās",
        "student_cancelled": "Students atcēla",
        "instructor_cancelled": "Instruktors atcēla"
    } as any)[lesson.status as any];

    return <Card style={{ marginBottom: 5, backgroundColor: "#ffa39e", border: "1px solid transparent" }}>
        <Row gutter={20}>
            <Col xs={{ span: 24 }} md={{ span: 5 }} style={{ marginBottom: 15 }}><Badge status="error" text={<strong>{formattedDate}</strong>} /></Col>
            <Col xs={{ span: 24 }} md={{ span: 4 }} style={{ marginBottom: 15 }}>{slot.time_from} - {slot.time_to}</Col>
            <Col xs={{ span: 24 }} md={{ span: 4 }} style={{ marginBottom: 15 }}>
                <Space>
                    <UserOutlined style={{ fontSize: 36 }} />
                    <span>
                        {slot.instructor_service.instructor.first_name} {slot.instructor_service.instructor.last_name}
                    </span>
                </Space>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }} style={{ marginBottom: 15 }}>{meetingPlace}, {city}</Col>
            <Col xs={{ span: 24 }} md={{ span: 5 }} style={{ marginBottom: 15 }}>
                <Space style={{ float: "right" }}>
                    <span>{reason}</span>
                </Space>
            </Col>
        </Row>
    </Card>
}

export const DrivingLessons = withRouter((props: any) => {
    const [lessonInfo, setLessonInfo] = useState({});
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (props.openedCategory) {
            http.get(`/rest/lesson_info/${props.match.params.id}`, { params: { category: props.openedCategory } })
                .then(result => {
                    setLessonInfo({
                        instructor: result.data.data.instructor,
                        lessons: result.data.data.lessons,
                    })
                    setLoaded(true);
                });
        }
    }, []);

    useEffect(() => {
        if (Object.keys(lessonInfo).length > 0) {
            setLoaded(true);
        }
    }, [lessonInfo])

    const { instructor } = lessonInfo as any;
    let { lessons } = lessonInfo as any;

    if (!loaded) {
        return <div style={{ height: "100vh" }}><Skeleton /></div>
    }

    function removeLesson(id: any) {
        if (lessons) {
            const nextLessons = lessons.filter((l: any) => l.id !== id);
            setLessonInfo({ lessons: nextLessons, instructor });
            if (nextLessons.length < 1) {
                window.location.href = "/";
            }
        }
    }

    lessons = _.sortBy(lessons, (lesson: any) => lesson.lesson_time_slot.date);

    const newLessons = lessons.filter((lesson: any) => {
        const status = lesson.lesson_time_slot.student_lessons[0].status
        return status === 'new';
    });

    const doneLessons = lessons.filter((lesson: any) => {
        const status = lesson.lesson_time_slot.student_lessons[0].status
        return status === 'done' || status.includes("exam_failed") || status.includes("exam_passed");
    });

    const lessonsMissed = lessons.filter((lesson: any) => {
        const status = lesson.lesson_time_slot.student_lessons[0].status
        return status !== "new" && status !== "done" && !status.includes("exam_failed") && !status.includes("exam_passed");
    });

    return (
        <Card
            style={{ marginBottom: 20 }}
            title={
                instructor
                ? <div>
                    <Space>
                        <span style={{ fontWeight: "bold" }}>{instructor.category.value} kategorijas braukšanas apmācības</span>
                        <span style={{ color: "#777" }}></span>
                    </Space>
                    {/* <Space style={{ float: "right" }}>
                  <Link to={`/driving/calendar/${instructor.instructor.id}`}><Button type="primary">Pieteikt nodarbību</Button></Link>
                </Space> */}
                </div>
                : <b>Nav braukšanas datu</b>
            }>

            <div style={{ fontSize: "16px", marginBottom: 5 }}>
                Ieplānotās nodarbības ({newLessons.length})
            </div>
            <div style={{ marginBottom: 20 }}>
                {newLessons.map((lesson: any) => {
                    return <UpcomingLessonCard
                        key={`upc-${lesson.id}`}
                        lesson={lesson}
                        removeLesson={removeLesson}
                    />
                })}

            </div>

            <div style={{ fontSize: "16px", marginBottom: 5 }}>
                Apmeklētās nodarbības ({doneLessons.length})
            </div>
            <div>
                {doneLessons.map((lesson: any) => {
                    return <DoneLessonCard
                        key={`donelesson-${lesson.id}`}
                        lesson={lesson}
                    />
                })}
            </div>

            <div style={{ fontSize: "16px", marginBottom: 5 }}>
                Neapmeklētās nodarbības ({lessonsMissed.length})
            </div>
            <div>
                {lessonsMissed.map((lesson: any) => {
                    return <MissedLessonCard
                        key={`missedlesson-${lesson.id}`}
                        lesson={lesson}
                    />
                })}
            </div>

        </Card>
    )
})