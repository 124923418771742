import { Layout, Select, DatePicker, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import { PaymentsTable } from "./PaymentsTable";

const { RangePicker } = DatePicker;

export const PaymentsPage = withRouter((props: any) => {

  const [instructors, setInstructors] = useState([]);
  const [monthFilter, setMonthFilter] = useState(null) as any;

  function onMonthFilterChange(value: any) {
    if (value == null) {
      setMonthFilter(null);
      return;
    }

    let [fromDate, toDate] = value;
    fromDate = fromDate.format("YYYY-MM");
    toDate = toDate.format("YYYY-MM");
    setMonthFilter([fromDate, toDate]);
  }

  useEffect(() => {
    http.get(`/rest/get-all-instructors-name-id`)
      .then(result => { setInstructors(result.data) });
  }, []);

  useEffect(() => {
    console.log(instructors)
  }, [instructors])

  const instructor_id = props.match.params.id;
  const hasInstructor = instructors?.map((i: any) => String(i.id)).indexOf(instructor_id) >= 0;

  return (
    <Layout>
      <Layout.Header style={{ padding: "0 16px", background: "rgb(240, 242, 245)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}>
        <Space>
          <Select
            loading={instructors?.length < 1}
            value={hasInstructor ? Number(instructor_id) : undefined}
            onChange={(value: any) => {
              props.history.push(`/payments/${value}`);
            }}
            placeholder="Izvēlieties instruktoru..."
            showSearch
            style={{ width: 200 }}
            filterOption={((input: any, option: any) => {
              return option && option.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }) as any}
          >
            {instructors?.map((instructor: any) =>
              <Select.Option
                key={`instructor-${instructor.id}`}
                value={instructor.id}
              >
                {instructor.first_name} {instructor.last_name}
              </Select.Option>
            )}
          </Select>
          <RangePicker onChange={onMonthFilterChange} picker="month" />
        </Space>
      </Layout.Header>
      <Layout.Content>
        <PaymentsTable monthFilter={monthFilter} instructor_id={instructor_id} />
      </Layout.Content>
    </Layout>
  );
});