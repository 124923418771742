import { Button, Card, Col, Collapse, Input, Layout, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosResponse } from 'axios';

export const EvaluatingOptionsLayout = (props: any) => {
    const [themes, setThemes] = useState([] as any);
    const [examThemes, setExamThemes] = useState([] as any);
    const [figures, setFigures] = useState([] as any);
    const [theoryQuestions, setTheoryQuestions] = useState([] as any);

    const fetchDrivingThemes = () => {
        http.get(`rest/driving-themes`).then((res: any) => {
            setThemes(res.data.data)
        })
    };

    const fetchExamThemes = () => {
        http.get(`rest/driving-exam-questions`).then((res: any) => {
            setExamThemes(res.data.data)
            console.log(res)
        })
    };

    const fetchExamFigures = () => {
        http.get(`rest/driving-exam-figures`).then((res: AxiosResponse) => {
            console.log(res)
            setFigures(res.data.data)
        })
    }

    const fetchExamTheoryQuestions = () => {
        http.get(`rest/driving-exam-stage-questions`).then((res: AxiosResponse) => {
            console.log(res)
            setTheoryQuestions(res.data.data)
        })
    }

    const updateThemes = (key: any, value: any, index: any) => {
        setThemes((prev: any) => {
            const copy = JSON.parse(JSON.stringify(prev));
            copy[index][key] = value;
            return copy;
        });
    };

    const updateExamThemes = (key: any, value: any, index: any) => {
        setExamThemes((prev: any) => {
            const copy = JSON.parse(JSON.stringify(prev));
            copy[index][key] = value;
            return copy;
        });
    };

    const updateSubjects = (key: any, value: any, indexParent: any, indexChild: any) => {
        setThemes((prev: any) => {
            const copy = JSON.parse(JSON.stringify(prev));
            copy[indexParent].subjects[indexChild][key] = value;
            return copy;
        });
    };

    const updateExamSubjects = (key: any, value: any, indexParent: any, indexChild: any) => {
        setExamThemes((prev: any) => {
            const copy = JSON.parse(JSON.stringify(prev));
            copy[indexParent].subjects[indexChild][key] = value;
            return copy;
        });
    };

    const updateFigures = (key: any, value: any, index: any) => {
        setFigures((prev: any) => {
            const copy = JSON.parse(JSON.stringify(prev));
            copy[index][key] = value
            return copy;
        })
    }

    const updateTheoryQuestions = (key: any, value: any, index: any) => {
        setTheoryQuestions((prev: any) => {
            const copy = JSON.parse(JSON.stringify(prev));
            copy[index][key] = value
            return copy;
        })
    }

    const saveData = () => {
        http.post(`rest/driving-themes`, { themes }).then((res: any) => {
            console.log(res)
            message.success("Vērtēšanas kritēriju tulkojumi ir saglabāti")
        }).catch(() => {
            message.error("Vērtēšanas kritēriju tulkojumu saglabāšanas kļūda")
        })
        http.post(`rest/driving-exam-questions`, { themes: { ...examThemes } }).then((res: any) => {
            console.log(res)
            message.success("Eksāmenu vērtēšanas kritēriju tulkojumi ir saglabāti")
        }).catch(() => {
            message.error("Eksāmenu vērtēšanas kritēriju tulkojumu saglabāšanas kļūda")
        })
        http.post(`rest/driving-exam-figures`, { themes: figures }).then((res: any) => {
            console.log(res)
            message.success("Eksāmenu figūru tulkojumi ir saglabāti")
        }).catch(() => {
            message.error("Eksāmenu figūru tulkojumu saglabāšanas kļūda")
        })
        http.post(`rest/driving-exam-stage-questions`, { themes: theoryQuestions }).then((res: any) => {
            console.log(res)
            message.success("Eksāmenu teorētisko jautājumu tulkojumi ir saglabāti")
        }).catch(() => {
            message.error("Eksāmenu teorētisko jautājumu saglabāšanas kļūda")
        })
    }

    useEffect(() => {
        fetchDrivingThemes();
        fetchExamThemes();
        fetchExamFigures();
        fetchExamTheoryQuestions();
    }, []);

    return <Layout>
        <Card
            title="Studentu vērtēšanas kritēriji"
        >
            <Row>
                <Col span={24}>
                    <Collapse>
                        {
                            themes?.map((theme: any, index: any) => {
                                return <Collapse.Panel
                                    key={index}
                                    header={<Row gutter={24}>
                                        <Col span={8}>
                                            <Input
                                                value={theme.theme_lv}
                                                placeholder="Tēmas nosaukums LV"
                                                onChange={(e: any) => {
                                                    const value = e.target.value
                                                    updateThemes("theme_lv", value, index);
                                                }}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Input
                                                value={theme.theme_en}
                                                placeholder="Tēmas nosaukums EN"
                                                onChange={(e: any) => {
                                                    const value = e.target.value
                                                    updateThemes("theme_en", value, index);
                                                }}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Input
                                                value={theme.theme_ru}
                                                placeholder="Tēmas nosaukums RU"
                                                onChange={(e: any) => {
                                                    const value = e.target.value
                                                    updateThemes("theme_ru", value, index);
                                                }}
                                            />
                                        </Col>
                                    </Row>}
                                >
                                    <h3><b>Tēmas komentārs vai īss apraksts</b></h3>
                                    <Row gutter={[24, 24]}>
                                        <Col span={8}>
                                            <Input.TextArea
                                                value={theme.tooltip_lv}
                                                placeholder={"Komentārs, vai īss apraksts LV"}
                                                onChange={(e: any) => {
                                                    const value = e.target.value
                                                    updateThemes("tooltip_lv", value, index);
                                                }}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Input.TextArea
                                                value={theme.tooltip_en}
                                                placeholder={"Komentārs, vai īss apraksts EN"}
                                                onChange={(e: any) => {
                                                    const value = e.target.value
                                                    updateThemes("tooltip_en", value, index);
                                                }}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Input.TextArea
                                                value={theme.tooltip_ru}
                                                placeholder={"Komentārs, vai īss apraksts RU"}
                                                onChange={(e: any) => {
                                                    const value = e.target.value
                                                    updateThemes("tooltip_ru", value, index);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <h3><b>Vērtēšanas kritēriji</b></h3>
                                    {
                                        theme?.subjects?.map((subj: any, ind: any) => {
                                            return <Row key={ind} gutter={[24, 24]}>
                                                <Col span={8}>
                                                    <Input.TextArea
                                                        value={subj.content_lv}
                                                        onChange={(e: any) => {
                                                            const value = e.target.value
                                                            updateSubjects("content_lv", value, index, ind);
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <Input.TextArea
                                                        value={subj.content_en}
                                                        onChange={(e: any) => {
                                                            const value = e.target.value
                                                            updateSubjects("content_en", value, index, ind);
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <Input.TextArea
                                                        value={subj.content_ru}
                                                        onChange={(e: any) => {
                                                            const value = e.target.value
                                                            updateSubjects("content_ru", value, index, ind);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        })
                                    }
                                </Collapse.Panel>
                            })
                        }
                    </Collapse>
                </Col>
                <Col span={24} style={{ paddingTop: 40 }}>
                    <h3>Eksāmeni</h3>
                    <Collapse>
                        {
                            examThemes?.map((theme: any, index: any) => {
                                return <Collapse.Panel
                                    key={index}
                                    header={<Row gutter={24}>
                                        <Col span={8}>
                                            <Input
                                                value={theme.theme_lv}
                                                placeholder="Tēmas nosaukums LV"
                                                onChange={(e: any) => {
                                                    const value = e.target.value
                                                    updateExamThemes("theme_lv", value, index);
                                                }}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Input
                                                value={theme.theme_en}
                                                placeholder="Tēmas nosaukums EN"
                                                onChange={(e: any) => {
                                                    const value = e.target.value
                                                    updateExamThemes("theme_en", value, index);
                                                }}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Input
                                                value={theme.theme_ru}
                                                placeholder="Tēmas nosaukums RU"
                                                onChange={(e: any) => {
                                                    const value = e.target.value
                                                    updateExamThemes("theme_ru", value, index);
                                                }}
                                            />
                                        </Col>
                                    </Row>}
                                >
                                    <h3><b>Tēmas komentārs vai īss apraksts</b></h3>
                                    <Row gutter={[24, 24]}>
                                        <Col span={8}>
                                            <Input.TextArea
                                                value={theme.tooltip_lv}
                                                placeholder={"Komentārs, vai īss apraksts LV"}
                                                onChange={(e: any) => {
                                                    const value = e.target.value
                                                    updateExamThemes("tooltip_lv", value, index);
                                                }}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Input.TextArea
                                                value={theme.tooltip_en}
                                                placeholder={"Komentārs, vai īss apraksts EN"}
                                                onChange={(e: any) => {
                                                    const value = e.target.value
                                                    updateExamThemes("tooltip_en", value, index);
                                                }}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Input.TextArea
                                                value={theme.tooltip_ru}
                                                placeholder={"Komentārs, vai īss apraksts RU"}
                                                onChange={(e: any) => {
                                                    const value = e.target.value
                                                    updateExamThemes("tooltip_ru", value, index);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <h3><b>Vērtēšanas kritēriji</b></h3>
                                    {
                                        theme?.subjects?.map((subj: any, ind: any) => {
                                            return <Row key={ind} gutter={[24, 24]}>
                                                <Col span={8}>
                                                    <Input.TextArea
                                                        value={subj.content_lv}
                                                        onChange={(e: any) => {
                                                            const value = e.target.value
                                                            updateExamSubjects("content_lv", value, index, ind);
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <Input.TextArea
                                                        value={subj.content_en}
                                                        onChange={(e: any) => {
                                                            const value = e.target.value
                                                            updateExamSubjects("content_en", value, index, ind);
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <Input.TextArea
                                                        value={subj.content_ru}
                                                        onChange={(e: any) => {
                                                            const value = e.target.value
                                                            updateExamSubjects("content_ru", value, index, ind);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        })
                                    }
                                </Collapse.Panel>
                            })
                        }
                    </Collapse>
                    <h3 style={{ marginTop: 40 }}>Eksāmenu figūras</h3>
                    {
                        figures?.map((figure: any, index: number) => {
                            return <Row key={figure.id} gutter={[24, 24]}>
                                <Col span={8}>
                                    <Input.TextArea
                                        value={figure.figure_lv}
                                        placeholder="Figūras nosaukums LV"
                                        onChange={(e: any) => {
                                            const value = e.target.value
                                            updateFigures("figure_lv", value, index);
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Input.TextArea
                                        value={figure.figure_en}
                                        placeholder="Figūras nosaukums EN"
                                        onChange={(e: any) => {
                                            const value = e.target.value
                                            updateFigures("figure_en", value, index);
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Input.TextArea
                                        value={figure.figure_ru}
                                        placeholder="Figūras nosaukums RU"
                                        onChange={(e: any) => {
                                            const value = e.target.value
                                            updateFigures("figure_ru", value, index);
                                        }}
                                    />
                                </Col>
                            </Row>
                        })
                    }
                    <h3 style={{ marginTop: 40 }}>Eksāmenu teorētiskie jautājumi</h3>
                    {
                        theoryQuestions?.map((question: any, index: number) => {
                            return <Row key={question.id} gutter={[24, 24]}>
                                <Col span={8}>
                                    <Input.TextArea
                                        value={question.content_lv}
                                        placeholder="Teorētiskā jautājuma nosaukums LV"
                                        onChange={(e: any) => {
                                            const value = e.target.value
                                            updateTheoryQuestions("content_lv", value, index);
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Input.TextArea
                                        value={question.content_en}
                                        placeholder="Teorētiskā jautājuma nosaukums EN"
                                        onChange={(e: any) => {
                                            const value = e.target.value
                                            updateTheoryQuestions("content_en", value, index);
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Input.TextArea
                                        value={question.content_ru}
                                        placeholder="Teorētiskā jautājuma nosaukums RU"
                                        onChange={(e: any) => {
                                            const value = e.target.value
                                            updateTheoryQuestions("content_ru", value, index);
                                        }}
                                    />
                                </Col>
                            </Row>
                        })
                    }
                </Col>
            </Row>
            <Row justify="end" style={{ marginTop: 20 }}>
                <Button
                    type="primary"
                    onClick={saveData}
                >
                    Saglabāt
                </Button>
            </Row>
        </Card>
    </Layout>
}