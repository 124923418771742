import React, { useEffect, useState } from "react";
import { Button, Card, Layout, message, Modal, PageHeader, Row, Table } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined, StarFilled } from "@ant-design/icons";
import { useHistory } from "react-router";
import { FeedbackForm } from "./FeedbackForm";
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { DiagnosticCategory } from "typescript";

export const FeedbackPage = (props: any) => {
    const { confirm } = Modal;
    const { t } = useTranslation();
    const [formVisible, setFormVisible] = useState(false);
    const [formData, setFormData] = useState({} as any);
    const [targets, setTargets] = useState([]);
    const [instructors, setInstructors] = useState([] as any);
    const [lecturers, setLecturers] = useState([] as any);
    const [cities, setCities] = useState([] as any);
    const [categories, setCategories] = useState([] as any);
    const [comments, setComments] = useState([] as any);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15,
    } as any)
    const history = useHistory();

    const fetchAllComments = async (params: any = { pagination: { current: 1 } }) => {
        await http.get('/rest/public-comments', { params: getParams(params) }).then((response: any) => {
            console.log(response)
            const { data, meta } = response.data;
            setPagination(
                {
                    current: meta.current_page,
                    pageSize: meta.per_page,
                    total: meta.total
                }
            )
            const modifiedResponse = data.map((item: any) => {
                console.log(item)
                return {
                    ...item,
                    category_id: item.category_id === null && item.category !== null ? 0 : item.category_id
                }
            })
               
            console.log(modifiedResponse)
            setComments(modifiedResponse)
        })
    }

    const fetchCities = async () => {
        await http.get(`/rest/cities`).then((response: any) => {
            setCities(response.data.data);
        })
    }

    const fetchCategories = async () => {
        await http.get(`/rest/categories`).then((response: any) => {
            setCategories(response.data.data);
        })
    }

    const fetchTargets = async () => {
        await http.get(`/rest/public-comment-target-values`).then((response: any) => {
            setTargets(response.data);
        })
    }

    const fetchInstructorsAndLecturers = async () => {
        await http.get("/rest/lecturers?no_paginate").then((response: any) => {
            setLecturers(response.data.data);
        })
        await http.get(`/rest/get-all-instructors-name-id`).then((response: any) => {
            setInstructors(response.data);
        })
    }

    const deleteComment = (id: any) => {
        confirm({
            title: 'Vai tiešām vēlaties dzēst komentāru?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Dzēst',
            okType: 'danger',
            cancelText: 'Nē',
            onOk() {
                http.delete(`/rest/public-comments/${id}`).then((response: any) => {
                    console.log(response)
                    message.success('Komentārs ir dzēsts')
                    fetchAllComments();
                }).catch(() => {
                    message.error('Dzēšanas kļūda')
                })
            },
        });
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(filters, sorter, pagination);
        fetchAllComments({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters,
        });
    };

    const getParams = (params: any) => {
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters
        }
    }

    useEffect(() => {
        document.getElementById('feedback-card')?.scrollIntoView({ behavior: "smooth" });
    }, [formData])

    useEffect(() => {
        fetchCities();
        fetchCategories();
        fetchTargets();
        fetchInstructorsAndLecturers();
        fetchAllComments();
    }, [])

    useEffect(() => {
        if (instructors.length > 0) {
            setLoading(false);
        }
    }, [instructors])

    const columns = [
        {
            title: "Datums",
            dataIndex: "created_at",
            key: "created_at",
            fixed: "left",
            width: 110,
            sorter: true,
            render: (record: any) => {
                const arrayOfTime = record.split("T");
                // console.log(arrayOfTime[0])
                return arrayOfTime[0];
            },
            ...getColumnSearchProps('calendar')
        },
        {
            title: "Lietotājs",
            children: [
                {
                    title: "Vārds",
                    dataIndex: "name",
                    key: "name",
                    fixed: "left",
                    width: 100,
                    ...getColumnSearchProps()
                },
                {
                    title: "Uzvārds",
                    dataIndex: "surname",
                    key: "surname",
                    fixed: "left",
                    width: 100,
                    ...getColumnSearchProps()
                },
            ]
        },
        {
            title: "Pilsēta",
            dataIndex: "city_id",
            sorter: true,
            key: "city",
            render: (record: any) => {
                const cityName = cities.find((el: any) => el.id === record);
                return cityName ? cityName.value : "";
            },
            filters: cities != null ? cities.map((c: any) => ({
                text: c.value, value: c.id
            })) : [],
        },
        {
            title: "Mērķis",
            dataIndex: "target",
            key: "target",
            width: 100,
            render: (record: any) => {
                return t(`label.${record}`);
            },
            filters: targets != null ? targets.map((target: any) => ({
                value: target, text: t(`label.${target}`)
            })) : [],
        },
        {
            title: "Instruktors",
            dataIndex: "instructor_id",
            key: "instructor",
            render: (record: any) => {
                const instructorObj = instructors.find((el: any) => el.id === record);
                return instructorObj ? instructorObj.first_name + " " + instructorObj?.last_name : "";
            },
            ...getColumnSearchProps()
        },
        {
            title: "Pasniedzējs",
            dataIndex: "lecturer_id",
            key: "lecturer",
            render: (record: any) => {
                const lecturerObj = lecturers.find((el: any) => el.id === record);
                return lecturerObj ? lecturerObj.first_name + " " + lecturerObj?.last_name : "";
            },
            ...getColumnSearchProps()
        },
        {
            title: <StarFilled style={{ fontSize: 18, color: "gold" }} />,
            align: "center",
            children: [
                {
                    dataIndex: "rating",
                    key: "rating",
                    width: 60,
                    align: "center",
                    sorter: true,
                    filters: [
                        { text: 1, value: 1 },
                        { text: 2, value: 2 },
                        { text: 3, value: 3 },
                        { text: 4, value: 4 },
                        { text: 5, value: 5 },
                    ],
                }
            ]

        },
        {
            title: "Publicēts",
            dataIndex: "posted",
            key: "posted",
            render: (record: any, all: any) => {
                return <>
                    {all.show_system ? <p>Sistēmā</p> : <></>}
                    {all.show_web ? <p>Web</p> : <></>}
                    {!all.show_system && !all.show_web ? <p>Nav publicēts</p> : <></>}
                </>
            },
            filters: [
                { text: "Sistēmā", value: "show_system" },
                { text: "Web", value: "show_web" },
                { text: "Nav publicēts", value: "not_published" }
            ]
        },
        {
            title: "No",
            dataIndex: "from",
            key: "from",
            render: (record: any, all: any) => {
                return <>
                    {all.from_system ? <p>No sistēmas</p> : <></>}
                    {all.from_web ? <p>No Web</p> : <></>}
                </>
            },
            filters: [
                { text: "No sistēmas", value: "from_system" },
                { text: "No Web", value: "from_web" },
            ]
        },
        {
            title: "",
            dataIndex: "id",
            key: "delete",
            width: 60,
            align: "center",
            render: (record: any) => <Button
                type="text"
                danger
                onClick={(e: any) => { deleteComment(record); e.stopPropagation() }}
            >
                <DeleteOutlined style={{ fontSize: 18 }} />
            </Button>
        },
    ] as any;



    return (
        <Layout>
            <PageHeader
                title="Atsauksmes"
                onBack={history.goBack}
                style={{ backgroundColor: "#FFFFFF" }}
            />
            <Card id="feedback-card">
                <Button type="primary" onClick={() => { setFormVisible(!formVisible); setFormData({}) }}>{formVisible ? "Atcelt" : "Pievienot"}</Button>
                {
                    formVisible
                        ? <FeedbackForm
                            refetch={fetchAllComments}
                            formVisible={setFormVisible}
                            cities={cities}
                            categories={categories}
                            instructors={instructors}
                            lecturers={lecturers}
                            targets={targets}
                            data={formData}
                        />
                        : <></>
                }
                <Table
                    style={{ marginTop: 20 }}
                    rowKey={record => record.id}
                    onChange={handleTableChange}
                    columns={loading ? [] : columns}
                    dataSource={comments}
                    pagination={{
                        showSizeChanger: false,
                        size: 'small',
                        showTotal: (total) => <div>Kopā {total} Atsauksmes</div>,
                        position: ['bottomCenter'],
                        pageSizeOptions: ['15'],
                        defaultPageSize: 15,
                        ...pagination
                    }}
                    expandable={{
                        expandedRowRender: (record: any) => {
                            return (
                                <>
                                    <p>LV</p>
                                    <p>{record.comment_lv}</p>
                                    <p>EN</p>
                                    <p>{record.comment_en}</p>
                                    <p>RU</p>
                                    <p>{record.comment_ru}</p>
                                </>
                            )
                        }
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {
                                setFormVisible(true);
                                console.log(record);
                                setFormData(record);
                            }, // click row
                            onDoubleClick: event => { }, // double click row
                            onContextMenu: event => { }, // right button click row
                            onMouseEnter: event => { }, // mouse enter row
                            onMouseLeave: event => { }, // mouse leave row
                        };
                    }}
                    loading={loading}
                    scroll={{ x: 1200 }}
                    size="middle"
                />
            </Card>
        </Layout>
    )
}