import { Button, Card, Checkbox, Col, Collapse, Form, Input, InputNumber, Layout, message, Row, Select, Space, Tabs, Typography } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { http } from "../../helpers/http";
import { ServicesPriceLog } from "./ServicesPriceLog";
import { useParams } from "react-router-dom";

const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

export const Services = (props: any) => {
    const { save } = props;
    const [form] = Form.useForm();
    const { id: group } = useParams() as any;
    const [services, setService] = useState(props.services !== undefined ? props.services : [] as any);
    const [trigger, setTrigger] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [serviceTypes, setServiceTypes] = useState([] as any);
    const [serviceHistory, setServiceHistory] = useState({
        service: null,
        group: null,
        visible: false,
    });

    const submitForm = () => {
        form.validateFields()
            .then((values: any) => {
                console.log("AIZIET")
                processData(values);
                // message.success("Informācija saglabāta");
            })
            .catch((errorInfo) => { console.log(errorInfo) });
    }

    const addService = () => {
        setService((data: any) => {
            data.push(
                {
                    id: uuidv4().split('-').join(''),
                    ind: data.length,
                    value: undefined,
                    price: undefined,
                    discount_price: undefined,
                    fees: [],
                    required_mark: false,
                    checked: false,
                    info: undefined
                }
            )
            return [...data];
        })
        setTrigger(trigger + 1);
    }

    const addAdditionalPrice = (event: any) => {
        const eventValue = event.target.value;

        const [service] = services.filter((service: any) => {
            return String(service.id) === eventValue;
        });
        const index = services.findIndex((service: any) => String(service.id) === eventValue)
        const prices = [...service.fees];

        if (prices.length === props.cities.length) return;

        prices.push(
            {
                id: uuidv4().split('-').join(''),
                parent_id: eventValue,
                city_id: undefined,
                price: undefined,
                discount_price: undefined,
            }
        )
        setService((data: any) => {
            data[index].fees = [...prices];
            return [...data]
        })
    }

    const removeService = (event: any) => {
        const eventValue = event.target.value;
        const newServicesList = services.filter((service: any) => {
            return String(service.id) !== eventValue;
        })
        setService((data: any) => {
            data = newServicesList;
            return [...data];
        }
        )
        // props.getServices(newServicesList);
    }

    const removeAdditionalPrice = (event: any) => {
        const idArray = event.target.value.split(",")
        const feeValue = idArray[0];
        const serviceId = idArray[1];
        const service = services.filter((service: any) => {
            return String(service.id) === serviceId;
        })
        const newFeesList = service[0].fees.filter((fee: any) => {
            return String(fee.id) !== feeValue;
        })
        setService((data: any) => {
            data = data.map((service: any) => {
                if (String(service.id) === serviceId) {
                    service.fees = newFeesList;
                }
                return service;
            })
            return [...data];
        })
    };


    const moveUp = (event: any) => {
        const index = Number(event.target.value);
        event.persist();
        setService((data: any) => {
            const moveDown = data[index - 1];
            const moveUp = data[index];
            data[index - 1] = moveUp;
            data[index] = moveDown;
            const newList = data.map((item: any, ind: any) => {
                return {
                    ...item,
                    ind: ind
                }
            })
            return newList;
        })
    }

    const moveDown = (event: any) => {
        const index = Number(event.target.value);
        event.persist();
        setService((data: any) => {
            const moveUp = data[index + 1];
            const moveDown = data[index];
            data[index + 1] = moveDown;
            data[index] = moveUp;
            const newList = data.map((item: any, ind: any) => {
                return {
                    ...item,
                    ind: ind
                }
            })
            return newList;
        })
    }

    const processData = (data: any) => {
        console.log(services, data);
        const copyServices = services.map((service: any) => {
            return { ...service }
        })
        const serviceIDs = [] as any;
        const feeIDs = [] as any;
        Object.keys(data).map((item: any) => {
            return item.split("-");
        }).map((service: any) => {
            if (service.length === 2) {
                const id = service[1];
                if (serviceIDs.indexOf(id) === -1) {
                    serviceIDs.push(id);
                }
            } else if (service.length === 3) {
                const id = {
                    id: service[2],
                    parent_id: service[1],
                };
                if (!feeIDs.some((el: any) => el.id === id.id && el.parent_id === id.parent_id)) {
                    feeIDs.push(id);
                }
            }
        });
        const newList = serviceIDs.map((id: any) => {
            return copyServices.filter((service: any) => {
                return String(service.id) === id
            })[0] = {
                ind: copyServices.filter((service: any) => {
                    return String(service.id) === id;
                })[0].ind,
                study_group_service_type_id: data[`study_group_service_type_id-${id}`],
                value_lv: data[`value_lv-${id}`],
                value_en: data[`value_en-${id}`],
                value_ru: data[`value_ru-${id}`],
                price: data[`price-${id}`],
                discount_price: data[`discount_price-${id}`] === undefined ? null : data[`discount_price-${id}`],
                vat: data[`vat-${id}`],
                comment_lv: data[`comment_lv-${id}`],
                comment_en: data[`comment_en-${id}`],
                comment_ru: data[`comment_ru-${id}`],
                description_lv: data[`description_lv-${id}`],
                description_en: data[`description_en-${id}`],
                description_ru: data[`description_ru-${id}`],
                def_checked: data[`type-${id}`]?.includes(`checked_by_default`) ? 1 : 0,
                required: data[`type-${id}`]?.includes(`required_mark`) ? 1 : 0,
                main_service: data[`type-${id}`]?.includes(`main`) ? 1 : 0,
                show_medical_groups: data[`type-${id}`]?.includes(`show_medical_groups`) ? 1 : 0,
                medical_check: data[`type-${id}`]?.includes('medical_check') ? 1 : 0,
                book: data[`type-${id}`]?.includes('book') ? 1 : 0,
                show_omniva: data[`type-${id}`]?.includes(`show_omniva`) ? 1 : 0,
                theory: data[`type-${id}`]?.includes(`theory`) ? 1 : 0,
                theory_service: data[`type-${id}`]?.includes(`theory_service`) ? 1 : 0,
                full_course: data[`type-${id}`]?.includes(`full_course`) ? 1 : 0,
                fees: feeIDs.filter((ids: any) => {
                    if (ids.parent_id === id) {
                        return true;
                    }
                }).map((ids: any) => {
                    return {
                        city: data[`city-${id}-${ids.id}`],
                        price: data[`price-${id}-${ids.id}`],
                        discount_price: data[`discount_price-${id}-${ids.id}`] === undefined ? null : data[`discount_price-${id}-${ids.id}`],
                    }
                })
            }
        })
        console.log(newList);
        props.getServices(newList);
    };

    const fetchServiceTypes = async () => {
        await http.get('rest/study-groups-service-types').then((response: any) => {
            console.log(response);
            setServiceTypes(response.data.data)
        })
    }

    const processServices = () => {
        const serviceTypes = [
            "checked_by_default",
            "required_mark",
            "main",
            "show_medical_groups",
            "show_omniva",
            "theory",
            "full_course",
            "theory_service",
            "medical_check",
            "book"
        ]
        const processedServices = services.map((service: any) => {
            return {
                ...service,
                types: serviceTypes.filter((t: any) => {
                    if (service[t]) return t;
                })
            }
        }).sort((a: any, b: any) => a.ind - b.ind);
        setService(processedServices);
        setLoaded(true);
    };

    useEffect(() => {
        processServices();
        fetchServiceTypes();
    }, []);

    useEffect(() => {
        const element = document.getElementById('add-service');
        if (trigger > 1) {
            element?.scrollIntoView(true);
        }
    }, [trigger]);

    useEffect(() => {
        if (save > 0) {
            submitForm();
        }
    }, [save]);

    useEffect(() => {
        form.resetFields();
    }, [loaded]);

    return (

        <Card className="add-services-forms" style={{ width: "100%", margin: 15 }}>
            <Title level={4}>
                <Space>
                    Pakalpojumi

                </Space>
            </Title>
            {/* <Button onClick={() => console.log(services)}>!</Button> */}
            {services.map((service: any, index: any) => {
                return (
                    <Card className="service-card" key={service.id}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row justify="space-between" style={{ borderBottom: "1px solid #F0F0F0", paddingBottom: 20 }}>
                                <Col span={21}>
                                    <h3 style={{ margin: "0 20px 0 0" }}>Nosaukums:</h3>
                                    <Row>
                                        <Form.Item style={{ marginRight: 20 }} name={`study_group_service_type_id-${service.id}`} initialValue={service.study_group_service_type_id}>
                                            <Select
                                                // style={{ width: 200 }}
                                                onChange={(e: any) => {
                                                    const type = serviceTypes.find((el: any) => Number(el.id) === Number(e));
                                                    form.setFieldsValue({
                                                        [`value_lv-${service.id}`]: type.value_lv,
                                                        [`value_en-${service.id}`]: type.value_en,
                                                        [`value_ru-${service.id}`]: type.value_ru,
                                                    })
                                                }}
                                            >
                                                {
                                                    serviceTypes?.map((type: any) => {
                                                        return <Select.Option
                                                            key={type.id}
                                                            value={type.id}
                                                        >
                                                            {type.value_lv}
                                                        </Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name={`value_lv-${service.id}`}
                                            initialValue={service.value_lv}
                                            style={{ marginRight: 20 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Šis lauks ir obligāts',
                                                },
                                            ]}
                                        >
                                            <Input style={{ width: "100%" }} placeholder="Latviešu val" />
                                        </Form.Item>
                                        <Form.Item style={{ marginRight: 20 }} name={`value_en-${service.id}`} initialValue={service.value_en}>
                                            <Input style={{ width: "100%" }} placeholder="Angļu val" />
                                        </Form.Item>
                                        <Form.Item style={{ marginRight: 20 }} name={`value_ru-${service.id}`} initialValue={service.value_ru}>
                                            <Input style={{ width: "100%" }} placeholder="Krievu val" />
                                        </Form.Item>
                                    </Row>
                                </Col>
                                <div>
                                    {index === services.length - 1 ? <></> : <Button value={index} onClick={moveDown} icon={<ArrowDownOutlined style={{ pointerEvents: "none" }} />}></Button>}
                                    {index !== 0 ? <Button value={index} onClick={moveUp} icon={<ArrowUpOutlined style={{ pointerEvents: "none" }} />}></Button> : <></>}
                                    <Button onClick={removeService} value={service.id} style={{ marginLeft: 10 }} icon={<DeleteOutlined style={{ pointerEvents: "none" }} />}></Button>
                                </div>
                            </Row>
                            <Row style={{ marginTop: 15 }}>
                                <Col span={10}>
                                    <Row>
                                        {/* <Col span={6}></Col> */}
                                        <Col span={24}>
                                            <Row>
                                                <Form.Item
                                                    style={{ marginLeft: 10, width: "25%" }}
                                                    name={`price-${service.id}`}
                                                    initialValue={service.price}
                                                    label="Cena:"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Šis lauks ir obligāts',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber style={{ width: "100%" }} min={0} />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ marginLeft: 10, width: "25%", whiteSpace: "nowrap" }}
                                                    name={`discount_price-${service.id}`}
                                                    initialValue={service.discount_price}
                                                    label="Atlaides cena:"
                                                >
                                                    <InputNumber style={{ width: "100%" }} min={0} />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ marginLeft: 10, width: "25%" }}
                                                    name={`vat-${service.id}`}
                                                    initialValue={service.vat}
                                                    label="PVN %"
                                                >
                                                    <InputNumber style={{ width: "100%" }} />
                                                </Form.Item>
                                                <Button
                                                    value={service.id}
                                                    style={{ margin: "30px 0 0 10px" }}
                                                    onClick={addAdditionalPrice} icon={<PlusCircleOutlined style={{ pointerEvents: "none" }} />}>
                                                </Button>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {service.fees.map((price: any) => {
                                        return <Row style={{ marginTop: 15 }} key={price.id}>
                                            <Col span={22}>
                                                <Row>
                                                    <Form.Item
                                                        style={{ marginLeft: 10, width: "32%" }}
                                                        name={`price-${service.id}-${price.id}`}
                                                        initialValue={price.price}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Šis lauks ir obligāts',
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber style={{ width: "100%" }} min={0} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginLeft: 10, width: "32%" }}
                                                        name={`discount_price-${service.id}-${price.id}`}
                                                        initialValue={price.discount_price}
                                                    >
                                                        <InputNumber style={{ width: "100%" }} min={0} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginLeft: 10 }}
                                                        name={`city-${service.id}-${price.id}`}
                                                        initialValue={price.city_id}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Šis lauks ir obligāts',
                                                            },
                                                        ]}
                                                    >
                                                        <Select style={{ width: 100 }} placeholder="pilsēta">
                                                            {props.cities.map((city: any) => {
                                                                return <Option key={city.id} value={city.id}>{city.value}</Option>
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Row>
                                            </Col>
                                            <Col span={2}>
                                                <Row>

                                                    <Button
                                                        value={[price.id, service.id]}
                                                        style={{ margin: "0 0 0 10px" }}
                                                        onClick={removeAdditionalPrice} icon={<DeleteOutlined style={{ pointerEvents: "none" }} />}>
                                                    </Button>
                                                </Row>
                                            </Col>
                                        </Row>
                                    })}
                                    <Button
                                        style={{ marginTop: 30 }}
                                        onClick={() => {
                                            setServiceHistory({
                                                service: service,
                                                visible: true,
                                                group: group
                                            })
                                        }}
                                    >
                                        Pakalpojuma cenu vēsture
                                    </Button>
                                </Col>
                                <Col span={24} lg={6}>
                                    <Form.Item initialValue={service.types} name={`type-${service.id}`} label="Veids">
                                        <Select mode="multiple">
                                            <Select.Option value={`checked_by_default`}>Atzīmēts pēc noklusējuma</Select.Option>
                                            <Select.Option value={`required_mark`}>Obligāti atzīmēts</Select.Option>
                                            <Select.Option value={`main`}>Galvenais</Select.Option>
                                            <Select.Option value={`show_medical_groups`}>PMP grupas</Select.Option>
                                            <Select.Option value={`show_omniva`}>Omniva piegāde</Select.Option>
                                            <Select.Option value={`theory`}>Teorija</Select.Option>
                                            <Select.Option value={`full_course`}>Pilnā apmācība</Select.Option>
                                            <Select.Option value={`theory_service`}>Teorijas pakalpojums</Select.Option>
                                            <Select.Option value={`medical_check`}>Medicīniskā izziņa</Select.Option>
                                            <Select.Option value={`book`}>Grāmata</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Collapse accordion style={{ margin: "30px 0 0 15px" }}>
                                        <Panel header="Komentāri" key="1" forceRender>
                                            <Tabs defaultActiveKey="1">
                                                <TabPane tab="LV" key="1" forceRender>
                                                    <Form.Item initialValue={service.comment_lv} name={`comment_lv-${service.id}`} label="Komentārs latviešu valodā">
                                                        <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                    </Form.Item>
                                                </TabPane>
                                                <TabPane tab="EN" key="2" forceRender>
                                                    <Form.Item initialValue={service.comment_en} name={`comment_en-${service.id}`} label="Komentārs angļu valodā">
                                                        <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                    </Form.Item>
                                                </TabPane>
                                                <TabPane tab="RU" key="3" forceRender>
                                                    <Form.Item initialValue={service.comment_ru} name={`comment_ru-${service.id}`} label="Komentārs krievu valodā">
                                                        <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                    </Form.Item>
                                                </TabPane>
                                            </Tabs>
                                        </Panel>
                                        <Panel header="Apraksts" key="2" forceRender>
                                            <Tabs defaultActiveKey="1">
                                                <TabPane tab="LV" key="1" forceRender>
                                                    <Form.Item initialValue={service.description_lv} name={`description_lv-${service.id}`} label="Apraksts latviešu valodā">
                                                        <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                    </Form.Item>
                                                </TabPane>
                                                <TabPane tab="EN" key="2" forceRender>
                                                    <Form.Item initialValue={service.description_en} name={`description_en-${service.id}`} label="Apraksts angļu valodā">
                                                        <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                    </Form.Item>
                                                </TabPane>
                                                <TabPane tab="RU" key="3" forceRender>
                                                    <Form.Item initialValue={service.description_ru} name={`description_ru-${service.id}`} label="Apraksts krievu valodā">
                                                        <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                    </Form.Item>
                                                </TabPane>
                                            </Tabs>
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>

                        </Form>
                    </Card>
                )
            })}
            <Row justify="space-between">
                <Button id="add-service" icon={<PlusCircleOutlined />} onClick={addService}>Pievienot pakalpojumu</Button>
            </Row>
            <ServicesPriceLog
                visible={serviceHistory.visible}
                close={() => {
                    setServiceHistory((prev: any) => {
                        return {
                            ...prev,
                            visible: false
                        }
                    })
                }}
                service={serviceHistory.service}
                groupID={serviceHistory.group}
            />
        </Card>
    )
}
