import React, { useEffect, useState } from "react";
import { MultiChannelLanguagesSpammer } from "../StudentSpamFilter/MultiChannelLanguagesSpammer";
import { http } from "../../helpers/http";
import { AxiosResponse } from "axios";
import { Layout, Card } from 'antd';
import { TemplatesList } from "./TemplatesList";

export const TemplatesLayout = () => {
    const [languages, setLanguages] = useState([] as any);
    const [templateForEdit, setTemplateForEdit] = useState(null as any);
    const [templates, setTemplates] = useState({} as any);

    const getLanguages = () => {
        http.get('/rest/languages').then((res: AxiosResponse) => {
            setLanguages(res.data.data)
        })
    }

    const getTemplates = () => {
        http.get(`rest/student-spam-templates`).then((res: AxiosResponse) => {
            setTemplates(res.data)
        })
    }

    useEffect(() => {
        getLanguages();
        getTemplates();
    }, [])

    return <Layout>
        <Layout.Header style={{ padding: "0 16px", background: "rgb(240, 242, 245)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}>
            <h3><b>Paziņojumu sagataves</b></h3>
        </Layout.Header>
        <Card>
            <MultiChannelLanguagesSpammer
                languages={languages}
                template
                templateForEdit={templateForEdit}
                resetTemplateForEdit={() => setTemplateForEdit(null)}
                refresh={getTemplates}
            />
            <TemplatesList
                languages={languages}
                setTemplateForEdit={(data: any) => setTemplateForEdit(data)}
                templates={templates}
                refresh={getTemplates}
            />
        </Card>
    </Layout>
}