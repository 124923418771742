import { message, Modal, Button, Layout, PageHeader, Card, Table, Row, Col, DatePicker, Checkbox } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { http } from "../../helpers/http";
import moment from "moment";
import { useTranslation } from "react-i18next";



export const ApplicationsView = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [data, setData] = useState([] as any);
    const [pagination, setPagination] = useState({} as any);
    const [loading, setLoading] = useState(false);
    const [cities, setCities] = useState([] as any);
    const [filters, setFilters] = useState({} as any);
    const [counters, setCounters] = useState({
        total: 0,
        thinking: 0,
        refused: 0,
        dont_answer: 0,
        is_studying: 0,
        will_study: 0
    });

    const studyStateProps = {
        // "will_study": "Mācīsies",
        // "is_studying": "Mācās",
        "full_time": "Klātienē",
        "e_learning": "Online"
    }

    const fetchCities = () => {
        http.get(`rest/cities`).then((res: AxiosResponse) => {
            setCities(res.data.data)
        }).catch(() => {
            message.error("Kļūda!")
        })
    }

    const fetchApplications = (params: any = { pagination: { current: 1 } }) => {
        setLoading(true);
        http.get(`rest/crm-predictions`, { params: getParams(params) }).then((res: any) => {
            console.log(params.filters)
            setFilters((prev: any) => { return { ...prev, ...params.filters } });
            setLoading(false);
            setData(res.data.data)
            setCounters(res.data.statuses)
            setPagination({
                current: Number(res.data.page),
                pageSize: 15,
                total: res.data.total
            })
        }).catch(() => {
            message.error("Kļūda!")
            setLoading(false);
        })
    }

    const handleTableChange = (pagination: any, filtersNew: any, sorter: any) => {
        const filtersCopy = { ...filters, ...filtersNew }
        fetchApplications({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters: filtersCopy,
        });
    };

    const getParams = (params: any) => {
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters
        }
    };

    const newTabHandle = (e: any, id: number) => {
        e.stopPropagation();
        window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}groups/student-opening/${id}`, "_blank");
    }

    const columns = [
        {
            title: 'Statuss',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (status: string, record: any) => {
                return <div>
                    <p style={{ marginBottom: 3 }}>
                        {
                            t(`crm.status.${status}`)
                        }
                    </p>
                    {
                        !!record.from_app
                        && <p style={{ color: "red", fontSize: 10 }}>
                            (APP)
                        </p>
                    }
                </div>
            },
            filters: [
                {
                    text: "Domā",
                    value: "thinking"
                },
                {
                    text: "Atteicās",
                    value: "refused"
                },
                {
                    text: "Neatbild uz zvanu",
                    value: "dont_answer"
                },
                {
                    text: "Uzsāks apmācību",
                    value: "will_study"
                },
                {
                    text: "Ir uzsācis apmācību",
                    value: "is_studying"
                }

            ]
        },
        {
            title: 'Atteikuma iemesls',
            dataIndex: 'refuse_cause',
            key: 'refuse_cause',
            align: 'center',
            width: 100,
            render: (cause: string) => {
                return cause ? t(`crm.refuse_cause.${cause}`) : ""
            },
            filters: [
                {
                    text: "nav naudas",
                    value: "no_money"
                },
                {
                    text: "liegums",
                    value: "denied"
                },
                {
                    text: "vecums nav atbilstošs",
                    value: "underage"
                },
                {
                    text: "tikai skatījās",
                    value: "only_watch"
                }
            ]
        },
        {
            title: 'Students',
            dataIndex: 'student',
            key: 'student',
            render: (student: any, record: any) => {
                return <Row>
                    {student}
                    <Button
                        type="link"
                        onClick={(e: any) => newTabHandle(e, record.student_id)}
                    >
                        <LinkOutlined />
                    </Button>
                </Row>
            },
            ...getColumnSearchProps()
        },
        {
            title: 'Studenta Tālrunis',
            dataIndex: 'phone_number',
            key: 'phone_number',
            ...getColumnSearchProps()
        },
        {
            title: 'Web pilsēta',
            dataIndex: 'web_city_name',
            key: 'web_city_name',
            ...getColumnSearchProps()
        },
        {
            title: 'Izveidots',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: any, record: any) => {
                return date ? moment(date).format("DD.MM.YYYY") : ""
            },
            ...getColumnSearchProps("calendar")
        },
        {
            title: 'Grupas Datums',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (date: any, record: any) => {
                return date ? moment(date).format("DD.MM.YYYY") : ""
            },
            ...getColumnSearchProps("calendar")
        },
        {
            title: 'Rezervācija',
            dataIndex: 'reservation_type',
            key: 'reservation_type',
            filters: [
                {
                    text: "Soft",
                    value: "soft"
                },
                {
                    text: "Hard",
                    value: "hard"
                },
                {
                    text: "Interested",
                    value: "interested"
                },
            ]
        },
        {
            title: 'Apmaksa',
            key: 'city_id',
            render: (_: any, record: any) => {
                return <div>
                    {
                        record?.registration_fee
                        && <p style={{ marginBottom: 3 }}><b>R:{record?.registration_fee}&euro;</b></p>
                    }
                    {
                        record?.theory_price
                        && <p style={{ marginBottom: 3 }}><b>T:{record?.theory_price}&euro;</b></p>
                    }
                </div>
            }
        },
        {
            title: 'Piesaistīts',
            dataIndex: 'admin_name',
            key: 'admin_name',
            ...getColumnSearchProps()
        }
    ]

    useEffect(() => {
        fetchApplications();
        fetchCities();
    }, []);

    return <Layout>
        <PageHeader
            onBack={history.goBack}
            title={"Pieteikumi"}
            style={{ backgroundColor: "white" }}
        />
        <Card
            style={{ marginTop: 30 }}
            title={<Row style={{}}>
                <Col span={12}>
                    <p><b>Kopā: {counters.total}</b></p>
                    <p><b>Domā: {counters.thinking}</b></p>
                    <p><b>Atteicās: {counters.refused}</b></p>
                    <p><b>Neatbild uz zvanu: {counters.dont_answer}</b></p>
                    <p><b>Ir uzsācis apmācību: {counters.is_studying}</b></p>
                    <p><b>Uzsāks apmācību: {counters.will_study}</b></p>
                </Col>
                {/* <Col span={12} style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>
            <div>
                <Checkbox
                    style={{ marginRight: 10 }}
                />
                <b>Online</b>
            </div>
            <div>
                <Checkbox
                    style={{ marginRight: 10 }}
                />
                <b>Klātiene</b>
            </div>
        </Col> */}
            </Row>}
        >
            <Row>
                <Col span={12}>
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}>
                        <div style={{ width: 20, height: 20, backgroundColor: "#eaf670", borderRadius: 4, marginRight: 8 }} />
                        <span>Ir mācījies senāk, tagad atkārtoti mācās tajā pašā kategorijā</span>
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{ marginBottom: 10 }}>
                        <p style={{ marginBottom: 4 }}>Atlases periods:</p>
                        <DatePicker.RangePicker
                            onChange={(dates: any) => {
                                console.log(dates)
                                fetchApplications({
                                    pagination: { current: 1 },
                                    filters: {
                                        ...filters,
                                        created_at_from: dates ? [dates[0].format("YYYY-MM-DD")] : null,
                                        created_at_till: dates ? [dates[1].format("YYYY-MM-DD")] : null
                                    }
                                })
                            }}
                        />
                    </div>
                    {
                        Object.keys(studyStateProps)?.map((key: string) => {
                            return <div key={key} style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}>
                                <Checkbox
                                    style={{ marginRight: 8 }}
                                    onChange={(e: any) => {
                                        fetchApplications({
                                            pagination: { current: 1 },
                                            filters: {
                                                ...filters,
                                                [key]: e.target.checked ? [1] : null
                                            }
                                        })
                                    }}
                                />
                                <span>{studyStateProps[key as keyof typeof studyStateProps]}</span>
                            </div>
                        })
                    }
                </Col>
            </Row>
            <Table
                rowKey={record => record.id}
                rowClassName={(record, index) => !!record.was_learning_in_past ? "was-learning-in-past" : ""}
                dataSource={data}
                columns={columns as any}
                pagination={pagination}
                onChange={handleTableChange}
                loading={loading}
                scroll={{ x: "100%" }}
            />
        </Card>
    </Layout>
}