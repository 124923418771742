import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { Row, Button, Table, Modal, Tabs } from "antd";

type ChooseTemplateModalProps = {
    setSpam: (data: any) => void;
    dbOnly?: boolean;
}

export const ChooseTemplateModal = ({ setSpam, dbOnly }: ChooseTemplateModalProps) => {
    const [languages, setLanguages] = useState([] as any);
    const [templates, setTemplates] = useState({} as any);
    const [visible, setVisible] = useState(false);

    const channelsTranslation = {
        db: "Portāls",
        email: "E-pasts",
        sms: "SMS",
        whatsapp: "Whatsapp"
    } as any;

    const getLanguages = () => {
        http.get('/rest/languages').then((res: AxiosResponse) => {
            setLanguages(res.data.data)
        })
    };

    const getTemplates = () => {
        http.get(`rest/student-spam-templates`).then((res: AxiosResponse) => {
            setTemplates(res.data)
        })
    };

    const pickTemplate = (record: any) => {
        const hash = Object.keys(record)[0];
        setSpam(record[hash]);
        setVisible(false);
    }

    const eraseAllText = () => {
        Modal.confirm({
            title: "Dzēst tekstus",
            content: "Apstiprinot tiks izdzēsi visi ievadītie teksti!",
            onOk: () => { setSpam({}) }
        })
    }

    useEffect(() => {
        getLanguages();
        getTemplates();
    }, []);

    const columns = [
        {
            title: "Nosaukums",
            render: (x: any, record: any) => {
                const hash = Object.keys(record)[0];
                return record[hash].title
            }
        },
        {
            title: "",
            render: (x: any, record: any) => {
                const hash = Object.keys(record)[0];
                return <Button
                    type="primary"
                    style={{ marginRight: 10 }}
                    onClick={() => pickTemplate(record)}
                >
                    Pielietot
                </Button>
            },
            align: "end"
        },
    ] as any;

    return <div style={{ marginTop: 10 }}>
        <Button
            onClick={eraseAllText}
            style={{ marginRight: 10 }}
        >
            Dzēst visus tekstus
        </Button>
        <Button
            type="primary"
            onClick={() => setVisible(true)}
        >
            Sagataves
        </Button>
        <Modal
            visible={visible}
            onCancel={() => setVisible(false)}
            width={"90%"}
            footer={<></>}
        >
            <h1><b>Sagataves:</b></h1>
            <Table
                rowKey={record => Object.keys(record)[0]}
                dataSource={Object.keys(templates).map((key: string) => { return { [key]: templates[key] } })}
                columns={columns}
                expandable={{
                    expandedRowRender: (record: any) => {
                        return <Tabs>
                            {
                                languages.map((lang: any) => {
                                    const hash = Object.keys(record)[0];
                                    const hasLang = Object.keys(record[hash]).some((id: string) => Number(id) === lang.id)
                                    if (!hasLang) return <></>;
                                    return <Tabs.TabPane key={lang.id} tab={lang?.iso.toUpperCase()}>
                                        <Tabs>
                                            {
                                                Object.keys(channelsTranslation).map((channelKey: string) => {
                                                    const hasChannel = Object.keys(record[hash][lang.id]).some((channel: string) => channel.includes(channelKey))
                                                    if (!hasChannel || dbOnly && channelKey !== "db") return <></>
                                                    return <Tabs.TabPane key={channelKey} tab={channelsTranslation[channelKey]}>
                                                        {
                                                            channelKey === "email"
                                                            && <div>
                                                                <p><b>E-pasta temats:</b></p>
                                                                <p>{record[hash][lang.id]?.email_subject}</p>
                                                            </div>
                                                        }
                                                        <p><b>Teksts:</b></p>
                                                        <p>{record[hash][lang.id][channelKey]}</p>
                                                    </Tabs.TabPane>
                                                })
                                            }
                                        </Tabs>
                                    </Tabs.TabPane>
                                })
                            }
                        </Tabs>
                    }
                }}
            />
        </Modal>
    </div>
}