import { Button, Card, Layout, Row, Space, Table } from "antd";
import React, { useState } from "react";
import { Link, matchPath, NavLink, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { v4 as uuidv4, v4 } from 'uuid';
import { http } from "../../helpers/http";
import { FailedTransfers } from "./Tabs/FailedTransfers";
import { IncomingTab } from "./Tabs/IncomingTab";
import { InDetail } from "./Tabs/InDetail";
import { InstructorsBalanceTab } from "./Tabs/InstructorsBalanceTab";
import { InstructorTab } from "./Tabs/InstructorTab";
import { SchoolTab } from "./Tabs/SchoolTab";
import { TransfersTab } from "./Tabs/TransfersTab";
import { Transactions } from "./Transactions/Transactions";
import { TransactionsDetails } from "./Transactions/TransactionsDetails";

export const InvoicesList = withRouter((props: any) => {

    return (
        <Layout style={{ width: '100%' }}>
            <Card>
                <Row>
                    <Space>
                        <NavLink activeClassName="active-link" to={`${props.match.url}/instructors`}><Button>Instruktoru rēķini</Button></NavLink>
                        <NavLink activeClassName="active-link" to={`${props.match.url}/instructors-balance`}><Button>Instruktoru uzkrājums</Button></NavLink>
                        <NavLink activeClassName="active-link" to={`${props.match.url}/school`}><Button>Einšteins uzkrājums</Button></NavLink>
                        <NavLink activeClassName="active-link" to={`${props.match.url}/transfers`}><Button>Depozīta apgrozījums</Button></NavLink>
                        <NavLink activeClassName="active-link" to={`${props.match.url}/transactions`}><Button>Transakcijas</Button></NavLink>
                        <NavLink activeClassName="active-link" to={`${props.match.url}/failed-transactions`}><Button>Neveiksmīgas transakcijas</Button></NavLink>
                    </Space>
                </Row>
                <Switch>
                    <Route exact path="/invoices">
                        <Redirect to="/invoices/instructors" />
                    </Route>
                    <Route path={`${props.match.path}/school`}>
                        <SchoolTab />
                    </Route>
                    <Route path={`${props.match.path}/instructors`}>
                        <InstructorTab />
                    </Route>
                    <Route path={`${props.match.path}/incoming`}>
                        <IncomingTab />
                    </Route>
                    <Route path={`${props.match.path}/transfers`}>
                        <InDetail />
                    </Route>
                    <Route path={`${props.match.path}/instructors-balance`}>
                        <InstructorsBalanceTab />
                    </Route>
                    <Route path={`${props.match.path}/transactions/:id`}>
                        <TransactionsDetails />
                    </Route>
                    <Route path={`${props.match.path}/transactions`}>
                        <Transactions />
                    </Route>
                    <Route path={`${props.match.path}/failed-transactions`}>
                        <FailedTransfers />
                    </Route>
                </Switch>
            </Card>
        </Layout>
    )
})