import { Button, Col, Input, message, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../../helpers/http";
import { DaysPlanner } from "./DaysPlanner";
import { ZoomAccountsList } from "./ZoomAccountsList";

export const TheoryExamSettings = (props: any) => {
    const [weeks, setWeeks] = useState(null as any);
    const [freeExamDays, setFreeExamDays] = useState(null as any);
    const [freeCancelSlots, setFreeCancelSlots] = useState(null as any);
    const [csddDoomClock, setCsddDoomClock] = useState(null as any);
    const [reminderCounter, setReminderCounter] = useState(null as any);
    const [accounts, setAccounts] = useState([] as any);
    const [settings, setSettings] = useState(undefined as any);

    const fetchOptions = () => {
        http.get(`rest/theory-exam-settings`).then((response: any) => {
            if (response.data.data === null) {
                setWeeks(null);
                setFreeExamDays(null);
                setFreeCancelSlots(null);
                setCsddDoomClock(null);
                setReminderCounter(null);
            } else {
                setSettings(response.data.data);
                setWeeks(response.data.data.weeks);
                setFreeExamDays(response.data.data.free_exam_days);
                setFreeCancelSlots(response.data.data.cancel_payment_hours);
                setCsddDoomClock(response.data.data.doom_clock_csdd_days);
                setReminderCounter(response.data.data.reminder_counter);
            }

        })
    };

    const fetchZoomAccounts = () => {
        http.get(`rest/zoom-accounts`).then((response: any) => {
            console.log(response)
            setAccounts(response.data.data)
        })
    };

    const saveSettings = () => {
        if (settings === undefined) {
            http.post(`rest/theory-exam-settings`, {
                weeks,
                free_exam_days: freeExamDays,
                cancel_payment_hours: freeCancelSlots,
                doom_clock_csdd_days: csddDoomClock,
                reminder_counter: reminderCounter
            }).then((response: any) => {
                message.success("Saglabāts!")
            }).catch((err: any) => {
                message.error("Kļūda");
            });
        } else {
            http.patch(`rest/theory-exam-settings/${settings.id}`, {
                weeks,
                free_exam_days: freeExamDays,
                cancel_payment_hours: freeCancelSlots,
                doom_clock_csdd_days: csddDoomClock,
                reminder_counter: reminderCounter
            }).then((response: any) => {
                message.success("Saglabāts!")
            }).catch((err: any) => {
                message.error("Kļūda");
            });
        }

    }

    useEffect(() => {
        fetchOptions();
    }, []);

    return <div>
        <DaysPlanner
            zoomAccounts={accounts}
        />
        <Button danger
            onClick={() => {
                http.post(`rest/generate-slots`).then(() => {
                    message.success("Sloti ir uzģenerēti")
                })
            }}
        >
            {"{Ģenerēt slotus}"}
        </Button>
        <Row>
            <Col span={24} style={{ paddingTop: 30 }}>
                <h3>Teorijas eksamenu uzstādījumi</h3>
                <Row gutter={[24, 12]} align="bottom">
                    <Col>
                        <p>Atļauts iesūtīt pieteikumus</p>
                        <Select
                            style={{ width: 300 }}
                            value={weeks}
                            onChange={(e: any) => {
                                setWeeks(e)
                            }}
                        >
                            <Select.Option value={1}>1 nedēļu uz priekšu</Select.Option>
                            <Select.Option value={2}>2 nedēļas uz priekšu</Select.Option>
                            <Select.Option value={3}>3 nedēļas uz priekšu</Select.Option>
                            <Select.Option value={4}>4 nedēļas uz priekšu</Select.Option>
                        </Select>
                    </Col>
                    <Col>
                        <p>Bezmaksas eksāmena kārtošanas periods (dienās)</p>
                        <Input
                            style={{ width: 120 }}
                            type="number"
                            value={freeExamDays}
                            onChange={(e: any) => {
                                e.persist();
                                setFreeExamDays(e.target.value === null ? 0 : e.target.value)
                            }}
                        />
                    </Col>
                    <Col>
                        <p>Maksas eksāmena atcelšanas periods (stundas)</p>
                        <Input
                            style={{ width: 120 }}
                            type="number"
                            value={freeCancelSlots}
                            onChange={(e: any) => {
                                e.persist();
                                setFreeCancelSlots(e.target.value === null ? 0 : e.target.value)
                            }}
                        />
                    </Col>
                    <Col>
                        <p>Dienas līdz CSDD eksāmenam pēc skolas eksāmena nokārtošanas</p>
                        <Input
                            style={{ width: 120 }}
                            type="number"
                            value={csddDoomClock}
                            onChange={(e: any) => {
                                e.persist();
                                setCsddDoomClock(e.target.value === null ? 0 : e.target.value)
                            }}
                        />
                    </Col>
                    <Col>
                        <p>Paziņojumu, par nepieciešamību atkārtot skolas eksāmenu, daudzums</p>
                        <Input
                            style={{ width: 120 }}
                            type="number"
                            value={reminderCounter}
                            onChange={(e: any) => {
                                e.persist();
                                setReminderCounter(e.target.value === null ? 0 : e.target.value)
                            }}
                        />
                    </Col>
                </Row>
                <Row>

                </Row>
                <Row justify="end">
                    <Button
                        style={{ marginLeft: 20 }}
                        type="primary"
                        onClick={saveSettings}
                    >
                        Saglabāt
                    </Button>
                </Row>
            </Col>
            <Col span={24} style={{ paddingTop: 30 }}>
                <h3>Zoom kontu konfigurācija</h3>
                <ZoomAccountsList
                    accounts={accounts}
                    fetchZoomAccounts={() => fetchZoomAccounts()}
                />
            </Col>
        </Row>
    </div>
}