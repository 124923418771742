import React, { useEffect, useState } from "react";
import { Button, Layout, message, PageHeader, Spin, Table, Tag } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import { http } from "../helpers/http";
import { getColumnSearchProps } from "../helpers/getColumnSearchProps";
import { useHistory } from "react-router-dom";

const applicationTypes = {
    "need_driving": "Iesācējs",
    "refresh_driving": "Zināšanu atsvaidzināšana",
    "test_drive": "Testa brauciens"
}

export const StudentsFromWebToInstructors = (props: any) => {

    const history = useHistory();

    const [students, setStudents] = useState([] as any);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15
    });
    const [loading, setLoading] = useState(false);
    const [types, setTypes] = useState([] as any);
    const [expandedRows, setExpandedRows] = useState([] as any);

    const fetchStudents = async (params: any = { pagination: { current: 1 } }) => {
        setLoading(true);
        await http.get("/rest/student-web-driving", { params: getParams(params) }).then((response: any) => {
            setLoading(false);
            console.log(response);
            const newStudents = response.data.data.map((student: any, index: any) => {
                return {
                    ...student,
                    blocked: response.data.restrictedIps.some((el: any) => el === student.ip) ? 1 : 0,
                    ind: index
                }
            })
            setStudents(newStudents);
            setTypes(response.data.types);
            setPagination(() => {
                return {
                    current: response.data.page,
                    pageSize: 15,
                    total: response.data.total,
                }
            })
        });
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(filters);
        fetchStudents({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters,
        });
    };

    const getParams = (params: any) => {
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters
        }
    }

    const ipBlockHandler = (ip: string, state: "block" | "unblock") => {
        if (state === "block") {
            http.post(`/rest/block-ip`, { ip: ip }).then((response: any) => {
                fetchStudents();
            }).catch(() => {
                message.error("Kļūda!")
            });
        } else {
            http.post(`/rest/unblock-ip`, { ip: ip }).then((response: any) => {
                fetchStudents();
            }).catch(() => {
                message.error("Kļūda!")
            });
        }

    }

    useEffect(() => {
        fetchStudents();
    }, [])

    const columns = [
        {
            title: "Veids",
            dataIndex: "type",
            key: "type",
            filters: types.map((type: any) => {
                return {
                    text: applicationTypes[type as keyof typeof applicationTypes],
                    value: type
                }
            }),
            render: (type: any, record: any) => {
                return applicationTypes[type as keyof typeof applicationTypes]
            },
        },
        {
            title: "Vārds",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps()
        },
        {
            title: "Uzvārds",
            dataIndex: "surname",
            key: "surname",
            ...getColumnSearchProps()
        },
        {
            title: "E-pasts",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps()
        },
        {
            title: "Tālr.Nr.",
            dataIndex: "phone",
            key: "phone",
            ...getColumnSearchProps()
        },
        {
            title: "Vēlamais laiks/datums/vieta",
            render: (_: any, record: any) => {
                return <div>
                    <div>
                        <b>Laiks:</b> {record.driving_time ?? "Nav norādīts"}
                    </div>
                    <div>
                        <b>Datums:</b> {record.driving_date ?? "Nav norādīts"}
                    </div>
                    <div>
                        <b>Vieta:</b> {record.city ?? "Nav norādīts"}
                    </div>
                </div>
            }
        },
        {
            title: "Tālr.Nr.",
            dataIndex: "phone",
            key: "phone",
            ...getColumnSearchProps()
        },
        // {
        //     title: "IP adrese",
        //     dataIndex: "ip",
        //     key: "ip",
        //     // sorter: true,
        //     render: (record: any, all: any) => {
        //         return <>
        //             {record}
        //             {
        //                 all.blocked
        //                     ? <Tag style={{ marginLeft: 10 }} color={"red"}>Bloķēts</Tag>
        //                     : ""
        //             }
        //         </>
        //     },
        //     // ...getColumnSearchProps()
        // },
        {
            title: "Datums",
            dataIndex: "created_at",
            key: "created_at",
            render: (record: any) => {
                return record.split("T")[0]
            },
            ...getColumnSearchProps('calendar')
        },
        // {
        //     title: "",
        //     dataIndex: "ip",
        //     key: "block_ip",
        //     render: (record: any, all: any) => {
        //         return <Button onClick={() => ipBlockHandler(record, all.blocked ? "unblock" : "block")} >
        //             {
        //                 all.blocked
        //                     ? "Atbloķēt"
        //                     : "Bloķēt"
        //             }
        //         </Button>
        //     }
        // },
    ] as any;

    return (
        <Layout>
            <PageHeader
                title={"Pieteikumi pie instruktoriem"}
                onBack={history.goBack}
                style={{ backgroundColor: "#FFFFFF", marginBottom: 15 }}
            />
            <Table
                rowKey={record => record.ind}
                dataSource={students}
                columns={columns}
                pagination={{
                    showSizeChanger: false,
                    size: 'small',
                    showTotal: (total) => <div>Kopā {total} pieteikumi</div>,
                    position: ['bottomCenter'],
                    pageSizeOptions: ['15'],
                    defaultPageSize: 15,
                    ...pagination
                }}
                onChange={handleTableChange}
                loading={loading}
                onRow={(record: any, rowIndex: any) => {
                    return {
                        onClick: (event: any) => {
                            window.open(`/students/edit/${record.student_id}`, "_blank")
                        }
                    };
                }}
                expandable={{
                    rowExpandable(record) {
                        return record.comment
                    },
                    expandedRowKeys: expandedRows,
                    expandedRowRender: (record: any) => {
                        return <b>{record?.comment}</b>
                    },
                    onExpand(expanded, record) {
                        setExpandedRows([...expandedRows, record.ind]);
                    },
                }}
            />
        </Layout>
    )
}