import { Col, Popover, Row } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../../../../helpers/http";

export const AttainedExamsRow = (props: any) => {
    const { slot } = props;
    const [examHistory, setExamHistory] = useState([] as any)
    const getExamsHistory = () => {
        http.get(`rest/get-theory-exam-history/${slot.student_id}`).then((response: any) => {
            const examsDone = response.data?.filter((exam: any) => {
                return exam.status.includes('exam');
            })
            console.log(examsDone)
            setExamHistory(examsDone)
        })
    }

    useEffect(() => {
        console.log(slot)
        getExamsHistory();
    }, []);

    return examHistory?.map((exam: any, index: any) => {
        return <Row style={styles.thirdRow} key={index}>
            <Col span={24}>
                <p style={styles.paragraph}>Kārtotie eksāmeni:</p>
                <Row style={styles.examRow}>
                    <Col flex={1}>
                        <b>Teorijas eksāmens</b>
                    </Col>
                    <Col flex={1}>
                        <span>{exam.exam_date}</span>
                    </Col>
                    <Col flex={1}>
                        <b>
                            {
                                exam.results.reduce((prev: any, current: any) => {
                                    return prev + current.correct_count
                                }, 0)
                            }/
                            {
                                exam.results.reduce((prev: any, current: any) => {
                                    return prev + current.question_count
                                }, 0)
                            }
                            <Popover
                                content={
                                    exam.results.map((result: any, index: any) => {
                                        return <div
                                            style={{
                                                padding: 20,
                                                border: "1px solid #c0c0c0",
                                                borderRadius: 4,
                                                marginBottom: 4
                                            }}
                                            key={index}
                                        >
                                            <Row key={index} gutter={24}>
                                                <Col>
                                                    <p style={{ marginBottom: 8 }}>Mēģinājums:</p>&nbsp;
                                                    <b>{index + 1}</b>
                                                </Col>
                                                <Col>
                                                    <p style={{ marginBottom: 8 }}>Rezultāts:</p>&nbsp;
                                                    <b>
                                                        {result.correct_count !== null ? result.correct_count : 0}/
                                                        {result.question_count !== null ? result.question_count : 0}
                                                    </b>
                                                </Col>
                                                <Col>
                                                    <p style={{ marginBottom: 8 }}>Komentārs:</p>&nbsp;
                                                    <b style={{ width: "100%", whiteSpace: "normal" }}>{result.mistakes}</b>
                                                </Col>
                                            </Row>
                                        </div>
                                    })
                                }
                                trigger="hover"
                                placement="right"
                            >
                                <InfoCircleOutlined style={{ marginLeft: 8 }} />
                            </Popover>
                        </b>
                    </Col>
                    <Col flex={1}>
                        <b
                            style={{
                                color: exam.status.includes('passed') ? "green" : "#870E0E"
                            }}
                        >
                            {exam.status.includes('passed') ? "Nokārtots" : "Nav nokārtots"}
                        </b>
                    </Col>
                </Row>
            </Col>
        </Row>
    })
}

const styles = {
    paragraph: {
        marginBottom: 6
    },
    thirdRow: {
        padding: "18px 0 20px",
    },
    examRow: {
        padding: "10px 0",
        borderBottom: "1px solid #D8D8D8",
        borderTop: "1px solid #D8D8D8"
    },
}