import { Button, Card, Col, DatePicker, Input, message, Row, Select, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../../helpers/http";
import { AxiosResponse } from "axios";
import { TransactionsDetails } from "./TransactionsDetails";

export const Transactions = (props: any) => {
    const history = useHistory();
    const [filter, setFilter] = useState({
        date_from: null as string | null,
        date_to: null as string | null,
        payee: null as string | null,
        processed: null as 1 | 0 | null,
        incoming_outgoing: null as 1 | 0 | null
    });
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1
    });
    const [data, setData] = useState([] as any);
    const [selectedTransaction, setSelectedTransaction] = useState(null as null | {} as any);
    const [loading, setLoading] = useState(false);

    const searchRecords = (page: number = 1) => {
        setLoading(true);
        let newFilters = {} as any;
        Object.keys(filter).filter((key: string) => {
            return filter[key as keyof typeof filter] !== null
        }).forEach((entry: string) => {
            newFilters = {
                ...newFilters,
                [entry]: filter[entry as keyof typeof filter]
            }
        })
        http.get(`/rest/get-student-transaction-log`, { params: { page, filters: newFilters } }).then((res: AxiosResponse) => {
            console.log(res)
            setData(res.data.data)
            setPagination({
                total: res.data.total,
                page: Number(res.data.page)
            })
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }

    const getFile = async () => {
        setLoading(true);
        await http.get(`/rest/get-transactions-link-xcel`, { responseType: 'blob', params: { filters: filter } }).then((response: any) => {
            setLoading(false);
            return new Blob([response.data]);
        }).then((blob: any) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a') as any;
            link.href = url;
            link.setAttribute('download', `${moment().format('YYYY-MM-DD HH:mm:ss')}.xls`);
            document.body.appendChild(link);
            link.click();
            if (link !== null) link.parentNode.removeChild(link);
        }).catch(() => {
            setLoading(false);
            message.error("Kļūda!");
        })
    }

    const handleTableChange = (pagination: any) => {
        console.log(pagination)
        searchRecords(pagination.current)
    }

    const columns = [
        {
            title: "Veids",
            dataIndex: "incoming_outgoing",
            render: (type: any) => {
                return type === 1
                    ? "Ienākošais"
                    : "Izejošais"
            }
        },
        {
            title: "Datums",
            dataIndex: "paid_on",
            key: "paid_on"
        },
        {
            title: "Maksātājs",
            dataIndex: "payee",
            key: "payee",
            render: (payee: any, record: any) => {
                if (Object.keys(record.merged_with).length > 0) {
                    return record.merged_with.payee.length > 0
                        ? record.merged_with.payee
                        : Number(record.merged_with.amount) < 0
                            ? "Depozītkonts"
                            : "Nav atrasts"
                } else {
                    return payee
                }
                if (record.incoming_outgoing === 1) {
                    return payee
                } else {
                    if (Object.keys(record.merged_with).length > 0) {
                        return record.merged_with.money_user
                    } else {
                        return "Nav atrasts"
                    }
                }
            }
            // render: (payee: string, record: any) => {
            //     return record.incoming_outgoing === 1
            //         ? payee
            //         : "AUTOSKOLA EINŠTEINS"
            // }
        },
        {
            title: "Saņēmējs",
            dataIndex: "merged_with",
            key: "merged_with",
            render: (merged: any, record: any) => {
                if (Object.keys(merged).length > 0) {
                    return merged.money_user?.length > 0
                        ? merged.money_user
                        : "Nav atrasts"
                } else {
                    return "Nav atrasts"
                }
                if (record.incoming_outgoing === 1) {
                    if (Object.keys(merged).length > 0) {
                        return merged.money_user
                    } else {
                        return "Nav atrasts"
                    }
                } else {
                    return record.payee
                }
            }
            // render: (payee: string, record: any) => {
            //     return record.incoming_outgoing === 0
            //         ? payee
            //         : "AUTOSKOLA EINŠTEINS"
            // }
        },
        {
            title: "Sistēmas pamatojums",
            dataIndex: "merged_with",
            key: "merged_with",
            render: (merged: any, record: any) => {
                if (Object.keys(merged).length > 0) {
                    return merged.reason
                } else {
                    return ""
                }
            }
        },
        {
            title: "Bankas pamatojums",
            dataIndex: "klix_log_reason",
            key: "klix_log_reason"
        },
        {
            title: "Piesiets",
            dataIndex: "processed",
            key: "processed",
            render: (processed: any) => {
                return processed ? "Jā" : "Nē"
            }
        },
        {
            title: "Summa",
            dataIndex: "merged_with",
            key: "merged_with",
            render: (merged: any, record: any) => {
                if (Object.keys(merged).length > 0) {
                    return merged.amount
                } else {
                    return record.klix_log_amount
                }
            }
        },
        // {
        //     title: "Tips",
        //     dataIndex: "type",
        //     key: "status"
        // },
    ] as any;

    useEffect(() => {
        console.log(filter)
    }, [filter])

    useEffect(() => {
        if (data.length > 0 && selectedTransaction) {
            setSelectedTransaction(
                data.find((item: any) => item.payment_merger_id === selectedTransaction.payment_merger_id
                )
            )
        }
    }, [data])

    return <Card style={{ margin: "20px 0" }}>
        {
            selectedTransaction
                ? <TransactionsDetails
                    record={selectedTransaction}
                    setSelectedTransaction={(val: any) => setSelectedTransaction(val)}
                    refresh={() => searchRecords(pagination.page)}
                />
                : <>
                    <Row style={{ marginBottom: 30 }} align="bottom">
                        <Col>
                            <b>Periods:</b><br />
                            <DatePicker.RangePicker
                                style={{ marginRight: 20, marginTop: 8 }}
                                value={filter.date_from ? [moment(filter.date_from, "YYYY-MM-DD"), moment(filter.date_to, "YYYY-MM-DD")] : undefined}
                                onChange={(e) => {
                                    setFilter((prev: typeof filter) => {
                                        return {
                                            ...prev,
                                            date_from: e ? moment(e[0]).format("YYYY-MM-DD") : null,
                                            date_to: e ? moment(e[1]).format("YYYY-MM-DD") : null,
                                        }
                                    })
                                }}
                            />
                        </Col>
                        <Col>
                            <b>{filter.incoming_outgoing === 1 ? "Maksātājs" : filter.incoming_outgoing === null ? "Maksātājs/Saņēmējs" : "Saņēmējs"}:</b><br />
                            <Input
                                style={{ width: 200, marginRight: 20, marginTop: 8 }}
                                value={filter.payee === null ? "" : filter.payee}
                                onChange={(e) => {
                                    e.persist();
                                    setFilter((prev: typeof filter) => {
                                        return {
                                            ...prev,
                                            payee: e.target.value.length === 0 ? null : e.target.value
                                        }
                                    })
                                }}
                            />
                        </Col>
                        {/* <Col>
                <b>Kam piesiets:</b><br />
                <Input
                    style={{ width: 200, marginRight: 20, marginTop: 8 }}
                />
            </Col> */}
                        {/* <Col>
                <b>Tips:</b><br />
                <Select
                    style={{ width: 150, marginRight: 20, marginTop: 8 }}
                >
                    <Select.Option value="merged">Izejošās</Select.Option>
                    <Select.Option value="unmerged">Ienākošās</Select.Option>
                </Select>
            </Col> */}
                        <Col>
                            <b>Status:</b><br />
                            <Select
                                style={{ width: 150, marginRight: 20, marginTop: 8 }}
                                value={filter.processed === null ? -1 : filter.processed}
                                onChange={(e) => {
                                    setFilter((prev: typeof filter) => {
                                        return {
                                            ...prev,
                                            processed: e === -1 ? null : e
                                        }
                                    })
                                }}
                            >
                                <Select.Option value={-1}>{" "}</Select.Option>
                                <Select.Option value={1}>Piesiets</Select.Option>
                                <Select.Option value={0}>Nav Piesiets</Select.Option>
                            </Select>
                        </Col>
                        <Col>
                            <b>Veids:</b><br />
                            <Select
                                style={{ width: 150, marginRight: 20, marginTop: 8 }}
                                value={filter.incoming_outgoing === null ? -1 : filter.incoming_outgoing}
                                onChange={(e) => {
                                    setFilter((prev: typeof filter) => {
                                        return {
                                            ...prev,
                                            incoming_outgoing: e === -1 ? null : e
                                        }
                                    })
                                }}
                            >
                                <Select.Option value={-1}>{" "}</Select.Option>
                                <Select.Option value={1}>Ienākošie</Select.Option>
                                <Select.Option value={0}>Izejošie</Select.Option>
                            </Select>
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column-reverse" }}>
                            <Button
                                onClick={() => searchRecords()}
                            >
                                Atlasīt
                            </Button>
                        </Col>
                        {
                            data.length > 0
                            && <Button
                                style={{ margin: "0 0 0 auto" }}
                                onClick={getFile}
                            >
                                Exports
                            </Button>
                        }
                    </Row>
                    <Table
                        rowKey={record => record.klix_log_id}
                        columns={columns}
                        dataSource={data}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (event: any) => {
                                    // history.push(`/invoices/transactions/${record.id}`, { record })
                                    setSelectedTransaction(record)
                                }
                            };
                        }}
                        pagination={{
                            current: pagination.page,
                            total: pagination.total,
                            showTotal(total, range) {
                                return `Kopā ${total} ieraksti`
                            },
                            position: ["bottomCenter"],
                            pageSize: 15
                        }}
                        onChange={handleTableChange}
                        loading={loading}
                        scroll={{ x: 1300 }}
                    />
                </>
        }

    </Card>
}