import { Card, Row, Col, Input, Button, message, Checkbox } from "antd"
import { AxiosResponse } from "axios";
import React, { useState } from "react"
import { http } from "../../helpers/http";
import { Admin } from "./SchoolsPage"

interface SchoolAdminFormProps {
    edit: boolean;
    admin: [Admin];
    setAdmin: (admin: [Admin]) => void;
    refreshSchools: () => void;
}

export const Required = () => {
    return <span style={{ color: "red" }}>
        *
    </span>
}

export const SchoolAdminForm = ({ edit, admin, setAdmin, refreshSchools }: SchoolAdminFormProps) => {
    const [loading, setLoading] = useState(false);

    const saveAdmin = () => {
        setLoading(true);
        http.post(`rest/school-admins`, { ...admin[0] }).then((res: AxiosResponse) => {
            refreshSchools();
            setLoading(false);
            message.success("Lietotājs ir saglabāts")
        }).catch((err: any) => {
            message.error("Kļūda!");
            setLoading(false);
        })
    };

    return <Card
        title="Sistēmas administrātors"
    >
        <Row>
            <Col>
                <p><b>Vārds:</b><Required /></p>
                <Input
                    style={{ marginBottom: 10 }}
                    value={admin[0].first_name}
                    name="first_name"
                    onChange={(e) => {
                        setAdmin([
                            {
                                ...admin[0],
                                [e.target.name]: e.target.value.length > 0 ? e.target.value : undefined
                            }
                        ])
                    }}
                />
                <p><b>Uzvārds:</b><Required /></p>
                <Input
                    style={{ marginBottom: 10 }}
                    value={admin[0].last_name}
                    name="last_name"
                    onChange={(e) => {
                        setAdmin([
                            {
                                ...admin[0],
                                [e.target.name]: e.target.value.length > 0 ? e.target.value : undefined
                            }
                        ])
                    }}
                />
                <p><b>Tālruņa Nr:</b><Required /></p>
                <Input
                    style={{ marginBottom: 10 }}
                    value={admin[0].phone}
                    name="phone"
                    onChange={(e) => {
                        setAdmin([
                            {
                                ...admin[0],
                                [e.target.name]: e.target.value.length > 0 ? e.target.value : undefined
                            }
                        ])
                    }}
                />
                <p><b>E-pasts:</b><Required /></p>
                <Input
                    type="email"
                    style={{ marginBottom: 10 }}
                    value={admin[0].email}
                    name="email"
                    onChange={(e) => {
                        setAdmin([
                            {
                                ...admin[0],
                                [e.target.name]: e.target.value.length > 0 ? e.target.value : undefined
                            }
                        ])
                    }}
                />
                <b>Sūtīt reģistrācijas pieprasījumu:</b>
                <Checkbox
                    style={{ marginLeft: 10 }}
                    checked={!!admin[0].new_school_user}
                    name="new_school_user"
                    onChange={(e) => {
                        setAdmin([
                            {
                                ...admin[0],
                                new_school_user: e.target.checked ? 1 : 0
                            }
                        ])
                    }}
                />
                <Col style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                        type="primary"
                        onClick={saveAdmin}
                        disabled={admin[0].first_name === undefined || admin[0].last_name === undefined || admin[0].phone === undefined || admin[0].email === undefined}
                        loading={loading}
                    >
                        Saglabāt
                    </Button>
                </Col>
            </Col>
        </Row>
    </Card>
}