import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

const { Title, Text} = Typography;

export const TeachersHandler = (props: any) => {
  const [teachers, setTeachers] = useState(props.teachers); //Pievienoti grupai
  const [isModalVisible, setModalVisible] = useState(false);  
  const [freeTeachersList, setFreeTeachersList] = useState(props.teachersList)  //Brīvie
  const [selectedRowsList, setSelectedRowsList] = useState([{}] as any); //Atzīmētās rindas 
  const [selectedRowKeys, setSelectedRowKeys] = useState([{}] as any);

  const columns = [
    {
      title: 'Vārds',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Uzvārds',
      dataIndex: 'last_name',
      key: 'last_name',
    }
  ]

  const deleteTeacher = (id: any) => {
    const [...newAddedTeachers] = teachers.filter((teacher: any) => {
      return teacher.id !== id;
    })

    setFreeTeachersList((data: any) => {
      let [...newFreeTeachers] = teachers.filter((teacher: any) => {
        return teacher.id === id;
      });
      newFreeTeachers = data.concat(newFreeTeachers);
      console.log(newFreeTeachers)
      return [...newFreeTeachers];
    })

    setTeachers(newAddedTeachers);
  }

  useEffect(() => {
    const filterTeachers = freeTeachersList.filter((teacher: any) => {
      if(teachers.length === 0) return true;
      return teachers.some((el: any) => el.id !== teacher.id)
    })
    console.log(filterTeachers, teachers, freeTeachersList)
    setFreeTeachersList(filterTeachers);
  }, [])

  useEffect(() => {
    props.returnUpdated(teachers);
  }, [teachers])

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys([...selectedRowKeys]);
      setSelectedRowsList([...selectedRows]);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
    hideSelectAll: true,
    selectedRowKeys: selectedRowKeys
  };

  

  const saveSelected = () => {
    if (selectedRowsList.length > 0) {
      setTeachers((data: any) => {
        let updatedList = data;
        updatedList = updatedList.concat(selectedRowsList);
        return updatedList;
      })
    }
    
    setFreeTeachersList((data: any) => {
      const newList = freeTeachersList.filter((teacher: any) => {
        return !selectedRowsList.some((row: any) => {
          return teacher.id === row.id;
        })
      })
      return newList;
    })
    setSelectedRowsList([])
    setModalVisible(false);
    setSelectedRowKeys([]);
  }

  return (
    <Col span={24}>
      <Title level={4}>Pasniedzēji</Title>
      {props.teachers.length > 0 ? props.teachers.map((teacher: any) => {
        return <Row key={teacher.id}>
          <Col span={6}>
            <Space>
              <Text>{`${teacher.first_name} ${teacher.last_name}`}</Text>
              <Button onClick={() => deleteTeacher(teacher.id)} icon={<DeleteOutlined />}></Button>
            </Space>
          </Col>
        </Row>
      }) : <Text>Nav pievienots neviens pasniedzējs<br /></Text>}
      <Button onClick={()=> {setModalVisible(true);console.log(selectedRowKeys)}} icon={<PlusCircleOutlined />} style={{ marginTop: 30 }}>Pievienot pasniedzēju</Button>
      <Modal title="Pasniedzēji" visible={isModalVisible} onOk={saveSelected} onCancel={() => setModalVisible(false)}>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
            columnWidth: 3
          }}
          rowKey={(record: any) => record.id}
          dataSource={freeTeachersList}
          columns={columns}
        />
      </Modal>
    </Col>
  )
}
