import { Button, Card, Layout, PageHeader, Table, message, Row } from 'antd';
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { http } from "../../helpers/http";
import { AxiosResponse, AxiosError } from 'axios';
import { PaginationProps } from "antd/lib/pagination";
import { ColumnsType } from "antd/lib/table";
import { getColumnSearchProps } from '../../helpers/getColumnSearchProps';

type Message = {
    id: number;
    student_id: number;
    student: string;
    email: string;
    phone_number: string;
    read: 1 | 0;
    messages: string[];
    created_at: string;
}

type Filter = {
    "wwr.read": 1[] | 0[];
    "st.email": string[];
    "st.phone_number": string[];
    "wwr.created_at": string[];
}

export const WhatsappNotifications = () => {
    const history = useHistory();
    const [messages, setMessages] = useState<Message[]>([]);
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 15,
        total: 0
    });
    const [loading, setLoading] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [filters, setFilters] = useState<Filter>({
        "wwr.read": [0],
        "wwr.created_at": [],
        "st.email": [],
        "st.phone_number": []
    });

    const getWhatsappNotifications = (params: any = { pagination: { current: 1 }, filters }) => {
        setLoading(true);
        http.get(`rest/whatsapp-notifications`, { params: getParams(params) }).then((res: AxiosResponse) => {
            console.log(res)
            setLoading(false);
            setMessages(
                res.data.data.map((message: any): Message => {
                    return {
                        id: message.id,
                        student_id: message.student_id,
                        student: message.student,
                        email: message.email,
                        phone_number: message.phone_number,
                        read: message.read,
                        messages: message.messages,
                        created_at: message.created_at
                    }
                })
            );
            setPagination({
                current: Number(res.data.page),
                pageSize: 15,
                total: res.data.total
            });
        }).catch((err: AxiosError) => {
            message.error("Kļūda!");
            setLoading(false);
        })
    };

    const getParams = (params: any) => {
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters
        }
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(filters, pagination)
        setFilters((prev: Filter): Filter => {
            return {
                ...prev,
                ...filters
            }
        })
        getWhatsappNotifications({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters
        });
    };

    const showAllHandler = (show: boolean) => {
        setFilters((prev: Filter): Filter => {
            return {
                ...prev,
                "wwr.read": show ? [] : [0]
            }
        })
        getWhatsappNotifications({
            pagination,
            filters: {
                ...filters,
                "wwr.read": show ? [] : [0]
            }
        });
        setShowAll(show);
    };

    const setRead = (id: number) => {
        setLoading(true);
        http.post(`rest/change-whatsapp-hook/${id}`, { read: 1 }).then((res: AxiosResponse) => {
            getWhatsappNotifications();
            setLoading(true);
        }).catch((err: AxiosError) => {
            message.error("Kļūda!");
        })
    }

    const columns: ColumnsType<Message> = [
        {
            title: "Students",
            dataIndex: "student",
            key: "student",
            ...getColumnSearchProps()
        },
        {
            title: "Tālruņa nr",
            dataIndex: "phone_number",
            key: "st.phone_number",
            ...getColumnSearchProps()
        },
        {
            title: "E-pasta adrese",
            dataIndex: "email",
            key: "st.email",
            ...getColumnSearchProps()
        },
        {
            title: "Nosūtīts",
            dataIndex: "created_at",
            key: "wwr.created_at",
            ...getColumnSearchProps('calendar')
        },
        {
            title: "Datbības",
            dataIndex: "id",
            key: "id",
            render: (id: Message['id'], record: Message): React.ReactNode => {
                return !!!record.read && <Button
                    type="primary"
                    onClick={() => setRead(id)}
                >
                    Izlasīts
                </Button>
            }
        }
    ]

    useEffect(() => {
        getWhatsappNotifications();
    }, []);

    return <Layout>
        <PageHeader
            title="Whatsapp paziņojumi"
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF", width: "100%" }}
        />
        <Card>
            <Row style={{ padding: "10px 0" }}>
                <Button
                    onClick={() => showAllHandler(!showAll)}
                >
                    {!showAll ? "Rādīt" : "Slēpt"} izlasītos ierakstus
                </Button>
            </Row>
            <Table
                size="small"
                rowKey={"id"}
                rowClassName={(record: Message) => {
                    return !!record.read
                        ? "read-whatsapp-row"
                        : "unread-whatsapp-row"
                }}
                columns={columns}
                dataSource={messages}
                pagination={pagination}
                onChange={handleTableChange}
                expandable={{
                    expandedRowRender: (record: any) => {
                        return <>
                            <h4>Ziņas:</h4>
                            {
                                record.messages.map((message: Message, index: number): React.ReactNode => {
                                    return <p style={{ border: "1px solid #dbdbdb", padding: "12px 30px", marginBottom: 10 }}>
                                        <b>{message}</b>
                                    </p>
                                })
                            }
                        </>
                    }
                }}
                loading={loading}
            />
        </Card>
    </Layout>
}