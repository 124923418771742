import { Button, Checkbox, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../../../helpers/http";

export const ApproveStudentRow = (props: any) => {
    const [rules, setRules] = useState({
        hasPmp: false,
        hasMedical: false
    } as any);


    useEffect(() => {
        const allowApprove = Object.keys(rules).filter((key: any) => {
            return rules[key]
        }).length === 2;
        props.setApprove(allowApprove);
    }, [rules]);

    return <Row style={styles.secondRow} justify="space-between">
        <Col>
            <p style={{ marginBottom: 10 }}>Prasības:</p>
            <div style={styles.cbWrap}>
                <Checkbox
                    style={styles.checkbox}
                    checked={rules.hasMedical}
                    onChange={(e: any) => {
                        setRules((prev: any) => {
                            return {
                                ...prev,
                                hasMedical: e.target.checked
                            }
                        })
                    }}
                />
                <span>Ir medicīniskā komisija</span>
            </div>
            <div style={styles.cbWrap}>
                <Checkbox
                    style={styles.checkbox}
                    checked={rules.hasPmp}
                    onChange={(e: any) => {
                        setRules((prev: any) => {
                            return {
                                ...prev,
                                hasPmp: e.target.checked
                            }
                        })
                    }}
                />
                <span>Izieti PMP kursi</span>
            </div>
        </Col>
        {/* <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button type="text">Labot</Button>
        </Col> */}
    </Row>
}

const styles = {
    secondRow: {
        borderBottom: "1px solid #D8D8D8",
        padding: "18px 0",
    },
    checkbox: {
        marginRight: 14
    },
    cbWrap: {
        marginBottom: 10
    }
}