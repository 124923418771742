import { Button, Card, Col, Input, Layout, message, PageHeader, Progress, Row, Select, Skeleton, Space, Tabs } from "antd";
import { FileExcelTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../helpers/http";
import ReactPlayer from "react-player";
import Resumable from "resumablejs";
import { random } from "lodash"
import { VideoUploader } from "./video/VideoUploader";

// const resumable = new Resumable({
//     target: process.env.REACT_APP_API_URL + "admin-api/rest/upload-instruction-video",
//     query: {
//         instruction_id: 1,
//         language_id: 1,
//         title: "Random test title",
//         token: random(1, 999999),
//         token2: random(1, 999999)
//     },
//     headers: {
//         "Authorization": `Bearer ${sessionStorage.getItem('bearer_token')}`,
//         'Accept': 'application/json',
//     },
//     testChunks: false,
//     fileParameterName: "video",
//     simultaneousUploads: 1,
//     fileType: ["mp4"]
// }) as any;

export const ElearningMain = (props: any) => {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([] as any);
    const [languages, setLanguages] = useState([] as any);
    const [descriptions, setDescriptions] = useState({
        small_text_lv: "" as string,
        full_text_lv: "" as string,
        small_text_en: "" as string,
        full_text_en: "" as string,
        small_text_ru: "" as string,
        full_text_ru: "" as string,
        thumb_lv: "" as string,
        thumb_en: "" as string,
        thumb_ru: "" as string
    });
    const [videoToken, setVideoToken] = useState(undefined as any);

    const fetchCategories = async () => {
        await http.get(`/rest/categories`).then((response: any) => {
            setCategories(response.data.data);
        });
    };

    const getVideoToken = async () => {
        await http.get(`/rest/get-instruction-token`).then((response: any) => {
            setVideoToken(response.data.token.token)
        })
    };

    const fetchLanguages = async () => {
        await http.get(`/rest/languages`).then((response: any) => {
            setLanguages(response.data.data);
            console.log(response.data.data)
        });
    }

    const fetchInstructions = async () => {
        await http.get(`/rest/learning-instruction`).then((response: any) => {
            const { data } = response;
            console.log(data)
            setDescriptions({
                full_text_en: data.full_text_en || "",
                full_text_lv: data.full_text_lv || "",
                full_text_ru: data.full_text_ru || "",
                small_text_en: data.small_text_en || "",
                small_text_lv: data.small_text_lv || "",
                small_text_ru: data.small_text_ru || "",
                thumb_en: data.thumb_en,
                thumb_lv: data.thumb_lv,
                thumb_ru: data.thumb_ru
            })
        })
    }

    const saveDescriptions = () => {
        const toSend = {
            ...descriptions,
            thumb_lv: descriptions.thumb_lv?.includes("base64") || descriptions.thumb_lv === null ? descriptions.thumb_lv : undefined,
            thumb_en: descriptions.thumb_en?.includes("base64") || descriptions.thumb_en === null ? descriptions.thumb_en : undefined,
            thumb_ru: descriptions.thumb_ru?.includes("base64") || descriptions.thumb_ru === null ? descriptions.thumb_ru : undefined,
        }
        http.patch(`/rest/learning-instruction/${1}`, toSend).then((response: any) => {
            message.success("Pamācības uzstādījumi ir saglabāti")
        })
    }

    useEffect(() => {
        fetchCategories();
        fetchLanguages();
        fetchInstructions();
        getVideoToken();
    }, []);

    useEffect(() => {
        if (languages.length > 0) {
            setLoading(false)
        }
    }, [languages])

    useEffect(() => {
        console.log(videoToken)
    }, [videoToken])

    return <Layout className="main-content">
        <PageHeader
            title="E-apmācība"
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF" }}
        />
        <Card style={{ margin: 15 }}>
            <Row gutter={[16, 16]}>
                {
                    categories.map((category: any) => {
                        if (category.exam) return;
                        return <Col key={category.id} lg={6}>
                            <Card hoverable onClick={() => history.push(`/e-learning/${category.id}`, { catName: category.value })}>
                                {category.value} kategorija
                            </Card>
                        </Col>
                    })
                }
            </Row>
            {
                loading
                    ? <Skeleton />
                    : <Card className="elearning-help-settings-card" title="Kā lietot E-apmācību (pamācība)">
                        <Row>
                            <Col span={24}>
                                <VideoUploader
                                    url={process.env.REACT_APP_API_URL + `admin-api/get-instruction-video?e_instruction_id=1`}
                                    uploadUrl={process.env.REACT_APP_API_URL + "admin-api/rest/upload-instruction-video"}
                                    token={videoToken}
                                    checkUrl={{
                                        url: `/rest/check-instruction`,
                                        param: { e_instruction_id: 1 }
                                    }}
                                    deleteURL={{
                                        url: `/rest/delete-instruction`,
                                        param: { e_instruction_id: 1 }
                                    }}
                                    data={descriptions}
                                    setData={setDescriptions}
                                    saveData={saveDescriptions}
                                />
                            </Col>
                            <Col span={24}>
                                <Tabs defaultActiveKey="1">
                                    <Tabs.TabPane key="1" tab="LV" forceRender>
                                        <Row gutter={[16, 16]}>
                                            <Col span={10}>
                                                <b>Īss skaidrojums ({500 - descriptions.small_text_lv?.length})</b>
                                                <Input.TextArea
                                                    value={descriptions.small_text_lv}
                                                    autoSize={{ maxRows: 7, minRows: 7 }}
                                                    maxLength={500}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        setDescriptions((data: any) => {
                                                            return {
                                                                ...data,
                                                                small_text_lv: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            <Col span={14}>
                                                <b>Izvērsts skaidrojums</b>
                                                <Input.TextArea
                                                    value={descriptions.full_text_lv}
                                                    autoSize={{ maxRows: 7, minRows: 7 }}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        setDescriptions((data: any) => {
                                                            return {
                                                                ...data,
                                                                full_text_lv: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="2" tab="EN" forceRender>
                                        <Row gutter={[16, 16]}>
                                            <Col span={10}>
                                                <b>Short description ({500 - descriptions.small_text_en?.length})</b>
                                                <Input.TextArea
                                                    value={descriptions.small_text_en}
                                                    autoSize={{ maxRows: 7, minRows: 7 }}
                                                    maxLength={500}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        setDescriptions((data: any) => {
                                                            return {
                                                                ...data,
                                                                small_text_en: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            <Col span={14}>
                                                <b>Long description</b>
                                                <Input.TextArea
                                                    value={descriptions.full_text_en}
                                                    autoSize={{ maxRows: 7, minRows: 7 }}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        setDescriptions((data: any) => {
                                                            return {
                                                                ...data,
                                                                full_text_en: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="3" tab="RU" forceRender>
                                        <Row gutter={[16, 16]}>
                                            <Col span={10}>
                                                <b>Краткое описание ({500 - descriptions.small_text_ru?.length})</b>
                                                <Input.TextArea
                                                    value={descriptions.small_text_ru}
                                                    autoSize={{ maxRows: 7, minRows: 7 }}
                                                    maxLength={500}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        setDescriptions((data: any) => {
                                                            return {
                                                                ...data,
                                                                small_text_ru: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            <Col span={14}>
                                                <b>Полное описание</b>
                                                <Input.TextArea
                                                    value={descriptions.full_text_ru}
                                                    autoSize={{ maxRows: 7, minRows: 7 }}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        setDescriptions((data: any) => {
                                                            return {
                                                                ...data,
                                                                full_text_ru: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Tabs.TabPane>
                                </Tabs>
                                <Button onClick={saveDescriptions} type="primary">Saglabāt aprakstus</Button>
                            </Col>
                        </Row>
                    </Card>

            }
        </Card>
    </Layout>
}