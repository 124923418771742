import { Button, Card, Col, Form, Input, Row, Select, Space, Typography } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

const { Title } = Typography;

export const CheckpointsForm = (props: any) => {
    const { save } = props;

    const types = {
        is_school_theory_exam: "Skolas teorijas eksāmens",
        is_school_driving_exam: "Skolas braukšanas eksāmens",
        is_csdd_theory_exam: "CSDD teorijas eksāmens",
        is_csdd_driving_exam: "CSDD braukšanas eksāmens",
        is_got_licence: "Iegūta vadītāja apliecība",
        is_pmp: "Pirmā medicīniskā palīdzība",
        is_medical: "Medicīniskā izziņa",
        is_registration: "Reģistrācija",
        is_theory_finished: "Teorijas apmācība pabeigta"
    } as any;

    const [form] = Form.useForm();
    const [checkpoints, setCheckpoints] = useState(() => {
        const newCpList = props.cp?.map((cp: any) => {
            return {
                ...cp,
                type: Object.keys(types).find((key: any) => {
                    return cp?.[key] === 1
                })
            }
        });
        return newCpList || []
    });

    const [trigger, setTrigger] = useState(1);

    const submitForm = () => {
        form.validateFields()
            .then((values: any) => {
                processData(values);
            })
            .catch((errorInfo) => { console.log(errorInfo) });
    };

    const addCheckpoint = () => {
        setCheckpoints((data: any) => {
            data.push(
                {
                    id: uuidv4(),
                    value_en: undefined,
                    value_lv: undefined,
                    value_ru: undefined,
                }
            )
            return [...data];
        })
        setTrigger(trigger + 1);
    };



    const removeCheckpoint = (event: any) => {
        const eventValue = event.target.value;
        const newCheckpointsList = [...checkpoints.filter((checkpoint: any) => {
            return String(checkpoint.id) !== eventValue;
        })]
        setCheckpoints((data: any) => {
            data = newCheckpointsList;
            return [...data];
        })
    };

    const moveUp = (event: any) => {
        const index = Number(event.target.value);
        event.persist();
        setCheckpoints((data: any) => {
            const moveDown = data[index - 1];
            const moveUp = data[index];
            data[index - 1] = moveUp;
            data[index] = moveDown;
            return [...data]
        })
    };

    const moveDown = (event: any) => {
        const index = Number(event.target.value);
        event.persist();
        setCheckpoints((data: any) => {
            const moveUp = data[index + 1];
            const moveDown = data[index];
            data[index + 1] = moveDown;
            data[index] = moveUp;
            return [...data]
        })
    };

    const processData = (data: any) => {
        // console.log(data)
        const newCPList = checkpoints.map((checkpoint: any) => {
            const byID = Object.keys(data).filter((el: any) => el.includes(checkpoint.id));
            return {
                value_lv: data[`${byID.find((el: any) => el.includes('value_lv'))}`],
                value_en: data[`${byID.find((el: any) => el.includes('value_en'))}`],
                value_ru: data[`${byID.find((el: any) => el.includes('value_ru'))}`],
                is_pmp: data[`${byID.find((el: any) => el.includes('type'))}`] === "is_pmp",
                is_medical: data[`${byID.find((el: any) => el.includes('type'))}`] === "is_medical" ? 1 : 0,
                is_school_theory_exam: data[`${byID.find((el: any) => el.includes('type'))}`] === "is_school_theory_exam" ? 1 : 0,
                is_school_driving_exam: data[`${byID.find((el: any) => el.includes('type'))}`] === "is_school_driving_exam" ? 1 : 0,
                is_csdd_theory_exam: data[`${byID.find((el: any) => el.includes('type'))}`] === "is_csdd_theory_exam" ? 1 : 0,
                is_csdd_driving_exam: data[`${byID.find((el: any) => el.includes('type'))}`] === "is_csdd_driving_exam" ? 1 : 0,
                is_got_licence: data[`${byID.find((el: any) => el.includes('type'))}`] === "is_got_licence" ? 1 : 0,
                is_registration: data[`${byID.find((el: any) => el.includes('type'))}`] === "is_registration" ? 1 : 0,
                is_theory_finished: data[`${byID.find((el: any) => el.includes('type'))}`] === "is_theory_finished" ? 1 : 0,
            }
        })
        props.getCheckpoints(newCPList);
    };

    // useEffect(() => {
    //     setCheckpoints(() => {
    //         const newCpList = props.cp?.map((cp: any) => {
    //             return {
    //                 ...cp,
    //                 type: Object.keys(types).find((key: any) => {
    //                     return cp?.[key] === 1
    //                 })
    //             }
    //         });
    //         return newCpList || []
    //     })
    //     console.log(props.cp)
    // }, [props.cp]);

    useEffect(() => {
        form.resetFields();
    }, [checkpoints])

    useEffect(() => {
        const element = document.getElementById('add-checkpoint');
        if (trigger > 1) {
            element?.scrollIntoView(true);
        }
    }, [trigger]);

    useEffect(() => {
        if (save > 0) {
            submitForm();
        }
    }, [save]);

    return (
        <Card className="add-services-forms" style={{ width: "100%", margin: 15 }}>
            <Title level={4}>Checkpoints</Title>
            {checkpoints?.map((checkpoint: any, index: any) => {
                return (
                    <Card className="service-card" key={checkpoint.id}>
                        <Form
                            form={form}
                            layout="horizontal"
                        >
                            <Row align="bottom" style={{ marginTop: 15 }}>
                                <Space>
                                    <Form.Item
                                        name={`value_lv-${checkpoint.id}`}
                                        label="Nosaukums:"
                                        initialValue={checkpoint.value_lv}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Šis lauks ir obligāts',
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: "100%" }} placeholder="Latviešu val" />
                                    </Form.Item>
                                    <Form.Item name={`value_en-${checkpoint.id}`} initialValue={checkpoint.value_en}>
                                        <Input style={{ width: "100%" }} placeholder="Angļu val" />
                                    </Form.Item>
                                    <Form.Item name={`value_ru-${checkpoint.id}`} initialValue={checkpoint.value_ru}>
                                        <Input style={{ width: "100%" }} placeholder="Krievu val" />
                                    </Form.Item>

                                    {index === checkpoints.length - 1 ? <></> : <Button value={index} onClick={moveDown} icon={<ArrowDownOutlined style={{ pointerEvents: "none" }} />}></Button>}
                                    {index !== 0 ? <Button value={index} onClick={moveUp} icon={<ArrowUpOutlined style={{ pointerEvents: "none" }} />}></Button> : <></>}
                                    <Button onClick={removeCheckpoint} value={checkpoint.id} style={{ marginLeft: 10 }} icon={<DeleteOutlined style={{ pointerEvents: "none" }} />}></Button>
                                </Space>
                                <Col span={24} style={{ marginTop: 8 }}>
                                    <Row>
                                        <Form.Item
                                            label="Tips"
                                            name={`type-${checkpoint.id}`}
                                            initialValue={checkpoint.type}
                                        >
                                            <Select style={{ width: 250 }}>
                                                {
                                                    Object.keys(types).map((key: any, index: any) => {
                                                        return <Select.Option key={index} value={key}>
                                                            {types[key]}
                                                        </Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                )
            })}
            <Row justify="space-between">
                <Button id="add-checkpoint" icon={<PlusCircleOutlined />} onClick={addCheckpoint}>Pievienot checkpoint</Button>
            </Row>
        </Card>
    )
}