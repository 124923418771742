import { Button, Checkbox, Row, Select, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { http } from '../../helpers/http';

type CategoryCitySearchExceptionFormProps = {
    categories: any;
    cities: any;
    exception: any;
    refresh: () => void;
    setLoading: (loading: boolean) => void;
}

function CategoryCitySearchExceptionForm(props: CategoryCitySearchExceptionFormProps) {
    const [data, setData] = useState({
        category: props.exception?.category_id,
        city: props.exception?.city_id,
        active: props.exception?.active,
    })

    const save = (active: 1 | 0 | undefined = data.active) => {
        props.setLoading(true)
        http.get(`rest/set-disable-available-instructor-week-check/${data.category}/${data.city}/${active}`).then(() => {
            props.refresh();
            if (props.exception) {
                message.success("Izmaiņas saglabātas");
                return;
            }
            setData({
                category: undefined,
                city: undefined,
                active: undefined,
            });
        }).catch(() => {
            message.error("Neizdevās saglabāt izmaiņas");
        });
    }

    useEffect(() => {
        // if (props.exception) {
        //     save();
        // }
    }, [data.active])

    return <Row style={{ marginBottom: 20 }}>
        <div style={{ width: 200, marginRight: 20 }}>
            <Select
                style={{ width: 200 }}
                value={data.category}
                disabled={props.exception}
                placeholder="Kategorija"
                onChange={(value: any) => setData((prev: any) => { return { ...prev, category: value } })}
            >
                {props.categories.map((category: any) => <Select.Option key={category.id} value={category.id}>{category.value}</Select.Option>)}
            </Select>
        </div>
        <div style={{ width: 200, marginRight: 20 }}>
            <Select
                style={{ width: 200 }}
                value={data.city}
                disabled={props.exception}
                placeholder="Pilsēta"
                onChange={(value: any) => setData((prev: any) => { return { ...prev, city: value } })}
            >
                {props.cities.map((city: any) => <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>)}
            </Select>
        </div>
        <div style={{ marginRight: 20 }}>
            <b>Aktīvs</b>
            <Checkbox
                style={{ marginLeft: 10 }}
                checked={!!data.active}
                onChange={(e) => {
                    if (props.exception) {
                        save(e.target.checked ? 1 : 0);
                    }
                    setData((prev: any) => { return { ...prev, active: e.target.checked ? 1 : 0 } })
                }}
            />
        </div>
        {
            props.exception
                ? <></>
                : <Button
                    type="primary"
                    onClick={() => save()}
                >
                    Pievienot
                </Button>
        }
    </Row >
}

export default CategoryCitySearchExceptionForm