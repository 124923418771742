import { Button, Card, Col, Collapse, Form, Input, Layout, message, Modal, PageHeader, Row, Select, Spin, Tabs, Tag } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../helpers/http";
import { indexOf } from "lodash";

export const MassGroupsEdit = (props: any) => {
    const history = useHistory();
    const [cities, setCities] = useState([] as any);
    const filters = JSON.parse(sessionStorage.getItem("groupsTableState") as string)?.filters;
    const [groupsAffected, setGroupsAffected] = useState([]);
    const [services, setServices] = useState([]);
    const [servicesPrices, setServicesPrices] = useState([] as any);
    const [groupFields, setGroupFields] = useState([]);
    const [loadingServices, setLoadingServices] = useState(false);
    const [registrationPrices, setRegistrationPrices] = useState({
        registration_fee: null,
        registration_discount: null,
        registration_vat: null,
        city_prices: [] as any
    } as any);

    const getCities = () => {
        http.get('rest/cities').then((response: any) => {
            setCities(response.data.data)
        })
    }

    const getAllGroupsIds = async () => {
        await http.get("/rest/study-groups", { params: { filters, only_ids: 1 } }).then((response: any) => {
            setGroupsAffected(response.data.data)
        })
    };

    const getGroupFields = async () => {
        await http.get(`rest/get-mass-update-group-fields`).then((response: any) => {
            setGroupFields(response.data.data)
        })
    };

    const getGroupServicesFields = async () => {
        if (services.length > 0) return;
        setLoadingServices(true);
        await http.post(`rest/get-mass-update-group-services`, { ids: groupsAffected }).then((response: any) => {
            setServices(response.data.data);
            setLoadingServices(false);
        })
    };

    const getGroupServicesPrices = async () => {
        if (services.length > 0) return;
        setLoadingServices(true);
        http.post(`rest/get-mass-update-group-service-prices`, { ids: groupsAffected }).then((response: any) => {
            setServicesPrices(response.data.data.services);
            setLoadingServices(false);
        })
        http.post('rest/get-mass-update-group-registration-cities', { ids: groupsAffected }).then((response: any) => {
            setRegistrationPrices((prev: any) => {
                return {
                    ...prev,
                    city_prices: response.data.registration_cities.map((id: any) => {
                        return {
                            city_id: id,
                            registration_fee: null,
                            registration_discount: null
                        }
                    })
                }
            })
        })
    };

    const addAdditionalPrice = (id: any) => {
        setServicesPrices((prev: any) => {
            const copyServices = [...prev];
            const newServices = copyServices.map((service: any) => {
                if (Number(service.study_group_service_type_id) === Number(id)) {
                    if (!service.hasOwnProperty('city_prices')) {
                        service = { ...service, city_prices: [] }
                    }
                    service.city_prices.push({
                        city_id: null,
                        from_db: 0,
                    })
                }
                return service;
            })
            return newServices;
        })
    };

    const addAdditionalRegistrationPrice = () => {
        setRegistrationPrices((prev: any) => {
            return {
                ...prev,
                city_prices: [
                    ...prev.city_prices,
                    {
                        ind: prev.city_prices.length,
                        city_id: null,
                        registration_fee: null,
                        registration_discount: null
                    }
                ]
            }
        })
    };

    const deleteAdditionalPrice = (service: any, ind: any) => {
        setServicesPrices((prev: any) => {
            const copy = [...prev];
            const newServices = copy.map((serviceOld: any) => {
                if (serviceOld.study_group_service_type_id === service.study_group_service_type_id) {
                    serviceOld.city_prices = serviceOld.city_prices.filter((price: any, index: any) => {
                        return index !== ind;
                    })
                }
                return serviceOld;
            })
            return newServices;
        })
    }

    const deleteAdditionalRegistrationPrice = (ind: any) => {
        setRegistrationPrices((prev: any) => {
            const copy = [...prev.city_prices];
            const newPrices = copy.filter((city: any) => {
                return ind !== city.ind
            }).map((city: any, index: any) => {
                return {
                    ...city,
                    ind: index
                }
            })
            return {
                ...prev,
                city_prices: newPrices
            }
        })
    }

    const newPriceDataHelper = (index: any, key: any, value: any) => {
        setServicesPrices((prev: any) => {
            const copy = [...prev];
            const newServices = copy.map((item: any, ind: any, self: any) => {
                if (self.indexOf(item) === index) {
                    return {
                        ...item,
                        [key]: Number(value) === 0 ? null : value
                    }
                } else return item
            })
            return newServices;
        })
    }

    const newCityPriceDataHelper = (service: any, index: any, key: any, value: any) => {
        setServicesPrices((prev: any) => {
            const copy = [...prev];
            const newServices = copy.map((serviceOld: any) => {
                if (service.study_group_service_type_id === serviceOld.study_group_service_type_id) {
                    if (Number(value) === 0) {
                        delete serviceOld.city_prices[index][key]
                    } else {
                        serviceOld.city_prices[index] = {
                            ...serviceOld.city_prices[index],
                            [key]: Number(value) === 0 ? null : value
                        }
                    }
                }
                return serviceOld;
            });
            return newServices;
        })
    }

    const registrationCityDataHelper = (index: any, key: any, value: any) => {
        setRegistrationPrices((prev: any) => {
            const copy = [...prev.city_prices]
            const updatedCities = copy.map((city: any, ind: any) => {
                if (ind === index) {
                    return {
                        ...city,
                        [key]: value
                    }
                }
                return city
            })
            return {
                ...prev,
                city_prices: updatedCities
            }
        })
    }

    const massUpdateGroups = (values: any) => {
        Modal.confirm({
            title: "Izmainīt grupu pakalpojumu datus",
            content: "Apstiprinot, tiks izmainīti ievadītie komentāri un apraksti, visām atzīmētajām grupām.",
            onOk() {
                const toSend = {
                    ids: groupsAffected,
                    fields: Object.fromEntries(Object.entries(values).filter((entry: any) => {
                        return entry[1] !== undefined;
                    }))
                }
                http.post('rest/make-mass-update-group', { ...toSend }).then((response: any) => {
                    message.success("Dati veiksmīgi izmainīti");
                    history.goBack();
                }).catch((err: any) => {
                    message.error("Kļūda!")
                })
            }
        })
    }

    const massUpdateServices = (values: any) => {
        Modal.confirm({
            title: "Izmainīt grupu pakalpojumu datus",
            content: "Apstiprinot, tiks izmainīti ievadītie komentāri un apraksti, visām atzīmētajām grupām.",
            onOk() {
                const arrayFromValues = Object.entries(values).filter((item: any) => {
                    return item[1] !== undefined
                });
                const changed = Object.keys(values).filter((value: any) => {
                    return values[value] !== undefined;
                })
                const types = changed.map((type: any) => {
                    return type.split("-")[0]
                }).filter((item: any, index: any, self: any) => {
                    return self.indexOf(item) === index;
                });
                const valuesObject = types.flatMap((type: any) => {
                    const relatedParams = arrayFromValues.filter((item: any) => {
                        return item[0].includes(type)
                    }).map((item: any) => {
                        return [
                            item[0].split("-")[1],
                            item[1]
                        ]
                    })
                    return { [type]: Object.fromEntries(relatedParams) }
                }).reduce((prev: any, current: any) => {
                    return {
                        ...prev,
                        [Object.keys(current)[0]]: current[Object.keys(current)[0]]
                    }
                }, {});
                const toSend = {
                    ids: groupsAffected,
                    fields: valuesObject
                }
                http.post('rest/make-mass-update-group-services', { ...toSend }).then((response: any) => {
                    message.success("Dati veiksmīgi izmainīti");
                    history.goBack();
                }).catch((err: any) => {
                    message.error("Kļūda!")
                })
            }
        })
    };

    const massUpdateServicesPrices = () => {

        Modal.confirm({
            title: "Izmainīt grupu pakalpojumu datus",
            content: "Apstiprinot, tiks izmainītas pakalpojumu cenas, visām atzīmētajām grupām. Pilsētu individuālās cenas tiks izmainītas tikai tām grupām, kurām šīs cenas bija definētas iepriekš!",
            onOk() {
                let registration_prices = { city_prices: {} } as any;
                let study_services: any;
                registrationPrices.city_prices.forEach((cityPrice: any) => {
                    if (cityPrice.city_id !== null) {
                        registration_prices.city_prices[cityPrice.city_id] = {
                            registration_fee: cityPrice.registration_fee,
                            registration_discount: cityPrice.registration_discount
                        }
                    }
                })
                registration_prices = {
                    ...registration_prices,
                    registration_fee: registrationPrices.registration_fee,
                    registration_discount: registrationPrices.registration_discount,
                    registration_vat: registrationPrices.registration_vat,
                }
                servicesPrices.forEach((service: any) => {
                    delete service.value_lv
                    study_services = {
                        ...study_services,
                        [service.study_group_service_type_id]: service
                    };
                });
                http.post('rest/make-mass-update-group-service-prices', { ids: groupsAffected, study_services, registration_prices })
                    .then((response: any) => {
                        console.log(response)
                        message.success("Izmaiņas saglabātas!")
                    }).catch((err: any) => {
                        message.error("Kļūda!")
                    })
            }
        })
    }

    useEffect(() => {
        console.log(registrationPrices)
    }, [registrationPrices])

    useEffect(() => {
        console.log(servicesPrices)
    }, [servicesPrices]);

    useEffect(() => {
        console.log(filters)
        getCities();
        getAllGroupsIds();
        getGroupFields();
    }, []);


    return <Layout>
        <PageHeader
            style={{ background: "#FFFFFF" }}
            title={`Grupu un pakalpojumu rediģēšana (izvēlētas ${groupsAffected.length} grupas)`}
            onBack={() => history.goBack()}
        />
        <Tag
            color={"red"}
            style={{
                whiteSpace: "normal",
                margin: "20px 100px 0",
                padding: 10,
                textAlign: "center",
                fontSize: 16
            }}
        >
            Uzmanību! Šajā formā var izmainīt komentārus, aprakstus un cenas visām iepriekšejā lapā atfiltrētām grupām un grupu pakalpojumiem! Izmaiņas nevar atcelt!
        </Tag>
        <Card
            title={`Grupas komentārs un apraksts`}
            style={{ margin: 20 }}
        >
            {
                groupsAffected.length === 0
                    ? <p>Nav atlasīta neviena grupa</p>
                    : <Form
                        layout={"vertical"}
                        onFinish={massUpdateGroups}
                    >
                        <Col span={24}>
                            <Tabs>
                                <Tabs.TabPane key={1} tab="Komentārs">
                                    <Row gutter={16}>
                                        {
                                            groupFields.map((field: any, index: any) => {
                                                if (!field.includes("comment")) return;
                                                return <Col span={8} key={index}>
                                                    <Form.Item name={field} label={field.split('_')[1].toUpperCase()}>
                                                        <Input.TextArea
                                                            autoSize={{ minRows: 3, maxRows: 3 }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            })
                                        }
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane key={2} tab="Apraksts">
                                    <Row gutter={16}>
                                        {
                                            groupFields.map((field: any, index: any) => {
                                                if (!field.includes("description")) return;
                                                return <Col span={8} key={index}>
                                                    <Form.Item name={field} label={field.split('_')[1].toUpperCase()}>
                                                        <Input.TextArea
                                                            autoSize={{ minRows: 3, maxRows: 3 }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            })
                                        }
                                    </Row>
                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                        <Row justify="end">
                            <Button
                                htmlType="submit"
                                type="primary"
                            >
                                Iespējot
                            </Button>
                        </Row>
                    </Form>
            }
        </Card>
        <Card
            title="Pakalpojumi"
            style={{ margin: 20 }}
        >
            <Collapse
                onChange={() => {
                    getGroupServicesFields();
                    getGroupServicesPrices();
                }}
            >
                <Collapse.Panel header="Komentāri" key="1">
                    {
                        loadingServices
                            ? <Spin size="large" />
                            : groupsAffected.length === 0
                                ? <p>Nav atlasīta neviena grupa</p>
                                : <Form
                                    layout={"vertical"}
                                    onFinish={massUpdateServices}
                                >
                                    <Row justify="end">
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                        >
                                            Iespējot
                                        </Button>
                                    </Row>
                                    {
                                        services.map((service: any, index: any) => {
                                            return <Row key={index}>
                                                <Col span={24}>
                                                    <h3>{service.lv.value_lv}</h3>
                                                </Col>
                                                <Col span={24}>
                                                    <Tabs>
                                                        <Tabs.TabPane key={1} tab="Komentārs">
                                                            <Row gutter={16}>
                                                                {
                                                                    Object.keys(service).map((key: any, index: any) => {
                                                                        return <Col span={8} key={index}>
                                                                            <Form.Item name={service.lv.study_group_service_type_id + "-comment_" + key} label={key.toUpperCase()}>
                                                                                <Input.TextArea
                                                                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    })
                                                                }
                                                            </Row>
                                                        </Tabs.TabPane>
                                                        <Tabs.TabPane key={2} tab="Apraksts">
                                                            <Row gutter={16}>
                                                                {
                                                                    Object.keys(service).map((key: any, index: any) => {
                                                                        return <Col span={8} key={index}>
                                                                            <Form.Item name={service.lv.study_group_service_type_id + "-description_" + key} label={key.toUpperCase()}>
                                                                                <Input.TextArea
                                                                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    })
                                                                }
                                                            </Row>
                                                        </Tabs.TabPane>
                                                    </Tabs>
                                                </Col>
                                            </Row>
                                        })
                                    }
                                    <Row justify="end">
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                        >
                                            Iespējot
                                        </Button>
                                    </Row>
                                </Form>
                    }
                </Collapse.Panel>
                <Collapse.Panel header="Cenas" key="2">
                    {
                        loadingServices
                            ? <Spin size="large" />
                            : groupsAffected.length === 0
                                ? <p>Nav atlasīta neviena grupa</p>
                                : <div>
                                    <Row>
                                        <Col span={24}>
                                            <Row justify="end" style={{ paddingBottom: 10 }}>
                                                <Button
                                                    type="primary"
                                                    onClick={massUpdateServicesPrices}
                                                >
                                                    Iespējot
                                                </Button>
                                            </Row>
                                        </Col>
                                        <Col span={24}>
                                            {
                                                <Card>
                                                    <h3>Reģistrācija</h3>
                                                    <Row
                                                        align="bottom"
                                                        gutter={12}
                                                        style={{ paddingBottom: 20 }}
                                                    >
                                                        <Col>
                                                            <p>Cena</p>
                                                            <Input
                                                                type="number"
                                                                min={0}
                                                                step={0.01}
                                                                value={registrationPrices?.registration_fee}
                                                                onChange={(e: any) => {
                                                                    e.persist();
                                                                    setRegistrationPrices((prev: any) => {
                                                                        return {
                                                                            ...prev,
                                                                            registration_fee: e.target.value.length > 0 ? e.target.value : null
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <p>Atlaides cena</p>
                                                            <Input
                                                                type="number"
                                                                min={0}
                                                                step={0.01}
                                                                value={registrationPrices?.registration_discount}
                                                                onChange={(e: any) => {
                                                                    e.persist();
                                                                    setRegistrationPrices((prev: any) => {
                                                                        return {
                                                                            ...prev,
                                                                            registration_discount: e.target.value.length > 0 ? e.target.value : null
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <p>PVN %</p>
                                                            <Input
                                                                type="number"
                                                                min={0}
                                                                step={0.01}
                                                                value={registrationPrices?.registration_vat}
                                                                onChange={(e: any) => {
                                                                    e.persist();
                                                                    setRegistrationPrices((prev: any) => {
                                                                        return {
                                                                            ...prev,
                                                                            registration_vat: e.target.value.length > 0 ? e.target.value : null
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        </Col>
                                                        {/* <Button
                                                            icon={<PlusCircleOutlined style={{ pointerEvents: "none" }} />}
                                                            onClick={(e: any) => {
                                                                addAdditionalRegistrationPrice();
                                                            }}
                                                        /> */}
                                                    </Row>
                                                    {
                                                        registrationPrices?.city_prices?.map((cityPrice: any, index: any) => {
                                                            return <Row
                                                                style={{ marginBottom: 10 }}
                                                                align="bottom"
                                                                key={index}
                                                                gutter={12}
                                                            >
                                                                <Col>
                                                                    <p>Cena</p>
                                                                    <Input
                                                                        type="number"
                                                                        min={0}
                                                                        step={0.01}
                                                                        value={cityPrice.registration_fee}
                                                                        onChange={(e: any) => {
                                                                            e.persist();
                                                                            registrationCityDataHelper(index, "registration_fee", e.target.value.length > 0 ? e.target.value : null)
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <p>Atlaides cena</p>
                                                                    <Input
                                                                        type="number"
                                                                        min={0}
                                                                        step={0.01}
                                                                        value={cityPrice.registration_discount}
                                                                        onChange={(e: any) => {
                                                                            e.persist();
                                                                            registrationCityDataHelper(index, "registration_discount", e.target.value.length > 0 ? e.target.value : null)
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <p>Pilsēta</p>
                                                                    <Select
                                                                        style={{ width: 205 }}
                                                                        value={cityPrice.city_id}
                                                                        disabled
                                                                        onChange={(e: any) => {
                                                                            setRegistrationPrices((prev: any) => {
                                                                                const copy = [...prev.city_prices]
                                                                                const updatedCities = copy.map((city: any, ind: any) => {
                                                                                    if (ind === index) {
                                                                                        return {
                                                                                            ...city,
                                                                                            city_id: e
                                                                                        }
                                                                                    }
                                                                                    return city
                                                                                })
                                                                                return {
                                                                                    ...prev,
                                                                                    city_prices: updatedCities
                                                                                }
                                                                            })
                                                                        }}
                                                                    >
                                                                        {
                                                                            cities.map((city: any) => {
                                                                                return <Select.Option
                                                                                    key={city.id}
                                                                                    value={city.id}
                                                                                >
                                                                                    {city.value}
                                                                                </Select.Option>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </Col>
                                                                {/* {
                                                                    <Button
                                                                        icon={<DeleteOutlined />}
                                                                        onClick={() => {
                                                                            deleteAdditionalRegistrationPrice(index);
                                                                        }}
                                                                    />
                                                                } */}
                                                            </Row>
                                                        })
                                                    }
                                                </Card>
                                            }
                                            {
                                                servicesPrices?.map((service: any, index: any) => {
                                                    return <Card key={index}>
                                                        <h3>{service.value_lv}</h3>
                                                        <Row
                                                            align="bottom"
                                                            gutter={12}
                                                            style={{ paddingBottom: 20 }}
                                                        >
                                                            <Col>
                                                                <p>Cena</p>
                                                                <Input
                                                                    type="number"
                                                                    min={0}
                                                                    step={0.01}
                                                                    value={service.price}
                                                                    onChange={(e: any) => {
                                                                        newPriceDataHelper(index, "price", e.target.value)
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <p>Atlaides cena</p>
                                                                <Input
                                                                    type="number"
                                                                    min={0}
                                                                    step={0.01}
                                                                    value={service.discount_price}
                                                                    onChange={(e: any) => {
                                                                        newPriceDataHelper(index, "discount_price", e.target.value)
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <p>PVN %</p>
                                                                <Input
                                                                    type="number"
                                                                    min={0}
                                                                    step={0.01}
                                                                    value={service.vat}
                                                                    onChange={(e: any) => {
                                                                        newPriceDataHelper(index, "vat", e.target.value)
                                                                    }}
                                                                />
                                                            </Col>
                                                            {/* <Button
                                                                value={service.study_group_service_type_id}
                                                                icon={<PlusCircleOutlined style={{ pointerEvents: "none" }} />}
                                                                onClick={(e: any) => {
                                                                    addAdditionalPrice(e.target.value);
                                                                }}
                                                            /> */}
                                                        </Row>
                                                        {
                                                            service?.city_prices?.map((cityPrice: any, index: any) => {
                                                                return <Row
                                                                    style={{ marginBottom: 10 }}
                                                                    align="bottom"
                                                                    key={index}
                                                                    gutter={12}
                                                                >
                                                                    <Col>
                                                                        <p>Cena</p>
                                                                        <Input
                                                                            type="number"
                                                                            min={0}
                                                                            step={0.01}
                                                                            value={cityPrice.price}
                                                                            onChange={(e: any) => {
                                                                                e.persist();
                                                                                newCityPriceDataHelper(service, index, "price", e.target.value);
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <p>Atlaides cena</p>
                                                                        <Input
                                                                            type="number"
                                                                            min={0}
                                                                            step={0.01}
                                                                            value={cityPrice.discount_price}
                                                                            onChange={(e: any) => {
                                                                                newCityPriceDataHelper(service, index, "discount_price", e.target.value);
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <p>Pilsēta</p>
                                                                        <Select
                                                                            style={{ width: 205 }}
                                                                            disabled={!!cityPrice.from_db}
                                                                            value={cityPrice.city_id}
                                                                            onChange={(e: any) => {
                                                                                newCityPriceDataHelper(service, index, "city_id", e);
                                                                            }}
                                                                        >
                                                                            {
                                                                                cities.map((city: any) => {
                                                                                    return <Select.Option
                                                                                        key={city.id}
                                                                                        value={city.id}
                                                                                    >
                                                                                        {city.value}
                                                                                    </Select.Option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </Col>
                                                                    {/* {
                                                                        !!cityPrice.from_db
                                                                            ? <></>
                                                                            : <Button
                                                                                icon={<DeleteOutlined />}
                                                                                onClick={() => {
                                                                                    deleteAdditionalPrice(service, index);
                                                                                }}
                                                                            />
                                                                    } */}
                                                                </Row>
                                                            })
                                                        }
                                                    </Card>
                                                })
                                            }
                                        </Col>
                                    </Row>
                                </div>
                    }
                </Collapse.Panel>
            </Collapse>
        </Card>
    </Layout >
}