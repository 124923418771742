import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Row, Select, Space, Table } from "antd";
import { LockTwoTone, UnlockTwoTone, ExclamationCircleTwoTone, LinkOutlined } from '@ant-design/icons';
import moment from "moment";
import { useHistory } from "react-router";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";

export const ReminderTable = (props: any) => {
    const { t } = useTranslation();
    const [data, setData] = useState([] as any);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15,
        total: null,
    } as any)
    const [classifiers, setClassifiers] = useState({} as any);
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(true);
    const [tableState, setTableState] = useState({} as any)
    const [showAutoGenerated, setShowAutoGenerated] = useState(false);
    const [delegated, setDelegated] = useState([] as any);
    const [assignedJob, setAssignedJob] = useState({
        current_crm: null,
        my_crm_student: null
    });
    const [plannedJobs, setPlannedJobs] = useState(0 as number);
    const [totalApplicationsCount, setTotalApplicationsCount] = useState(null as null | number);

    const activityTypes = {
        group_change: "Grupu maiņa",
        auto_notifications: "Automātiskie paziņojumi",
        status_change: "Statusa maiņa",
        manual: "Menedžera komentārs"
    }

    const history = useHistory();
    const self = localStorage.getItem('self');

    async function loadClassifiers() {

        const crmNextAction = await http.get('/rest/crm-next-action-list').then((response: any) => {
            return response.data;
        });

        const languages = await http.get("/rest/languages");
        const cities = await http.get("/rest/cities");
        const categories = await http.get("/rest/categories");

        setClassifiers((data: any) => {
            return {
                ...data,
                languages: languages.data.data,
                cities: cities.data.data,
                categories: categories.data.data,
                nextAction: crmNextAction,
            }
        }
        );
    }

    useEffect(() => {
        loadClassifiers().then(async () => {
            const savedFilters = await returnTableFilters();
            if (savedFilters) {
                const { pagination, filters, sorter } = savedFilters;
                setShowAutoGenerated(!!filters?.allow_autogen)
                setTableState({ ...savedFilters })
                fetch({
                    sortField: sorter.field,
                    sortOrder: sorter.order,
                    pagination,
                    filters,
                });
            } else {
                fetch();
            }
        });
        getTodayPlannedCrms();
    }, []);

    // useEffect(() => {
    //     console.log(tableState)
    // }, [tableState])

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        // if (filters.manager !== null) {
        //     filters.manager = convertManagerFilter(filters.manager.at(-1))
        // }
        const trueFilters = { ...filters }
        if (!trueFilters?.hasOwnProperty('allow_autogen')) {
            trueFilters.allow_autogen = showAutoGenerated ? 1 : 0
        }
        saveTableFilters(pagination, trueFilters, sorter)
        fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters: trueFilters,
        });
    };

    const saveTableFilters = (pagination: any, filters: any, sorter: any) => {
        sessionStorage.setItem("crm_table", JSON.stringify({ pagination, filters, sorter }))
    }

    const returnTableFilters = () => {
        const savedFilters = sessionStorage.getItem("crm_table");
        if (savedFilters) {
            return JSON.parse(savedFilters)
        }
        return null
    }

    const removeFilters = () => {
        sessionStorage.removeItem("crm_table");
        fetch();
    }

    // const convertManagerFilter = (manager: string) => {
    //     console.log()
    //     const trueFilter = Object.keys(status).filter((key: any) => {
    //         return status[key].toLowerCase().includes(manager.toLowerCase());
    //     });
    //     console.log(manager)
    //     trueFilter.push(manager)
    //     return trueFilter;
    // };

    const checkIfHaveAssignedJob = () => {
        http.get(`rest/crm-self`).then((response: any) => {
            setAssignedJob(response.data.data);
        })
    }

    const getDelegatedJob = () => {
        http.get(`rest/my-delegated-crm`).then((response: AxiosResponse) => {
            if (response.data.data) {
                setDelegated(response.data.data);
            }
            console.log(response)
        })
    }

    const getTodayPlannedCrms = () => {
        http.get(`rest/my-planned-crms`).then((response: AxiosResponse) => {
            console.log(response.data.data)
            if (response.data.data.length === 0) setPlannedJobs(0);
            else {
                setPlannedJobs(response.data.data[0]?.jobs);
            }
        });
    }

    const totalApplications = () => {
        http.get(`rest/crm-application-count`).then((response: AxiosResponse) => {
            setTotalApplicationsCount(response.data)
        }).catch((error: any) => {
        })
    }

    const fetch = async (params: any = { pagination: { current: 1 } }) => {
        // checkIfHaveAssignedJob();
        totalApplications();
        setTableLoading(true);
        getDelegatedJob();
        let trueParams = JSON.parse(JSON.stringify(params))
        await http.get("/rest/crm-reminders", { params: getParams(trueParams) }).then(result => {
            setTableState(getParams(trueParams))
            const { data, meta } = result.data;
            setData(data.filter((item: any) => !!!item.delegated));
            setPagination({
                current: meta.current_page,
                pageSize: meta.per_page,
                total: meta.total
            });
        })
        setTableLoading(false);
        setLoading(false);
    }

    const getParams = (params: any) => {
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters
        }
    }

    const newTabHandle = (e: any, id: number) => {
        e.stopPropagation();
        window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}groups/student-opening/${id}`, "_blank");
    }

    const status = {
        "school_exam": "Novirzīts skolas braukšanas eksāmenam",
        "csdd": "Novirzīts CSDD braukšanas eksāmenam",
        "e_learning": "E-apmācība",
        "extra_pmp": "Papildus pirmās palīdzības apmācība",
        "extra_medical": "Papildus medicīniskās izziņas pakalpojums",
        "cancelled_individual_driving_registration": "Students atteica individuālas braukšanas reģistrāciju",
        "student_want_med": "Students pieteicis medicīnisko izziņu",
        "student_want_pmp": "Students ir pieteicis pirmo medicīnisko palīdzību",
        "study_group_status_change": "Nomainīts rezervācijas veids grupā"
    } as any;

    const reminderCols = [
        {
            render: (a: any, record: any) => {
                return !!record.new_tab_allowed
                    ? <Button
                        type="link"
                        onClick={(e: any) => newTabHandle(e, record.student.id)}
                    >
                        <LinkOutlined />
                    </Button>
                    : <></>
            }
        },
        {
            title: "Tips",
            dataIndex: "activity_type",
            filters: Object.keys(activityTypes).map((key: any) => ({
                text: activityTypes[key as keyof typeof activityTypes], value: key
            })),
            filteredValue: tableState?.filters?.activity_type || null,
            render: (type: any) => {
                return activityTypes[type as keyof typeof activityTypes]
            }
        },
        {
            title: "Klients",
            //   sorter: true,
            dataIndex: "student",
            key: 'student',
            filteredValue: tableState?.filters?.student || null,
            render: (student: any, record: any) => {
                return <div>
                    <span style={{ color: !!record.with_requisites ? "green" : "black" }}>{student?.name}</span>
                    {
                        !!record.scholarship
                        && <>
                            <br />
                            <span style={{ color: "#0200ff" }}>Stipendija</span>
                        </>
                    }
                </div>
            },
            ...getColumnSearchProps(),
        },
        {
            title: "Kategorija",
            dataIndex: "category",
            key: 'category',
            filteredValue: tableState?.filters?.category || null,
            render: (cat: any) => cat ? cat.value : "",
            filters: classifiers.categories != null ? classifiers.categories.map((cat: any) => {
                return { text: cat.value, value: cat.id }
            }) : [],
        },
        {
            title: "Pilsēta",
            //   sorter: true,
            dataIndex: "city",
            key: 'city',
            filteredValue: tableState?.filters?.city || null,
            render: (city: any) => city?.value,
            filters: classifiers.cities !== undefined
                ? classifiers.cities.map((city: any) => ({ text: city.value, value: city.id }))
                : [],
        },
        {
            title: "WEB pilsēta",
            dataIndex: "web_city",
            key: 'web_city',
            filteredValue: tableState?.filters?.web_city || null,
            ...getColumnSearchProps()
        },
        {
            title: "WEB valoda",
            dataIndex: "web_lang",
            key: 'web_lang',
            filteredValue: tableState?.filters?.web_lang || null,
            ...getColumnSearchProps()
        },
        {
            title: "Grupa",
            //   sorter: true,
            dataIndex: "study_group",
            key: 'study_group',
            filteredValue: tableState?.filters?.study_group || null,
            render: (group: any, all: any) => {
                if (all.fake_group === String(group.id)) return;
                if (group.start_time) {
                    return <>
                        <p style={{ fontSize: 12 }}>
                            {group.start_date}
                        </p>
                        <p style={{ fontSize: 12 }}>
                            {moment(group?.start_time, "HH:mm:ss").format("HH:mm") +
                                " - " + moment(group?.end_time, "HH:mm:ss").format("HH:mm")}
                        </p>
                    </>
                }
            },
            ...getColumnSearchProps(),
        },
        {
            title: "Menedžeris/Darbība",
            //   sorter: true,
            dataIndex: "manager",
            key: 'manager',
            // filteredValue: tableState?.filters?.manager ? [tableState?.filters?.manager[0]] : null,
            filteredValue: tableState?.filters?.manager,
            render: (from: any, record: any) => {
                return <div>
                    <p style={{ marginBottom: 3 }}>
                        {
                            status[from] === undefined
                                ? record.activity_type === "auto_notifications" || record.activity_type === "group_change"
                                    ? t(`auto_notifications.${from.trim()}`)
                                    : from
                                : status[from]
                        }
                    </p>
                    {
                        !!record.from_app && from === "Pieteikums"
                        && <p style={{ color: "red", fontSize: 10 }}>
                            (APP)
                        </p>
                    }
                </div>
            },
            ...getColumnSearchProps(),
            width: 150
        },
        {
            title: "Aktivitātes datums",
            sorter: true,
            dataIndex: "next_action_date",
            key: 'next_action_date',
            sortOrder: tableState?.sortField === 'next_action_date' && tableState?.sortOrder,
            filteredValue: tableState?.filters?.next_action_date || null,
            ...getColumnSearchProps('calendar'),
        },
        {
            title: "Svarīgi!",
            dataIndex: "urgent",
            key: "urgent",
            filteredValue: tableState?.filters?.urgent || null,
            render: (urgent: 1 | 0) => {
                return urgent
                    ? <ExclamationCircleTwoTone twoToneColor={"red"} style={{ fontSize: 24 }} />
                    : <></>
            },
            filters: [
                {
                    text: "Tikai svarīgie",
                    value: 1
                }
            ],
        },
        {
            title: () => <LockTwoTone />,
            dataIndex: "locked",
            key: "locked",
            render: (locked: any, record: any) => {
                const haveAccess = locked && record.locker.id === Number(JSON.parse(self ? self : "")?.id)
                return locked
                    ? haveAccess
                        ? <UnlockTwoTone title={`${record.locker.first_name} ${record.locker.last_name}`} twoToneColor={"green"} />
                        : <LockTwoTone title={`${record.locker.first_name} ${record.locker.last_name}`} twoToneColor={"red"} />
                    : <></>
            }
        }
    ];

    // useEffect(() => {
    //     console.log("autogenCHange", tableState)
    //     handleTableChange({ ...tableState?.page }, { ...tableState?.filters }, { ...tableState?.sort })
    //     fetch({
    //         sortField: tableState.sortField,
    //         sortOrder: tableState.sortOrder,
    //         pagination,
    //         filters: tableState.filters,
    //     });
    // }, [showAutoGenerated])

    return (
        <Card style={{ width: "100%" }}>
            <h3>Nepabeigti {plannedJobs} darbi</h3>
            <h3>Kopā pieteikumu skaits: {totalApplicationsCount}</h3>
            {
                delegated.length > 0
                    ? <Col style={{ marginBottom: 10 }}>
                        <b>Deleģētais darbs</b>
                        <Card
                            style={{ overflow: "auto", marginTop: 20 }}
                            hoverable
                            onClick={() => {
                                if (delegated[0]?.locked && delegated[0]?.locker?.id !== Number(JSON.parse(self ? self : "")?.id)) return;
                                const fake = Number(delegated[0]?.fake_group) === delegated[0]?.study_group.id;
                                // history.push({ pathname: `/groups/edit/edit-student/${record.student.id}/${fake ? "no-group" : record.study_group.id}`, state: { tab: "3" } });
                                history.push({ pathname: `/groups/student-opening/${delegated[0]?.student.id}`, state: { tab: "3" } });
                            }}
                        >
                            <Row style={{ flexWrap: "nowrap" }}>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b>Tips:</b>
                                    <p style={{ marginTop: 10 }}>{activityTypes[delegated[0]?.activity_type as keyof typeof activityTypes]}</p>
                                </div>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b>Klients:</b>
                                    <div style={{ marginTop: 10 }}>
                                        <span style={{ color: !!delegated[0].with_requisites ? "green" : "black" }}>{delegated[0]?.student?.name}</span>
                                        {
                                            !!delegated[0].scholarship
                                            && <>
                                                <br />
                                                <span style={{ color: "#0200ff" }}>Stipendija</span>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b>Kategorija:</b>
                                    <p style={{ marginTop: 10 }}>{delegated[0]?.category ? delegated[0]?.category.value : ""}</p>
                                </div>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b>Pilsēta:</b>
                                    <p style={{ marginTop: 10 }}>{delegated[0]?.city?.value}</p>
                                </div>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b style={{ whiteSpace: "nowrap" }}>WEB pilsēta:</b>
                                    <p style={{ marginTop: 10 }}>{delegated[0]?.web_city}</p>
                                </div>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b style={{ whiteSpace: "nowrap" }}>WEB valoda:</b>
                                    <p style={{ marginTop: 10 }}>{delegated[0]?.web_lang}</p>
                                </div>
                                {
                                    delegated[0]?.fake_group === String(delegated[0]?.study_group_id)
                                        ? <></>
                                        : delegated[0]?.study_group.start_time
                                            ? <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0", minWidth: 125 }}>
                                                <b>Grupa:</b>
                                                <div style={{ marginTop: 10 }}>
                                                    <p style={{ fontSize: 12 }}>
                                                        {delegated[0]?.study_group?.start_date}
                                                    </p>
                                                    <p style={{ fontSize: 12 }}>
                                                        {moment(delegated[0]?.study_group?.start_time, "HH:mm:ss").format("HH:mm") +
                                                            " - " + moment(delegated[0]?.study_group?.end_time, "HH:mm:ss").format("HH:mm")}
                                                    </p>
                                                </div>
                                            </div>
                                            : <></>
                                }
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0", minWidth: 200 }}>
                                    <b>Veids:</b>
                                    <p style={{ marginTop: 10 }}>
                                        {
                                            status[delegated[0]?.manager] === undefined
                                                ? delegated[0]?.activity_type === "auto_notifications" || delegated[0]?.activity_type === "group_change"
                                                    ? t(`auto_notifications.${delegated[0]?.manager.trim()}`)
                                                    : delegated[0]?.manager
                                                : status[delegated[0]?.manager]
                                        }
                                    </p>
                                </div>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b style={{ whiteSpace: "nowrap" }}>Aktivitātes datums:</b>
                                    <p style={{ marginTop: 10 }}>{delegated[0]?.next_action_date}</p>
                                </div>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <b>Svarīgi:</b>
                                    {
                                        delegated[0]?.urgent
                                            ? <div style={{ marginTop: 10 }}><ExclamationCircleTwoTone twoToneColor={"red"} style={{ fontSize: 24 }} /></div>
                                            : <></>
                                    }
                                </div>
                                <div style={{ paddingInline: 20, borderRight: "1px solid #f0f0f0" }}>
                                    <LockTwoTone />
                                    <div style={{ marginTop: 10 }}>
                                        {
                                            delegated[0]?.locked
                                                ? delegated[0]?.locker?.id === Number(JSON.parse(self ? self : "")?.id)
                                                    ? <UnlockTwoTone title={`${delegated[0]?.locker?.first_name} ${delegated[0]?.locker?.last_name}`} twoToneColor={"green"} />
                                                    : <LockTwoTone title={`${delegated[0]?.locker?.first_name} ${delegated[0]?.locker?.last_name}`} twoToneColor={"red"} />
                                                : <></>
                                        }
                                    </div>
                                </div>
                            </Row>
                        </Card>
                    </Col>
                    : <></>
            }
            <Row justify="end" style={{ padding: "10px 0" }}>
                {/* <Select
                    style={{ width: 300, marginRight: 10 }}
                    showSearch
                    value={lv.auto_notifications[tableState?.filters?.manager?.[0] as keyof typeof lv.auto_notifications]}
                    onChange={(value: any) => {
                        const dbName = Object.keys(lv.auto_notifications).find((key: any) => {
                            return lv.auto_notifications[key as keyof typeof lv.auto_notifications] === value
                        })
                        handleTableChange({ current: 1 }, { manager: [dbName] }, {})
                    }}
                >
                    {
                        Object.keys(lv.auto_notifications)?.map((key: any, index: number) => {
                            return <Select.Option value={lv.auto_notifications[key as keyof typeof lv.auto_notifications]} key={index}>
                                {lv.auto_notifications[key as keyof typeof lv.auto_notifications]}
                            </Select.Option>
                        })
                    }
                </Select> */}
                <Button
                    type="primary"
                    onClick={removeFilters}
                    loading={tableLoading}
                >
                    Atcelt filtrus
                </Button>
            </Row>
            <Row style={{ marginBottom: 10 }}>
                <b>Rādīt automātiski ģenerētos paziņojumus: </b>
                <Checkbox
                    style={{ marginLeft: 10 }}
                    checked={!!showAutoGenerated}
                    onChange={(e) => {
                        setShowAutoGenerated(e.target.checked)
                        handleTableChange(
                            pagination,
                            { ...tableState?.filters, allow_autogen: e.target.checked ? 1 : 0 },
                            { field: tableState?.sortField, order: tableState?.sortOrder }
                        )
                        // fetch({
                        //     sortField: tableState.sortField,
                        //     sortOrder: tableState.sortOrder,
                        //     pagination,
                        //     filters: { ...tableState.filters, allow_autogen: e.target.checked ? 1 : 0 },
                        // })
                    }}
                />
            </Row>
            <Table
                rowKey={record => record.id}
                dataSource={data}
                columns={reminderCols}
                rowClassName={(record: any) => {
                    return (record.status === "missed"
                        ? "missed-reminder"
                        : "") +
                        (!!record.urgent
                            ? " urgent"
                            : "")
                }}
                pagination={{
                    showSizeChanger: false,
                    size: 'small',
                    showTotal: (total) => <div>Kopā {total} atgādinājumi</div>,
                    position: ['bottomCenter'],
                    pageSizeOptions: ['15'],
                    defaultPageSize: 15,
                    ...pagination
                }}
                onRow={(record: any, rowIndex: any) => {
                    return {
                        onClick: (event: any) => {
                            console.log(event, record)
                            if (record.locked && record.locker?.id !== Number(JSON.parse(self ? self : "")?.id)) return;
                            const fake = Number(record.fake_group) === record.study_group.id;
                            // history.push({ pathname: `/groups/edit/edit-student/${record.student.id}/${fake ? "no-group" : record.study_group.id}`, state: { tab: "3" } });
                            history.push({ pathname: `/groups/student-opening/${record.student.id}`, state: { tab: "3" } });
                        }, // click row
                        onDoubleClick: (event: any) => { }, // double click row
                        onContextMenu: (event: any) => { }, // right button click row
                        onMouseEnter: (event: any) => { }, // mouse enter row
                        onMouseLeave: (event: any) => { }, // mouse leave row
                    };
                }}
                onChange={handleTableChange}
                loading={tableLoading}
                expandable={{
                    expandedRowRender: (record: any) => {
                        return (
                            <>
                                <p>{record?.comment}</p>
                            </>
                        )
                    }
                }}
                scroll={{ x: 1500 }}
            />
        </Card>
    )
}