import { Layout, PageHeader, Table, Row, Col, Card, Form, Button, Input, Skeleton, Space, Select, Radio, Divider, Rate, message, Checkbox, Tooltip } from 'antd';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined, SaveOutlined, InfoCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter, Switch, Route } from 'react-router-dom';
import { http } from '../helpers/http';
import { FormInstance } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import Avatar from './ImgUploader';
import { v4 as uuidv4 } from 'uuid';
import { initial } from 'lodash';

const Content = Layout.Content;

export type EditInstructorOutlinePageProps = RouteComponentProps<{
    id: string
}> & {
};
export type EditInstructorOutlinePageState = {
    submitting: boolean,
    loading: boolean,
    changing_password: boolean,
    desired: boolean,
    initialData: any,
    classifiers: any,
    fileData: any,
    img: any,
    changeImg: boolean
};

export class BaseEditInstructorOutlinePage extends React.Component<EditInstructorOutlinePageProps, EditInstructorOutlinePageState> {

    constructor(props: EditInstructorOutlinePageProps) {
        super(props);

        this.state = {
            submitting: false,
            loading: true,
            changing_password: false,
            desired: false,
            initialData: {},
            classifiers: {},
            fileData: [],
            img: {},
            changeImg: false
        };
    }

    form = React.createRef<FormInstance>();
    passwordFormRef = React.createRef<FormInstance>();

    componentDidMount() {
        (window["component" as any] as any) = this;
        this.loadClassifiers().then(() => {
            this.fetchData();
        });
    }

    fetchData = async () => {
        await http.get(`/rest/instructors/${this.props.match.params.id}`).then(response => {
            const data = response.data.data;
            // console.log(data)
            this.setState({
                initialData: this.processData(data),
                loading: false,
                desired: data.desired
            });
        });
    }

    async loadClassifiers() {
        const languages = await http.get("/rest/languages");

        this.setState({
            classifiers: {
                languages: languages.data.data
            }
        });
    }

    processData = (data: any) => {
        return {
            ...data,
            language_ids: data.languages.map((l: any) => String(l.id))
        }
    }

    uploadImage = (value: any) => {
        // console.log(value)
        this.setState({ img: value });
    }

    getFile = (file: any) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            this.setState({ fileData: reader.result });
        }
        // console.log(file)
        if (file && file?.length > 0) {
            reader.readAsDataURL(file[0].originFileObj);
        }
    }

    onFinish = (values: any) => {
        // console.log('Success:', values);
        // console.log(this.state.fileData);
        let valuesToSubmit
        if (this.state.fileData) {
            if (this.state.fileData.length > 0) {
                valuesToSubmit = {
                    ...values,
                    img: this.state.fileData
                };
            } else {
                valuesToSubmit = {
                    ...values,
                };
            }
        } else {
            valuesToSubmit = {
                ...values,
            };
        }
        valuesToSubmit.active = valuesToSubmit.active ? 1 : 0;
        // console.log('Success:', valuesToSubmit);
        this.setState({ submitting: true });

        http.patch(`/rest/instructors/${this.state.initialData.id}`, valuesToSubmit).then((response: any) => {
            // console.log(response)
            this.setState({ submitting: false, initialData: this.processData(response.data.data), changeImg: false });
            message.success('Izmaiņas veiksmīgi saglabātas');
        }).catch(() => {
            message.error('Neizdevās saglabāt izmaiņas');
        });
    };

    deleteImg = () => {
        http.delete(`/rest/instructor-img/${this.state.initialData.id}`).then((response: any) => {
            // console.log(response)
            this.setState({ changeImg: false, initialData: this.processData(response.data.data) })
            message.success("Attēls ir dzēsts")
        }).catch(() => {
            message.error("Kļūda")
        })
    }

    onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    onChangePassword = (values: any) => {
        this.setState({ changing_password: true });
        http.patch(`/rest/instructors/${this.state.initialData.id}`, { password: values.new_password }).then(() => {
            this.passwordFormRef.current?.resetFields();
            this.setState({ changing_password: false });

        });
    }

    onChangePasswordFailed = (errorInfo: any) => {
        console.log("Password change failed:", errorInfo);
    }

    deleteService = (serviceId: number) => {
        http.delete(`/rest/services/${serviceId}`).then(() => {
            this.setState({
                initialData: {
                    ...this.state.initialData,
                    services: this.state.initialData.services.filter((service: any) => service.id !== serviceId)
                }
            });
        });
    }

    render() {
        const servicesColumns = [
            {
                title: 'Kategorija',
                dataIndex: 'category',
                key: 'category',
                render: (record: any) => record.value
            },
            {
                title: 'Transportlīdzeklis',
                dataIndex: 'vehicle_and_plate',
                key: 'vehicle_and_plate',
            },
            {
                title: 'Instruktora likme',
                dataIndex: 'instructor_rate_real',
                key: 'instructor_rate_real',
                render: (record: any) => `${Number(record).toFixed(2)} EUR`
            },
            {
                title: 'Skolas likme',
                dataIndex: 'school_rate_real',
                key: 'school_rate',
                render: (record: any) => `${Number(record).toFixed(2)} EUR`
            },
            {
                title: 'Kopā',
                dataIndex: 'total_rate_real',
                key: 'total_rate_real',
                render: (record: any) => `${Number(record).toFixed(2)} EUR`
            },
            {
                title: 'Prioritāte',
                dataIndex: 'priority',
                key: 'priority',
            },
            {
                title: '',
                dataIndex: '',
                key: 'actions',
                width: 1,
                render: (row: any) => {
                    return <Space>
                        <Link to={`${this.props.location.pathname}/services/edit/${row.id}`}><Button type="link" icon={<EditOutlined />}></Button></Link>
                        {/* <Button onClick={() => { this.deleteService(row.id); }} type="link" danger icon={<DeleteOutlined />}></Button> */}
                    </Space>;
                }
            }
        ];

        const content = this.state.loading ? (
            <Card>
                <Skeleton />
            </Card>
        ) : (
            <>
                <Form
                    ref={this.form}
                    name="basic"
                    layout="vertical"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    initialValues={this.state.initialData}
                >
                    <Card style={{ marginBottom: 5 }} title={
                        <Space>
                            <strong>Instruktora informācija</strong>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 30 }}>
                                <b>Pieprasīts</b>
                                <Form.Item style={{ margin: "0 10px" }} valuePropName='checked' name="desired">
                                    <Checkbox />
                                </Form.Item>
                            </div>
                            <Button type="link"><Link to={`/payments/${this.props.match.params.id}`}>Atvērt aprēķinus</Link></Button>
                        </Space>
                    }>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item valuePropName="checked" name="active" label="Aktīvs">
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col flex={1}>
                                <Form.Item name="first_name" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Vārds">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col flex={1}>
                                <Form.Item name="last_name" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Uzvārds">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col flex={1}>
                                <Form.Item name="phone_number" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Tālrunis">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col flex={1}>
                                <Form.Item name="email" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }, { type: "email", message: "Nav derīga e-pasta adrese" }]} label="E-pasta adrese">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col flex={1}>
                                <Form.Item name="username" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Lietotāja vārds (login)">
                                    <Input disabled style={{ fontWeight: "bold", color: "#020202" }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={20}>
                            <Col>
                                <Form.Item name="language_ids" required rules={[{ required: true, message: "Obligāts lauks", type: "array" }]} label="Valodas">
                                    <Select style={{ width: 200 }} mode="multiple">
                                        {this.state.classifiers.languages
                                            ? this.state.classifiers.languages.map((language: any) => {
                                                return <Select.Option key={language.id} value={String(language.id)}>{language.value}</Select.Option>
                                            }) : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={20}>
                            <Col>
                                <Form.Item name="description" label="Papildus informācija">
                                    <TextArea style={{ width: 300 }}></TextArea>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="payment_requisites" label="Rekvizīti">
                                    <TextArea rows={6} style={{ width: 300 }}></TextArea>
                                </Form.Item>
                            </Col>
                            <Col style={{ padding: 10 }}>
                                <Row justify='end'>
                                    <Tooltip
                                        style={{ height: 20, width: 20 }}
                                        placement="top"
                                        title={"Lai izveidotu rēķina numura sagatavi, izmantojiet pogas kuras atrodas zem ievadlauka. Poga pievieno nepieciešamo mainīgo rēķina numura tekstam."}>
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </Row>
                                <Form.Item style={{ marginBottom: 10 }} name="invoice_template" label="Rēķina numura sagatave">
                                    <Input />
                                </Form.Item>
                                <Button
                                    onClick={() => {
                                        const text = this.form.current?.getFieldValue("invoice_template")
                                        this.form.current?.setFieldsValue({ invoice_template: text !== undefined && text !== null ? text + "{year}" : "{year}" })
                                    }}
                                >
                                    Gads
                                </Button>
                                <Button
                                    onClick={() => {
                                        const text = this.form.current?.getFieldValue("invoice_template")
                                        this.form.current?.setFieldsValue({ invoice_template: text !== undefined && text !== null ? text + "{month}" : "{month}" })
                                    }}
                                >
                                    Mēnesis
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    this.state.initialData.img.includes('noimage.svg') || this.state.initialData.img === 'storage/instructors/' || this.state.changeImg
                                        ? <>
                                            <Form.Item name="image" label="Pievienot attēlu">
                                                <Avatar getImg={this.uploadImage} returnFile={this.getFile} aspect={1 / 1} />
                                            </Form.Item>
                                            {
                                                this.state.changeImg
                                                    ? <Button style={{ margin: "10px auto" }} onClick={() => this.setState({ changeImg: false })}>Atcelt attēla maiņu</Button>
                                                    : <></>
                                            }
                                        </>
                                        : <>
                                            <img width="200px" height="200px" src={process.env.REACT_APP_API_URL + this.state.initialData.img} alt="" /><br />
                                            <Space>
                                                <Button style={{ margin: "10px auto" }} onClick={() => this.setState({ changeImg: true })}>Mainīt attēlu</Button>
                                                <Button danger onClick={this.deleteImg} icon={<DeleteOutlined />}></Button>
                                            </Space>
                                        </>
                                }
                                {/* <Button onClick={() => console.log(this.state.initialData)}>INIT</Button> */}
                            </Col>
                        </Row>
                        <Form.Item name="comment" label="Komentārs">
                            <Input.TextArea style={{ maxWidth: 400 }} />
                        </Form.Item>
                    </Card>

                    <Card style={{ marginBottom: 20 }}>
                        <Row>
                            <Col>
                                <Button type="primary" loading={this.state.submitting} htmlType="submit">Saglabāt</Button>
                            </Col>
                        </Row>
                    </Card>
                </Form>

                <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 20 }} title={<strong>Pakalpojumi</strong>}>
                    <Table
                        rowKey={record => record.id}
                        columns={servicesColumns}
                        dataSource={this.state.initialData.services}
                        footer={() =>
                            <Space>
                                <Link to={`/instructors/edit/${this.props.match.params.id}/services/new`}><Button icon={<PlusCircleOutlined />}>Pievienot pakalpojumu</Button></Link>
                            </Space>
                        }
                        pagination={false}
                    />
                </Card>

                <Card style={{ marginBottom: 20 }} title={<strong>Mainīt paroli</strong>}>
                    <Form
                        name="change-password"
                        layout="vertical"
                        onFinish={this.onChangePassword}
                        onFinishFailed={this.onChangePasswordFailed}
                        ref={this.passwordFormRef}
                    >
                        <Row gutter={20}>
                            <Col>
                                <Form.Item name="new_password" required rules={[{ required: true, whitespace: true, message: "Ievadiet jauno paroli" }]}>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col>
                                <Form.Item>
                                    <Button type="primary" loading={this.state.changing_password} htmlType="submit">Mainīt</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </>
        );

        return <Layout>
            <PageHeader
                style={{ padding: "0 16px", background: "rgb(255, 255, 255)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}
                onBack={() => { this.props.history.goBack(); }}
                title={
                    <>
                        <span style={{ fontSize: "0.7em", fontWeight: "normal" }}>Doties atpakaļ</span>
                        <span style={{ marginLeft: 40 }}>{this.state.loading ? `` : `${this.state.initialData.first_name} ${this.state.initialData.last_name}`}</span>
                    </>
                }
            />
            <Content style={{ padding: 20 }}>
                {content}
            </Content>
        </Layout>;
    }

}

export class SchoolRate extends React.Component<{ getRates: any, rate: any, deleteRate: any, classifiers: any, cities: any, external: any }, { formValues: any }> {
    constructor(props: any) {
        super(props);

        this.state = {
            formValues: {} as any
        };
    }

    onFinish = (values: any) => {
        const id = this.props.rate.id;
        values.id = id;
        values.cities = this.props.cities.map((city: any) => {
            return {
                id: city,
                rate: values[`rate-${city}`],
                rate_weekends: values[`rate_weekends-${city}`],
                rate_evenings: values[`rate_evenings-${city}`],
                extra_rate: values[`extra_rate-${city}`],
                extra_rate_weekends: values[`extra_rate_weekends-${city}`],
                extra_rate_evenings: values[`extra_rate_evenings-${city}`],
            }
            // values.cities.concat(newCityService)
            // console.log(values.cities)
        })
        // console.log(values)
        http.patch(`/rest/school_rates/${id}`, values).then((response: any) => {
            // console.log(response)
            message.success('Pakalpojums saglabāts');
        }).catch(() => {
            message.error('Neizdevās saglabāt pakalpojumu');
        });
    }

    onFinishFailed = (errors: any) => {
        message.error('Pārbaudiet vai visi lauki ir aizpildīti');
    }

    deleteSchoolRate = () => {
        const id = this.props.rate.id;
        http.delete(`/rest/school_rates/${id}`).then((response: any) => {
            this.props.deleteRate(id);
            // console.log(response)
        }).catch(() => {
            message.error("Neizdevās dzēst ierakstu")
        })
    }


    rate = this.props.rate;

    componentDidMount() {
        this.setState({ formValues: this.rate })
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.formValues !== this.state.formValues) {
            this.props.getRates(this.state.formValues)
        }
    }

    render() {
        // console.log(this.rate)
        return (
            <Form
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                layout="vertical"
            >
                <Row gutter={20}>
                    <Col span={5}>
                        <Form.Item initialValue={this.rate.description} name={`description`} required rules={[{ required: true, message: "Obligāts lauks" }]} label="Pozīcijas nosaukums">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ paddingBottom: 10 }}>
                        <Form.Item
                            style={{ marginBottom: 0 }}
                            initialValue={this.rate.rate}
                            name={`rate`}
                            required rules={[{ required: true, message: "Obligāts lauks" }]}
                            label={`Parastā likme`}
                        >
                            <Input
                                onChange={(e: any) => {
                                    this.setState({
                                        formValues: {
                                            ...this.state.formValues,
                                            [e.target.id]: e.target.value
                                        }
                                    })
                                }}
                            />
                        </Form.Item>
                        <small>Ar PVN: {Number(this.state.formValues[`rate`]) * (1 + Number(this.state.formValues.rate_vat) / 100)}</small>
                    </Col>
                    <Col span={4} style={{ paddingBottom: 10 }}>
                        <Form.Item
                            style={{ marginBottom: 0 }}
                            initialValue={this.rate.rate_weekends}
                            name={`rate_weekends`}
                            required rules={[{ required: true, message: "Obligāts lauks" }]}
                            label={`Brīvdienu likme`}
                        >
                            <Input
                                onChange={(e: any) => {
                                    this.setState({
                                        formValues: {
                                            ...this.state.formValues,
                                            [e.target.id]: e.target.value
                                        }
                                    })
                                }}
                            />
                        </Form.Item>
                        <small>Ar PVN: {Number(this.state.formValues[`rate_weekends`]) * (1 + Number(this.state.formValues.rate_vat) / 100)}</small>
                    </Col>
                    <Col span={4} style={{ paddingBottom: 10 }}>
                        <Form.Item
                            style={{ marginBottom: 0 }}
                            initialValue={this.rate.rate_evenings}
                            name={`rate_evenings`}
                            required
                            rules={[{ required: true, message: "Obligāts lauks" }]}
                            label={`Vakara likme`}>
                            <Input
                                onChange={(e: any) => {
                                    this.setState({
                                        formValues: {
                                            ...this.state.formValues,
                                            [e.target.id]: e.target.value
                                        }
                                    })
                                }}
                            />
                        </Form.Item>
                        <small>Ar PVN: {Number(this.state.formValues[`rate_evenings`]) * (1 + Number(this.state.formValues.rate_vat) / 100)}</small>
                    </Col>
                    <Col span={4}>
                        <Form.Item initialValue={this.rate.rate_vat} name={`rate_vat`} required rules={[{ required: true, message: "Obligāts lauks" }]} label="PVN (%)">
                            <Input
                                onChange={(e: any) => {
                                    this.setState({
                                        formValues: {
                                            ...this.state.formValues,
                                            [e.target.id]: e.target.value
                                        }
                                    })
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={3} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit" icon={<SaveOutlined />}></Button>
                                <Button danger onClick={this.deleteSchoolRate} icon={<DeleteOutlined />}></Button>
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        {this.props.cities.map((city: any, index: any) => {
                            console.log(city, this.state.formValues)
                            const { formValues } = this.state;
                            const cityRate = this.state.formValues.city_rates?.find((el: any) => el.city_id === city);
                            const cityName = this.props.classifiers.cities.filter((item: any) => {
                                return item.id === city;
                            })[0].value;
                            const real_rate = formValues.hasOwnProperty(`rate-${city}`)
                                ? Number(formValues[`rate-${city}`]) > 0
                                    ? Number(formValues[`rate-${city}`])
                                    : Number(formValues.rate)
                                : Number(formValues.rate)
                            const real_rate_evenings = formValues.hasOwnProperty(`rate_evenings-${city}`)
                                ? Number(formValues[`rate_evenings-${city}`]) > 0
                                    ? Number(formValues[`rate_evenings-${city}`])
                                    : Number(formValues.rate_evenings)
                                : Number(formValues.rate_evenings)
                            const real_rate_weekends = formValues.hasOwnProperty(`rate_weekends-${city}`)
                                ? Number(formValues[`rate_weekends-${city}`]) > 0
                                    ? Number(formValues[`rate_weekends-${city}`])
                                    : Number(formValues.rate_weekends)
                                : Number(formValues.rate_weekends)
                            const real_vat = (1 + Number(this.state.formValues.rate_vat) / 100)
                            return (
                                <Row key={city} gutter={20}>
                                    <Col span={5} style={{ display: "flex", alignItems: "center" }}>
                                        <b>{cityName}</b>
                                    </Col>
                                    <Col span={4} style={{ paddingBottom: 10 }}>
                                        <Form.Item
                                            style={{ marginBottom: 0 }}
                                            initialValue={
                                                this.rate.city_rates
                                                    && this.rate.city_rates.length > 0
                                                    ? this.rate.city_rates.find((rate: any) => rate.city_id === city)?.rate
                                                    : undefined
                                            }
                                            name={`rate-${city}`}
                                            label={`Parastā likme`}
                                        >
                                            <Input
                                                onChange={(e: any) => {
                                                    this.setState({
                                                        formValues: {
                                                            ...this.state.formValues,
                                                            [e.target.id]: e.target.value
                                                        }
                                                    })
                                                }}
                                            />
                                        </Form.Item>
                                        <small>
                                            Ar PVN: {
                                                real_rate * real_vat
                                            }
                                        </small>
                                    </Col>
                                    <Col span={4} style={{ paddingBottom: 10 }}>
                                        <Form.Item
                                            style={{ marginBottom: 0 }}
                                            initialValue={
                                                this.rate.city_rates
                                                    && this.rate.city_rates.length > 0
                                                    ? this.rate.city_rates.find((rate: any) => rate.city_id === city)?.rate_weekends
                                                    : undefined
                                            }
                                            name={`rate_weekends-${city}`}
                                            label={`Brīvdienu likme`}
                                        >
                                            <Input
                                                onChange={(e: any) => {
                                                    this.setState({
                                                        formValues: {
                                                            ...this.state.formValues,
                                                            [e.target.id]: e.target.value
                                                        }
                                                    })
                                                }}
                                            />
                                        </Form.Item>
                                        <small>
                                            Ar PVN: {
                                                real_rate_weekends * real_vat
                                            }
                                        </small>                                  </Col>
                                    <Col span={4} style={{ paddingBottom: 10 }}>
                                        <Form.Item
                                            style={{ marginBottom: 0 }}
                                            initialValue={
                                                this.rate.city_rates
                                                    && this.rate.city_rates.length > 0
                                                    ? this.rate.city_rates.find((rate: any) => rate.city_id === city)?.rate_evenings
                                                    : undefined
                                            }
                                            name={`rate_evenings-${city}`}
                                            label={`Vakara likme`}
                                        >
                                            <Input
                                                onChange={(e: any) => {
                                                    this.setState({
                                                        formValues: {
                                                            ...this.state.formValues,
                                                            [e.target.id]: e.target.value
                                                        }
                                                    })
                                                }}
                                            />
                                        </Form.Item>
                                        <small>
                                            Ar PVN: {
                                                real_rate_evenings * real_vat
                                            }
                                        </small>
                                    </Col>
                                    <Col span={4}>
                                        {/* <Form.Item name={`rate_vat-${city}`} label="PVN (%)">
                                            <Input />
                                        </Form.Item> */}
                                    </Col>
                                </Row>
                            )
                        })}
                    </Col>
                </Row>
                <Divider />
                {
                    this.props.external
                        ? <ExternalSchoolRate
                            classifiers={this.props.classifiers}
                            cities={this.props.cities}
                            rate={this.props.rate}
                            schoolFormValues={this.state.formValues}
                            getExtraRates={(rates: any) => this.setState({
                                formValues: {
                                    ...this.state.formValues,
                                    ...rates
                                }
                            })
                            }
                        />
                        : <></>
                }
            </Form>
        );
    }
}

export class ExternalSchoolRate extends React.Component<{ rate: any, classifiers: any, cities: any, schoolFormValues: any, getExtraRates: any }, { formValues: any }> {
    constructor(props: any) {
        super(props);

        this.state = {
            formValues: {} as any
        };
    }

    componentDidMount() {
        this.setState({ formValues: this.props.schoolFormValues })
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.schoolFormValues !== this.props.schoolFormValues) {
            // this.setState({
            //     formValues: {
            //         ...this.state.formValues,
            //         rate: this.props.schoolFormValues.rate,
            //         rate_weekends: this.props.schoolFormValues.rate_weekends,
            //         rate_evenings: this.props.schoolFormValues.rate_evenings,
            //     }
            // })
            // this.props.getExtraRates({
            //     ...this.state.formValues,
            //     rate: this.props.schoolFormValues.rate,
            //     rate_weekends: this.props.schoolFormValues.rate_weekends,
            //     rate_evenings: this.props.schoolFormValues.rate_evenings,
            // })
        }
        if (prevState.formValues !== this.state.formValues) {

        }
    }

    rate = this.props.rate;

    render() {
        const real_vat = (1 + Number(this.state.formValues.rate_vat) / 100)
        return (
            <>
                <Row gutter={20}>
                    <Col span={5}></Col>
                    <Col span={4}>
                        <Form.Item
                            style={{ marginBottom: 0 }}
                            initialValue={this.rate.extra_rate}
                            name={`extra_rate`}
                            required
                            rules={[{ required: true, message: "Obligāts lauks" }]}
                            label="Likme stundā pēc limita"
                        >
                            <Input
                                onChange={(e: any) => {
                                    this.setState({
                                        formValues: {
                                            ...this.state.formValues,
                                            [e.target.id]: e.target.value
                                        }
                                    })
                                }}
                            />
                        </Form.Item>
                        <small>Ar PVN:{' '}
                            {
                                (Number(this.state.formValues[`extra_rate`]) > 0
                                    ? Number(this.state.formValues[`extra_rate`])
                                    : Number(this.state.formValues[`rate`]))
                                * real_vat
                            }
                        </small>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            style={{ marginBottom: 0 }}
                            initialValue={this.rate.extra_rate_weekends}
                            name={`extra_rate_weekends`}
                            required
                            rules={[{ required: true, message: "Obligāts lauks" }]}
                            label="Brīvdienu likme"
                        >
                            <Input
                                onChange={(e: any) => {
                                    this.setState({
                                        formValues: {
                                            ...this.state.formValues,
                                            [e.target.id]: e.target.value
                                        }
                                    })
                                }}
                            />
                        </Form.Item>
                        <small>Ar PVN:{' '}
                            {
                                (Number(this.state.formValues[`extra_rate_weekends`]) > 0
                                    ? Number(this.state.formValues[`extra_rate_weekends`])
                                    : Number(this.state.formValues[`rate_weekends`]))
                                * real_vat
                            }
                        </small>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            style={{ marginBottom: 0 }}
                            initialValue={this.rate.extra_rate_evenings}
                            name={`extra_rate_evenings`}
                            required
                            rules={[{ required: true, message: "Obligāts lauks" }]}
                            label="Vakara likme"
                        >
                            <Input
                                onChange={(e: any) => {
                                    this.setState({
                                        formValues: {
                                            ...this.state.formValues,
                                            [e.target.id]: e.target.value
                                        }
                                    })
                                }}
                            />
                        </Form.Item>
                        <small>Ar PVN:{' '}
                            {
                                (Number(this.state.formValues[`extra_rate_evenings`]) > 0
                                    ? Number(this.state.formValues[`extra_rate_evenings`])
                                    : Number(this.state.formValues[`rate_evenings`]))
                                * real_vat
                            }
                        </small>
                    </Col>
                </Row>
                {this.props.cities.map((city: any, index: any) => {
                    const cityName = this.props.classifiers.cities.filter((item: any) => {
                        return item.id === city;
                    })[0].value;
                    // console.log(cityName)
                    return <Row key={city} gutter={20}>
                        <Col span={5} style={{ display: "flex", alignItems: "center" }}>
                            <b>{cityName}</b>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                initialValue={this.rate.city_rates && this.rate.city_rates.length > 0 ? this.rate.city_rates[index]?.extra_rate : undefined}
                                name={`extra_rate-${city}`}
                                label="Likme stundā pēc limita"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                initialValue={this.rate.city_rates && this.rate.city_rates.length > 0 ? this.rate.city_rates[index]?.extra_rate_weekends : undefined}
                                name={`extra_rate_weekends-${city}`}
                                label="Brīvdienu likme"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                initialValue={this.rate.city_rates && this.rate.city_rates.length > 0 ? this.rate.city_rates[index]?.extra_rate_evenings : undefined}
                                name={`extra_rate_evenings-${city}`}
                                label="Vakara likme"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                })}
            </>

        );
    }
}

export function BaseEditInstructorServicePage2(props: any) {
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [initialData, setInitialData] = useState({} as any);
    const [instructor, setInstructor] = useState({} as any);
    const [classifiers, setClassifiers] = useState({} as any);
    const [form] = Form.useForm();
    const [isExternal, setIsExternal] = useState(Number(form.getFieldValue("is_external")));
    const [uploadedImage, setImage] = useState({});
    const [cities, setCities] = useState([] as any);
    const [changeImg, setChangeImg] = useState(false);
    const [fileData, setFileData] = useState({} as any);
    const [selectedCities, selectCities] = useState([] as any);
    const [selectedVirtualCities, selectVirtualCities] = useState([] as any);
    const [schoolRates, setSchoolRates] = useState([] as any);
    const [instructorRate, setInstructorRate] = useState({} as any);
    const [maponNumberPlates, setMaponNumberPlates] = useState([] as any);
    // let initialCities = [] as any;

    const uploadImage = (value: any) => {
        // console.log(value)
        setImage(value);
    }

    const getFile = (file: any) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            // this.setState({ fileData: reader.result });
            setFileData(reader.result);
        }
        // console.log(file)
        if (file && file?.length > 0) {
            reader.readAsDataURL(file[0].originFileObj);
        }
    }

    const deleteImg = () => {
        http.delete(`/rest/instructor-service-img/${initialData.id}`).then((response: any) => {
            // console.log(response)
            setChangeImg(false);
            setInitialData(processData(response.data.data))
            message.success("Attēls ir dzēsts")
        }).catch(() => {
            message.error("Kļūda")
        })
    }

    const processData = (serviceData: any) => {
        // console.log(serviceData);
        return {
            ...serviceData,
            category_id: String(serviceData.category_id),
            web: String(serviceData.web),
            city_ids: serviceData.cities.map((c: any) => c.id),
            instructor_rate: Number(serviceData.instructor_rate).toFixed(2),
            instructor_rate_vat: Number(serviceData.instructor_rate_vat).toFixed(2),
            is_external: String(serviceData.is_external),
            meeting_place: String(serviceData.meeting_place != null ? serviceData.meeting_place : "")
        }
    }

    const convertData = (serviceData: any) => {
        return {
            ...serviceData,
            category_id: Number(serviceData.category_id),
            web: Number(serviceData.web),
            is_external: Number(serviceData.is_external)
        }
    }

    async function loadClassifiers() {
        const categories = await http.get("/rest/categories");
        const cities = await http.get("/rest/cities");

        setClassifiers({
            categories: categories.data.data,
            cities: cities.data.data
        });
    }

    const getNumberPlates = () => {
        http.get(`rest/mapon-number-plates`).then((res: any) => {
            setMaponNumberPlates(res.data.data)
        })
    }

    const setNewCarID = (id: number) => {
        setInitialData((prev: any) => {
            return {
                ...prev,
                mapon_car_id: id
            }
        });
        form.setFieldsValue({ mapon_car_id: id })
    }

    async function loadEverything() {
        const data = (await http.get(`/rest/instructors/${props.match.params.id}`)).data.data;
        const initial = processData(data.services.find((service: any) => service.id === Number(props.match.params.serviceId)));
        setInstructor(data);
        selectCities(initial.city_services.map((city: any) => city.city_id))
        selectVirtualCities(initial.virtual_cities.map((city: any) => city.city_id))
        setCities(initial.city_services.map((city: any) => city.city_id));
        setInitialData(initial);
        setLoading(false);
        // console.log(data)
        setInstructorRate({
            basic_instructor_rate: initial.instructor_rate,
            basic_instructor_rate_evenings: initial.instructor_rate_evenings,
            basic_instructor_rate_weekends: initial.instructor_rate_weekends,
            basic_instructor_rate_vat: initial.instructor_rate_vat,
            ...initial.city_services.map((service: any) => {
                return {
                    [`basic_instructor_rate-${service.city_id}`]: service.instructor_rate,
                    [`basic_instructor_rate_evenings-${service.city_id}`]: service.instructor_rate_evenings,
                    [`basic_instructor_rate_weekends-${service.city_id}`]: service.instructor_rate_weekends,
                    [`basic_instructor_rate_vat-${service.city_id}`]: service.instructor_rate_vat,
                }
            }).flat()[0]
        });
    }

    const onFinish = (values: any) => {
        values.meeting_place = {};
        values.city_rates = {};
        values.school_rates = [];
        if (typeof values.busy_term === "string") {
            values.busy_term = values.busy_term.length === 0 ? null : Number(values.busy_term);
        }
        cities.forEach((city: any) => {
            values.meeting_place[city] = values[`meeting_place-${city}`];
            values.city_rates[city] = {
                instructor_rate: Number(values[`instructor_rate-${city}`]),
                instructor_rate_vat: Number(values[`instructor_rate_vat-${city}`]),
                instructor_rate_weekends: Number(values[`instructor_rate_weekends-${city}`]),
                instructor_rate_evenings: Number(values[`instructor_rate_evenings-${city}`]),
                instructor_extra_rate: Number(values[`instructor_extra_rate-${city}`]),
                instructor_extra_start_hours: Number(values[`instructor_extra_start_hours-${city}`]),
                instructor_extra_rate_weekends: Number(values[`instructor_extra_rate_weekends-${city}`]),
                instructor_extra_rate_evenings: Number(values[`instructor_extra_rate_evenings-${city}`]),
            }

        })
        let valuesToSubmit
        if (fileData) {
            if (fileData.length > 0) {
                valuesToSubmit = {
                    ...values,
                    img: fileData
                };
            } else {
                valuesToSubmit = {
                    ...values,
                };
            }
        } else {
            valuesToSubmit = {
                ...values,
            };
        }
        valuesToSubmit.allow_bought_slot_del = values.allow_bought_slot_del ? 1 : 0;
        http.patch(`/rest/services/${props.match.params.serviceId}`, convertData(valuesToSubmit)).then((response: any) => {
            // loadEverything();
            // console.log(response)
            message.success("Izmaiņas veiksmīgi saglabātas")
        }).catch(() => {
            message.error("Saglabāšanas kļūda")
        });
    }

    const onFinishFailed = (errors: any) => {
        message.error('Pārbaudiet vai visi lauki ir aizpildīti');
    }

    const createNewSchoolRate = () => {
        http.post(`/rest/services/${initialData.id}/school_rates`, {
            description: "Nosaukums",
            rate: "0",
            rate_weekends: "0",
            rate_evenings: "0",
            rate_vat: "0",
            cities: cities.map((city: any) => {
                return {
                    id: city,
                    rate: "0",
                    rate_weekends: "0",
                    rate_evenings: "0",
                    rate_vat: "0",
                }
            })
        }).then((response: any) => {
            // console.log(response);
            setInitialData((prevData: any) => {
                const newSchoolRate = [
                    {
                        ...response.data.data
                    }
                ]
                return {
                    ...prevData,
                    school_rates: prevData.school_rates.concat(newSchoolRate)
                }
            })
            loadEverything();
        });

    }

    const deleteSchoolRate = (id: any) => {
        setInitialData((prevData: any) => {
            const newSchoolRates = prevData.school_rates.filter((rate: any) => {
                return rate.id !== id;
            })
            // console.log(newSchoolRates);
            return {
                ...prevData,
                school_rates: newSchoolRates,
            }
        })
    }

    const recalcPrices = () => {
        http.post(`/rest/recalculate-free-slot-prices/${initialData.id}`).then((response: any) => {
            message.success("Cenas ir veiksmīgi pārrēķinātas")
        }).catch(() => {
            message.error("Kļūda!")
        })
    }

    const onIsExternalChange = (value: any) => {
        setIsExternal(Number(value));
    }

    const selectedCitiesHandler = (values: any) => {
        if (selectedCities.some((el: any) => !values.some((val: any) => val === el))) {
            message.error('Nevar dzēst esošās pilsētas')
        }

        setCities(() => {
            const list = selectedCities.concat(values).filter((city: any, index: any, self: any) => {
                return self.indexOf(city) === index;
            })
            form.setFieldsValue({ city_ids: list })
            return list;
        })
    };

    const setSchoolRatesHandler = (rate: any) => {
        const hasItem = schoolRates.some((el: any) => el.id === rate.id)
        const newRates = hasItem
            ? [...schoolRates.map((item: any) => {
                if (item.id === rate.id) {
                    return rate;
                } else {
                    return item;
                }
            })]
            : schoolRates.concat([rate]);
        setSchoolRates(newRates);
    }

    useEffect(() => {
        loadClassifiers().then(() => {
            loadEverything();
            getNumberPlates();
        });
    }, []);

    useEffect(() => {
        console.log(initialData)
        setSchoolRates(initialData.school_rates)
    }, [initialData]);

    useEffect(() => {
        setIsExternal(Number(form.getFieldValue("is_external")));
    });

    const content = loading ? (
        <Card>
            <Skeleton />
        </Card>
    ) : (
        <>
            <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={initialData}
                form={form}
            >
                <Card style={{ marginBottom: 5 }} title={<Row justify='space-between'>
                    <strong>Pakalpojuma kategorija</strong>
                    <Row>
                        <div style={{ marginRight: 20 }}>
                            <span>Rādīšanas ierobežojums (dienās):</span>
                            <Form.Item name="busy_term" style={{ display: "inline-block", margin: "0 0 0 15px" }}>
                                <Input style={{ width: 80 }} type="number" min={0} step={1} />
                            </Form.Item>
                            {
                                form.getFieldValue("busy_term") !== null
                                    ? <Button
                                        onClick={() => {
                                            form.setFieldsValue({ busy_term: null });
                                        }}
                                    >
                                        Atcelt
                                    </Button>
                                    : null
                            }
                        </div>
                        <div>
                            <span>Prioritāte:</span>
                            <Form.Item name="priority" style={{ display: "inline-block", margin: "0 0 0 15px" }}>
                                <Input style={{ width: 80 }} type="number" min={0} step={1} />
                            </Form.Item>
                        </div>
                    </Row>
                </Row>}>
                    <Row gutter={20}>
                        <Col>
                            <Form.Item name="category_id" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Kategorija">
                                <Select style={{ width: 150 }}>
                                    {classifiers.categories
                                        ? classifiers.categories.map((cat: any) => {
                                            return <Select.Option key={cat.id} value={String(cat.id)}>{cat.value}</Select.Option>
                                        }) : null}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        <Col>
                            <Form.Item name="is_external" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Instruktora pakalpojuma veids">
                                <Radio.Group onChange={onIsExternalChange}>
                                    <Radio.Button value="0">Skolas instruktors</Radio.Button>
                                    <Radio.Button value="1">Piesaistītais instruktors</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item valuePropName='checked' name="allow_bought_slot_del" label="Atļaut atcelt un dzēst nodarbību pašam">
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card style={{ marginBottom: 5 }} title={<strong>Transportlīdzekļa informācija</strong>}>
                    <Row gutter={20}>
                        <Col flex={1}>
                            <Form.Item name="vehicle" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Transportlīdzekļa marka">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item name="licence_plate" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Transportlīdzekļa numurs">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={40}>
                        <Col flex={1}>
                            <Form.Item name="transmission" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Ātrumkārba">
                                <Radio.Group>
                                    <Radio.Button value="manual">Manuālā</Radio.Button>
                                    <Radio.Button value="auto">Automātiskā</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item name="web" required rules={[{ required: true, message: "Obligāts lauks" }]} label="WEB pieejamība">
                                <Radio.Group>
                                    <Radio.Button value="0">Nav pieejams</Radio.Button>
                                    <Radio.Button value="1">Ir pieejams</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Button onClick={recalcPrices}>Pārrēķināt brīvo slotu cenas</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            {
                                initialData.img.includes('noimage.svg') || initialData.img === 'storage/instructors/' || changeImg
                                    ? <>
                                        <Form.Item name="image" label="Pievienot attēlu">
                                            <Avatar getImg={uploadImage} returnFile={getFile} aspect={16 / 9} />
                                        </Form.Item>
                                        {
                                            changeImg
                                                ? <Button style={{ margin: "10px auto" }} onClick={() => setChangeImg(false)}>Atcelt attēla maiņu</Button>
                                                : <></>
                                        }
                                    </>
                                    : <>
                                        <img width="335" height="225" src={process.env.REACT_APP_API_URL + initialData.img} alt="" /><br />
                                        <Space>
                                            <Button style={{ margin: "10px auto" }} onClick={() => setChangeImg(true)}>Mainīt attēlu</Button>
                                            <Button danger onClick={deleteImg} icon={<DeleteOutlined />}></Button>
                                        </Space>
                                    </>
                            }
                        </Col>
                        <Col span={12}>
                            <p>Mapon piesaistītā transportlīdzekļa numurs</p>
                            <Select
                                value={initialData.mapon_car_id}
                                style={{ width: 200, marginBottom: 20 }}
                                onChange={(e: any) => setNewCarID(e)}
                            >
                                {
                                    maponNumberPlates?.map((item: any) => {
                                        return <Select.Option key={item.unit_id} value={item.unit_id}>{item.number_plate}</Select.Option>
                                    })
                                }
                            </Select>
                            <Form.Item style={{ width: 250 }} name="mapon_car_id" label="Mapon car ID">
                                <Input />
                            </Form.Item>
                            <Form.Item style={{ width: 250 }} name="mapon_key" label="Mapon key (Tikai, ja nav no Einšteins Mapon konta!)">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card style={{ marginBottom: 5 }} title={<strong>Pakalpojuma veikšanas vieta</strong>}>
                    <Row>
                        <Col span={24}>
                            <Form.Item initialValue={selectedVirtualCities} name="virtual_ids" label="Virtuālās pilsētas">
                                <Select mode="multiple" style={{ maxWidth: "50%" }}>
                                    {classifiers.cities
                                        ? classifiers.cities.map((city: any) =>
                                            <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                                        )
                                        : null}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col flex={1}>
                            <Form.Item name="city_ids" required rules={[{ required: true, message: "Obligāts lauks", type: "array" }]} label="Pilsētas">
                                <Select mode="multiple" onChange={selectedCitiesHandler}>
                                    {classifiers.cities
                                        ? classifiers.cities.map((city: any) => {
                                            return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                                        }) : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            {cities.map((city: any) => {
                                const cityVal = classifiers.cities.filter((item: any) => {
                                    return item.id === city;
                                })[0].value;
                                const service = initialData.city_services.filter((service: any) => {
                                    return city === service.city_id;
                                });
                                return <Form.Item
                                    key={city}
                                    name={`meeting_place-${city}`}
                                    required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]}
                                    label={`Tikšanās vieta ${cityVal}`}
                                    initialValue={service.length > 0 ? service[0].meeting_place : ""}
                                >
                                    <Input />
                                </Form.Item>

                            })}
                        </Col>
                    </Row>
                </Card>

                <Card style={{ marginBottom: 5 }} title={<strong>Instruktora likme</strong>}>
                    <Row gutter={20}>
                        <Col flex={1}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name="instructor_rate"
                                required rules={[{ required: true, message: "Obligāts lauks" }]}
                                label="Likme stundā"
                            >
                                <Input
                                    onChange={(e: any) => {
                                        setInstructorRate((prev: any) => {
                                            return {
                                                ...prev,
                                                [e.target.id]: e.target.value
                                            }
                                        })
                                    }}
                                />
                            </Form.Item>
                            <small>Kopā:{` `}
                                {
                                    Number(instructorRate?.basic_instructor_rate) * (1 + Number(instructorRate.basic_instructor_rate_vat) / 100)
                                    + schoolRates.reduce((prev: any, current: any) => {
                                        return prev + (Number(current.rate) * (1 + Number(current.rate_vat) / 100))
                                    }, 0)
                                }
                            </small>
                        </Col>
                        <Col flex={1}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name="instructor_rate_weekends"
                                required
                                rules={[{ required: true, message: "Obligāts lauks" }]}
                                label="Brīvdienu likme"
                            >
                                <Input
                                    onChange={(e: any) => {
                                        setInstructorRate((prev: any) => {
                                            return {
                                                ...prev,
                                                [e.target.id]: e.target.value
                                            }
                                        })
                                    }}
                                />
                            </Form.Item>
                            <small>Kopā:{` `}
                                {
                                    Number(instructorRate?.basic_instructor_rate_weekends) * (1 + Number(instructorRate.basic_instructor_rate_vat) / 100)
                                    + schoolRates.reduce((prev: any, current: any) => {
                                        return prev + (Number(current.rate_weekends) * (1 + Number(current.rate_vat) / 100))
                                    }, 0)
                                }
                            </small>
                        </Col>
                        <Col flex={1}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name="instructor_rate_evenings"
                                required
                                rules={[{ required: true, message: "Obligāts lauks" }]}
                                label="Vakara likme"
                            >
                                <Input
                                    onChange={(e: any) => {
                                        setInstructorRate((prev: any) => {
                                            return {
                                                ...prev,
                                                [e.target.id]: e.target.value
                                            }
                                        })
                                    }}
                                />
                            </Form.Item>
                            <small>Kopā:{` `}
                                {
                                    Number(instructorRate?.basic_instructor_rate_evenings) * (1 + Number(instructorRate.basic_instructor_rate_vat) / 100)
                                    + schoolRates.reduce((prev: any, current: any) => {
                                        return prev + (Number(current.rate_evenings) * (1 + Number(current.rate_vat) / 100))
                                    }, 0)
                                }
                            </small>
                        </Col>
                        <Col flex={1}>
                            <Form.Item name="instructor_rate_vat" required rules={[{ required: true, message: "Obligāts lauks" }]} label="PVN (%)">
                                <Input
                                    onChange={(e: any) => {
                                        setInstructorRate((prev: any) => {
                                            return {
                                                ...prev,
                                                [e.target.id]: e.target.value
                                            }
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        isExternal
                            ? (<Row gutter={20}>
                                <Col flex={1}>
                                    <Form.Item name="instructor_extra_start_hours" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Stundu limits">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col flex={1}>
                                    <Form.Item name="instructor_extra_rate" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Likme stundā pec limita">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col flex={1}>
                                    <Form.Item name="instructor_extra_rate_weekends" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Brīvdienu likme">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col flex={1}>
                                    <Form.Item name="instructor_extra_rate_evenings" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Vakara likme">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>)
                            : null
                    }
                </Card>

                {cities.map((city: any) => {
                    const cityVal = classifiers.cities.filter((item: any) => {
                        return item.id === city;
                    })[0].value;
                    const service = initialData.city_services.filter((service: any) => {
                        return city === service.city_id;
                    });
                    const vat = Number(instructorRate[`basic_instructor_rate_vat-${city}`]) > 0
                        ? Number(instructorRate[`basic_instructor_rate_vat-${city}`])
                        : Number(instructorRate.basic_instructor_rate_vat)
                    const rate = Number(instructorRate[`basic_instructor_rate-${city}`]) > 0
                        ? Number(instructorRate[`basic_instructor_rate-${city}`])
                        : Number(instructorRate.basic_instructor_rate)
                    const rate_evenings = Number(instructorRate[`basic_instructor_rate_evenings-${city}`]) > 0
                        ? Number(instructorRate[`basic_instructor_rate_evenings-${city}`])
                        : Number(instructorRate.basic_instructor_rate_evenings)
                    const rate_weekends = Number(instructorRate[`basic_instructor_rate_weekends-${city}`]) > 0
                        ? Number(instructorRate[`basic_instructor_rate_weekends-${city}`])
                        : Number(instructorRate.basic_instructor_rate_weekends)
                    const school_rate = schoolRates.reduce((prev: any, current: any) => {
                        const city_rate = current.hasOwnProperty(`rate-${city}`)
                            ? Number(current[`rate-${city}`])
                            : current.hasOwnProperty('city_rates')
                                ? current.city_rates?.find((el: any) => el.city_id === city)
                                : 0
                        const real_rate = typeof city_rate === "number"
                            ? city_rate > 0
                                ? city_rate
                                : Number(current?.rate)
                            : Number(city_rate?.rate) > 0
                                ? Number(city_rate?.rate)
                                : Number(current?.rate)
                        const real_vat = current?.rate_vat
                        return prev + (real_rate * (1 + real_vat / 100))
                    }, 0);
                    const school_rate_evenings = schoolRates.reduce((prev: any, current: any) => {
                        const city_rate = current.hasOwnProperty(`rate_evenings-${city}`)
                            ? Number(current[`rate_evenings-${city}`])
                            : current.hasOwnProperty('city_rates')
                                ? current.city_rates?.find((el: any) => el.city_id === city)
                                : 0
                        const real_rate = typeof city_rate === "number"
                            ? city_rate > 0
                                ? city_rate
                                : Number(current?.rate_evenings)
                            : Number(city_rate?.rate_evenings) > 0
                                ? Number(city_rate?.rate_evenings)
                                : Number(current?.rate_evenings)
                        const real_vat = current?.rate_vat
                        return prev + (real_rate * (1 + real_vat / 100))
                    }, 0);
                    const school_rate_weekends = schoolRates.reduce((prev: any, current: any) => {
                        const city_rate = current.hasOwnProperty(`rate_weekends-${city}`)
                            ? Number(current[`rate_weekends-${city}`])
                            : current.hasOwnProperty('city_rates')
                                ? current.city_rates?.find((el: any) => el.city_id === city)
                                : 0
                        const real_rate = typeof city_rate === "number"
                            ? city_rate > 0
                                ? city_rate
                                : Number(current?.rate_weekends)
                            : Number(city_rate?.rate) > 0
                                ? Number(city_rate?.rate_weekends)
                                : Number(current?.rate_weekends)
                        const real_vat = current?.rate_vat
                        return prev + (real_rate * (1 + real_vat / 100))
                    }, 0);
                    return (
                        <Card key={city} style={{ marginBottom: 5 }} title={<strong>Instruktora likme {cityVal}</strong>}>
                            <Row gutter={20}>
                                <Col flex={1}>
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name={`instructor_rate-${city}`}
                                        label="Likme stundā"
                                        initialValue={service.length > 0 ? service[0].instructor_rate : null}
                                    >
                                        <Input
                                            onChange={(e: any) => {
                                                setInstructorRate((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        [e.target.id]: e.target.value
                                                    }
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                    <small>
                                        Kopā:{' '}
                                        {
                                            rate * (1 + vat / 100) + school_rate
                                            // + schoolRates.reduce((prev: any, current: any) => {
                                            //     return prev + (Number(current.rate_evenings) * (1 + Number(current.rate_vat) / 100))
                                            // }, 0)
                                        }
                                    </small>
                                </Col>
                                <Col flex={1}>
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name={`instructor_rate_weekends-${city}`}
                                        label="Brīvdienu likme"
                                        initialValue={service.length > 0 ? service[0].instructor_rate_weekends : null}
                                    >
                                        <Input
                                            onChange={(e: any) => {
                                                setInstructorRate((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        [e.target.id]: e.target.value
                                                    }
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                    <small>
                                        Kopā:{' '}
                                        {
                                            rate_weekends * (1 + vat / 100) + school_rate_weekends
                                        }
                                    </small>
                                </Col>
                                <Col flex={1}>
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name={`instructor_rate_evenings-${city}`}
                                        label="Vakara likme"
                                        initialValue={service.length > 0 ? service[0].instructor_rate_evenings : null}
                                    >
                                        <Input
                                            onChange={(e: any) => {
                                                setInstructorRate((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        [e.target.id]: e.target.value
                                                    }
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                    <small>
                                        Kopā:{' '}
                                        {
                                            rate_evenings * (1 + vat / 100) + school_rate_evenings
                                        }
                                    </small>
                                </Col>
                                <Col flex={1}>
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name={`instructor_rate_vat-${city}`}
                                        label="PVN (%)"
                                        initialValue={service.length > 0 ? service[0].instructor_rate_vat : null}
                                    >
                                        <Input
                                            onChange={(e: any) => {
                                                setInstructorRate((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        [e.target.id]: e.target.value
                                                    }
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {
                                isExternal
                                    ? (<Row gutter={20}>
                                        <Col flex={1}>
                                            <Form.Item
                                                name={`instructor_extra_start_hours-${city}`}
                                                label="Stundu limits"
                                                initialValue={service.length > 0 ? service[0].instructor_extra_start_hours : null}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col flex={1}>
                                            <Form.Item
                                                name={`instructor_extra_rate-${city}`}
                                                label="Likme stundā pec limita"
                                                initialValue={service.length > 0 ? service[0].instructor_extra_rate : null}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col flex={1}>
                                            <Form.Item
                                                name={`instructor_extra_rate_weekends-${city}`}
                                                label="Brīvdienu likme"
                                                initialValue={service.length > 0 ? service[0].instructor_extra_rate_weekends : null}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col flex={1}>
                                            <Form.Item
                                                name={`instructor_extra_rate_evenings-${city}`}
                                                label="Vakara likme"
                                                initialValue={service.length > 0 ? service[0].instructor_extra_rate_evenings : null}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>)
                                    : null
                            }
                        </Card>
                    )
                })}

                <Card style={{ marginBottom: 20 }}>
                    <Row gutter={20}>
                        <Col>
                            <Button type="primary" htmlType="submit">Saglabāt</Button>
                        </Col>
                    </Row>
                </Card>

            </Form>
            <Card style={{ marginBottom: 20 }} title={<strong>Skolas likme</strong>}>
                {initialData.school_rates.map((rate: any) => {
                    return <SchoolRate
                        getRates={(rates: any) => setSchoolRatesHandler(rates)}
                        key={rate.id}
                        classifiers={classifiers}
                        cities={cities}
                        deleteRate={deleteSchoolRate}
                        rate={rate}
                        external={isExternal}
                    />
                })}
                <Row gutter={20}>
                    <Col>
                        <Button onClick={createNewSchoolRate}>Izveidot jaunu pozīciju</Button>
                    </Col>
                </Row>
            </Card>
        </>
    );

    return <Layout>
        <PageHeader
            style={{ padding: "0 16px", background: "rgb(255, 255, 255)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}
            onBack={() => { props.history.push(`/instructors/edit/${instructor.id}`); }}
            title={
                <>
                    <span style={{ fontSize: "0.7em", fontWeight: "normal" }}>Doties atpakaļ</span>
                    <span style={{ marginLeft: 40 }}>{loading ? `` : `${instructor.first_name} ${instructor.last_name} / Pakalpojums`}</span>
                </>
            }
        />
        <Content style={{ padding: 20 }}>
            {content}
        </Content>
    </Layout>;
}

export type NewInstructorServicePageProps = RouteComponentProps<{
    id: string
}> & {
};
export type NewInstructorServicePageState = {
    submitting: boolean,
    loading: boolean,
    instructor: any,
    classifiers: any,
    fileData: any,
    img: any,
    cities: [],
    virtual_ids: [],
};

export class BaseNewInstructorServicePage extends React.Component<NewInstructorServicePageProps, NewInstructorServicePageState> {

    constructor(props: NewInstructorServicePageProps) {
        super(props);

        this.state = {
            submitting: false,
            loading: true,
            instructor: {},
            classifiers: {
                cities: [],
                categories: []
            },
            fileData: {},
            img: {},
            cities: [],
            virtual_ids: []
        };
    }

    componentDidMount() {
        (window["component" as any] as any) = this;
        this.reloadEverything();
    }

    async loadClassifiers() {
        const cities = await http.get("/rest/cities");
        const categories = await http.get("/rest/categories");

        this.setState({
            classifiers: {
                cities: cities.data.data,
                categories: categories.data.data
            }
        });
    }

    reloadEverything = () => {
        this.loadClassifiers().then(() => {
            http.get(`/rest/instructors/${this.props.match.params.id}`).then(response => {
                const data = response.data.data;
                this.setState({
                    instructor: data,
                    loading: false
                });
            });
        })
    }

    convertData = (serviceData: any) => {
        return {
            ...serviceData,
            category_id: Number(serviceData.category_id),
            web: Number(serviceData.web),
            is_external: Number(serviceData.is_external),
            city_id: Number(serviceData.city_id)
        }
    }

    uploadImage = (value: any) => {
        this.setState({ img: value });
    }

    getFile = (file: any) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            this.setState({ fileData: reader.result });
        }
        // console.log(file)
        if (file && file?.length > 0) {
            reader.readAsDataURL(file[0].originFileObj);
        }
    }

    onFinish = (values: any) => {
        // console.log(values);
        values.meeting_place = {};
        values.city_rates = {};
        this.state.cities.forEach((city: any) => {
            // console.log("Check")
            values.meeting_place[city] = values[`meeting_place-${city}`];
            values.city_rates[city] = {
                instructor_rate: Number(values[`instructor_rate-${city}`]),
                instructor_rate_vat: Number(values[`instructor_rate_vat-${city}`]),
                instructor_rate_weekends: Number(values[`instructor_rate_weekends-${city}`]),
                instructor_rate_evenings: Number(values[`instructor_rate_evenings-${city}`]),
            }
        })
        values.school_rates = [];
        // console.log(values);
        // console.log(this.state.fileData);
        let valuesToSubmit
        if (this.state.fileData) {
            if (this.state.fileData.length > 0) {
                valuesToSubmit = {
                    ...values,
                    img: this.state.fileData
                };
            } else {
                valuesToSubmit = {
                    ...values,
                };
            }
        } else {
            valuesToSubmit = {
                ...values,
            };
        }
        http.post(`/rest/instructors/${this.props.match.params.id}/services`, this.convertData(valuesToSubmit)).then((result: any) => {
            const serviceId = result.data.data.id;
            window.location.href = `/instructors/edit/${this.props.match.params.id}/services/edit/${serviceId}`
            // this.props.history.replace(`/instructors/edit/${this.props.match.params.id}/services/edit/${serviceId}`);
        }).catch(() => {
            message.error("Kļūda");
        });
    }

    onFinishFailed = (errors: any) => {
        message.error('Pārbaudiet vai visi lauki ir aizpildīti');
    }

    render() {
        const content = this.state.loading ? (
            <Card>
                <Skeleton />
            </Card>
        ) : (
            <>
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Card style={{ marginBottom: 5 }} title={<Row justify='space-between'>
                        <strong>Pakalpojuma kategorija</strong>
                        <div>
                            <span>Prioritāte:</span>
                            <Form.Item name="priority" style={{ display: "inline-block", margin: "0 0 0 15px" }}>
                                <Input style={{ width: 80 }} type="number" min={0} step={1} />
                            </Form.Item>
                        </div>
                    </Row>}>
                        <Row gutter={20}>
                            <Col>
                                <Form.Item name="category_id" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Kategorija">
                                    <Select style={{ width: 150 }}>
                                        {this.state.classifiers.categories.map((cat: any) =>
                                            <Select.Option key={cat.id} value={String(cat.id)}>{cat.value}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col>
                                <Form.Item name="is_external" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Instruktora pakalpojuma veids">
                                    <Radio.Group>
                                        <Radio.Button value="0">Skolas instruktors</Radio.Button>
                                        <Radio.Button value="1">Piesaistītais instruktors</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>

                    <Card style={{ marginBottom: 5 }} title={<strong>Transportlīdzekļa informācija</strong>}>
                        <Row gutter={20}>
                            <Col flex={1}>
                                <Form.Item name="vehicle" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Transportlīdzekļa marka">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col flex={1}>
                                <Form.Item name="licence_plate" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Transportlīdzekļa numurs">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={40}>
                            <Col flex={1}>
                                <Form.Item name="transmission" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Ātrumkārba">
                                    <Radio.Group>
                                        <Radio.Button value="manual">Manuālā</Radio.Button>
                                        <Radio.Button value="auto">Automātiskā</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col flex={1}>
                                <Form.Item name="web" required rules={[{ required: true, message: "Obligāts lauks" }]} label="WEB pieejamība">
                                    <Radio.Group>
                                        <Radio.Button value="0">Nav pieejams</Radio.Button>
                                        <Radio.Button value="1">Ir pieejams</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Item name="image" label="Pievienot attēlu">
                                    <Avatar getImg={this.uploadImage} returnFile={this.getFile} aspect={16 / 9} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>

                    <Card style={{ marginBottom: 5 }} title={<strong>Pakalpojuma veikšanas vieta</strong>}>
                        <Row>
                            <Col span={24}>
                                <Form.Item name="virtual_ids" label="Virtuālās pilsētas">
                                    <Select mode="multiple" style={{ maxWidth: "50%" }} onChange={(e: any) => this.setState({ virtual_ids: e })}>
                                        {this.state.classifiers.cities.map((city: any) =>
                                            <Select.Option value={city.id}>{city.value}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col flex={1}>
                                <Form.Item name="city_ids" required rules={[{ required: true, message: "Obligāts lauks", type: "array" }]} label="Pilsētas">
                                    <Select mode="multiple" onChange={(e: any) => this.setState({ cities: e })}>
                                        {this.state.classifiers.cities.map((city: any) =>
                                            <Select.Option value={city.id}>{city.value}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col flex={1}>
                                {this.state.cities.map((city: any) => {
                                    const cityVal = this.state.classifiers.cities.filter((cityName: any) => {
                                        return cityName.id === city;
                                    })[0].value;
                                    return (
                                        <Form.Item
                                            key={city}
                                            name={`meeting_place-${city}`}
                                            required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]}
                                            label={`Tikšanās vieta ${cityVal}`}>
                                            <Input />
                                        </Form.Item>
                                    )
                                })}
                            </Col>
                        </Row>
                    </Card>

                    <Card style={{ marginBottom: 5 }} title={<strong>Instruktora stundas likme</strong>}>
                        <Row gutter={20}>
                            <Col flex={1}>
                                <Form.Item name={`instructor_rate`} required rules={[{ required: true, message: "Obligāts lauks" }]} label="Parastā likme">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col flex={1}>
                                <Form.Item name={`instructor_rate_weekends`} required rules={[{ required: true, message: "Obligāts lauks" }]} label="Brīvdienu likme">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col flex={1}>
                                <Form.Item name={`instructor_rate_evenings`} required rules={[{ required: true, message: "Obligāts lauks" }]} label="Vakara likme">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col flex={1}>
                                <Form.Item name={`instructor_rate_vat`} required rules={[{ required: true, message: "Obligāts lauks" }]} label="PVN (%)">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    {this.state.cities.map((city: any) => {
                        const cityVal = this.state.classifiers.cities.filter((cityName: any) => {
                            return cityName.id === city;
                        })[0].value;
                        return (
                            <Card style={{ marginBottom: 5 }} title={<strong>Instruktora stundas likme {cityVal}</strong>}>
                                <Row gutter={20}>
                                    <Col flex={1}>
                                        <Form.Item name={`instructor_rate-${city}`} label="Parastā likme">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col flex={1}>
                                        <Form.Item name={`instructor_rate_weekends-${city}`} label="Brīvdienu likme">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col flex={1}>
                                        <Form.Item name={`instructor_rate_evenings-${city}`} label="Vakara likme">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col flex={1}>
                                        <Form.Item name={`instructor_rate_vat-${city}`} label="PVN (%)">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    })}

                    <Card style={{ marginBottom: 20 }}>
                        <Row gutter={20}>
                            <Col>
                                <Button type="primary" htmlType="submit">Saglabāt</Button>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </>
        );

        return <Layout>
            <PageHeader
                style={{ padding: "0 16px", background: "rgb(255, 255, 255)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}
                onBack={() => { this.props.history.goBack(); }}
                title={
                    <>
                        <span style={{ fontSize: "0.7em", fontWeight: "normal" }}>Doties atpakaļ</span>
                        <span style={{ marginLeft: 40 }}>{this.state.loading ? `` : `${this.state.instructor.first_name} ${this.state.instructor.last_name} / Jauns pakalpojums`}</span>
                    </>
                }
            />
            <Content style={{ padding: 20 }}>
                {content}
            </Content>
        </Layout>;
    }

}

export const EditInstructorOutlinePage = withRouter(BaseEditInstructorOutlinePage);
export const EditInstructorServicePage = withRouter(BaseEditInstructorServicePage2);
export const NewInstructorServicePage = withRouter(BaseNewInstructorServicePage);

export const EditInstructorPage = withRouter((props: RouteComponentProps) => {
    return (
        <Switch>
            <Route exact path={props.match.path}>
                <EditInstructorOutlinePage />
            </Route>
            <Route path={`${props.match.path}/services/edit/:serviceId`}>
                <EditInstructorServicePage />
            </Route>
            <Route path={`${props.match.path}/services/new`}>
                <NewInstructorServicePage />
            </Route>
        </Switch>
    );
});