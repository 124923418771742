import { Typography, Button, Card, Col, DatePicker, Form, Input, InputNumber, Layout, PageHeader, Row, Select, Skeleton, TimePicker, Space, message, Tabs, Checkbox, Modal } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory, withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import { Services } from "./Services";
import { TeachersHandler } from "./TeachersHandler";
import { CheckpointsForm } from "./CheckpointsForm";
import { checkServerIdentity } from "tls";
import { initial } from "lodash";
import { ServicesPriceLog } from "./ServicesPriceLog";

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

export const EditGroupInfo = withRouter((props: any) => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false);
    const [initialData, setInitialData] = useState({} as any);
    const [dataToSend, setData] = useState({} as any);
    const [checkpoints, setCheckpointsList] = useState([]);
    const [services, setServicesList] = useState([]);
    const [allSet, setAllSet] = useState({ services: false, checkpoints: false })
    const [formData, setFormData] = useState({} as any);
    const [courseType, setCourseType] = useState({} as any);
    const [classifiers, setClassifiers] = useState({} as any);
    const [saveTrigger, setSaveTrigger] = useState(0);
    const [selectedCities, setSelectedCities] = useState([] as any);
    const [serviceHistory, setServiceHistory] = useState({
        service: null as any,
        group: null,
        visible: false,
    });
    const [csddCodes, setCsddCodes] = useState([] as any);
    const [dateTimeChanged, setDateTimeChanged] = useState(false);

    async function loadClassifiers() {
        const languages = await http.get("/rest/languages");
        const cities = await http.get("/rest/cities");
        const categories = await http.get("/rest/categories");
        const course_type = await http.get("/rest/study-groups-types");
        const status = await http.get("/rest/study-groups-status");
        const freeTeachers = await http.get("/rest/lecturers?no_paginate").then((response: any) => response.data.data);
        setClassifiers({
            languages: languages.data.data,
            cities: cities.data.data,
            categories: categories.data.data,
            status: status.data.data,
            course_type: course_type.data.data,
            teachers: freeTeachers,
        });
    }

    const rules = [
        {
            required: true,
            message: 'Šis lauks ir obligāts',
        },
    ]

    const fetchData = async () => {
        await http.get(`/rest/study-groups/${props.match.params.id}`).then(response => {
            const data = response.data.data;
            console.log(data)
            setInitialData(data);
            setCourseType(data.course_type)
            setSelectedCities(() => {
                return data.cities.map((city: any) => {
                    return city.id;
                })
            })
        });
    }

    const fetchCsddCodes = () => {
        http.get(`/rest/get-csdd-codes`).then((response: any) => {
            console.log(response)
            setCsddCodes(response.data.data)
        })
    }

    useEffect(() => {
        fetchCsddCodes();
        loadClassifiers().then(() => {
            fetchData();
        })
    }, []);

    useEffect(() => {
        if (!loaded) {
            setCheckpointsList(initialData.checkpoints);
            setServicesList(initialData.services)
        }
        if (Object.keys(classifiers).length > 0) {
            setLoaded(true);
        }

    }, [initialData]);

    useEffect(() => {
        if (saveTrigger > 0) {
            http.patch(`/rest/study-groups/${props.match.params.id}`, dataToSend).then((response: any) => {
                // setData(response.data.data)
                console.log('Ok');
                message.success("Informācija saglabāta");
            }).catch((e: any) => {
                console.log('not ok');
                message.error("Neizdevās saglabāt");

            }
            );
        }
        console.log(dataToSend, allSet)
    }, [dataToSend]);

    useEffect(() => {
        if (allSet.services && allSet.checkpoints) {
            setAllSet({ services: false, checkpoints: false });
            setData((data: any) => {
                return {
                    ...formData,
                    checkpoints: checkpoints,
                    services: services,
                    teachers: Object.keys(initialData).length > 0 ? initialData.lecturers.map((item: any) => item.id) : [],
                }
            })
        }
    }, [allSet])

    useEffect(() => {
        setAllSet((data: any) => {
            return {
                ...data,
                services: true,
            }
        })
    }, [services])

    useEffect(() => {
        setAllSet((data: any) => {
            return {
                ...data,
                checkpoints: true,
            }
        })
    }, [checkpoints])

    const processData = (data: any) => {
        console.log(data)
        let newCities = [];
        if (data.course_type === "Klātienes" || data.course_type === "Online apmācība") {
            newCities = data.cities.map((city: any) => {
                return {
                    [city]: {
                        registration_fee: data[`registration_fee-${city}`],
                        registration_discount: data[`registration_discount-${city}`],
                    }
                }
            })
        }

        const dataToSubmit = {
            ...data,
            category_id: classifiers.categories.filter((category: any) => {
                if (category.value === data.category) return category;
            })[0].id,
            cities: newCities,
            name: data.name,
            languages: classifiers.languages.filter((lang: any) => {
                return data.languages.some((vLang: any) => {
                    return vLang === lang.value;
                }) && lang;
            }).map((language: any) => language.id),
            planned_slots: data.planned_slots,
            extra_slots: data.extra_slots,
            extra_slots_price: data.extra_slots_price,
            study_type_id: classifiers.course_type.filter((course: any) => {
                return data.course_type === course.value;
            })[0].id,
            show_web: (data.show_web === "yes" ? 1 : 0),
            start_date: moment(data.start_date).format("YYYY-MM-DD"),
            start_time: moment(data.start_end_time[0]).format("HH:mm:ss"),
            end_time: moment(data.start_end_time[1]).format("HH:mm:ss"),
            csdd_nr: data.csdd_nr,
            status_id: initialData.group_status.id,
            comment: data.comment,
            group_for_statistic_survey: data.group_for_statistic_survey ? 1 : 0,
            real_elearning: data.real_elearning ? 1 : 0,
            fake_elearning: data.fake_elearning ? 1 : 0,
            approve_spam_sending: data.approve_spam_sending
        }
        console.log(dataToSubmit);
        return dataToSubmit;
    }

    const onFinish = (values: any) => {
        console.log(initialData, values)
        let dateTimeChanged = false;
        const start_date = moment(values.start_date).format("YYYY-MM-DD")
        const start_time = moment(values.start_end_time[0]).format("HH:mm:ss")
        const end_time = moment(values.start_end_time[1]).format("HH:mm:ss")
        if (start_date !== initialData.start_date || start_time !== initialData.start_time || end_time !== initialData.end_time) {
            dateTimeChanged = true;
        }
        if (values.csdd_nr === undefined || values.csdd_nr === "" || values.csdd_nr === null) values.csdd_nr = `undefined_${initialData.id}`
        if (dateTimeChanged) {
            Modal.confirm({
                content: "Datums un/vai laiks ir izmainīts! Vai vēlaties nosūtīt grupas studentiem paziņojumu?",
                onOk: () => {
                    values.approve_spam_sending = 1
                    setSaveTrigger(saveTrigger + 1);
                    setFormData(processData(values));
                },
                onCancel: () => {
                    values.approve_spam_sending = 1
                    setSaveTrigger(saveTrigger + 1);
                    setFormData(processData(values));
                }
            })
        } else {
            setSaveTrigger(saveTrigger + 1);
            setFormData(processData(values));
        }
    }

    const handleCourseChange = (event: any) => {
        setCourseType(() => {
            return classifiers.course_type.filter((course: any) => {
                return event === course.value;
            })[0]
        });
    }

    const updateTeachers = (newList: any) => {
        setInitialData((data: any) => {
            return {
                ...data,
                lecturers: newList
            }
        })
    }

    return (
        <>
            {!loaded ?
                <Card style={{ width: "100%" }}>
                    <Skeleton />
                </Card>
                :
                <Layout>
                    <Row>
                        {/* <Button onClick={() => console.log(initialData, courseType)}>Console.Log</Button> */}
                        <PageHeader
                            onBack={history.goBack}
                            title={
                                <Title level={4} style={{ marginLeft: 40, marginBottom: 0 }}>Labot grupas informāciju</Title>
                            }
                        />
                    </Row>
                    <Row>
                        <Card style={{ width: "100%", margin: 15 }}>
                            <Form
                                layout="vertical"
                                onFinish={onFinish}
                                initialValues={
                                    {
                                        category: initialData.category.value,
                                        name: initialData.name,
                                        course_type: initialData.course_type.value,
                                        languages: initialData.languages.map((lang: any) => lang.value),
                                        cities: initialData.cities.map((city: any) => city.id),
                                        start_date: initialData.start_date !== null ? moment(initialData.start_date, "YYYY-MM-DD") : undefined,
                                        start_end_time: initialData.start_time !== null
                                            ? [moment(initialData.start_time, "HH:mm"), moment(initialData.end_time, "HH:mm")]
                                            : [],
                                        planned_slots: initialData.planned_slots,
                                        show_web: initialData.show_web ? "yes" : "no",
                                        csdd_nr: initialData.csdd_nr,
                                        extra_slots: initialData.extra_slots,
                                        extra_slots_price: initialData.extra_slots_price,
                                        comment: initialData.comment,
                                        registration_fee: initialData.registration_fee,
                                        registration_discount: initialData.registration_discount,
                                        registration_vat: initialData.registration_vat,
                                        comment_lv: initialData.comment_lv,
                                        comment_en: initialData.comment_en,
                                        comment_ru: initialData.comment_ru,
                                        description_lv: initialData.description_lv,
                                        description_en: initialData.description_en,
                                        description_ru: initialData.description_ru,
                                        csdd_code: initialData.csdd_code || initialData.category.csdd_code,
                                        group_for_statistic_survey: initialData.group_for_statistic_survey,
                                        real_elearning: initialData.real_elearning,
                                        fake_elearning: initialData.fake_elearning
                                    }
                                }
                            >
                                <Row gutter={[2, 24]}>
                                    <Col span={24} >
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Saglabāt
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} lg={8}>
                                        <Form.Item name="category" label="Kategorija" rules={rules}>
                                            <Select style={{ width: 200 }}>
                                                {classifiers.categories.map((category: any) => {
                                                    return <Option key={category.id} value={category.value}>{category.value}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} lg={8}>
                                        <Form.Item name="csdd_code" label="CSDD kods">
                                            <Select style={{ width: 200 }}>
                                                {csddCodes.map((code: any) => {
                                                    return <Option key={code.code} value={code.code}>{code.code}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} lg={8}>
                                        <Form.Item name="name" label="Nosaukums" rules={rules}>
                                            <Input style={{ width: 200 }} />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} lg={8}>
                                        <Form.Item name="course_type" label="Apmācību veids" rules={rules}>
                                            <Select style={{ width: 200 }} onChange={handleCourseChange}>
                                                {classifiers.course_type.map((type: any) => {
                                                    return <Option key={type.value} value={type.value}>{type.value}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} lg={8}>
                                        <Form.Item name="languages" label="Valoda" rules={rules}>
                                            <Select style={{ width: 200 }} mode="multiple">
                                                {classifiers.languages.map((language: any) => {
                                                    return <Option key={language.id} value={language.value}>{language.value}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {courseType.value === "Tiešsaistes" ? <></> : courseType.value === "E-apmācība" ? <></> :
                                        <Col md={12} lg={8}>
                                            <Form.Item name="cities" label="Pilsēta" rules={rules}>
                                                <Select style={{ width: 200 }} mode="multiple" onChange={(e: any) => setSelectedCities(e)}>
                                                    {classifiers.cities.map((city: any) => {
                                                        return <Option key={city.id} value={city.id}>{city.value}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    }
                                    <Col md={12} lg={8}>
                                        <Form.Item name="start_date" label="Uzsākšanas datums" rules={rules}>
                                            <DatePicker style={{ width: 200 }} format="YYYY-MM-DD" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} lg={8}>
                                        <Form.Item name="start_end_time" label="Nodarbības sākuma un beigu laiks" rules={rules}>
                                            <TimePicker.RangePicker
                                                format="HH:mm"
                                                style={{ width: 200 }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} lg={8}>
                                        <Form.Item name="planned_slots" label="Maksimālais studentu skaits" rules={rules}>
                                            <InputNumber style={{ width: 200 }} min={1} />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} lg={8}>
                                        <Form.Item
                                            name="extra_slots"
                                            label="Papildus studentu skaits"
                                        >
                                            <InputNumber style={{ width: 200 }} min={0} />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} lg={8}>
                                        <Form.Item
                                            name="extra_slots_price"
                                            label="Papildus studenta lekcijas cena"
                                        >
                                            <InputNumber style={{ width: 200 }} min={0} />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} lg={8}>
                                        <Form.Item name="show_web" label="Publicēt WEB" rules={rules}>
                                            <Select style={{ width: 200 }}>
                                                <Option value="yes">Jā</Option>
                                                <Option value="no">Nē</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} lg={8} >
                                        <Form.Item name="csdd_nr" label="CSDD numurs">
                                            <Input style={{ width: 200 }} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8}>
                                        <Form.Item name="comment" label="Komentārs">
                                            <Input.TextArea
                                                style={{ maxWidth: 300 }}
                                                placeholder="..."
                                                autoSize={{ maxRows: 3, minRows: 3 }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8}>
                                        <Form.Item valuePropName="checked" name="group_for_statistic_survey" label="Grupa statistikai">
                                            <Checkbox />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8}>
                                        <Form.Item valuePropName="checked" name="real_elearning" label="Einšteins online apmācība">
                                            <Checkbox />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8}>
                                        <Form.Item valuePropName="checked" name="fake_elearning" label="Grupa klātienes nodarbībām (individuāla grupa)">
                                            <Checkbox />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Card title="Reģistrācija">
                                            <Row gutter={24}>
                                                <Col span={8}>
                                                    <Row gutter={16}>
                                                        <Col span={8}>
                                                            <Form.Item name="registration_fee" label="Cena (ar PVN)">
                                                                <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Form.Item name="registration_discount" label="Atlaides cena">
                                                                <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Form.Item name="registration_vat" label="PVN %">
                                                                <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                            </Form.Item>
                                                        </Col>
                                                        {
                                                            selectedCities.map((cityId: any) => {
                                                                const data = initialData.cities.filter((city: any) => {
                                                                    // console.log(data)
                                                                    return city.id === cityId;
                                                                })[0];
                                                                console.log(data);
                                                                return (
                                                                    <Row key={cityId} gutter={16}>
                                                                        <h3 style={{ width: "100%", borderBottom: "1px solid #f0f0f0" }}>
                                                                            {classifiers.cities.filter((city: any) => {
                                                                                return city.id === cityId;
                                                                            })[0].value}
                                                                        </h3>
                                                                        <Col span={12}>
                                                                            <Form.Item initialValue={data?.pivot.registration_fee} name={`registration_fee-${cityId}`} label="Cena">
                                                                                <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Form.Item initialValue={data?.pivot.registration_discount} name={`registration_discount-${cityId}`} label="Atlaides cena">
                                                                                <InputNumber style={{ width: "100%" }} min={0} step={0.01} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                    <Row>
                                                        <Button
                                                            style={{ marginTop: 30 }}
                                                            onClick={() => {
                                                                setServiceHistory({
                                                                    service: 0,
                                                                    visible: true,
                                                                    group: initialData.id
                                                                })
                                                            }}
                                                        >
                                                            Reģistrācijas cenu vēsture
                                                        </Button>
                                                        <ServicesPriceLog
                                                            visible={serviceHistory.visible}
                                                            close={() => {
                                                                setServiceHistory((prev: any) => {
                                                                    return {
                                                                        ...prev,
                                                                        visible: false
                                                                    }
                                                                })
                                                            }}
                                                            service={serviceHistory.service}
                                                            groupID={serviceHistory.group}
                                                        />
                                                    </Row>
                                                </Col>
                                                <Col span={16}>
                                                    <Tabs style={{ marginLeft: 30 }} defaultActiveKey="1">
                                                        <TabPane tab="LV" key="1" forceRender>
                                                            <Form.Item name="comment_lv" label="Komentārs latviešu valodā">
                                                                <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                            </Form.Item>
                                                        </TabPane>
                                                        <TabPane tab="EN" key="2" forceRender>
                                                            <Form.Item name="comment_en" label="Komentārs angļu valodā">
                                                                <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                            </Form.Item>
                                                        </TabPane>
                                                        <TabPane tab="RU" key="3" forceRender>
                                                            <Form.Item name="comment_ru" label="Komentārs krievu valodā">
                                                                <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                            </Form.Item>
                                                        </TabPane>
                                                    </Tabs>
                                                    <Tabs style={{ marginLeft: 30 }} defaultActiveKey="1">
                                                        <TabPane tab="LV" key="1" forceRender>
                                                            <Form.Item name="description_lv" label="Apraksts latviešu valodā">
                                                                <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                            </Form.Item>
                                                        </TabPane>
                                                        <TabPane tab="EN" key="2" forceRender>
                                                            <Form.Item name="description_en" label="Apraksts angļu valodā">
                                                                <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                            </Form.Item>
                                                        </TabPane>
                                                        <TabPane tab="RU" key="3" forceRender>
                                                            <Form.Item name="description_ru" label="Apraksts krievu valodā">
                                                                <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                            </Form.Item>
                                                        </TabPane>
                                                    </Tabs>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <TeachersHandler
                                        teachers={initialData.lecturers}
                                        teachersList={classifiers.teachers}
                                        returnUpdated={(newList: any) => updateTeachers(newList)}
                                    />
                                </Row>
                            </Form>
                        </Card>
                        <CheckpointsForm getCheckpoints={setCheckpointsList} save={saveTrigger} cp={initialData.checkpoints} />
                        <Services cities={classifiers.cities} save={saveTrigger} getServices={setServicesList} services={initialData.services} />
                    </Row>
                </Layout>}
        </>
    )
})