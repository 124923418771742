import { Button, Calendar, Col, Layout, Row, Modal, Input, DatePicker, Tag, message } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";

const Slot = (props: any) => {
    const { item } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [holidayDate, setHolidayDate] = useState(moment(item.holiday_date).format("YYYY-MM-DD"));
    const [holidayName, setHolidayName] = useState(item.name);

    const nameHandler = (e: any) => {
        const text = e.target.value
        setHolidayName(text);
    }

    const dateHandler = (e: any) => {
        setHolidayDate(moment(e, "YYYY-MM-DD").format("YYYY-MM-DD"))
    }

    const saveHoliday = () => {
        if (modalVisible) {
            const data = {
                name: holidayName,
                holiday_date: holidayDate
            }
            http.patch(`/rest/slot-holidays/${item.id}`, null, { params: data }).then((response: any) => {
                props.reload();
            }).catch(() => {
                message.error("Kļūda");
            })
            setModalVisible(false)
        } else {
            setModalVisible(true);
        }
    }

    const deleteSlot = () => {
        http.delete(`/rest/slot-holidays/${item.id}`).then((response: any) => {
            props.reload();
        }).catch(() => {
            message.error("Kļūda");
        })
    }

    const closeModal = () => {
        // setHolidayName("");
        setModalVisible(false);
    }

    return (
        <>
            <Modal
                title={
                    <Row justify="space-between">
                        <p>Labot</p>

                    </Row>
                }
                visible={modalVisible}
                onOk={saveHoliday}
                onCancel={closeModal}
            >
                <Row>
                    <Col span={24}>
                        <p>Nosaukums:</p>
                        <Input value={holidayName} onChange={nameHandler} style={{ marginBottom: 15 }} />
                        <p>Datums</p>
                        <Row justify="space-between">
                            <DatePicker value={moment(holidayDate)} onChange={dateHandler} />
                            <Button type="text" danger onClick={deleteSlot} icon={<DeleteOutlined />}></Button>
                        </Row>
                    </Col>
                </Row>
            </Modal>
            <Tag
                onClick={saveHoliday}
                color="green"
                key={item.id}
            >
                {item.name}
            </Tag><br />
        </>
    )
}

export const HolidaysPage = (props: any) => {
    const [holidayDate, setHolidayDate] = useState(moment().format("YYYY-MM-DD"));
    const [holidays, setHolidays] = useState([] as any);
    const [holidayName, setHolidayName] = useState("");
    const [modalVisible, setModalVisible] = useState(false);

    const dateCellRender = (value: any) => {
        const slots = holidays.filter((slot: any) => {
            return moment(slot.holiday_date, "YYYY-MM-DD").isSame(value, "day");
        });
        if (slots.length === 0) {
            return;
        }

        return slots.map((item: any) => {
            return <Slot key={item.id} item={item} reload={fetch} />
        })
    }

    const monthCellRender = (value: any) => {
        const slots = holidays.filter((slot: any) => {
            return moment(slot.holiday_date, "YYYY-MM-DD").isSame(value, "month");
        });
        if (slots.length === 0) {
            return;
        }

        return slots.map((item: any) => {
            return <Slot key={item.id} item={item} reload={fetch} />
        })
    }

    const fetch = async () => {
        await http.get('/rest/slot-holidays').then((response: any) => {
            setHolidays(response.data)
        })

    }

    const selectDateHandler = (e: any) => {
        setHolidayDate(moment(e).format("YYYY-MM-DD"))
    }

    const nameHandler = (e: any) => {
        const text = e.target.value
        setHolidayName(text);
    }

    const dateHandler = (e: any) => {
        setHolidayDate(moment(e, "YYYY-MM-DD").format("YYYY-MM-DD"))
    }

    useEffect(() => {
        // console.log(moment(holidays.holiday_date, "YYYY-MM-DD"))
    }, [holidays])

    useEffect(() => {
        fetch();
    }, [])

    const addHoliday = () => {
        if (modalVisible) {
            const data = {
                name: holidayName,
                holiday_date: holidayDate
            }
            http.post('/rest/slot-holidays', null, { params: data }).then((response: any) => {
                fetch();
            }).catch(() => {
                message.error("Kļūda");
            })
            setModalVisible(false)
        } else {
            setModalVisible(true);
        }
    }

    // const editSlot = (item: any) => {
    //     setHolidayDate(item.holiday_date);
    //     setHolidayName(item.name);
    //     if (modalVisible) {
    //         const data = {
    //             name: holidayName,
    //             holiday_date: holidayDate
    //         }
    //         http.patch(`/rest/slot-holidays/${item.id}`, data).then((response: any) => {
    //             console.log(response)
    //         })
    //     }
    //     setModalVisible(true);
    // }

    const closeModal = () => {
        setHolidayName("");
        setModalVisible(false);
    }

    return (<Layout>
        <Modal
            title={"Pievienot svētku dienu"}
            visible={modalVisible}
            onOk={addHoliday}
            onCancel={closeModal}
        >
            <Row>
                <Col span={24}>
                    <p>Nosaukums:</p>
                    <Input value={holidayName} onChange={nameHandler} style={{ marginBottom: 15 }} />
                    <p>Datums</p>
                    <DatePicker value={moment(holidayDate)} onChange={dateHandler} />
                </Col>
            </Row>
        </Modal>
        <Row>
            <Col>
                <Button
                    style={{ margin: 10 }}
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={addHoliday}
                >
                    Pievienot
                </Button>
            </Col>
        </Row>
        <Calendar
            onSelect={selectDateHandler}
            dateCellRender={dateCellRender}
            monthCellRender={monthCellRender}
        />
    </Layout>
    )
}