import React from "react";
import { Card, Col, Layout, PageHeader, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { ReminderTable } from "./ReminderTable";
import { useHistory } from "react-router";

export const Reminders = (props: any) => {
    const history = useHistory();

    return (
        <Layout>
            <PageHeader
                style={{ backgroundColor: "#FFFFFF" }}
                title={"Atgādinājumi"}
                onBack={history.goBack}
            />
            <Card style={{ width: "100%" }} className="crm-card">
                <Row>
                    <Col span={24}>
                        <Row justify="center">
                            <ReminderTable />
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Layout>
    )
}