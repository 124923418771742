import { Button, DatePicker, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React from 'react';
import moment from 'moment';

export const getColumnSearchProps = (type: string = 'text') => {
    let inputNode: any;

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {
            return <div style={{ padding: 8 }}>
                {type.includes('calendar')
                    ? type.includes('range')
                        ? <DatePicker.RangePicker
                            ref={node => inputNode = node}
                            value={selectedKeys.length > 0 ? [moment(selectedKeys[0], 'YYYY-MM-DD'), moment(selectedKeys[1], 'YYYY-MM-DD')] : undefined}
                            onChange={value => setSelectedKeys(value === null ? [] : [moment(value[0]).format('YYYY-MM-DD'), moment(value[1]).format('YYYY-MM-DD')])}
                            style={{ width: 250, marginBottom: 8, display: 'block' }}
                        />
                        : <DatePicker
                            ref={node => inputNode = node}
                            placeholder={'Izvēlēties datumu'}
                            value={selectedKeys.length > 0 ? moment(selectedKeys[0], 'YYYY-MM-DD') : undefined}
                            onChange={value => setSelectedKeys(value === null ? [] : [moment(value).format('YYYY-MM-DD')])}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                    : <Input
                        ref={node => inputNode = node}
                        placeholder={`Meklēt`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => { confirm() }}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                }
                <Space>
                    <Button type="link" disabled={selectedKeys.length === 0} onClick={() => { clearFilters() }} size="small" style={{ width: 90 }}>
                        Atiestatīt
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => { confirm() }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        OK
                    </Button>
                </Space>
            </div>
        },
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible && type === 'text') {
                setTimeout(() => inputNode.select(), 100);
            }
        },
    }
};