import React, { useEffect, useState } from "react";
import { Button, Layout, Row, Space, Table } from "antd";
import { http } from "../../helpers/http";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";

const { Header, Content } = Layout;

function Activity(props: { status: "new" | "paid" }) {
  const isActive = props.status === "paid";
  const text = isActive ? "Aktīvs" : "Neaktīvs";
  const icon = <span style={{
    display: "inline-block",
    width: 6,
    height: 6,
    borderRadius: "50%",
    backgroundColor: isActive ? "#25c253" : "#c22525",
    marginBottom: 2
    }}></span>; 
  return <span style={{ fontWeight: 600 }}>{icon} {text}</span>
}

export const AddStudent = withRouter((props: any) => {
  const history = useHistory();
  const [classifiers, setClassifiers] = useState({} as any);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([] as any);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    total: 1
  })
  const id = useParams();

  useEffect(() => {
    loadClassifiers().then(() => {
      fetch()
    })
  }, [])

  async function loadClassifiers() {
    const languages = await http.get("/rest/languages");
    const cities = await http.get("/rest/cities");
    const categories = await http.get("/rest/categories");

    setClassifiers({
        languages: languages.data.data,
        cities: cities.data.data,
        categories: categories.data.data
      }
    );
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  function fetch(params: any = { pagination: { current: 1 } }) {
    const timeout = setTimeout(() => { setLoading(true) }, 150);
    http.get("/rest/students", { params: getParams(params) }).then(result => {
      const { data, meta } = result.data;
      clearTimeout(timeout);
      setData(data);
      setPagination({
        current: meta.current_page,
        pageSize: meta.per_page,
        total: meta.total
      })
      setLoading(false);
    });
  }

  function getParams(params: any) {
    return {
      page: params.pagination.current,
      sortField: params.sortField,
      sortOrder: params.sortOrder,
      filters: params.filters
    }
  }

  const columns = [
    {
      title: 'Statuss',
      width: 20,
      dataIndex: 'registration_status',
      key: 'registration_status',
      sorter: true,
      fixed: 'left',
      filters: [
        { text: 'Neaktīvs', value: 'new' },
        { text: 'Aktīvs', value: 'paid' },
      ],
      render: (status: any) => <Activity status={status} />
    },
    {
      title: 'Vārds',
      width: 30,
      sorter: true,
      dataIndex: 'first_name',
      key: 'first_name',
      fixed: 'left',
      ...getColumnSearchProps()
    },
    {
      title: 'Uzvārds',
      width: 30,
      sorter: true,
      dataIndex: 'last_name',
      key: 'last_name',
      fixed: 'left',
      ...getColumnSearchProps()
    },
    {
      title: 'Kategorijas',
      dataIndex: 'category_letters',
      key: 'category_letters',
      width: 25,
      sorter: true,
      filters: classifiers.categories != null ? classifiers.categories.map((cat: any) => ({
        text: cat.value, value: cat.id
      })) : [],
      // filters: [
      //   { text: 'B', value: 1 },
      //   { text: 'A', value: 2 },
      //   { text: 'C', value: 3 },
      // ],
      render: (category: any) => category
    },
    {
      title: 'Pilsēta',
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      width: 25,
      filters: classifiers.cities != null ? classifiers.cities.map((c: any) => ({
        text: c.value, value: c.id
      })) : [],
      // filters: [
      //   { text: 'Rīga', value: 1 },
      //   { text: 'Valmiera', value: 2 },
      // ],
      render: (city: any) => city?.value
    },
    {
      title: 'Valoda',
      dataIndex: 'language',
      key: 'language',
      sorter: true,
      width: 25,
      filters: classifiers.languages != null ? classifiers.languages.map((c: any) => ({
        text: c.value, value: c.id
      })) : [],
      // filters: [
      //   { text: 'Latviešu', value: 1 },
      //   { text: 'Krievu', value: 2 },
      // ],
      render: (language: any) => language?.value
    },
    {
      title: 'E-pasts',
      width: 45,
      sorter: true,
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      ...getColumnSearchProps()
    },
    {
      title: 'Tālrunis',
      width: 49,
      sorter: true,
      dataIndex: 'phone_number',
      key: 'phone_number',
      ellipsis: true,
      ...getColumnSearchProps()
    },
    {
      title: 'Braukšana',
      width: 25,
      // sorter: true,
      dataIndex: 'brauksana',
      key: 'brauksana',
      ellipsis: true,
    },
    {
      title: 'Depozīts',
      width: 25,
      // sorter: true,
      dataIndex: 'deposit',
      key: 'deposit',
      ellipsis: true,
    },
  ];

  const addStudent = (event: any) => {
    const value = {
      reservation_type: [props.match.params.reservation],
      student: [event],
    }
    console.log(event)
    http.post(`/rest/study-groups/add-student/${props.match.params.id}`, value).then((response) => {
      console.log(response)
    });
    history.goBack()
  }

  return (
    <Layout>
      <Header style={{ padding: "0 16px", background: "rgb(240, 242, 245)", borderBottom: "2px solid #e7e7e7",flexWrap: 'nowrap' }}>
        <Space>
          <Button type="primary" onClick={() => history.goBack()}>Atpakaļ</Button>
        </Space>
      </Header>
        {/* <Button onClick={()=>console.log(data)}>!</Button> */}
      <Content>
        <Table
          rowKey={record => record.id}
          columns={columns as any}
          rowClassName="clickable-row"
          pagination={{
            showSizeChanger: false,
            size: 'small',
            showTotal: (total) => <div>Kopā {total} studenti</div>,
            position: ['bottomCenter'],
            pageSizeOptions: ['15'],
            defaultPageSize: 15,
            ...pagination
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                addStudent(record);
              }, // click row
              onDoubleClick: event => {}, // double click row
              onContextMenu: event => {}, // right button click row
              onMouseEnter: event => {}, // mouse enter row
              onMouseLeave: event => {}, // mouse leave row
            };
          }}
          dataSource={data}
          loading={loading}
          scroll={{ x: 1600, y: props.windowHeight - 185 }}
          onChange={handleTableChange}
        />
      </Content>
    </Layout>
  );
});