import { Card, Layout, PageHeader, Row, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../helpers/http";
import { render } from '@testing-library/react';
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { QuestionAnswerCard } from "./components/QuestionAnswerCard";

export const StudentQuestionsView = () => {
    const history = useHistory();
    const [questions, setQuestions] = useState([] as any);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 1,
        pageSize: 15,
        showSizeChanger: false,
    });
    const [loading, setLoading] = useState(false);

    const fetchStudentQuestions = (params: {} = {}) => {
        setLoading(true);
        http.get(`/rest/elearning-chapter-questions`, { params }).then((res) => {
            setQuestions(res.data.data);
            setPagination((prev: any) => {
                return {
                    ...prev,
                    current: Number(res.data.page),
                    total: res.data.total
                }
            });
            console.log(res)
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(pagination, sorter, filters)
        fetchStudentQuestions({
            page: pagination.current,
            filters,
            order_by: sorter.order ? sorter.field : null,
            order_desc: sorter.order === "ascend"
                ? "asc"
                : sorter.order === "descend"
                    ? "desc"
                    : null
        })
    }

    useEffect(() => {
        fetchStudentQuestions();
    }, []);

    const columns = [
        {
            title: "Status",
            dataIndex: "answer",
            key: "answered",
            render: (answer: any, record: any) => {
                if (answer === null) {
                    return <Tag color="red">Nav atbildēts</Tag>
                } else {
                    return <Tag color="green">Atbildēts</Tag>
                }
            },
            filters: [
                {
                    text: "Atbildēts",
                    value: "1",
                },
                {
                    text: "Nav atbildēts",
                    value: "0",
                }
            ],
        },
        {
            title: "Students",
            dataIndex: "student",
            key: "student",
            ...getColumnSearchProps(),
        },
        {
            title: "Epasta adrese",
            dataIndex: "email",
            key: "st.email",
            ...getColumnSearchProps(),
        },
        {
            title: "Tālr. Nr.",
            dataIndex: "phone_number",
            key: "st.phone_number",
            ...getColumnSearchProps(),
        },
        {
            title: "Modulis",
            dataIndex: "module",
            key: "em.title_lv",
        },
        {
            title: "Tēma",
            dataIndex: "chapter",
            key: "ec.title_lv",
        },
        {
            title: "Datums",
            dataIndex: "created_at",
            key: "eisq.created_at",
            ...getColumnSearchProps("calendar"),
        },

    ];

    return <Layout>
        <PageHeader
            title="Studentu uzdotie jautājumi"
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF" }}
        />
        <Card style={{ margin: 20 }}>
            <Table
                rowKey={(record) => record.id}
                dataSource={questions}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                loading={loading}
                expandable={{
                    expandedRowRender: (record: any) => {
                        return <div>
                            <b>Jautājums:</b>
                            <p>{record.question}</p>
                            <QuestionAnswerCard
                                question={record}
                                setAnswer={(answer: string) => {
                                    setQuestions((prev: any) => {
                                        return prev.map((question: any) => {
                                            if (question.id === record.id) {
                                                return {
                                                    ...question,
                                                    answer
                                                }
                                            }
                                            return question;
                                        })
                                    })
                                }}
                            />
                        </div>
                    }
                }}
            />
        </Card>
    </Layout>
}