import { Button } from 'antd';
import React, { useEffect, useState } from 'react'
import Resumable from "resumablejs";

const resumable = new Resumable({
    headers: {
        'Accept': 'application/json',
    },
    testChunks: false,
    fileParameterName: "video",
    simultaneousUploads: 1,
    fileType: ["mp4"]
}) as Resumable;

type OnlyVideoUploaderProps = {
    uploadUrl: string;
    uploadProps: any;
    setProgress: any;
    uniqueId: string;
    returnResponseOnSuccess: (response: any) => void;
    change: boolean;
}

function OnlyVideoUploader(props: OnlyVideoUploaderProps) {
    const { uploadUrl, uploadProps, setProgress, uniqueId } = props;
    const [resFile, setResFile] = useState({} as any);
    const [token, setToken] = useState(null as null | string);

    const initResumable = () => {
        resumable.events = []
        resumable.opts.target = uploadUrl;
        resumable.opts.query = {
            ...resumable.opts.query,
            ...uploadProps
        }
        resumable.opts.headers = {
            "Authorization": `Bearer ${token}`,
        }
        const player = document.getElementById(`resumable-instructions-video-input-${uniqueId}`)
        if (player === null) return;
        resumable.assignBrowse(player, false)
        resumable.on('fileAdded', (file: any) => {
            setResFile(file)
        })
        resumable.on('complete', (file: any) => {

            console.log("complete", file)
        })
        resumable.on('progress', (file: any, ratio: any) => {
            // console.log((resumable.progress() * 100).toFixed(0))
            const progr = (resumable.progress() * 100)
            setProgress(progr)
        })
        resumable.on('fileSuccess', (file: any, event: any) => {
            props.returnResponseOnSuccess(JSON.parse(event))
        })
    }

    const resumableFileUpload = async () => {
        resumable.upload();
    }

    useEffect(() => {
        if (token) {
            initResumable()
        } else {
            const token = localStorage.getItem('bearer_token');
            setToken(token)
        }
    }, [token])

    return (
        <div>
            <span
                id={`resumable-instructions-video-input-${uniqueId}`}
                style={{
                    cursor: "pointer",
                    padding: "5px 10px",
                    border: "1px solid #E0E0E0",
                }}
            >
                {props.change ? "Mainīt video" : "Pievienot video"}
            </span>
            {
                resFile?.fileName
                    ? <div>
                        <span>{resFile?.fileName}</span>
                        <Button onClick={resumableFileUpload}>Upload</Button>
                    </div>
                    : <></>
            }
        </div>

    )
}

export default OnlyVideoUploader