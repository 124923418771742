import { Button, Col, Input, message, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { AxiosResponse } from 'axios';
import { http } from "../../helpers/http";
import { set } from 'lodash';

type StudentDiscounts = {
    [key: string]: number | undefined;
    bonus_school_driving: number | undefined;
    bonus_external_driving: number | undefined;
    bonus_school_driving_exam: number | undefined;
    bonus_external_driving_exam: number | undefined;
    bonus_registration: number | undefined;
    bonus_books: number | undefined;
    bonus_theory: number | undefined;
    bonus_pmp: number | undefined;
    bonus_school_exam: number | undefined;
    bonus_extra_theory: number | undefined;
    bonus_full_training: number | undefined;
    bonus_omniva: number | undefined;
}

type DiscountsSettingsProps = {
    studentID: number;
}

export const DiscountsSettings = ({ ...props }: DiscountsSettingsProps) => {
    const [discounts, setDiscounts] = useState<StudentDiscounts>({
        bonus_school_driving: 0,
        bonus_external_driving: 0,
        bonus_school_driving_exam: 0,
        bonus_external_driving_exam: 0,
        bonus_registration: 0,
        bonus_books: 0,
        bonus_theory: 0,
        bonus_pmp: 0,
        bonus_school_exam: 0,
        bonus_extra_theory: 0,
        bonus_full_training: 0,
        bonus_omniva: 0,
    });
    const [loading, setLoading] = useState(true);

    const getDiscounts = () => {
        setLoading(true);
        http.get(`rest/get-bonus-settings/${props.studentID}`).then((res: AxiosResponse) => {
            setLoading(false);
            console.log(res)
            if (typeof res.data === "string") return;
            const { data } = res.data;
            setDiscounts((prev: StudentDiscounts): StudentDiscounts => {
                const copy = { ...prev };
                Object.keys(copy).forEach(key => {
                    copy[key] = data[key]
                })
                return copy
            })
        })
    }

    const setDiscountSettings = () => {
        http.post(`rest/change-bonus-settings/${props.studentID}`, { settings: discounts }).then((res: AxiosResponse) => {
            console.log(res)
            message.success(`Saglabāts!`);
        })
    };

    const keys = {
        bonus_school_driving: "(Bonus) Braukšanas nodarbība skolas instruktoriem",
        bonus_external_driving: "(Bonus) Braukšanas nodarbība instruktoriem, kas nav skolas",
        bonus_school_driving_exam: "(Bonus) Braukšanas eksāmens skolas instruktoriem",
        bonus_external_driving_exam: "(Bonus) Braukšanas eksāmens instruktoriem, kas nav skolas",
        bonus_registration: "(Bonus) Reģistrācija mācību grupā",
        bonus_books: "(Bonus) Grāmatas iegādei",
        bonus_theory: "(Bonus) Teorijas pakalpojums",
        bonus_pmp: "(Bonus) PMP pakalpojums",
        bonus_school_exam: "(Bonus) Skolas teorijas eksāmens",
        bonus_extra_theory: "(Bonus) Papildus teorijas nodarbība",
        bonus_full_training: "(Bonus) Pilna apmācība",
        bonus_omniva: "(Bonus) Omniva",
    } as any

    useEffect(() => {
        if (props.studentID !== undefined) {
            getDiscounts();
        }
    }, [props.studentID]);

    return loading
        ? <Skeleton />
        : <Row gutter={[20, 20]}>
            {
                Object.keys(keys).map(key => {
                    return <Col key={key} style={{ marginBottom: 10, width: 200, display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                        <p>{keys[key]}</p>
                        <Input
                            value={discounts[key] ?? undefined}
                            name={key}
                            type="number"
                            min={0}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                e.persist();
                                setDiscounts((prev: StudentDiscounts): StudentDiscounts => {
                                    return {
                                        ...prev,
                                        [e.target.name]: e !== undefined ? Number(e.target.value) : undefined
                                    }
                                })
                            }}
                        />
                    </Col>
                })
            }
            <Col style={{ marginBottom: 10, width: 200, display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                <Button
                    type="primary"
                    onClick={() => setDiscountSettings()}
                >
                    Saglabāt
                </Button>
            </Col>
        </Row>
}