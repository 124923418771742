import { Button, Card, Layout, message, Modal, PageHeader, Row, Table, Tabs } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { http } from "../../helpers/http";
import { AxiosResponse } from "axios";

export const PMPOverview = () => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState("paid_not_in_csdd");
    const [data, setData] = useState([] as any);
    const [tableState, setTableState] = useState({ pagination: { current: 1 }, filters: {}, sorter: {} } as any);
    const [loading, setLoading] = useState(false);
    const [cities, setCities] = useState([] as any);

    const getCities = () => {
        http.get("rest/cities").then((res: AxiosResponse) => {
            setCities(res.data.data);
        }).catch(() => {
            setCities([]);
        })
    }

    const getData = async (params: any = { pagination: { current: 1 }, tab: activeTab }) => {
        setLoading(true);
        setData([]);
        http.get(`rest/pmp-paid-finder`, {
            params: {
                tab: params.tab,
                page: params.pagination.current,
                filters: params.filters,
            }
        }).then((res: AxiosResponse) => {
            setLoading(false);
            setData(res.data.data);
            setTableState({
                pagination: {
                    current: res.data.page,
                    total: res.data.total,
                },
                filters: params.filters,
                tab: params.tab
            })
        }).catch(() => {
            setLoading(false);
            setData([]);
        })
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setTableState({ pagination, filters, sorter });
        getData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters,
            tab: activeTab
        });
    };

    const removeStudent = (studentId: any, groupId: any) => {
        Modal.confirm({
            title: "Vai tiešām dzēst studentu no grupas?",
            onOk: () => {
                setLoading(true);
                http.post(`/rest/remove-student-from-medical-group/${groupId}/${studentId}`).then((response: any) => {
                    console.log(response)
                    message.success("Students dzēsts no grupas");
                    setLoading(false);
                    getData(tableState);
                }).catch(() => {
                    message.error("Kļūda");
                    setLoading(false);
                });
            }
        })
    }

    const columns = [
        //student, email, phone, city, category, group
        {
            title: "Students",
            dataIndex: "student",
            key: "student",
            ...getColumnSearchProps(),
            render: (student: any, record: any) => {
                return record.medical_group_id && activeTab === "attached_to_group_unpaid"
                    ? <Row style={{ flexWrap: "nowrap" }} align="middle">
                        <Button
                            type="text"
                            danger
                            style={{ fontSize: 16 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                removeStudent(record.student_id, record.medical_group_id)
                            }}
                            icon={<DeleteOutlined />}
                        ></Button>
                        <p style={{ marginBottom: 0 }}>{student}</p>
                    </Row>
                    : student
            }
        },
        {
            title: "E-pasts",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps(),
        },
        {
            title: "Tālruņa nr.",
            dataIndex: "phone_number",
            key: "phone_number",
            ...getColumnSearchProps(),
        },
        {
            title: "Pilsēta",
            dataIndex: "city",
            key: "city",
            filters: cities?.map((city: any) => ({ text: city.value, value: city.value })),
        },
        {
            title: "Kategorija",
            dataIndex: "category",
            key: "category",
        }
    ] as any;

    const groupColumn = [
        {
            title: "Grupa",
            dataIndex: "group",
            key: "group",
            ...getColumnSearchProps(),
            render: (group: any) => {
                return group ? group : "Nav grupas"
            }
        },
        {
            title: "Online",
            dataIndex: "online_date",
            key: "online_date",
            ...getColumnSearchProps("calendar"),
            render: (group_date: any, record: any) => {
                return group_date
                    ? <div>
                        <p style={{ marginBottom: 2 }}>{group_date}</p>
                        <p style={{ fontSize: 10 }}>{record.online_from} - {record.online_to}</p>
                    </div>
                    : ""
            }
        },
        {
            title: "Klātienē",
            dataIndex: "medical_date",
            key: "medical_date",
            ...getColumnSearchProps("calendar"),
            render: (group_date: any, record: any) => {
                return group_date
                    ? <div>
                        <p style={{ marginBottom: 2 }}>{group_date}</p>
                        <p style={{ fontSize: 10 }}>{record.medical_from} - {record.medical_to}</p>
                    </div>
                    : ""
            }
        }
    ]

    useEffect(() => {
        getData();
    }, [activeTab])

    useEffect(() => {
        getCities();
    }, [])

    return <Layout>
        <PageHeader
            title="PMP Pārskats"
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF", width: "100%" }}
        />
        <Card style={{ width: "100%", margin: 20 }}>
            <Tabs
                defaultActiveKey={activeTab}
                onChange={(key) => {
                    if (loading) return;
                    setActiveTab(key)
                }}
            >
                <Tabs.TabPane tab="Ir samaksāts, nav reģistrēts CSDD" key="paid_not_in_csdd" disabled={loading}>
                    <Table
                        rowKey={(record: any) => record.student_id}
                        dataSource={data}
                        columns={[...columns, ...groupColumn]}
                        onChange={handleTableChange}
                        loading={loading}
                        pagination={{
                            ...tableState.pagination,
                            pageSize: 15,
                            showSizeChanger: false,
                            position: ['bottomCenter'],
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}groups/student-opening/${record.student_id}`, "_blank");
                                }
                            }
                        }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Ir grupā, nav samaksājuši" key="attached_to_group_unpaid" disabled={loading} >
                    <Table
                        rowKey={(record: any) => record.student_id}
                        dataSource={data}
                        columns={[...columns, ...groupColumn]}
                        onChange={handleTableChange}
                        loading={loading}
                        pagination={{
                            ...tableState.pagination,
                            pageSize: 15,
                            showSizeChanger: false,
                            position: ['bottomCenter'],
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}groups/student-opening/${record.student_id}`, "_blank");
                                }
                            }
                        }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Nav grupā, ir samaksājuši" key="paid_without_group" disabled={loading} >
                    <Table
                        rowKey={(record: any) => record.student_id}
                        dataSource={data}
                        columns={[...columns]}
                        onChange={handleTableChange}
                        loading={loading}
                        pagination={{
                            ...tableState.pagination,
                            pageSize: 15,
                            showSizeChanger: false,
                            position: ['bottomCenter'],
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}groups/student-opening/${record.student_id}`, "_blank");
                                }
                            }
                        }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Nav PMP, nav pieteikušies" key="pmpless" disabled={loading}>
                    <Table
                        rowKey={(record: any) => record.student_id}
                        dataSource={data}
                        columns={[...columns]}
                        onChange={handleTableChange}
                        loading={loading}
                        pagination={{
                            ...tableState.pagination,
                            pageSize: 15,
                            showSizeChanger: false,
                            position: ['bottomCenter'],
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}groups/student-opening/${record.student_id}`, "_blank");
                                }
                            }
                        }}
                    />
                </Tabs.TabPane>
            </Tabs>
        </Card>
    </Layout>
};