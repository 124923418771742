import { Button, Modal, Table, message } from 'antd';
import { ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { http } from '../../helpers/http';
import { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';

export const GroupQuickInfoModal = (props: any) => {
    const { visible, setVisible, module } = props;
    const [loading, setLoading] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(null as null | string);
    const [students, setStudents] = useState(null as any);

    const resetModal = () => {
        setVisible(undefined);
    }

    // const getModuleNames = () => {
    //     http.get(`/rest/get-foreign-counter/${group.id}`).then((res: AxiosResponse) => {
    //         console.log(res)
    //         setModulesNames(res.data.data)
    //     })
    // }

    const getStudentInfo = (csdd = false) => {
        setLoading(true);
        http.get(`/rest/study-group-extra-stats/${module.study_group_id}/${module.module_counter}`, {
            params: {
                look_csdd: csdd ? 1 : undefined
            }
        }).then((res: AxiosResponse) => {
            console.log(res)
            setLastUpdated(res.data.last_update);
            setStudents(res.data.data);
            setLoading(false);
        }).catch(() => {
            message.error("Kļūda!");
            setLoading(false);
        })
    }

    // const setActiveNameFilterKey = (activeName: any) => {
    //     let newFilters = JSON.parse(JSON.stringify(modulesNames))
    //     const module = modulesNames.find((el: any) => el.name === activeName);
    //     if (activeName.length === 0) {
    //         delete newFilters.name
    //     } else {
    //         newFilters = {
    //             ...newFilters,
    //             name: activeName
    //         }
    //     }
    //     setModulesNames((prev: any) => {
    //         return modulesNames.map((item: any) => {
    //             if (item.name === activeName) {
    //                 return {
    //                     ...item,
    //                     active: 1
    //                 }
    //             } else {
    //                 return {
    //                     ...item,
    //                     active: 0
    //                 }
    //             }
    //         })
    //     })
    //     getStudentInfo(module.lesson_counter);
    // }

    const columns = [
        {
            title: "Students",
            dataIndex: "student"
        },
        {
            title: "Personas kods",
            dataIndex: "personal_identity_number"
        },
    ] as any;

    useEffect(() => {
        if (module !== undefined) {
            getStudentInfo();
        }
    }, [module])

    useEffect(() => {
        console.log(students)
    }, [students])

    return <Modal
        visible={visible}
        onCancel={resetModal}
        width={800}
    >
        <h2>
            CSDD nr: <Link to={`/groups/edit/${module.study_group_id}`}>{module.csdd_nr}</Link>, Pēdējo reizi atjaunots: {lastUpdated ? lastUpdated : "Nav importēti dati"}
            <Button
                type="text"
                onClick={() => getStudentInfo(true)}
                style={{ height: "unset", width: "unset", padding: "5px 5px 0" }}
                icon={<ReloadOutlined style={{ fontSize: 24 }} spin={loading} />}
            />
        </h2>
        {/* {
            modulesNames.map((lesson: any) => {
                return <Button
                    style={{ marginRight: 8, marginBottom: 4 }}
                    type={lesson.active ? "primary" : "default"}
                    onClick={() => {
                        // this.handleForeignTableChange({ pagination: { current: 1 } }, { name: lesson.name }, {});
                        setActiveNameFilterKey(lesson.name);
                    }}
                >
                    <span>{lesson.name}</span>
                    {lesson.problem && <ExclamationCircleOutlined style={{ color: "red", fontSize: 16, marginLeft: 5 }} />}
                </Button>
            })
        } */}
        <h2>Ir sistēmā, nav CSDD</h2>
        <Table
            columns={columns}
            dataSource={students?.filter((student: any) => !!!student.csdd_found && !!student.system_found)}
        />
        <h2>Ir CSDD, nav sistēmā</h2>
        <Table
            columns={columns}
            dataSource={students?.filter((student: any) => !!student.csdd_found && !!!student.system_found)}
        />
    </Modal>
}