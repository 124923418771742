import { Button, Card, Col, Input, Layout, Row, Select, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosError, AxiosResponse } from "axios";

export const ExchangeCarsLayout = () => {
    const [exchangeCars, setExchangeCars] = useState([] as any);
    const [car, setCar] = useState({} as any);
    const [cities, setCities] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);

    const getCities = () => {
        http.get(`/rest/cities`).then((res: AxiosResponse) => {
            setCities(res.data.data);
        })
    }

    const getExchangeCars = () => {
        http.get(`/rest/exchange-cars`, {
            params: {
                no_paginate: 1
            }
        }).then((res: AxiosResponse) => {
            setExchangeCars(res.data.data)
        }).catch((err: AxiosError) => {
        })
    };

    const saveExchangeCar = () => {
        setLoading(true);
        http.post(`/rest/exchange-cars`, { ...car }).then((res: AxiosResponse) => {
            getExchangeCars();
            setCar({});
            setLoading(false);
            message.success("Auto veiksmīgi pievienots");
        }).catch(() => {
            setLoading(false);
            message.error("Kļūda!");
        })
    }

    const columns = [
        {
            title: "Marka",
            dataIndex: "vehicle",
            key: "vehicle"
        },
        {
            title: "VRN",
            dataIndex: "licence_plate",
            key: "licence_plate"
        },
        {
            title: "Pārnesumkārba",
            dataIndex: "transmission",
            key: "transmission",
            render: (transmission: any) => {
                return transmission === "auto"
                    ? "Automātiskā"
                    : "Manuālā"
            }
        },
        {
            title: "Pilsēta",
            dataIndex: "city",
            key: "city"
        },
        {
            title: "Aktīva",
            dataIndex: "active",
            key: "active",
            render: (active: 1 | 0) => {
                return !!active ? "Ir" : "Nav"
            }
        },
        {
            title: "Aizņemta",
            dataIndex: "free",
            key: "free",
            render: (free: 1 | 0) => {
                return !!!free ? "Ir" : "Nav"
            }
        },
    ]

    useEffect(() => {
        getExchangeCars();
        getCities();
    }, []);

    useEffect(() => {
        if (car.hasOwnProperty("id")) {
            setEdit(true)
        } else {
            setEdit(false)
        }
    }, [car])

    return <Layout>
        <Card
            title="Maiņas auto uzstādījumi"
        >
            <Card
                title="Pievienot maiņas auto"
                style={{ marginBottom: 30 }}
            >
                <Row gutter={[20, 20]} align="bottom">
                    <Col>
                        <p>Marka</p>
                        <Input
                            style={{ width: 200 }}
                            value={car.vehicle}
                            onChange={e => {
                                e.persist();
                                setCar((prev: any) => {
                                    return {
                                        ...prev,
                                        vehicle: e.target.value
                                    }
                                })
                            }}
                        />
                    </Col>
                    <Col>
                        <p>VRN</p>
                        <Input
                            style={{ width: 120 }}
                            value={car.licence_plate}
                            onChange={e => {
                                e.persist();
                                setCar((prev: any) => {
                                    return {
                                        ...prev,
                                        licence_plate: e.target.value
                                    }
                                })
                            }}
                        />
                    </Col>
                    <Col>
                        <p>Pārnesumkārba</p>
                        <Select
                            style={{ width: 120 }}
                            value={car.transmission}
                            onChange={e => {
                                setCar((prev: any) => {
                                    return {
                                        ...prev,
                                        transmission: e
                                    }
                                })
                            }}
                        >
                            <Select.Option value="manual">Manuālā</Select.Option>
                            <Select.Option value="auto">Automātiskā</Select.Option>
                        </Select>
                    </Col>
                    <Col>
                        <p>Pilsēta</p>
                        <Select
                            style={{ width: 120 }}
                            value={car.city_id}
                            onChange={e => {
                                setCar((prev: any) => {
                                    return {
                                        ...prev,
                                        city_id: e
                                    }
                                })
                            }}
                        >
                            {
                                cities.map((city: any) => {
                                    return <Select.Option value={city.id} key={city.id}>
                                        {city.value}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Col>
                    {
                        edit
                        && <Col>
                            <p>Aktīva</p>
                            <Select
                                style={{ width: 120 }}
                                value={car.active}
                                onChange={e => {
                                    setCar((prev: any) => {
                                        return {
                                            ...prev,
                                            active: e
                                        }
                                    })
                                }}
                            >
                                <Select.Option value={1}>Ir</Select.Option>
                                <Select.Option value={0}>Nav</Select.Option>
                            </Select>
                        </Col>
                    }
                    <Col>
                        {
                            edit
                                ? <div>
                                    <Button
                                        type="primary"
                                        onClick={saveExchangeCar}
                                        loading={loading}
                                        style={{ marginRight: 10 }}
                                    >
                                        Saglabāt
                                    </Button>
                                    <Button
                                        onClick={() => setCar({})}
                                        loading={loading}
                                    >
                                        Atcelt
                                    </Button>
                                </div>
                                : <Button
                                    type="primary"
                                    onClick={saveExchangeCar}
                                    loading={loading}
                                    disabled={
                                        !car.hasOwnProperty("city_id")
                                        || !car.hasOwnProperty("transmission")
                                        || !car.hasOwnProperty("vehicle")
                                        || !car.hasOwnProperty("licence_plate")
                                        || car?.vehicle?.length === 0
                                        || car?.licence_plate?.length === 0
                                    }
                                >
                                    Pievienot
                                </Button>
                        }
                    </Col>
                </Row>
            </Card>
            <Table
                columns={columns}
                dataSource={exchangeCars}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            setCar(record);
                        }
                    };
                }}
            />
        </Card>
    </Layout>
}