import { Tabs, Card, Space, Button, Progress, Skeleton, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { random, set } from 'lodash';
import ReactPlayer from "react-player";
import Resumable from "resumablejs";
import { http } from "../../../helpers/http";
import { ImgUploader } from "../components/ImgUploader";

const resumable = new Resumable({
    target: process.env.REACT_APP_API_URL + "admin-api/rest/upload-instruction-video",
    query: {
        instruction_id: 1,
        language_id: 1,
        video_quality: "medium",
        title: "Random test title",
        token: random(1, 999999),
        token2: random(1, 999999)
    },
    headers: {
        "Authorization": `Bearer ${localStorage.getItem('bearer_token')}`,
        'Accept': 'application/json',
    },
    testChunks: false,
    fileParameterName: "video",
    simultaneousUploads: 1,
    fileType: ["mp4"]
}) as any;

export const VideoUploader = (props: any) => {
    const { url, uploadUrl, uploadProps, token, checkUrl, deleteURL } = props;

    const [loading, setLoading] = useState(true);
    const [videoURL, setVideoURL] = useState('');
    const [langTabKey, setLangTabKey] = useState("1");
    const [qualityTabKey, setQualityTabKey] = useState("1");
    const [resFile, setResFile] = useState({} as any);
    const [languages, setLanguages] = useState([] as any);
    const [videoQualities, setVideoQualities] = useState([] as any);
    const [progress, setProgress] = useState(0 as any);
    const [linkIsReady, setLinkIsReady] = useState({} as any);
    const [playerIsReady, setPlayerIsReady] = useState(false);
    const [hasVideo, setHasVideo] = useState(false);

    const initResumable = (language: any = 'lv', quality: any = 'medium') => {
        resumable.events = []
        resumable.opts.target = uploadUrl;
        resumable.opts.query = {
            ...resumable.opts.query,
            ...uploadProps
        }
        const player = document.getElementById(`resumable-instructions-video-input-${language}-${quality}`)
        console.log(player)
        if (player === null) return;
        resumable.assignBrowse(player, false)
        resumable.on('fileAdded', (file: any) => {
            console.log(file, resumable)
            setResFile((old: any) => {
                return {
                    ...old,
                    [language]: {
                        ...old[language],
                        [quality]: file.file
                    }
                }
            })
        })
        resumable.on('complete', () => {
            let qualitiesObj = {} as any;
            videoQualities.forEach((quality: any) => [
                qualitiesObj[quality] = null
            ]);
            setResFile({
                lv: qualitiesObj,
                ru: qualitiesObj,
                en: qualitiesObj,
            });
            checkVideo(langTabKey, qualityTabKey);
            console.log("complete")
        })
        resumable.on('progress', (file: any, ratio: any) => {
            // console.log((resumable.progress() * 100).toFixed(0))
            const progr = (resumable.progress() * 100)
            setProgress(progr)
        })
    }

    const checkVideo = async (lang_id: any, quality: any) => {
        console.log("lang_id from check video", lang_id)
        const qualityValue = videoQualities[quality]
        await http.post(checkUrl.url, { ...checkUrl.param, language_id: lang_id, video_quality: qualityValue }).then((response: any) => {
            setHasVideo(response.data.status);
            console.log("response from check video", response.data)
            if (response.data.status) {
                console.log("setting URL from check video", url)
                setVideoURL(`${url}&language_id=${lang_id}&video_quality=${qualityValue}`);
                deleteNotUploadedFile();
            } else {
                setVideoURL('');
            }
        })
    }

    const fetchLanguages = () => {
        http.get(`/rest/languages`).then((response: any) => {
            setLanguages(response.data.data);
        });
    };

    const fetchQuality = () => {
        http.get(`rest/get-video-qualities`).then((res: any) => {
            console.log(res)
            setVideoQualities(res.data.data);
            let qualitiesObjBool = {} as any;
            let qualitiesObj = {} as any;
            res.data.data.forEach((quality: any) => [
                qualitiesObj[quality] = null
            ]);
            res.data.data.forEach((quality: any) => [
                qualitiesObjBool[quality] = false
            ]);
            setLinkIsReady({
                lv: qualitiesObjBool,
                en: qualitiesObjBool,
                ru: qualitiesObjBool
            });
            setResFile({
                lv: qualitiesObj,
                ru: qualitiesObj,
                en: qualitiesObj,
            });
        })
    }

    const resumableFileUpload = (language: any, quality: any) => {
        resumable.opts.query.language_id = language;
        resumable.opts.query.video_quality = quality;
        resumable.upload();
    }

    const deleteNotUploadedFile = () => {
        resumable.cancel();
        let qualitiesObj = {} as any;
        videoQualities.forEach((quality: any) => [
            qualitiesObj[quality] = null
        ]);
        setResFile({
            lv: qualitiesObj,
            ru: qualitiesObj,
            en: qualitiesObj,
        });
    }

    const deleteUploadedFile = async (lang_id: any, quality: any) => {
        await http.post(deleteURL.url, { ...deleteURL.param, language_id: lang_id, video_quality: quality }).then((response: any) => {
            checkVideo(langTabKey, qualityTabKey);
        })
    }

    useEffect(() => {
        fetchLanguages();
        fetchQuality();
        return () => {
            const video = document.getElementsByTagName('video')
            Array.from(video).forEach((item: any) => {
                item.src = ""
            })
        }
    }, []);

    useEffect(() => {
        console.log(linkIsReady)
        if (Object.keys(linkIsReady).length > 0 && Object.keys(resFile).length > 0) {
            setLoading(false);
        }
    }, [linkIsReady, resFile]);

    useEffect(() => {
        console.log(props.data)
    }, [props.data])

    useEffect(() => {
        if (languages.length > 0 && videoQualities.length > 0) {
            // setLoading(false);
            checkVideo(1, 1);
        }
    }, [languages, videoQualities])

    useEffect(() => {
        if (!loading) {
            initResumable();
        }
    }, [loading])

    useEffect(() => {
        setPlayerIsReady(false)
        if (Object.keys(languages).length > 0 && Object.keys(languages).length > 0) {
            const lang = languages.find((lang: any) => lang.id === Number(langTabKey)).iso
            const quality = videoQualities[Number(qualityTabKey)]
            initResumable(lang, quality)
        }
    }, [langTabKey, qualityTabKey])

    useEffect(() => {
        console.log("videoURL: ", videoURL)
        if (languages.length > 0) {
            const langIso = languages.find((el: any) => Number(el.id) === Number(langTabKey)).iso
            const quality = videoQualities[Number(qualityTabKey)]
            if (videoURL.length > 0) {
                setLinkIsReady((prev: any) => {
                    console.log("This executes?", langIso, quality)
                    return {
                        ...prev,
                        [langIso]: {
                            ...prev[langIso],
                            [quality]: true
                        }
                    }
                })
            } else {
                setLinkIsReady((prev: any) => {
                    return {
                        ...prev,
                        [langIso]: {
                            ...prev[langIso],
                            [quality]: false
                        }
                    }
                })
            }
        }
    }, [videoURL]);

    return loading ? <Skeleton />
        : <Tabs
            defaultActiveKey="1"
            onChange={(key: any) => {
                deleteNotUploadedFile();
                checkVideo(key, qualityTabKey);
                setLangTabKey(key);
            }}
        >
            {
                languages?.map((lang: any) => {
                    return <Tabs.TabPane key={lang.id} tab={lang.iso?.toUpperCase()}>
                        <Card>
                            <p><b>Video sākuma attēls {lang.iso}</b></p>
                            <ImgUploader
                                img={props.data?.[`thumb_${lang.iso}` as any]}
                                uniqueId={`_${lang.iso}`}
                                setImg={(img: any) => {
                                    props.setData((prev: any) => {
                                        return {
                                            ...prev,
                                            [`thumb_${lang.iso}` as any]: img === undefined ? null : img
                                        }
                                    });
                                }}
                            />
                            <Button
                                type="primary"
                                style={{ marginLeft: 10 }}
                                onClick={props.saveData}
                            >
                                Saglabāt attēlus
                            </Button>
                        </Card>
                        <Tabs
                            defaultActiveKey="1"
                            activeKey={qualityTabKey}
                            onChange={(key: any) => {
                                deleteNotUploadedFile();
                                checkVideo(langTabKey, key);
                                setQualityTabKey(key);
                            }}
                        >
                            {
                                videoQualities?.map((quality: any, index: any) => {
                                    // console.log(videoQualities, resFile, lang.iso, quality)
                                    return <Tabs.TabPane key={index} tab={quality.charAt(0).toUpperCase() + quality.slice(1)}>
                                        <b>Augšupielādēt mācību video</b><br />
                                        {
                                            !linkIsReady[lang.iso][quality] && hasVideo
                                                ? <Spin style={{ margin: 30 }} />
                                                : <></>
                                        }
                                        <ReactPlayer
                                            style={{ display: linkIsReady[lang.iso][quality] ? "block" : "none" }}
                                            className="instructions-video-preview-player"
                                            url={videoURL + `&bearrer_token=${token}`}
                                            onReady={() => {
                                                // console.log("READY PLAYER")
                                                setPlayerIsReady(true)
                                            }}
                                            controls
                                        />
                                        {
                                            resFile[lang.iso][quality] !== null
                                                ? <ReactPlayer
                                                    className="instructions-video-preview-player"
                                                    url={URL.createObjectURL(resFile[lang.iso][quality])}
                                                    controls
                                                />
                                                : <></>
                                        }
                                        <Card style={{ margin: "15px 0" }}>
                                            {
                                                lang.id === Number(langTabKey) && index === Number(qualityTabKey)
                                                    ? <Space>
                                                        <span
                                                            id={`resumable-instructions-video-input-${lang.iso}-${quality}`}
                                                            style={{
                                                                display: resFile[lang.iso][quality] === null && !linkIsReady[lang.iso][quality] ? "inline-block" : "none",
                                                                cursor: "pointer"
                                                            }}
                                                        >
                                                            Pievienot video
                                                        </span>
                                                        {
                                                            !linkIsReady[lang.iso][quality]
                                                                ? <></>
                                                                : <Button danger type="primary" onClick={() => deleteUploadedFile(lang.id, quality)}>
                                                                    Dzēst
                                                                </Button>
                                                        }
                                                        {
                                                            resFile[lang.iso][quality] !== null
                                                                ? <Space>
                                                                    <Button danger type="primary" onClick={deleteNotUploadedFile}>
                                                                        Dzēst
                                                                    </Button>
                                                                    <button onClick={() => resumableFileUpload(lang.id, quality)}>Sākt augšupielādi</button>
                                                                    <div style={{ width: 400 }}>
                                                                        <Progress
                                                                            percent={progress.toFixed(0)}
                                                                            // size="small"
                                                                            status={Number(progress.toFixed(0)) === 100 ? "success" : "active"}
                                                                        />
                                                                    </div>
                                                                </Space>
                                                                : <></>
                                                        }
                                                    </Space>
                                                    : <></>
                                            }
                                        </Card>
                                    </Tabs.TabPane>
                                })
                            }
                        </Tabs>
                    </Tabs.TabPane>
                })
            }
        </Tabs >
}