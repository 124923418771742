import React, { useEffect, useState } from "react";
import { Button, Card, Col, Divider, Input, Layout, message, Modal, Row, Table, Typography } from 'antd';
import {
    CalendarOutlined, EnvironmentOutlined, InfoCircleOutlined,
    MessageOutlined, UserOutlined, PhoneOutlined,
    CarOutlined, ClockCircleOutlined
} from '@ant-design/icons';
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { http } from "../../helpers/http";

const errorStatuses = {
    "other_reserved": "Kāds cits jau ir rezervējis šo laiku",
    "slot_not_active": "Nodarbība nav aktīva",
    "lesson_start_time_offset_fail": "Nodarbības rezevācijas laiks ir pārāk tuvu",
    "student_already_signed_to_datetime": "Šis laiks jau ir rezervēts",
    "someone_already_bought": "Kāds cits jau ir rezervējis šo laiku",
    "student_dont_have_category": "Students nav šīs kategorijas",
    "daily_driving_limit_reached": "Dienas braukšanas limits sasniegts",
    "not_enough_balance": "Nepietiek līdzekļu",
    "student_not_found": "Students netika atrasts",
    "short_phone_nr": "Nepareizs tālruņa numurs",
} as any;

export const FreeSlotsPage = (props: any) => {
    const [classifiers, setClassifiers] = useState({} as any);
    const [freeSlots, setFreeSlots] = useState([] as any);
    const [phone, setPhone] = useState(undefined as any);
    const [studentAdded, setStudentAdded] = useState({} as any)
    const [addStudentModalVisible, setAddStudentModalVisible] = useState(false);
    const [editSlot, setEditSlot] = useState({} as any);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15,
    } as any);
    const [instructors, setInstructors] = useState([] as any);
    const [selectedInstructor, setSelectedInstructor] = useState(null as any);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const fetchClassifiers = async () => {
        const categories = await http.get(`/rest/categories`).then((response: any) => {
            return response.data.data;
        });
        const cities = await http.get(`/rest/cities`).then((response: any) => {
            return response.data.data;
        });
        setClassifiers({
            cities: cities,
            categories: categories
        });
    }

    const fetchSlots = async (params: any = { pagination: { current: 1 } }) => {
        setLoading(true);
        await http.get('/rest/free-instructors', { params: getParams(params) }).then((response: any) => {
            console.log(response)
            const meta = response.data.meta;
            setFreeSlots(response.data.data);
            setPagination({
                current: meta.current_page,
                pageSize: meta.per_page,
                total: meta.total
            })
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }

    const fetchFreeInstructorsStats = () => {
        setLoading(true);
        http.get(`rest/free-instructors-stats`).then((res: any) => {
            setInstructors(res.data.data)
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }

    const getParams = (params: any) => {
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters
        }
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(filters, pagination, sorter);
        if (selectedInstructor) {
            filters.instructor_id = [selectedInstructor.instructor_id]
        }
        fetchSlots({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters,
        });
    };

    const setStudent = (slot: any) => {
        setAddStudentModalVisible(true);
        slot.meeting_place = slot.instructor_service.city.filter((city: any) => {
            return city.city_id === slot.city_id;
        })[0].meeting_place;
        console.log(slot)
        setEditSlot(slot);
    };

    useEffect(() => {
        if (addStudentModalVisible === true) {
            console.log(editSlot)
        }
    }, [addStudentModalVisible]);

    useEffect(() => {
        if (selectedInstructor) {
            handleTableChange({ current: 1 }, { instructor_id: [selectedInstructor.instructor_id] }, {});
        }
    }, [selectedInstructor])

    useEffect(() => {
        fetchClassifiers();
        // fetchSlots();
        fetchFreeInstructorsStats();
    }, []);

    const assignStudentToSlot = () => {
        http.post(`/rest/time_slots/${editSlot.id}/add_student`, { phone_number: phone }).then((response) => {
            setStudentAdded(response.data.data)
            setError("");
            fetchSlots();
        }).catch((e: any) => {
            if (e.response && e.response.data.data.reason) {
                setError(e.response.data.data.reason);
            }
            // if (e.response && e.response.data.data === "Student not found.") {
            //     message.error("Students netika atrasts")
            // } else if (e.response && e.response.data.data === "Student balance error.") {
            //     message.error("Studentam nepietiek līdzekļu")
            // }
            // setAddingStudent(false);
        });
    }

    const resetModal = () => {
        setAddStudentModalVisible(false);
        setPhone(undefined);
        setStudentAdded({});
        setError("");
    }

    const columns = [
        {
            title: "Instruktors",
            dataIndex: ["instructor_service", "instructor"],
            key: "instructor",
            render: (record: any) => {
                return record.first_name + " " + record.last_name
            },
            ...getColumnSearchProps()
        },
        {
            title: "Kategorija",
            dataIndex: "instructor_service",
            key: "category",
            align: "center",
            filters: classifiers.categories?.map((category: any) => {
                return { text: category.value, value: category.id };
            }),
            render: (record: any) => {
                return classifiers.categories?.find((el: any) => el.id === record.category.id).value;
            }
        },
        {
            title: "Pilsēta",
            dataIndex: "city_id",
            key: "city",
            filters: classifiers.cities?.map((city: any) => {
                return { text: city.value, value: city.id };
            }),
            render: (record: any) => {
                return classifiers.cities?.find((el: any) => el.id === record).value;
            }
        },
        {
            title: "Datums",
            dataIndex: "date",
            key: "date",
            ...getColumnSearchProps('calendar')
        },
        {
            title: "Laiks",
            dataIndex: "time",
            key: "time",
            render: (record: any, all: any) => {
                return all.time_from + " - " + all.time_to
            }
        },
        {
            title: "Nodarbību skaits",
            dataIndex: "how_many_lessons",
            key: "how_many_lessons",
            align: "center",
            width: 70,
            render: (count: any) => {
                return count === 5 ? 1 : count;
            }
        },
        {
            title: "Studentu skaits",
            dataIndex: "how_many_students",
            key: "how_many_students",
            align: "center",
            width: 70
        },
        {
            title: "Summa",
            dataIndex: "total_rate_real",
            key: "total_rate_real",
            render: (record: any, all: any) => {
                return (record * (all.how_many_lessons === 5 ? 1 : all.how_many_lessons)).toFixed(2);
            }
        },
    ] as any;

    const instructorsColumns = [
        {
            title: "Instruktors",
            dataIndex: "instructor",
            key: "instructor",
        },
        {
            title: "Brīvie sloti",
            dataIndex: "free_lessons",
            key: "free_lessons",
        },
        {
            title: "Aizņemtie sloti",
            dataIndex: "bought_lessons",
            key: "bought_lessons",
        },
    ] as any;

    return (
        <Layout>
            <Modal
                title="Pievienot studentu nodarbībai"
                visible={addStudentModalVisible}
                onCancel={resetModal}
                footer={<></>}
            >
                <Row gutter={16}>
                    <Col lg={12}>
                        <div style={{ marginBottom: 10 }}>
                            <UserOutlined style={{ fontSize: 16, marginRight: 10 }} />
                            {editSlot.instructor_service?.instructor?.first_name} {editSlot.instructor_service?.instructor?.last_name}
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <CarOutlined style={{ fontSize: 16, marginRight: 10 }} />
                            {editSlot.instructor_service?.vehicle + " " + editSlot.instructor_service?.licence_plate}, {editSlot.instructor_service?.transmission === "manual" ? "Manuālā" : "Automātiskā"} Pārnesumkārba
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div style={{ marginBottom: 10 }}>
                            <PhoneOutlined style={{ fontSize: 16, marginRight: 10 }} />
                            {editSlot.instructor_service?.instructor?.phone_number}
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <MessageOutlined style={{ fontSize: 16, marginRight: 10 }} />
                            {editSlot.instructor_service?.instructor?.email}
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={16}>
                    <Col lg={12}>
                        <div style={{ marginBottom: 10 }}>
                            <EnvironmentOutlined style={{ fontSize: 16, marginRight: 10 }} />
                            {editSlot?.instructor_service?.cities.find((city: any) => city.id === editSlot.city_id).value},&nbsp;
                            {editSlot.meeting_place}
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <InfoCircleOutlined style={{ fontSize: 16, marginRight: 10 }} />
                            {editSlot.instructor_service?.category.value} kategorija
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div style={{ marginBottom: 10 }}>
                            <CalendarOutlined style={{ fontSize: 16, marginRight: 10 }} />
                            {editSlot.date}
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <ClockCircleOutlined style={{ fontSize: 16, marginRight: 10 }} />
                            {editSlot.time_from + " - " + editSlot.time_to}
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    {
                        Object.keys(studentAdded).length > 0
                            ? <Col span={24} style={{ marginBottom: 10 }}>
                                Students <b>{studentAdded.first_name} {studentAdded.last_name}</b> ir pievienots
                            </Col>
                            : <Col span={24}>
                                <Input value={phone} onChange={(e: any) => setPhone(e.target.value)} style={{ maxWidth: 200, marginRight: 20 }} placeholder="Studenta tālruņa numurs" />
                                <Button onClick={assignStudentToSlot} type="primary">Pievienot</Button>
                                {
                                    error.length > 0
                                    && <div style={{ marginTop: 2 }}>
                                        <Typography.Text type="danger">{errorStatuses[error] ?? error}</ Typography.Text>
                                    </div>
                                }
                            </Col>
                    }
                </Row>
            </Modal>
            <Card
                title={
                    <Row>
                        <p>Brīvās braukšanas nodarbības</p>
                        {
                            selectedInstructor
                            && <Button
                                type="ghost"
                                style={{ marginLeft: 50 }}
                                onClick={() => setSelectedInstructor(null)}
                            >
                                Atgriezties pie instruktoru saraksta
                            </Button>
                        }
                    </Row>
                }
            >
                {
                    !selectedInstructor
                        ? <Table
                            dataSource={instructors}
                            columns={instructorsColumns}
                            rowKey={record => record.instructor_id}
                            pagination={false}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        setSelectedInstructor(record);
                                    }, // click row
                                    onDoubleClick: event => { }, // double click row
                                    onContextMenu: event => { }, // right button click row
                                    onMouseEnter: event => { }, // mouse enter row
                                    onMouseLeave: event => { }, // mouse leave row
                                };
                            }}
                            loading={loading}
                        />
                        : <Table
                            dataSource={freeSlots}
                            columns={columns}
                            rowKey={record => record.id}
                            onChange={handleTableChange}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        setStudent(record);
                                    }, // click row
                                    onDoubleClick: event => { }, // double click row
                                    onContextMenu: event => { }, // right button click row
                                    onMouseEnter: event => { }, // mouse enter row
                                    onMouseLeave: event => { }, // mouse leave row
                                };
                            }}
                            pagination={{
                                showSizeChanger: false,
                                size: 'small',
                                showTotal: (total) => <div>Kopā {total} Nodarbības</div>,
                                position: ['bottomCenter'],
                                defaultPageSize: 15,
                                ...pagination
                            }}
                            loading={loading}
                        />
                }
            </Card>
        </Layout>
    )
}