import { Layout, PageHeader, Card, Row, Col, Select, Input, Button, Calendar, Spin } from "antd";
import { ExclamationCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { AxiosResponse } from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../helpers/http";
import { GroupQuickInfoModal } from "./GroupQuickInfoModal";
import { DayModulesOverview } from "./DayModulesOverview";

const DateSlot = (props: any) => {
    const { slot, types, selectGroup } = props;

    return <div
        style={{
            padding: 4,
            border: "1px solid #F0F0F0",
            borderRadius: 3,
            marginBottom: 3,
            backgroundColor: types.find((el: any) => el.id === slot.study_type_id)?.color
        }}
        onClick={() => selectGroup(slot)}
    >
        <p style={{ margin: 0, fontSize: 10 }}><b>{slot.csdd_nr} {slot.category} {slot.city} {slot.language}</b></p>
        <p style={{ margin: 0, fontSize: 10 }}><b>Atvērta: {slot.created_at} {slot.student_counter}/{slot.planned_slots} {slot.problem && <ExclamationCircleOutlined style={{ color: "red", fontSize: 16, marginLeft: 5 }} />}</b></p>

    </div>
}

const ProblemDay = (props: any) => {
    return <div
        style={{
            backgroundColor: "#ff00000a",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center"
        }}
        onClick={() => props.onClick(props.day)}
    >
        <ExclamationCircleOutlined
            style={{
                color: "red",
                fontSize: 20,
                marginLeft: 5,
                display: "flex",
                alignItems: "center"
            }}
        />
    </div>
}

const AllGoodDay = (props: any) => {
    return <div
        style={{
            backgroundColor: "#00ff0d0a",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center"
        }}
        onClick={() => props.onClick(props.day)}
    >
        <CheckOutlined
            style={{
                color: "#00ff0d",
                fontSize: 20,
                marginLeft: 5,
                display: "flex",
                alignItems: "center"
            }}
        />
    </div>
}

const EmptyDay = (props: any) => {
    return <div
        style={{
            backgroundColor: "#5252520a",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center"
        }}
    >
        {/* <CheckOutlined
            style={{
                color: "#00ff0d",
                fontSize: 20,
                marginLeft: 5,
                display: "flex",
                alignItems: "center"
            }}
        /> */}
    </div>
}

const LegendItem = ({ text, color }: any) => {
    return <div>
        <div style={{
            width: 20,
            height: 20,
            borderRadius: 4,
            backgroundColor: color,
            display: "inline-flex",
            marginRight: 8,
            border: "1px solid #F0F0F0"
        }}>
        </div>
        <span>{text}</span>
    </div>
}

export const GroupsCalendarLayout = () => {
    const history = useHistory();
    const [filters, setFilters] = useState({
        city_id: undefined as undefined | number,
        category: undefined as undefined | number,
        language_id: undefined as undefined | number,
        study_type_id: undefined as undefined | number,
        name: undefined as undefined | string,
        lecturer: undefined as undefined | string
    });
    const [currentDate, setCurrentDate] = useState(moment())
    const [categories, setCategories] = useState([] as any);
    const [cities, setCities] = useState([] as any);
    const [languages, setLanguages] = useState([] as any);
    const [types, setTypes] = useState([] as any);
    const [data, setData] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [showCalendar, setShowCalendar] = useState(true);
    const [daySelected, setDaySelected] = useState(undefined as string | undefined);

    const getCategories = () => {
        http.get(`/rest/categories`).then((res: AxiosResponse) => {
            setCategories(res.data.data)
        })
    };

    const getCities = () => {
        http.get(`/rest/cities`).then((res: AxiosResponse) => {
            setCities(res.data.data)
        })
    };
    const getLanguages = () => {
        http.get(`/rest/languages`).then((res: AxiosResponse) => {
            setLanguages(res.data.data)
        })
    };

    const getTypes = () => {
        http.get(`/rest/study-groups-types`).then((res: AxiosResponse) => {
            setTypes(res.data.data.map((type: any) => {
                return {
                    ...type,
                    color: type.id === 1
                        ? "#d6ffd8"
                        : type.id === 2
                            ? "#ffffd3"
                            : "#d8d6ff"
                }
            }))
        })
    }

    const fetchData = (date: any = currentDate) => {
        const date_from = moment(date).startOf("month").subtract(10, "days").format("YYYY-MM-DD")
        const date_to = moment(date).endOf("month").add(10, "days").format("YYYY-MM-DD")
        setLoading(true);
        http.get(`/rest/group-load-stats`, {
            params: {
                filters: {
                    ...filters,
                    date_from,
                    date_to
                }
            }
        }).then((res: AxiosResponse) => {
            setLoading(false);
            console.log(res)
            setData(res.data)
        }).catch(() => {
            setLoading(false)
        })
    }

    const selectDateHandler = (date: moment.Moment) => {
        setCurrentDate(date)
        if (!date.isSame(currentDate, "month")) {
            fetchData(date)
        }

    }

    const dateCellRender = (value: any) => {
        const day = moment(value).format("YYYY-MM-DD");
        if (data === undefined) return
        const slot = data?.find((slot: any) => {
            return moment(slot.date, "YYYY-MM-DD").isSame(value, "day");
        });
        if (slot === undefined) {
            return <EmptyDay />
        } else if (!!slot.problem) {
            return <ProblemDay onClick={(data: string) => setDaySelected(data)} day={day} />
        }
        return <AllGoodDay onClick={(data: string) => setDaySelected(data)} day={day} />
    }

    useEffect(() => {
        getCategories();
        getCities();
        getLanguages();
        getTypes();
        fetchData();
    }, []);

    useEffect(() => {
        setShowCalendar(daySelected === undefined)
    }, [daySelected])

    return <Layout>
        <PageHeader
            title="Grupu Kalendārs"
            style={{ backgroundColor: "#FFFFFF" }}
            onBack={history.goBack}
        />
        {
            showCalendar
                ? <><Card>
                    <Row gutter={[20, 20]}>
                        {/* <Col>
                    <p>Atlases periods:</p>
                    <DatePicker.RangePicker
                        value={
                            filters.date_from !== undefined
                                ? [moment(filters.date_from, "YYYY-MM-DD"), moment(filters.date_to, "YYYY-MM-DD")]
                                : undefined
                        }
                        onChange={e => {
                            setFilters((prev: any) => {
                                return {
                                    ...prev,
                                    date_from: e ? e[0]?.format("YYYY-MM-DD") : undefined,
                                    date_to: e ? e[1]?.format("YYYY-MM-DD") : undefined,
                                }
                            })
                        }}
                    />
                </Col> */}
                        <Col>
                            <p>Pilsēta:</p>
                            <Select
                                style={{ width: 120 }}
                                value={filters.city_id}
                                onChange={e => {
                                    setFilters((prev: any) => {
                                        return {
                                            ...prev,
                                            city_id: e === 0 ? undefined : e
                                        }
                                    })
                                }}
                            >
                                <Select.Option value={0}> </Select.Option>
                                {
                                    cities.map((city: any) => {
                                        return <Select.Option key={city.id} value={city.id}>
                                            {city.value}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                        </Col>
                        <Col>
                            <p>Kategorija:</p>
                            <Select
                                style={{ width: 120 }}
                                value={filters.category}
                                onChange={e => {
                                    setFilters((prev: any) => {
                                        return {
                                            ...prev,
                                            category: e === 0 ? undefined : e
                                        }
                                    })
                                }}
                            >
                                <Select.Option value={0}> </Select.Option>
                                {
                                    categories.map((category: any) => {
                                        return <Select.Option key={category.id} value={category.id}>
                                            {category.value}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                        </Col>
                        <Col>
                            <p>Valoda:</p>
                            <Select
                                style={{ width: 120 }}
                                value={filters.language_id}
                                onChange={e => {
                                    setFilters((prev: any) => {
                                        return {
                                            ...prev,
                                            language_id: e === 0 ? undefined : e
                                        }
                                    })
                                }}
                            >
                                <Select.Option value={0}> </Select.Option>
                                {
                                    languages.map((language: any) => {
                                        return <Select.Option key={language.id} value={language.id}>
                                            {language.value}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                        </Col>
                        <Col>
                            <p>Apmācības veids:</p>
                            <Select
                                style={{ width: 150 }}
                                value={filters.study_type_id}
                                onChange={e => {
                                    setFilters((prev: any) => {
                                        return {
                                            ...prev,
                                            study_type_id: e === 0 ? undefined : e
                                        }
                                    })
                                }}
                            >
                                <Select.Option value={0}> </Select.Option>
                                {
                                    types.map((type: any) => {
                                        return <Select.Option key={type.id} value={type.id}>
                                            {type.value}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                        </Col>
                        {/* <Col>
                    <p>Modulis:</p>
                    <Input
                        value={filters.name}
                        onChange={e => {
                            e.persist();
                            setFilters((prev: any) => {
                                return {
                                    ...prev,
                                    name: e.target.value.length > 0 ? e.target.value : undefined
                                }
                            })
                        }}
                    />
                </Col>
                <Col>
                    <p>Pasniedzejs:</p>
                    <Input
                        value={filters.lecturer}
                        onChange={e => {
                            e.persist();
                            setFilters((prev: any) => {
                                return {
                                    ...prev,
                                    lecturer: e.target.value.length > 0 ? e.target.value : undefined
                                }
                            })
                        }}
                    />
                </Col> */}
                        <Col>
                            <Button
                                style={{ marginTop: 36 }}
                                type="primary"
                                onClick={() => fetchData()}
                                loading={loading}
                            >
                                Atlasīt
                            </Button>
                        </Col>
                    </Row>
                </Card>
                    <Card bodyStyle={{ overflow: "scroll", maxHeight: "100vh" }}>
                        {
                            loading
                            && <div
                                style={{
                                    display: "flex",
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    top: 0,
                                    left: 0,
                                    backgroundColor: "#ffffff80",
                                    zIndex: 3,
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Spin size="large" />
                            </div>
                        }
                        {/* <Row>
                <Col>
                    <p><b>Krāsu atšifrējums</b></p>
                    {
                        types.map((type: any) => {
                            return <LegendItem color={type.color} text={type.value} />
                        })
                    }
                </Col>
            </Row> */}
                        {/* <Table
                rowKey={record => record.lecturer + record.csdd_nr + record.module}
                dataSource={data}
                columns={columns}
                pagination={
                    {
                        ...pagination,
                        pageSize: 15,
                        showSizeChanger: false,
                        position: ['bottomCenter'],
                        showTotal: total => `Kopā ${total} ieraksti`
                    }
                }
                onChange={handleTableChange}
            /> */}
                        <Calendar
                            onSelect={selectDateHandler}
                            dateCellRender={dateCellRender}
                            value={currentDate}
                        />
                    </Card>
                </>
                : <DayModulesOverview
                    goBack={() => setDaySelected(undefined)}
                    day={daySelected}
                />
        }
    </Layout>
}