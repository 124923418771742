import { Button, Card, Col, PageHeader, Radio, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

type ExamResultsProps = {
    exams: [any]
}

export const ExamResults = ({ exams }: ExamResultsProps) => {
    const { id }: { id: string } = useParams();
    const history = useHistory();
    const [exam, setExam] = useState({} as any);

    useEffect(() => {
        window.scrollTo(0, 0)
        if (exams === null) {
            history.goBack();
        }
        if (exams) {
            setExam(exams.find((el: any) => el.exam.id === Number(id)))
        }
    }, [id, exams]);

    useEffect(() => {
        console.log(exam)
    }, [exam])

    return <div>
        <PageHeader
            title={`Eksāmena rezultāti - ` + exam?.exam?.student + " - " + exam?.exam?.module}
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF" }}
        />
        <Card
            style={{ margin: 20 }}
        >
            {
                exam?.questions?.map((question: any, index: any) => {
                    const correct = question.answers.find((el: any) => !!el.correct)
                    const selectedAnswer = exam?.answers.find((el: any) => el.question_id === question.id)
                    console.log(selectedAnswer)
                    return <div
                        className={`exam-question-card ${selectedAnswer.correct ? "correct" : "fault"}`}
                        key={question.id}
                    >
                        <Row gutter={24} style={{ height: "100%" }}>
                            <Col span={24} lg={15} style={{ borderRight: false ? "" : "1px solid #E0E6EC", paddingTop: 48, paddingBottom: 48 }}>
                                <b className="question-big-text">{index + 1}. {question?.question_lv}</b>
                                {
                                    question?.img !== null
                                        ? <div className="img-wrap question-img">
                                            <img src={process.env.REACT_APP_API_URL + question?.public_image} alt="" />
                                        </div>
                                        : <></>
                                }
                                {
                                    question?.description_lv !== null
                                        ? <div className="description-wrap">
                                            <b>{question?.description_lv}</b>
                                        </div>
                                        : <></>
                                }
                            </Col>
                            <Col
                                span={24}
                                lg={9}
                                style={{
                                    paddingTop: 48,
                                    paddingBottom: 48,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between"
                                }}
                            >
                                <div>
                                    <Radio.Group
                                        value={selectedAnswer?.answer_id}
                                        style={{ width: "100%" }}
                                    >
                                        {
                                            question?.answers?.map((answer: any, index: any) => {
                                                return <div
                                                    key={index}
                                                    className={`answer ${answer.id === selectedAnswer.id
                                                        // ? selectedAnswer === undefined
                                                        //     ? "selected"
                                                        //     : "correct"
                                                        //         ? "correct"
                                                        //         : "incorrect"
                                                        ? "selected"
                                                        : ""
                                                        } ${answer.id === correct.id
                                                            ? "correct"
                                                            : "incorrect"
                                                        }
                                                            `}
                                                >
                                                    <Radio value={answer.id} />
                                                    <div className="answer-col">
                                                        {
                                                            answer?.img !== null
                                                                ? <div className="img-wrap">
                                                                    <img src={process.env.REACT_APP_API_URL + answer?.public_image} alt="" />
                                                                </div>
                                                                : <></>
                                                        }
                                                        <p>{answer?.answer_lv}</p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </Radio.Group>
                                </div>
                            </Col>
                        </Row>
                    </div>
                })
            }
        </Card>
    </div>
}