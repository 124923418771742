import { Card, Form, Row, Col, Input, Button, message } from "antd"
import { useForm } from "antd/lib/form/Form";
import React, { useState } from "react"
import { http } from "../../helpers/http";

type ChangePasswordProps = {
    student: any;
}

export const ChangePassword = ({ student }: ChangePasswordProps) => {
    const [passwordForm] = useForm();
    const [loading, setLoading] = useState(false);

    const onChangePassword = (values: any) => {
        setLoading(true);
        http.patch(`/rest/students/${student.id}`, { password: values.new_password }).then(() => {
            passwordForm.resetFields();
            setLoading(false);
            message.success("Parole nomainīta");
        }).catch((err: any) => {
            message.error("Kļūda!");
            setLoading(false);
        });
    }

    const onChangePasswordFailed = (errorInfo: any) => {
        console.log("Password change failed:", errorInfo);
        message.error("Neizdevās nomainīt paroli");
    }

    const sendCredential = () => {
        http.post(`/rest/student-get-new-password/${student.id}`).then((response: any) => {
            console.log(response)
        })
    }

    return <Card style={{ marginBottom: 20 }} title={<strong>Mainīt paroli</strong>}>
        <Form
            name="change-password"
            layout="vertical"
            onFinish={onChangePassword}
            onFinishFailed={onChangePasswordFailed}
            form={passwordForm}
        >
            <Row gutter={20}>
                <Col>
                    <Form.Item name="new_password" required rules={[{ required: true, whitespace: true, message: "Ievadiet jauno paroli" }]}>
                        <Input />
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item>
                        <Button type="primary" loading={loading} htmlType="submit">Mainīt</Button>
                    </Form.Item>
                </Col>
                <Button
                    type="primary"
                    onClick={sendCredential}
                >
                    Nosūtīt piekļuves saiti
                </Button>
            </Row>
        </Form>
    </Card>
}