import { Row, Button, Table } from "antd"
import React from "react"

export const IncomingTab = () => {
  const dati = [
    {
      id: 1,
      justification: "231-RA",
      date: "25.09.2020",
      customer:
      {
        first_name: "Olģerts",
        last_name: "Kauliņš"
      },
      amount: 231.43,
      status: "processed",
    },
    {
      id: 2,
      justification: "254-XA",
      date: "21.07.2021",
      customer:
      {
        first_name: "Anna",
        last_name: "Panda"
      },
      amount: 31.06,
      status: "not-processed",
    },
    {
      id: 3,
      justification: "351-FA",
      date: "18.05.2021",
      customer:
      {
        first_name: "Agnis",
        last_name: "Bāliņš"
      },
      amount: 115,
      status: "processed",
    }
  ]

  const columns = [
    {
      title: 'Izrakstīšanas datums',
      dataIndex: 'date',
      key: 'date' + 'id'
    },
    {
      title: 'Summa',
      dataIndex: 'amount',
      key: 'amount' + 'id',
      render: (amount: any) => amount.toFixed(2) + " " + "EUR"
    },
    {
      title: 'Klients',
      dataIndex: 'customer',
      key: 'customer' + 'id',
      render: (customer: any) => customer.first_name + " " + customer.last_name
    },
    {
      title: 'Pamatojums',
      dataIndex: 'justification',
      key: 'justification' + 'id'
    }
  ]

  return (
    <>
      <Row>
        Te būs meklēšana
        <Button>Apstrādātie</Button>
        <Button>Neapstrādātie</Button>
      </Row>
      <Table
        rowKey={record => record.id}
        dataSource={dati}
        columns={columns}
      />
    </>
  )
}