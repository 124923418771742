import { Button, Card, DatePicker, Layout, message, PageHeader, Space, Table, Tabs } from "antd"
import { ReactComponent as ExcelLogo } from '../../src/resources/svg/icons8-microsoft-excel.svg';
import moment from "moment";
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { getColumnSearchProps } from "../helpers/getColumnSearchProps";
import { http } from "../helpers/http";
import { AxiosResponse } from "axios";

export const StudentsRemainingBalance = (props: any) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [hasLicenseList, setHasLicenseList] = useState(true);
    const [students, setStudents] = useState({
        withLicense: {
            data: [],
            pages: 0,
            page: 1,
            total: 0,
        },
        withoutLicense: {
            data: [],
            pages: 0,
            page: 1,
            total: 0,
        }
    });
    const [currentFilters, setCurrentFilters] = useState({} as any);

    const fetchStudents = (hasLicense: boolean, params: any = { params: { page: 1 } }) => {
        console.log(params)
        setLoading(true);
        if (hasLicense) {
            http.get(`rest/get-students-with-licence-deposit`, params).then(async (response: any) => {

                const students = await Promise.all(response.data.data.map(async (student: any) => {
                    return {
                        ...student,
                        ...await getPaymentsInfo(student.student_id)
                    }
                }))
                setLoading(false);
                setStudents((prev: any) => {
                    return {
                        ...prev,
                        withLicense: {
                            ...response.data,
                            data: students
                        }
                    }
                })
            })
        } else {
            http.get(`rest/get-students-without-licence-deposit`, params).then(async (response: any) => {
                const students = await Promise.all(response.data.data.map(async (student: any) => {
                    return {
                        ...student,
                        ...await getPaymentsInfo(student.student_id)
                    }
                }))
                setLoading(false);
                setStudents((prev: any) => {
                    return {
                        ...prev,
                        withoutLicense: {
                            ...response.data,
                            data: students
                        }
                    }
                })
            })
        }
    };

    const getPaymentsInfo = async (student: number) => {
        return await http.get(`/rest/student-last-deposit-date/${student}`).then((res: AxiosResponse) => {
            return res.data
        })
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(pagination, filters, sorter, "CHAAANGE")
        const params = {
            params: {
                page: pagination.current,
                filters: { ...currentFilters, ...filters }
            }
        }
        setCurrentFilters((prev: any) => { return { ...prev, ...filters } })
        fetchStudents(hasLicenseList, params);
    }

    const getFile = async () => {
        setLoading(true);
        const url = hasLicenseList
            ? `rest/student-with-licence-excel`
            : `rest/student-without-licence-excel`
        await http.get(url, { params: { filters: { ...currentFilters } }, responseType: 'blob' }).then((response: any) => {
            console.log(response)
            setLoading(false);
            return new Blob([response.data]);
        }).then((blob: any) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a') as any;
            link.href = url;
            link.setAttribute('download', `${moment().format('YYYY-MM-DD HH:mm:ss')}.xls`);
            document.body.appendChild(link);
            link.click();
            if (link !== null) link.parentNode.removeChild(link);
        }).catch(() => {
            message.error("Kļūda");
            setLoading(false);
        })
    }

    const woLicenseCols = [
        {
            title: "Students",
            dataIndex: "student_name",
            key: "student_name"
        },
        {
            title: "Depozīta atlikums",
            dataIndex: "remaining_deposit",
            key: "remaining_deposit",
        },
        {
            title: "Pēdējās iemaksas datums",
            dataIndex: "last_payment_date",
            key: "last_payment_date_sp",
            // ...getColumnSearchProps("calendar-range")
        },
        {
            title: "Pēdējā maksājuma datums",
            dataIndex: "last_expense_date",
            key: "last_expense_date_sp",
            // ...getColumnSearchProps("calendar-range")
        },
        {
            title: "E-pasts",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps()
        },
        {
            title: "Tālruņa nr.",
            dataIndex: "phone_number",
            key: "phone_number"
        },
    ];

    const wLicenseCols = [
        ...woLicenseCols,
        {
            title: "Vadītāja apliecības saņemšanas datums",
            dataIndex: "got_licence_date",
            key: "got_licence_date"
        },
    ];

    useEffect(() => {
        fetchStudents(hasLicenseList);
    }, [hasLicenseList]);

    useEffect(() => {
        setCurrentFilters({})
    }, [hasLicenseList]);

    useEffect(() => {
        console.log(students)
    }, [students]);

    return <Layout>
        <PageHeader
            title={<div>
                <Space>
                    <span>Studentu bilances atlikums</span>
                    <Button
                        onClick={() => setHasLicenseList(true)}
                        type={!hasLicenseList ? "default" : "primary"}
                        loading={loading && hasLicenseList}
                    >
                        Nokārtojuši vadītāja apliecību
                    </Button>
                    <Button
                        onClick={() => setHasLicenseList(false)}
                        type={hasLicenseList ? "default" : "primary"}
                        loading={loading && !hasLicenseList}
                    >
                        Nav nokārtojuši vadītāja apliecību
                    </Button>
                    <b style={{ fontSize: 12 }}>Datums līdz:</b>
                    <DatePicker
                        value={currentFilters.date_to !== undefined ? moment(currentFilters.date_to, "YYYY-MM-DD") : null}
                        onChange={e => {
                            handleTableChange({ current: 1 }, { date_to: e ? e.format("YYYY-MM-DD") : undefined }, null)
                        }}
                    />
                    {
                        hasLicenseList
                            ? students.withLicense.data.length > 0
                                ? <Button className="excel-btn" onClick={getFile}>
                                    Eksportēt&nbsp;
                                    <ExcelLogo className="excel-btn__logo" />
                                </Button>
                                : <></>
                            : students.withoutLicense.data.length > 0
                                ? <Button className="excel-btn" onClick={getFile}>
                                    Eksportēt&nbsp;
                                    <ExcelLogo className="excel-btn__logo" />
                                </Button>
                                : <></>
                    }

                </Space>
            </div>}
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF", marginBottom: 15 }}
        />
        <Card>
            {
                hasLicenseList
                    ? <Table
                        rowKey="student_id"
                        columns={wLicenseCols}
                        dataSource={students.withLicense.data}
                        onChange={handleTableChange}
                        loading={loading}
                        pagination={{
                            current: Number(students.withLicense.page),
                            total: students.withLicense.total,
                            pageSize: 15,
                            showSizeChanger: false,
                            showTotal: (total) => <div>Kopā {total} ieraksti</div>,
                        }}
                    />
                    : <Table
                        rowKey="student_id"
                        columns={woLicenseCols}
                        dataSource={students.withoutLicense.data}
                        onChange={handleTableChange}
                        loading={loading}
                        pagination={{
                            current: Number(students.withoutLicense.page),
                            total: students.withoutLicense.total,
                            pageSize: 15,
                            showSizeChanger: false,
                            showTotal: (total) => <div>Kopā {total} ieraksti</div>,
                        }}
                    />
            }
        </Card>
    </Layout>
}