import { Button, message, Select } from "antd"
import React, { useEffect, useState } from "react"
import { http } from "../../helpers/http";
import { AxiosResponse } from 'axios';
import { useParams } from "react-router-dom";
import { setgroups } from "process";

type GroupsLoaderSelectProps = {
    category: any;
    student: any;
    refresh: () => void;
}

export const GroupsLoaderSelect = ({ category, student, refresh }: GroupsLoaderSelectProps) => {
    const { id }: { id: string } = useParams();
    const [studyGroups, setStudyGroups] = useState([] as any);
    const [selectedGroup, setSelectedGroup] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetchGroups = () => {
        http.get(`rest/groups-for-student-select/${category.category_id}/${student.language_id}`).then((res: AxiosResponse) => {
            console.log(res)
            setStudyGroups(res.data.data)
        })
        // http.get("/rest/study-groups", {
        //     params: {
        //         no_paginate: 1,
        //         filters: {
        //             cities: category.city_id ? [category.city_id] : null,
        //             category: [category.category_id],
        //             languages: [student.language_id]
        //         }
        //     }
        // }).then((res: AxiosResponse) => {
        //     setStudyGroups(res.data.data)
        // }).catch(() => {

        // })
    }

    const addStudentToGroup = () => {
        const values = {
            reservation_type: ["interested"],
            student: [student]
        }
        setLoading(true)
        http.post(`/rest/study-groups/add-student/${selectedGroup}`, values).then((response) => {
            // http.get(`/rest/student-study-group/${id}`).then((response: any) => {
            //     if (response.data.length > 0) {
            //         setSignedToGroups(response.data)
            //     }
            // });
            message.success("Students ir pievienots grupai");
            refresh();
            setLoading(false)
        }).catch(() => {
            message.error("Kļūda!");
            setLoading(false)
        });
    }

    useEffect(() => {
        fetchGroups();
    }, []);

    return <div>
        <Select
            value={selectedGroup === 0 ? undefined : selectedGroup}
            placeholder="Izvēlieties grupu"
            style={{ width: 350, marginBottom: 15 }}
            onChange={(e: any) => setSelectedGroup(e)}
        >
            {
                studyGroups
                    ? studyGroups.map((group: any) => {
                        return <Select.Option style={{ fontWeight: "bold" }} key={group.id} value={String(group.id)}>{group.name} {group.start_date} {group.csdd_nr} {group.taken_slots}/{group.planned_slots} ({group.interested}) {group.languages}</Select.Option>
                    }) : null
            }

        </Select>
        {
            selectedGroup > 0
            && <Button loading={loading} style={{ maxWidth: 250, marginLeft: 20 }} onClick={addStudentToGroup}>Pievienot grupai</Button>
        }
    </div>
}