import { Button, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { ServicePricesLogTable } from "./ServicePricesLogTable";

export const ServicesPriceLog = (props: any) => {
    const { service, groupID, visible, close } = props;
    const [priceHistory, setPriceHistory] = useState([]);

    const getServicePriceLog = () => {
        http.get(`rest/get-price-change-log/${groupID}?service=${service !== 0 ? service.id : 0}`).then((response: any) => {
            const priceLogArr = [] as any;
            Object.keys(response.data.data).forEach((key: any) => {
                priceLogArr.push(response.data.data[key])
            })
            setPriceHistory(priceLogArr.reverse())
        })
    }

    useEffect(() => {
        if (visible) {
            getServicePriceLog();
        } else {
            setPriceHistory([]);
        }
    }, [visible]);

    useEffect(() => {
        console.log(service, priceHistory)
    }, [priceHistory])

    return <Modal
        title={service === 0 ? "Reģistrācija" : service?.value_lv}
        visible={visible}
        onCancel={() => close()}
        footer={
            <Button onClick={() => close()}>
                Aizvērt
            </Button>
        }
        width={800}
    >
        <ServicePricesLogTable data={priceHistory} />
    </Modal>
}