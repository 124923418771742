import React, { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Layout, message, Modal, PageHeader, Row, Select, Skeleton, Space, Spin, Table, Tag, TimePicker } from "antd";
import { withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import moment from "moment";

export const EditMedicalGroup = withRouter((props: any) => {
    const [group, setGroup] = useState({} as any);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [studyGroups, setStudyGroups] = useState([] as any);
    const [chosenGroup, setChosenGroup] = useState(undefined as any);
    const [loadingGroups, setLoadingGroups] = useState(false);

    const fetchGroups = async (date: any) => {
        if (!date) return;
        setLoadingGroups(true);
        await http.get("/rest/study-groups", {
            params: {
                no_paginate: 1,
                pmp: 1,
                medical_date: date.format("YYYY-MM-DD")
            }
        }).then(result => {
            setLoadingGroups(false);
            const { data } = result.data;
            console.log(result)
            setStudyGroups(data);
        }).catch(() => {
            setLoadingGroups(false);
            message.error("Kļūda");
        })
    }

    const joinGroup = () => {
        http.get(`rest/medgroup-add-study-group/${group.id}/${chosenGroup}`).then((response: any) => {
            fetch();
        }).catch(() => {
            message.error("Kļūda");
        })
    }

    const fetch = async () => {
        setLoading(true);
        await http.get(`/rest/medical-groups/${props.match.params.id}`).then((response: any) => {
            console.log(response)
            setGroup(response.data);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            message.error("Kļūda");
        })
    }

    useEffect(() => {
        fetch().then(() => setLoaded(true));
    }, [])

    useEffect(() => {

    }, [group])

    const saveGroup = (values: any) => {
        console.log(values)
        values.medical_date = moment(values.medical_date).format("YYYY-MM-DD");
        values.online_date = moment(values.online_date).format("YYYY-MM-DD");
        values.medical_from = moment(values.medical_from_to[0]).format("HH:mm");
        values.medical_to = moment(values.medical_from_to[1]).format("HH:mm");
        values.online_from = moment(values.online_from_to[0]).format("HH:mm");
        values.online_to = moment(values.online_from_to[1]).format("HH:mm");
        http.patch(`/rest/medical-groups/${group.id}`, values).then((response: any) => {
            console.log(response)
            window.location.replace('/groups-medical/groups');
        });
    }

    const removeStudent = (id: any) => {
        Modal.confirm({
            title: "Vai tiešām dzēst studentu no grupas?",
            onOk: () => {
                http.post(`/rest/remove-student-from-medical-group/${group.id}/${id}`).then((response: any) => {
                    console.log(response)
                    message.success("Students dzēsts no grupas");
                    fetch();
                }).catch(() => {
                    message.error("Kļūda");
                });
            }
        })
    }

    const exportStudents = async () => {
        setLoading(true);
        const url = `rest/pmp-group-students-excel/${group.id}`
        await http.get(url, { params: undefined, responseType: 'blob' }).then((response: any) => {
            console.log(response)
            setLoading(false);
            return new Blob([response.data]);
        }).then((blob: any) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a') as any;
            link.href = url;
            link.setAttribute('download', `${group.name.split(" ").join("_")}.xls`);
            document.body.appendChild(link);
            link.click();
            if (link !== null) link.parentNode.removeChild(link);
        }).catch(() => {
            message.error("Kļūda");
            setLoading(false);
        })
    }

    const cols = [
        {
            title: "Students",
            dataIndex: "full_name",
            key: "full_name",
        },
        {
            title: "Apmaksa",
            dataIndex: "paid_pmp",
            key: "paid",
            render: (paid: any) => {
                // if (!record) return;
                // console.log("record", record)
                // const paid = record.paid_pmp?.find((el: any) => {
                //     return el.id === group.id
                // })
                return paid?.paid
                    ? <Tag color="green">Apmaksāts</Tag>
                    : <Tag color="red">Neapmaksāts</Tag>
            }
        },
        {
            title: "P.K.",
            dataIndex: "personal_identity_number",
            key: "personal_identity_number"
        },
        {
            title: "E-pasts",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Tālruņa nr.",
            dataIndex: "phone_number",
            key: "phone_number"
        }
    ] as any;

    const deleteColumn = {
        title: "",
        dataIndex: "id",
        key: "delete",
        width: 40,
        align: "center",
        render: (record: any, all: any) => {
            return <Button
                type="text"
                danger
                style={{ fontSize: 16 }}
                onClick={() => removeStudent(record)}
                icon={<DeleteOutlined />}
            ></Button>
        }
    }

    return (
        <Layout>
            <PageHeader
                title="Pirmās palīdzības mācību grupas"
                onBack={props.history.goBack}
                style={{ backgroundColor: "#FFFFFF", width: "100%" }}
            />
            {loaded
                ? <Row>
                    <Col span={24} style={{ padding: 15 }}>
                        <Card>
                            <Form
                                layout="vertical"
                                onFinish={saveGroup}
                                initialValues={
                                    {
                                        name: group.name,
                                        medical_date: moment(group.medical_date, "YYYY-MM-DD"),
                                        online_date: moment(group.online_date, "YYYY-MM-DD"),
                                        medical_from_to: [
                                            moment(group.medical_from, "HH:mm:ss"),
                                            moment(group.medical_to, "HH:mm:ss"),
                                        ],
                                        online_from_to: [
                                            moment(group.online_from, "HH:mm:ss"),
                                            moment(group.online_to, "HH:mm:ss"),
                                        ],
                                        comment: group.comment,
                                        slots: group.slots,
                                        admin_comment: group.admin_comment,
                                        zoom_link: group.zoom_link,
                                        zoom_info: group.zoom_info,
                                        address: group.address
                                    }
                                }
                            >
                                <Row>
                                    <Col span={24} style={{ padding: "0 20px" }}>
                                        <Form.Item name="name" label="Grupas nosaukums" style={{ maxWidth: 200 }}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Card style={{ margin: "0 20px 20px" }} title="1. Teorētiskā nodarbība">
                                            <Row gutter={16}>
                                                <Col>
                                                    <Form.Item name="online_date" label="Datums">
                                                        <DatePicker />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item name="online_from_to" label="Nodarbības laiks">
                                                        <TimePicker.RangePicker format="HH:mm" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="zoom_link" label="Zoom saite">
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="zoom_info" label="Zoom papildus informācija">
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card style={{ margin: "0 20px 20px" }} title="1. Praktiskā nodarbība">
                                            <Row gutter={16}>
                                                <Col>
                                                    <Form.Item name="medical_date" label="Datums">
                                                        <DatePicker />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item name="medical_from_to" label="Nodarbības laiks">
                                                        <TimePicker.RangePicker format="HH:mm" />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item name="slots" label="Studentu skaits">
                                                        <InputNumber max={15} min={1} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="address" label="Adrese">
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col span={12} style={{ padding: "0 20px" }}>
                                        <Form.Item name="comment" label="Komentārs">
                                            <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ padding: "0 20px" }}>
                                        <Form.Item name="admin_comment" label="Sistēmas komentārs">
                                            <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Button style={{ margin: "0 20px", marginBottom: 8 }} htmlType="submit" type="primary">Saglabāt</Button>
                            </Form>
                            <Row>
                                <Col span={24} style={{ padding: 15 }}>
                                    <Card title="Apvienošana ar kategorijas PMP grupu" loading={loading}>
                                        {
                                            group?.study_group
                                            && <p style={{ marginBottom: 10 }}>Grupa: <b>{moment(group?.study_group?.group?.start_date).format("DD.MM.YYYY")} {group.study_group.cities.join(", ")}</b></p>
                                        }
                                        <Row align="middle">
                                            <DatePicker
                                                onChange={fetchGroups}
                                            />
                                            {
                                                loadingGroups && <Spin size={"small"} style={{ marginLeft: 6 }} />
                                            }
                                            <Select
                                                style={{ width: 500, marginLeft: 30, marginRight: 10 }}
                                                placeholder="Izvēlieties grupu"
                                                onChange={(value: any) => setChosenGroup(value)}
                                                loading={loadingGroups}
                                            >
                                                {
                                                    studyGroups.map((el: any) => {
                                                        return <Select.Option key={el.id} value={el.id}>
                                                            {
                                                                el?.cities?.map((city: any) => city.value).join(", ")
                                                            }
                                                            {" " + (el.start_date ? moment(el.start_date).format("DD.MM.YYYY") : "")}
                                                        </Select.Option>
                                                    })
                                                }
                                            </Select>
                                            <Button
                                                type="primary"
                                                onClick={joinGroup}
                                            >
                                                Apvienot
                                            </Button>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Card
                                title={<Row>
                                    <h3>Studenti</h3>
                                    <Button
                                        type="primary"
                                        style={{ marginLeft: 20 }}
                                        onClick={exportStudents}
                                        loading={loading}
                                    >
                                        Eksportēt
                                    </Button>
                                </Row>}
                            >
                                <h3>PMP grupā</h3>
                                <Table
                                    rowKey={record => record.id}
                                    columns={[...cols, deleteColumn]}
                                    dataSource={group?.combined_students?.medical_group_students ?? []}
                                />
                                <h3>Mācību grupā</h3>
                                <Table
                                    rowKey={record => record.id}
                                    columns={cols}
                                    dataSource={group?.combined_students?.study_group_students ?? []}
                                />
                            </Card>
                        </Card>
                    </Col>
                </Row>
                : <Layout style={{ marginLeft: 200, width: "100%", height: "100%" }}><Skeleton /></Layout>
            }
        </Layout>
    )
})