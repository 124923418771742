import { Button } from "antd";
import React, { useState, useEffect } from "react";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";


export const ImgUploader = (props: any) => {

    // const [image, setImage] = useState("" as any);
    // const [imageURL, setImageURL] = useState(
    //     props.img.length > 0
    //         ? process.env.REACT_APP_API_URL + "storage/study_group_types/" + props.img as any
    //         : null
    // );

    const triggerupload = (link: string) => {
        document.getElementById(link)?.click();
    }

    const getFile = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            props.setImg(reader.result)
            // setImageURL(reader.result)
            console.log(reader.result)
        };
        reader.onerror = (error) => {
            console.log("ERROR ", error)
        }
    }

    useEffect(() => {
        console.log("img", props.img)
    }, [props.img]);

    // useEffect(() => {
    //     if (!props.img.includes(process.env.REACT_APP_API_URL)) {
    //         props.setImg(imageURL)
    //     }
    // }, [imageURL]);

    return <>
        {
            !props.disableButtons
            && <Button
                style={{ marginBottom: 10 }}
                icon={<UploadOutlined />}
                onClick={() => triggerupload(`upload-extra-img${props.uniqueId ?? ""}`)}
            >
                {
                    props.img && props.img !== undefined
                        ? "Mainīt attēlu"
                        : "Pievienot attēlu"
                }
            </Button>
        }
        {
            props.img && props.img !== undefined && props.img.length > 0
                ? !props.disableButtons
                && <Button
                    danger
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                        // setImageURL("");
                        props.img.includes("base64")
                            ? props.setImg(null)
                            : props.setImg(undefined);
                    }}
                >
                    <DeleteOutlined />
                </Button>
                : <></>
        }

        <input
            type="file"
            id={`upload-extra-img${props.uniqueId ?? ""}`}
            style={{ display: "none" }}
            accept="image/*"
            onChange={(e: any) => {
                e.persist();
                getFile(e);
                e.target.value = "";
            }}
        />
        {
            (props.img && props.img !== undefined && props.img?.length > 0)
            && <div className="question-img-wrap" style={{ ...props.style }}>
                <img
                    style={{ ...props.imgStyle }}
                    src={
                        props.img.includes("base64")
                            ? props.img
                            : process.env.REACT_APP_API_URL + "storage/" + (props.preUrl ?? "") + props.img
                    }
                />
            </div>

        }
    </>
}