import { Button, Card, message, Row, Table } from "antd"
import React, { useEffect, useState } from "react"
import { getColumnSearchProps } from "../../../helpers/getColumnSearchProps";
import { http } from "../../../helpers/http";

export const FailedTransfers = () => {
    const [data, setData] = useState([] as any);
    const [pagination, setPagination] = useState({} as any);
    const [loading, setLoading] = useState(false);

    const fetchFailedTransactions = (params: any = { pagination: { current: 1 } }) => {
        setLoading(true);
        http.get(`rest/klix-bad-transactions`, { params: getParams(params) }).then((res: any) => {
            setLoading(false);
            setData(res.data.data)
            setPagination({
                current: Number(res.data.page),
                pageSize: 15,
                total: res.data.total
            })
        }).catch(() => {
            message.error("Kļūda!")
            setLoading(false);
        })
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const filtersCopy = { ...filters }
        if (filtersCopy['kt.sum']) {
            filtersCopy['kt.sum'] = filtersCopy['kt.sum'][0]
        }
        fetchFailedTransactions({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters: filtersCopy,
        });
    };

    const getParams = (params: any) => {
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters
        }
    };

    const checkTransaction = (id: any) => {
        http.get(`rest/klix-bad-transaction/${id}`).then((res: any) => {
            console.log(res)
            if (res.data.data.result) {
                message.success(res.data.data.status)
            } else {
                message.error(res.data.data.status)
            }
        }).catch((err: any) => {
            message.error("Kļūda!")
            console.log(err)
        })
    };

    const columns = [
        {
            title: 'Datums',
            dataIndex: 'updated_at',
            key: 'kt.updated_at',
            render: (record: any) => {
                return record?.split(" ")[0]
            },
            ...getColumnSearchProps("calendar")
        },
        {
            title: 'Summa',
            dataIndex: 'sum',
            key: 'kt.sum',
            render: (amount: any) => `${amount} EUR`,
            ...getColumnSearchProps()
        },
        {
            title: 'Klients',
            dataIndex: 'student',
            key: 'student',
            ...getColumnSearchProps()
        },
        {
            title: 'Tālrunis',
            dataIndex: 'phone_number',
            key: 'st.phone_number',
            ...getColumnSearchProps()
        },
        {
            title: 'Epasts',
            dataIndex: 'email',
            key: 'st.email',
            ...getColumnSearchProps()
        },
        {
            key: 'student_payment_id',
            render: (item: any) =>
                <Button
                    onClick={() => checkTransaction(item?.transaction_id)}
                >
                    Pārbaudīt transakciju
                </Button>
        }
    ]

    useEffect(() => {
        fetchFailedTransactions();
    }, []);

    return (
        <Card style={{ marginTop: 30 }}>
            <Table
                rowKey={record => record.student_payment_id}
                dataSource={data}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                loading={loading}
                expandable={{
                    // rowExpandable: (record: any) => {
                    //     return record.input !== null
                    // },
                    expandedRowRender: (record: any) => {
                        return <div>
                            <p><b>Input</b></p>
                            {record.input}
                            <p><b>Atbilde</b></p>
                            {record.response}
                        </div>
                    }
                }}
                scroll={{ y: 756 }}
            />
        </Card>
    )
}