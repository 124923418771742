import { message, Layout, PageHeader, Card, Table, Spin, Button, Modal } from "antd";
import { AxiosResponse } from "axios";
import moment from "moment";
import React, { Key, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../helpers/http";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";

export const SyncErrorsPage = () => {
    const history = useHistory();
    const [pagination, setPagination] = useState({
        current: 1,
        total: 1,
        pageSize: 15
    });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([] as any);
    const [expandedRows, setExpandedRows] = useState([] as any);
    const [schools, setSchools] = useState([] as any);
    const [tableState, setTableState] = useState({ pagination: { current: 1 }, filters: {}, sorter: {} } as any);

    const getErrors = (params: any = { pagination: { current: 1 } }) => {
        setExpandedRows([]);
        setLoading(true);
        http.get(`/rest/csdd-api-errors`, {
            params: {
                page: params.pagination.current,
                filters: params.filters
                // student: filters.student,
                // category: filters.category,
                // failures: filters.failures,
                // date_from: filters.date_range[0].format("YYYY-MM-DD"),
                // date_to: filters.date_range[1].format("YYYY-MM-DD"),
            }
        }).then((res: AxiosResponse) => {
            console.log(res)
            setData(res.data.data.map((error: any, index: number) => {
                return {
                    ...error,
                    ind: index
                }
            }));
            setSchools(res.data.schools);
            setPagination({
                current: res.data.page,
                total: res.data.total,
                pageSize: 15
            })
            setLoading(false);
        }).catch(() => {
            message.error("Kļūda!");
            setLoading(false);
        })
    }

    const processApiError = (id: number) => {
        Modal.confirm({
            title: "Vai tiešām atzīmēt kļūdu kā apstrādātu?",
            onOk: () => {
                http.get(`rest/csdd-api-error-processed/${id}`).then((res: AxiosResponse) => {
                    message.success("Kļūda apstrādāta!");
                    getErrors({
                        pagination: tableState.pagination,
                        filters: tableState.filters,
                        sorter: tableState.sorter
                    });
                }).catch(() => {
                    message.error("Kļūda!");
                })
            }
        })
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(filters);
        setTableState({ pagination, filters, sorter });
        getErrors({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters,
        });
    };

    const getSyncErrors = (record: any) => {
        http.get(`/rest/get-csdd-api-error/${record.csdd_nr}/${record.personal_identity_number}/${record.external_id}`).then((res: AxiosResponse) => {
            setData(data.map((error: any) => {
                if (error.ind === record.ind) {
                    return {
                        ...error,
                        sync_errors: res.data
                    }
                }
                return error;
            }))
            console.log(res)
        }).catch(() => {
            message.error("Kļūda!");
        })
    }

    useEffect(() => {
        getErrors();
    }, [])

    const columns = [
        {
            title: "Skola",
            dataIndex: "school",
            key: "school_id",
            render: (school: any, record: any) => {
                return <b>{school ?? "Einšteins"}</b>
            },
            filters: schools?.map((school: any) => {
                return {
                    text: school.name,
                    value: school.id
                }
            }).concat({ text: "Einšteins", value: null }),
        },
        {
            title: "Vārds",
            dataIndex: "first_name",
            key: "first_name",
            ...getColumnSearchProps(),
            render: (first_name: string, record: any) => {
                return <Button
                    type="link"
                    onClick={() => window.open(`/students/edit/${record.student_id}`, "_blank")}
                >{first_name}</Button>
            }
        },
        {
            title: "Uzvārds",
            dataIndex: "last_name",
            key: "last_name",
            ...getColumnSearchProps()
        },
        {
            title: "Pers.k.",
            dataIndex: "personal_identity_number",
            key: "personal_identity_number",
            ...getColumnSearchProps()
        },
        {
            title: "E-pasts",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps()
        },
        {
            title: "Tālr.Nr.",
            dataIndex: "phone_number",
            key: "phone_number",
            ...getColumnSearchProps()
        },
        {
            title: "Modulis",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps()
        },
        {
            title: "Kategorija",
            dataIndex: "code",
            key: "code",
            ...getColumnSearchProps()
        },
        {
            title: "CSDD NR.",
            dataIndex: "csdd_nr",
            key: "csdd_nr",
            ...getColumnSearchProps()
        },
        {
            title: "Izveidots",
            dataIndex: "created_at",
            key: "created_at",
            ...getColumnSearchProps('calendar')
        },
        {
            title: "Atjaunots",
            dataIndex: "updated_at",
            key: "updated_at",
            ...getColumnSearchProps('calendar')
        }
    ] as any;

    return <Layout>
        <PageHeader
            title="CSDD moduļu sinhronizācijas kļūdas"
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF" }}
        />
        <Card>
            <Table
                rowKey={record => record.ind}
                dataSource={data}
                columns={columns as any}
                pagination={{
                    showSizeChanger: false,
                    size: 'small',
                    showTotal: (total) => <div>Kopā {total} kļūdas</div>,
                    position: ['bottomCenter'],
                    pageSizeOptions: ['15'],
                    defaultPageSize: 15,
                    ...pagination
                }}
                onChange={handleTableChange}
                loading={loading}
                expandable={{
                    expandedRowKeys: expandedRows,
                    expandedRowRender: (record: any) => {
                        return record?.hasOwnProperty("sync_errors")
                            ? <div>
                                <p>{JSON.stringify(record?.sync_errors)}</p>
                                <Button
                                    type="primary"
                                    onClick={() => processApiError(record.id)}
                                >
                                    Apstrādāts
                                </Button>
                            </div>
                            : <Spin />
                    },
                    onExpand(expanded, record) {
                        if (expanded) {
                            setExpandedRows([...expandedRows, record.ind]);
                            getSyncErrors(record)
                        } else {
                            setExpandedRows(expandedRows.filter((key: Key) => key !== record.ind))
                        }
                    },
                }}
                scroll={{ x: "100%" }}
            />
        </Card>
    </Layout>
}