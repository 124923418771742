import React from "react";
import { Card, Col, Layout, PageHeader, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useHistory } from "react-router";
import { BooksShippingTable } from "./BooksShippingTable";

export const BooksShipping = (props: any) => {
  const history = useHistory();

  return (
    <Layout>
      <PageHeader
        style={{ backgroundColor: "#FFFFFF" }}
        title={"Grāmatas uz omniva"}
        onBack={history.goBack}
      />
      <Card style={{ width: "100%" }} className="crm-card">
        <Row>
          <Col span={24}>
            <Row justify="center">
              <BooksShippingTable paid={true} />
              <BooksShippingTable paid={false} />
            </Row>
          </Col>
        </Row>
      </Card>
    </Layout>
  )
}