import { Tag } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";

export const AgeCheck = (props: any) => {
    const { studentID, birthdate, category } = props;
    const [age, setAge] = useState({} as any);
    const [loaded, setLoaded] = useState(false);

    const checkAgeValidity = () => {
        http.get(`rest/valid-theory-exam-age/${studentID}`, { params: { category } }).then((response: any) => {
            console.log(response)
            setLoaded(true)
            setAge(response.data)
        })
    }

    useEffect(() => {
        checkAgeValidity();
    }, [birthdate])

    return !!age.valid
        ? <></>
        : loaded ? <div style={{ marginBottom: 5 }}>
            <Tag color="red">
                Students nav sasniedzis minimālo vecumu eksāmena kārtošanai.
            </Tag>
        </div>
            : <></>
}