import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosResponse } from 'axios';
import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

type PaymentNotificListProps = {
    id: string;
};

type PaymentNotification = {
    id: string;
    message: string;
    read_at: string | null;
    created_at: string | null;
}

export const PaymentNotificList = ({ id }: PaymentNotificListProps) => {
    const [paymentNotifications, setPaymentNotifications] = useState<PaymentNotification[] | []>([]);

    const getNotifications = () => {
        http.get(`rest/student-db-notifications/${id}`).then((res: AxiosResponse) => {
            console.log(res)
            setPaymentNotifications(formatResponseData(res.data.data))
        })
    };

    const formatResponseData = (data: any): PaymentNotification[] => {
        return data.map((item: any): PaymentNotification => {
            return {
                id: item.id,
                message: item.data.message,
                created_at: item.created_at ? moment(item.created_at.split("T")[0], "YYYY-MM-DD").format("DD.MM.YYYY") : null,
                read_at: item.read_at ? moment(item.read_at.split("T")[0], "YYYY-MM-DD").format("DD.MM.YYYY") : null,
            }
        })
    };

    const setReadUnread = (id: PaymentNotification['id'], unread: boolean): void => {
        http.post(`rest/change-student-db-notifications/${id}`, unread ? {} : { read_at: moment().format("YYYY-MM-DD") }).then((res: any) => {
            getNotifications();
        })
    };

    const columns: ColumnsType<PaymentNotification> = [
        {
            title: "Paziņojums",
            dataIndex: "message"
        },
        {
            title: "Izveidots",
            dataIndex: "created_at"
        },
        {
            title: "Lasīts",
            dataIndex: "read_at"
        },
        {
            title: "Darbības",
            dataIndex: "read_at",
            render: (read: PaymentNotification['read_at'], record: PaymentNotification): React.ReactNode => {
                return read
                    ? <Button
                        type="primary"
                        onClick={() => setReadUnread(record.id, true)}
                    >
                        Nosūtīt atkārtoti
                    </Button>
                    : <Button
                        onClick={() => setReadUnread(record.id, false)}
                    >
                        atzīmēt kā lasītu
                    </Button>
            }
        },
    ];

    useEffect(() => {
        getNotifications();
    }, [])

    return <div>
        <Table
            rowKey="id"
            columns={columns}
            dataSource={paymentNotifications}
        />
    </div>
}