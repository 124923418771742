import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";

export const ProtectedRoute = ({ children, ...rest }: any) => {
    const { permissions, accept } = rest;

    const [allowed, setAllowed] = useState(true);

    useEffect(() => {
        setAllowedHandler();
    }, [])

    const setAllowedHandler = () => {
        const allow = permissions.ruler_of_universe || permissions.perms.some((perm: any) => {
            return perm === accept.find((el: any) => el === perm)
        }) || accept.some((el: string) => el === 'all');
        setAllowed(allow);
    }

    return (
        allowed
            ? <Route {...rest} render={(props: any) => {
                return children
            }} />
            : <Redirect to="/" />
    )
}