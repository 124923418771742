import { PageHeader } from "antd";
import React from "react";
import { useHistory, withRouter } from "react-router-dom";

export const InstructorInvoice = withRouter((props: any) => {
  const history = useHistory();

  return (
    <>
    <PageHeader
      title={`Rēķins Nr: ${props.match.params.id}`}
      onBack={ () => history.goBack() }
    />
    </>
  )
})