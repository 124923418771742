import { Button, Card, Checkbox, Col, Input, Modal, Radio, Row, Select, message } from 'antd'
import React, { useEffect, useState } from 'react'
import Answer from './Answer';
import { http } from '../../../helpers/http';
import { ImgUploader } from '../../Elearning/components/ImgUploader';
import { VideoUploader } from '../../Elearning/video/VideoUploader';
import OnlyVideoUploader from '../../../components/OnlyVideoUploader';

export type Question = {
    active?: 1 | 0;
    answers?: { [key in "en" | "lv" | "ru"]: any };
    category_id?: number;
    csdd_question_category_id?: null | number;
    data_hash?: string;
    media?: string;
    media_hash?: string;
    img?: null | string;
    public_image?: string | null;
    question_en?: string | null;
    question_lv?: string | null;
    question_ru?: string | null;
    source?: string;
    video?: 1 | 0;
    video_source?: string;
    bought_question?: 1 | 0;
    demo_question?: 1 | 0;
}

type QuestionCardProps = {
    question: Question;
    savedQuestion?: (question: Question) => void;
    categories: any;
    themes: any;
}

function QuestionCard(props: QuestionCardProps) {
    const { question, categories, themes } = props;
    const [modifiedQuestion, setModifiedQuestion] = useState<Question>(question);


    const saveModifiedQuestion = () => {
        Modal.confirm({
            title: "Vai tiešām vēlaties saglabāt izmaiņas?",
            onOk: () => {
                http.post(`rest/csdd-test-store`, modifiedQuestion).then((res) => {
                    if (modifiedQuestion?.data_hash === undefined && props.savedQuestion) {
                        props.savedQuestion(res.data.data)
                    }
                    message.success("Jautājums saglabāts")
                }).catch((err) => {
                    console.log(err)
                });
            }
        })
    }

    return <Card>
        <Row gutter={[20, 20]}>
            <Col span={16}>
                <div style={{ marginBottom: 40 }}>
                    <b>Aktīvs:</b>
                    <Checkbox
                        style={{ marginLeft: 8 }}
                        checked={!!modifiedQuestion?.active}
                        onChange={(e) => {
                            setModifiedQuestion((prev: Question) => {
                                return {
                                    ...prev,
                                    active: e.target.checked ? 1 : 0
                                }
                            })
                        }}
                    />
                    <div>
                        <b>Pirkts:</b>
                        <Checkbox
                            style={{ marginLeft: 8 }}
                            checked={!!modifiedQuestion?.bought_question}
                            onChange={(e) => {
                                setModifiedQuestion((prev: Question) => {
                                    return {
                                        ...prev,
                                        bought_question: e.target.checked ? 1 : 0
                                    }
                                })
                            }}
                        />
                    </div>
                    <div>
                        <b>Demo jautājums:</b>
                        <Checkbox
                            style={{ marginLeft: 8 }}
                            checked={!!modifiedQuestion?.demo_question}
                            onChange={(e) => {
                                setModifiedQuestion((prev: Question) => {
                                    return {
                                        ...prev,
                                        demo_question: e.target.checked ? 1 : 0
                                    }
                                })
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                        <p><b>Jautājums LV</b></p>
                        <Input
                            placeholder="Question LV"
                            value={modifiedQuestion?.question_lv ?? undefined}
                            onChange={(e) => {
                                e.persist();
                                setModifiedQuestion((prev: Question) => {
                                    return {
                                        ...prev,
                                        question_lv: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                    <div>
                        <p><b>Jautājums EN</b></p>
                        <Input
                            placeholder="Question EN"
                            value={modifiedQuestion?.question_en ?? undefined}
                            onChange={(e) => {
                                e.persist();
                                setModifiedQuestion((prev: Question) => {
                                    return {
                                        ...prev,
                                        question_en: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                    <div>
                        <p><b>Jautājums RU</b></p>
                        <Input
                            placeholder="Question RU"
                            value={modifiedQuestion?.question_ru ?? undefined}
                            onChange={(e) => {
                                e.persist();
                                setModifiedQuestion((prev: Question) => {
                                    return {
                                        ...prev,
                                        question_ru: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                    <div>
                        <p><b>Jautājuma tēma:</b></p>
                        <Select
                            style={{ width: "100%" }}
                            value={modifiedQuestion?.csdd_question_category_id ?? undefined}
                            onChange={(value) => {
                                setModifiedQuestion((prev: Question) => {
                                    return {
                                        ...prev,
                                        csdd_question_category_id: value
                                    }
                                })
                            }}
                        // disabled={modifiedQuestion?.data_hash !== undefined}
                        >
                            {
                                themes?.map((theme: any) => {
                                    return <Select.Option key={theme.id} value={theme.id}>{theme.name_lv}</Select.Option>
                                })
                            }
                        </Select>
                    </div>
                </div>
                {
                    modifiedQuestion?.public_image !== null
                        ? !!modifiedQuestion?.video && !!!modifiedQuestion?.video_source
                            ? <div style={{ width: "100%", }}>
                                <video
                                    src={process.env.REACT_APP_API_URL! + modifiedQuestion?.public_image}
                                    controls={true}
                                    style={{ objectFit: "cover", width: "100%" }}
                                />
                            </div>
                            : <></>
                        : <></>
                }
                <ImgUploader
                    // disableButtons
                    style={{ width: "100%", height: "auto" }}
                    imgStyle={{ width: "100%", height: "auto", objectFit: "contain" }}
                    img={modifiedQuestion?.img
                        ? modifiedQuestion?.img
                        : !!modifiedQuestion?.video
                            ? undefined
                            : modifiedQuestion?.public_image?.split("storage/")[1]}
                    setImg={(img: any) => {
                        setModifiedQuestion((prev: Question) => {
                            return {
                                ...prev,
                                img: img,
                                public_image: img === undefined ? img : prev.public_image,
                                delete_image: img === undefined ? 1 : 0,
                                video: 0,
                                video_source: undefined
                            }
                        })
                    }}
                    uniqueId={question?.data_hash ?? "new-question"}
                />
                {
                    modifiedQuestion?.video_source
                    && <div style={{ width: "100%" }}>
                        <video
                            src={process.env.REACT_APP_API_URL! + `storage/exam_tests/${modifiedQuestion?.video_source}`}
                            controls={true}
                            style={{ objectFit: "cover", width: "100%" }}
                        />
                    </div>
                }
                <OnlyVideoUploader
                    change={!!modifiedQuestion?.video_source || modifiedQuestion?.video === 1}
                    uploadUrl={process.env.REACT_APP_API_URL + "admin-api/rest/csdd-test-upload-video"}
                    uploadProps={{}}
                    setProgress={(progress: any) => {
                        console.log(progress)
                    }}
                    uniqueId={question.data_hash ?? "new-question"}
                    returnResponseOnSuccess={(response: any) => {
                        setModifiedQuestion((prev: any) => {
                            return {
                                ...prev,
                                video_source: response?.path,
                                video: 1,
                                img: undefined,
                                public_image: undefined,
                                media: undefined,
                                media_hash: undefined
                            }
                        })
                    }}
                />
                <p><b>Kategorija:</b></p>
                <Select
                    style={{ width: 200 }}
                    value={modifiedQuestion?.category_id}
                    onChange={(value) => {
                        setModifiedQuestion((prev: Question) => {
                            return {
                                ...prev,
                                category_id: value
                            }
                        })
                    }}
                    disabled={modifiedQuestion?.data_hash !== undefined}
                >
                    {
                        categories?.map((category: any) => {
                            return <Select.Option key={category.id} value={category.id}>{category.value}</Select.Option>
                        })
                    }
                </Select>
            </Col>
            <Col span={8}>
                {
                    modifiedQuestion?.answers
                    && Object.keys(modifiedQuestion?.answers).map((key: string, index: number) => {
                        return <Card key={index} style={{ marginBottom: 10 }}>
                            <Row justify='space-between' style={{ marginBottom: 5 }}>
                                <b>{key.toUpperCase()}</b>
                                <Button
                                    type="primary"
                                    onClick={() => setModifiedQuestion((prev: any) => {
                                        const newAnswers = prev.answers;
                                        newAnswers[key].push({
                                            answer: "",
                                            correct: 0
                                        })
                                        return {
                                            ...prev,
                                            answers: newAnswers
                                        }
                                    })}

                                >
                                    +
                                </Button>
                            </Row>

                            {
                                modifiedQuestion?.answers
                                && modifiedQuestion?.answers[key as "en" | "lv" | "ru"].map((answer: any, index: number) => {
                                    return <Answer
                                        key={index}
                                        ind={index}
                                        answer={answer}
                                        locale={key as "lv" | "en" | "ru"}
                                        modifyAnswer={(modifiedAnswer: any) => {
                                            setModifiedQuestion((prev: any) => {
                                                const answers = {
                                                    ...prev!.answers,
                                                    [key]: prev!.answers![key].map((a: any, ind: number) => {
                                                        if (ind === index) {
                                                            return modifiedAnswer
                                                        }
                                                        return a
                                                    })
                                                }
                                                if (!!modifiedAnswer.correct) {
                                                    answers[key] = answers[key].map((a: any, ind: any) => {
                                                        if (ind !== index) {
                                                            return {
                                                                ...a,
                                                                correct: 0
                                                            }
                                                        }
                                                        return a
                                                    })
                                                }
                                                return {
                                                    ...prev,
                                                    answers
                                                }
                                            })
                                        }}
                                        removeAnswer={(ind: any, id: number | undefined = undefined) => {
                                            if (id) {
                                                Modal.confirm({
                                                    title: "Vai tiešām vēlaties dzēst atbildi?",
                                                    onOk: () =>

                                                        http.get(`rest/csdd-test-delete-answer/${id}`).then((res) => {
                                                            console.log(res)
                                                            setModifiedQuestion((prev: any) => {
                                                                const newAnswers = prev.answers;
                                                                newAnswers[key].splice(ind, 1);
                                                                return {
                                                                    ...prev,
                                                                    answers: newAnswers
                                                                }
                                                            })
                                                            return
                                                        }).catch((err) => {
                                                            console.log(err)
                                                        }),
                                                    onCancel: () => { }
                                                })
                                                return;
                                            }
                                            setModifiedQuestion((prev: any) => {
                                                const newAnswers = prev.answers;
                                                newAnswers[key].splice(ind, 1);
                                                return {
                                                    ...prev,
                                                    answers: newAnswers
                                                }
                                            })
                                        }}
                                    />
                                })
                            }
                        </Card>
                    })
                }
            </Col>
        </Row>
        <Row justify='end'>
            <Button
                type="primary"
                onClick={saveModifiedQuestion}
            >
                Saglabāt
            </Button>
        </Row>
    </Card >
}

export default QuestionCard