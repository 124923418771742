import React, { useEffect, useState } from "react";
import { Button, Card, Col, DatePicker, Form, Input, message, Rate, Row, Select, Spin, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { http } from "../../helpers/http";
import { set } from "lodash";
import moment from "moment";

const { TabPane } = Tabs;

export const FeedbackForm = (props: any) => {
    const { t } = useTranslation();
    const [target, setTarget] = useState("" as any);
    const [data, setData] = useState({} as any);
    const [cantFindCategory, setCantFindCategory] = useState(false);
    const [form] = Form.useForm();

    const submitForm = (values: any) => {
        values.post_date = moment(values.post).format("YYYY-MM-DD")
        delete values.post;
        values.from_system = 1;
        values.show_system = values.publication_type?.some((el: any) => el === "system") ? 1 : 0;
        values.show_web = values.publication_type?.some((el: any) => el === "web") ? 1 : 0;
        delete values.publication_type;
        console.log(values);
        if (values.category_id === 0) {
            values.category_id = null;
        }
        if (values.category_id > 0) {
            values.category = null;
        }
        if (data.id) {
            if (values.target === "lecturer") {
                values.instructor_id = null;
            }
            if (values.target === "instructor") {
                values.lecturer_id = null;
            }
            if (values.target === "school") {
                values.lecturer_id = null;
                values.instructor_id = null;
            }

            http.patch(`/rest/public-comments/${data.id}`, values).then((response: any) => {
                console.log(response);

                message.success('Atsauksme ir saglabāta')
                props.refetch();
            }).catch(() => {
                message.error("Saglabāšanas kļūda");
            })
        }
        else {
            http.post(`/rest/public-comments`, values).then((response: any) => {
                // console.log(response);
                // formatSaveData(response.data)
                props.formVisible(false)
                message.success('Atsauksme ir saglabāta')
                props.refetch();
            }).catch(() => {
                message.error("Saglabāšanas kļūda");
            })
        }

    }

    useEffect(() => {
        formatSaveData(props.data);
        setTarget(props.data?.target);
    }, [props.data])

    const formatSaveData = (data: any) => {
        const newData = {
            ...data,
            publication_type: []
        }
        if (data.show_system) {
            newData.publication_type.push("system")
        }
        if (data.show_web) {
            newData.publication_type.push("web")
        }
        console.log(newData)
        setData(newData)
    }

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(data);
        if (data.category_id === 0) {
            setCantFindCategory(true);
        }
    }, [data])

    const setCategoryHandler = (e: any) => {
        if (e === 0) {
            setCantFindCategory(true);
        } else {
            setCantFindCategory(false);
        }
    }

    return (
        <Card style={{ marginTop: 20 }}>
            {/* <Button onClick={() => console.log(fetch())}>Test Fetch</Button> */}
            <Form
                layout="vertical"
                onFinish={submitForm}
                initialValues={
                    {
                        ...props.data,
                        post: props.data.post_date === undefined ? undefined : moment(props.data.post_date, "YYYY-MM-DD")
                    }
                }
                form={form}
            >
                <Card
                    title="Lietotājs"
                >
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="name" label="Vārds" rules={[{ required: true, message: "Obligāts lauks" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="surname" label="Uzvārds" rules={[{ required: true, message: "Obligāts lauks" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="email" label="e-pasts" rules={[{ required: true, message: "Obligāts lauks" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="city_id" label="Pilsēta" >
                                <Select>
                                    {props.cities?.map((city: any) => {
                                        return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card
                    style={{ marginTop: 20 }}
                    title="Mērķis"
                >
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="target" label=" ">
                                <Select onChange={setTarget}>
                                    {props.targets.map((item: any) => {
                                        return <Select.Option key={item} value={item}>{t(`label.${item}`)}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            {
                                target !== "school" && target !== ""
                                    ? <Form.Item name={`${target}_id`} label="Vārds Uzvārds">
                                        <Select
                                            showSearch
                                            filterOption={((input: any, option: any) => {
                                                return option && option.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                            }) as any}
                                        >
                                            {
                                                target === "lecturer" ? props.lecturers.map((item: any) => {
                                                    return <Select.Option key={item.id} value={item.id}>{item.first_name} {item.last_name}</Select.Option>
                                                })
                                                    : target === "instructor" ? props.instructors.map((item: any) => {
                                                        return <Select.Option key={item.id} value={item.id}>{item.first_name} {item.last_name}</Select.Option>
                                                    })
                                                        : <></>
                                            }
                                        </Select>
                                    </Form.Item>
                                    : <></>
                            }
                        </Col>
                        <Col span={6}>
                            <Form.Item name="category_id" label="Kategorija">
                                <Select onChange={setCategoryHandler}>
                                    {props.categories?.map((category: any) => {
                                        return <Select.Option key={category.id} value={category.id}>{category.value}</Select.Option>
                                    })}
                                    <Select.Option value={0}>Nav sarakstā</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {
                            cantFindCategory
                                ? <Col span={6}>
                                    <Form.Item name="category" label=" ">
                                        <Input placeholder="Ierakstiet kategoriju" />
                                    </Form.Item>
                                </Col>
                                : <></>
                        }

                    </Row>
                </Card>
                <Card
                    style={{ marginTop: 20 }}
                    title="Komentārs"
                >
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="LV" key="1">
                            <Form.Item name="comment_lv" label="Komentārs latviešu valodā">
                                <Input.TextArea autoSize={{ maxRows: 5, minRows: 5 }} />
                            </Form.Item>
                        </TabPane>
                        <TabPane tab="EN" key="2">
                            <Form.Item name="comment_en" label="Komentārs angļu valodā">
                                <Input.TextArea autoSize={{ maxRows: 5, minRows: 5 }} />
                            </Form.Item>
                        </TabPane>
                        <TabPane tab="RU" key="3">
                            <Form.Item name="comment_ru" label="Komentārs krievu valodā">
                                <Input.TextArea autoSize={{ maxRows: 5, minRows: 5 }} />
                            </Form.Item>
                        </TabPane>
                    </Tabs>
                </Card>
                <Row>
                    <Row align="bottom" style={{ padding: "10px 0" }}>
                        <Form.Item
                            style={{ width: 180, margin: "0 20px" }}
                            name="rating"
                            label="Vērtējums"
                        >
                            <Rate />
                        </Form.Item>
                        <Form.Item
                            style={{ width: 180, margin: "0 20px" }}
                            name="publication_type"
                            label="Publicēt"
                        >
                            <Select mode="multiple">
                                <Select.Option value="web">WEB</Select.Option>
                                <Select.Option value="system">Sistēmā</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{ width: 180, margin: "0 20px" }}
                            name="post"
                            label="Publikācijas datums"
                        >
                            <DatePicker />
                        </Form.Item>
                        <Button type="primary" htmlType="submit">Apstiprināt</Button>
                    </Row>
                </Row>
            </Form>
        </Card>
    )
}