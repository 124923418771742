import { Layout, PageHeader, Card, Table, Button } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { http } from "../../helpers/http";
import { AxiosResponse } from "axios";

export const ModuleDetails = () => {
    const history = useHistory();
    const { id, cid, mid, lid } = useParams() as any;
    const [data, setData] = useState([] as any);

    const speedTranslation = {
        slow: "Lēns",
        medium: "Vidējs",
        fast: "Ātrs"
    }

    const newTabHandle = (e: any, id: number) => {
        e.stopPropagation();
        window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}students/edit/${id}`, "_blank");
    }

    const fetchData = () => {
        http.get(`rest/get-module-avg-detailed-mod/${id}/${cid}/${mid}`, { params: { language: lid } }).then((res: AxiosResponse) => {
            console.log(res)
            setData(res.data)
        })
    }

    const columns = [
        {
            title: "Students",
            dataIndex: "student",
            align: "center",
            width: 250,
            render: (student: string, record: any) => {
                return <>
                    <span>{student}</span>
                    <Button
                        type="link"
                        onClick={(e: any) => newTabHandle(e, record.student_id)}
                    >
                        <LinkOutlined />
                    </Button>
                </>
            }
        },
        {
            title: "Ātrums",
            dataIndex: "speed",
            align: "center",
            width: 250,
            render: (speed: any) => {
                return speedTranslation[speed as keyof typeof speedTranslation]
            }
        },
        {}
    ] as any

    useEffect(() => {
        fetchData();
    }, [])

    return <Layout>
        <PageHeader
            title="Online apmācības statistika"
            style={{ backgroundColor: "#FFFFFF" }}
            onBack={history.goBack}
        />
        <Card style={{ margin: 20 }}>
            <h2 style={{ textAlign: "center" }}>
                {
                    mid === "not_signed_to_lesson"
                        ? "Pabeiguši Online moduļus, nav pieteikušies klātienei"
                        : "Moduļa kopsavilkums"
                }
            </h2>
            <Table
                rowKey={record => record.student + record.speed}
                columns={columns}
                dataSource={data}
                expandable={{
                    rowExpandable: (record: any) => {
                        return record.finished_modules !== null || record.finished_modules.length > 0
                    },
                    expandedRowRender: (record: any) => {
                        return Object.keys(record.finished_modules).map((key: any, index: any) => {
                            return <div style={{ marginBottom: 8 }} key={index}>
                                <b>{Object.keys(record.finished_modules[key])[0]}: </b>
                                <b>{record.finished_modules[key][Object.keys(record.finished_modules[key])[0]]}</b>
                            </div>
                        })
                    }
                }}
            />
        </Card>
    </Layout>
}