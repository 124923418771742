import React from "react";
//@ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
//@ts-ignore
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export const TextEditor = (props: any) => {
    return (
        <div style={{ marginBottom: 30 }}>
            <div className={`document-editor ${props.lang}`}>
                <div className={`document-editor__toolbar ${props.lang}`}></div>
                <div className={`document-editor__editable-container ${props.lang}`}>
                    <CKEditor
                    config={{ 
                        removePlugins: ['BlockQuote', 'MediaEmbed']
                     }}
                        onReady={(editor: any) => {
                            const toolbarContainer = document.querySelector(`.document-editor__toolbar.${props.lang}`);
                            toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
                            const list = Array.from(editor.ui.componentFactory.names())
                        }}
                        onChange={(event: any, editor: any) => {
                            props.returnData(props.lang, editor.getData());
                        }}
                        editor={DecoupledEditor}
                        data={props.data}
                    />
                </div>
            </div>
        </div>
    )
}

