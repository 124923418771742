import { Button, Card, DatePicker, message, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";

export const ManualExamSignup = (props: any) => {
    const { studentId, category } = props;
    const [services, setServices] = useState([] as any);
    const [service, setService] = useState(null);
    const [slot, setSlot] = useState(null);
    const [slots, setSlots] = useState([] as any);
    const [date, setDate] = useState();

    const fetchServices = async () => {
        await http.get(`/rest/student-exam-services/${studentId}`, { params: { category: category.category_id } }).then((response: any) => {
            setServices(response.data.data);
        })
    }

    const dateChangeHandler = (e: any) => {
        setDate(e);
        if (service && e) {
            fetchSlots(service, e)
        }
    }

    const serviceSelectHandler = (e: any) => {
        setService(e);
        if (date) {
            fetchSlots(e, date);
        }
    }

    const fetchSlots = async (service: any, date: any) => {
        await http.post(`/rest/student-exam-slots/${service}`, { date: moment(date).format("YYYY-MM-DD"), category: category.category_id }).then((response: any) => {
            setSlots(response.data.data);
            setSlot(null);
        })
    }

    const signupForSlot = async () => {
        await http.get(`/rest/student-assing-exam-with-slot/${studentId}/${slot}`, { params: { category: category.category_id } }).then((response: any) => {
            message.success('Students ir veiksmīgi pievienots eksāmenam');
            window.location.reload();
        }).catch(() => {
            message.error("Kļūda")
        })
    }

    useEffect(() => {
        fetchServices();
    }, [])

    return (
        <Card style={{ marginBottom: 20 }} title={<>{"Pieteikt studentu eksāmena nodarbībai"} <b>{category?.category}</b> kategorijā</>}>
            <Select style={{ width: 300 }} onChange={serviceSelectHandler}>
                {services?.map((service: any) => {
                    return <Select.Option key={service.id} value={service.id}>
                        {service.first_name + " " + service.last_name}, {service.licence_plate}
                        <b>{service.transmission === "manual" ? " (M)" : " (A)"} {service.languages}</b>
                    </Select.Option>
                })}
            </Select>
            <DatePicker allowClear={false} style={{ marginLeft: 30 }} value={date} onChange={dateChangeHandler} />
            {
                slots.length > 0
                    ? <Select style={{ marginLeft: 30, width: 200 }} onChange={(e: any) => setSlot(e)}>
                        {slots.map((slot: any) => {
                            return <Select.Option key={slot.id} value={slot.id}>
                                {slot.time_from + " - " + slot.time_to}
                            </Select.Option>
                        })}
                    </Select>
                    : <></>
            }
            {
                slot !== null
                    ? <Button style={{ marginLeft: 30 }} type="primary" onClick={signupForSlot}>Pieteikt</Button>
                    : <></>
            }
        </Card>
    )
}