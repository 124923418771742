import { Button, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { http } from "../../helpers/http";

export const NotificationsLog = (props: any) => {
    const { studentId } = props;
    const [data, setData] = useState([] as any);
    const [notificationTypes, setNotificationTypes] = useState([] as any);
    const [categories, setCategories] = useState([] as any);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    })

    const channelTypes = {
        mail: "E-pasts",
        sms: "SMS",
        whatsapp: "Whatsapp",
        database: "Portālā",
        fcm: "Push (APP)"
    } as any;

    const fetchCategories = async () => {
        await http.get(`rest/categories`).then((response: any) => {
            setCategories(response.data.data)
        })
    }

    const fetchNotificationsTypes = async () => {
        await http.get(`rest/student-notification-types`).then((response: any) => {
            console.log(response.data)
            setNotificationTypes(response.data)
        })
    };

    const getNotificationsLog = async (params: any = { pagination }) => {
        await http.get(`rest/get-student-notification-log/${studentId}`, {
            params: {
                page: params.pagination.current,
                limit: params.pagination.pageSize,
                filters: params.filters,
                order_by: params.order_by,
                order_desc: params.order_desc
            }
        }).then((response: any) => {
            console.log(response)
            setData(response.data.data)
            setPagination({
                current: Number(response.data.page),
                pageSize: 15,
                total: Number(response.data.total)
            })
        })
    };

    const resendNotification = async (id: any) => {
        await http.post(`rest/resend-notification/${id}`).then((response: any) => {
            console.log(response);
            getNotificationsLog();
        })
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        console.log(pagination, sorter, filters)
        getNotificationsLog({
            pagination,
            filters,
            order_by: sorter.field,
            order_desc: sorter.order === "ascend"
                ? "asc"
                : sorter.order === "descend"
                    ? "desc"
                    : null
        })
    }

    useEffect(() => {
        fetchCategories();
        fetchNotificationsTypes();
        getNotificationsLog();
    }, []);

    const columns = [
        {
            title: "Kanāls",
            dataIndex: "channel",
            key: "channel",
            filters: Object.keys(channelTypes).map((key: any) => ({
                text: channelTypes[key], value: key
            })),
            render: (channel: any) => {
                return channelTypes[channel];
            }
        },
        {
            title: "Nosūtīts",
            dataIndex: "sent",
            key: "sent",
            filters: [
                { text: "Ir", value: 1 },
                { text: "Nav", value: 0 }
            ],
            render: (sent: any) => {
                return !!sent ? "Ir" : "Nav"
            }
        },
        {
            title: "Tips",
            dataIndex: "info_type",
            key: "info_type",
            filters: notificationTypes.map((type: any) => {
                return {
                    text: type.notification_type_name || type.notification_type,
                    value: type.notification_type
                }
            }),
            render: (info_type: any) => {
                return notificationTypes?.find((el: any) => {
                    return el.notification_type === info_type
                })?.notification_type_name
            }
        },
        {
            title: "Vienreizējs",
            dataIndex: "onetimer",
            key: "onetimer",
            filters: [
                { text: "Jā", value: 1 },
                { text: "Nē", value: 0 }
            ],
            render: (onetimer: any) => {
                return !!onetimer ? "Jā" : "Nē"
            }
        },
        {
            title: "Izveidots",
            dataIndex: "created_at",
            key: "created_at",
            sorter: true,
            render: (created_at: any) => {
                return moment(created_at).format("DD.MM.YYYY HH:mm:ss")
            },
            ...getColumnSearchProps('calendar')
        },
        {
            title: "Rediģēts",
            dataIndex: "updated_at",
            key: "updated_at",
            sorter: true,
            render: (updated_at: any) => {
                return moment(updated_at).format("DD.MM.YYYY HH:mm:ss")
            },
            ...getColumnSearchProps('calendar')
        },
        // {
        //     title: "Kategorija",
        //     dataIndex: "category_id",
        //     key: "category_id",
        //     filters: categories.length > 0
        //         ? categories?.map((cat: any) => {
        //             return {
        //                 text: cat.value,
        //                 value: cat.id
        //             }
        //         })
        //         : [],
        //     render: (category_id: any) => {
        //         return categories?.find((el: any) => el.id === category_id)?.value
        //     }
        // },
        {
            title: "Atkārtot",
            dataIndex: "id",
            key: "send_again",
            render: (id: any, record: any) => {
                return !!record.sent
                    ? <></>
                    : <Button
                        onClick={() => resendNotification(id)}
                    >
                        Nosūtīt
                    </Button>
            }
        }
    ] as any;

    return <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        pagination={pagination}
        expandable={{
            expandedRowRender: (record: any) => {
                return record.channel === "mail"
                    ? <>
                        <b>E-pasta virsraksts: </b><br />
                        <span>{JSON.parse(record.message)?.email_subject}</span><br />
                        <b>E-pasta ziņa: </b><br />
                        <span>{JSON.parse(record.message)?.email_content}</span>
                    </>
                    : record.channel === "whatsapp"
                        ? JSON.parse(record.message)?.whatsapp_content
                        : record.channel === "fcm"
                            ? <>
                                <b>Push paziņojuma virsraksts: </b><br />
                                <span>{JSON.parse(record.message)?.fcm_title}</span><br />
                                <b>Push paziņojuma ziņa: </b><br />
                                <span>{JSON.parse(record.message)?.fcm_content}</span><br />
                                <b>Push paziņojuma izvērstā ziņa: </b><br />
                                <span>{JSON.parse(record.message)?.fcm_full_content}</span>
                            </>
                            : record.channel === "sms"
                                ? JSON.parse(record.message)?.sms_content
                                : JSON.parse(record.message)?.message
            }
        }}
    />
}