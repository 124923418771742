import React, { useEffect, useState } from "react";
import { Button, Card, message, Row } from "antd";
import { PlusOutlined, SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { CSDDExamService } from "./CSDDExamService";
import { v4 as uuid } from 'uuid';
import { http } from "../helpers/http";

export const ExamPrices = withRouter((props: any) => {
    const history = useHistory();
    const params = useParams() as any;
    const [services, setServices] = useState([] as any);
    const [save, setSave] = useState("not" as "not" | "collect" | "save");
    const [cities, setCities] = useState([] as any);

    const addService = () => {
        setServices((data: any) => {
            const newService = [{
                id: uuid().split("-")[0]
            }]
            const newArray = data.concat(newService)
            return newArray;
        })
    }

    const deleteService = (id: any) => {
        if (typeof (id) === "number") {
            http.delete(`/rest/category-prices/${id}`).then(() => {
                fetchServices();
            })
        } else {
            setServices((data: any) => {
                const newArray = data.filter((service: any) => {
                    return service.id !== id;
                })
                return newArray;
            })
        }
    }

    const saveServices = () => {
        if (save === "save") {
            http.post(`/rest/category-prices?category=${params.id}`, {
                CSDDservices: [...services]
            }).then((response: any) => {
                message.success("Dati ir saglabāti");
                fetchServices();
            }).catch(() => {
                message.error("Kļūda!");
            })
        }
        
    }

    const getData = (values: any) => {
        setServices((data: any) => {
            const toSubmit = data.map((item: any, index: any) => {
                if (item.id === values.id) {
                    return values;
                } else {
                    return item;
                }
            })
            return toSubmit;
        })
        if (services[services.length - 1].id === values.id) {
            setSave("save");
        } else {
            setSave("not");
        }
    }

    const fetchCities = async () => {
        await http.get("/rest/cities").then((response: any) => {
            setCities(response.data.data.filter((city: any) => city.has_exam))
            // setCities(response.data.data)
        });
    }

    const fetchServices = async () => {
        await http.get(`/rest/category-prices?category=${params.id}`).then((response: any) => {
            setServices(response.data)
        });
    }

    const rearangeServiceHandler = (direction: "up" | "down", position: number) => {
        if (direction === "up" && position > 0) {
            setServices((data: any) => {
                const tmpElement = data[position - 1];
                data[position - 1] = data[position];
                data[position] = tmpElement;
                return [...data];
            })
        }
        if (direction === "down" && position < services.length - 1) {
            setServices((data: any) => {
                const tmpElement = data[position + 1];
                data[position + 1] = data[position];
                data[position] = tmpElement;
                return [...data];
            })
        }
    }

    useEffect(() => {
        saveServices();
    }, [save]);

    useEffect(() => {
        fetchServices();
        fetchCities();
    }, [])

    return (
        <Card
            title={
                <Row justify="space-between">
                    <p>CSDD pakalpojumu cenas</p>
                    <Button type="link" onClick={history.goBack}><ArrowLeftOutlined />Atgriezties</Button>
                </Row>
            }
        >
            {
                services?.map((service: any, index: any) => {
                    return <CSDDExamService
                        key={service.id}
                        service={service}
                        cities={cities}
                        deleteService={deleteService}
                        up={() => rearangeServiceHandler("up", index)}
                        down={() => rearangeServiceHandler("down", index)}
                        returnFormData={getData}
                        save={save}
                    />
                })
            }
            <Row justify="space-between">
                <Button style={{ marginTop: 10 }} type="primary" onClick={addService}><PlusOutlined /></Button>
                <Button onClick={() => setSave('collect')} type="primary"><SaveOutlined />Saglabāt</Button>
            </Row>
        </Card>
    )
})