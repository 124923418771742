import { Layout, PageHeader, Row, Col, Card, Skeleton, Typography, Button, Divider, Table, Modal, Select, Tooltip, Input, Tag, message, Tabs, Space, Checkbox, Spin } from 'antd';
import moment from 'moment';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { http } from '../../helpers/http';
import Form from 'antd/lib/form';
import { EditLessonsSchedule } from './EditLessonsSchedule';
import { getColumnSearchProps } from '../../helpers/getColumnSearchProps';
import { MultiChannelLanguagesSpammer } from '../StudentSpamFilter/MultiChannelLanguagesSpammer';
import { AxiosResponse } from 'axios';
import { TheoryLessonsCalendar } from './TheoryLessonsCalendar';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const Content = Layout.Content;

export type ShowGroupPageProps = RouteComponentProps<{ id: string }> & {
    permissions: any,
    accept: any
};
export type ShowGroupPageState = {
    submitting: boolean,
    loading: boolean,
    changing_password: boolean,
    initialData: any,
    classifiers: any,
    isModalVisible: boolean,
    studentTableState: "hard" | "soft" | "interested" | "from_other_group",
    studentsSelected: {
        hard: number[],
        soft: number[],
        interested: number[],
        from_other_group: number[]
    },
    mergedSelectedStudents: number[],
    lessons_visible: boolean,
    lessons: any,
    pagination: any,
    students: any,
    deleteWarning: boolean,
    expanded: { expanded: boolean, count: number },
    foreignStudents: [],
    fromOtherGroupPagination: any,
    tableLoading: boolean,
    importing: boolean,
    theoryForeignCounters: any[],
    foreignFilters: any,
    allow_crm_for_all: boolean,
    savingLessons: boolean
};

const ModalForm = (props: any) => {
    const { statuses } = props;
    const [status, setStatus] = useState(props.status);


    const selectHandler = (value: any) => {
        setStatus(value);
    }

    const okHandler = () => {
        props.okHandler(status);
        setStatus(props.status);
        props.close();
    }

    return (
        <Modal title="Mainīt statusu" visible={props.isVisible} onOk={okHandler} onCancel={props.close}>
            <Select defaultValue={props.status} style={{ width: 120 }} onChange={selectHandler}>
                {statuses.map((status: any) => {
                    return <Option key={status.id} value={status.id}>{status.value}</Option>
                })}
            </Select>
        </Modal>
    )
}

export class BaseShowGroupPage extends React.Component<ShowGroupPageProps, ShowGroupPageState> {
    preparedData: any;
    constructor(props: ShowGroupPageProps) {
        super(props);

        this.state = {
            submitting: false,
            loading: true,
            changing_password: false,
            initialData: [{}],
            classifiers: {},
            isModalVisible: false,
            studentTableState: "hard",
            studentsSelected: {
                hard: [],
                soft: [],
                interested: [],
                from_other_group: []
            },
            mergedSelectedStudents: [],
            lessons_visible: false,
            lessons: [],
            pagination: {},
            students: [],
            deleteWarning: false,
            expanded: {
                expanded: false,
                count: 0
            },
            foreignStudents: [],
            fromOtherGroupPagination: {},
            tableLoading: true,
            importing: false,
            theoryForeignCounters: [],
            foreignFilters: {},
            allow_crm_for_all: false,
            savingLessons: false
        };

        this.goBack = this.goBack.bind(this);
    }

    statusChangeHandler = (values: any) => {
        values = { status: values }
        http.post(`/rest/study-groups/change-status/${this.props.match.params.id}`, values).then((response: any) => {
            this.props.history.replace(`/groups/edit/${response.data.data.id}`);
            this.submitModal(response.data.data.group_status)
        }).catch((e: any) => {
            console.log('not ok');
        }
        );
    }

    async fetch(params: any = { pagination: { current: 1 } }) {
        await http.get(`/rest/study-groups/${this.props.match.params.id}`, { params: this.getParams(params) }).then(response => {
            const { data, meta } = response.data;
            this.setState({
                initialData: data,
                lessons: data.theory_lessons,
                loading: false,
                allow_crm_for_all: !!data.allow_all_crm
            }, () => console.log(this.state.initialData));
        });
    }

    getForeignCounter = () => {
        http.get(`/rest/get-foreign-counter/${this.props.match.params.id}`).then((res: AxiosResponse) => {
            this.setState({ theoryForeignCounters: res.data.data })
        })
    }

    refresh = async () => {
        http.get(`/rest/study-groups/${this.props.match.params.id}`, { params: { skip_students: 1 } }).then(response => {
            const { data, meta } = response.data;
            this.setState({
                initialData: data,
                lessons: data.theory_lessons,
            });
        });
    }

    fetchForeignStudents = async (params: any = { no_paginate: 1 }) => {
        await http.get(`/rest/get-foreign/${this.props.match.params.id}`, { params: this.getParamsStudents(params) }).then((response: any) => {
            const { data } = response.data;
            this.setState({
                foreignStudents: data,
                fromOtherGroupPagination: {
                    current: response.data.current_page,
                    pageSize: response.data.per_page,
                    total: response.data.total
                }
            })
        })
    }

    fetchStudents = async (params: any = { no_paginate: 1 }) => {
        this.setState({ tableLoading: true })
        await http.get(`/rest/study-groups-students/${this.props.match.params.id}/${this.state.studentTableState}`,
            {
                params: {
                    ...{
                        ...this.getParamsStudents(params),
                        elearning: this.state.initialData.real_elearning
                    },
                    no_paginate: this.state.studentTableState === "hard" ? 1 : 0
                }
            }).then((response) => {
                const { data, meta } = response.data;
                this.setState(
                    {
                        students: data,
                        pagination: {
                            current: meta.current_page,
                            pageSize: meta.per_page,
                            total: meta.total
                        },
                        tableLoading: false
                    }
                )
            })
    }

    getParams(params: any) {
        return {
            // page: params.pagination.current,
            no_paginate: 1,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters,
            reservationType: params.reservationType,
            skip_students: 1
        }
    }

    getParamsStudents(params: any) {
        return {
            page: params.pagination?.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters,
            reservationType: params.reservationType,
        }
    }

    handleForeignTableChange = (pagination: any, filters: any, sorter: any) => {
        let newFilters = JSON.parse(JSON.stringify((this.state.foreignFilters)))
        if (filters) {
            Object.keys(filters).forEach(key => {
                if (filters[key]) {
                    newFilters = {
                        ...newFilters,
                        [key]: filters[key]
                    }
                } else {
                    delete newFilters[key]
                }
            });
        } else {
            delete newFilters.name
        }
        this.setState({ foreignFilters: newFilters })
        // if (filters !== null) {
        //     if (alreadyFilter !== undefined) {
        //         newFilters = {
        //             ...newFilters,
        //             name: alreadyFilter.name
        //         }
        //     }
        // }
        this.fetchForeignStudents({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters: newFilters
        });
    };

    setActiveNameFilterKey = (activeName: any) => {
        let newFilters = JSON.parse(JSON.stringify(this.state.foreignFilters))
        if (activeName.length === 0) {
            delete newFilters.name
        } else {
            newFilters = {
                ...newFilters,
                name: activeName
            }
        }
        this.setState({
            theoryForeignCounters: this.state.theoryForeignCounters.map((item: any) => {
                if (item.name === activeName) {
                    return {
                        ...item,
                        active: 1
                    }
                } else {
                    return {
                        ...item,
                        active: 0
                    }
                }
            }),
            foreignFilters: newFilters
        })
        this.handleForeignTableChange({ current: 1 }, activeName.length > 0 ? newFilters : null, {})
    }

    handleTableChange = (pagination: any, filters: any, sorter: any) => {
        this.fetchStudents({
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            filters,
        });
    };

    submitModal = (value: any) => {
        this.setState((prevState: any) => ({
            initialData: {
                ...prevState.initialData,
                group_status: value
            },
            isModalVisible: false
        }));
    }

    goBack() {
        this.props.history.push('/groups');
    }

    componentDidMount() {
        this.loadClassifiers().then(() => {
            this.fetch();
            this.getForeignCounter();
        });
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.initialData !== this.state.initialData) {
            if (Object.keys(this.state.initialData).length > 0) {
                this.fetchStudents();
            }
        }
        if (prevState.studentsSelected !== this.state.studentsSelected) {
            const { studentsSelected, studentTableState } = this.state
            const newStudentList = Object.keys(this.state.studentsSelected).flatMap((key: any) => {
                return studentsSelected[key as keyof typeof studentsSelected]
            })
            this.setState({ mergedSelectedStudents: newStudentList })
        }
        if (prevState.studentTableState !== this.state.studentTableState) {
            if (this.state.studentTableState !== "from_other_group") {
                this.fetchStudents();
            } else {
                this.fetchForeignStudents();
            }
        }
    }

    processDataOld = (data: any) => {
        return {
            ...data,

            interested: data.students.filter((student: any) => {
                // return false
                return student.pivot.reservation_type === "interested"
            }),
            hard: data.students.filter((student: any) => {
                // return false
                return student.pivot.reservation_type === "hard"
            }),
            soft: data.students.filter((student: any) => {
                // return false
                return student.pivot.reservation_type === "soft"
            }),
        }
    }

    async loadClassifiers() {
        const languages = await http.get("/rest/languages");
        const cities = await http.get("/rest/cities");
        const categories = await http.get("/rest/categories");
        const status = await http.get("/rest/study-groups-status");

        this.setState({
            classifiers: {
                languages: languages.data.data,
                cities: cities.data.data,
                categories: categories.data.data,
                status: status.data.data,
            }
        });
    }

    toggleLessonsForm = () => {
        this.state.lessons_visible
            ? this.setState({ lessons_visible: false })
            : this.setState({ lessons_visible: true });
    }

    updateLessons = (newList: any) => {
        this.setState(
            {
                initialData: {
                    ...this.state.initialData,
                    theory_lessons: newList,
                },
                lessons: newList
            }
        );
    }

    saveLessons = (lessons: any) => {
        this.setState({ lessons: lessons });
    }

    deleteGroup = (confirmed: boolean = false) => {
        if (confirmed) {
            http.delete(`rest/study-groups/${this.state.initialData.id}`).then((response: any) => {
                this.setState({ deleteWarning: false })
                window.location.href = "/groups/"
            })
        } else {
            this.setState({ deleteWarning: true })
        }
    }

    changeAllowCrmStatus = (value: boolean) => {
        http.post(`/rest/allow-crm/${this.state.initialData.id}`, { allow_all_crm: value ? 1 : 0 }).then((response: any) => {
            this.setState({ allow_crm_for_all: value });
            message.success("Izmaiņas saglabātas!")
        }).catch((e: any) => {
            message.error("Kļūda saglabājot izmaiņas!")
        })
    }

    render() {
        const { permissions, accept } = this.props;

        const SendEmailForm = () => {
            return (
                <Row>
                    <Col span={24}>
                        <Title level={4}>Sūtīt ziņu atzīmētajiem studentiem</Title>
                        <Form
                            layout="vertical"
                        >
                            <Form.Item style={{ width: "50vw" }} name="mail_text" label="Ziņa:">
                                <Input.TextArea autoSize={{ maxRows: 6, minRows: 6 }} />
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType="submit" type="primary">Sūtīt</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            )
        }

        const rowSelection = {
            onChange: (selectedRowKeys: any, selectedRows: any) => {
                const tableState = this.state.studentTableState
                this.setState({ studentsSelected: { ...this.state.studentsSelected, [tableState]: selectedRowKeys } })
            },
            getCheckboxProps: (record: any) => ({
                name: record.name,
            }),
            selectedRowKeys: this.state.studentsSelected[this.state.studentTableState]
        };

        const closeModal = () => {
            this.setState({ isModalVisible: false });
        }

        const foreignColumns = [
            {
                title: 'Students',
                dataIndex: 'student',
                key: 'student',
                render: (name: any, record: any) => {

                    return <Button
                        type="link"
                        onClick={() => this.props.history.push(`/groups/student-opening/${record.student_id}`, { defaultCategory: this.state.initialData.category.id })}
                    >
                        {name}
                    </Button>
                }
            },
            {
                title: "Tālr.Nr.",
                dataIndex: "phone_number",
                key: "phone_number",
                ...getColumnSearchProps()
            },
            {
                title: "Personas kods",
                dataIndex: "personal_identity_number",
                key: "person_code",
                ...getColumnSearchProps()
            },
            {
                title: 'CSDD',
                dataIndex: 'csdd_nr',
                key: 'csdd_nr',
            },
            {
                title: 'Modulis',
                dataIndex: 'name',
                key: 'name',
                // filters: this.state.initialData.theory_lessons != null ? this.state.initialData.theory_lessons.map((c: any) => ({
                //     text: c.name, value: c.name
                // })) : [],
                render: (item: any, record: any) => {
                    return <Space> {item}
                        {
                            record.is_online
                                ? <Tag color="blue">Tiešsaistē</Tag>
                                : <></>
                        }
                        {
                            record.paid
                                ? <Tag color="success">Apmaksāts</Tag>
                                : <Tag color="warning">Gaida apmaksu</Tag>
                        }
                    </Space>

                }
            },
            {
                title: 'Datums',
                dataIndex: 'lesson_date',
                key: 'lesson_date',
            },
            {
                title: 'Laiks no',
                dataIndex: 'time_from',
                key: 'time_from',
            },
            {
                title: 'Laiks līdz',
                dataIndex: 'time_to',
                key: 'time_to',
            }
        ] as any;

        const columns = [
            {
                title: '',
                dataIndex: 'comment',
                width: "3%",
                fixed: 'left',
                key: 'comment',
                render: (comment: any) => {
                    return comment !== null
                        ? <Tooltip placement="top" title={comment}>
                            <InfoCircleOutlined style={{ color: "red" }} />
                        </Tooltip>
                        : <></>
                }
            },
            {
                title: 'Vārds',
                width: 20,
                sorter: true,
                dataIndex: 'first_name',
                key: 'first_name',
                fixed: 'left',
                // filters: this.state.initialData.students !== undefined ? this.state.initialData.students.map((student: any) => ({
                //   value: student.first_name
                // })) : [],
                ...getColumnSearchProps,
                render: (name: any, record: any) => {

                    return <Button
                        type="link"
                        onClick={() => this.props.history.push(`/groups/student-opening/${record.id}`, { defaultCategory: this.state.initialData.category.id })}
                    >
                        {name}
                    </Button>
                }
            },
            {
                title: 'Uzvārds',
                width: 20,
                sorter: true,
                dataIndex: 'last_name',
                key: 'last_name',
                fixed: 'left',
                ...getColumnSearchProps(),
                render: (name: any, record: any) => {

                    return <Button
                        type="link"
                        onClick={() => this.props.history.push(`/groups/student-opening/${record.id}`, { defaultCategory: this.state.initialData.category.id })}
                    >
                        {name}
                    </Button>
                }
            },
            {
                title: 'Tālruņa nr.',
                width: 20,
                dataIndex: 'phone_number',
                key: 'phone_number',
                fixed: 'left',
                ...getColumnSearchProps()
            },
            {
                title: 'WEB pilsēta',
                width: 20,
                dataIndex: 'web_city_name',
                key: 'web_city_name',
                fixed: 'left',
                ...getColumnSearchProps(),
            },
            {
                title: 'WEB valoda',
                width: 20,
                dataIndex: 'web_lang',
                key: 'web_lang',
                fixed: 'left',
                ...getColumnSearchProps(),
            },
            // {
            //     title: 'Status',
            //     width: 20,
            //     sorter: true,
            //     dataIndex: 'registration_status',
            //     key: 'registration_status',
            //     filters: [
            //         {
            //             value: "new",
            //             text: "Jauns"
            //         },
            //         {
            //             value: "paid",
            //             text: "Apmaksāts"
            //         },
            //     ],
            //     render: (status: any) => status === "new" ? "Jauns" : "Apmaksāts"
            // },
        ];

        const hardColumns = [
            ...columns,
            {
                title: 'Dalība lekcijās',
                width: 20,
                sorter: true,
                dataIndex: 'theory_lessons',
                key: 'attended_lessons',
                render: (lessons: any) => `${lessons.length}/${this.state.initialData.theory_lessons.length}`
            }
        ]

        const hardWithElearning = [
            ...columns,
            {
                title: 'E-moduļi',
                width: 20,
                dataIndex: 'study_group_modules',
                key: 'study_group_modules',
                render: (modules: any, student: any) => {
                    const completed = modules[this.state.initialData.id]
                    return `${completed}/${this.state.initialData.category.e_learning_lesson_counter}`
                }
            },
            {
                title: 'Dalība lekcijās',
                width: 20,
                // sorter: true,
                dataIndex: 'theory_lessons',
                key: 'attended_lessons',
                render: (lessons: any) => `${lessons.length}/${this.state.initialData.theory_lessons.length}`
            }
        ]

        const content = this.state.loading ? (
            <Card>
                <Skeleton />
            </Card>
        ) : (
            <>
                <Card>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Row justify="space-between">
                                <Title level={4} >Grupas status</Title>
                                <Button danger icon={<DeleteOutlined />} onClick={() => this.deleteGroup()}>Dzēst grupu</Button>
                                <Modal
                                    onCancel={() => this.setState({ deleteWarning: false })}
                                    onOk={() => this.deleteGroup(true)}
                                    visible={this.state.deleteWarning}
                                    title="Dzēst mācību grupu?"
                                >
                                    Vai tiešām vēlaties dzēst mācību grupu?
                                </Modal>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Text style={{ fontSize: 16, margin: "10px 0 0 10px", display: "block" }}>{this.state.initialData.group_status !== null ? this.state.initialData.group_status.value : ""}</Text>
                        </Col>
                        <Col span={6}>
                            <Button onClick={() => this.setState({ isModalVisible: true })}>Mainīt statusu</Button>
                        </Col>
                        <Col span={10}>

                            {
                                this.state.initialData.comment?.length > 0
                                    ? <Card>
                                        <div key={this.state.expanded.count}>
                                            <Paragraph
                                                ellipsis={{
                                                    rows: 2,
                                                    expandable: true,
                                                    symbol: "Rādīt vairāk",
                                                    onExpand: () => this.setState({ expanded: { expanded: true, count: this.state.expanded.count + 0 } })
                                                }}
                                            >
                                                <InfoCircleOutlined style={{ fontSize: 18, color: "red" }} /> {this.state.initialData.comment}
                                            </Paragraph>
                                        </div>
                                        {this.state.expanded.expanded ? <Button onClick={() => this.setState({ expanded: { expanded: false, count: this.state.expanded.count + 1 } })} type="link">Rādīt mazāk</Button> : <></>}
                                    </Card>
                                    : ""
                            }
                        </Col>
                        <Col span={24}>
                            <Row>
                                <b>Rādīt visus grupas pieteikumus atgādinājumu sadaļā:</b>
                                <Checkbox
                                    checked={this.state.allow_crm_for_all}
                                    onChange={(e) => {
                                        this.changeAllowCrmStatus(e.target.checked)
                                    }}
                                    style={{ marginLeft: 10 }}
                                />
                            </Row>
                        </Col>
                        <Divider />
                        <Col span={24}>
                            <Title level={4} >Mācību grupas informācija</Title>
                        </Col>
                        <Col span={12}>
                            <Text>Kategorija</Text><br />
                            <Text style={{ fontSize: 16, margin: "10px 0 0 10px", display: "block" }}>{this.state.initialData.category.value + " kategorija"}</Text>
                        </Col>
                        <Col span={12}>
                            <Text>CSDD kategorijas kods</Text><br />
                            <Text style={{ fontSize: 16, margin: "10px 0 0 10px", display: "block" }}>{this.state.initialData.csdd_code || this.state.initialData.category.csdd_code}</Text>
                        </Col>
                        <Col span={12}>
                            <Text>Nosaukums</Text><br />
                            <Text style={{ fontSize: 16, margin: "10px 0 0 10px", display: "block" }}>{this.state.initialData.name}</Text>
                        </Col>
                        <Col span={12}>
                            <Text>Valoda</Text><br />
                            {this.state.initialData.languages.map((language: any) => {
                                return <Tag key={language.id}>{language.value}</Tag>
                            })}
                        </Col>
                        <Col span={12}>
                            <Text>Pilsēta</Text><br />
                            {this.state.initialData.cities.map((city: any) => {
                                return <Tag key={city.id}>{city.value}</Tag>
                            })}
                        </Col>
                        <Col span={12}>
                            <Text>Uzsākšanas datums</Text><br />
                            <Text style={{ fontSize: 16, margin: "10px 0 0 10px", display: "block" }}>{this.state.initialData.start_date !== null ? this.state.initialData.start_date : "Datums nav norādīts"}</Text>
                        </Col>
                        <Col span={12}>
                            <Text>Nodarbības sākuma un beigu laiks</Text><br />
                            <Text style={{ fontSize: 16, margin: "10px 0 0 10px", display: "block" }}>{this.state.initialData.start_time !== null ? `${this.state.initialData.start_time} - ${this.state.initialData.end_time}` : "Laiks nav norādīts"}</Text>
                        </Col>
                        <Col span={12}>
                            <Text>Maksimālais studentu skaits</Text><br />
                            <Text style={{ fontSize: 16, margin: "10px 0 0 10px", display: "block" }}>{this.state.initialData.planned_slots}</Text>
                        </Col>
                        <Col span={12}>
                            <Text>Papildus studentu skaits</Text><br />
                            <Text style={{ fontSize: 16, margin: "10px 0 0 10px", display: "block" }}>{this.state.initialData.extra_slots}</Text>
                        </Col>
                        <Col span={12}>
                            <Text>Papildus studenta lekcijas cena</Text><br />
                            <Text style={{ fontSize: 16, margin: "10px 0 0 10px", display: "block" }}>{this.state.initialData.extra_slots_price} EUR</Text>
                        </Col>
                        <Col span={12}>
                            <Text>Nodarbības online</Text><br />
                            <Text style={{ fontSize: 16, margin: "10px 0 0 10px", display: "block" }}>{this.state.initialData.show_web ? "Pieejamas" : "Nav pieejamas"}</Text>
                        </Col>
                        <Col span={12}>
                            <Text>CSDD numurs</Text><br />
                            <Text style={{ fontSize: 16, margin: "10px 0 0 10px", display: "block" }}>{this.state.initialData.csdd_nr}</Text>
                        </Col>
                        <Col span={24}>
                            <Card title="Reģistrācija">
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Row>
                                            <Col span={8}>
                                                <h4>Cena (ar PVN)</h4>
                                                <b style={{ marginBottom: 6, display: "block" }}>{this.state.initialData.registration_fee} &euro;</b>
                                                {
                                                    this.state.initialData.cities.map((city: any) => {
                                                        return <div key={city.id} style={{ borderTop: "1px solid #F0F0F0", padding: "6px 0" }}>
                                                            <b style={{ display: "block" }}>{city.value}</b>
                                                            <b>
                                                                {
                                                                    city.pivot.registration_fee !== null
                                                                        ? <>{city.pivot.registration_fee} &euro;</>
                                                                        : <>{this.state.initialData.registration_fee} &euro;</>
                                                                }
                                                            </b>
                                                        </div>
                                                    })
                                                }
                                            </Col>
                                            <Col span={8}>
                                                <h4>Atlaides cena</h4>
                                                <b style={{ marginBottom: 6, display: "block" }}>{this.state.initialData.registration_discount} &euro;</b>
                                                {
                                                    this.state.initialData.cities.map((city: any) => {
                                                        return <b key={city.id} style={{
                                                            padding: "28px 0 6px",
                                                            display: "block",
                                                            borderTop: "1px solid #F0F0F0",
                                                        }}>
                                                            {
                                                                city.pivot.registration_discount !== null
                                                                    ? <>{city.pivot.registration_discount} &euro;</>
                                                                    : "-"
                                                            }
                                                        </b>
                                                    })
                                                }
                                            </Col>
                                            <Col span={8}>
                                                <h4>PVN</h4>
                                                <b style={{ marginBottom: 6, display: "block" }}>{this.state.initialData.registration_vat} %</b>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8}>
                                        <h4 style={{ marginLeft: 30 }}>Komentārs</h4>
                                        <Tabs style={{ marginLeft: 30 }} defaultActiveKey="1">
                                            <TabPane tab="LV" key="1">
                                                <p>{this.state.initialData.comment_lv}</p>
                                            </TabPane>
                                            <TabPane tab="EN" key="2">
                                                <p>{this.state.initialData.comment_en}</p>
                                            </TabPane>
                                            <TabPane tab="RU" key="3">
                                                <p>{this.state.initialData.comment_ru}</p>
                                            </TabPane>
                                        </Tabs>
                                    </Col>
                                    <Col span={8}>
                                        <h4 style={{ marginLeft: 30 }}>Apraksts</h4>
                                        <Tabs style={{ marginLeft: 30 }} defaultActiveKey="1">
                                            <TabPane tab="LV" key="1">
                                                <p>{this.state.initialData.description_lv}</p>
                                            </TabPane>
                                            <TabPane tab="EN" key="2">
                                                <p>{this.state.initialData.description_en}</p>
                                            </TabPane>
                                            <TabPane tab="RU" key="3">
                                                <p>{this.state.initialData.description_ru}</p>
                                            </TabPane>
                                        </Tabs>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            {
                                permissions.ruler_of_universe || permissions.perms.some((perm: any) => {
                                    return perm === accept.find((el: any) => el === perm)
                                })
                                    ? <Link to={`/groups/edit/${this.state.initialData.id}/edit`}><Button style={{ display: "block", width: "100%" }}>Mainīt grupas informāciju</Button></Link>
                                    : <></>
                            }
                        </Col>
                        <Divider />
                        <Col span={24}>
                            <Row>
                                <Title level={4} style={{ marginRight: 20 }} >Teorijas nodarbību grafiks</Title>
                                <TheoryLessonsCalendar
                                    selectedDates={this.state.lessons.map((lesson: any) => {
                                        return lesson.lesson_date
                                            ? moment(lesson.lesson_date, 'YYYY-MM-DD')
                                            : undefined
                                    }).filter((date: any) => date !== undefined)}
                                    lessons={this.state.lessons}
                                    saveLessons={this.saveLessons}
                                    groupID={this.props.match.params.id}
                                    setSavingLessons={(state: boolean) => this.setState({ savingLessons: state })}
                                />
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row justify='center'>
                                {
                                    this.state.savingLessons
                                        ? <Spin size='large' />
                                        : <></>
                                }
                            </Row>
                            {/* <Button onClick={() => this.fetch()}>!</Button> */}
                            {this.state.lessons.map((item: any, index: any) => {
                                return (
                                    <Card key={item.id} >
                                        <Row gutter={16}>
                                            <Col span={5}><Text style={{ fontSize: 16 }}>{index + 1 + ". nodarbība"}</Text></Col>
                                            <Col span={6}><Text>{item.name}</Text></Col>
                                            <Col span={5}>
                                                {item.lecturers?.map((lecturer: any) => {
                                                    return <Tag key={lecturer.id}>{lecturer.first_name} {lecturer.last_name}</Tag>
                                                })}
                                            </Col>
                                            <Col span={4}>
                                                <Text>
                                                    {
                                                        item.lesson_date
                                                            ? <>{item.lesson_date}, {moment(item.lesson_date, 'YYYY-MM-DD').format("dd")}</>
                                                            : "Datums nav norādīts"
                                                    }
                                                </Text>
                                            </Col>
                                            <Col span={4}><Text>{item.time_from !== null ? `${item.time_from} - ${item.time_to}` : "Laiks nav norādīts"}</Text></Col>
                                        </Row>
                                    </Card>
                                )
                            })}
                        </Col>
                        <Col span={24}>
                            {/* <Link to={`/groups/edit/${this.state.initialData.id}/edit-lessons`}><Button style={{ display: "block", width: "100%" }}>Mainīt nodarbību grafiku</Button></Link> */}
                            <Button onClick={this.toggleLessonsForm} style={{ display: "block", width: "100%" }}>Nodarbību grafiks</Button>
                            {this.state.lessons_visible
                                ? <EditLessonsSchedule
                                    updateLessons={this.updateLessons}
                                    lessons={this.state.lessons}
                                    groupID={this.props.match.params.id}
                                    saveLessons={this.saveLessons}
                                    groupData={this.state.initialData}
                                    refresh={this.refresh}
                                />
                                : <></>}
                        </Col>
                        <Divider />
                        <Col span={24}>
                            <Title level={4} >Studenti</Title>
                        </Col>
                        <Col span={24}>
                            <Row justify='center'>
                                <Button type={this.state.studentTableState === "from_other_group" ? "primary" : "default"} onClick={() => this.setState({ studentTableState: "from_other_group" })}>
                                    Studenti no citam grupām{` (${this.state.fromOtherGroupPagination.total ?? this.state.initialData.from_other_group ?? 0})`}
                                </Button>
                                <Button type={this.state.studentTableState === "hard" ? "primary" : "default"} onClick={() => this.setState({ studentTableState: "hard" })}>
                                    Hard rezervācija{` (${this.state.initialData.hard_reservation ?? 0})`}
                                </Button>
                                <Button type={this.state.studentTableState === "soft" ? "primary" : "default"} onClick={() => this.setState({ studentTableState: "soft" })}>
                                    Soft rezervācija{` (${this.state.initialData.soft_reservation ?? 0})`}
                                </Button>
                                <Button type={this.state.studentTableState === "interested" ? "primary" : "default"} onClick={() => this.setState({ studentTableState: "interested" })}>
                                    Interesenti{` (${this.state.initialData.interested ?? 0})`}
                                </Button>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row justify="end">
                                {
                                    this.state.studentTableState === "interested"
                                        ? <Link style={{ float: "right" }} to={`/groups/edit/${this.state.initialData.id}/interested/add-student`}><Button>Pievienot studentu</Button></Link>
                                        : <></>
                                }
                            </Row>
                        </Col>
                        {
                            this.state.studentTableState !== "from_other_group"
                                ? <>
                                    <Table
                                        style={{ width: "100%" }}
                                        rowKey={(record: any) => record.id}
                                        rowSelection={{
                                            type: "checkbox",
                                            ...rowSelection,
                                            columnWidth: 3
                                        }}
                                        columns={this.state.studentTableState === "hard" ? this.state.initialData.real_elearning ? hardWithElearning : hardColumns : columns as any}
                                        rowClassName="clickable-row"
                                        // pagination={}
                                        pagination={this.state.studentTableState === "hard" ? false : {
                                            showSizeChanger: false,
                                            size: 'small',
                                            showTotal: (total) => <div>Kopā {total} studenti</div>,
                                            position: ['bottomCenter'],
                                            pageSizeOptions: ['15'],
                                            defaultPageSize: 15,
                                            ...this.state.pagination
                                        }}
                                        onRow={(record: any, rowIndex: any) => {
                                            return {
                                                onClick: (event: any) => {
                                                    // this.props.history.push(`/groups/edit/edit-student/${record.id}/${this.props.match.params.id}`);

                                                }, // click row
                                                onDoubleClick: (event: any) => { }, // double click row
                                                onContextMenu: (event: any) => { }, // right button click row
                                                onMouseEnter: (event: any) => { }, // mouse enter row
                                                onMouseLeave: (event: any) => { }, // mouse leave row
                                            };
                                        }}
                                        dataSource={this.state.students}
                                        loading={this.state.tableLoading}
                                        scroll={{ x: 800, }}
                                        onChange={this.handleTableChange}
                                    />
                                </>
                                : <div style={{ width: "100%" }}>
                                    <Row style={{ padding: "15px 0" }}>
                                        {
                                            this.state.theoryForeignCounters.map((lesson: any, index: number) => {
                                                return <Button
                                                    key={index}
                                                    style={{ marginRight: 8, marginBottom: 4 }}
                                                    type={lesson.active ? "primary" : "default"}
                                                    onClick={() => {
                                                        // this.handleForeignTableChange({ pagination: { current: 1 } }, { name: lesson.name }, {});
                                                        this.setActiveNameFilterKey(lesson.name);
                                                    }}
                                                >
                                                    <span>{lesson.name} <b>({lesson.taken_free_stats})</b></span>
                                                </Button>
                                            })
                                        }
                                        {
                                            this.state.theoryForeignCounters.some((item: any) => !!item.active)
                                            && <Button
                                                onClick={() => {
                                                    // this.handleForeignTableChange({ pagination: { current: 1 } }, {}, {});
                                                    this.setActiveNameFilterKey("");
                                                }}
                                                danger
                                            >
                                                <span>Atcelt filtru</span>
                                            </Button>
                                        }
                                    </Row>
                                    <Table
                                        style={{ width: "100%" }}
                                        rowKey={(record: any) => record.student_id + record.slot_id}
                                        rowSelection={{
                                            type: "checkbox",
                                            ...rowSelection,
                                            columnWidth: 3
                                        }}
                                        dataSource={this.state.foreignStudents}
                                        loading={this.state.tableLoading}
                                        columns={foreignColumns}
                                        pagination={{
                                            showSizeChanger: false,
                                            size: 'small',
                                            showTotal: (total) => <div>Kopā {total} studenti</div>,
                                            position: ['bottomCenter'],
                                            pageSizeOptions: ['15'],
                                            defaultPageSize: 15,
                                            ...this.state.fromOtherGroupPagination
                                        }}
                                        // pagination={false}
                                        scroll={{ x: 800, }}
                                        onRow={(record: any, rowIndex: any) => {
                                            return {
                                                onClick: (event: any) => {
                                                    // this.props.history.push(`/groups/edit/edit-student/${record.student_id}/${record.study_group_id}`);
                                                    // this.props.history.push(`/groups/student-opening/${record.student_id}`, { defaultCategory: this.state.initialData.category.id });
                                                }, // click row
                                                onDoubleClick: (event: any) => { }, // double click row
                                                onContextMenu: (event: any) => { }, // right button click row
                                                onMouseEnter: (event: any) => { }, // mouse enter row
                                                onMouseLeave: (event: any) => { }, // mouse leave row
                                            };
                                        }}
                                        onChange={this.handleForeignTableChange}
                                    />
                                </div>
                        }
                    </Row>
                    {
                        this.state.mergedSelectedStudents.length > 0
                        && <div>
                            <b>Atzīmēti studenti: {this.state.mergedSelectedStudents.length}</b>
                            <MultiChannelLanguagesSpammer
                                students={this.state.mergedSelectedStudents}
                                languages={this.state.classifiers.languages}
                            />
                        </div>
                    }
                </Card>
                <ModalForm
                    isVisible={this.state.isModalVisible}
                    status={this.state.initialData.group_status !== null ? this.state.initialData.group_status.id : ""}
                    okHandler={this.statusChangeHandler}
                    close={closeModal}
                    statuses={this.state.classifiers.status}
                />
            </>
        );

        return <Layout>
            <PageHeader
                style={{ padding: "0 16px", background: "rgb(255, 255, 255)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}
                onBack={this.goBack}
                title={
                    <>
                        <Title
                            level={3}
                            style={{ marginLeft: 40, marginBottom: 0 }}
                        >
                            <Row>
                                <Col>
                                    {this.state.loading ? `` : `${this.state.initialData.name} - ${this.state.initialData.start_date || ""}`}
                                </Col>
                                <Col>
                                    {
                                        this.state.initialData?.csdd_nr?.includes("undefined", "fake") || this.state.loading
                                            ? <></>
                                            : <Button
                                                onClick={async () => {
                                                    this.setState({ importing: true })
                                                    await http.post(`rest/call-csdd-study-group/${this.state.initialData.id}`).then((response: any) => {
                                                        this.setState({ importing: false })

                                                    })
                                                }}
                                                loading={this.state.importing}
                                                style={{ marginLeft: 15 }}
                                            >
                                                Importēt CSDD datus
                                            </Button>
                                    }
                                </Col>
                            </Row>
                        </Title>
                    </>
                }
                extra={this.state.loading ? null : null}
            />
            <Content style={{ padding: 20 }}>
                {content}
            </Content>
        </Layout>;
    }

}

export const GroupInfoPage = withRouter(BaseShowGroupPage);