import { Table } from "antd";
import React from "react";

export const ServicePricesLogTable = (props: any) => {
    const { data } = props;

    const columns = [
        {
            title: "Pilsēta",
            key: "city",
            align: "center",
            render: (all: any) => {
                let city = "Visās";
                if (all.hasOwnProperty("city")) city = all.city;
                return city;
            }
        },
        {
            title: "Cena",
            dataIndex: "price",
            key: "price",
            align: "center",
        },
        {
            title: "Atlaides cena",
            dataIndex: "discount_price",
            key: "discount_price",
            align: "center",
        },
        {
            title: "PVN",
            key: "vat",
            align: "center",
            render: (all: any) => {
                let vat = "";
                if (all.hasOwnProperty("vat")) vat = all.vat;
                return vat;
            }
        },
        {
            title: "Izmaiņas",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
        },
    ] as any;
    return <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 500 }}
    />
}