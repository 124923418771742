import React from "react";
import { Card, Col, Layout, PageHeader, Row, Tabs } from "antd";
import { useHistory } from "react-router";
import { MissedLessonsTable } from "./MissedLessonsTable";

export const MissedLessons = (props: any) => {
    const history = useHistory();

    return (
        <Layout>
            <PageHeader
                style={{ backgroundColor: "#FFFFFF" }}
                title={"Neapmeklētās lekcijas"}
                onBack={history.goBack}
            />
            <Card style={{ width: "100%" }} className="crm-card">
                <Row>
                    <Col span={24}>
                        <Row justify="center">
                            <Tabs>
                                <Tabs.TabPane key="1" tab="Klātiene">
                                    <MissedLessonsTable />
                                </Tabs.TabPane>
                                <Tabs.TabPane key="2" tab="Online apmācība">
                                    <MissedLessonsTable online />
                                </Tabs.TabPane>
                            </Tabs>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Layout>
    )
}