import React, { useEffect, useState } from "react";
import { Button, Card, Col, message, Modal, Radio, Row, Space, Table, Tag, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { http } from "../../helpers/http";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";

const { Text } = Typography;
const { confirm } = Modal;

export const BookInOtherGroup = (props: any) => {
    const { showModal, setShowModal, reserved, lesson, lessonNum, student, cities, refetch, group } = props;
    const [selectedGroup, selectGroup] = useState({} as any);
    const [freeLessons, setFreeLessons] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [availableFromDate, setAvailableFromDate] = useState(null as null | string);
    const [error, setError] = useState("")

    const getFilteredGroups = async () => {
        setLoading(true);
        await http.get(`/rest/student-study-group-free-attendance/${student.id}/${lessonNum}`, {
            params: {
                category: group?.category?.id
            }
        }).then((response: any) => {
            const freeLessons = response.data.data.map((lesson: any) => {
                return {
                    ...lesson,
                    cities: lesson?.cities.map((id: any) => {
                        return cities.find((el: any) => el?.id === Number(id));
                    })
                }
            })
            setLoading(false);
            setFreeLessons(freeLessons)
            setAvailableFromDate(response.data.date_available_from)
        })
    }

    useEffect(() => {
        if (showModal) {
            getFilteredGroups();
        }
    }, [showModal])

    const columns = [
        {
            title: 'Datums',
            dataIndex: 'lesson_date',
            key: 'date',
        },
        {
            title: 'Laiks no',
            dataIndex: 'time_from',
            key: 'time_from',
        },
        {
            title: 'Laiks līdz',
            dataIndex: 'time_to',
            key: 'time_to',
        },
        {
            title: 'Valoda',
            dataIndex: 'language'
        },
        {
            title: 'Pilsēta',
            dataIndex: 'cities',
            key: 'cities',
            render: (records: any) => {
                return records.map((record: any) => {
                    return <Tag key={record?.id}>{record?.value}</Tag>
                })
            }
        },
        {
            title: 'Adrese',
            dataIndex: 'address',
            key: 'address',
            render: (record: any) => {
                return <p style={{ whiteSpace: "normal", width: 200 }}>{record}</p>
            }
        },
        {
            title: 'CSDD',
            dataIndex: 'csdd',
            key: 'csdd',
            align: 'center',
        },
        {
            title: 'Nosaukums',
            dataIndex: 'name',
            key: 'name',
            align: 'center'
        },
        {
            title: 'Veids',
            dataIndex: 'is_online',
            key: 'is_online',
            render: (record: any) => {
                return record ? "Tiešsaistē" : "Klātienē"
            }
        },
    ] as any;

    const resetModal = () => {
        setShowModal(false)
    }

    const setGroup = (value: any) => {
        Modal.confirm({
            title: 'Nodarbības apstiprināšana',
            content: 'Pieteikt studentam šo nodarbību?',
            onOk: () => {
                http.post(`/rest/student-study-group-create-attendance/${student.id}`, { id: value }).then((response: any) => {
                    message.success("Students piesaistīts nodarbībai")
                    if (refetch) {
                        refetch();
                    }
                }).catch((err: AxiosError) => {
                    errorMessageResolver(err);
                    console.log(err.response?.data?.msg)
                    const { msg } = err.response?.data;
                    message.error("Kļūda! " + msg)
                })
            }
        })
    }

    const errorMessageResolver = (err: any) => {
        const { data, status } = err.response;
        if (status === 403) {
            if (data?.length > 0) {
                const errorType = { time_taken: "Izvēlētais laiks ir aizņemts!" } as any;
                const activities = {
                    driving_lesson: "Braukšanas nodarbība",
                    theory_lesson: "Teorijas nodarbība",
                    pmp_lesson: "Pirmās palīdzības nodarbība",
                    theory_exam: "Teorijas eksāmens"
                } as any;
                setError(`${errorType[data[0].message_type]} ${data[0].info} ${activities[data[0].reason]}`);
            }
        } else {
            setError("")
        }
    }

    const saveGroup = () => {
        selectedGroup.hasOwnProperty('id') ? reserved(true) : reserved(false);
        selectGroup({});
        resetModal();
    }

    return (
        <Modal
            visible={showModal}
            onCancel={resetModal}
            onOk={saveGroup}
            title={"Pieteikt " + lesson.lesson_counter + ". nodarbības noklausīšanos citā grupā"}
            width="60%"
            footer={null}
        >
            {/* <Button onClick={filteredGroups}>LOG</Button> */}
            {
                availableFromDate
                && <b>Tuvākais atļautais pieteikšanās datums: {moment(availableFromDate).format("DD.MM.YYYY")}</b>
            }
            {
                error.length > 0
                && <div>
                    <Tag color="red" style={{ marginBottom: 10, marginTop: 10, whiteSpace: "normal" }}>
                        {error}
                    </Tag>
                </div>
            }
            <Table
                className="change-lesson-group"
                rowKey={record => record.id}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            setGroup(record.id);
                            resetModal();
                        }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { }, // mouse leave row
                    };
                }}
                rowClassName={(record: any) => {
                    return record.id === selectedGroup.id ? "selected-row" : "";
                }}
                dataSource={freeLessons}
                scroll={{ x: 800, }}
                columns={columns}
                loading={loading}
            />
        </Modal>
    )
}

const LessonItem = (props: any) => {

    const {
        lesson,
        lesson_status,
        lessonNum,
        student,
        reserved,
        next,
        cities,
        refetch,
        haveElearning,
        foreign,
        group
    } = props;

    const [modalVisible, setModalVisible] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const style = () => {
        if (lesson_status === "attained") {
            return {
                backgroundColor: "#d9f7be"
            }
        } else if (lesson_status === 'foreign') {
            if (reserved?.paid && !reserved?.skipped) {
                return {
                    backgroundColor: "rgb(245 255 213)"
                }
            }
            if (!reserved?.paid && reserved) {
                return {
                    backgroundColor: "orange"
                }
            }
        } else if (lesson_status === "skipped") {
            if (haveElearning && !lesson?.foreign_lesson) {
                return {
                    backgroundColor: "#c4f3f7"
                }
            }
            if (reserved?.paid && reserved?.skipped && lesson?.foreign_lesson) {
                return {
                    backgroundColor: "#ffccc7"
                }
            }
            return {
                backgroundColor: "#ffccc7"
            }
        }
    }

    const status = () => {
        if (lesson_status === "attained") return "Apmeklēta"
        else if (lesson_status === "foreign") {
            if (reserved?.paid && !reserved?.skipped) {
                return "Pieteikta citā grupā"
            }
            if (!reserved?.paid && reserved) {
                return `Gaida apmaksu`
            }
        }
        else if (lesson_status === "skipped") {
            return "Neapmeklēta"
        }
    }

    const changeGroup = () => {
        setModalVisible(true);
    }

    const fakeAttended = (fake: 1 | 0) => {
        http.post(`/rest/student-set-fake-attended-theory-lesson/${student.id}/${lesson.related_id}`, { fake_attended: fake }).then((response: any) => {
            refetch();
        })
        // confirm({
        //     title: 'Atzīmēt kā apmeklētu',
        //     icon: <ExclamationCircleOutlined />,
        //     content: 'Apstiprinot, jūs atzīmēsiet, šo nodarbību kā apmeklētu! Atcelt nebūs iespējams!',
        //     onOk() {
        //         http.post(`/rest/student-set-fake-attended-theory-lesson/${student.id}/${lesson.related_id}`, { fake_attended: 1 }).then((response: any) => {
        //             refetch();
        //         })
        //     },
        //     onCancel() {
        //     },
        // });
    }

    const cancelAttendance = () => {
        confirm({
            title: 'Atcelt nodarbīibu',
            icon: <ExclamationCircleOutlined />,
            content: 'Apstiprinot, jūs atcelsiet nodarbību. Ja students ir veicis apmaksu, tad naudu studentam TIKS atgriezta!',
            onOk() {
                http.post(`/rest/student-remove-attended-theory-slot/${student.id}/${lesson.related_id}`).then((response: any) => {
                    refetch();
                })
            },
            onCancel() {
            },
        });

    }

    return (
        <Card style={{ ...style(), marginBottom: 4, cursor: "default", borderColor: next ? "rgb(24, 144, 255)" : "" }} hoverable>
            <Row gutter={[8, 8]}>
                {lesson.is_online ? <Tag style={{ marginLeft: 5, position: "absolute", top: 4, left: 0, fontSize: 10 }} color="blue">Tiešsaistē</Tag> : ""}
                <Col lg={3} md={12} span={24}>
                    <Text>Nr: {lessonNum}</Text>
                </Col>
                <Col lg={4} md={12} span={24}>
                    <Text>{lesson.name}</Text>
                </Col>
                <Col lg={4} md={12} span={24}>
                    <Text>{lesson.lesson_date}</Text>
                </Col>
                <Col lg={5} md={12} span={24}>
                    <Text>{lesson.time_from} - </Text>
                    <Text>{lesson.time_to}</Text>
                </Col>
                <Col lg={3} md={12} span={24}>
                    <Text>{false && !foreign && (lesson_status !== 1) ? "Nav pieteikta" : status()}</Text>
                    <BookInOtherGroup
                        showModal={modalVisible}
                        setShowModal={setModalVisible}
                        lesson={lesson}
                        lessonNum={lessonNum}
                        student={student}
                        cities={cities}
                        refetch={refetch}
                        group={group}
                    />
                </Col>
                <Col lg={5}>
                    <Row justify="end">
                        <Space>
                            {
                                status() === "Apmeklēta"
                                    ? !!lesson.fake_attended
                                        ? <div>
                                            <b style={{ color: "red" }}>Atzīmēta manuāli</b>
                                            <Button
                                                style={{ marginLeft: 5 }}
                                                onClick={() => fakeAttended(0)}
                                            >
                                                Atcelt
                                            </Button>
                                        </div>
                                        : <></>
                                    : status() === "Neapmeklēta"
                                        ? <Space>
                                            <Button onClick={() => fakeAttended(1)}>Apmeklēta</Button>
                                            <Button onClick={changeGroup}>Pieteikt</Button>
                                        </Space>
                                        : reserved !== undefined
                                            ? <><Button onClick={cancelAttendance} danger>Atcelt</Button></>
                                            : <></>
                            }
                            <Button onClick={() => setExpanded(!expanded)}>{expanded ? "-" : "+"}</Button>
                        </Space>
                    </Row>
                </Col>
                <Col>

                </Col>
                {
                    expanded
                        ? <Col span={24}>
                            {
                                lesson.is_online
                                    ? <div>
                                        <b>Tiešsaistē</b><br />
                                        {
                                            lesson.zoom_link
                                                ? <><b>Zoom saite:</b> <a href={lesson.zoom_link} target="_blank" rel='noopener noreferrer'>{lesson.zoom_link}</a></>
                                                : ""
                                        }
                                    </div>
                                    : <div>
                                        <b>Klātienē</b><br />
                                        {
                                            lesson.address
                                                ? <><b>Adrese:</b> <p>{lesson.address}</p></>
                                                : ""
                                        }
                                    </div>
                            }
                            {
                                lesson.lecturers.length > 0
                                    ? <div>
                                        <b>Pasniedzēji:</b>&nbsp;
                                        {
                                            lesson.lecturers.map((lecturer: any, index: any, self: any) => {
                                                return lecturer.first_name + " " + lecturer.last_name + (index !== self.length - 1 ? ", " : "")
                                            })
                                        }
                                    </div>
                                    : <></>
                            }
                            {
                                lesson.comment_lv || lesson.comment_en || lesson.comment_ru
                                    ? <div>
                                        <b>Komentāri</b><br />
                                        <p>{lesson?.comment_lv}</p>
                                        <p>{lesson?.comment_en}</p>
                                        <p>{lesson?.comment_ru}</p>
                                    </div>
                                    : <></>
                            }
                        </Col>
                        : <></>
                }
            </Row>
        </Card>
    )
}

export const LessonsList = (props: any) => {
    const {
        studentLessons,
        foreignLessons,
        student,
        getNotifications,
        nextTheoryLesson,
        refetch,
        haveElearning,
        group
    } = props;

    const [cities, setCities] = useState([] as any);

    const getCities = async () => {
        await http.get('/rest/cities').then((response: any) => {
            setCities(response.data.data)
        })
    }

    useEffect(() => {
        getCities();
    }, []);

    return (
        <>
            {studentLessons?.map((lesson: any, index: any) => {
                let foreign = undefined;
                if (lesson.skipped) {
                    foreign = foreignLessons.find((el: any) => {
                        return el.lesson_counter === lesson.lesson_counter
                        // return el.lesson_counter === index + 1
                    });
                    if (foreign) {
                        lesson = foreign;
                    }
                }
                const lessonForeign = !lesson.attained && !lesson.skipped && lesson.foreign_lesson //pure foreign
                const lessonSkipped = lesson.skipped
                const lessonAttained = lesson.attained
                const status = lessonSkipped
                    ? "skipped"
                    : lessonForeign
                        ? "foreign"
                        : lessonAttained
                            ? "attained"
                            : ""
                return <LessonItem
                    key={lesson.id}
                    lesson={lesson}
                    lessonNum={lesson?.lesson_counter}
                    lesson_status={status}
                    student={student}
                    getNotifications={getNotifications}
                    reserved={foreign}
                    group={group}
                    cities={cities}
                    refetch={refetch}
                    haveElearning={haveElearning}
                    foreign={lessonForeign}
                />
            })}
        </>
    )
}
