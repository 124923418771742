import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Row, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
import { CheckCircleTwoTone, CheckOutlined } from "@ant-design/icons";
import { Checkpoint } from "./Checkpoint";
import moment from "moment";
import { http } from "../../helpers/http";
import { withRouter } from "react-router-dom";
import { isShorthandPropertyAssignment } from "typescript";
import { last, lastIndexOf } from "lodash";

const { Title, Text } = Typography;

export const CheckpointsCard = withRouter((props: any) => {
    const { reload, studentCp } = props;
    const [checkpoints, setCheckpoints] = useState(props.checkpoints);

    useEffect(() => {
        setCheckpoints((data: any) => {
            const newList = props.checkpoints.map((cp: any) => {
                if (studentCp.some((el: any) => el.id === cp.id)) {
                    const temp = studentCp.filter((el: any) => {
                        return el.id === cp.id;
                    })[0]
                    temp.passed = temp.pivot.passed;
                    temp.pass_date = temp.pivot.pass_date === null ? undefined : temp.pivot.pass_date;
                    return temp;
                } else {
                    return cp;
                }
            })
            return newList;
        })
    }, [studentCp])

    const setCompleted = (checkpoint: any, status: boolean) => {
        const value = studentCp.filter((cp: any) => {
            return cp.id === checkpoint.id;
        })[0]
        value.passed = status ? 1 : 0;
        value.pass_date = value.pivot.pass_date === null ? undefined : value.pivot.pass_date;
        value.dont_need = 0;
        value.student = value.pivot.student_id;
        value.checkpoint = value.pivot.study_group_checkpoint_id;
        console.log(value)
        http.post(`/rest/student-study-group-checkpoints/change-checkpoint/${value.pivot.id}`, value).then((response: any) => {
            reload();
        });
    }

    const setDate = (checkpoint: any, newDate: any) => {
        const value = studentCp.filter((cp: any) => {
            return cp.id === checkpoint.id;
        })[0]
        console.log(moment(newDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))
        value.passed = 0;
        value.pass_date = newDate === undefined || newDate === "Invalid date" ? undefined : moment(newDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        value.dont_need = 0;
        value.student = value.pivot.student_id;
        value.checkpoint = value.pivot.study_group_checkpoint_id;
        http.post(`/rest/student-study-group-checkpoints/change-checkpoint/${value.pivot.id}`, value).then((response: any) => {
            reload();
        });
    }

    const setActual = (checkpoint: any, status: boolean = true) => {
        const cpIndex = studentCp.findIndex((cp: any) => checkpoint.id === cp.id);
        const idProps = props.match.params;
        console.log(checkpoint);
        if (cpIndex === -1) {
            const value = {
                ...checkpoint,
                student: idProps.id,
                checkpoint: checkpoint.id,
                passed: 0,
                dont_need: 0,
                pass_date: undefined,
            }
            console.log(value);
            http.post(`/rest/student-study-group-checkpoints/add-checkpoint/${idProps.id}`, value).then((response: any) => {
                reload();
            });
        } else {
            const value = studentCp.filter((cp: any) => {
                return cp.id === checkpoint.id;
            })[0]
            value.dont_need = !status ? 1 : 0;
            value.student = value.pivot.student_id;
            value.checkpoint = value.pivot.study_group_checkpoint_id;
            http.post(`/rest/student-study-group-checkpoints/change-checkpoint/${value.pivot.id}`, value).then((response: any) => {
                reload();
            });
        }
    }

    const lastModified = () => {
        const dates = studentCp.map((cp: any) => {
            return moment(cp.pivot.updated_at, "YYYY-MM-DD HH:mm:ss");
        })
        if (dates.length === 0) return;
        return <>{moment.max(dates).format("YYYY-MM-DD HH:mm:ss")}</>
    }

    return (
        <Card className="checkpoints-card" style={{ marginTop: 15 }}>
            <Title level={5}>Checkpoints:</Title>
            {checkpoints.map((checkpoint: any) => {
                return (
                    <Checkpoint
                        key={checkpoint.id}
                        checkpoint={checkpoint}
                        done={setCompleted}
                        changeDate={setDate}
                        actual={setActual}
                        studentGroup={props.studentGroup}
                    />
                )
            })}
            {/* <Row>
                <Title style={{ marginTop: 20 }} level={5}>Pēdējās izmaiņas: Name {lastModified()}</Title>
            </Row> */}
        </Card>
    )
})