import { Button, Col, DatePicker, Form, Input, InputNumber, Row, message, Checkbox } from "antd";
import React, { useState } from "react";
import { http } from "../../helpers/http";

export const AddPaymentForm = (props: any) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [takeCommision, setTakeCommision] = useState(false);

    const { student_id } = props;

    function onFinish(values: any) {
        values.date = values.date.format("YYYY-MM-DD");
        values.not_credit_check = 1;

        setSubmitting(true);
        http.post(`/payments/add-student-payment`, {
            ...values,
            student_id,
            commision: takeCommision ? 1 : 0,
        })
            .then(() => {
                setSubmitting(false);
                form.resetFields();
                message.success("Maksājums reģistrēts");
                props.onFinish();
            })
            .catch(() => { setSubmitting(false); message.error("Notika neparedzēta kļūda"); });
    }

    function onFinishFailed() {
        message.error("Neizdevās reģistrēt maksājumu");
    }

    return <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} layout="vertical">
        <Row gutter={20}>
            <Col span={6}>
                <Form.Item label="Summa EUR" rules={[{ required: true, message: "Obligāts lauks" }]} name="amount">
                    <InputNumber precision={2}></InputNumber>
                </Form.Item>
                <Row>
                    <Checkbox
                        style={{ marginRight: 8 }}
                        onChange={(e: any) => setTakeCommision(e.target.checked)}
                    />
                    <p>Ieturēt komisiju</p>
                </Row>
                {
                    takeCommision
                        ? <Form.Item label="Komisija" rules={[{ required: true, message: "Obligāts lauks" }]} name="commision_amount">
                            <InputNumber max={0} precision={2}></InputNumber>
                        </Form.Item>
                        : <></>
                }

            </Col>

            <Col span={6}>
                <Form.Item label="Datums" rules={[{ required: true, message: "Obligāts lauks" }]} name="date">
                    <DatePicker></DatePicker>
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item label="Pamatojums" rules={[{ required: true, message: "Obligāts lauks" }]} name="reason">
                    <Input />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={20}>
            <Col>
                <Form.Item>
                    <Button type="primary" loading={submitting} htmlType="submit">Pievienot maksājumu</Button>
                </Form.Item>
            </Col>
        </Row>
    </Form>;
}