import { Button, Checkbox, Col, Input, message, Modal, Row, Select, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";

export const MigrateServices = (props: any) => {
    const { studentID, groupsID, visible, setVisible } = props;
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState({
        bad_services: [],
        new_services: [] as any
    });
    const [registrationRequest, setRegistrationRequest] = useState(false);
    const [oldServices, setOldServices] = useState([] as any);
    const [selectedServices, setSelectedServices] = useState({} as any);
    const [studentMoved, setStudentMoved] = useState(false);
    const [keepRegistration, setKeepRegistration] = useState({
        keep: 0,
        fee: null
    } as any);
    const [keepServices, setKeepServices] = useState({} as any);
    const [firstStep, setFirstStep] = useState(true);
    const reservation = "interested";

    const fetchStudentRegistration = async () => {
        await http.post(`/rest/student-check-study-group-registration/${studentID}/${groupsID.old}`)
            .then((response: any) => {
                const paidRegistration = !!response.data.paid;
                const showKeepRegistration = paidRegistration && response.data.data[0] !== null;
                setRegistrationRequest(showKeepRegistration)
            })
    };

    const fetchServices = async () => {
        const studentServices = await http.get(`/rest/student-study-group-services/get-services/${studentID}/${groupsID.old}`).then((response: any) => {
            console.log(response)
            return response.data.data.map((item: any) => {
                return {
                    ...item,
                    approved: item.pivot.approved,
                    canceled: item.pivot.canceled,
                    from_web: item.pivot.from_web,
                    paid: item.pivot.paid,
                }
            })
        });
        const keep_services = new Object() as any;
        studentServices.forEach((service: any) => {
            if (!!service.paid) {
                keep_services[service.id] = {
                    fee: service.pivot.individual_price !== null
                        ? service.pivot.individual_price
                        : service.price,
                    keep: 0
                }
            }
        });
        setKeepServices(keep_services)
        setOldServices(studentServices);
    };

    const changeGroup = async () => {
        setLoading(true);
        let toSend = { new_group: groupsID.new } as any;
        if (registrationRequest) {
            toSend = {
                // reservation_type: reservation,
                new_group: groupsID.new,
                keep_registration: keepRegistration.keep
            } as any;
            if (keepRegistration.fee !== null) {
                toSend.new_registration_fee = keepRegistration.fee
            }
        }
        const servicesToKeep = new Object() as any;
        Object.keys(keepServices).forEach((key: any) => {
            if (!!keepServices[key].keep) {
                servicesToKeep[key] = {
                    fee: keepServices[key].fee
                }
            }
        });
        if (Object.keys(servicesToKeep).length > 0) {
            toSend.keep_services = servicesToKeep
        }
        console.log(toSend)
        http.post(`rest/study-groups/change-group-with-payments/${studentID}/${groupsID.old}`, { ...toSend })
            .then((response: any) => {
                console.log(response)
                const { bad_services, new_services } = response.data.data
                setServices({
                    bad_services,
                    new_services
                })
                setLoading(false);
                setStudentMoved(true);
            })
    };

    const setBadServices = async () => {
        await http.post(`rest/study-groups/change-group-with-payments-bad-groups/${studentID}/${groupsID.old}/${groupsID.new}`,
            {
                newServices: selectedServices
            }).then((response: any) => {
                console.log(response);
                message.success("Uzstādījumi saglabāti!");
                regenerateAgreement();
            })
    };

    const regenerateAgreement = () => {
        http.post(`rest/manipulate-agreement/${studentID}`, {
            new: 1,
            group: groupsID.new
        }).then((response: any) => {
            redirect();
        }).catch(() => {
            message.error("Kļūda ģenerējot jaunu līgumu!")
            const timeout = setTimeout(() => redirect(), 3000);
        })
    }

    const redirect = () => {
        setVisible(false);
        // window.location.href = `/groups/edit/edit-student/${studentID}/${groupsID.new}`;
        window.location.href = `/groups/student-opening/${studentID}`;
    };

    const goToStepTwo = () => {
        setFirstStep(false);
        changeGroup();
    }

    useEffect(() => {
        console.log(studentID, groupsID, visible)
    }, []);

    useEffect(() => {
        console.log(keepServices)
    }, [keepServices])

    useEffect(() => {
        console.log(keepRegistration)
    }, [keepRegistration])

    // useEffect(() => {
    //     if (visible) {
    //         fetchStudentReservation();
    //     }
    // }, [visible]);

    useEffect(() => {
        if (reservation.length > 0) {
            // changeGroup();
            fetchServices();
            fetchStudentRegistration();
        };
    }, [reservation]);

    useEffect(() => {
        console.log(selectedServices)
    }, [selectedServices])

    const columns = [
        {
            title: "Pakalpojums",
            key: "old_service",
            render: (record: any) => {
                return <b>
                    {record?.value_lv}
                </b>
            },
            width: "40%",
        },
        {
            title: "Cena",
            key: "old_service_price",
            render: (record: any) => {
                const price = record?.pivot?.individual_price !== null
                    ? record?.pivot?.individual_price
                    : record?.price
                return <b>
                    {price}
                </b>
            },
            width: "10%"
        },
        {
            title: "Jaunais pakalpojums",
            key: "new_service",
            render: (record: any) => {
                return <Row>
                    <Select
                        style={{ width: 200 }}
                        onChange={(e: any) => {
                            console.log(e)
                            setSelectedServices((prev: any) => {
                                if (Number(e) === 0) {
                                    delete prev[record.id];
                                    return { ...prev };
                                } else {
                                    return {
                                        ...prev,
                                        [record.id]: services?.new_services.find((el: any) => Number(el.id) === Number(e))
                                    }
                                }
                            })
                        }}
                        defaultValue={0}
                    >
                        <Select.Option value={0}>{ }</Select.Option>
                        {
                            services?.new_services.map((service: any) => {
                                return <Select.Option key={service.id} value={service.id}>
                                    {service.value_lv}
                                </Select.Option>
                            })
                        }
                    </Select>
                </Row>
            },
            width: "40%",
        },
        {
            title: "Cena",
            key: "new_service_price",
            render: (record: any) => {
                return <b>{selectedServices?.[record.id]?.price}</b>
            },
            width: "10%"
        }
    ] as any;

    return <Modal
        visible={visible}
        onOk={setBadServices}
        onCancel={() => { firstStep ? setVisible(false) : setVisible(true) }}
        width={900}
        destroyOnClose={true}
        closeIcon={""}
        footer={
            firstStep
                ? <Button type="primary" loading={loading} onClick={goToStepTwo}>
                    Turpināt
                </Button>
                : <Button key="submit" type="primary" loading={loading} onClick={setBadServices}>
                    Saglabāt
                </Button>
        }
    >
        {
            firstStep
                ? <div>
                    {
                        registrationRequest
                            ? <Row>
                                <Col span={12}>
                                    <Checkbox
                                        style={{ marginRight: 10 }}
                                        checked={!!keepRegistration.keep}
                                        onChange={(e: any) => {
                                            setKeepRegistration((prev: any) => {
                                                return {
                                                    ...prev,
                                                    keep: e.target.checked ? 1 : 0
                                                }
                                            })
                                        }}
                                    />
                                    <b>Atkārtoti pieprasīt reģistrācijas maksu</b>
                                </Col>
                                <Col span={12}>
                                    {
                                        !!keepRegistration.keep
                                        && <>
                                            <Input
                                                style={{ width: 100, marginLeft: 20 }}
                                                type="number"
                                                step={0.01}
                                                value={keepRegistration.fee}
                                                onChange={(e: any) => {
                                                    e.persist();
                                                    setKeepRegistration((prev: any) => {
                                                        return {
                                                            ...prev,
                                                            fee: e.target.value.length > 0 ? e.target.value : null
                                                        }
                                                    })
                                                }}
                                            />&nbsp;&euro;
                                        </>
                                    }
                                </Col>
                            </Row>
                            : <p>Nav apmaksātu reģistrācijas ierakstu</p>
                    }
                    {
                        oldServices?.filter((service: any) => {
                            return !!service.paid;
                        })?.map((service: any, index: any) => {
                            const oldService = keepServices[service.id]
                            return <div
                                key={index}
                                style={{ margin: "8px 0" }}
                            >
                                <Row>
                                    <Col span={12}>
                                        <Checkbox
                                            style={{ marginRight: 10 }}
                                            checked={!!oldService.keep}
                                            onChange={(e: any) => {
                                                setKeepServices((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        [service.id]: {
                                                            ...keepServices[service.id],
                                                            keep: e.target.checked ? 1 : 0
                                                        }
                                                    }
                                                })
                                            }}
                                        />
                                        <b>Atkārtoti pieprasīt apmaksu par: {service.value_lv}</b>
                                    </Col>
                                    <Col span={12}>
                                        {
                                            !!oldService.keep
                                            && <>
                                                <Input
                                                    style={{ width: 100, marginLeft: 20 }}
                                                    type="number"
                                                    step={0.01}
                                                    value={oldService.fee}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        setKeepServices((prev: any) => {
                                                            return {
                                                                ...prev,
                                                                [service.id]: {
                                                                    ...keepServices[service.id],
                                                                    fee: e.target.value.length > 0 ? e.target.value : "0"
                                                                }
                                                            }
                                                        })
                                                    }}
                                                />&nbsp;&euro;
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        })
                    }
                </div>
                : <>
                    {
                        studentMoved
                            ? <h3><b>Students ir pārcelts uz grupu: {groupsID.new}</b></h3>
                            : <></>
                    }
                    <Tag
                        style={{ whiteSpace: "normal", margin: "20px 0", textAlign: "center" }}
                        color={"green"}
                    >
                        Tabulā ir pakalpojumi, kurus nav iespējams pārcelt automātiski. Lūdzu norādiet katram pakalpojumam atbilstošu pozīciju izkrītošajā sarakstā. Ja izkrītošajā sarakstā būs atstāts tukšums, pakālpojums netiks pārnests un studentam automātiski tiks atgriezta nauda!
                    </Tag>
                    <Table
                        rowKey="id"
                        columns={columns}
                        loading={loading}
                        dataSource={services.bad_services}
                    />
                </>
        }
    </Modal>
}