import { Layout, PageHeader, Row, Col, Card, Form, Button, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { http } from '../helpers/http';
import Avatar from './ImgUploader';

const Content = Layout.Content;

export type NewInstructorPageProps = RouteComponentProps & {
};
export type NewInstructorPageState = {
    submitting: boolean,
    classifiers: any,
    fileData: any,
    img: any,
};

export class BaseNewInstructorPage extends React.Component<NewInstructorPageProps, NewInstructorPageState> {

    constructor(props: NewInstructorPageProps) {
        super(props);

        this.state = {
            submitting: false,
            classifiers: {},
            fileData: {},
            img: {},
        };
    }

    uploadImage = (value: any) => {
        this.setState({ img: value });
    }

    getFile = (file: any) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            this.setState({ fileData: reader.result });
        }
        // console.log(file)
        if (file && file?.length > 0) {
            reader.readAsDataURL(file[0].originFileObj);
        }
    }

    onFinish = (values: any) => {
        console.log('Success:', values);
        console.log(this.state.fileData);
        let valuesToSubmit
        if (this.state.fileData) {
            if (this.state.fileData.length > 0) {
                valuesToSubmit = {
                    ...values,
                    img: this.state.fileData
                };
            } else {
                valuesToSubmit = {
                    ...values,
                };
            }
        } else {
            valuesToSubmit = {
                ...values,
            };
        }

        this.setState({ submitting: true });

        http.post("/rest/instructors", valuesToSubmit).then((response) => {
            this.props.history.replace(`/instructors/edit/${response.data.data.id}`);
        });
    };

    onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    componentDidMount() {
        this.loadClassifiers();
    }

    async loadClassifiers() {
        const languages = await http.get("/rest/languages");

        this.setState({
            classifiers: {
                languages: languages.data.data
            }
        });
    }

    render() {
        return <Layout>
            <PageHeader
                style={{ padding: "0 16px", background: "rgb(255, 255, 255)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}
                onBack={() => { this.props.history.goBack(); }}
                title={
                    <>
                        <span style={{ fontSize: "0.7em", fontWeight: "normal" }}>Doties atpakaļ</span>
                        <span style={{ marginLeft: 40 }}>Jauns instruktors</span>
                    </>
                }
            />
            <Content style={{ padding: 20 }}>
                <Card title={<strong>Instruktora informācija</strong>}>
                    <Form
                        name="basic"
                        layout="vertical"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Row gutter={20}>
                            <Col flex={1}>
                                <Form.Item name="first_name" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Vārds">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col flex={1}>
                                <Form.Item name="last_name" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Uzvārds">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={20}>
                            <Col flex={1}>
                                <Form.Item name="phone_number" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Tālrunis">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col flex={1}>
                                <Form.Item name="email" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }, { type: "email", message: "Nav derīga e-pasta adrese" }]} label="E-pasta adrese">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={20}>
                            <Col flex={1}>
                                <Form.Item name="username" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Lietotāja vārds (login)">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col flex={1}>
                                <Form.Item name="password" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Parole">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={20}>
                            <Col>
                                <Form.Item name="language_ids" required rules={[{ required: true, message: "Obligāts lauks", type: "array" }]} label="Valodas">
                                    <Select style={{ width: 200 }} mode="multiple">
                                        {this.state.classifiers.languages
                                            ? this.state.classifiers.languages.map((language: any) => {
                                                return <Select.Option value={String(language.id)}>{language.value}</Select.Option>
                                            }) : null}
                                        {/* <Select.Option value="1">Latviešu</Select.Option>
                  <Select.Option value="2">Krievu</Select.Option> */}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={20}>
                            <Col>
                                <Form.Item name="description" label="Papildus informācija">
                                    <TextArea style={{ width: 300 }}></TextArea>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Item name="image" label="Pievienot attēlu">
                                    <Avatar getImg={this.uploadImage} returnFile={this.getFile} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Item>
                                    <Button type="primary" loading={this.state.submitting} htmlType="submit">Pievienot</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Content>
        </Layout>;
    }

}

export const NewInstructorPage = withRouter(BaseNewInstructorPage);