import { Button, Card, Checkbox, Col, Collapse, Input, Layout, message, Row, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../helpers/http";
import { BasicFilterForm } from "./BasicFiltersForm";
import { DrivingFilterForm } from "./DrivingFilterForm";
import { MultiChannelLanguagesSpammer } from "./MultiChannelLanguagesSpammer";
import { ServicesFilterForm } from "./ServicesFilterForm";
import { StudyGroupFilterForm } from "./StudyGroupFilterForm";
import { ReactComponent as ExcelLogo } from '../../../src/resources/svg/icons8-microsoft-excel.svg'
import moment from "moment";
const { Header } = Layout;

export interface StudentFilter {
    gender?: [undefined | "male" | "female"],
    age_group?: [number],
    days_since_start_of_study_from?: string,
    days_since_start_of_study_till?: string,
    study_type?: [string],
    category?: [number],
    instructor?: [number],
    language?: [number],
    books?: 1 | 0,
    driving_city?: [number],
    study_city?: [number],
    school_theory_exam_passed?: 1 | 0,
    pmp_passed?: 1 | 0,
    pmp_from?: string,
    pmp_till?: string,
    med_from?: string,
    med_till?: string,
    got_med?: 1 | 0,
    attended_lessons_from?: number,
    attended_lessons_to?: number,
    unattended_lessons_from?: number,
    unattended_lessons_to?: number,
    skipped_lessons_from?: number,
    skipped_lessons_to?: number,
    have_group?: 1 | 0,
    paid_registration?: 1 | 0,
    csdd_theory_passed?: 1 | 0,
    has_driven?: 1 | 0,
    logged_in?: 1 | 0,
    sent_to_driving_csdd?: 1 | 0,
    individual_driving?: 1 | 0,
    got_licence?: 1 | 0,
    registered_at_till?: string,
    registered_at_from?: string,
    driving_from?: string,
    driving_till?: string,
    transmission?: ["automatic" | "manual"],
    school_theory_finished?: 1 | 0,
    stopped_driving?: 1 | 0,
    didnt_drive_from?: number,
    didnt_drive_to?: number,
    school_theory_exam_not_applied?: 1 | 0
}

const FilterIndicator = () => <div
    style={{
        width: 8,
        height: 8,
        backgroundColor: "#02c0fa",
        borderRadius: "50%"
    }}
/>

export const StudentSpamFilterLayout = (props: any) => {
    const history = useHistory();
    const [studentFilter, setStudentFilter] = useState<StudentFilter>({} as StudentFilter);
    const [classifiers, setClassifiers] = useState({} as any);
    const [students, setStudents] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const fetchClassifiers = async () => {
        const languages = await http.get('rest/languages').then(response => response.data.data)
        const categories = await http.get('rest/categories').then(response => response.data.data)
        const cities = await http.get('rest/cities').then(response => response.data.data)
        setClassifiers({ languages, categories, cities })
    };

    const filterCategories = {
        "basic": ["gender", "age_group", "category", "language", "got_licence", "logged_in", "registered_at_from"],
        "study_group": ["days_since_start_of_study", "study_type", "study_city", "attended_lessons_from", "attended_lessons_to", "unattended_lessons_from", "unattended_lessons_to", "unattended_lessons_from", "unattended_lessons_to", "have_group", "school_theory_exam_passed", "csdd_theory_passed", "school_theory_finished", "school_theory_exam_not_applied"],
        "driving": ["instructor", "driving_city", "has_driven", "sent_to_driving_csdd", "individual_driving", "driving_from", "transmission", "stopped_driving"],
        "services": ["books", "pmp_passed", "got_med", "paid_registration"]
    } as any;

    const columns = [
        {
            title: "Students",
            dataIndex: "student_name",
            key: "student_name"
        },
        {
            title: "Kategorija",
            dataIndex: "category_id",
            key: "category_id",
            render: (category: any) => {
                return classifiers?.categories?.find((el: any) => el.id === category)?.value
            }
        },
        {
            title: <div>
                <p style={{ margin: 0 }}>Pilsēta</p>
                <small>Teorija</small>
            </div>,
            dataIndex: "study_city",
            key: "study_city",
            render: (city: any) => {
                return city === 0
                    ? "-"
                    : classifiers?.cities?.find((el: any) => el.id === city)?.value
            }
        },
        {
            title: <div>
                <p style={{ margin: 0 }}>Pilsēta</p>
                <small>Braukšana</small>
            </div>,
            dataIndex: "driving_city",
            key: "driving_city",
            render: (city: any) => {
                return city === 0
                    ? "-"
                    : classifiers?.cities?.find((el: any) => el.id === city)?.value
            }
        },
        {
            title: "Valoda",
            dataIndex: "language_id",
            key: "language_id",
            render: (language: any) => {
                return classifiers?.languages?.find((el: any) => el.id === language)?.value
            }
        },
        {
            title: "E-pasts",
            dataIndex: "student_email",
            key: "student_email"
        },
        {
            title: "Tālrunis",
            dataIndex: "phone_number",
            key: "phone_number"
        },
        {
            title: "CSDD grupa",
            dataIndex: "csdd_nr",
            key: "csdd_nr"
        },
        {
            dataIndex: "stid",
            key: "stid",
            render: (id: any) => {
                return <Button
                    onClick={() => {
                        // history.push(`/students/edit/${id}`)
                        window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}students/edit/${id}`, "_blank");
                    }}
                >
                    Atvērt
                </Button>
            }
        }
    ]

    const fetchStudents = (filters: StudentFilter = studentFilter) => {
        setLoading(true);
        http.get(`rest/get-filter-spam`, { params: { filters } }).then((response: any) => {
            console.log(response)
            setLoading(false);
            setStudents(response.data.data)
        }).catch(() => {
            message.error("Kļūda!");
            setLoading(false);
        })
        sessionStorage.setItem("spammerFilter", JSON.stringify(filters));
    };

    const getFile = async () => {
        setLoading(true);
        const url = `rest/get-filter-spam?export_excel=1`
        await http.get(url, { params: { filters: studentFilter }, responseType: 'blob' }).then((response: any) => {
            console.log(response)
            setLoading(false);
            return new Blob([response.data]);
        }).then((blob: any) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a') as any;
            link.href = url;
            link.setAttribute('download', `${moment().format('YYYY-MM-DD HH:mm:ss')}studentu_filtrs.xls`);
            document.body.appendChild(link);
            link.click();
            if (link !== null) link.parentNode.removeChild(link);
        }).catch(() => {
            message.error("Kļūda");
            setLoading(false);
        })
    }

    const checkFilterMemo = () => {
        const filters = sessionStorage.getItem("spammerFilter");
        if (filters && filters !== undefined) {
            const filterObj = JSON.parse(filters);
            if (Object.keys(filterObj).length > 0) {
                setStudentFilter(filterObj);
                fetchStudents(filterObj)
            }
        }
    };

    const eraseFilters = () => {
        sessionStorage.removeItem("spammerFilter");
        setStudentFilter({});
        setStudents([]);
    };

    const activeFilter = (category: any) => {
        return Object.keys(studentFilter).some((el: any) =>
            filterCategories[category].some((item: any) =>
                item === el))
    };

    useEffect(() => {
        checkFilterMemo();
        fetchClassifiers();
    }, []);

    useEffect(() => {
        console.log(studentFilter)
    }, [studentFilter]);

    return <Layout>
        <Header style={{ padding: "0 16px", background: "rgb(240, 242, 245)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}>
            <h3><b>Studentu filtrs. Atlasīti: {students.length} ieraksti</b></h3>
        </Header>
        <Card>
            <Collapse style={{ marginBottom: 30 }}>
                <Collapse.Panel
                    key="1"
                    header={
                        <Row>
                            <p style={{ margin: 0, marginRight: 6 }}>Studenta pamatinformācija</p>
                            {
                                activeFilter('basic') && <FilterIndicator />
                            }
                        </Row>
                    }
                >
                    <BasicFilterForm
                        studentFilter={studentFilter}
                        setStudentFilter={setStudentFilter}
                        classifiers={classifiers}
                    />
                </Collapse.Panel>
                <Collapse.Panel
                    key="2"
                    header={
                        <Row>
                            <p style={{ margin: 0, marginRight: 6 }}>Mācību grupa</p>
                            {
                                activeFilter('study_group') && <FilterIndicator />
                            }
                        </Row>
                    }
                >
                    <StudyGroupFilterForm
                        studentFilter={studentFilter}
                        setStudentFilter={setStudentFilter}
                        classifiers={classifiers}
                    />
                </Collapse.Panel>
                <Collapse.Panel
                    key="3"
                    header={
                        <Row>
                            <p style={{ margin: 0, marginRight: 6 }}>Braukšana</p>
                            {
                                activeFilter('driving') && <FilterIndicator />
                            }
                        </Row>
                    }
                >
                    <DrivingFilterForm
                        studentFilter={studentFilter}
                        setStudentFilter={setStudentFilter}
                        classifiers={classifiers}
                    />
                </Collapse.Panel>
                <Collapse.Panel
                    key="4"
                    header={
                        <Row>
                            <p style={{ margin: 0, marginRight: 6 }}>Pakalpojumi</p>
                            {
                                activeFilter('services') && <FilterIndicator />
                            }
                        </Row>
                    }
                >
                    <ServicesFilterForm
                        studentFilter={studentFilter}
                        setStudentFilter={setStudentFilter}
                    />
                </Collapse.Panel>
            </Collapse>
            <Row justify="end" align="middle">
                <Button
                    type="primary"
                    onClick={() => fetchStudents()}
                    loading={loading}
                >
                    Atlasīt
                </Button>
                <Button className="excel-btn" onClick={getFile} style={{ marginLeft: 10 }}>
                    Eksportēt&nbsp;
                    <ExcelLogo className="excel-btn__logo" />
                </Button>
                <Button
                    style={{ marginLeft: 10 }}
                    onClick={eraseFilters}
                    loading={loading}
                    danger
                >
                    Dzēst filtru
                </Button>
            </Row>
            <Row>
                <Col span={24}>
                    <div style={{ marginBottom: 30 }}>
                        <h3><b>Sūtīt paziņojumu</b></h3>
                        <MultiChannelLanguagesSpammer
                            languages={classifiers.languages}
                            students={students.map((el: any) => el.stid)}
                        />
                    </div>
                </Col>
                <Col span={24}>
                    <Table
                        rowKey={record => record.stid}
                        columns={columns}
                        dataSource={students}
                        loading={loading}
                    />
                </Col>
            </Row>
        </Card>
    </Layout >
}