import React, { useEffect, useState } from "react";
import { Button, Card, Col, DatePicker, Input, message, Row, Space, Spin, Table } from "antd";
import moment from "moment";
import { http } from "../../../helpers/http";
import { ReactComponent as ExcelLogo } from '../../../resources/svg/icons8-microsoft-excel.svg';

export const InDetail = (props: any) => {
    const [showInBrowser, setShowInBrowser] = useState(false);
    const [period, setPeriod] = useState(null as any);
    const [loading, setLoading] = useState(false);
    const [balances, setBalances] = useState(undefined as any);
    const [payments, setPayments] = useState([] as any);
    const [student, setStudent] = useState("" as string)

    const fetchPayments = async () => {
        if (period === null) return;
        setShowInBrowser(false);
        setLoading(true);
        const from = moment(period[0]).format('YYYY-MM-DD');
        const to = moment(period[1]).format('YYYY-MM-DD');
        await http.post(`/schoolinvoices/get-all-detalization/${from}/${to}/${student.length === 0 ? null : student}`).then((response: any) => {
            setLoading(false);
            const joinAll = Object.keys(response.data.rows).flatMap((part: any) => {
                return response.data.rows[part];
            });
            const addedLastRow = joinAll.concat({ amount: response.data.balances.period_total.toFixed(2) })
            setPayments(addedLastRow)
            setBalances(() => {
                console.log(response.data)
                return {
                    ...response.data.balances,
                    calculated_end_balance: response.data.calculated_end_balance,
                    instructor_total: response.data.instructor_total,
                    someting_positive: response.data.something_positive,
                    school_total: response.data.school_total,
                    school_period_in: response.data.school_period_in,
                    school_period_out: response.data.school_period_out,
                    instructors_period_in: response.data.instructors_period_in,
                    instructors_period_out: response.data.instructors_period_out,
                    deposit_period_in: response.data.deposit_period_in,
                    deposit_period_out: response.data.deposit_period_out,
                    credit_in: response.data.credit_in,
                    credit_out: response.data.credit_out,
                    end_credit: response.data.end_credit
                }
            })
        }).catch(() => {
            message.error("Kļūda (vai nav atrasts neviens ieraksts)");
            setLoading(false);
        })
    }

    const getFile = async () => {
        if (period === null) return;
        const from = moment(period[0]).format('YYYY-MM-DD');
        const to = moment(period[1]).format('YYYY-MM-DD');
        setLoading(true);
        await http.get(`/schoolinvoices/export-all-detalization/${from}/${to}/${student.length === 0 ? null : student}`, { responseType: 'blob' }).then((response: any) => {
            setLoading(false);
            return new Blob([response.data]);
        }).then((blob: any) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a') as any;
            link.href = url;
            link.setAttribute('download', `${moment().format('YYYY-MM-DD HH:mm:ss')}.xls`);
            document.body.appendChild(link);
            link.click();
            if (link !== null) link.parentNode.removeChild(link);
        })
    }

    // useEffect(() => {
    //     fetchPayments();
    // }, [period]);


    const columns = [
        {
            title: 'Datums',
            dataIndex: 'ts',
            key: 'date',
            render: (date: any) => {
                return date ? moment(date).format("DD.MM.YYYY") : ""
            }
        },
        {
            title: 'Maksātājs',
            dataIndex: 'student',
            key: 'payer'
        },
        {
            title: 'Virtuālā nauda',
            dataIndex: 'virtual_amount',
            key: 'virtual_amount',
            render: (record: any) => record ? Number(record).toFixed(2) : "",
        },
        {
            title: <>
                <div className="outer-upper-cell">{balances?.start_total.toFixed(2)}</div>
                Reālā nauda
            </>,
            dataIndex: 'amount',
            key: 'amount',
            width: '150px',
            render: (record: any) => Number(record).toFixed(2),
        },
        {
            title: 'Saņēmējs',
            dataIndex: 'instructor',
            key: 'recipent'
        },
        {
            title: 'Pamatojums',
            dataIndex: 'reason',
            key: 'reason'
        },

        // {
        //     title: 'Apgrozījums',
        //     dataIndex: 'amount',
        //     key: 'turnover',
        //     render: (record: any, all: any) => {
        //         return <b style={{ color: record < 0 ? "#FF0000" : "#0EB700"}}>{record.toFixed(2)} &euro;</b>
        //     }
        // },
    ] as any;

    useEffect(() => {
        console.log(student)
    }, [student])

    return (
        <Card>
            <Row align="middle" style={{ marginBottom: 20 }}>
                <DatePicker.RangePicker value={period} onChange={setPeriod} style={{ margin: "10px 15px 10px 0" }} />
                <Input
                    style={{ width: 200 }}
                    value={student}
                    onChange={(e: any) => setStudent(e.target.value)}
                    placeholder="Studenta e-pasts"
                />
                {/* <Input placeholder="Nosaukums" style={{ width: 200, margin: "10px 15px 10px 0" }} />
                <Input placeholder="Summa" style={{ width: 200, margin: "10px 15px 10px 0" }} /> */}
                {/* <Input placeholder="Numurs" style={{ width: 200, margin: "10px 15px 10px 0" }} /> */}
                <Button style={{ margin: "0 15px" }} onClick={fetchPayments}>Atlasīt</Button>
                <h3 style={{ margin: "0 15px 0 0" }}>Ieraksti: <b>{payments.length > 0 ? payments.length - 1 : 0}</b></h3>
                {
                    payments.length > 0
                        ? <Space>
                            <Button onClick={() => setShowInBrowser(!showInBrowser)} style={{ margin: "10px 15px 10px 0" }} >
                                {showInBrowser ? "Paslēpt rezultātus" : "Rādīt rezultātus pārlūkā"}
                            </Button>
                            <Button className="excel-btn" onClick={getFile}>
                                Eksportēt&nbsp;
                                <ExcelLogo className="excel-btn__logo" />
                            </Button>
                        </Space>
                        : <></>
                }
            </Row>
            <Row justify="center">
                {
                    loading
                        ? <div style={{ display: "flex", flexDirection: "column", alignContent: "center" }}>
                            <Spin size="large" /><br />
                            <small>(Ierakstu atlase var aizņemt vairākas minūtes)</small>
                        </div>
                        : <></>
                }
            </Row>

            {
                showInBrowser
                    ? <Row>
                        <Col span={24}>
                            <Table
                                className="deposit-table"
                                rowKey={record => { return record.id || record.date + record.amount }}
                                columns={columns}
                                dataSource={payments}
                                pagination={false}
                                title={(records: any) => {
                                    return <Row>
                                        <table className="balance-mini-table" style={{ display: "inline-table", width: 300, background: "#ffffff", border: "1px solid #c0c0c0" }}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={2}>
                                                        Atlikums uz perioda SĀKUMU
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Autoskola Einšteins</td>
                                                    <td>{balances?.start_balance.toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tai skaitā Kredītlīnija</td>
                                                    <td>{balances?.start_credit}</td>
                                                </tr>
                                                <tr>
                                                    <td>DEPOZĪTS</td>
                                                    <td>{balances?.start_earnings.toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Instruktors</td>
                                                    <td>{balances?.start_balance_instructor.toFixed(2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <b>Autoskola Einšteins: {balances?.start_balance}</b>&nbsp;
                                        <b>DEPOZĪTS: {balances?.end_balance}</b>&nbsp;
                                        <b>Instruktors: {balances?.start_balance_instructor}</b>&nbsp; */}
                                        {/* <b>end_balance: {balances?.end_balance}</b>&nbsp;
                                        <b>end_balance_data_instructor: {balances?.end_balance_data_instructor}</b>&nbsp;
                                        <b>end_balance_data_invoice: {balances?.end_balance_data_invoice}</b>&nbsp;
                                        <b>instructor_sum: {balances?.instructor_sum}</b>&nbsp;
                                        <b>invoice_deduction: {balances?.invoice_deduction}</b>&nbsp;
                                        <b>period_balance: {balances?.period_balance}</b>&nbsp;
                                        <b>period_balance_instructor: {balances?.period_balance_instructor}</b>&nbsp;
                                        <b>period_balance_invoice: {balances?.period_balance_invoice}</b>&nbsp;
                                        <b>school_payments_sum: {balances?.school_payments_sum}</b>&nbsp;
                                        <b>start_balance_instructor: {balances?.start_balance_instructor}</b>&nbsp;
                                        <b>start_balance_invoice: {balances?.start_balance_invoice}</b>&nbsp;
                                        <b>calculated_end_balance: {balances?.calculated_end_balance}</b>&nbsp; */}
                                    </Row>
                                }}
                                footer={(records: any) => {
                                    return <Row justify="start">
                                        <Col span={24}>
                                            <table className="balance-mini-table" style={{ marginBottom: 16, display: "inline-table", width: 500, background: "#ffffff", border: "1px solid #c0c0c0" }}>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Apgrozījums
                                                        </th>
                                                        <th>
                                                            Ienākošie
                                                        </th>
                                                        <th>
                                                            Izejošie
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Autoskola Einšteins</td>
                                                        <td>{(balances?.school_period_in).toFixed(2)}</td>
                                                        <td>{balances?.school_period_out.toFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kredītlīnija</td>
                                                        <td>{(balances?.credit_in).toFixed(2)}</td>
                                                        <td>{balances?.credit_out.toFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DEPOZĪTS</td>
                                                        <td>{balances?.deposit_period_in.toFixed(2)}</td>
                                                        <td>{balances?.deposit_period_out.toFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Instruktors</td>
                                                        <td>{balances?.instructors_period_in.toFixed(2)}</td>
                                                        <td>{balances?.instructors_period_out.toFixed(2)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                        <table className="balance-mini-table" style={{ display: "inline-table", width: 300, background: "#ffffff", border: "1px solid #c0c0c0" }}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={2}>
                                                        Atlikums uz perioda beigām
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Autoskola Einšteins</td>
                                                    <td>{(balances?.end_balance).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Kredītlīnija</td>
                                                    <td>{(balances?.end_credit).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>DEPOZĪTS</td>
                                                    <td>{(balances?.end_earnings).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Instruktors</td>
                                                    <td>{(balances?.end_balance_instructor).toFixed(2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <Col style={{ display: "flex", flexDirection: "column", alignContent: "end" }}>
                                            <b>Einšteina daļa: {balances?.school_total}</b>
                                            <b>Instruktoru daļa: {balances?.instructor_total}</b>
                                            <b>Depozīts, kas vēl nav atprečots: {payments[payments.length - 1]?.total}</b>
                                            <b>Einšteins uzkrājums: {balances?.calculated_end_balance}</b>
                                        </Col> */}
                                    </Row>
                                }}
                            />
                        </Col>
                    </Row>
                    : <></>
            }

        </Card>
    )
}