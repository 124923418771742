import { Button, Col, Input, Modal, Row } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../../../helpers/http";

export const ZoomAccountsList = (props: any) => {
    const { accounts, fetchZoomAccounts } = props;
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        client_id: "",
        client_secret: "",
    } as any);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [updateID, setUpdateID] = useState(null);

    const saveZoomAccount = () => {
        if (updateID !== null) {
            http.patch(`rest/zoom-accounts/${updateID}`, { ...formData }).then((response: any) => {
                console.log(response)
                fetchZoomAccounts();
                setShowForm(false);
            });
        } else {
            http.post(`rest/zoom-accounts`, { ...formData }).then((response: any) => {
                console.log(response)
                fetchZoomAccounts();
                setShowForm(false);
            });
        }
    };

    const deleteZoomAccount = (id: any) => {
        Modal.confirm({
            title: "Dzēst Zoom kontu",
            content: "Vai tiešām dzēst izvēlēto Zoom kontu?",
            onOk: () => {
                http.delete(`rest/zoom-accounts/${id}`).then((response: any) => {
                    fetchZoomAccounts();
                })
            },
        })
    };

    useEffect(() => {
        fetchZoomAccounts();
    }, []);

    useEffect(() => {
        const allowSubmit = Object.keys(formData).filter((key: any) => {
            return formData[key].length > 0
        }).length === 3;
        setAllowSubmit(allowSubmit);
    }, [formData])

    return <>
        <ul
            style={{ padding: 0, marginBottom: 4 }}
        >
            {
                accounts?.map((account: any, index: number) => {
                    return <li
                        key={index}
                        style={{
                            backgroundColor: "#D8D8D8",
                            listStyle: "none",
                            padding: "12px 20px"
                        }}
                    >
                        <Row>
                            <Col span={6} style={styles.flexCol}>
                                <span style={styles.linkText}>Nosaukums: {account.name}</span>
                            </Col>
                            <Col span={12} style={styles.flexCol}>
                                <span style={styles.linkText}>Client ID: {account.client_id}</span>
                            </Col>
                            <Col span={6} style={{ display: "flex", flexDirection: "row-reverse" }}>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setFormData({
                                            name: account.name,
                                            client_id: account.client_id,
                                            client_secret: account.client_secret
                                        });
                                        setShowForm(true);
                                        setUpdateID(account.id);
                                    }}
                                >
                                    Labot
                                </Button>
                                <Button
                                    type="text"
                                    onClick={() => deleteZoomAccount(account.id)}
                                >
                                    Dzēst kontu
                                </Button>
                            </Col>
                            {
                                <Col span={24} style={styles.flexCol}>
                                    <b style={styles.linkText}>token_uri: {account.token_uri}</b>
                                </Col>
                            }
                        </Row>
                    </li>
                })
            }
        </ul>
        {
            showForm
                ? <Row
                    style={{
                        border: "1px solid #c0c0c0",
                        borderRadius: 5,
                        padding: 30
                    }}
                    align="bottom"
                >
                    <div>
                        <p>Nosaukums</p>
                        <Input
                            value={formData.name}
                            onChange={(e: any) => {
                                e.persist();
                                setFormData((prev: any) => {
                                    return {
                                        ...prev,
                                        name: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                    <div
                        style={{ marginLeft: 20 }}
                    >
                        <p>Client ID</p>
                        <Input
                            value={formData.client_id}
                            onChange={(e: any) => {
                                e.persist();
                                setFormData((prev: any) => {
                                    return {
                                        ...prev,
                                        client_id: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                    <div
                        style={{ marginLeft: 20 }}
                    >
                        <p>Client secret</p>
                        <Input
                            value={formData.client_secret}
                            onChange={(e: any) => {
                                e.persist();
                                setFormData((prev: any) => {
                                    return {
                                        ...prev,
                                        client_secret: e.target.value
                                    }
                                })
                            }}
                        />
                    </div>
                    <Button
                        type="primary"
                        style={{ marginLeft: 20 }}
                        disabled={!allowSubmit}
                        onClick={() => {
                            saveZoomAccount();
                        }}
                    >
                        {
                            updateID === null
                                ? "Pievienot"
                                : "Labot"
                        }
                    </Button>
                    <Button
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                            setFormData({
                                name: "",
                                client_id: "",
                                client_secret: ""
                            });
                            setShowForm(false);
                        }}
                    >
                        Atcelt
                    </Button>
                </Row>
                : <Button
                    type="text"
                    onClick={() => {
                        setUpdateID(null);
                        setFormData({
                            name: "",
                            client_id: "",
                            client_secret: ""
                        });
                        setShowForm(true);
                    }}
                >
                    <PlusCircleOutlined style={{ marginRight: 8 }} />
                    Pievienot jaunu kontu
                </Button>
        }
    </>
}

const styles = {
    flexCol: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    linkText: {
        display: "block",
    }
} as any;