import { Button, Card, Col, Layout, PageHeader, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GroupQuickInfoModal } from "./GroupQuickInfoModal";
import { http } from "../../helpers/http";
import { AxiosResponse } from "axios";

export const DayModulesOverview = (props: any) => {
    const {
        goBack,
        day
    } = props;
    const normalDate = day === undefined ? "" : moment(day, "YYYY-MM-DD").format("DD.MM.YYYY")
    const [modules, setModules] = useState({} as any)
    const [selectedModule, setSelectedModule] = useState(undefined as any);
    const [loading, setLoading] = useState(false);

    const getDayModules = (date: string) => {
        setLoading(true);
        http.get(`rest/group-load-day-stats/${date}`).then((res: AxiosResponse) => {
            setModules(res.data)
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if (props.day !== undefined) {
            getDayModules(props.day)
        }
    }, [props.day]);

    useEffect(() => {
        console.log(selectedModule)
    }, [selectedModule]);

    return <Card>
        <Row>
            <Col span={12}>
                <Button onClick={goBack}>
                    <ArrowLeftOutlined />
                    Kalendārs
                </Button>
            </Col>
            <Col span={12}>
                <h3 style={{ margin: 0 }}><b>{normalDate}</b></h3>
            </Col>
        </Row>
        <Card style={{ marginTop: 20 }} loading={loading}>
            <ul>
                {
                    Object.keys(modules).map((categoryID: any) => {
                        return <li key={categoryID}>
                            <b>{modules[categoryID][0]?.category} kategorija</b>
                            <ul className="problem-modules-list">
                                {
                                    modules[categoryID].map((module: any, index: any) => {
                                        return <li key={index} style={{ color: !!module.problem ? "red" : "" }} onClick={() => setSelectedModule(module)}>
                                            {module.module_name} {module.csdd_nr} ({module.foreigners}/{module.max_foreigners ?? 0}) {module.study_type} {module.city}
                                        </li>
                                    })
                                }
                            </ul>
                        </li>
                    })
                }
            </ul>
        </Card>
        {
            selectedModule
            && <GroupQuickInfoModal
                visible={selectedModule}
                setVisible={(state: any) => setSelectedModule(state)}
                module={selectedModule}
            />
        }
    </Card>
}